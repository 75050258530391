import { makeStyles, createStyles } from '#/utils/theme';

import SearchIcon from '../../../assets/Search.svg';
import { THEME } from '../../../constants/theme/constants';

export const useProductMixStyles = makeStyles(() =>
  createStyles({
    flex: {
      display: 'flex',
    },
    mbQrtRem: {
      '&&': {
        marginBottom: THEME.REM_SPACES['0.25'],
      },
    },
    flexSpaceBetweenCenter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    mrImg: {
      marginRight: THEME.REM_SPACES['0.5'],
    },
    mr125: {
      marginRight: '1.25rem',
    },
    tagMargin: {
      '&&': {
        margin: `${THEME.REM_SPACES['0.5']} 0  ${THEME.REM_SPACES['1']}`,
      },
    },
    editBox: {
      color: THEME.PALETTES.TEXT.Action_Primary,
      fontSize: '0.75rem',
      cursor: 'pointer',
      fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
      display: 'flex',
      alignItems: 'end',
    },
    typographyTitle: {
      '&&': {
        fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
        marginBottom: THEME.REM_SPACES['0.5'],
        display: 'flex',
        alignItems: 'center',
      },
    },
    wrapperDiv: {
      display: 'flex',
      background: '#fff',
      borderRadius: 22,
      padding: 16,
    },
    barChartContainer: {
      width: '70%',
      height: 350,
      paddingTop: 20,
    },
    barChartItem: {
      right: 30,
      left: 20,
    },
    pieChartContainer: {
      width: '30%',
      paddingTop: 20,
    },
    pieChartLabel: {
      fontSize: '27px',
      fontWeight: 'bold',
      fontFamily: 'Roboto',
    },
    itemViewSubLabel: {
      color: THEME.PALETTES.UI.Neutral.Base,
      fontSize: '0.813rem',
      marginBottom: THEME.REM_SPACES['1.5'],
    },
    justifyEnd: {
      display: 'flex',
      justifyContent: 'end',
    },
    autoCompleteInput: {
      background: `url(${SearchIcon} ) 5px center no-repeat`,
      paddingLeft: '30px!important',
      borderRadius: 3,
    },
  }),
);
