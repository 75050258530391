import { IDayOfWeek } from '../../interfaces/settings/calendar';

export const daysOfWeek: IDayOfWeek[] = [
  {
    label: 'Su',
    value: 'SUNDAY',
  },
  {
    label: 'M',
    value: 'MONDAY',
  },
  {
    label: 'Tu',
    value: 'TUESDAY',
  },
  {
    label: 'W',
    value: 'WEDNESDAY',
  },
  {
    label: 'Th',
    value: 'THURSDAY',
  },
  {
    label: 'F',
    value: 'FRIDAY',
  },
  {
    label: 'Sa',
    value: 'SATURDAY',
  },
];
