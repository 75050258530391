import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

// page container
export const usePageContainerStyles = makeStyles(() =>
  createStyles({
    pageContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: THEME.PALETTES.OTHER.White,
      borderRadius: THEME.REM_SPACES['1'],
    },
    pageDivider: {
      marginTop: THEME.REM_SPACES['1'],
    },
    tiersContainer: {
      padding: THEME.REM_SPACES['1'],
    },
    container: { display: 'flex', flexDirection: 'column', flex: '1' },
  }),
);
