import { useParams } from 'react-router';

import { useGetTaskQuery } from '../../../store/api/tasks/tasks';
import Loader from '../../common/loader/loader';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import DetailsSection from './components/DetailsSection';
import NextActionSection from './components/NextActionSection';

const TasksOverview = () => {
  const { aggregateId } = useParams();
  const { data, isLoading, isError } = useGetTaskQuery({ taskId: aggregateId });

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <TypographyWithFontFamily>There has been an error please refresh the page</TypographyWithFontFamily>;
  }

  if (!data) {
    return null;
  }

  const {
    targetObjectId,
    targetObjectType,
    status,
    taskStage,
    lastUpdated,
    description,
    taskType,
    permittedActions,
    comment,
    targetObjectName,
    targetObjectPath,
  } = data;

  const permittedActionsArray = permittedActions.length !== 0 ? permittedActions?.split(',') : [];

  return (
    <>
      <DetailsSection
        description={description}
        lastUpdated={lastUpdated}
        status={status}
        targetObjectId={targetObjectId}
        targetObjectName={targetObjectName}
        targetObjectPath={targetObjectPath}
        targetObjectType={targetObjectType}
        taskStage={taskStage}
        taskType={taskType}
      />
      <NextActionSection
        aggregateId={aggregateId}
        comment={comment}
        permittedActionsArray={permittedActionsArray}
        status={status}
      />
    </>
  );
};

export default TasksOverview;
