import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../constants/theme/constants';

export const useCampaignOverviewInternalStyles = makeStyles(() =>
  createStyles({
    form: {
      marginTop: THEME.REM_SPACES['1'],
      border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      padding: `${THEME.REM_SPACES['1']}`,
      borderRadius: THEME.REM_SPACES['0.5'],
    },
    row: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill,minmax(33%, 1fr))',
      gridGap: '2em',
      marginBottom: THEME.REM_SPACES['1'],
    },
  }),
);
