import { createStyles, makeStyles } from '#/utils/theme';

export const useBrandUserListStyle = makeStyles((theme) =>
  createStyles({
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.custom.REM_SPACES[1],
      columnGap: theme.custom.REM_SPACES[0.5],
      justifyContent: 'space-between',
    },
    headerLeft: {
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.custom.REM_SPACES['0.5'],
    },
    header: {
      '&.MuiTypography-root': {
        fontWeight: 'bold',
      },
    },
    tableContainer: {
      margin: `${theme.custom.REM_SPACES[1]} 0`,
      '& button': {
        border: 'none',
      },
    },
    mt2: {
      marginTop: theme.custom.REM_SPACES['2'],
    },
    tableHeader: {
      background: theme.custom.PALETTES.UI.Neutral[100],
      height: theme.custom.REM_SPACES['2.5'],
      color: theme.custom.PALETTES.TEXT.Dark,
      fontSize: '0.71875rem',
      fontWeight: theme.custom.FONT_WEIGHTS.SEMIBOLD,
    },
    tableCell: {
      height: theme.custom.REM_SPACES['2.5'],
      color: theme.custom.PALETTES.TEXT.Dark,
      fontWeight: theme.custom.FONT_WEIGHTS.REGULAR,
      fontSize: '0.8125rem',
    },
  }),
);
