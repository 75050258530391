import { FC } from 'react';

import ProductsFeedContainer from '#/components/features/campaigns/instances/channelsAndTactics/productsFeed/ProductsFeedContainer';

import Modal from '../../../../common/modal/Modal';

interface IProductFeedTableViewProps {
  upnCodesViewInstanceId: string;
  upnCodesViewName: string;
  onClose: () => void;
}
const ProductFeedTableView: FC<IProductFeedTableViewProps> = ({ upnCodesViewInstanceId, onClose }) => {
  return (
    <Modal
      content={<ProductsFeedContainer upnCodesViewInstanceId={upnCodesViewInstanceId} />}
      handleCloseModal={onClose}
      modalTitle={`Products list`}
      open
      size="wizard"
    />
  );
};
export default ProductFeedTableView;
