import { FormHelperText } from '@mui/material';
import { MobileDatePicker as MuiMobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { FC } from 'react';

import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { THEME } from '#/constants/theme/constants';

import { useDataCy } from '#/hooks/useDataCy';

import { useDatePickerStyles } from './DatePicker.styles';

type DatePickerProps = {
  value: string | number;
  onChange: (value: Dayjs | null, context?: unknown) => void;
  editMode?: boolean;
  error?: string;
} & (
  | {
      label?: undefined;
      dataCy: string;
    }
  | {
      label: string;
      dataCy?: string;
    }
);

export const MobileDatePicker: FC<DatePickerProps> = ({ label, dataCy, value, onChange, editMode = true, error }) => {
  const classes = useDatePickerStyles();
  const generateDataCy = useDataCy();

  return (
    <div>
      {label && (
        <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} variant="Label_Base">
          {label}
        </TypographyWithFontFamily>
      )}
      <MuiMobileDatePicker
        className={`${!editMode && classes.disabled}`}
        data-cy={generateDataCy('MobileDatePicker', label ?? dataCy)}
        disabled={!editMode}
        onChange={onChange}
        slotProps={{
          field: {
            className: classes.input,
            id: `DatePicker-${label?.replace(' ', '-')}`,
          },
          textField: { size: 'small', fullWidth: true },
        }}
        value={dayjs(value)}
      />
      <FormHelperText error>{error}</FormHelperText>
    </div>
  );
};
