import { createStyles, makeStyles } from '#/utils/theme';

export const useTableCellLinkStyles = makeStyles((theme) =>
  createStyles({
    link: {
      color: theme.custom.PALETTES.TEXT.Action_Secondary,
      textDecoration: 'none',
    },
  }),
);
