import { Box } from '@mui/material';

import { USER_PERMISSIONS } from '../../../../../constants/permissions/userPermissions';
import { getUserHasPermission } from '../../../../../constants/permissions/utils';
import useGetResource from '../../../../../hooks/useGetResource';
import { useGetCampaignTiersInstanceQuery } from '../../../../../store/api/instances/campaignInstanceChannelsAndTactics';
import Loader from '../../../../common/loader/loader';
import CampaignTierInstance from '../../CampaignTierInstance';
import CreateCampaignTierInstance from '../../CreateCampaignTierInstance';
import { useCampaignChannelsAndTacticsStyle } from '../../campaign/channelsAndTactics/RetailerView/CampaignChannelsAndTactics.style';

export const RetailerInstanceChannelAndTactics = () => {
  const userHasCreatePermission = getUserHasPermission(USER_PERMISSIONS.CAMPAIGN_CHANNEL_TACTICS_CREATE);
  const resource = useGetResource();
  const { data, isLoading, isFetching } = useGetCampaignTiersInstanceQuery({ resource });
  const classes = useCampaignChannelsAndTacticsStyle();
  if (isLoading || isFetching) return <Loader />;
  return (
    <Box className={classes.channelAndTacticsContainer}>
      <Box>
        {userHasCreatePermission && <CreateCampaignTierInstance />}
        {data?.tiers?.map((tier) => (
          <Box key={tier.tierName} sx={{ marginTop: '1rem' }}>
            <CampaignTierInstance isOnInstance tier={tier} userHasCreatePermission={userHasCreatePermission} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
