import { productImage } from '#/components/features/campaigns/instances/channelsAndTactics/productsFeed/productFeed.styles';

import { ICampaignInstanceProductsListSingle } from '#/interfaces/campaigns/campaigns';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

export const assetProductTableColumns = (
  data: ICampaignInstanceProductsListSingle[],
  selectedProduct: ICampaignInstanceProductsListSingle[],
  setSelectedProduct: (newValue: ICampaignInstanceProductsListSingle[]) => void,
  sortableColumns: string[] = [],
): ITableColumnsProps<ICampaignInstanceProductsListSingle>[] => [
  {
    fieldName: null,
    align: TableColumnAlign.CENTER,
    type: TableCellTypes.CHECKBOX,
    customHead: ' ',
    checkBoxAction: (checked, universalProductCode) => {
      const currentProduct = data.filter((product) => product.universalProductCode === universalProductCode);
      if (checked) {
        setSelectedProduct(currentProduct);
      } else {
        const newCheckList = selectedProduct.filter((el) => el.universalProductCode !== universalProductCode);
        setSelectedProduct(newCheckList);
      }
    },
    checkFromList: selectedProduct.map((product) => product.universalProductCode),
    actionElementIdentifier: 'universalProductCode',
    showRadioButton: true,
  },
  {
    fieldName: 'selected',
    align: TableColumnAlign.LEFT,
    type: TableCellTypes.STRING,
    customHead: 'Universal Product Code',
    hide: true,
  },
  {
    fieldName: 'universalProductCode',
    align: TableColumnAlign.LEFT,
    type: TableCellTypes.STRING,
    customHead: 'Universal Product Code',
    sortable: sortableColumns.includes('universalProductCode'),
  },
  {
    fieldName: 'productName',
    align: TableColumnAlign.LEFT,
    type: TableCellTypes.STRING,
    customHead: 'Product Name',
    sortable: sortableColumns.includes('productName'),
  },
  {
    fieldName: 'brandName',
    align: TableColumnAlign.LEFT,
    type: TableCellTypes.STRING,
    customHead: 'Brand Name',
    sortable: sortableColumns.includes('brandName'),
  },
  {
    fieldName: 'vendorId',
    align: TableColumnAlign.LEFT,
    type: TableCellTypes.STRING,
    customHead: 'Vendor ID',
    sortable: sortableColumns.includes('vendorId'),
  },
  {
    fieldName: 'shortDescription',
    align: TableColumnAlign.LEFT,
    type: TableCellTypes.STRING,
    customHead: 'Short Description',
    sortable: sortableColumns.includes('shortDescription'),
  },
  {
    fieldName: 'longDescription',
    align: TableColumnAlign.LEFT,
    type: TableCellTypes.STRING,
    customHead: 'Long Description',
    sortable: sortableColumns.includes('longDescription'),
  },
  {
    fieldName: 'category',
    align: TableColumnAlign.LEFT,
    type: TableCellTypes.STRING,
    customHead: 'Category',
    sortable: sortableColumns.includes('category'),
  },
  {
    fieldName: 'subCategory',
    align: TableColumnAlign.LEFT,
    type: TableCellTypes.STRING,
    customHead: 'Sub-Category',
    sortable: sortableColumns.includes('subCategory'),
  },
  {
    fieldName: 'weight',
    align: TableColumnAlign.LEFT,
    type: TableCellTypes.STRING,
    customHead: 'Weight',
    sortable: sortableColumns.includes('weight'),
  },
  {
    fieldName: 'stockKeepingUnit',
    align: TableColumnAlign.LEFT,
    type: TableCellTypes.STRING,
    customHead: 'Stock Keeping Unit',
    sortable: sortableColumns.includes('stockKeepingUnit'),
  },
  {
    fieldName: 'imageLocation',
    align: TableColumnAlign.CENTER,
    customHead: 'Product Image',
    type: TableCellTypes.CUSTOM_ELEMENT,
    actionElementIdentifier: 'imageLocation',
    getCustomElement: (imageLocation) =>
      imageLocation ? (
        <img
          alt={'Product Image'}
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.remove();
          }}
          src={imageLocation}
          style={productImage}
        />
      ) : (
        <div>No image found</div>
      ),
  },
];
