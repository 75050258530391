import { Grid } from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { THEME } from '#/constants/theme/constants';

import { ITacticMetadataDTO, TacticMetadataForm } from '../../../../interfaces/tacticMetadata/tacticMetadata';
import { InfoTooltip } from '../../../features/reports/InfoTooltip';
import Select from '../../inputs/Select/Select';
import { TypographyWithFontFamily } from '../../typography/TypographyWithFontFamily';
import { errorTextSx, useTacticMetadataFormStyle } from '../TacticMetadataForm.style';

interface IProps {
  errors: FieldErrors<TacticMetadataForm>;
  input: ITacticMetadataDTO;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<TacticMetadataForm, any>;
  helperTextSubtitle?: boolean;
  editMode?: boolean;
}

export const DropdownInput = ({ errors, input, control, helperTextSubtitle = false, editMode = true }: IProps) => {
  const options = input.listValues?.map((input) => {
    return {
      value: input.value,
      label: input.displayValue,
    };
  });

  const existingErrors = errors[String(input.id)]?.message || '';
  const classes = useTacticMetadataFormStyle();

  return (
    <Grid container spacing={{ xs: '1rem' }} xs={12}>
      <Grid item xs={9}>
        <TypographyWithFontFamily cName={classes.labelUppercase} color={THEME.PALETTES.TEXT.Gray} variant="Label_Base">
          {input.label} {input.required ? '*' : ''}
        </TypographyWithFontFamily>
        {helperTextSubtitle && (
          <TypographyWithFontFamily gutterBottom variant={'Label_Base'}>
            {input.helperText}
          </TypographyWithFontFamily>
        )}
        <Controller
          control={control}
          name={String(input.id)}
          render={({ field: { onChange, value } }) => (
            <Select
              className={input.isDisabled || !editMode ? classes.selectContainerDisabled : ''}
              editMode={!input.isDisabled && editMode}
              label={''}
              onChange={onChange}
              options={options || []}
              value={value}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        {!helperTextSubtitle && input.helperText && <InfoTooltip text={input.helperText} />}
      </Grid>
      <Grid item xs={12}>
        <TypographyWithFontFamily sx={errorTextSx} variant="Default_Base">
          {String(existingErrors)}
        </TypographyWithFontFamily>
      </Grid>
    </Grid>
  );
};
