import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../../../constants/theme/constants';

export const useAddChannelPartnerUserStyle = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& ::-webkit-scrollbar': {
        width: '2px',
      },
      '& ::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: THEME.PALETTES.UI.Neutral[900],
        outline: `1px solid ${THEME.PALETTES.UI.Neutral[900]}`,
      },
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '66rem',
      height: '52rem',
      background: THEME.PALETTES.OTHER.White,
      overflowY: 'auto',
    },
    headerContainer: {
      background: THEME.PALETTES.UI.Primary[900],
      height: '4rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1rem',
    },
    input: {
      padding: '0',
      width: '100%',
      '& input': { padding: '0 8px', height: '30px' },
      height: '30px',
    },
    selectMenuPaper: {
      maxHeight: '18.75rem',
    },
    modalFormData: {
      padding: THEME.REM_SPACES[1],
    },
    buttonsContainer: {
      padding: THEME.REM_SPACES[1],
      display: 'flex',
      justifyContent: 'flex-end',
      columnGap: THEME.REM_SPACES[0.5],
    },
  }),
);
export const userInfoHeaderSx = {
  margin: `${THEME.REM_SPACES[1]} 0`,
};
export const contactCheckboxContainerSx = {
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'self-start',
};

export const gridSpaceBetweenSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};
export const headerWithSpacingSx = {
  margin: `${THEME.REM_SPACES[1]} 0`,
};
export const gridFlexSx = {
  display: 'flex',
  alignItems: 'flex-end',
};
