import { IDynamicRibbonRequestResponse } from '#/interfaces/dynamicRibbon/dynamicRibbon';

import { baseApi } from '../../base';

const ribbonApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRibbonTasksInstanceAssets: builder.mutation<
      IDynamicRibbonRequestResponse,
      {
        instanceId: string | undefined;
      }
    >({
      query: ({ instanceId }) => `/retailer/ribbon/tasks/instance/${instanceId}/assets`,
    }),
    getRibbonTasksInstanceProducts: builder.mutation<
      IDynamicRibbonRequestResponse,
      {
        instanceId: string | undefined;
      }
    >({
      query: ({ instanceId }) => `/retailer/ribbon/tasks/instance/${instanceId}/products`,
    }),
    getRibbonBrandCampaignInstance: builder.mutation<
      IDynamicRibbonRequestResponse,
      {
        instanceId: string | undefined;
      }
    >({
      query: ({ instanceId }) => `/retailer/ribbon/brand/campaign-instance/${instanceId}`,
    }),
    getRibbonTasksCampaignBrandAdoption: builder.mutation<
      IDynamicRibbonRequestResponse,
      {
        campaignId: string | undefined;
      }
    >({
      query: ({ campaignId }) => `/retailer/ribbon/tasks/campaign/${campaignId}/brand-adoption`,
    }),
  }),
});

export const {
  useGetRibbonTasksInstanceAssetsMutation,
  useGetRibbonTasksInstanceProductsMutation,
  useGetRibbonBrandCampaignInstanceMutation,
  useGetRibbonTasksCampaignBrandAdoptionMutation,
} = ribbonApi;

export { ribbonApi };
