import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { isEqual } from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';

import { useSnackbarError } from '#/components/common/snackbar/useSnackbarError';
import { useSnackbarSuccess } from '#/components/common/snackbar/useSnackbarSuccess';

import useGetResource from '#/hooks/useGetResource';

import { useGetCompanyAccountQuery, usePutEditBillingCompanyMutation } from '#/store/api/company/company';
import { CompanyAccountBillingInfo } from '#/store/api/company/companyInterfaces';

export const useEditCompanyBillingForm = (updateEditMode: React.Dispatch<React.SetStateAction<boolean>>) => {
  const { resourceId } = useGetResource();
  const { data, isFetching, refetch } = useGetCompanyAccountQuery({ companyId: resourceId });
  const [updateBillingCompany, result] = usePutEditBillingCompanyMutation();

  const { setSuccess } = useSnackbarSuccess();
  const { setError } = useSnackbarError();

  const dataDefaultValues = {
    billingCity: data?.billingCity,
    billingState: data?.billingState,
    billingZip: data?.billingZip,
    ein: data?.ein,
    billType: data?.billType,
    w9FormId: data?.w9FormId,
    billingAddress1: data?.billingAddress1,
    billingAddress2: data?.billingAddress2,
  };

  const schema = yup
    .object()
    .shape({
      billingAddress1: yup.string(),
      billingAddress2: yup.string(),
      billingCity: yup.string(),
      billingState: yup.string(),
      billingCountry: yup.string(),
      billingZip: yup.string(),
      ein: yup.string(),
      w9FormId: yup.string().nullable(),
      billType: yup.string().nullable(),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
    formState: { errors, defaultValues },
  } = useForm<CompanyAccountBillingInfo>({
    resolver: yupResolver(schema),
    defaultValues: dataDefaultValues,
  });

  const onSubmit = handleSubmit(async (formData: CompanyAccountBillingInfo) => {
    if (!isEqual(formData, defaultValues)) {
      try {
        const updatedData = { ...formData, country: 'United States' };
        await updateBillingCompany({ data: updatedData, companyId: resourceId }).unwrap();
        setSuccess('Your action is being processed. Please refresh this section if data is not updated.');
        reset(formData);
        updateEditMode(false);
      } catch (error) {
        setError((error as FetchBaseQueryError).data);
      }
    } else {
      updateEditMode(false);
    }
  });

  const onRefetch = async () => {
    try {
      await refetch().unwrap();
      reset(dataDefaultValues);
    } catch (error) {
      setError('We are unable to re fetch the data');
    }
  };

  return {
    onSubmit,
    control,
    reset,
    errors,
    register,
    setValue,
    isLoading: result.isLoading,
    dataLoading: isFetching,
    refetch: onRefetch,
    watch,
    data,
    error: result.error,
  };
};
