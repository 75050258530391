import { Box } from '@mui/material';

import Refetch from '../reFetchComponent/Refetch';
import { tableRefreshStyle } from './TableRefresh.style';

interface IProps {
  refetch: () => void;
  dataLoading: boolean;
  customStyle?: { [key: string]: string };
}

export const TableRefresh = ({ refetch, dataLoading, customStyle }: IProps) => {
  const classes = tableRefreshStyle();

  return (
    <Box className={classes.wrapper} sx={{ ...(customStyle && { ...customStyle }) }}>
      <Refetch isLoading={dataLoading} reFetchFn={refetch} />
    </Box>
  );
};
