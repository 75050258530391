import { PromoteCampaigns } from '#/components/features/communications/PromoteCampaigns';

import { useGetPendingInvitationsFiltersQuery } from '#/store/api/promote/promote';

import Loader from '../../common/loader/loader';

export const PromoteCampaignsContainer = () => {
  const { isLoading: filterDataIsLoading } = useGetPendingInvitationsFiltersQuery({});

  if (filterDataIsLoading) return <Loader />;

  return <PromoteCampaigns />;
};
