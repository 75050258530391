import { ROUTE_PATHS } from '../../../constants/routing/routePaths';

export const tasksSubmenu = [
  {
    label: 'ALL',
    url: `/${ROUTE_PATHS.TASKS}/${ROUTE_PATHS.TASKS_ALL}`,
  },
  {
    label: 'OPEN',
    url: `/${ROUTE_PATHS.TASKS}/${ROUTE_PATHS.TASKS_OPEN}`,
  },
  {
    label: 'CLOSED',
    url: `/${ROUTE_PATHS.TASKS}/${ROUTE_PATHS.TASKS_CLOSED}`,
  },
];
