import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { CompanyAccountAssociatedCompany } from '#/store/api/company/companyInterfaces';

import { getBrandAddress } from '#/utils/utilsReact';

export const getTableColumns = (
  associatedCompanies: CompanyAccountAssociatedCompany[] = [],
  selectedBrands: string[],
  setSelectedBrands: React.Dispatch<React.SetStateAction<string[]>>,
) => {
  const tableColumns: ITableColumnsProps<CompanyAccountAssociatedCompany>[] = [
    {
      fieldName: null,
      align: TableColumnAlign.RIGHT,
      type: TableCellTypes.CHECKBOX,
      hasCheckAll: true,
      checkAllValue: selectedBrands.length !== 0,
      checkAllAction: (checked) => {
        if (checked) {
          setSelectedBrands(associatedCompanies.map((company) => company.aggregateId));
        } else {
          setSelectedBrands([]);
        }
      },
      checkBoxAction: (checked, aggregateId) => {
        if (checked) {
          setSelectedBrands([...selectedBrands, aggregateId]);
        } else {
          setSelectedBrands(selectedBrands.filter((el) => el !== aggregateId));
        }
      },
      actionElementIdentifier: 'aggregateId',
      checkFromList: selectedBrands,
    },
    {
      fieldName: 'companyName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Company',
    },
    {
      fieldName: null,
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Address',
      actionElementIdentifier: 'mailingAddress1',
      actionElementSecondIdentifier: 'city',
      actionElementThirdIdentifier: 'state',
      actionElementFourthIdentifier: 'zip',
      getCustomElement: getBrandAddress,
    },
  ];
  return tableColumns;
};
