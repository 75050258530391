import { Box } from '@mui/material';
import { useParams } from 'react-router';

import SearchFilters from '#/components/common/SearchFilters/SearchFilters';
import useSearchFilters from '#/components/common/SearchFilters/useSearchFilters.hook';
import { CampaignListModes } from '#/components/common/TabModeToggle';
import Loader from '#/components/common/loader/loader';
import getCampaignInstancesRetailerTableColumns from '#/components/features/campaigns/instances/list/CampaignInstancesRetailer/CampaignInstancesRetailer.columns';

import { useGetInstancesRetailerFilterQuery, useGetInstancesRetailerQuery } from '#/store/api/campaigns/instances';

import { Table } from '../../../../../common/table/tableComponent/Table';
import { BrandOrderGeneralInformation } from './components/BrandOrderGeneralInformation/BrandOrderGeneralInformation';

export const CampaignInstancesRetailer = () => {
  const { campaignId } = useParams();

  const { data: filterData, isError: filterDataIsError } = useGetInstancesRetailerFilterQuery({ campaignId });

  const { actions, state } = useSearchFilters(
    {
      viewMode: CampaignListModes.LIST,
      filters: [{ key: 'status', value: 'BRAND_APPLY', operation: '!=' }],
      alwaysActiveFilters: [],
    },
    true,
    filterData?.filters,
  );

  const {
    data: instancesData,
    isError: instancesIsError,
    isFetching: instancesIsFetching,
    isLoading: instancesIsLoading,
  } = useGetInstancesRetailerQuery({
    campaignId,
    pageNumber: state.pageNumber,
    numberOfRows: state.numberOfElementsPerPage,
    searchQuery: state.searchQuery,
  });

  if (instancesIsLoading) return <Loader />;

  const columns = getCampaignInstancesRetailerTableColumns(filterData ? filterData.filters : []);

  return (
    <Box>
      <BrandOrderGeneralInformation />
      <SearchFilters
        columnNames={filterData?.filters}
        currentIndex={instancesData?.currentIndex}
        filterDataIsError={filterDataIsError}
        hideViewMode
        isError={instancesIsError}
        isFetching={instancesIsFetching}
        isNoResults={!instancesData || instancesData.instances.length === 0}
        totalPages={instancesData?.totalPages}
        totalResults={instancesData?.totalResults}
        {...state}
        {...actions}
      >
        <Table
          columns={columns}
          data={instancesData?.instances || []}
          displayStatusColor
          sortProps={{
            sortParams: state.sortParams,
            setSortParams: actions.setSortParam,
          }}
        />
      </SearchFilters>
    </Box>
  );
};
