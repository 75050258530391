import UserTypeWrapper from '#/utils/UserTypeWrapper';

import { BrandInstanceChannelsAndTactics } from './BrandInstanceChannelsAndTactics';
import ChannelPartnerInstanceChannelAndTactics from './ChannelPartnerInstanceChannelAndTactics';
import { RetailerInstanceChannelAndTactics } from './RetailerInstanceChannelAndTactics';

export const CampaignInstanceChannelsAndTactics = () => {
  return (
    <UserTypeWrapper
      agency={<BrandInstanceChannelsAndTactics />}
      brand={<BrandInstanceChannelsAndTactics />}
      channel-partner={<ChannelPartnerInstanceChannelAndTactics />}
      retailer={<RetailerInstanceChannelAndTactics />}
    />
  );
};
