import { EditGeneralOverview, Task } from '#/store/api/tasks/interfaces';

import { baseApi } from '../../base';

const tasksApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query<Task[], { status: string }>({
      query: ({ status }) => {
        switch (status) {
          case 'open':
            return 'retailer/task/open/all';
          case 'closed':
            return 'retailer/task/closed/all';
          default:
            return 'retailer/task/all';
        }
      },
      providesTags: ['CURRENT_TASK'],
    }),
    getTask: builder.query({
      query: ({ taskId }) => `retailer/task/${taskId}`,
    }),

    putTask: builder.mutation<void, { taskId: string | undefined; body: EditGeneralOverview }>({
      query: ({ taskId, body }) => ({
        url: `retailer/task/${taskId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CURRENT_TASK']),
    }),
  }),
});

export const { useGetTasksQuery, useGetTaskQuery, usePutTaskMutation } = tasksApi;

export { tasksApi };
