import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../../../../../../constants/theme/constants';

export const useBrandOrderGeneralInformationStyle = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '30%',
      minWidth: THEME.REM_SPACES[15],
      border: `1px solid ${THEME.PALETTES.BORDER.Light}`,
      marginBottom: THEME.REM_SPACES[1],
      padding: THEME.REM_SPACES[0.5],
      borderRadius: '16px',
    },
  }),
);
