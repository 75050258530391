import { createStyles, makeStyles } from '#/utils/theme';

export const useSearchInputsStyles = makeStyles((theme) =>
  createStyles({
    selectTextComboContainer: {
      display: 'flex',
      flexDirection: 'row',
      flex: '1',
    },
    select: {
      width: '25%',
      paddingLeft: theme.custom.REM_SPACES[0.25],
      '&& .MuiSelect-select': {
        width: '100%',
      },
      alignSelf: 'center',
    },
    input: {
      width: '50%',
      paddingLeft: theme.custom.REM_SPACES[1],
      paddingRight: theme.custom.REM_SPACES[1],
      alignSelf: 'center',
    },
    inputLarge: {
      width: '75%',
    },
    secondDropdown: {
      width: '50%',
      paddingLeft: theme.custom.REM_SPACES[1],
      paddingRight: theme.custom.REM_SPACES[1],
      alignSelf: 'center',
    },
    datePicker: {
      width: '50%',
      paddingLeft: theme.custom.REM_SPACES[1],
      paddingRight: theme.custom.REM_SPACES[1],
      display: 'flex',

      borderColor: 'white',
    },
    intervalPicker: {
      width: '50%',
      paddingLeft: theme.custom.REM_SPACES[1],
      paddingRight: theme.custom.REM_SPACES[1],
      display: 'flex',

      borderColor: 'white',
    },
    clearButton: {
      marginLeft: theme.custom.REM_SPACES[0.5],
      marginTop: theme.custom.REM_SPACES[0.25],
    },
  }),
);
