import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useSnackbarError } from '../../../components/common/snackbar/useSnackbarError';
import { IAddTierForm, IChannelAndTacticsResponseTier } from '../../../interfaces/forms/campaigns';
import {
  useGetCampaignTiersQuery,
  usePostCampaignTierMutation,
  usePutCampaignTierMutation,
} from '../../../store/api/campaigns/campaignChannelsAndTactics';
import useGetResource from '../../useGetResource';

export const useAddTireForm = (close?: () => void, tier?: IChannelAndTacticsResponseTier) => {
  const resource = useGetResource();
  const [triggerPost, { isLoading: isPostCampaignTierLoading, error: triggerPostError }] =
    usePostCampaignTierMutation();
  const [triggerPut, { isLoading: isPutCampaignTierLoading, error: triggerPutError }] = usePutCampaignTierMutation();

  const error = triggerPostError || triggerPutError;

  const { refetch, isFetching } = useGetCampaignTiersQuery({ resource });
  const schema = yup
    .object()
    .shape({
      tierName: yup.string().required('Tier name is required'),
      targetBudget: yup.number().typeError('Target budget must be a number').required('Target budget is required'),
      targetBrands: yup.number().typeError('Target brands must be a number').required('Target brands is required'),
    })
    .required();

  const {
    register,
    clearErrors,
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors, defaultValues },
    setValue,
  } = useForm<IAddTierForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      tierName: tier ? tier?.tierName : '',
      targetBudget: tier ? tier?.targetBudget : 0,
      targetBrands: tier ? tier?.numberOfBrands : 0,
    },
  });
  const [errorMessage] = useState('');
  const { setError } = useSnackbarError();

  const onSubmit = handleSubmit(async (formData: IAddTierForm) => {
    if (!isEqual(formData, defaultValues)) {
      try {
        await triggerPost({
          resource,
          body: { ...formData, targetTotal: formData.targetBrands * formData.targetBudget },
        }).unwrap();

        reset();
      } catch (error) {
        setError(error);
      }
      clearErrors();
    }
  });

  const onSubmitEdit = handleSubmit(async (formData: IAddTierForm) => {
    if (!isEqual(formData, defaultValues)) {
      try {
        await triggerPut({
          body: { ...formData, targetTotal: formData.targetBrands * formData.targetBudget, tierId: tier?.tierId || '' },
        }).unwrap();
        reset();
        close?.();
      } catch (error) {
        setError(error);
      }
      clearErrors();
    } else {
      close?.();
    }
  });

  return {
    register,
    onSubmit,
    errors,
    errorMessage,
    reset,
    control,
    watch,
    onSubmitEdit,
    refetch,
    isPostCampaignTierLoading,
    isPutCampaignTierLoading,
    dataLoading: isFetching,
    setValue,
    error: error,
  };
};
