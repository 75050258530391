import { Button, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';

import MoreIcon from '../../../../../assets/MoreIcon.svg';
import { IMoreActions } from '../../../../../interfaces/table/table';

export const MoreOptions = ({ actions, itemIdentifier }: { actions?: IMoreActions[]; itemIdentifier?: string }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        id="demo-positioned-button"
        onClick={handleClick}
      >
        <img src={MoreIcon} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        aria-labelledby="demo-positioned-button"
        id="demo-positioned-menu"
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {actions?.map((action) => (
          <MenuItem
            key={action.name}
            onClick={() => {
              if (action.action) {
                action.action(itemIdentifier || '');
              }
              handleClose();
            }}
          >
            {action.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
