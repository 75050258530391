import { FC } from 'react';

import { FiltersActions, FiltersState } from '#/components/common/SearchFilters/SearchFilters.types';
import { CalendarModes, CampaignListModes } from '#/components/common/TabModeToggle';
import { Table } from '#/components/common/table/tableComponent/Table';
import { useProductFeedStyles } from '#/components/features/campaigns/instances/channelsAndTactics/productsFeed/productFeed.styles';

import { ICampaignInstanceProductsListSingle } from '#/interfaces/campaigns/campaigns';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

interface IProductFeedTable {
  data: ICampaignInstanceProductsListSingle[];
  editable?: boolean;
  upnCodesViewInstanceId: string;
  selectedBrands: string[];
  setSelectedBrands: React.Dispatch<React.SetStateAction<string[]>>;
  actions: FiltersActions;
  state: FiltersState<CampaignListModes | CalendarModes>;
  sortableColumns: string[];
}

const ProductFeedTable: FC<IProductFeedTable> = ({
  data,
  editable = false,
  selectedBrands,
  setSelectedBrands,
  actions,
  state,
  sortableColumns,
}) => {
  const classes = useProductFeedStyles();

  const productFeedTableColumns: ITableColumnsProps<ICampaignInstanceProductsListSingle>[] = [
    {
      hide: !editable,
      fieldName: null,
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.CHECKBOX,
      customHead: ' ',
      checkBoxAction: (checked, universalProductCode) => {
        if (checked) {
          setSelectedBrands([...selectedBrands, universalProductCode]);
        } else {
          const newCheckList = selectedBrands.filter((el) => el !== universalProductCode);
          setSelectedBrands(newCheckList);
        }
      },
      hasCheckAll: true,
      checkFromList: selectedBrands,
      checkAllAction: (checked) => {
        const dataIds = data.map((brand) => brand.universalProductCode);

        if (checked) {
          setSelectedBrands(dataIds);
        } else {
          setSelectedBrands([]);
        }
      },
      actionElementIdentifier: 'universalProductCode',
    },
    {
      fieldName: 'selected',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Universal Product Code',
      hide: true,
    },
    {
      fieldName: 'universalProductCode',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Universal Product Code',
      sortable: sortableColumns.includes('universalProductCode'),
    },
    {
      fieldName: 'productName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Product Name',
      sortable: sortableColumns.includes('productName'),
    },
    {
      fieldName: 'brandName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Brand Name',
      sortable: sortableColumns.includes('brandName'),
    },
    {
      fieldName: 'vendorId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Vendor ID',
      sortable: sortableColumns.includes('vendorId'),
    },
    {
      fieldName: 'shortDescription',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Short Description',
      sortable: sortableColumns.includes('shortDescription'),
    },
    {
      fieldName: 'longDescription',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Long Description',
      sortable: sortableColumns.includes('longDescription'),
    },
    {
      fieldName: 'category',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Category',
      sortable: sortableColumns.includes('category'),
    },
    {
      fieldName: 'subCategory',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Sub-Category',
      sortable: sortableColumns.includes('subCategory'),
    },
    {
      fieldName: 'weight',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Weight',
      sortable: sortableColumns.includes('weight'),
    },
    {
      fieldName: 'stockKeepingUnit',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Stock Keeping Unit',
      sortable: sortableColumns.includes('stockKeepingUnit'),
    },
    {
      fieldName: 'imageLocation',
      align: TableColumnAlign.CENTER,
      customHead: 'Product Image',
      type: TableCellTypes.CUSTOM_ELEMENT,
      actionElementIdentifier: 'imageLocation',
      getCustomElement: (imageLocation) =>
        imageLocation ? (
          <img
            alt={'Product Image'}
            className={classes.imageFix}
            onError={(e) => {
              e.currentTarget.onerror = null;
              e.currentTarget.remove();
            }}
            src={imageLocation}
          />
        ) : (
          <div>No image found</div>
        ),
    },
  ];

  return (
    <Table
      columns={productFeedTableColumns}
      data={data}
      sortProps={{
        sortParams: state.sortParams,
        setSortParams: actions.setSortParam,
      }}
    />
  );
};

export default ProductFeedTable;
