import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUser } from '#/store/api/users/usersInterfaces';

type BasicUser = Omit<IUser, 'permissions'>;

type UserState = {
  user: BasicUser | null;
  permissions: IUser['permissions'];
  authToken?: string;
};

const initialState: UserState = {
  user: null,
  permissions: [],
  authToken: undefined,
};

export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    logout: () => {
      return initialState;
    },
    setUser: (state, action: PayloadAction<UserState['user']>) => {
      state.user = action.payload;
    },
    setPermissions: (state, action: PayloadAction<UserState['permissions']>) => {
      state.permissions = action.payload;
    },
    setAuthToken: (state, action: PayloadAction<UserState['authToken']>) => {
      state.authToken = action.payload;
    },
  },
});

export const { actions } = userSlice;

export default userSlice;
