import { Box, FormControl, MenuItem, OutlinedInput, Select as SelectMUI } from '@mui/material';
import classnames from 'classnames';
import { FC } from 'react';

import { useCampaignInvitationsStyles } from '#/components/campaignInvitations/CampaignInvitations.styles';
import { useInputsStyle } from '#/components/common/inputs/Inputs.styles';
import { useSelectStyle } from '#/components/common/inputs/Select/Select.styles';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { THEME } from '#/constants/theme/constants';

import { useGetProductMixAllCategoriesQuery } from '#/store/api/productMix/productMix';

interface IProductMixSelectProps {
  handleChange: (value: string | string[], updateFilterName: string) => void;
  additionalCategoryIds: string[];
  additionalSubCategoryIds: string[];
  additionalAttributeIds: string[];
}

const ProductMixSelect: FC<IProductMixSelectProps> = ({
  handleChange,
  additionalCategoryIds,
  additionalSubCategoryIds,
  additionalAttributeIds,
}) => {
  const classes = useCampaignInvitationsStyles();
  const inputsClasses = useInputsStyle();
  const selectClasses = useSelectStyle();

  const { data } = useGetProductMixAllCategoriesQuery();

  return (
    <Box className={classes.mb1}>
      <TypographyWithFontFamily className={classes.customLabel} color={THEME.PALETTES.TEXT.Gray} variant="Label_Base">
        Product Mix
      </TypographyWithFontFamily>
      <Box>
        <Box className={classes.productMixWrapper}>
          <TypographyWithFontFamily>Category</TypographyWithFontFamily>
          <FormControl className={classes.productMixWidth}>
            <SelectMUI
              className={classnames(
                inputsClasses['Default'],
                inputsClasses,
                selectClasses.base,
                classes.customMuiSelect,
              )}
              input={<OutlinedInput />}
              multiple
              onChange={(e) => handleChange(e.target.value, 'additionalCategoryIds')}
              size="small"
              value={additionalCategoryIds}
            >
              {data?.categories?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.categoryName}
                </MenuItem>
              ))}
            </SelectMUI>
          </FormControl>
        </Box>
      </Box>
      <Box>
        <Box className={classes.productMixWrapper}>
          <TypographyWithFontFamily>Sub Category</TypographyWithFontFamily>
          <FormControl className={classes.productMixWidth}>
            <SelectMUI
              className={classnames(inputsClasses, selectClasses.base, classes.customMuiSelect)}
              input={<OutlinedInput />}
              multiple
              onChange={(e) => handleChange(e.target.value, 'additionalSubCategoryIds')}
              size="small"
              value={additionalSubCategoryIds}
            >
              {data?.subcategories?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.subcategoryName}
                </MenuItem>
              ))}
            </SelectMUI>
          </FormControl>
        </Box>
      </Box>
      <Box>
        <Box className={classes.productMixWrapper}>
          <TypographyWithFontFamily>Attributes</TypographyWithFontFamily>
          <FormControl className={classes.productMixWidth}>
            <SelectMUI
              className={classnames(
                inputsClasses['Default'],
                inputsClasses,
                selectClasses.base,
                classes.customMuiSelect,
              )}
              input={<OutlinedInput />}
              multiple
              onChange={(e) => handleChange(e.target.value, 'additionalAttributeIds')}
              size="small"
              value={additionalAttributeIds}
            >
              {data?.attributes?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.attributeName}
                </MenuItem>
              ))}
            </SelectMUI>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductMixSelect;
