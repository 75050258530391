import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import TaskIcon from '../../../assets/TaskIconPage.svg';
import DisplayPageName from '../../common/displayPageName/DisplayPageName';
import { useTasksHeaderStyles } from './TasksHeader.styles';

const TasksHeader = () => {
  const classes = useTasksHeaderStyles();

  return (
    <Box className={classes.section}>
      <DisplayPageName imgSrc={TaskIcon} pageName="Tasks" />
      <Outlet />
    </Box>
  );
};

export default TasksHeader;
