import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../constants/theme/constants';

export const useEditComboButtonStyles = makeStyles(() =>
  createStyles({
    editContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

export const styles = {
  saveButton: {
    marginLeft: THEME.REM_SPACES['0.25'],
  },
};
