import { Box, Chip } from '@mui/material';
import { useMemo, useState } from 'react';

import { useDataCy } from '#/hooks/useDataCy';

import {
  useDeleteProductMixSubcategoryMutation,
  usePostProductMixSubcategoryMutation,
} from '#/store/api/productMix/productMix';

import { getProductMixSubCategories } from '../../../../../api/productMix';
import { useRequest } from '../../../../../hooks/useRequest';
import NewButton from '../../../../common/button/NewButton';
import Loader from '../../../../common/loader/loader';
import { TypographyWithFontFamily } from '../../../../common/typography/TypographyWithFontFamily';
import ProductMixInput from '../ProductMixInput/ProductMixInput';
import { titleSX, useProductMixStyles } from '../productMix.styles';

const ProductMixSubCategoriesTable = ({ selectedId }: { selectedId: string }) => {
  const [postProductMixSubcategory, { isLoading: isPostProductMixSubcategoryLoading }] =
    usePostProductMixSubcategoryMutation({
      fixedCacheKey: 'postProductMixSubcategory',
    });
  const [deleteProductMixCategory, { isLoading: idDeleteProductMixCategoryLoading }] =
    useDeleteProductMixSubcategoryMutation({ fixedCacheKey: 'deleteProductMixCategory' });

  const isLoading = isPostProductMixSubcategoryLoading || idDeleteProductMixCategoryLoading;

  const styles = useProductMixStyles();
  const generateDataCy = useDataCy();

  const [value, setValue] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);

  const getProductMixSubCategoriesRequest = useMemo(() => {
    if (selectedId) {
      return getProductMixSubCategories(selectedId);
    }
  }, [selectedId, isLoading]);

  const { response } = useRequest(getProductMixSubCategoriesRequest);

  const handleAddProductMix = async () => {
    await postProductMixSubcategory({ categoryId: selectedId, subcategoryName: value }).unwrap();
    setSubmitted(true);
  };
  const onDeleteSubCategory = async (id) => {
    deleteProductMixCategory({ categoryId: selectedId, subCategoryId: id });
  };
  return (
    <Box className={`${!selectedId ? styles.disabled : styles.active}`}>
      <TypographyWithFontFamily sx={titleSX} variant="H3_Base">
        Sub Categories
      </TypographyWithFontFamily>
      <Box className={styles.flexDiv} data-cy={generateDataCy('box', 'subCategoriesButtonContainer')}>
        <ProductMixInput setValue={setValue} submitted={submitted} value={value} />
        <NewButton className={styles.buttonWidth} disabled={!value} onClick={handleAddProductMix} text="Add" />
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!response?.data.length ? (
            <Box className={styles.marginY}>Please select a category</Box>
          ) : (
            <Box className={styles.chipsContainer} data-cy={generateDataCy('box', 'subcategoriesChipContainer')}>
              {response?.data?.map((subCategory) => (
                <Chip
                  key={subCategory.id}
                  className={styles.chipItem}
                  label={<TypographyWithFontFamily>{subCategory.subcategoryName}</TypographyWithFontFamily>}
                  onDelete={() => onDeleteSubCategory(subCategory.id)}
                />
              ))}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ProductMixSubCategoriesTable;
