import { FC } from 'react';

import { useGetRetailerCalendarQuery } from '../../../store/api/campaigns/campaigns';
import CampaignDetailRetailer from '../../common/CampaignDetail/CampaignDetailRetailer';
import Loader from '../../common/loader/loader';

interface EventModalRetailerContainerProps {
  campaignId: string;
}

const EventModalRetailerContainer: FC<EventModalRetailerContainerProps> = ({ campaignId }) => {
  const { data, isLoading } = useGetRetailerCalendarQuery({ campaignId });

  if (isLoading) return <Loader />;

  if (!data) return null;

  return <CampaignDetailRetailer data={data} />;
};
export default EventModalRetailerContainer;
