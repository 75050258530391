import { MenuElement } from '#/components/common/sideMenu/MenuElementInterfaces';

import CalendarIcon from '../../../assets/CalendarIcon.svg';
import DashboardIcon from '../../../assets/DashboardIcon.svg';
import MegaphoneIcon from '../../../assets/MegaphoneIcon.svg';
import PartnersIcon from '../../../assets/PartnersIcon.svg';
import PromoteIcon from '../../../assets/Promote.svg';
import ReportsIcon from '../../../assets/ReportsMenu.svg';
import SettingsIcon from '../../../assets/SettingsIcon.svg';
import TaskIcon from '../../../assets/TaskIcon.svg';
import { ALL_USER_PERMISSION, USER_PERMISSIONS } from '../../../constants/permissions/userPermissions';
import { ROUTE_PATHS } from '../../../constants/routing/routePaths';

export const menuElements: MenuElement[] = [
  {
    route: ROUTE_PATHS.ROOT,
    displayedText: 'Dashboard',
    locationIdentifier: '',
    permission: ALL_USER_PERMISSION,
    icon: DashboardIcon,
  },
  {
    route: `${ROUTE_PATHS.TASKS}/${ROUTE_PATHS.TASKS_OPEN}`,
    displayedText: 'Tasks',
    permission: USER_PERMISSIONS.WORKFLOW,
    icon: TaskIcon,
  },
  {
    route: ROUTE_PATHS.CALENDAR,
    displayedText: 'Calendar',
    permission: USER_PERMISSIONS.CAMPAIGN_OVERVIEW,
    icon: CalendarIcon,
  },
  {
    route: `${ROUTE_PATHS.CAMPAIGNS}/${ROUTE_PATHS.CAMPAIGNS_ALL}`,
    displayedText: 'Campaigns',
    permission: USER_PERMISSIONS.CAMPAIGNS,
    icon: MegaphoneIcon,
  },
  {
    route: `${ROUTE_PATHS.COMMUNICATIONS}/${ROUTE_PATHS.CAMPAIGNS_PROMOTE}`,
    displayedText: 'Communications',
    permission: USER_PERMISSIONS.PROMOTE,
    icon: PromoteIcon,
  },
  {
    route: `${ROUTE_PATHS.PARTNERS}/${ROUTE_PATHS.COMPANIES}`,
    displayedText: 'Partners',
    permission: USER_PERMISSIONS.PARTNERS,
    icon: PartnersIcon,
  },
  {
    route: ROUTE_PATHS.REPORTS,
    displayedText: 'Reports',
    permission: USER_PERMISSIONS.REPORTS,
    icon: ReportsIcon,
  },
  {
    route: `${ROUTE_PATHS.SETTINGS}/${ROUTE_PATHS.SETTINGS_JOBS}`,
    displayedText: 'Settings',
    permission: USER_PERMISSIONS.SETTINGS,
    icon: SettingsIcon,
  },
];
