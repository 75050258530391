import format from 'string-template';

import { IPostDynamicRibbonBody } from '#/interfaces/dynamicRibbon/dynamicRibbon';

import { rootAxios } from './axiosConfig';

export const putDynamicRibbon = (uri: string, resource: IPostDynamicRibbonBody) => {
  return rootAxios.put(`${uri}`, resource);
};

export const postDynamicRibbon = (uri: string, resource: IPostDynamicRibbonBody) => {
  return rootAxios.post(`${uri}`, resource);
};

export const getDynamicRibbon = (uri: string, resource: IPostDynamicRibbonBody) => {
  const customUri = format(uri, { ...resource });
  return rootAxios.get(customUri);
};
