import { Box } from '@mui/system';
import { FC } from 'react';

import ImageDownloadUpload from '../downloadUpload/ImageDownloadUpload';
import { useCampaignThumbnailStyles } from './CampaignThumbnail.styles';

type CampaignThumbnailProps = {
  thumbnailId: number | null;
};

const CampaignThumbnail: FC<CampaignThumbnailProps> = ({ thumbnailId = '' }) => {
  const classes = useCampaignThumbnailStyles();
  return (
    <Box className={classes.thumbnailSection}>
      {thumbnailId?.toString() ? (
        <ImageDownloadUpload fileId={thumbnailId?.toString()} />
      ) : (
        <>
          <img alt="default logo" className={classes.image} src={`/custom/DefaultLogo.png`} />
        </>
      )}
    </Box>
  );
};

export default CampaignThumbnail;
