import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import CheckGreyIcon from '#/assets/Megaphone.svg';

import DisplayPageName from '#/components/common/displayPageName/DisplayPageName';
import Loader from '#/components/common/loader/loader';
import { NoDataMessage } from '#/components/common/noDataMessage/NoDataMessage';
import { useSnackbarError } from '#/components/common/snackbar/useSnackbarError';
import { Table } from '#/components/common/table/tableComponent/Table';
import { generateTableProps, generateUrl } from '#/components/features/reports/utils';

import { PendingCampaignsFields } from '#/interfaces/reports/reports';

import { useGetPendingCampaignsQuery } from '#/store/api/dashboard/dashboard';

import { headClass, pageNameCustomStyle, usePendingCampaignStyles } from '../Dashboard.styles';

export const PendingCampaigns = () => {
  const classes = usePendingCampaignStyles();

  const { setError } = useSnackbarError();

  const { data, isLoading, isFetching, error, isError, refetch } = useGetPendingCampaignsQuery();

  if (isLoading) {
    return (
      <Box className={classes.wrapperDiv}>
        <Loader />
      </Box>
    );
  }

  if (isError) {
    setError(error);
  }

  if (!data) {
    return <NoDataMessage dataLoading={isFetching} message="No Pending Campaigns Available" refetch={refetch} />;
  }

  const { tableColumns, tableValues } = generateTableProps(data, true);
  const redirectTo = generateUrl<PendingCampaignsFields>(data, 'redirectReportId');

  return (
    <Box className={classes.wrapperDiv}>
      <DisplayPageName
        customStyles={pageNameCustomStyle}
        dataLoading={isFetching}
        imgSrc={CheckGreyIcon}
        pageName="Pending Campaigns"
        redirectTo={redirectTo}
        refetch={refetch}
      />
      <Table
        bodyCellClass={classes.noBorder}
        columns={tableColumns}
        data={tableValues}
        headCustomStyle={headClass}
        tableClass={classes.noBorder}
      />
      {data.data.length > 5 && redirectTo && (
        <Link className={classes.showAll} to={redirectTo}>
          ...Show more
        </Link>
      )}
    </Box>
  );
};
