import interactionPlugin from '@fullcalendar/interaction';
import multimonth from '@fullcalendar/multimonth';
import FullCalendar from '@fullcalendar/react';
import { Box, InputAdornment } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { flow } from 'fp-ts/function';
import { FC, useState } from 'react';

import AlertError from '#/components/common/Alert/AlertError/AlertError';
import TextField from '#/components/common/inputs/TextField/TextField';
import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';

import { createFormatterWithReverse, extractNumber, numberFormatter } from '#/utils/textFormatters';

import { THEME } from '../../../../constants/theme/constants';
import { useAddNewCampaignForm } from '../../../../hooks/forms/campaigns/useAddCampaignForm';
import { IPeriod } from '../../../../store/api/periods/periodsInterfaces';
import { IRetailerAccountCalendar } from '../../../../store/api/retailerAccount/retailerAccountInterfaces';
import Cell from '../../../Calendar/Cell';
import getWeekNumberContent, { CalendarLocationEnum } from '../../../Calendar/getWeekNumberContent';
import useCalendarYear from '../../../Calendar/useCalendarYear.hook';
import NewButton from '../../button/NewButton';
import { TypographyWithFontFamily } from '../../typography/TypographyWithFontFamily';
import CalendarDatePickerInputCombo from './CalendarDatePickerInputCombo';
import NewCampaignIntervalIndicator from './NewCampaignIntervalIndicator';
import { style, useAddNewCampaignStyles } from './addNewCampaign.styles';
import useNewCampaignInterval from './useNewCampaignInterval.hook';

interface IAddNewCampaign {
  handleClose: () => void;
  retailerAccountCalendar: IRetailerAccountCalendar | undefined;
  periods: IPeriod[] | undefined;
}

const AddNewCampaign: FC<IAddNewCampaign> = ({ handleClose, periods, retailerAccountCalendar }) => {
  const { onSubmit, errors, register, control, setValue, watch, trigger, isLoading, error } = useAddNewCampaignForm();

  const isViewSelectEnabled = Boolean(periods) && Boolean(retailerAccountCalendar);

  const { initialDate, startDayOfWeek, calendarKey, nextYearHandler, previousYearHandler } = useCalendarYear(
    isViewSelectEnabled,
    retailerAccountCalendar?.retailerPeriods,
  );

  const {
    newCampaignIntervalEvent,
    selectingDate,
    setSelectionStart,
    setSelectionEnd,
    onDateSelect,
    onStartChange,
    onEndChange,
    setSubmissionDeadline,
    onSubmissionDeadlineSelect,
  } = useNewCampaignInterval(setValue, trigger, watch);
  const isDatesError = errors.submissionDeadline || errors.startDate || errors.endDate;
  const getDatesErrorMessage = () => {
    if (errors.startDate) {
      return `Start date must be earlier than ${dayjs(
        errors.startDate?.message?.split('startDate field must be at earlier than ')[1],
      ).format('MM-DD-YYYY')}`;
    }
    if (errors.endDate) {
      return `End date must be later than ${dayjs(
        errors.endDate?.message?.split('endDate field must be later than ')[1],
      ).format('MM-DD-YYYY')}`;
    }
    if (errors.submissionDeadline) {
      return `Submission deadline date must be earlier than ${dayjs(
        errors.submissionDeadline?.message?.split('submissionDeadline field must be at earlier than ')[1],
      ).format('MM-DD-YYYY')}`;
    }
    return '';
  };

  const [isCalendarVisible, setCalendarVisible] = useState(true);
  const classes = useAddNewCampaignStyles();

  return (
    <form onSubmit={onSubmit}>
      <Box>
        <AlertError error={error} />
        <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Dark} sx={style.campaignName} variant="H5_Base">
          Campaign Name
        </TypographyWithFontFamily>
        <TextFieldOld
          dataCy="campaign-name"
          editMode
          error={errors.name?.message}
          label=""
          placeholder="Please name your campaign (ex. 4th of July)"
          register={register('name')}
        />

        <Box className={classes.row}>
          <Box className={classes.inputContainer}>
            <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Dark} sx={style.field} variant="H5_Base">
              Target Goal
            </TypographyWithFontFamily>
            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              dataCy={'campaign-goal'}
              error={errors.goal?.message}
              formHooks={{
                register: register('goal'),
                setValue: (value) => setValue('goal', value),
              }}
              formatters={{
                typing: createFormatterWithReverse(flow(extractNumber, numberFormatter), extractNumber),
              }}
              label=""
            />
          </Box>
          <Box className={classes.inputContainer}>
            <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Dark} sx={style.field} variant="H5_Base">
              Target Number of Brands
            </TypographyWithFontFamily>
            <TextField
              dataCy="campaign-brands-targeted"
              error={errors.brandsTargeted?.message}
              formHooks={{
                register: register('brandsTargeted'),
                setValue: (value) => setValue('brandsTargeted', parseFloat(value)),
              }}
              formatters={{
                typing: createFormatterWithReverse(flow(extractNumber, numberFormatter), extractNumber),
              }}
              label=""
            />
          </Box>
        </Box>
        <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Dark} sx={style.date} variant="H5_Base">
          Dates
        </TypographyWithFontFamily>
        <Box className={classNames(classes.row, { [classes.lastRow]: !isDatesError })}>
          <CalendarDatePickerInputCombo
            control={control}
            isPickerVisible={isCalendarVisible}
            label="CAMPAIGN START"
            name="startDate"
            onChange={onStartChange}
            selectingDate={selectingDate}
            setSelection={setSelectionStart}
          />
          <CalendarDatePickerInputCombo
            control={control}
            isPickerVisible={isCalendarVisible}
            label="CAMPAIGN END"
            name="endDate"
            onChange={onEndChange}
            selectingDate={selectingDate}
            setSelection={setSelectionEnd}
          />
          <CalendarDatePickerInputCombo
            control={control}
            isPickerVisible={isCalendarVisible}
            label="SUBMISSION DEADLINE"
            legendColor={THEME.PALETTES.UI.Secondary.Base}
            name="submissionDeadline"
            onChange={onSubmissionDeadlineSelect}
            selectingDate={selectingDate}
            setSelection={setSubmissionDeadline}
          />
        </Box>
        {isDatesError && (
          <TypographyWithFontFamily
            classNames={classNames(classes.errorMessage, { [classes.lastRow]: isDatesError })}
            variant="Small_Base"
          >
            {getDatesErrorMessage()}
          </TypographyWithFontFamily>
        )}
      </Box>
      {isCalendarVisible && (
        <Box className={classNames(classes.calendarHeader)}>
          <FullCalendar
            key={calendarKey}
            aspectRatio={2.25}
            customButtons={{
              leftCustomButton: {
                text: 'Prev',
                click: previousYearHandler,
              },
              rightCustomButton: {
                text: 'Next',
                click: nextYearHandler,
              },
            }}
            dateClick={(dateData) => onDateSelect(dateData.date)}
            dayCellContent={(dayCell) => <Cell dayCell={dayCell} periods={periods} />}
            dragScroll
            eventContent={<NewCampaignIntervalIndicator />}
            events={newCampaignIntervalEvent}
            firstDay={startDayOfWeek}
            headerToolbar={{
              start: 'title',
              right: 'leftCustomButton rightCustomButton',
            }}
            height="40vh"
            initialDate={initialDate}
            multiMonthMaxColumns={2}
            plugins={[multimonth, interactionPlugin]}
            selectAllow={() => selectingDate !== ''}
            selectable={selectingDate !== ''}
            weekNumberContent={(weekNumber) =>
              getWeekNumberContent(weekNumber, isViewSelectEnabled, periods, CalendarLocationEnum.NEW_CAMPAIGN)
            }
            weekNumbers={!!periods}
          />
        </Box>
      )}
      <Box sx={style.bottomButtons}>
        <NewButton onClick={() => setCalendarVisible(!isCalendarVisible)} text="Toggle Calendar" variant="Secondary" />
        <Box sx={style.leftButtons}>
          <NewButton onClick={handleClose} sx={style.cancelButton} text="Cancel" variant="Secondary" />
          <NewButton className={classes.submitButton} loading={isLoading} text="Create Campaign" type="submit" />
        </Box>
      </Box>
    </form>
  );
};

export default AddNewCampaign;
