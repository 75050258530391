import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const useCommittedBrandsLinkStyles = makeStyles(() =>
  createStyles({
    links: { textDecoration: 'none', color: THEME.PALETTES.TEXT.Action_Secondary },
    disabledLinks: {
      pointerEvents: 'none',
      color: THEME.PALETTES.TEXT.Medium,
    },
  }),
);
