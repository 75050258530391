import { ButtonTypeMap } from '@mui/material';

import { THEME } from '../../../constants/theme/constants';
import { ButtonVariantOptions } from './NewButton.type';

export const ButtonMapper: {
  [k in ButtonVariantOptions]: {
    variant: ButtonTypeMap['props']['variant'];
    // FIXME: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    styles: any;
  };
} = {
  Primary: {
    variant: 'contained',
    styles: {
      borderRadius: THEME.REM_SPACES['2.5'],
      background: THEME.PALETTES.UI.Primary.Base,
      color: THEME.PALETTES.TEXT.Light,
      textTransform: 'none',
      '&:hover': {
        background: THEME.PALETTES.UI.Primary['700'],
      },
    },
  },
  Secondary: {
    variant: 'outlined',
    styles: {
      borderRadius: THEME.REM_SPACES['2.5'],
      background: THEME.PALETTES.OTHER.White,
      borderColor: THEME.PALETTES.UI.Primary.Base,
      color: THEME.PALETTES.UI.Primary.Base,
      textTransform: 'none',
      fontSize: '0.75rem',
      '&:hover': {
        background: THEME.PALETTES.UI.Primary['200'],
      },
    },
  },
  Ghost: {
    variant: 'text',
    styles: {
      borderRadius: THEME.REM_SPACES['2.5'],
      color: THEME.PALETTES.TEXT.Action_Primary,
      textTransform: 'none',
    },
  },
};
