import { IDynamicRibbonRequestResponse } from '../../../interfaces/dynamicRibbon/dynamicRibbon';
import { baseApi } from '../../base';
import { IChannelAndTacticsResponseDisplayShell, IEditDetails } from './campaignsInterfaces';

const campaignChannelAndTacticsChannelPartnerApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCampaignTiersChannelPartner: builder.query<IChannelAndTacticsResponseDisplayShell, { campaignId: string }>({
      query: ({ campaignId }) => `/retailer/campaign/channel-partner/${campaignId}/tiers/all`,
      providesTags: ['CAMPAIGN_CHANNEL_AND_TACTICS'],
    }),
    putCampaignTacticChannelPartnerCampaignInventoryConfirm: builder.mutation<
      void,
      { campaignTacticId: string | undefined }
    >({
      query: (campaignTacticId) => ({
        url: `/retailer/campaign-tactic/channel-partner/${campaignTacticId}/campaign-inventory-confirm`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_CHANNEL_AND_TACTICS']),
    }),
    putCampaignTacticChannelPartnerCampaignInventoryReject: builder.mutation<
      void,
      { campaignTacticId: string | undefined }
    >({
      query: (campaignTacticId) => ({
        url: `/retailer/campaign-tactic/channel-partner/${campaignTacticId}/campaign-inventory-reject`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_CHANNEL_AND_TACTICS']),
    }),
    putEditUnits: builder.mutation<void, { campaignTacticId: string | undefined; units: string }>({
      query: ({ campaignTacticId, units }) => ({
        url: `/retailer/campaign-tactic/channel-partner/${campaignTacticId}/edit-units`,
        method: 'PUT',
        body: {
          units,
        },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_CHANNEL_AND_TACTICS']),
    }),
    getCampaignTiersDetailsChannelPartner: builder.query<IEditDetails, { campaignTacticId: string | undefined }>({
      query: ({ campaignTacticId }) => `/retailer/campaign-tactic/channel-partner/${campaignTacticId}/details`,
      providesTags: ['CAMPAIGN_CHANNEL_AND_TACTICS'],
    }),
    getChannelPartnerDynamicRibbon: builder.query<IDynamicRibbonRequestResponse, { campaignId: string | undefined }>({
      query: ({ campaignId }) => `/retailer/ribbon/channel-partner/campaign/${campaignId}`,
      providesTags: ['CAMPAIGN_CHANNEL_AND_TACTICS'],
    }),
  }),
});

export const {
  useGetCampaignTiersChannelPartnerQuery,
  usePutCampaignTacticChannelPartnerCampaignInventoryConfirmMutation,
  usePutCampaignTacticChannelPartnerCampaignInventoryRejectMutation,
  usePutEditUnitsMutation,
  useGetCampaignTiersDetailsChannelPartnerQuery,
  useGetChannelPartnerDynamicRibbonQuery,
} = campaignChannelAndTacticsChannelPartnerApi;

export { campaignChannelAndTacticsChannelPartnerApi };
