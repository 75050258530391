import { Box } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router';
import { Outlet } from 'react-router-dom';

import Loader from '#/components/common/loader/loader';
import CampaignInstanceHeaderButtons from '#/components/features/campaigns/CampaignHeaderButtons/CampaignInstanceHeaderButtons';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';
import { ROUTE_PATHS } from '#/constants/routing/routePaths';

import { RESOURCE_TYPES } from '#/hooks/useGetResource';

import { useGetCampaignHeaderQuery } from '#/store/api/campaigns/campaigns';

import UserTypeWrapper from '#/utils/UserTypeWrapper';
import { convertAllCapsUnderscoreStrings } from '#/utils/utils';

import CampaignIcon from '../../../assets/Megaphone.svg';
import { SubmenuBar } from '../../common/submenuBar/SubmenuBar';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import CampaignStepper from './campaignStepper';
import { statusSx, useCampaignsSingleHeaderStyles } from './campaignsSingleHeader.styles';
import { returnCampaignUrls } from './utils';

export type Props = {
  campaignId: string;
};

const CampaignsSingleHeader: React.FC<Props> = ({ campaignId }) => {
  const classes = useCampaignsSingleHeaderStyles();

  const { data, isLoading } = useGetCampaignHeaderQuery({
    resource: {
      resourceType: RESOURCE_TYPES.campaign,
      resourceId: campaignId,
    },
  });

  const campaignSubmenu = [
    {
      label: 'Overview',
      url: returnCampaignUrls(ROUTE_PATHS.CAMPAIGN_OVERVIEW, campaignId || ''),
      permission: USER_PERMISSIONS.CAMPAIGN_OVERVIEW,
    },
    {
      label: 'Channel & Tactics',
      url: returnCampaignUrls(ROUTE_PATHS.CAMPAIGN_CHANNEL_TACTICS, campaignId || ''),
      permission: [
        USER_PERMISSIONS.CAMPAIGN_CHANNEL_TACTICS,
        USER_PERMISSIONS.CAMPAIGN_BRAND_CHANNEL_TACTICS,
        USER_PERMISSIONS.CHANNEL_PARTNER_CAMPAIGN_CHANNEL_AND_TACTICS_INVENTORY_READ,
      ],
    },
    {
      label: 'Target Product Mix',
      url: returnCampaignUrls(ROUTE_PATHS.CAMPAIGN_TARGET_PRODUCT_MIX, campaignId || ''),
      permission: USER_PERMISSIONS.CAMPAIGN_TARGET_PRODUCT_MIX,
    },
    {
      label: 'Brand Orders',
      url: returnCampaignUrls(ROUTE_PATHS.CAMPAIGN_INSTANCES, campaignId || ''),
      permission: [
        USER_PERMISSIONS.CAMPAIGN_INSTANCES,
        USER_PERMISSIONS.CAMPAIGN_BRAND_INSTANCES,
        USER_PERMISSIONS.CAMPAIGN_CHANNEL_PARTNER_INSTANCES,
      ],
    },
    {
      label: 'History',
      url: returnCampaignUrls(ROUTE_PATHS.CAMPAIGN_HISTORY, campaignId || ''),
      permission: [USER_PERMISSIONS.CAMPAIGN_HISTORY_READ],
    },
    {
      label: 'Engagement Stats',
      url: returnCampaignUrls(ROUTE_PATHS.CAMPAIGN_ENGAGEMENT_STATS, campaignId || ''),
      permission: [USER_PERMISSIONS.CAMPAIGN_ENGAGEMENT_STATS],
    },
  ];

  if (isLoading) return <Loader />;
  if (!data) return null;

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.headerContainer}>
          <Box className={classes.icon}>
            <img alt="CampaignIcon" src={CampaignIcon} />
          </Box>
          <Box className={classes.ml1half}>
            <Box className={classes.flexWithCenterAlignItems}>
              <TypographyWithFontFamily cName={classes.campaignCustomStyle}>
                {data.campaignName}
              </TypographyWithFontFamily>
              <Box sx={statusSx(data.status)}>{convertAllCapsUnderscoreStrings(data.status)}</Box>
              <TypographyWithFontFamily cName={classes.ml1half} variant="Tag_Base">
                ID: {campaignId}
              </TypographyWithFontFamily>
            </Box>
          </Box>
        </Box>
        <UserTypeWrapper retailer={<CampaignInstanceHeaderButtons actions={data.actions} name={data.campaignName} />} />
      </Box>
      <CampaignStepper campaignId={campaignId} />
      <SubmenuBar tabs={campaignSubmenu} />
      <Outlet />
    </>
  );
};

const CampaignsSingleHeaderWrapper = () => {
  const { campaignId } = useParams();

  if (!campaignId) {
    return null;
  }

  return <CampaignsSingleHeader campaignId={campaignId} />;
};

export default CampaignsSingleHeaderWrapper;
