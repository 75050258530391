import * as React from 'react';

import { useAppSelector } from '#/hooks';

interface PermissionWrapperProps {
  requiredPermissions: string[];
}

const PermissionWrapper: React.FC<React.PropsWithChildren<PermissionWrapperProps>> = ({
  requiredPermissions,
  children,
}) => {
  const { permissions } = useAppSelector((state) => state.user);

  const hasPermission = requiredPermissions.some((requiredPermission) => permissions.includes(requiredPermission));

  if (hasPermission) {
    return children;
  }

  return null;
};

export default PermissionWrapper;
