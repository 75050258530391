import { Box } from '@mui/material';

import { useCommunicationsSelectedCampaignsStyles } from '#/components/features/communications/shared/CommunicationsSelectedCampaigns.styles';

import { useAppSelector } from '#/hooks';

import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { ICommunicationsSelectedCampaignsResponse } from '#/store/api/communications/communication-interface';
import { useGetCommunicationsPromoteCampaignsQuery } from '#/store/api/communications/communications';

import { currencyFormatter } from '#/utils/textFormatters';

import { Table } from '../../../common/table/tableComponent/Table';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';

const CommunicationsSelectedCampaigns = () => {
  const { selectedCampaigns } = useAppSelector((state) => state.communications);
  const { data } = useGetCommunicationsPromoteCampaignsQuery({ campaignIds: selectedCampaigns });
  const classes = useCommunicationsSelectedCampaignsStyles();
  const formatCompanyType = (startDate: string, endDate: string) => (
    <TypographyWithFontFamily>
      {startDate} - {endDate}
    </TypographyWithFontFamily>
  );

  const productFeedTableColumns: ITableColumnsProps<ICommunicationsSelectedCampaignsResponse>[] = [
    {
      fieldName: 'campaignName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Campaign',
      linkable: true,
      baseUrl: '/campaigns/overview',
      actionElementIdentifier: 'campaignId',
    },
    {
      fieldName: 'companyType',
      align: TableColumnAlign.LEFT,
      customHead: 'Market Date',
      type: TableCellTypes.CUSTOM_ELEMENT,
      actionElementIdentifier: 'startDate',
      actionElementSecondIdentifier: 'endDate',
      getCustomElement: formatCompanyType,
    },
    {
      fieldName: 'submissionDeadline',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Submit Date',
    },
    {
      fieldName: 'brandsTargeted',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Target Brands',
    },
    {
      fieldName: 'campaignGoal',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      textFormatter: currencyFormatter,
      customHead: 'Target Amount',
    },
    {
      fieldName: 'categoriesWithSubcategories',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Category / Sub-Category',
      actionElementIdentifier: 'categoriesWithSubcategories',
      getCustomElement: (categoriesWithSubcategories: string[]) => (
        <TypographyWithFontFamily>{categoriesWithSubcategories?.join(', ')}</TypographyWithFontFamily>
      ),
    },
    {
      fieldName: 'attributes',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Attributes',
      actionElementIdentifier: 'attributes',
      getCustomElement: (attributes: string[]) => (
        <TypographyWithFontFamily>{attributes?.join(', ')}</TypographyWithFontFamily>
      ),
    },
  ];

  if (!data) {
    return <Box>There is no data</Box>;
  }

  return (
    <>
      <TypographyWithFontFamily variant="H5_Base">Selected Campaigns</TypographyWithFontFamily>
      <Table columns={productFeedTableColumns} data={data} headClass={classes.selectedCampaignsTableHeader} />
    </>
  );
};
export default CommunicationsSelectedCampaigns;
