import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { currencyFormatter } from '#/utils/textFormatters';

import { getBrandsByCommitmentStatus } from '../../../api/brandsCommitted';
import { useRequest } from '../../../hooks/useRequest';
import { BrandCommitmentStatus, IBrandsCommittedTableRow } from '../../../interfaces/brandCommitted/brandCommitted';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '../../../interfaces/table/table';
import { Table } from '../../common/table/tableComponent/Table';
import TablePagination from '../../common/tablePagination/TablePagination';
import { useBrandsCommittedTableStyles } from './BrandsCommittedTable.styles';

export const BrandsCommittedTable = () => {
  const { campaignId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState(5);
  const getBrandsByCommitmentStatusAction = useMemo(
    () =>
      getBrandsByCommitmentStatus(campaignId, BrandCommitmentStatus.BRAND_ACCEPT, currentPage, numberOfItemsPerPage),
    [currentPage, numberOfItemsPerPage],
  );
  const { response } = useRequest(getBrandsByCommitmentStatusAction);
  const tableColumns: ITableColumnsProps<IBrandsCommittedTableRow>[] = [
    {
      fieldName: 'brandName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Brand Name',
    },
    {
      fieldName: 'instanceId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Campaign ID',
    },
    {
      fieldName: 'version',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Version',
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Status',
    },
    {
      fieldName: 'agency',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Agency Name',
    },
    {
      fieldName: 'tier',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Tier',
    },
    {
      fieldName: 'budget',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Budget',
      textFormatter: currencyFormatter,
      itemClass: 'text-right',
    },
    {
      fieldName: 'numberOfTactics',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Number of Tactics',
    },
    {
      fieldName: 'bookedTacticInventory',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Booked tactic inventory',
    },
    {
      fieldName: 'numberOfAssets',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Received assets',
    },
    {
      fieldName: 'nextSteps',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Next Steps',
    },
    {
      fieldName: 'alerts',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Alerts',
    },
  ];
  const classes = useBrandsCommittedTableStyles();
  return (
    <Box>
      <Table columns={tableColumns} data={response?.data?.tableProjection || []} headClass={classes.tableHeader} />
      <TablePagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalNumberOfPages={response?.data?.totalPages || 0}
      />
    </Box>
  );
};
