import { Box, Divider, Grid } from '@mui/material';
import React, { FC } from 'react';

import AddDetailsHeader from '#/components/campaigns/AddDetailsHeader';
import AddDetailsOverview from '#/components/campaigns/AddDetailsOverview';
import RateCardsDetails from '#/components/campaigns/RateCardsDetails';

import { useTacticDetailsForm } from '#/hooks/forms/tacticDetails/useTacticDetailsForm';

import { useGetShellMetadataFormQuery } from '#/store/api/campaigns/tacticMetadataForm';
import { useGetRateCardsQuery } from '#/store/api/rateCards/rateCards';

import EditIcon from '../../assets/EditIcon.svg';
import useGetResource from '../../hooks/useGetResource';
import { IAddDetailsToSpecificTierProps } from '../../interfaces/forms/campaigns';
import { useGetCampaignTiersDetailsBrandQuery } from '../../store/api/campaigns/campaignChannelsAndTacticsBrand';
import { useGetCampaignHeaderQuery } from '../../store/api/campaigns/campaigns';
import NewButton from '../common/button/NewButton';
import Loader from '../common/loader/loader';
import Modal from '../common/modal/Modal';
import { useSnackbarError } from '../common/snackbar/useSnackbarError';
import { TacticMetadataForm } from '../common/tacticMetadataForm/TacticMetadataForm';
import { TypographyWithFontFamily } from '../common/typography/TypographyWithFontFamily';
import { styles, useAddDetailsToSpecificTierStyles } from './AddDetailsToSpecificTier.styles';

const AddDetailsToSpecificTierBrand: FC<IAddDetailsToSpecificTierProps> = ({ selectedTactic, setSelectedTactic }) => {
  const { setError } = useSnackbarError();

  const { data: rateCardsData } = useGetRateCardsQuery({
    channelTacticId: selectedTactic?.channelTacticId,
  });

  const { data: detailsData, isLoading: detailsIsLoading } = useGetCampaignTiersDetailsBrandQuery({
    campaignTacticId: selectedTactic?.campaignTacticId,
  });

  const {
    data: tacticsMetadataData,
    isLoading: isTacticsMetadataLoading,
    error: tacticsMetadataError,
  } = useGetShellMetadataFormQuery({ id: selectedTactic.campaignTacticId });

  if (tacticsMetadataError) {
    setError(tacticsMetadataError);
  }
  const { control, register, setValue, watch, errors } = useTacticDetailsForm(detailsData, selectedTactic);

  const resource = useGetResource();
  const { data: headerData } = useGetCampaignHeaderQuery({ resource });

  const onClose = () => {
    setSelectedTactic(undefined);
  };

  const classes = useAddDetailsToSpecificTierStyles();

  return (
    <Box>
      <Modal
        content={
          <form className={classes.form}>
            <Box className={classes.formContainer}>
              <Box>
                {detailsIsLoading ? (
                  <Loader />
                ) : (
                  <>
                    <Grid container spacing={2}>
                      {detailsData && headerData && (
                        <AddDetailsHeader
                          campaignId={headerData.campaignId}
                          campaignName={headerData.campaignName}
                          detailsData={detailsData}
                        />
                      )}
                      {detailsData && (
                        <AddDetailsOverview
                          control={control}
                          detailsData={detailsData}
                          editMode={false}
                          errors={errors}
                          register={register}
                          selectedTactic={selectedTactic}
                          setValue={setValue}
                          watch={watch}
                        />
                      )}
                      {rateCardsData && detailsData && (
                        <RateCardsDetails
                          detailsData={detailsData}
                          editMode={false}
                          rateCardsData={rateCardsData}
                          register={register}
                          selectedTactic={selectedTactic}
                          setValue={setValue}
                          watch={watch}
                        />
                      )}
                      <Grid item xs={12}>
                        <TypographyWithFontFamily variant={'Default_Bold'}>Additional Details</TypographyWithFontFamily>
                      </Grid>
                      <TacticMetadataForm inputsData={tacticsMetadataData} loading={isTacticsMetadataLoading} />
                    </Grid>
                  </>
                )}
              </Box>
            </Box>
            <Box>
              <Divider sx={styles.divider} />
              <Box className={classes.bottomButtonsContainer}>
                <NewButton onClick={onClose} sx={styles.cancelButton} text="Cancel" variant="Secondary" />
              </Box>
            </Box>
          </form>
        }
        handleCloseModal={onClose}
        icon={EditIcon}
        modalTitle={`Edit details for ${detailsData?.tactic}`}
        size="large"
      />
    </Box>
  );
};

export default AddDetailsToSpecificTierBrand;
