import CompanyBillingForm from '#/components/common/forms/companies/CompanyBillingForm/CompanyBillingForm';
import CompanyGeneralForm from '#/components/common/forms/companies/CompanyGeneralForm/CompanyGeneralForm';
import AgencyUsers from '#/components/features/partners/agencies/AgencyUsers/AgencyUsers';

import useGetResource from '#/hooks/useGetResource';

import { useGetCompanyAccountQuery } from '../../../../store/api/company/company';
import Loader from '../../../common/loader/loader';
import AssociatedBrands from './AssociatedBrands/AssociatedBrands';

export const Agency = () => {
  const { resourceId } = useGetResource();
  const { data, isLoading } = useGetCompanyAccountQuery({ companyId: resourceId });

  if (isLoading) return <Loader />;
  if (!data) return null;

  return (
    <>
      <CompanyGeneralForm />
      <AgencyUsers />
      <AssociatedBrands />
      <CompanyBillingForm />
    </>
  );
};
