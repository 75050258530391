import { Box, TextareaAutosize } from '@mui/material';
import { FC } from 'react';
import { Controller } from 'react-hook-form';

import { THEME } from '#/constants/theme/constants';

import { useTaskAction } from '#/hooks/forms/tasks/useTaskAction';

import { Status } from '#/store/api/tasks/interfaces';

import NewButton from '../../../common/button/NewButton';
import Select from '../../../common/inputs/Select/Select';
import Loader from '../../../common/loader/loader';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { useNextActionStyles } from './NextActionSection.styles';

interface NextActionSectionProps {
  aggregateId?: string;
  permittedActionsArray: string[];
  status: Status;
  comment?: string;
}

const NextActionSection: FC<NextActionSectionProps> = ({
  aggregateId = '',
  permittedActionsArray = [],
  status,
  comment = '',
}) => {
  const classes = useNextActionStyles();
  const isReadOnly = status === 'CLOSED';
  const { control, onSubmit, isLoading } = useTaskAction(aggregateId, permittedActionsArray[0] || '');

  return (
    <Box className={classes.section}>
      {isLoading ? (
        <Loader></Loader>
      ) : permittedActionsArray.length === 0 ? (
        <TypographyWithFontFamily sx={{ margin: 'auto' }} variant="H5_Base">
          {'You have to go to the "Target Object ID" to continue with this task'}
        </TypographyWithFontFamily>
      ) : (
        <form className={classes.flexContainer} onSubmit={onSubmit}>
          <Controller
            control={control}
            name={'action'}
            render={({ field: { onChange, value } }) => (
              <Select
                className={classes.selector}
                editMode={!isReadOnly}
                label="Submit Action"
                labelPosition="Default"
                onChange={onChange}
                options={permittedActionsArray.map((el) => ({ label: el, value: el }))}
                placeholder="Please select a next action"
                value={value}
              />
            )}
          />
          <Box className={classes.flexContainer}>
            <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} variant="Label_Base">
              COMMENT
            </TypographyWithFontFamily>
            <Controller
              control={control}
              name={'comment'}
              render={({ field: { onChange, value } }) => (
                <TextareaAutosize
                  className={isReadOnly ? classes.testAreaDisabled : classes.textArea}
                  disabled={isReadOnly}
                  maxRows={6}
                  minRows={3}
                  onChange={onChange}
                  value={isReadOnly ? comment : value}
                />
              )}
            />
          </Box>
          <NewButton className={classes.submit} disabled={isReadOnly} text="Save" type="submit" variant="Primary" />
        </form>
      )}
    </Box>
  );
};

export default NextActionSection;
