import { Box } from '@mui/material';
import { FC } from 'react';
import Tree from 'react-d3-tree';

import PartnerGreyIcon from '#/assets/PartnerGreyIcon.svg';

import Card from '#/components/common/card/card';
import Loader from '#/components/common/loader/loader';
import Refetch from '#/components/common/reFetchComponent/Refetch';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import CompanyHierarchyTable from '#/components/features/partners/brands/viewEditSections/companyHierarchy/CompanyHierarchyTable';
import { useCompanyHierarchyStyles } from '#/components/features/partners/brands/viewEditSections/companyHierarchy/companyHierarchy.styles';

import { useCenteredTree } from '#/hooks/useCenteredTree';
import useGetResource from '#/hooks/useGetResource';

import { useGetCompanyHierarchyQuery } from '#/store/api/company/company';
import { ICompanyHierarchy } from '#/store/api/company/companyInterfaces';

interface ICompanyHierarchyProps {
  companyId: string;
}

const CompanyHierarchy: FC<ICompanyHierarchyProps> = ({ companyId }) => {
  const { resourceId } = useGetResource();
  const { data, refetch, isFetching } = useGetCompanyHierarchyQuery({ companyId });
  const classes = useCompanyHierarchyStyles();
  const { translate, containerRef } = useCenteredTree();

  if (!data) {
    return null;
  }
  const formatData = (data: ICompanyHierarchy) => {
    const newData = { ...data };
    newData.id && (newData.attributes = { id: newData.id });
    if (newData.children) {
      newData.children = newData.children.map((child) => formatData(child));
    }
    return newData;
  };
  return (
    <Box ref={containerRef}>
      <Card customClass={classes.card}>
        {isFetching ? (
          <Loader />
        ) : (
          <>
            <Box className={classes.header}>
              <Box className={classes.headerText}>
                <img alt="receipt logo" src={PartnerGreyIcon} />
                <TypographyWithFontFamily variant="H3_Base">Company Hierarchy</TypographyWithFontFamily>
              </Box>
              <Refetch isLoading={isFetching} reFetchFn={refetch} />
            </Box>
            <Box className={classes.companyHierarchyTable}>
              <CompanyHierarchyTable />
            </Box>
            <Box className={classes.treeContainer}>
              <Tree
                data={formatData(data)}
                nodeSize={{ x: 250, y: 200 }}
                orientation={'vertical'}
                pathFunc={'step'}
                renderCustomNodeElement={({ nodeDatum }) => (
                  <g>
                    <foreignObject height={200} width={200} x={-100} y={-50}>
                      <Box
                        className={`${classes.nodeContainer} ${nodeDatum?.attributes?.id === resourceId ? classes.currentNode : ''}`}
                      >
                        <TypographyWithFontFamily variant={'Default_Bold'}>{nodeDatum.name}</TypographyWithFontFamily>
                      </Box>
                    </foreignObject>
                  </g>
                )}
                translate={translate}
                zoom={1}
              />
            </Box>
          </>
        )}
      </Card>
    </Box>
  );
};

export default CompanyHierarchy;
