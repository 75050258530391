import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const useCampaignDetailStyles = makeStyles(() =>
  createStyles({
    campaignName: {
      overflow: 'hidden',
      width: '100%',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: THEME.REM_SPACES['0.5'],
    },
    topBorder: {
      borderColor: 'lightgrey',
      borderTopWidth: '0.1px',
      borderTopStyle: 'solid',
      marginTop: THEME.REM_SPACES['0.5'],
    },
    bottomSection: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    bottomSectionBrand: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
    },
    buttonsSection: {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'end',
      marginTop: THEME.REM_SPACES['0.5'],
      marginLeft: 'auto',
    },
    buttonsSectionBrand: {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'center',
      marginTop: THEME.REM_SPACES['0.5'],
      justifyContent: 'center',
    },
    container: { display: 'flex', flexDirection: 'column', flex: '1' },
    links: { textDecoration: 'none', color: THEME.PALETTES.TEXT.Action_Secondary },
  }),
);

export const styles = {
  cloneButtonMargin: {
    marginRight: THEME.REM_SPACES['0.5'],
  },
};
