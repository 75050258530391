import { TypographyTypeMap } from '@mui/material';

import { THEME } from '../../../constants/theme/constants';
import { TypographyVariantOptions } from './Typography.types';

export const TypographyMapper: {
  [k in TypographyVariantOptions]: {
    variant: TypographyTypeMap['props']['variant'];
    // FIXME: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    styles: any;
  };
} = {
  H1_Base: {
    variant: 'h1',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
      fontSize: '2rem',
    },
  },
  H2_Base: {
    variant: 'h2',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
      fontSize: '1.5rem',
    },
  },
  H3_Base: {
    variant: 'h3',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
      fontSize: '1.313rem',
    },
  },
  H3_Regular: {
    variant: 'h3',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.REGULAR,
      fontSize: '1.313rem',
    },
  },
  H4_Base: {
    variant: 'h4',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
      fontSize: '1.125rem',
    },
  },
  H4_Regular: {
    variant: 'h4',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.REGULAR,
      fontSize: '1.125rem',
    },
  },
  H5_Base: {
    variant: 'h5',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
      fontSize: '0.938rem',
    },
  },
  H5_Regular: {
    variant: 'h5',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.REGULAR,
      fontSize: '0.938rem',
    },
  },
  H6_Base: {
    variant: 'h6',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
      fontSize: '0.75rem',
    },
  },
  H6_Regular: {
    variant: 'h6',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.REGULAR,
      fontSize: '0.75rem',
    },
  },
  Default_Base: {
    variant: 'body1',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.REGULAR,
      fontSize: '0.813rem',
    },
  },
  Default_Bold: {
    variant: 'body1',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
      fontSize: '0.813rem',
    },
  },
  Small_Base: {
    variant: 'body1',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.REGULAR,
      fontSize: '0.719rem',
    },
  },
  Small_Bold: {
    variant: 'body1',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
      fontSize: '0.719rem',
    },
  },
  Label_Base: {
    variant: 'body1',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.MEDIUM,
      fontSize: '0.719rem',
      marginTop: '5px',
      marginBottom: '5px',
    },
  },
  Tag_Base: {
    variant: 'body1',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.REGULAR,
      fontSize: '0.625rem',
    },
  },
  Tag_Semi_Bold: {
    variant: 'body1',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.MEDIUM,
      fontSize: '0.625rem',
    },
  },
  Text_Body_S_500: {
    variant: 'subtitle1',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.REGULAR,
      fontSize: '0.71875rem',
    },
  },
  Text_Body_500: {
    variant: 'body1',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.REGULAR,
      fontSize: '0.8125rem',
    },
  },
  Text_Body_700: {
    variant: 'body1',
    styles: {
      fontWeight: THEME.FONT_WEIGHTS.BOLD,
      fontSize: '0.8125rem',
    },
  },
  Small_Tag: {
    variant: 'subtitle1',
    styles: {
      padding: '0.25rem 0.5rem',
      fontSize: '0.625rem',
      background: THEME.PALETTES.UI.Secondary['200'],
      color: THEME.PALETTES.UI.Neutral['900'],
      borderRadius: '12px',
      marginRight: THEME.REM_SPACES['0.5'],
      display: 'inline-flex',
    },
  },
  Button: {
    variant: 'body1',
    styles: {
      fontSize: '0.75rem',
      fontWeight: THEME.FONT_WEIGHTS.BOLD,
      lineHeight: '14px',
      letterSpacing: '0em',
    },
  },
};
