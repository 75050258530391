import { createStyles, makeStyles } from '#/utils/theme';

export const useTasksHeaderStyles = makeStyles(() =>
  createStyles({
    section: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    pageHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1.25rem',
    },
    flexAlignCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    taskIconWrapper: {
      borderRadius: '50%',
      marginRight: '8px',
      width: '2.625rem',
      height: '2.5rem',
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    headerName: {
      '&&': {
        fontWeight: 600,
        fontSize: '24px',
      },
    },
  }),
);
