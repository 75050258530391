export enum BrandCommitmentStatus {
  CREATED = 'CREATED',
  BRAND_APPLY = 'BRAND_APPLY',
  BRAND_DECLINE = 'BRAND_DECLINE',
  BRAND_ACCEPT = 'BRAND_ACCEPT',
  RETAILER_DECLINE = 'RETAILER_DECLINE',
  BRAND_REQUEST_REVISION = 'BRAND_REQUEST_REVISION',
  RETAILER_ACCEPT = 'RETAILER_ACCEPT',
}
export interface IBrandsCommittedTableRow {
  status: BrandCommitmentStatus;
  instanceName: string;
  instanceId: string;
  version: string;
  brandName: string;
  agency: string;
  owner: string;
  tier: string;
  budget: string;
  numberOfTactics: number;
  bookedTacticInventory: string;
  numberOfAssets: number;
  nextSteps: string;
  alerts: string;
}
export interface IBrandsCommittedResponse {
  currentIndex: number;
  totalPages: number;
  tableProjection: IBrandsCommittedTableRow[];
}
