import { createStyles, makeStyles } from '#/utils/theme';

export const usePaginationStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      padding: 0,
      justifyContent: 'center',
    },
    section33: {
      width: '33%',
    },
    itemsPerPage: {
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    ul: {
      '& .MuiPaginationItem-root': {
        borderRadius: '25%',

        '&.Mui-selected': {
          background: theme.custom.PALETTES.UI.Secondary[200],
          borderRadius: '25%',
        },
      },
    },
    marginRight: {
      marginRight: theme.custom.REM_SPACES[1],
    },
    selectSx: { fontSize: '0.875rem', borderRadius: '20px' },
  }),
);
