import { Box } from '@mui/material';

import DynamicRibbon from '#/components/features/dynamicRibbon/DynamicRibbon';

import PermissionWrapper from '#/utils/PermissionWrapper';

import { USER_PERMISSIONS } from '../../../../../../constants/permissions/userPermissions';
import useCurrentPath from '../../../../../../hooks/useCurrentPath.hook';
import useGetResource from '../../../../../../hooks/useGetResource';
import {
  useGetCampaignTiersChannelPartnerQuery,
  useGetChannelPartnerDynamicRibbonQuery,
} from '../../../../../../store/api/campaigns/campaignChannelsAndTacticsChannelPartner';
import Loader from '../../../../../common/loader/loader';
import { TypographyWithFontFamily } from '../../../../../common/typography/TypographyWithFontFamily';
import { useCampaignChannelsAndTacticsStyle } from '../RetailerView/CampaignChannelsAndTactics.style';
import CampaignTierChannelPartner from './CampaignTierChannelPartner.tsx';

const locationMethodsMapper = {
  'instances/channel-tactics/:instanceId': {
    ribbonPermission: USER_PERMISSIONS.CHANNEL_PARTNER_CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS_RIBBON_READ,
    tablePermission: USER_PERMISSIONS.CHANNEL_PARTNER_CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS_INVENTORY_READ,
  },
  'campaigns/channel-tactics/:campaignId': {
    ribbonPermission: USER_PERMISSIONS.CHANNEL_PARTNER_CAMPAIGN_CHANNEL_AND_TACTICS_RIBBON_READ,
    tablePermission: USER_PERMISSIONS.CHANNEL_PARTNER_CAMPAIGN_CHANNEL_AND_TACTICS_INVENTORY_READ,
  },
};

const ChannelPartnerChannelAndTactics = () => {
  const currentPath = useCurrentPath();

  const { resourceId } = useGetResource();
  const { data, isFetching, refetch } = useGetCampaignTiersChannelPartnerQuery({ campaignId: resourceId });

  const classes = useCampaignChannelsAndTacticsStyle();
  const {
    data: dynamicRibbonData,
    isLoading: dynamicRibbonIsLoading,
    isFetching: dynamicRibbonIsFetching,
    refetch: refetchDynamicRibbon,
  } = useGetChannelPartnerDynamicRibbonQuery({
    campaignId: resourceId,
  });
  return (
    <>
      <PermissionWrapper requiredPermissions={[locationMethodsMapper[currentPath].ribbonPermission]}>
        <DynamicRibbon
          isLoading={dynamicRibbonIsFetching || dynamicRibbonIsLoading}
          response={dynamicRibbonData}
          submitCallback={() => {
            refetch();
            refetchDynamicRibbon();
          }}
        />
      </PermissionWrapper>
      <Box className={classes.channelAndTacticsContainer}>
        {isFetching ? (
          <Loader />
        ) : data ? (
          data?.tiers?.map((tier) => (
            <Box key={tier.tierName} className={classes.tierContainer}>
              <CampaignTierChannelPartner tier={tier} />
            </Box>
          ))
        ) : (
          <TypographyWithFontFamily variant={'Default_Bold'}>No tier added</TypographyWithFontFamily>
        )}
      </Box>
    </>
  );
};

export default ChannelPartnerChannelAndTactics;
