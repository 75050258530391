import { Box } from '@mui/material';
import { FC, useMemo, useState } from 'react';

import Card from '#/components/common/card/card';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import PermissionWrapper from '#/utils/PermissionWrapper';

import { getChannelPartnerUsers } from '../../../../../api/partners/channelPartner';
import ContactsIcon from '../../../../../assets/ContactsIcon.svg';
import PlusIcon from '../../../../../assets/Plus.svg';
import { CompanyChannelPartnerUsersTable } from '../../../../../constants/tables/partners/channelPartners/channelPartners';
import { useRequest } from '../../../../../hooks/useRequest';
import {
  IChannelPartnerUsers,
  IChannelPartnerUsersProps,
} from '../../../../../interfaces/partners/channelPartners/channelPartners';
import NewButton from '../../../../common/button/NewButton';
import { Table } from '../../../../common/table/tableComponent/Table';
import { TypographyWithFontFamily } from '../../../../common/typography/TypographyWithFontFamily';
import { useBrandUserListStyle } from '../../brands/BrandUserList/BrandUserList.styles';
import { useChannelPartnerSingleStyle } from '../ChannelPartnerSingle.style';
import AddChannelPartnerUser from './AddChannelPartnerUser';

const ChannelPartnerUsers: FC<IChannelPartnerUsersProps> = ({ channelId }) => {
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);

  const getBrandUsersAction = useMemo(() => getChannelPartnerUsers(channelId), [isAddUserOpen]);
  const { response } = useRequest(getBrandUsersAction);

  const changeDisplayData = (data: IChannelPartnerUsers[]) => {
    return data.map((user: IChannelPartnerUsers) => {
      return { ...user, keyContact: user.keyContact ? true : null, billingContact: user.billingContact ? true : null };
    });
  };

  const modalStyles = useChannelPartnerSingleStyle();
  const classes = useBrandUserListStyle();
  const ChannelPartnerUsers = CompanyChannelPartnerUsersTable();

  return (
    <Card>
      <Box className={classes.headerContainer}>
        <Box className={classes.headerLeft}>
          <img alt="contacts icon" src={ContactsIcon} />
          <TypographyWithFontFamily cName={classes.header} variant="H4_Base">
            Users
          </TypographyWithFontFamily>
        </Box>
        <Box>
          <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.PARTNERS_CHANNEL_PARTNERS_ADD_USERS_WRITE]}>
            <Box className={modalStyles.addUserButtonContainer}>
              <NewButton icon={PlusIcon} onClick={() => setIsAddUserOpen(true)} text="Add User" />
            </Box>
          </PermissionWrapper>
          {isAddUserOpen && (
            <AddChannelPartnerUser
              channelPartnerId={channelId}
              isAddUserOpen={isAddUserOpen}
              onClose={() => setIsAddUserOpen(false)}
            />
          )}
        </Box>
      </Box>
      <Table
        bodyCellClass={classes.tableCell}
        columns={ChannelPartnerUsers}
        containerClass={classes.tableContainer}
        data={changeDisplayData(response?.data || [])}
        headCellClass={classes.tableHeader}
      />
    </Card>
  );
};

export default ChannelPartnerUsers;
