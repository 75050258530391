import { flow } from 'lodash';

import TableCellLink from '#/components/common/TableCellLink/TableCellLink';
import { ROUTE_PATHS } from '#/constants/routing/routePaths';

import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { BrandOrderChannelPartner } from '#/store/api/campaigns/brandOrdersInterfaces';
import { SearchFilters } from '#/store/api/campaigns/campaignsInterfaces';

import { camelOrPascalToRegular, currencyFormatter } from '#/utils/textFormatters';

const useBrandOrdersBrandTableColumns = (columNames: SearchFilters[] = []) => {
  const sortableColumns = columNames.map((el) => el.columnName);

  const tableColumns: ITableColumnsProps<BrandOrderChannelPartner>[] = [
    {
      fieldName: 'brandName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Brand',
      sortable: sortableColumns.includes('brandName'),
      actionElementIdentifier: 'instanceId',
      actionElementSecondIdentifier: 'brandName',
      getCustomElement: (instanceId, brandName) => (
        <TableCellLink link={`/${ROUTE_PATHS.CAMPAIGN_INSTANCE}/overview/${instanceId}`} text={brandName} />
      ),
    },
    {
      fieldName: 'campaignName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Campaign',
      sortable: sortableColumns.includes('campaignName'),
    },
    {
      fieldName: 'agencies',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.REACT_TABLE_DATA_ELEMENT,
      customHead: 'Agency Name',
      element: ({ rowData }) => <p>{rowData.agencies.join(', ')}</p>,
    },
    {
      fieldName: 'primaryContactName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Primary Contact',
      sortable: sortableColumns.includes('primaryContactName'),
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Status',
      textFormatter: flow(String, camelOrPascalToRegular),
      sortable: sortableColumns.includes('status'),
    },
    {
      fieldName: 'budget',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Budget',
      textFormatter: currencyFormatter,
      itemClass: 'text-right',
      sortable: sortableColumns.includes('budget'),
    },
    {
      fieldName: 'appliedDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Applied Date',
      sortable: sortableColumns.includes('appliedDate'),
    },
    {
      fieldName: 'startDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Start Date',
      sortable: sortableColumns.includes('startDate'),
    },
    {
      fieldName: 'endDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'End Date',
      sortable: sortableColumns.includes('endDate'),
    },
  ];

  return tableColumns;
};
export default useBrandOrdersBrandTableColumns;
