import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import SettingsIcon from '../../../assets/Settings.svg';
import { settingsSubmenu } from '../../../constants/submenu';
import DisplayPageName from '../../common/displayPageName/DisplayPageName';
import { SubmenuBar } from '../../common/submenuBar/SubmenuBar';

const SettingsSubmenu = () => {
  return (
    <Box>
      <Box>
        <DisplayPageName imgSrc={SettingsIcon} pageName="Settings" />
        <SubmenuBar tabs={settingsSubmenu} />
      </Box>
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
};

export default SettingsSubmenu;
