import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../constants/theme/constants';

export const useCampaignOverviewMarketingStyles = makeStyles(() =>
  createStyles({
    marketingContainer: {
      border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      padding: THEME.REM_SPACES[1],
      borderRadius: THEME.REM_SPACES[0.5],
      marginTop: THEME.REM_SPACES[2],
    },
    marketingSectionsContainer: {
      display: 'flex',
      columnGap: THEME.REM_SPACES[1],
    },
    marketingSection: {
      flex: '1',
    },
  }),
);

export const upperCaseSx = {
  textTransform: 'uppercase',
};

export const textAreaEditMode = {
  borderRadius: THEME.REM_SPACES['0.5'],
  border: THEME.PALETTES.UI.Neutral['200'],
};

export const textAreaReadOnlyMode = {
  background: THEME.PALETTES.UI.Neutral['200'],
  borderRadius: THEME.REM_SPACES['0.5'],
  border: THEME.PALETTES.UI.Neutral['200'],
};
