import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { formatList } from '#/components/features/associateAgencyToBrands/utils';

import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { CompanyAccount } from '#/store/api/company/companyInterfaces';

export const getTableColumns = (companiesData, selectedCompany, setValue) => {
  const getBrandAddress = (mailingAddress1: string, city: string, state: string, zip: string) => {
    return <TypographyWithFontFamily>{formatList(mailingAddress1, city, state, zip)}</TypographyWithFontFamily>;
  };

  const filteredTableColumns: ITableColumnsProps<CompanyAccount>[] = [
    {
      fieldName: null,
      align: TableColumnAlign.RIGHT,
      type: TableCellTypes.CHECKBOX,
      customHead: ' ',
      checkBoxAction: (checked, aggregateId) => {
        const selectedCompany = companiesData.companies.find((company) => company.aggregateId === aggregateId);
        setValue('selectedCompany', {
          companyId: selectedCompany?.aggregateId || '',
          name: selectedCompany?.companyName || '',
          type: selectedCompany?.companyType || '',
        });
      },
      checkFromList: [selectedCompany?.companyId],
      actionElementIdentifier: 'aggregateId',
    },
    {
      fieldName: 'companyName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Company',
    },
    {
      fieldName: 'companyType',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Company Type',
    },
    {
      fieldName: null,
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Address',
      actionElementIdentifier: 'mailingAddress1',
      actionElementSecondIdentifier: 'city',
      actionElementThirdIdentifier: 'state',
      actionElementFourthIdentifier: 'zip',
      getCustomElement: getBrandAddress,
    },
  ];

  return {
    filteredTableColumns,
  };
};
