import { Box, Grid, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import AlertError from '#/components/common/Alert/AlertError/AlertError';
import { useSnackbarError } from '#/components/common/snackbar/useSnackbarError';
import { BASE_PATH, COMMUNICATIONS_TABS } from '#/constants/general';
import { ROUTE_PATHS } from '#/constants/routing/routePaths';

import { useAppDispatch, useAppSelector } from '#/hooks';

import { usePostCommunicationsPromoteMutation } from '#/store/api/communications/communications';
import { communicationsPromote } from '#/store/slices';

import CampaignBrandsList from '../../../../campaignInvitations/CampaignBrandsList';
import {
  marginBottom05Sx,
  marginBottom1Sx,
  marginTop05Sx,
  useCampaignInvitationsStyles,
} from '../../../../campaignInvitations/CampaignInvitations.styles';
import NewButton from '../../../../common/button/NewButton';
import MultipleDownloadUpload from '../../../../common/downloadUpload/MultipleDownloadUpload';
import { useSnackbarSuccess } from '../../../../common/snackbar/useSnackbarSuccess';
import { TypographyWithFontFamily } from '../../../../common/typography/TypographyWithFontFamily';
import CommunicationsSelectedCampaigns from '../../shared/CommunicationsSelectedCampaigns';
import CustomCommunicationsModal from '../../shared/CustomCommunicationsModal';

const CommunicationPromoteReview = () => {
  const { setError } = useSnackbarError();
  const classes = useCampaignInvitationsStyles();
  const navigate = useNavigate();
  const { selectedCampaigns, day, time, subjectLine, message, filesAttachment } = useAppSelector(
    (state) => state.communications,
  );
  const selectBrandIds = useAppSelector((state) => communicationsPromote.getSelectedBrandsIds(state.communications));
  const selectedEmails = useAppSelector((state) =>
    communicationsPromote.getSelectedRecipientsEmails(state.communications),
  );

  const { COMMUNICATIONS, PROMOTE } = ROUTE_PATHS;
  const handleClick = () => navigate(-1);
  const [submitCommunicationPromote, { isLoading, error }] = usePostCommunicationsPromoteMutation();
  const { setSuccess } = useSnackbarSuccess();
  const dispatch = useAppDispatch();

  const datePart = dayjs(day).format('YYYY-MM-DD');
  const timePart = dayjs(time).format('HH:mm:ss');
  const dateToSend = `${datePart}T${timePart}`;

  useEffect(() => {
    if (selectedCampaigns.length === 0) {
      navigate(`${BASE_PATH}/${COMMUNICATIONS}/${PROMOTE}`);
    }
  }, []);
  const onSubmit = () => {
    submitCommunicationPromote({
      body: {
        campaignIds: selectedCampaigns,
        brandIds: selectBrandIds,
        emails: selectedEmails,
        subject: subjectLine,
        body: draftToHtml(convertToRaw(message.getCurrentContent())),
        scheduledDate: dayjs(dateToSend).toISOString(),
      },
    })
      .unwrap()
      .then(() => {
        setSuccess('Invitation(s) sent successfully');
        navigate(`${BASE_PATH}/${COMMUNICATIONS}/${PROMOTE}`);
        dispatch(communicationsPromote.actions.resetStepsData());
      })
      .catch(() => {
        setError({ code: 500, message: 'Something went wrong.' });
      });
  };

  return (
    <CustomCommunicationsModal activeTab={COMMUNICATIONS_TABS.COMMUNICATIONS_REVIEW} modalTitle="Review & Send">
      <Stack className={classes.selectBrandsContainer} direction={'row'}>
        <Box className={classes.invitationDetailsContainer}>
          <CampaignBrandsList />
        </Box>
        <Box className={classes.selectBrandsOptionsContainer}>
          <AlertError error={error} />
          <Box className={classes.buttonWrap}>
            <NewButton className={classes.mr15} onClick={handleClick} text="Back" type="button" variant="Secondary" />
            <NewButton loading={isLoading} onClick={onSubmit} text="Submit" type="button" />
          </Box>
          <CommunicationsSelectedCampaigns />
          <Box className={classes.reviewPageContainerRight}>
            <TypographyWithFontFamily variant="H5_Base">Attachments</TypographyWithFontFamily>
            <Grid container sx={marginTop05Sx}>
              <MultipleDownloadUpload filesGridSize={4} filesIds={filesAttachment} />
            </Grid>
            <TypographyWithFontFamily sx={marginBottom05Sx} variant="H5_Base">
              Send Schedule
            </TypographyWithFontFamily>
            <TypographyWithFontFamily sx={marginBottom1Sx} variant="Default_Base">
              {`${dayjs(day).format('DD/MM/YYYY')} at ${dayjs(time).format('HH:mm A')}`}
            </TypographyWithFontFamily>
            <TypographyWithFontFamily cName={classes.modalTextHeader} sx={marginBottom05Sx} variant="H5_Base">
              Subject Line
            </TypographyWithFontFamily>
            <TypographyWithFontFamily sx={marginBottom1Sx}>{subjectLine}</TypographyWithFontFamily>
            <TypographyWithFontFamily sx={marginBottom05Sx} variant="H5_Base">
              Message Body
            </TypographyWithFontFamily>
            <div dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(message.getCurrentContent())) }} />
          </Box>
        </Box>
      </Stack>
    </CustomCommunicationsModal>
  );
};

export default CommunicationPromoteReview;
