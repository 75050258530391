import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import TextField from '#/components/common/inputs/TextField/TextField';
import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';

import { useDataCy } from '#/hooks/useDataCy';

import { getCampaignInstanceInternal } from '../../../../../api/campaign/campaigns';
import { USER_PERMISSIONS } from '../../../../../constants/permissions/userPermissions';
import { useEditCampaignInstanceInternal } from '../../../../../hooks/forms/campaigns/instances/useEditCampaignInstanceInternal';
import { useRequest } from '../../../../../hooks/useRequest';
import { useCampaignOverviewInternalStyles } from '../../../../campaigns/CampaignOverviewInternal.styles';
import { DeprecatedTopSection } from '../../../../campaigns/topSection/DeprecatedTopSection';
import Loader from '../../../../common/loader/loader';

const CampaignInstanceInternal = () => {
  const { instanceId } = useParams();
  const classes = useCampaignOverviewInternalStyles();
  const generateDataCy = useDataCy();

  const [editMode, setEditMode] = useState(false);

  const getCampaignInternalAction = useMemo(() => getCampaignInstanceInternal(instanceId), [editMode]);
  const { response, loading } = useRequest(getCampaignInternalAction);

  const { onSubmit, errors, register, reset, setValue, watch } = useEditCampaignInstanceInternal(() =>
    setEditMode(false),
  );
  const { goal, description } = response?.data || {};
  useEffect(() => {
    reset({
      goal: goal || '',
      description: description || '',
    });
  }, [goal, description, editMode]);

  if (loading) return <Loader />;

  return (
    <>
      <form onSubmit={onSubmit}>
        <Box className={classes.form} data-cy={generateDataCy('box', 'CampaignOverviewInternal')}>
          <DeprecatedTopSection
            editMode={editMode}
            requiredPermission={USER_PERMISSIONS.CAMPAIGN_INSTANCE_OVERVIEW_INTERNAL_WRITE}
            sectionName="Internal"
            setEditMode={setEditMode}
          />
          <Box className={classes.row}>
            <TextFieldOld
              editMode={editMode}
              error={errors.goal?.message?.toString()}
              label="Campaign Goal"
              labelPosition="Top"
              register={register('goal')}
            />
          </Box>
          <TextField
            disabled={!editMode}
            error={errors.description?.message?.toString()}
            label="Description"
            labelPosition="Top"
            multiline
            onChange={(e) => setValue('description', e.target.value)}
            rows={5}
            value={watch('description')}
          />
        </Box>
      </form>
    </>
  );
};
export default CampaignInstanceInternal;
