import { makeStyles, createStyles } from '#/utils/theme';

export const useChannelPartnerSingleStyle = makeStyles(() =>
  createStyles({
    addUserButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);
