import { baseApi } from '../../base';

const campaign = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    postCampaignClone: builder.mutation<void, { campaignId: string | undefined; ownerId: string }>({
      query: ({ campaignId, ownerId }) => ({
        url: 'retailer/campaign/clone',
        method: 'POST',
        body: {
          campaignId,
          ownerId,
        },
        responseHandler: 'text',
      }),
    }),
    putCampaignProductMix: builder.mutation<
      void,
      {
        productId: string | undefined;
        data: {
          categoryIds: string[];
          subCategoryIds: string[];
          attributeIds: string[];
        };
      }
    >({
      query: ({ productId, data }) => ({
        url: `retailer/campaign/${productId}/product-mix`,
        method: 'PUT',
        body: data,
      }),
    }),
  }),
});

export const { usePostCampaignCloneMutation, usePutCampaignProductMixMutation } = campaign;
