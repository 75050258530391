import { CAMPAIGN_ALL } from '../../constants/tables/campaignTable/campaignTable';
import { UsersType } from '../../constants/usersRoles/usersRole';
import { ICampaignInstanceInternalForm, ICampaignInventoryDTO } from '../../interfaces/campaigns/campaigns';
import { ICampaignOverviewLegalTerms } from '../../store/api/campaigns/campaignsInterfaces';
import { retailerAxios } from '../axiosConfig';
import { IRequestDto } from '../requestDtoInterface';

export const getCampaigns = (status: string, type?: UsersType) => {
  const brandVariable = type !== UsersType.RETAILER ? `/${type}` : '';
  const brandStatus = status !== CAMPAIGN_ALL ? `/${status}` : '';

  return retailerAxios.get(`/campaign${brandVariable}${brandStatus}/all`);
};

export const getChannelPartnerCampaignInstancesById = (campaignId: string | undefined) => {
  return retailerAxios.get(`instance/view-controller/channel-partner/${campaignId}/all`);
};

export const getCampaignInstanceInternal = (instanceId: string | undefined) => {
  return retailerAxios.get(`instances/${instanceId}/overview/internal-section`);
};

export const editCampaignInstanceInternal = (instanceId: string | undefined, data: ICampaignInstanceInternalForm) => {
  return retailerAxios.put(`instances/${instanceId}/overview/internal-section`, {
    ...data,
    campaignGoal: data.goal,
  });
};

export const getInstanceOverviewLegalTerms = (
  instanceId: string | undefined,
): Promise<IRequestDto<ICampaignOverviewLegalTerms>> => {
  return retailerAxios.get(`instances/${instanceId}/overview/legal-terms`);
};

export const getInstanceBrandOverviewLegalTerms = (
  instanceId: string | undefined,
): Promise<IRequestDto<ICampaignOverviewLegalTerms>> => {
  return retailerAxios.get(`instances/brand/${instanceId}/overview/legal-terms`);
};

export const getCampaignInstanceMarketing = (instanceId: string | undefined) => {
  return retailerAxios.get(`instances/${instanceId}/overview/marketing-info`);
};

export const getInstanceById = (instanceId: string | undefined) => {
  return retailerAxios.get(`instances/${instanceId}`);
};

export const putCampaignTacticChannelPartnerCampaignInventoryReject = (campaignTacticId: string | undefined) => {
  return retailerAxios.put(`/campaign-tactic/channel-partner/${campaignTacticId}/campaign-inventory-reject`);
};

export const putCampaignTacticChannelPartnerCampaignInventoryConfirm = (campaignTacticId: string | undefined) => {
  return retailerAxios.put(`/campaign-tactic/channel-partner/${campaignTacticId}/campaign-inventory-confirm`);
};

export const putCampaignTacticChannelPartnerRejectTacticMetadata = (
  campaignTacticId: string | undefined,
  body: { [key: string]: string },
) => {
  return retailerAxios.put(`/campaign-tactic/channel-partner/${campaignTacticId}/inventory-tactic-metadata`, body);
};

export const putInstanceTacticChannelPartnerCampaignInventoryReject = (campaignTacticId: string | undefined) => {
  return retailerAxios.put(`/instance-tactic/channel-partner/${campaignTacticId}/campaign-inventory-reject`);
};

export const putInstanceTacticChannelPartnerCampaignInventoryBook = (campaignTacticId: string | undefined) => {
  return retailerAxios.put(`/instance-tactic/channel-partner/${campaignTacticId}/campaign-inventory-book`);
};

export const putInstanceTacticChannelPartnerCampaignTacticMetadata = (
  instanceTacticId: string | undefined,
  body: { [key: string]: string },
) => {
  return retailerAxios.put(`/instance-tactic/channel-partner/${instanceTacticId}/inventory-tactic-metadata`, body);
};

export const getCampaignInventory = (campaignId: string | undefined): Promise<ICampaignInventoryDTO> => {
  return retailerAxios.get(`/campaign/${campaignId}/inventory`);
};
