import { Box } from '@mui/material';
import classnames from 'classnames';
import { FC, useEffect } from 'react';

import { useTacticMetadataForm } from '../../../hooks/forms/tacticMetadata/useTacticMetadataForm';
import { IMetadataDTO, InputTypes } from '../../../interfaces/tacticMetadata/tacticMetadata';
import Loader from '../loader/loader';
import { NoDataMessage } from '../noDataMessage/NoDataMessage';
import { formStyle, useTacticMetadataFormStyle } from './TacticMetadataForm.style';
import { CheckBoxListInput } from './inputs/CheckBoxListInput';
import { CheckBoxSingleOptionInput } from './inputs/CheckBoxSingleOptionInput';
import { DropdownInput } from './inputs/DropdownInput';
import { FileUploadInput } from './inputs/FileUploadInput';
import { NumericInput } from './inputs/NumericInput';
import { RadioBoxInput } from './inputs/RadioBoxInput';
import { TextAreaInput } from './inputs/TextAreaInput';
import { TextInput } from './inputs/TextInput';
import { getFormResetValues, extractInputsFromMetadata } from './utils';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitRef?: React.RefObject<any>;
  isInstance?: boolean;
  inputsData?: IMetadataDTO;
  tacticId?: string;
  onClose?: () => void;
  loading: boolean;
}

export const TacticMetadataForm: FC<IProps> = ({ submitRef, isInstance, inputsData, tacticId, onClose, loading }) => {
  if (!inputsData) {
    return <NoDataMessage message="No Data Available" />;
  }

  if (loading) {
    return <Loader />;
  }

  const inputFields = extractInputsFromMetadata(inputsData);

  const { register, errors, control, onSubmit, watch, reset, setValue } = useTacticMetadataForm(
    inputFields,
    !!isInstance,
    tacticId,
    onClose,
  );

  const classes = useTacticMetadataFormStyle();

  useEffect(() => {
    const defaultFields = getFormResetValues(inputsData);
    reset(defaultFields);
  }, []);

  const returnInputs = inputFields.map((data) => {
    let input: JSX.Element = <></>;
    switch (data.inputType) {
      case InputTypes.TEXT:
        input = (
          <Box className={classnames(classes.inputContainer, classes.whiteContainer)}>
            <TextInput errors={errors} input={data} register={register} />
          </Box>
        );
        break;
      case InputTypes.AREA:
        input = (
          <Box className={classnames(classes.inputContainer, classes.whiteContainer)}>
            <TextAreaInput errors={errors} input={data} register={register} />
          </Box>
        );
        break;
      case InputTypes.NUMBER:
        input = (
          <Box className={classnames(classes.inputContainer, classes.whiteContainer)}>
            <NumericInput errors={errors} input={data} register={register} setValue={setValue} />
          </Box>
        );
        break;
      case InputTypes.DROPDOWN:
        input = (
          <Box className={classnames(classes.inputContainer, classes.whiteContainer)}>
            <DropdownInput control={control} errors={errors} input={data} />
          </Box>
        );
        break;
      case InputTypes.CHECKBOX_LIST:
        input = (
          <Box className={classnames(classes.inputContainer, classes.whiteContainer)}>
            <CheckBoxListInput control={control} errors={errors} input={data} watch={watch} />
          </Box>
        );
        break;
      case InputTypes.SINGLE_OPTION_CHECKBOX:
        input = (
          <Box className={classnames(classes.inputContainer, classes.whiteContainer)}>
            <CheckBoxSingleOptionInput control={control} input={data} watch={watch} />
          </Box>
        );
        break;
      case InputTypes.RADIO:
        input = (
          <Box className={classnames(classes.inputContainer, classes.whiteContainer)}>
            <RadioBoxInput control={control} errors={errors} input={data} watch={watch} />
          </Box>
        );
        break;
      case InputTypes.FILE:
        input = (
          <Box className={classnames(classes.inputContainer, classes.whiteContainer)}>
            <FileUploadInput control={control} errors={errors} input={data} />
          </Box>
        );
        break;
    }
    return input;
  });

  return (
    <Box className={classes.formContainer}>
      <form encType="multipart/form-data" style={formStyle}>
        <Box>
          <Box>{returnInputs}</Box>
        </Box>
        <button
          ref={submitRef}
          onClick={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          style={{ display: 'none' }}
        />
      </form>
    </Box>
  );
};
