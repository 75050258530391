import TableLogoCell from '#/components/common/tableLogoCell/TableLogoCell';

import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { CompanyAccountAssociatedCompany } from '#/store/api/company/companyInterfaces';

const getAssociatedAgenciesTables = (): ITableColumnsProps<CompanyAccountAssociatedCompany>[] => {
  const createCustomNameImageView = (
    aggregateId: string,
    logoId: string | null,
    companyName: string,
    companyType: string,
  ) => (
    <TableLogoCell
      link={companyType === 'AGENCY' ? `/partners/agencies/${aggregateId}` : `/partners/brands/${aggregateId}`}
      logoId={logoId}
      name={companyName}
    />
  );

  return [
    {
      fieldName: 'aggregateId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Campaign Id',
      hide: true,
    },
    {
      fieldName: 'companyName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Agency',
      actionElementIdentifier: 'aggregateId',
      actionElementSecondIdentifier: 'logoId',
      actionElementThirdIdentifier: 'companyName',
      actionElementFourthIdentifier: 'companyType',
      getCustomElement: createCustomNameImageView,
    },
    {
      fieldName: 'parentName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Parent Company',
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Brand Status',
    },
    {
      fieldName: 'vendorId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Vendor ID',
    },
    {
      fieldName: 'numberOfUsers',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.STRING,
      customHead: 'Users',
    },
    {
      fieldName: null,
      columnsToAggregate: ['addressZipCode', 'addressLine1', 'addressCity', 'addressState'],
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.AGGREGATE_COLUMNS,
      customHead: 'Address',
    },
  ];
};

export default getAssociatedAgenciesTables;
