import { Box } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router';

import useGetResource from '#/hooks/useGetResource';

import { numberFormatter } from '#/utils/textFormatters';

import {
  putCampaignTacticChannelPartnerCampaignInventoryConfirm,
  putCampaignTacticChannelPartnerCampaignInventoryReject,
  putCampaignTacticChannelPartnerRejectTacticMetadata,
  putInstanceTacticChannelPartnerCampaignInventoryBook,
  putInstanceTacticChannelPartnerCampaignInventoryReject,
  putInstanceTacticChannelPartnerCampaignTacticMetadata,
} from '../../../../../api/campaign/campaigns';
import { USER_PERMISSIONS } from '../../../../../constants/permissions/userPermissions';
import { getUserHasPermission } from '../../../../../constants/permissions/utils';
import { THEME } from '../../../../../constants/theme/constants';
import useCurrentPath from '../../../../../hooks/useCurrentPath.hook';
import { IChannelAndTacticsResponseTactic } from '../../../../../interfaces/forms/campaigns';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '../../../../../interfaces/table/table';
import {
  useGetCampaignTiersInstanceChannelPartnerQuery,
  usePutCampaignTacticChannelPartnerCampaignInventoryConfirmInstanceMutation,
  usePutCampaignTacticChannelPartnerCampaignInventoryRejectInstanceMutation,
} from '../../../../../store/api/instances/campaignInstanceChannelsAndTacticsChannelPartner';
import { useGetCampaignInstanceTacticsQuery } from '../../../../../store/api/products/products';
import AddDetailsToSpecificTierChannelPartnerInstance from '../../../../campaigns/AddDetailsToSpecificTierChannelPartnerInstance';
import { useCampaignTierTacticsTableStyle } from '../../../../campaigns/CampaignTierTacticsTableStyle';
import NewButton from '../../../../common/button/NewButton';
import { useSnackbarError } from '../../../../common/snackbar/useSnackbarError';
import { Table } from '../../../../common/table/tableComponent/Table';
import { styles } from './../../../../features/campaigns/campaign/channelsAndTactics/ChannelPartnerView/TacticsTable.styles';
import ProductFeedTableView from './ProductFeedTableView';

interface ITactic {
  campaignTacticId: string;
  channelPartnerId: string;
  channelPartnerName: string;
  tierId: string;
  status: string;
  channel: string;
  subChannel: string;
  tactic: string;
  placement: string;
  targeting: string;
  startDate: string;
  endDate: string;
  budget: string;
  units: string;
  rateType: string;
  rateValue: number;
}
interface INewCampaignTierTacticsTableProps {
  tactics?: IChannelAndTacticsResponseTactic[];
}
const NewCampaignTierTacticsTableChannelPartnerInstances = ({ tactics }: INewCampaignTierTacticsTableProps) => {
  const resource = useGetResource();
  const { data: campaignInstanceData } = useGetCampaignTiersInstanceChannelPartnerQuery({ resource });
  const userHasReadInventoryPermission = getUserHasPermission(
    USER_PERMISSIONS.CHANNEL_PARTNER_CAMPAIGN_CHANNEL_AND_TACTICS_INVENTORY_READ,
  );

  const [upnCodesViewInstanceId, setUpnCodesViewInstanceId] = useState<string | null>(null);
  const [upnCodesViewName, setUpnCodesViewName] = useState<string>('');

  const [selectedTactic, setSelectedTactic] = useState<IChannelAndTacticsResponseTactic | undefined>(undefined);
  const classes = useCampaignTierTacticsTableStyle();
  const { setError } = useSnackbarError();
  //
  const locationMethodsMapper = {
    'instances/channel-tactics/:instanceId': {
      confirmMethod: putInstanceTacticChannelPartnerCampaignInventoryBook,
      rejectMethod: putInstanceTacticChannelPartnerCampaignInventoryReject,
      updateMetadataMethod: putInstanceTacticChannelPartnerCampaignTacticMetadata,
      confirmText: 'Book',
      confirmButtonDisabledStatus: 'BOOKED',
    },
    'campaigns/channel-tactics/:campaignId': {
      confirmMethod: putCampaignTacticChannelPartnerCampaignInventoryConfirm,
      rejectMethod: putCampaignTacticChannelPartnerCampaignInventoryReject,
      updateMetadataMethod: putCampaignTacticChannelPartnerRejectTacticMetadata,
      confirmText: 'Confirm',
      confirmButtonDisabledStatus: 'CONFIRMED',
    },
  };
  //FIXME: fix any
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actions = ({ rowData }: { rowData: any }) => {
    const currentPath = useCurrentPath();
    const { instanceTacticId } = rowData;
    const [triggerPutCampaignTacticChannelPartnerCampaignInventoryConfirmMutation] =
      usePutCampaignTacticChannelPartnerCampaignInventoryConfirmInstanceMutation();
    const [putCampaignTacticChannelPartnerCampaignInventoryRejectMutation] =
      usePutCampaignTacticChannelPartnerCampaignInventoryRejectInstanceMutation();

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);

    const isConfirmButtonDisabled =
      rowData?.status === locationMethodsMapper[currentPath].confirmButtonDisabledStatus ||
      !!campaignInstanceData?.inventorySubmitted;
    const isRejectButtonDisabled = rowData?.status === 'REJECTED' || !!campaignInstanceData?.inventorySubmitted;

    const confirmHandler = async () => {
      try {
        setConfirmLoading(true);
        await triggerPutCampaignTacticChannelPartnerCampaignInventoryConfirmMutation(instanceTacticId).unwrap();
      } catch (error) {
        setError(error);
      } finally {
        setConfirmLoading(false);
      }
    };

    const rejectHandler = async () => {
      try {
        setRejectLoading(true);
        await putCampaignTacticChannelPartnerCampaignInventoryRejectMutation(instanceTacticId).unwrap();
      } catch (error) {
        setError(error);
      } finally {
        setRejectLoading(false);
      }
    };

    return (
      <Box sx={{ display: 'flex', columnGap: '0.5rem', justifyContent: 'center' }}>
        <NewButton
          disabled={isConfirmButtonDisabled}
          loading={confirmLoading}
          onClick={confirmHandler}
          sx={styles.actionButton}
          text={locationMethodsMapper[currentPath].confirmText}
          variant="Secondary"
        />
        <NewButton
          disabled={isRejectButtonDisabled}
          loading={rejectLoading}
          onClick={rejectHandler}
          sx={styles.actionButton}
          text="Reject"
          variant="Secondary"
        />
      </Box>
    );
  };
  //
  const { instanceId } = useParams();
  const { data: dataTactics } = useGetCampaignInstanceTacticsQuery({ instanceId });
  const tableColumns: ITableColumnsProps<ITactic>[] = [
    {
      fieldName: null,
      align: TableColumnAlign.CENTER,
      actionElementIdentifier: 'campaignTacticId',
      type: TableCellTypes.REACT_TABLE_DATA_ELEMENT,
      //FIXME: fix any
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      element: actions as any,
      customHead: 'Status',
      hide: !userHasReadInventoryPermission,
    },
    {
      fieldName: 'channel',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Channel',
    },
    {
      fieldName: 'subChannel',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Sub-channel',
    },
    {
      fieldName: 'tactic',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.ACTION,
      customHead: 'Tactic',
      referenceAction: (instanceTacticId) => {
        setSelectedTactic(tactics?.find((tactic) => tactic.instanceTacticId === instanceTacticId));
      },
      actionButtonTextFromMapping: 'tactic',
      itemClass: classes.areaButton,
      actionElementIdentifier: 'instanceTacticId',
    },
    {
      fieldName: 'channelPartnerName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Channel Partner',
    },
    {
      fieldName: 'placement',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Placement',
    },
    {
      fieldName: 'targeting',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Targeting',
    },
    {
      fieldName: 'rateType',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Rate Type',
    },
    {
      fieldName: 'rateValue',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Rate',
    },
    {
      fieldName: 'units',
      align: TableColumnAlign.LEFT,
      customHead: 'Units',
      textFormatter: numberFormatter,
      type: TableCellTypes.STRING,
    },

    {
      fieldName: null,
      align: TableColumnAlign.CENTER,
      customHead: 'Number of Products',
      type: TableCellTypes.CUSTOM_ELEMENT,
      actionElementIdentifier: 'instanceTacticId',
      actionElementSecondIdentifier: 'nrOfProductFeed',
      linkable: true,
      getCustomElement: (instanceTacticId, nrOfProductFeed) => {
        //FIXME: fix any
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        const currentTactic = dataTactics?.tactics?.find((t: any) => t.instanceTacticId === instanceTacticId);
        return (
          <Box
            onClick={() => {
              setUpnCodesViewInstanceId(instanceTacticId);
              setUpnCodesViewName(currentTactic?.tacticName || '');
            }}
            sx={{
              cursor: 'pointer',
              color: THEME.PALETTES.TEXT.Action_Secondary,
            }}
          >
            {nrOfProductFeed}
          </Box>
        );
      },
    },
    {
      fieldName: 'startDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Start Date',
    },
    {
      fieldName: 'endDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'End Date',
    },
  ];

  return (
    <Box>
      {!!selectedTactic && (
        <AddDetailsToSpecificTierChannelPartnerInstance
          selectedTactic={selectedTactic}
          setSelectedTactic={setSelectedTactic}
        />
      )}
      <Table columns={tableColumns} data={tactics || []} />
      {upnCodesViewInstanceId && (
        <ProductFeedTableView
          onClose={() => setUpnCodesViewInstanceId(null)}
          upnCodesViewInstanceId={upnCodesViewInstanceId}
          upnCodesViewName={upnCodesViewName}
        />
      )}
    </Box>
  );
};

export default NewCampaignTierTacticsTableChannelPartnerInstances;
