import { THEME } from '../../../../constants/theme/constants';

export const styles = {
  label: {
    paddingBottom: 0,
    marginBottom: 0,
    paddingTop: THEME.REM_SPACES[1],
    color: THEME.PALETTES.UI.Neutral[600],
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
};
