import { FC, ReactElement } from 'react';

import { useAppSelector } from '#/hooks';

import { pageLayoutStyles } from './pageLayouts.styles';

export interface IVerticalLayoutProp {
  component: ReactElement;
  requiredPermission?: string | string[];
}

interface VerticalLayoutProps {
  components: IVerticalLayoutProp[];
}

export const VerticalLayout: FC<VerticalLayoutProps> = ({ components }) => {
  const classes = pageLayoutStyles();
  const { permissions } = useAppSelector((state) => state.user);

  return (
    <div className={classes.contentContainer}>
      {components.map((component) => {
        let userHasPermission = false;
        if (typeof component.requiredPermission === 'string') {
          userHasPermission = permissions.includes(component.requiredPermission);
        } else {
          userHasPermission = !!component.requiredPermission?.some((permission) => permissions?.includes(permission));
        }

        if ((component.requiredPermission && userHasPermission) || !component.requiredPermission) {
          return component.component;
        }
      })}
    </div>
  );
};
