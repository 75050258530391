import { Box } from '@mui/material';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import AlertError from '#/components/common/Alert/AlertError/AlertError';
import CollapseComponent from '#/components/common/CollapseComponent/CollapseComponent';
import SearchFilters from '#/components/common/SearchFilters/SearchFilters';
import useSearchFilters from '#/components/common/SearchFilters/useSearchFilters.hook';
import { CampaignListModes } from '#/components/common/TabModeToggle';
import NewButton from '#/components/common/button/NewButton';
import ModalBottomContainer from '#/components/common/modal/ModalBottomContainer';
import { useBrandsTableStyles } from '#/components/features/associateAgencyToBrands/BrandsTable.styles';
import { getTableColumns } from '#/components/features/associateAgencyToBrands/BrandsTable.tableColumns';

import {
  useGetAssociatedCompaniesQuery,
  useGetCompaniesAssociateRetailerFilterQuery,
  useGetCompaniesRetailerQuery,
  usePutAssociatedCompaniesMutation,
} from '#/store/api/company/company';
import { CompanyAccountAssociatedCompany } from '#/store/api/company/companyInterfaces';

import Loader from '../../common/loader/loader';
import { Table } from '../../common/table/tableComponent/Table';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';

type BrandsTableProps = {
  onClose: () => void;
};

const BrandsTable: FC<BrandsTableProps> = ({ onClose }) => {
  const { agencyId, brandId } = useParams();

  const { data, isSuccess } = useGetAssociatedCompaniesQuery({ companyId: agencyId || brandId });

  const [selectedBrands, setSelectedBrands] = useState<CompanyAccountAssociatedCompany[]>([]);

  const [triggerPutAssociatedCompaniesMutation, { isLoading, error }] = usePutAssociatedCompaniesMutation();

  useEffect(() => {
    if (isSuccess) {
      setSelectedBrands(data);
    }
  }, [isSuccess]);

  const classes = useBrandsTableStyles();

  const { data: filterData, isError: filterDataIsError } = useGetCompaniesAssociateRetailerFilterQuery({});

  const { actions, state } = useSearchFilters(
    {
      viewMode: CampaignListModes.LIST,
      alwaysActiveFilters: [{ key: 'companyType', value: 'BRAND', operation: '=' }],
    },
    false,
    filterData?.filters,
  );

  const {
    data: companiesData,
    isError: companiesIsError,
    isFetching: companiesIsFetching,
    isLoading: companiesIsLoading,
  } = useGetCompaniesRetailerQuery({
    pageNumber: state.pageNumber,
    numberOfRows: state.numberOfElementsPerPage,
    searchQuery: state.searchQuery,
  });

  if (companiesIsLoading) return <Loader />;

  const { selectedTableColumns, filteredTableColumns } = getTableColumns(
    companiesData?.companies || [],
    selectedBrands,
    setSelectedBrands,
    filterData ? filterData.filters : [],
  );

  return (
    <Box className={classes.container}>
      <AlertError error={error} />
      <Box>
        {selectedBrands.length !== 0 && (
          <Box className={classes.collapseContainer}>
            <CollapseComponent
              actionTitle={
                <TypographyWithFontFamily variant="H5_Regular">
                  Selected Brands ({selectedBrands.length})
                </TypographyWithFontFamily>
              }
              content={
                <Table
                  columns={selectedTableColumns}
                  containerClass={classNames(classes.table, classes.selectedTable)}
                  data={selectedBrands}
                  headClass={classes.tableHeader}
                />
              }
            />
          </Box>
        )}
        <Box className={classes.searchFiltersContainer}>
          <SearchFilters
            columnNames={filterData?.filters}
            currentIndex={companiesData?.currentIndex}
            filterDataIsError={filterDataIsError}
            hideViewMode
            isError={companiesIsError}
            isFetching={companiesIsFetching}
            isNoResults={!companiesData || companiesData.companies.length === 0}
            totalPages={companiesData?.totalPages}
            totalResults={companiesData?.totalResults}
            {...state}
            {...actions}
          >
            <Table
              columns={filteredTableColumns}
              containerClass={classes.table}
              data={companiesData?.companies || []}
              headClass={classes.tableHeader}
              sortProps={{
                sortParams: state.sortParams,
                setSortParams: actions.setSortParam,
              }}
            />
          </SearchFilters>
        </Box>
      </Box>
      <ModalBottomContainer>
        <NewButton onClick={onClose} text="Cancel" variant="Secondary" />
        <NewButton
          loading={isLoading}
          onClick={async () => {
            await triggerPutAssociatedCompaniesMutation({
              companyId: agencyId || brandId,
              companyIds: selectedBrands.map((el) => el.aggregateId),
            }).unwrap();
            onClose();
          }}
          text="Save"
        />
      </ModalBottomContainer>
    </Box>
  );
};

export default BrandsTable;
