import { Box } from '@mui/material';

import { USER_PERMISSIONS } from '../../../../../../constants/permissions/userPermissions';
import { getUserHasPermission } from '../../../../../../constants/permissions/utils';
import useGetResource from '../../../../../../hooks/useGetResource';
import { useGetCampaignTiersBrandQuery } from '../../../../../../store/api/campaigns/campaignChannelsAndTacticsBrand';
import PermissionWrapper from '../../../../../../utils/PermissionWrapper';
import Loader from '../../../../../common/loader/loader';
import { TypographyWithFontFamily } from '../../../../../common/typography/TypographyWithFontFamily';
import CreateCampaignTier from '../../../CreateCampaignTier';
import { useCampaignChannelsAndTacticsStyle } from '../RetailerView/CampaignChannelsAndTactics.style';
import CampaignTierBrand from './CampaignTierBrand';

const CampaignChannelsAndTacticsBrand = () => {
  const classes = useCampaignChannelsAndTacticsStyle();
  const userHasCreatePermission = getUserHasPermission(USER_PERMISSIONS.CAMPAIGN_CHANNEL_TACTICS_CREATE);
  const resource = useGetResource();
  const { data, isLoading, isFetching } = useGetCampaignTiersBrandQuery({ resource });

  return (
    <Box className={classes.channelAndTacticsContainer}>
      <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.CAMPAIGN_CHANNEL_TACTICS_CREATE]}>
        <CreateCampaignTier />
      </PermissionWrapper>
      {isLoading || isFetching ? (
        <Loader />
      ) : data ? (
        data.tiers.map((tier) => (
          <CampaignTierBrand key={tier.tierName} tier={tier} userHasCreatePermission={userHasCreatePermission} />
        ))
      ) : (
        <TypographyWithFontFamily variant={'Default_Bold'}>No tiers added</TypographyWithFontFamily>
      )}
    </Box>
  );
};

export default CampaignChannelsAndTacticsBrand;
