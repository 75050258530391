import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { useSnackbarSuccess } from '#/components/common/snackbar/useSnackbarSuccess';

import { useGetUserQuery, usePutUserMutation } from '#/store/api/users/users';
import { PutUser } from '#/store/api/users/usersInterfaces';

import { useSnackbarError } from '../../../components/common/snackbar/useSnackbarError';

export const useEditUserInfo = (onClose: () => void) => {
  const { userId } = useParams();
  const [putUser, result] = usePutUserMutation({ fixedCacheKey: 'partners/users/edit' });
  const { data: userData, isFetching, refetch } = useGetUserQuery({ userId });
  const { setSuccess } = useSnackbarSuccess();

  const dataDefaultValues = {
    ...userData,
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    state: userData?.state,
  };

  const schema = yup
    .object()
    .shape({
      firstName: yup.string().matches(/^[a-zA-Z ]*$/, 'Only letters allowed'),
      lastName: yup.string().matches(/^[a-zA-Z ]*$/, 'Only letters allowed'),
      phoneNumber: yup.string(),
      emailAddress: yup.string(),
      addressLine1: yup.string(),
      addressLine2: yup.string(),
      city: yup.string(),
      state: yup.string(),
      zipCode: yup.string(),
    })
    .required();
  const {
    register,
    clearErrors,
    handleSubmit,
    reset,
    watch,
    formState: { errors, defaultValues },
  } = useForm<PutUser>({
    resolver: yupResolver(schema),
    defaultValues: dataDefaultValues,
  });
  const { setError } = useSnackbarError();
  const onSubmit = handleSubmit(async (formData: PutUser) => {
    if (!isEqual(formData, defaultValues)) {
      try {
        await putUser({ userId: userData?.userId, body: formData }).unwrap();
        setSuccess('Your action is being processed. Please refresh this section if data is not updated.');
        reset(formData);
        onClose();
      } catch (error) {
        setError(error);
      }
      clearErrors();
    } else {
      onClose();
    }
  });

  const onRefetch = async () => {
    try {
      await refetch().unwrap();
      reset(dataDefaultValues);
    } catch (error) {
      setError('We are unable to re fetch the data');
    }
  };

  return {
    onSubmit,
    reset,
    errors,
    register,
    isLoading: result.isLoading,
    dataLoading: isFetching,
    refetch: onRefetch,
    watch,
    userData,
    error: result.error,
  };
};
