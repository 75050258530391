import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../constants/theme/constants';

export const useCalendarStyles = makeStyles(() =>
  createStyles({
    cardContainer: { display: 'flex', flexDirection: 'column', height: '100%' },
    calendarHeader: {
      height: '100%',
      '&& .fc-event': {
        borderRadius: THEME.REM_SPACES['0.5'],
        paddingLeft: THEME.REM_SPACES['0.5'],
        paddingRight: THEME.REM_SPACES['0.5'],
      },
      '&& .fc-col-header-cell': {
        color: THEME.PALETTES.TEXT.Light,
        textAlign: 'center',
        fontFamily: 'Public Sans',
        fontSize: '0.8125rem',
        fontWeight: 400,
        padding: THEME.REM_SPACES['0.5'],
        background: THEME.PALETTES.UI.Neutral['600'],
      },
      '&& .fc-header-toolbar': {
        marginBottom: THEME.REM_SPACES['0.5'],
      },
      '&& button': {
        borderRadius: THEME.REM_SPACES['2.5'],
        borderWidth: 0,
        background: THEME.PALETTES.UI.Primary.Base,
        color: THEME.PALETTES.TEXT.Light,
        textTransform: 'none',
        '&:hover': {
          background: THEME.PALETTES.UI.Primary['700'],
        },
        fontFamily: 'Public Sans, sans-serif',
        textAlign: 'center',
        fontSize: '0.75rem',
        fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
        lineHeight: '1.53125rem',
      },
      '&& h2': {
        fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
        fontSize: THEME.REM_SPACES['1.5'],
      },
    },
    month: {
      color: THEME.PALETTES.TEXT.Light,
      fontSize: '0.71875rem',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    oddMonth: {
      background: THEME.PALETTES.UI.Neutral['400'],
    },
    evenMonth: {
      background: THEME.PALETTES.UI.Neutral['600'],
    },

    week: {
      color: THEME.PALETTES.TEXT.Dark,
      fontSize: '0.625rem',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    oddWeek: {
      background: THEME.PALETTES.UI.Neutral['100'],
    },
    evenWeek: {
      background: THEME.PALETTES.UI.Neutral['300'],
    },
    days: {
      color: THEME.PALETTES.TEXT.Dark,
      fontSize: '0.625rem',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    event: {
      background: THEME.PALETTES.UI.Neutral['200'],
      color: THEME.PALETTES.TEXT.Dark,
      fontSize: '0.8125rem',
      fontWeight: 600,
      marginTop: '0.5rem',
      marginBottom: '0.25rem',
    },
    // maybe we can find a better way to do this
    daysDisabled: {
      '&& div:nth-child(2) > button:first-child': {
        background: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.26)',
        pointerEvents: 'none',
      },
    },
    weeksDisabled: {
      '&& div:nth-child(2) > button:nth-child(2)': {
        background: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.26)',
        pointerEvents: 'none',
      },
    },
    monthsDisabled: {
      '&& div:nth-child(2) > button:nth-child(3)': {
        background: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.26)',
        pointerEvents: 'none',
      },
    },
    disabledToolbarButton: {
      '&& div:nth-child(3) > button:first-child': {
        background: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.26)',
        pointerEvents: 'none',
      },
    },
    popOverHidden: {
      '&& .fc-more-popover': {
        display: 'none',
      },
    },
  }),
);
