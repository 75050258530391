import { Box } from '@mui/system';
import React, { FC, ReactElement, useMemo, useRef, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { downloadFile } from '#/api/downloadUpload';
import { getFileMetadata, uploadFileCustomLink } from '#/api/files';

import { errorTextSx } from '#/components/common/tacticMetadataForm/TacticMetadataForm.style';
import { THEME } from '#/constants/theme/constants';

import { useDataCy } from '#/hooks/useDataCy';
import { useRequest } from '#/hooks/useRequest';

import { getFileIcon } from '#/utils/fileIcon';

import DeleteIcon from '../../../assets/DeleteIconBlue.svg';
import DownloadIcon from '../../../assets/DownloadIconBlue.svg';
import FileIcon from '../../../assets/FileIcon.svg';
import Loader from '../loader/loader';
import { useSnackbarError } from '../snackbar/useSnackbarError';
import { TypographyWithFontFamily } from '../typography/TypographyWithFontFamily';
import { useSingleDownloadUploadStyles } from './SingleDownloadUpload.styles';

interface ISingleDownloadUploadEditModeProps {
  editMode: boolean;
  // FIXME: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
  registerName: string;
  uploadLink: string;
}
interface ISingleDownloadUploadReadOnlyModeProps {
  editMode?: false;
}
type SingleDownloadUploadProps = {
  fileId: string | undefined;
  label?: string;
  extensions?: string[];
  size?: number;
  customComponent?: ReactElement;
} & (ISingleDownloadUploadReadOnlyModeProps | ISingleDownloadUploadEditModeProps);

const SingleDownloadUpload: FC<SingleDownloadUploadProps> = (props) => {
  const classes = useSingleDownloadUploadStyles();
  const generateDataCy = useDataCy();

  const { fileId, editMode, customComponent } = props;
  const getFileMetadataAction = useMemo(() => (fileId ? getFileMetadata(fileId) : null), [fileId, editMode]);
  const { response } = useRequest(getFileMetadataAction);
  const { fileName, timestamp } = response?.data || {};
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const { setError } = useSnackbarError();

  const dataCy = useMemo(
    /* @FIXME when label is set dynamically, sometimes it can be undefined */
    () => generateDataCy('TextField', props.label ?? ''),
    [props.label],
  );

  const LabelComponent = () => (
    <>
      {props.label && (
        <TypographyWithFontFamily
          className={classes.labelUppercase}
          color={THEME.PALETTES.TEXT.Gray}
          component="label"
          htmlFor={dataCy}
          variant="Label_Base"
        >
          {props.label}
        </TypographyWithFontFamily>
      )}
    </>
  );

  if (isUploading) {
    return <Loader />;
  }
  if (!fileId) {
    if (!editMode)
      return (
        <Box className={classes.noEditContainer}>
          <LabelComponent />
          <TypographyWithFontFamily variant="H5_Regular">No File Added</TypographyWithFontFamily>
        </Box>
      );

    //upload file and replace file id with the new one
    const updateFile = async (currentFile: File | undefined | null) => {
      if (editMode) {
        const isCorrectFileExtension =
          props?.extensions && !props?.extensions?.includes('.' + currentFile?.name?.split('.')?.[1] || '');
        const isCorrectFileSize = Number(currentFile?.size) / 1024 / 1024 <= Number(props?.size);
        if (isCorrectFileExtension) {
          setUploadError('File extension not allowed');
          return;
        }
        if (props?.size && !isCorrectFileSize) {
          setUploadError('File size is too large');
          return;
        }
        setUploadError('');
        setIsUploading(true);
        const { setValue, uploadLink } = props;
        const formData = new FormData();
        formData.append('file', currentFile as Blob);
        try {
          const response = await uploadFileCustomLink(uploadLink, formData);
          setValue(props.registerName, response?.data);
        } catch (error) {
          setError(error);
        } finally {
          setIsUploading(false);
        }
      }
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentFile = e.target.files?.[0];
      updateFile(currentFile);
    };

    const handleBrowseFile = () => {
      inputRef.current?.click();
    };

    const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
    };

    const dopHandler = async (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      let currentFile: File | null;
      if (e.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file
        currentFile = e.dataTransfer.items[0].getAsFile();
      } else {
        // Use DataTransfer interface to access the file
        currentFile = e.dataTransfer.files[0];
      }
      updateFile(currentFile);
    };

    return (
      <Box>
        <LabelComponent />
        <input
          ref={inputRef}
          accept={props?.extensions?.join(', ') || '*'}
          data-cy={generateDataCy('input', `DownloadUpload${props.registerName}`)}
          hidden={true}
          onChange={handleFileChange}
          type="file"
        />
        {customComponent ? (
          <Box onClick={handleBrowseFile} onDragOver={dragOverHandler} onDrop={dopHandler}>
            {customComponent}
          </Box>
        ) : (
          <Box
            className={classes.downloadUploadContainer}
            onClick={handleBrowseFile}
            onDragOver={dragOverHandler}
            onDrop={dopHandler}
          >
            <Box>
              <img src={FileIcon} />
            </Box>
            <TypographyWithFontFamily variant="Default_Base">Drag and drop to upload files</TypographyWithFontFamily>
            <Box>
              <TypographyWithFontFamily cName={classes.browseFile} color={THEME.PALETTES.ICONS.Action_Secondary}>
                Browse file
              </TypographyWithFontFamily>
            </Box>
          </Box>
        )}
        <TypographyWithFontFamily sx={errorTextSx} variant="Default_Base">
          {uploadError}
        </TypographyWithFontFamily>
      </Box>
    );
  } else {
    const onDownloadFile = async () => {
      const response = await downloadFile(fileId);
      const match = response?.headers['content-disposition'].match(/filename="([^"]+)"/);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(response?.data);
      link.setAttribute('download', match?.[1] || fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    };
    const onDeleteFile = () => {
      if (editMode) {
        const { setValue, registerName } = props;
        setValue(registerName, '');
      }
    };
    return (
      <Box>
        <LabelComponent />
        <Box className={classes.fileViewContainer}>
          <Box className={classes.imageNameContainer}>
            <img className={classes.icon} src={getFileIcon(fileName?.split('.')?.[1])} />
            <Box className={classes.fileTextContainer}>
              {fileName ? (
                <>
                  <TypographyWithFontFamily>{fileName}</TypographyWithFontFamily>
                  <TypographyWithFontFamily cName={classes.uploadDateText}>
                    Uploaded at {timestamp}
                  </TypographyWithFontFamily>
                </>
              ) : (
                <TypographyWithFontFamily>File not found</TypographyWithFontFamily>
              )}
            </Box>
          </Box>
          <Box className={classes.buttonsContainer}>
            {fileName && <img className={classes.fileButton} onClick={onDownloadFile} src={DownloadIcon} />}
            {editMode && <img className={classes.fileButton} onClick={onDeleteFile} src={DeleteIcon} />}
          </Box>
        </Box>
      </Box>
    );
  }
};

export default SingleDownloadUpload;
