import { createStyles, makeStyles } from '#/utils/theme';

export const useModalBottomContainerStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      columnGap: '8px',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      padding: '1.25rem',
      backdropFilter: 'blur(3px)',
      boxShadow: '1px 1px 1px 1px #ccc',
      zIndex: 20,
    },
  }),
);
