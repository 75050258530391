import { FC } from 'react';

import BrandsTable from '#/components/features/associateAgencyToBrands/BrandsTable';

import { useGetCompaniesAssociateRetailerFilterQuery } from '#/store/api/company/company';

import Loader from '../../common/loader/loader';

type BrandsTableContainerProps = {
  onClose: () => void;
};

const BrandsTableContainer: FC<BrandsTableContainerProps> = ({ onClose }) => {
  const { isLoading: filterDataIsLoading } = useGetCompaniesAssociateRetailerFilterQuery({});

  if (filterDataIsLoading) return <Loader />;

  return <BrandsTable onClose={onClose} />;
};

export default BrandsTableContainer;
