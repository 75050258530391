import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const useSelectStyle = makeStyles(() =>
  createStyles({
    base: {
      '&& .MuiOutlinedInput-root': {
        height: THEME.REM_SPACES['2'],
        borderRadius: THEME.REM_SPACES['0.5'],
        border: `1px solid var(--border-extra-light, ${THEME.PALETTES.BORDER.Extra_Light})`,
        background: THEME.PALETTES.OTHER.White,
        color: THEME.PALETTES.TEXT.Dark,
        fontSize: '0.8125rem',
      },
      '&& .MuiSelect-select': {
        padding: '0px',
        paddingLeft: `calc(${THEME.REM_SPACES['0.5']} - 1px);`,
        minWidth: '6.25rem',
      },
    },
  }),
);
