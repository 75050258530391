import { Tab } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import { MenuElement } from '#/components/common/sideMenu/MenuElementInterfaces';

import { useDataCy } from '#/hooks/useDataCy';

import { TypographyWithFontFamily } from '../typography/TypographyWithFontFamily';
import { getTabSx, useMainMenuStyles } from './MainMenu.style';
import { getIsActive } from './utils';

export const MainMenuItem = ({ item }: { item: MenuElement }) => {
  const location = useLocation();
  const classes = useMainMenuStyles();
  const generateDataCy = useDataCy();

  const isActive = getIsActive(location.pathname, item);

  return (
    <Link className={classes.itemLink} to={item.route}>
      <Tab
        data-cy={generateDataCy('tab', `MainMenuItem-${item.displayedText}`)}
        label={
          <div className={classes.menuItem}>
            <img src={item.icon} />
            <TypographyWithFontFamily>{item.displayedText}</TypographyWithFontFamily>
          </div>
        }
        sx={getTabSx(isActive)}
      />
    </Link>
  );
};
