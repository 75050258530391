import useGetResource from '../../../hooks/useGetResource';
import { useGetCampaignOverviewGeneralQuery } from '../../../store/api/campaigns/campaigns';
import Loader from '../../common/loader/loader';
import CampaignOverviewGeneral from './CampaignOverviewGeneral';

const CampaignOverviewGeneralContainer = () => {
  const resource = useGetResource();
  const { data, isLoading } = useGetCampaignOverviewGeneralQuery({ resource });

  if (isLoading) return <Loader />;
  if (!data) return null;

  return <CampaignOverviewGeneral />;
};
export default CampaignOverviewGeneralContainer;
