import * as React from 'react';

import { Container, Text } from '#/components/ErrorBoundary/ErrorBoundary.style';
import NewButton from '#/components/common/button/NewButton';
import { isDevelopment } from '#/constants/general';

import { useLogout } from '#/hooks/useLogout';

import log from '#/utils/log';

interface Props {
  message?: string;
  // This is a function that will be called when the error is not recoverable, it defaults to logout
  onFail: () => void;
  // This is a function that will be called when the user retries the action
  onRetry?: () => void | Promise<void>;
}

interface State {
  hasError: boolean;
  isRetrying: boolean;
}

const MAX_RETRY_COUNTER = isDevelopment ? 10 : 3;

class ErrorBoundaryClass extends React.Component<React.PropsWithChildren<Props>, State> {
  private retryCounter = 0;
  public state: State = {
    hasError: false,
    isRetrying: false,
  };

  static getDerivedStateFromError(): State {
    return { hasError: true, isRetrying: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    log.error('Something went wrong with the React code', error, errorInfo);
    this.retryCounter++;
    if (this.retryCounter >= MAX_RETRY_COUNTER) {
      this.props.onFail();
      this.retryCounter = 0;
    }
  }

  render() {
    const { message, onRetry, onFail, children } = this.props;
    const { hasError, isRetrying } = this.state;

    if (hasError) {
      return (
        <Container>
          <Text>{message}</Text>
          <NewButton
            disabled={isRetrying}
            onClick={async () => {
              this.setState((prevState) => ({ ...prevState, isRetrying: true }));
              try {
                await onRetry?.();
              } catch (error) {
                log.error(`Retry failed, we can't recover`, error);
                onFail();
              }
              this.setState((prevState) => ({ ...prevState, isRetrying: false, hasError: false }));
            }}
            text={isRetrying ? 'We are retrying' : 'Press to retry'}
          />
        </Container>
      );
    }

    return children;
  }
}

const ErrorBoundary: React.FC<React.PropsWithChildren<Partial<Props>>> = ({
  message = 'We are having some issue with the application',
  onFail,
  onRetry,
  children,
}) => {
  const logout = useLogout();

  return (
    <ErrorBoundaryClass message={message} onFail={onFail ?? logout} onRetry={onRetry}>
      {children}
    </ErrorBoundaryClass>
  );
};

export default ErrorBoundary;
