import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useSnackbarError } from '#/components/common/snackbar/useSnackbarError';

import { useGetCompaniesRetailerQuery, usePostCompanyMutation } from '#/store/api/company/company';
import { PostCompanyAccount } from '#/store/api/company/companyInterfaces';

export const useAddNewBrandForm = (onClose?: () => void) => {
  const [addCompany, { isLoading, error }] = usePostCompanyMutation();
  const { refetch } = useGetCompaniesRetailerQuery({ pageNumber: 1, numberOfRows: 50, searchQuery: '' });

  const schema = yup
    .object()
    .shape({
      companyType: yup.string().required('Please select company type'),
      name: yup.string().required('Company name is required'),
      parentId: yup.string()?.nullable(),
      companyUrl: yup.string(),
      mailingStreetAddress1: yup.string(),
      mailingStreetAddress2: yup.string(),
      mailingCity: yup.string(),
      mailingState: yup.string(),
      mailingCountry: yup.string(),
      mailingZip: yup.string(),
      isBillingSameAsMailing: yup.boolean(),
      billingStreetAddress1: yup.string(),
      billingStreetAddress2: yup.string(),
      billingCity: yup.string(),
      billingState: yup.string(),
      billingCountry: yup.string(),
      billingZip: yup.string(),
      legalName: yup.string().max(128),
      ein: yup
        .string()
        .matches(
          /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$|^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/,
          { message: 'Enter a valid Tax ID', excludeEmptyString: true },
        ),
      billType: yup.string().nullable(),
      vendorId: yup.string(),
      logoId: yup.string().nullable(),
    })
    .required();
  const {
    register,
    clearErrors,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<PostCompanyAccount>({
    resolver: yupResolver(schema),
    defaultValues: {
      isBillingSameAsMailing: true,
    },
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [successfulCreated, setSuccessfulCreated] = useState(false);
  const { setError } = useSnackbarError();

  const fetchCreateBrand = async (data: PostCompanyAccount) => {
    try {
      await addCompany({ body: data });
      setSuccessfulCreated(true);
      setErrorMessage('');
      onClose && onClose();
      refetch();
    } catch (error: unknown) {
      setError(error);
      if (error instanceof AxiosError) {
        setErrorMessage(error.response?.data.message);
      }
    }
  };
  useEffect(() => {
    if (watch('isBillingSameAsMailing')) {
      setValue('mailingStreetAddress1', watch('billingStreetAddress1'));
      setValue('mailingStreetAddress2', watch('billingStreetAddress2'));
      setValue('mailingCity', watch('billingCity'));
      setValue('mailingCountry', watch('billingCountry'));
      setValue('mailingState', watch('billingState'));
      setValue('mailingZip', watch('billingZip'));
    }
  }, [
    watch('isBillingSameAsMailing'),
    watch('billingStreetAddress1'),
    watch('billingStreetAddress2'),
    watch('billingCity'),
    watch('billingCountry'),
    watch('billingState'),
    watch('billingZip'),
  ]);
  const onSubmit = handleSubmit(async (formData: PostCompanyAccount) => {
    const updatedData = { ...formData, country: 'United States', parentId: formData?.parentId || null };
    await fetchCreateBrand(updatedData);
    clearErrors();
  });

  return {
    successfulCreated,
    register,
    setValue,
    onSubmit,
    errors,
    isLoading,
    errorMessage,
    handleSubmit,
    control,
    watch,
    error,
  };
};
