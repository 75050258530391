import { Box, CircularProgress } from '@mui/material';
import classnames from 'classnames';
import { useEffect, useState } from 'react';

import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';

import { usePutRetailerAccountFiscalYearGoalMutation } from '#/store/api/retailerAccount/retailerAccount';

import { THEME } from '../../../../constants/theme/constants';
import { useSnackbarError } from '../../../common/snackbar/useSnackbarError';
import { useGeneralSettingStyle } from './GeneralSettings.style';

interface IProps {
  goal: number;
}

export const FiscalYearGoal = ({ goal }: IProps) => {
  const [putRetailerAccountFiscalYearGoal, { isLoading: isPutRetailerAccountFiscalYearGoalLoading }] =
    usePutRetailerAccountFiscalYearGoalMutation({ fixedCacheKey: 'putRetailerAccountFiscalYearGoal' });
  const classes = useGeneralSettingStyle();
  const [fiscalGoal, setFiscalGoal] = useState(0);
  const { setError } = useSnackbarError();

  useEffect(() => {
    setFiscalGoal(goal);
  }, [goal]);

  const handleChange = (event) => {
    if (isNaN(Number(event?.target.value))) {
      setFiscalGoal(0);
    } else {
      setFiscalGoal(event?.target.value);
    }
  };

  const handleBlur = async () => {
    try {
      await putRetailerAccountFiscalYearGoal({ goal: fiscalGoal }).unwrap();
    } catch (error) {
      setError(error);
    }
  };

  return (
    <Box>
      <Box className={classes.goalInputContainer}>
        <TextFieldOld
          editMode
          label="Fiscal Year Goal:"
          onBlur={handleBlur}
          onChange={handleChange}
          useRegister={false}
          value={String(fiscalGoal)}
        />
        <Box className={classnames(classes.loader, { [classes.hidden]: !isPutRetailerAccountFiscalYearGoalLoading })}>
          <CircularProgress size={`${THEME.REM_SPACES[1]}`} />
        </Box>
      </Box>
    </Box>
  );
};
