import { Box } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { THEME } from '../../../../constants/theme/constants';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { useInfoStyles } from './InfoRow.styles';

interface InfoRowProps {
  label: string;
  value: string;
  link?: string;
}

const InfoRow: FC<InfoRowProps> = ({ label, value, link }) => {
  const classes = useInfoStyles();

  return (
    <Box className={classes.flexContainer}>
      <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} variant="Label_Base">
        {label}
      </TypographyWithFontFamily>
      {link === undefined ? (
        <TypographyWithFontFamily variant="H5_Regular">{value}</TypographyWithFontFamily>
      ) : (
        <Link className={classes.link} to={link}>
          <TypographyWithFontFamily cName={classes.link} color={THEME.PALETTES.TEXT.Action_Secondary} variant="H5_Base">
            {value}
          </TypographyWithFontFamily>
        </Link>
      )}
    </Box>
  );
};

export default InfoRow;
