import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import PartnerIcon from '../../../assets/PartnersLogo.svg';
import { partnersSubmenu } from '../../../constants/submenu';
import DisplayPageName from '../../common/displayPageName/DisplayPageName';
import { SubmenuBar } from '../../common/submenuBar/SubmenuBar';

export const Partners = () => {
  return (
    <Box>
      <DisplayPageName imgSrc={PartnerIcon} pageName="Partners" />
      <SubmenuBar tabs={partnersSubmenu} />
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
};
