import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const useTaskViewStyles = makeStyles(() =>
  createStyles({
    headCellClass: {
      borderWidth: '1px',
      border: '1px solid var(--border-extra-light, #E4E8EF)',
      background: 'var(--neutral-100, #F1F4F9)',
      color: ' var(--text-dark, #16191F)',
      fontFamily: 'Public Sans',
      fontSize: '11.5px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
    },
    headClass: {
      height: '3rem',
      color: ' var(--text-dark, #16191F)',
      fontFamily: 'Public Sans',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    taskHyperLink: {
      color: 'blue',
    },
    bodyCellClass: {
      height: '3rem',
    },
    targetObjectLink: {
      textDecoration: 'none',
    },
  }),
);

export const styles = {
  targetObjectText: {
    color: THEME.PALETTES.TEXT.Action_Secondary,
    fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
    cursor: 'pointer',
    fontSize: '0.875rem',
  },
};

export const refetchButtonStyle = { padding: '0px' };
