import { Link } from 'react-router-dom';

import TableLogoCell from '#/components/common/tableLogoCell/TableLogoCell';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { useBrandStyles } from '#/components/features/partners/brands/brands.styles';
import { ROUTE_PATHS } from '#/constants/routing/routePaths';

import { useAppDispatch } from '#/hooks';

import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { ICommunicationsCompaniesResponse } from '#/store/api/communications/communication-interface';
import { communicationsPromote } from '#/store/slices';

import { convertAllCapsUnderscoreStrings, formatColumnToHaveComma } from '#/utils/utils';

const getCommunicationsSelectBrandsTabletTableColumns = (data, selectBrandIds, selectedCampaigns, columNames) => {
  if (!data) return [];
  const sortableColumns = columNames.map((el) => el.columnName);

  const dispatch = useAppDispatch();
  const styles = useBrandStyles();

  const createCustomNameImageView = (aggregateId: string, logoId: string | null, companyName: string) => (
    <TableLogoCell link={`/partners/brands/${aggregateId}`} logoId={logoId} name={companyName} />
  );

  const tableColumns: ITableColumnsProps<ICommunicationsCompaniesResponse>[] = [
    {
      fieldName: null,
      customHead: ' ',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.CHECKBOX,
      checkBoxAction: (checked: boolean, aggregateId: string) => {
        const brandItemCheck = data?.find((brand) => brand.aggregateId === aggregateId);
        brandItemCheck && dispatch(communicationsPromote.actions.updateSelectedBrands(brandItemCheck));
      },
      actionElementIdentifier: 'aggregateId',
      checkFromList: selectBrandIds,
      hasCheckAll: true,
      checkAllAction: (checked) => {
        if (checked && data) {
          dispatch(communicationsPromote.actions.selectAllBrands(data));
        } else {
          dispatch(communicationsPromote.actions.clearSelectedBrands());
        }
      },
    },
    {
      fieldName: 'companyName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Company Name ',
      actionElementIdentifier: 'aggregateId',
      actionElementSecondIdentifier: 'logoId',
      actionElementThirdIdentifier: 'companyName',
      getCustomElement: createCustomNameImageView,
      sortable: sortableColumns.includes('companyName'),
    },
    {
      hide: selectedCampaigns.length > 1,
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      textFormatter: (text) => convertAllCapsUnderscoreStrings(text.toString()) || 'Not Sent',
      customHead: 'Invitation Status',
      sortLookUpFieldName: 'instance.status',
      sortable: sortableColumns.includes('instance.status'),
    },
    {
      hide: selectedCampaigns.length > 1,
      fieldName: 'tierName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      textFormatter: (text) => text.toString() || 'N/A',
      sortLookUpFieldName: 'instance.tierName',
      sortable: sortableColumns.includes('instance.tierName'),
      customHead: 'Tier Level',
    },
    {
      hide: selectedCampaigns.length > 1,
      fieldName: 'tierBudget',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      textFormatter: (text) => `$${text.toString() || '0'}`,
      sortLookUpFieldName: 'instance.tierBudget',
      sortable: sortableColumns.includes('instance.tierBudget'),
      customHead: 'Amount',
    },
    {
      hide: selectedCampaigns.length > 1,
      fieldName: null,
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      actionElementIdentifier: 'agencies',
      sortLookUpFieldName: 'agencies',
      getCustomElement: (agencies: { name: string; companyId: string }[]) => (
        <TypographyWithFontFamily className={styles.customHrefColor}>
          {agencies?.map((agency, index) => (
            <Link
              key={agency.companyId}
              to={`/${ROUTE_PATHS.PARTNERS}/${ROUTE_PATHS.PARTNERS_AGENCIES}/${agency.companyId}`}
            >
              {formatColumnToHaveComma(agency.name, index, agencies)}
            </Link>
          ))}
        </TypographyWithFontFamily>
      ),
      customHead: 'Agency',
      sortable: sortableColumns.includes('agencies'),
    },
  ];

  return tableColumns;
};
export default getCommunicationsSelectBrandsTabletTableColumns;
