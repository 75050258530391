import { Box } from '@mui/system';
import classNames from 'classnames';
import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { THEME } from '../../../constants/theme/constants';
import { ICampaignChannelPartner } from '../../../store/api/campaigns/campaignsInterfaces';
import { convertAllCapsUnderscoreStrings } from '../../../utils/utils';
import CampaignThumbnail from '../CampaignThumbnail/CampaignThumbnail';
import CommittedBrandsLink from '../CommittedBrandsLink/CommittedBrandsLink';
import LabelWithValue from '../LabelWithValue/LabelWithValue';
import NewButton from '../button/NewButton';
import { TypographyWithFontFamily } from '../typography/TypographyWithFontFamily';
import { useCampaignDetailStyles } from './CampaignDetail.styles';

interface CampaignDetailChannelPartnerProps {
  data: ICampaignChannelPartner;
}
const CampaignDetailChannelPartner: FC<CampaignDetailChannelPartnerProps> = ({ data }) => {
  const navigate = useNavigate();
  const classes = useCampaignDetailStyles();

  const navigateToCampaign = () => {
    navigate(`/campaigns/overview/${data.campaignId}`);
  };

  return (
    <Box>
      <CampaignThumbnail thumbnailId={data.thumbnailId} />
      <Box className={classes.row}>
        <TypographyWithFontFamily classNames={classes.campaignName} variant="H4_Base">
          <Link className={classes.links} to={`/campaigns/overview/${data.campaignId}`}>
            {data.campaignName}
          </Link>
        </TypographyWithFontFamily>
      </Box>

      <Box className={classNames(classes.row, classes.topBorder)}>
        <LabelWithValue label={'STATUS:'} value={convertAllCapsUnderscoreStrings(data?.status)} />
        <LabelWithValue label={'STAGE:'} value={convertAllCapsUnderscoreStrings(data?.stage)} />
      </Box>

      <Box className={classes.row}>
        <LabelWithValue label={'MARKET DATES:'} value={`${data.startDate}-${data.endDate}`} />
        <LabelWithValue
          label={'DEADLINE:'}
          placeholder={'No submission deadline provided'}
          value={data.submissionDeadline}
        />
      </Box>

      <Box className={classes.row}>
        <Box className={classes.container}>
          <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} variant="Label_Base">
            COMMITTED BRANDS:
          </TypographyWithFontFamily>
          <CommittedBrandsLink
            brandsTargeted={data.brandsTargeted}
            campaignId={data.campaignId}
            committedBrands={data.committedBrands}
          />
        </Box>
      </Box>

      <Box className={classNames(classes.bottomSection, classes.topBorder)}>
        <Box className={classes.buttonsSection}>
          <NewButton loading={false} onClick={navigateToCampaign} text="Open" variant="Secondary" />
        </Box>
      </Box>
    </Box>
  );
};
export default CampaignDetailChannelPartner;
