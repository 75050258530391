export const extractNumber = (input: string | number): number => {
  if (typeof input === 'number') {
    return input;
  }
  const hasLeadingNegativeSign = /^\s*-\d/.test(input.replace(/[^\d-]/g, ''));

  const numbersAndDots = input.replace(/[^0-9.-]/g, '');

  const parts = numbersAndDots.split('.');
  const result = parts.length > 1 ? `${parts.slice(0, -1).join('')}.${parts.at(-1)}` : parts.join('');

  const number = parseFloat(result);
  return isNaN(number) ? 0 : hasLeadingNegativeSign ? -number : number;
};

export const numberFormatter = (text: string | number, options?: Intl.NumberFormatOptions) => {
  const DEFAULT_OPTIONS = {
    maximumFractionDigits: 0,
  };

  const formatter = new Intl.NumberFormat('en-US', {
    ...(typeof options === 'object'
      ? {
          ...DEFAULT_OPTIONS,
          ...options,
        }
      : DEFAULT_OPTIONS),
  });

  const value = Number(text);
  return formatter.format(isNaN(value) ? 0 : value);
};

// Currency type from NumberFormatOptions is a best effort, so this type should be extended as needed.
export type Options = Omit<Intl.NumberFormatOptions, 'currency' | 'style'> & {
  currency?: 'USD' | 'EUR';
};

export function currencyFormatter(name: string | number, options?: Options): string;
export function currencyFormatter(name: string | number, index: number, options?: Options): string;
export function currencyFormatter(text: string | number, ...rest: [Options?] | [number, Options?]) {
  const DEFAULT_OPTIONS = {
    currency: 'USD',
    maximumFractionDigits: 0,
  } satisfies Options;

  const options = rest.length === 2 ? rest[1] : rest[0];
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    ...(typeof options === 'object'
      ? {
          ...DEFAULT_OPTIONS,
          ...options,
        }
      : DEFAULT_OPTIONS),
  });

  const value = Number(text);
  return formatter.format(isNaN(value) ? 0 : value);
}

export const nonAlphaNumericToDash = (str?: string) => {
  if (!str) {
    return '';
  }
  return str
    .replace(/[^a-zA-Z0-9]/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-|-$/g, '');
};

export const camelOrPascalToDash = (str: string) =>
  str
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1-$2')
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/[\s_]+/g, '-');

export const camelOrPascalToRegular = (str: string) =>
  str
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1-$2')
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/[\s_]+/g, ' ');

export const capitalizeAll = (str: string) => str.replace(/\b\w/g, (l) => l.toUpperCase());

/**
 * A workaround to format the value based on the label, not the best solution but it works for now
 * @param value
 * @param label
 */
export const getFormattedValue = (value?: string, label?: string) => {
  if (!value) {
    return value;
  }

  switch (label) {
    case 'campaignGoal':
      return currencyFormatter(value);
    default:
      return value;
  }
};

export const createFormatterWithReverse = (
  format: (text: string | number) => string,
  reverse: (text: string) => string | number,
) => Object.assign(format, { reverse });

export const percentageFormatter = (value: string | number) => `${numberFormatter(value)}%`;
