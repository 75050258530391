import { CompanyType } from '../../constants/companyType/CompanyType';
import { IBrandData } from '../../interfaces/forms/campaignInvitation';
import { retailerAxios } from '../axiosConfig';

export async function getCampaignDetails(campaignId: string) {
  return Promise.all([
    retailerAxios.get(`/campaign/${campaignId}/overview/internal-section`),
    retailerAxios.get(`campaign/${campaignId}/overview/general`),
    retailerAxios.get(`/campaign/${campaignId}/product-mix`),
  ]);
}

export const getBrandsTableSelection = async (searchBrands: string | undefined) => {
  return retailerAxios.post<IBrandData[]>(`/company-account/table-selection`, {
    type: CompanyType.BRAND,
    searchString: searchBrands,
  });
};

export const getOriginalCampaignInvitation = async (campaignId: string | undefined) => {
  return retailerAxios.get(`/promote/${campaignId}`);
};

export const getRecipientsList = async (companyIds: string[]) => {
  return retailerAxios.post(`company-account/users`, {
    companyIds,
  });
};
export const getBrandsCsv = async (idList: string[] | undefined) => {
  return retailerAxios.post(`/company-account/export`, { companyIds: idList }, { responseType: 'blob' });
};

export const getRecipientsCsv = async (idList: string[] | undefined) => {
  return retailerAxios.post(`/company-user/export`, { emails: idList }, { responseType: 'blob' });
};
