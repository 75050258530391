export enum USER_PERMISSIONS {
  // MENU
  BRANDS = 'brands',
  CAMPAIGNS = 'campaigns',
  PROMOTE = 'promote',
  PARTNERS = 'partners',
  WORKFLOW = 'workflow',
  CALENDAR = 'calendar',
  SETTINGS = 'settings',
  REPORTS = 'report',

  // PAGES SUBMENUS

  //FIXME We have a lot of duplicated permissions, we need to clean this up or implement different permissions on the BE
  /* eslint-disable @typescript-eslint/no-duplicate-enum-values */

  // promote
  PROMOTE_PENDING_INVITATIONS = 'turbyne.retailer.promote:read',

  // partners
  PARTNERS_BRANDS = 'partners',
  PARTNERS_AGENCIES = 'partners',
  PARTNERS_USERS = 'partners',
  PARTNERS_USER_ACCESS = 'partners',
  CHANNEL_PARTNER_CAMPAIGN_TACTICS_READ = 'turbyne.retailer.channel-partner.campaign-tactics:read',
  CHANNEL_PARTNER_CAMPAIGN_INSTANCES_READ = 'turbyne.retailer.channel-partner.campaign.instances:read',
  RETAILER_CHANNEL_PARTNER_ACCOUNT_READ = 'turbyne.retailer.channel-partner-account:read',

  //settings
  SETTINGS_CHANNEL_PARTNER = 'settings',
  SETTINGS_USER = 'settings',
  SETTINGS_REPORT = 'settings',
  SETTINGS_PRODUCT_MIX = 'settings',
  SETTINGS_EDIT_RETAILER = 'settings',
  SETTINGS_GENERAL = 'settings',
  SETTINGS_TACTICS = 'settings',
  SETTINGS_TACTICS_METADATA = 'settings',
  SETTINGS_BRAND_USER = 'settings',
  SUPER_ADMIN = 'settings',
  SETTINGS_DATA_JOBS = 'settings',

  // >>>PAGES SUBSECTIONS<<<
  // ===CAMPAIGN===

  // ---SHELL---
  // RETAILER
  CAMPAIGN_OVERVIEW = 'turbyne.retailer.campaign:read',
  CAMPAIGN_CHANNEL_TACTICS = 'turbyne.retailer.campaign.channels-and-tactics:read',
  CAMPAIGN_CHANNEL_TACTICS_WRITE = 'turbyne.retailer.campaign-tier:write',
  CAMPAIGN_CHANNEL_TACTICS_DELETE = 'turbyne.retailer.campaign-tier:delete',

  CAMPAIGN_TARGET_PRODUCT_MIX = 'turbyne.retailer.campaign.target-product-mix:read',
  CAMPAIGN_BRANDS = 'turbyne.retailer.campaign.brands:read',
  CAMPAIGN_INSTANCES = 'turbyne.retailer.campaign.instances:read',
  CAMPAIGN_CALENDAR_READ = 'turbyne.retailer.campaign.calendar:read',

  CAMPAIGN_CHANNEL_TACTICS_CREATE = 'turbyne.retailer.campaign.channels-and-tactics:create',
  RETAILER_CHANNEL_AND_TACTICS_INVENTORY_READ = 'turbyne.retailer.campaign.channels-and-tactics.inventory:read',
  CAMPAIGN_HISTORY_READ = 'turbyne.retailer.campaign.history:read',
  CAMPAIGN_ENGAGEMENT_STATS = 'turbyne.retailer.campaign.instance.invitation:read', //this permission will change

  // BRAND
  CAMPAIGN_BRAND_CHANNEL_TACTICS = 'turbyne.retailer.brand.campaign.channels-and-tactics:read',
  BRAND_CHANNEL_AND_TACTICS_INVENTORY_READ = 'turbyne.retailer.brand.campaign.channels-and-tactics.inventory:read',

  // CHANNEL PARTNER
  CAMPAIGN_CHANNEL_PARTNER_CHANNEL_TACTICS = 'turbyne.retailer.channel-partner.channels-and-tactics:read',
  CAMPAIGN_CHANNEL_PARTNER_INSTANCES = 'turbyne.retailer.channel-partner.campaigns:read',
  CHANNEL_PARTNER_CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS_RIBBON_READ = 'turbyne.retailer.channel-partner.campaign.instance.channels-and-tactics.ribbon:read',
  CHANNEL_PARTNER_CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS_INVENTORY_READ = 'turbyne.retailer.channel-partner.campaign.instance.channels-and-tactics.inventory:read',
  CHANNEL_PARTNER_CAMPAIGN_CHANNEL_AND_TACTICS_RIBBON_READ = 'turbyne.retailer.channel-partner.campaign.channels-and-tactics.ribbon:read',
  CHANNEL_PARTNER_CAMPAIGN_CHANNEL_AND_TACTICS_INVENTORY_READ = 'turbyne.retailer.channel-partner.campaign.channels-and-tactics.inventory:read',
  CHANNEL_PARTNER_CHANNEL_AND_TACTICS_INVENTORY_READ = 'turbyne.retailer.channel-partner.campaign.channels-and-tactics.inventory:read',
  CHANNEL_PARTNER_CHANNEL_AND_TACTICS_INSTANCE_INVENTORY_READ = 'turbyne.retailer.channel-partner.campaign.instance.channels-and-tactics.inventory:read',
  CAMPAIGN_CHANNEL_PARTNER_CALENDAR_READ = 'turbyne.retailer.channel-partner.campaign.calendar:read',

  // ---INSTANCE---
  // RETAILER
  CAMPAIGN_INSTANCE_OVERVIEW = 'turbyne.retailer.campaign.instance.overview:read',
  CAMPAIGN_INSTANCE_CHANNEL_TACTICS = 'turbyne.retailer.campaign.instance.channels-and-tactics:read',
  CAMPAIGN_INSTANCE_CHANNEL_TACTICS_WRITE = 'turbyne.retailer.campaign.instance.tier:write',
  CAMPAIGN_INSTANCE_CHANNEL_TACTICS_DELETE = 'turbyne.retailer.campaign.instance.tier:write',
  CAMPAIGN_INSTANCE_ASSETS = 'turbyne.retailer.campaign.instance.assets.tactic.metadata:read',
  CAMPAIGN_INSTANCE_ASSETS_WRITE = 'turbyne.retailer.campaign.instance.assets.tactic.metadata:write',
  CAMPAIGN_INSTANCE_CREATIVES_SPECS_WRITE = 'turbyne.retailer.asset:write',

  CAMPAIGN_INSTANCE_STORES_WRITE = 'turbyne.retailer.campaign.instance.stores:write',
  CAMPAIGN_INSTANCE_OVERVIEW_INTERNAL_READ = 'turbyne.retailer.campaign.instance.overview.internal:read',
  RETAILER_CHANNEL_AND_TACTICS_INVENTORY_INSTANCE_READ = 'turbyne.retailer.campaign.instance.channels-and-tactics.inventory:read',

  // BRAND
  CAMPAIGN_INSTANCE_BRAND_ASSETS = 'turbyne.retailer.brand.campaign.instance.assets:read',
  CAMPAIGN_INSTANCE_BRAND_ASSETS_WRITE = 'turbyne.retailer.brand.campaign.instance.assets:write',
  CAMPAIGN_INSTANCE_BRAND_ASSETS_DELETE = 'turbyne.retailer.brand.campaign.instance.assets:delete',
  CAMPAIGN_INSTANCE_BRAND_CHANNEL_TACTICS = 'turbyne.retailer.brand.campaign.instance.channels-and-tactics:read',
  CAMPAIGN_BRAND_INSTANCES = 'turbyne.retailer.brand.campaign.instances:read',
  BRAND_CAMPAIGN_INSTANCE_INVITATION_READ = 'turbyne.retailer.brand.campaign.instance.invitation:read',
  BRAND_CAMPAIGN_INSTANCE_OVERVIEW_LEGAL_TERMS_READ = 'turbyne.retailer.brand.campaign.instance.overview.legal-terms:read',
  BRAND_CHANNEL_AND_TACTICS_INVENTORY_INSTANCE_READ = 'turbyne.retailer.brand.campaign.instance.channels-and-tactics.inventory:read',
  BRAND_CAMPAIGN_CALENDAR_READ = 'turbyne.retailer.brand.campaign.calendar:read',
  BRAND_CAMPAIGN_INSTANCES_ASSETS_TACTICS = 'turbyne.retailer.campaign.instance.assets.tactics:read',

  // >>>TAB SUBSECTIONS<<<
  // ===CAMPAIGN===

  // ---SHELL---
  // Overview retailer
  CAMPAIGN_OVERVIEW_GENERAL_READ = 'turbyne.retailer.campaign.overview.general:read',
  CAMPAIGN_OVERVIEW_INTERNAL_READ = 'turbyne.retailer.campaign.overview.internal:read',
  CAMPAIGN_OVERVIEW_MARKETING_READ = 'turbyne.retailer.campaign.overview.marketing:read',
  CAMPAIGN_OVERVIEW_LEGAL_READ = 'turbyne.retailer.campaign.overview.legal-terms:read',
  CAMPAIGN_OVERVIEW_CREATIVE_SPECS_READ = 'turbyne.retailer.campaign.overview.creative-specs:read',

  CAMPAIGN_OVERVIEW_GENERAL_WRITE = 'turbyne.retailer.campaign.overview.general:write',
  CAMPAIGN_OVERVIEW_INTERNAL_WRITE = 'turbyne.retailer.campaign.overview.internal:write',
  CAMPAIGN_OVERVIEW_MARKETING_WRITE = 'turbyne.retailer.campaign.overview.marketing:write',
  CAMPAIGN_OVERVIEW_LEGAL_WRITE = 'turbyne.retailer.campaign.overview.legal-terms:write',
  CAMPAIGN_OVERVIEW_CREATIVE_SPECS_WRITE = 'turbyne.retailer.campaign.overview.creative-specs:write',

  // --- STORES for instance will be implemented later ---
  CAMPAIGN_STORES_LIST_WRITE = 'turbyne.retailer.campaign.store-filter:write',
  CAMPAIGN_INSTANCE_STORES_LIST = 'turbyne.retailer.campaign.instance.stores',
  CAMPAIGN_BRAND_STORES_LIST = 'turbyne.retailer.brand.campaign.stores',
  CAMPAIGN_BRAND_INSTANCE_STORES_LIST = 'turbyne.retailer.brand.campaign.instance.stores',

  // ---INSTANCE---
  // overview retailer
  CAMPAIGN_INSTANCE_OVERVIEW_GENERAL_READ = 'turbyne.retailer.campaign.instance.overview.general:read',
  CAMPAIGN_INSTANCE_READ = 'turbyne.retailer.campaign.instance.overview.internal:read',
  CAMPAIGN_INSTANCE_OVERVIEW_MARKETING_READ = 'turbyne.retailer.campaign.instance.overview.marketing:read',
  CAMPAIGN_INSTANCE_OVERVIEW_LEGAL_READ = 'turbyne.retailer.campaign.instance.overview.legal-terms:read',
  CAMPAIGN_INSTANCE_OVERVIEW_CREATIVE_SPECS_READ = 'turbyne.retailer.campaign.instance.overview.creative-specs:read',
  CAMPAIGN_INSTANCE_OVERVIEW_GENERAL_WRITE = 'turbyne.retailer.campaign.instance:write',
  CAMPAIGN_INSTANCE_OVERVIEW_INTERNAL_WRITE = 'turbyne.retailer.campaign.instance.overview.internal:write',
  CAMPAIGN_INSTANCE_OVERVIEW_MARKETING_WRITE = 'turbyne.retailer.campaign.instance.overview.marketing:write',
  CAMPAIGN_INSTANCE_OVERVIEW_LEGAL_WRITE = 'turbyne.retailer.campaign.instance.overview.legal-terms:write',
  CAMPAIGN_INSTANCE_OVERVIEW_CREATIVE_SPECS_WRITE = 'turbyne.retailer.campaign.overview.creative-specs:write',

  CAMPAIGN_INSTANCE_HISTORY_READ = 'turbyne.retailer.campaign.instance.history:read',
  // CAMPAIGN ACTIONS
  CAMPAIGN_CREATE = 'turbyne.retailer.campaign:create',

  // overview brand
  CAMPAIGN_INSTANCE_OVERVIEW_LEGAL_BRAND_READ = 'turbyne.retailer.brand.campaign.instance.legal-terms:read',

  // DASHBOARD
  DASHBOARD_QUICK_LINKS_RETAILER_READ = 'turbyne.dashboard.quick-links.retailer:read',
  DASHBOARD_QUICK_LINKS_BRAND_READ = 'turbyne.dashboard.quick-links.brand:read',
  DASHBOARD_QUICK_LINKS_CHANNEL_PARTNER_READ = 'turbyne.dashboard.quick-links.channel-partner:read',

  DASHBOARD_OVERVIEW_READ = 'turbyne.dashboard.overview:read',

  // Company
  COMPANY_ACCOUNT_READ = 'turbyne.retailer.company.account:read',
  COMPANY_USER_WRITE = 'turbyne.retailer.company.user:write',
  COMPANY_USER_READ = 'turbyne.retailer.company.user:read',

  // Account
  ACCOUNT_READ = 'turbyne.retailer.account:read',

  // User
  USER_READ = 'turbyne.user.user:read',

  ADMIN_DATA_JOB_READ = 'turbyne.retailer.account.settings.data-jobs:read',
  ADMIN_DATA_JOB_WRITE = 'turbyne.retailer.account.settings.data-jobs:write',
  ADMIN_DATA_JOB_DELETE = 'turbyne.retailer.account.settings.data-jobs:delete',

  ADMIN_SETTINGS_REPORTS = 'turbyne.retailer.account.settings.reports:read',

  // new permissions
  PARTNERS_COMPANY_BRAND_GENERAL_SECTION_WRITE = 'partners.company.brand.general.section:write',
  PARTNERS_COMPANY_BRAND_BILLING_SECTION_WRITE = 'partners.company.brand.billing.section:write',
  PARTNERS_COMPANY_BRAND_USERS_SECTION_WRITE = 'partners.company.brand.users.section:write',
  PARTNERS_COMPANY_BRAND_PRODUCT_MIX_SECTION_WRITE = 'partners.company.brand.product.mix.section:write',

  PARTNERS_COMPANY_AGENCY_GENERAL_SECTION_WRITE = 'partners.company.agency.general.section:write',
  PARTNERS_COMPANY_AGENCY_BILLING_SECTION_WRITE = 'partners.company.agency.billings.section:write',
  PARTNERS_COMPANY_AGENCY_USERS_SECTION_WRITE = 'partners.company.agency.users.section:write',
  PARTNERS_COMPANY_AGENCY_ASSOCIATE_BRANDS_SECTION_WRITE = 'partners.company.agency.associate.brands.section:write',

  CAMPAIGNS_CHANNEL_AND_TACTICS_TACTIC_MODAL_WRITE = 'campaigns.channel.and.tactics.tactic.modal:write',
  INSTANCE_CHANNEL_AND_TACTICS_TACTIC_MODAL_WRITE = 'instance.channel.and.tactics.tactic.modal:write',

  CAMPAIGNS_TARGET_PRODUCT_MIX = 'campaigns.target.product.mix:write',

  COMMUNICATIONS_PROMOTE_PROMOTE_SECTION_WRITE = 'communication.promote.promote.section:write',

  PARTNERS_COMPANIES_ADD_COMPANY_WRITE = 'partners.companies.add.company:write',

  PARTNERS_USERS_ADD_USER_WRITE = 'partners.users.add.user:write',
  PARTNERS_USERS_GENERAL_SECTION_WRITE = 'partners.users.general.section:write',
  PARTNERS_USERS_BILLING_SECTION_WRITE = 'partners.users.billing.section:write',
  PARTNERS_USERS_ASSOCIATE_BRANDS_SECTION_WRITE = 'partners.users.associate.brands.section:write',
  PARTNERS_USERS_ACTIVATE_DEACTIVATE_USER_WRITE = 'partners.users.activate.deactivate.user:write',
  PARTNERS_USERS_ACCESS_GRANT_DENY_WRITE = 'partners.users.access.grant.deny:write',

  PARTNERS_CHANNEL_PARTNERS_ADD_USERS_WRITE = 'partners.channel.partners.add.users:write',
  /* eslint-enable */
}

export const ALL_USER_PERMISSION = 'allUsers';
