import { IInstanceTactics } from '#/interfaces/forms/tacticAssets';
import { IMetadataDTO } from '#/interfaces/tacticMetadata/tacticMetadata';

import { baseApi } from '../../base';

const tacticAssetForm = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getInstanceAssetMetadata: builder.query<IMetadataDTO, { id: string | undefined }>({
      query: ({ id }) => `/retailer/instance-asset-metadata/${id}`,
      providesTags: ['CAMPAIGN_ASSETS_INSTANCE'],
    }),
    getInstanceTactics: builder.query<IInstanceTactics[], { id: string | undefined }>({
      query: ({ id }) => `/retailer/instance-tactic/${id}/asset-tactics`,
      providesTags: ['CAMPAIGN_ASSETS_INSTANCE'],
    }),
    editInstanceAssetMetadataForm: builder.mutation<void, { id: string | undefined; formData: string }>({
      query: ({ id, formData }) => ({
        url: `/retailer/instance-asset-metadata/${id}`,
        method: 'PUT',
        body: formData,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['CAMPAIGN_ASSETS_INSTANCE'],
    }),
    getInstanceAssetMetadataTacticDownload: builder.mutation<
      { contentDisposition: string | null; file: Blob },
      {
        instanceTacticId: string;
      }
    >({
      query: ({ instanceTacticId }) => {
        return {
          url: `retailer/instance-asset-metadata/tactic/${instanceTacticId}/download`,
          method: 'GET',
          responseHandler: async (response) => {
            return { file: await response.blob(), contentDisposition: response.headers.get('content-disposition') };
          },
        };
      },
    }),
  }),
});

export const {
  useGetInstanceAssetMetadataQuery,
  useEditInstanceAssetMetadataFormMutation,
  useGetInstanceTacticsQuery,
  useGetInstanceAssetMetadataTacticDownloadMutation,
} = tacticAssetForm;
