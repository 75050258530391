import { Client, IFrame } from '@stomp/stompjs';

import { WEBSOCKET_URL } from '#/constants/general';

import log from '#/utils/log';

export const stomp = (() => {
  let _client: Client | undefined;

  type callbacks = {
    onConnect: (frame: IFrame) => void;
    onStompError: (frame: IFrame) => void;
  };

  const initialize = (authorization: string, callbacks: callbacks) => {
    if (_client) {
      return;
    }
    // Initiate websocket connection once we are logged in
    const client = new Client({
      brokerURL: WEBSOCKET_URL || '',
      connectHeaders: {
        Authorization: authorization,
      },
    });

    client.onConnect = callbacks.onConnect;

    client.onStompError = (frame) => {
      log.error('Broker reported error: ' + frame.headers['message']);
      log.error('Additional details: ' + frame.body);
    };

    log.debug('Initializing websocket connection');
    client.activate();

    _client = client;
  };

  const get = () => _client;

  return {
    initialize,
    get,
  };
})();
