import { useEffect } from 'react';

import DynamicRibbon from '#/components/features/dynamicRibbon/DynamicRibbon';

import { useGetRibbonTasksInstanceProductsMutation } from '#/store/api/ribbon/ribbon';

import useGetResource from '../../../../../hooks/useGetResource';

export const DynamicRibbonTasksInstanceProducts = () => {
  const resource = useGetResource();

  const [getTasksInstanceProducts, { data: dataRibbon, isLoading }] = useGetRibbonTasksInstanceProductsMutation();

  const getTasksInstanceProductsCall = () => getTasksInstanceProducts({ instanceId: resource.resourceId });

  useEffect(() => {
    getTasksInstanceProductsCall();
  }, []);

  return <DynamicRibbon isLoading={isLoading} response={dataRibbon} submitCallback={getTasksInstanceProductsCall} />;
};
