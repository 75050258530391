import { IProductMixTable } from '../interfaces/productMix/ProductMix';
import { retailerAxios } from './axiosConfig';
import { IRequestDto } from './requestDtoInterface';

// TODO: move this to redux
export const getProductMixCategories = (productName: string): Promise<IRequestDto<IProductMixTable[]>> => {
  return retailerAxios.get(`/product-mix/${productName}`);
};

export const getProductMixSubCategories = (categoryId: string): Promise<IRequestDto<IProductMixTable[]>> => {
  return retailerAxios.get(`/product-mix/${categoryId}/subcategories`);
};
