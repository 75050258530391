import { useParams } from 'react-router';

import Loader from '#/components/common/loader/loader';
import { CampaignInstancesRetailer } from '#/components/features/campaigns/instances/list/CampaignInstancesRetailer/CampaignInstancesRetailer';

import { useGetInstancesRetailerFilterQuery } from '#/store/api/campaigns/instances';

export const CampaignInstancesRetailerContainer = () => {
  const { campaignId } = useParams();
  const { isLoading: filterDataIsLoading } = useGetInstancesRetailerFilterQuery({ campaignId });

  if (filterDataIsLoading) return <Loader />;

  return <CampaignInstancesRetailer />;
};
