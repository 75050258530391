import { createStyles, makeStyles } from '#/utils/theme';

export const useSearchErrorStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: '100%',
      margin: 'auto',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      width: '100%',
      flexDirection: 'column',
    },
    image: { height: theme.custom.REM_SPACES[10], opacity: 0.1 },
    messageContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    message: {
      margin: 'auto',
      color: theme.custom.PALETTES.TEXT.Gray,
      maxWidth: '25 rem',
    },
    button: {
      marginTop: theme.custom.REM_SPACES[1],
    },
  }),
);
