import { createStyles, makeStyles } from '#/utils/theme';

// TODO: use correct colors
export const useAssociatedAgenciesStyles = makeStyles((theme) =>
  createStyles({
    associateWrap: { display: 'flex', marginBottom: '1.25rem' },
    flexBetween: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    mrImg: {
      marginRight: theme.custom.REM_SPACES['0.5'],
    },
    bodyCellClass: {
      height: '48px',
    },
    headCellClass: {
      borderWidth: '1px',
      border: '1px solid var(--border-extra-light, #E4E8EF)',
      background: 'var(--neutral-100, #F1F4F9)',
      color: ' var(--text-dark, #16191F)',
      fontSize: '0.71875rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
    },
    headClass: {
      height: '48px',
      color: ' var(--text-dark, #16191F)',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    custom: {
      border: '1px solid #ccc',
      background: '#fff',
    },
  }),
);
