import { THEME } from '#/constants/theme/constants';

import {
  ICommunicationsDTO,
  ICommunicationsStatistics,
  IGeneralCommunicationsTableData,
  IMessagesEngagementChartData,
} from '#/interfaces/communication/communication';

export const formatMessagesEngagement = (data: ICommunicationsDTO): IMessagesEngagementChartData[] => {
  return data.statistics.map((dataElement: ICommunicationsStatistics) => {
    const {
      accepted,
      delivered,
      opened,
      clicked,
      failedDelivery: { total: failedTotal },
      time,
    } = dataElement;
    const total = accepted + delivered + opened + failedTotal;
    return { accepted, delivered, opened, clicked, time, failed: failedTotal, total };
  });
};

export const formatGeneralCommunicationsTableData = (data: ICommunicationsDTO): IGeneralCommunicationsTableData[] => {
  return data.statistics.map((dataElement: ICommunicationsStatistics) => {
    const {
      opened,
      clicked,
      unsubscribed,
      failedDelivery: {
        permanent: { suppressBounce },
      },
      complained,
    } = dataElement;
    return { clicked, unsubscribed, opened, complained, bounces: suppressBounce };
  });
};

export const legendColors = [
  {
    title: 'Accepted',
    color: THEME.PALETTES.LEGEND.ACCEPTED,
  },
  {
    title: 'Delivered',
    color: THEME.PALETTES.LEGEND.DELIVERED,
  },
  {
    title: 'Failed',
    color: THEME.PALETTES.LEGEND.FAILED,
  },
  {
    title: 'Opened',
    color: THEME.PALETTES.LEGEND.OPENED,
  },
  {
    title: 'Clicked',
    color: THEME.PALETTES.CHARTS.BOOKED,
  },
];
