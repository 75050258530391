import { CampaignInstancesBrandContainer } from '#/components/features/campaigns/instances/list/CampaignInstancesBrand/CampaignInstancesBrandContainer';
import { CampaignInstancesChannelPartnerContainer } from '#/components/features/campaigns/instances/list/CampaignInstancesChannelPartner/CampaignInstancesChannelPartnerContainer';
import { CampaignInstancesRetailerContainer } from '#/components/features/campaigns/instances/list/CampaignInstancesRetailer/CampaignInstancesRetailerContainer';

import UserTypeWrapper from '#/utils/UserTypeWrapper';

export const CampaignInstances = () => {
  return (
    <UserTypeWrapper
      agency={<CampaignInstancesBrandContainer />}
      brand={<CampaignInstancesBrandContainer />}
      channel-partner={<CampaignInstancesChannelPartnerContainer />}
      retailer={<CampaignInstancesRetailerContainer />}
    />
  );
};
