import SignOutIcon from '#/assets/SignOutIcon.svg';

import { useLogout } from '#/hooks/useLogout';

import { useLogoutStyles } from './Login.style';

export const LogoutButton = () => {
  const classes = useLogoutStyles();
  const logout = useLogout();

  return (
    <button className={classes.logoutButton} onClick={logout}>
      <img className={classes.image} src={SignOutIcon} /> Logout
    </button>
  );
};
