import MUITextField from '@mui/material/TextField';
import classNames from 'classnames';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';

import { THEME } from '#/constants/theme/constants';

import { useDataCy } from '#/hooks/useDataCy';

import { TypographyWithFontFamily } from '../../typography/TypographyWithFontFamily';
import { useInputsStyle } from '../Inputs.styles';
import { disabledInputSx, styles, useTextFieldStyle } from './TextFieldOld.styles';

type TextFieldProps<T extends string> = {
  label?: string;
  error?: string;
  editMode?: boolean;
  register?: UseFormRegisterReturn<T>;
  placeholder?: string;
  labelPosition?: 'Top' | 'Left' | 'Default';
  className?: string;
  InputProps?: Record<string, unknown>;
  useRegister?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  onBlur?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  value?: string;
  textarea?: boolean;
  rows?: number;
  // Custom formatting will only apply to read-only fields
  customFormatter?: (value: string) => string;
  customInputStyle?: { [key: string]: string };
} & (
  | {
      label?: undefined;
      dataCy: string;
    }
  | {
      label: string;
      dataCy?: string;
    }
) &
  (
    | {
        customFormatter: (value: string) => string;
        value: string;
      }
    | {
        customFormatter?: undefined;
        value?: string;
      }
  );

/**
 * @deprecated Use TextField instead
 */
const TextFieldOld = <T extends string>({
  error,
  editMode = false,
  register,
  placeholder,
  label,
  labelPosition = 'Top',
  InputProps,
  className,
  useRegister = true,
  onChange,
  onBlur,
  value = '',
  textarea = false,
  rows = 1,
  customInputStyle,
  customFormatter,
  dataCy,
}: TextFieldProps<T>) => {
  const inputsClasses = useInputsStyle();
  const classes = useTextFieldStyle();
  const generateDataCy = useDataCy();

  const isReadOnly = !editMode && typeof onChange === 'undefined' && useRegister === false;

  const stateStyle = !editMode && styles.disabled;

  const attributes = !useRegister
    ? {
        onChange,
        value: !editMode && customFormatter ? customFormatter(value) : value,
      }
    : {
        ...(!editMode && customFormatter ? { value: customFormatter(value) } : register),
      };

  const fixedHeightStyle = !textarea ? styles.fixedHeight : {};

  return (
    <div className={classNames(inputsClasses[labelPosition], className)}>
      {label !== '' && (
        <TypographyWithFontFamily cName={classes.labelUppercase} color={THEME.PALETTES.TEXT.Gray} variant="Label_Base">
          {label}
        </TypographyWithFontFamily>
      )}
      <MUITextField
        FormHelperTextProps={{
          className: inputsClasses.textError,
        }}
        InputProps={{
          style: {
            ...styles.input,
            ...stateStyle,
            ...customInputStyle,
            ...fixedHeightStyle,
          },
          ...(onBlur && { onBlur }),
          ...InputProps,
        }}
        className={`${classes.input} ${isReadOnly ? classes.readOnly : ''}`}
        data-cy={generateDataCy('TextField', label ?? dataCy)}
        disabled={!editMode}
        fullWidth
        helperText={error}
        multiline={textarea}
        placeholder={placeholder}
        rows={rows}
        {...attributes}
        sx={disabledInputSx}
      />
    </div>
  );
};

export default TextFieldOld;
