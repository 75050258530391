import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { FC, ReactElement } from 'react';

import CloseIcon from '#/assets/CloseIcon.svg';

import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { THEME } from '#/constants/theme/constants';

import { useDataCy } from '#/hooks/useDataCy';

import { modalSize, styles, useModalStyles } from './Modal.styles';

export type ModalSizes = 'small' | 'large' | 'wide' | 'wizard' | 'tall';

export type ModalProps = {
  icon?: string;
  modalTitle: string;
  size?: ModalSizes;
  open?: boolean;
  content: ReactElement;
  handleCloseModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const Modal: FC<ModalProps> = ({
  icon,
  content,
  modalTitle,
  open = true,
  size = 'large',
  handleCloseModal,
  ...props
}) => {
  const classes = useModalStyles();
  const generateDataCy = useDataCy();

  return (
    <>
      <Dialog
        slotProps={styles.backDropOpacity}
        {...props}
        data-cy={generateDataCy('Modal', modalTitle)}
        open={open}
        sx={modalSize(size)}
      >
        <DialogTitle className={classes.dialogTitle} sx={styles.titlePaddingSx}>
          <Box className={classes.flex}>
            {icon && <img alt="modal icon" className={classes.mrImg} src={icon} />}
            <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Light} variant="H4_Base">
              {modalTitle}
            </TypographyWithFontFamily>
          </Box>
          <Box className={classes.addBrandMargin}>
            <img
              alt="close icon"
              className={classes.closeImg}
              onClick={() => handleCloseModal(!open)}
              src={CloseIcon}
            />
          </Box>
        </DialogTitle>
        <DialogContent sx={styles.contentBackground}>{content}</DialogContent>
      </Dialog>
    </>
  );
};

export default Modal;
