import { ISettingsCalendarResponse } from '../interfaces/settings/calendar';
import { retailerAxios } from './axiosConfig';
import { IRequestDto } from './requestDtoInterface';

export const uploadJobFile = (jobId: string, file: File | null) => {
  const formData = new FormData();
  formData.append('file', file as Blob);
  return retailerAxios.put(`/data-job/${jobId}/run`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getCalendarData = (): Promise<IRequestDto<ISettingsCalendarResponse>> => {
  return retailerAxios.get(`/retailer-account/calendar`);
};
