import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { retailerAxios } from '../../../api/axiosConfig';
import { CompanyType } from '../../../constants/companyType/CompanyType';
import { ICreateCompany } from '../../../interfaces/forms/createCompany';

export const useCreateCompany = (onClose: () => void) => {
  const schema = yup
    .object()
    .shape({
      name: yup.string().required('Name is required'),
      companyType: yup.string().required('Company type is required'),
      parentId: yup.string(),
      companyUrl: yup.string(),
      mailingStreetAddress1: yup.string(),
      mailingStreetAddress2: yup.string(),
      mailingCity: yup.string(),
      mailingState: yup.string(),
      mailingCountry: yup.string(),
      mailingZip: yup.string(),
      isBillingSameAsMailing: yup.boolean(),
      billingStreetAddress1: yup.string(),
      billingStreetAddress2: yup.string(),
      billingCity: yup.string(),
      billingState: yup.string(),
      billingCountry: yup.string(),
      billingZip: yup.string(),
      ein: yup
        .string()
        .matches(
          /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$|^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/,
          { message: 'Enter a valid Tax ID', excludeEmptyString: true },
        ),
      billType: yup.string(),
      logoId: yup.string().nullable(),
      vendorId: yup.string(),
    })
    .required();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ICreateCompany>({
    resolver: yupResolver(schema),
    defaultValues: {
      // country: 'United States',
      companyType: CompanyType.BRAND,
      // billType: 'BILL_BACK',
      logoId: '',
    },
  });
  useEffect(() => {
    if (watch('isBillingSameAsMailing')) {
      setValue('mailingStreetAddress1', watch('billingStreetAddress1'));
      setValue('mailingStreetAddress2', watch('billingStreetAddress2'));
      setValue('mailingCity', watch('billingCity'));
      setValue('mailingCountry', watch('billingCountry'));
      setValue('mailingState', watch('billingState'));
      setValue('mailingZip', watch('billingZip'));
    }
  }, [
    watch('isBillingSameAsMailing'),
    watch('billingStreetAddress1'),
    watch('billingStreetAddress2'),
    watch('billingCity'),
    watch('billingCountry'),
    watch('billingState'),
    watch('billingZip'),
  ]);
  const onSubmit = handleSubmit(async (formData: ICreateCompany) => {
    await retailerAxios.post(`/company-account`, formData);
    onClose();
  });
  return {
    register,
    onSubmit,
    errors,
    handleSubmit,
    setValue,
    control,
    watch,
  };
};
