import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

import { useWebsocket } from '#/hooks/useWebsocket';

import { user } from '#/store/slices';

import log from '#/utils/log';
import { storage } from '#/utils/storage';

export const useLogout = () => {
  const { disconnectWebsocket } = useWebsocket();
  const dispatch = useDispatch();
  const { logout: authLogout } = useAuth0();

  const logout = async () => {
    disconnectWebsocket();
    dispatch(user.actions.logout());
    await authLogout().catch((error) => {
      log.error('Error logging out', error);

      // Force logout by clearing local storage and reloading the page
      storage.clear();
      window.location.reload();
    });
  };

  return logout;
};
