import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Outlet, useNavigate } from 'react-router-dom';
import { ProductFruits } from 'react-product-fruits';

import { setOktaTokenToAxios } from '#/api/axiosConfig';

import FullPageLoader from '#/components/common/loader/FullPageLoader';
import { PRODUCTFRUITS_WORKSPACECODE } from '#/constants/general';
import { useAppSelector } from '#/hooks';
import { useLogout } from '#/hooks/useLogout';
import { useWebsocket } from '#/hooks/useWebsocket';

import { useLazyGetCurrentUserProfileQuery } from '#/store/api/users/users';
import { user as userSlice } from '#/store/slices';

import log from '#/utils/log';
import { storage } from '#/utils/storage';

const SecureRoute = () => {
  const { isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const logout = useLogout();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const websocket = useWebsocket();
  const dispatch = useDispatch();

  const user = useAppSelector((state) => state.user);
  const [trigger, { data, error }] = useLazyGetCurrentUserProfileQuery();

  const logoutTimerRef = React.useRef<number | null>(null);

  const handleLogout = () => {
    dispatch(userSlice.actions.logout()); // Reset user state
    logout(); // Perform logout action (clear session, etc.)
    storage.clear(); // Clear any stored data
    navigate('/');
  };

  const checkTokenExpiry = async () => {
    try {
      const token = await getAccessTokenSilently();
      dispatch(userSlice.actions.setAuthToken(token));

      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const expiresAt = decodedToken.exp * 1000; // Convert to milliseconds

      // Set a timer to logout when token expires
      const timeToExpire = expiresAt - Date.now();
      logoutTimerRef.current = window.setTimeout(() => {
        handleLogout();
      }, timeToExpire);
    } catch (error) {
      log.error('Error getting access token', error);
      handleLogout();
    }
  };

  React.useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      storage.setRedirectUrl(pathname, search);
      loginWithRedirect();
    } else if (isAuthenticated && !isLoading) {
      checkTokenExpiry();
    }
  }, [isAuthenticated, isLoading]);

  React.useEffect(() => {
    if (user.authToken) {
      trigger();

      websocket.connectWebsocket(user.authToken);

      //@TODO Remove axios
      setOktaTokenToAxios(user.authToken);
    }
  }, [user.authToken]);

  React.useEffect(() => {
    if (error) {
      handleLogout();
    }
  }, [error]);

  React.useEffect(() => {
    if (data) {
      dispatch(userSlice.actions.setUser(data));
      dispatch(userSlice.actions.setPermissions(data.permissions));

      const redirectUrl = storage.getRedirectUrl();
      redirectUrl && navigate(redirectUrl, { replace: true });
      storage.deleteRedirectUrl();
    }
  }, [data]);

  if (!user.user) {
    return <FullPageLoader />;
  }

  const userInfo = {
    username: user.user?.userId ?? '',
    firstname: user.user?.firstName,
    lastname: user.user?.lastName,
    role: user.user?.roleName,
    props: { userType: user.user?.userType ?? '' },
  };
  return (
    <>
      <ProductFruits language="en" user={userInfo} workspaceCode={PRODUCTFRUITS_WORKSPACECODE} />
      <Outlet />
    </>
  );
};

export default SecureRoute;
