import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const useDisplayPageNameStyles = makeStyles(() =>
  createStyles({
    iconWrapper: {
      '&&': {
        width: THEME.REM_SPACES['2.5'],
        height: THEME.REM_SPACES['2.5'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: THEME.REM_SPACES['0.5'],
        background: THEME.PALETTES.TEXT.Light,
        borderRadius: '50%',
      },
    },
    wrap: {
      '&&': {
        marginBottom: '1.875rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },
    textWrap: {
      '&&': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    redirect: {
      textDecoration: 'none',
      fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
      color: THEME.PALETTES.TEXT.Action_Secondary,
      fontSize: '1.125rem',
      lineHeight: '1.235rem',
    },
  }),
);
