import { Box } from '@mui/material';
import { FC } from 'react';

import { IDynamicTacticsInventoryProps } from '#/interfaces/dynamicRibbon/dynamicRibbon';

import CheckBlueXIcon from '../../../../assets/CheckBlueXIcon.svg';
import CheckFalseBlueIcon from '../../../../assets/CheckFalseBlueIcon.svg';
import CheckTrueBlueIcon from '../../../../assets/CheckTrueBlueIcon.svg';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { useDynamicRibbonStyles } from '../DynamicRibbon.styles';
import { useDynamicTacticsInventoryStyles } from './DynamicTacticsInventory.styles';

const DynamicTacticsInventory: FC<IDynamicTacticsInventoryProps> = ({ component }) => {
  const ribbonClasses = useDynamicRibbonStyles();
  const classes = useDynamicTacticsInventoryStyles();

  const getCheckImage = (inventoryStatus: string) => {
    let src = '';
    let alt = '';

    switch (inventoryStatus) {
      case 'REJECTED': {
        src = CheckBlueXIcon;
        alt = 'rejected_checkbox';
        break;
      }
      case 'BOOKED':
      case 'CONFIRMED': {
        src = CheckTrueBlueIcon;
        alt = 'confirmed_checkbox';
        break;
      }
      default: {
        src = CheckFalseBlueIcon;
        alt = 'empty_checkbox';
      }
    }
    return <img alt={alt} className={classes.tacticCheckboxImage} src={src} width="20px" />;
  };
  return (
    <div className={ribbonClasses.dynamicInventoryContainer}>
      <TypographyWithFontFamily classes={classes.header} variant="H5_Base">
        TACTICS
      </TypographyWithFontFamily>
      <Box className={classes.tacticsGrid}>
        {component.map((tactic, index) => (
          <Box key={index} className={classes.displayFlex}>
            <TypographyWithFontFamily cName={ribbonClasses.inventoryTactic} classes={classes.tactic}>
              {tactic.displayName}
            </TypographyWithFontFamily>
            {getCheckImage(tactic.inventoryStatus)}
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default DynamicTacticsInventory;
