import { makeStyles, createStyles } from '#/utils/theme';

export const useCampaignStepperStyles = makeStyles(() =>
  createStyles({
    container: { backgroundColor: '#FFF', display: 'flex', borderRadius: '1rem', padding: '1rem' },
    m0auto: {
      '&&': {
        margin: '0 auto',
      },
    },
  }),
);
