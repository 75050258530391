import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../constants/theme/constants';

export const useCampaignStyles = makeStyles(() =>
  createStyles({
    wrapperDivOverviewGeneral: {
      '&&': {
        border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
        padding: `${THEME.REM_SPACES['1']}`,
        borderRadius: THEME.REM_SPACES['0.5'],
      },
    },
    flexWithSpaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    paragraph: {
      '&&': {
        fontSize: '20px',
        marginBottom: '30px',
      },
    },
    editMode: {
      color: 'blue',
      fontSize: '13px',
    },
    buttonMargin: {
      '&&': {
        marginRight: '1.25rem',
      },
    },
    boxGrid: {
      '&&': {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill,minmax(35%, 1fr))',
        gridGap: '2em',
      },
    },
    label: { fontSize: '13px', color: '#555' },
    error: {
      '&&': {
        color: THEME.PALETTES.SEMANTIC.Error['500'],
      },
    },
    disabledBg: {
      '& fieldset': { border: 'none' },
      background: '#E4E8EF',
    },
    inputState: {
      '&&': {
        color: '#777',
        fontSize: '13px',
        borderRadius: '7px',
      },
    },
    campaignTitle: { fontSize: '13px', color: '#555', marginBottom: '25px' },
    done: {
      color: 'green',
    },
    marginBottom: {
      marginBottom: THEME.REM_SPACES['1'],
    },
    datePicker: {
      color: '#333',
      '& label': { color: '#555' },
    },
    campaignBrandsTitle: {
      fontWeight: 700,
      fontSize: '18px',
      color: '#16191F',
    },
    campaignBrandsParagraph: {
      fontWeight: 400,
      fontSize: '11.5px',
      color: '#606670',
      marginBottom: '16px',
    },
    campaignBrandsBoxWrap: {
      borderRadius: '16px',
      minHeight: '700px',
      marginBottom: '16px',
      padding: '16px',
    },
    campaignBrandsBox: {
      width: '100%',
      border: '1px solid #E4E8EF',
      borderRadius: '8px',
      padding: '16px',
      display: 'flex',
      columnGap: '16px',
      marginBottom: '16px',
    },
    campaignBrandsLabels: {
      color: '#606670',
      fontSize: '16px',
      textTransform: 'uppercase',
      fontWeight: 500,
    },
    campaignBrandsTable: {
      width: '100%',
      minHeight: '48px',
      padding: '80px',
      background: '#EBF6FD',
      border: '1px solid #B7DEF5',
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      rowGap: '16px',
    },
    campaignBrandsTitle2: {
      fontWeight: 700,
      fontSize: '18px',
      color: '#16191F',
    },
    campaignsBrandsPar: {
      fontWeight: 400,
      fontSize: '11.5px',
      color: '#606670',
      marginBottom: '16px',
    },
    campaignBrandsPromoteText: {
      fontWeight: 700,
      fontSize: '18px',
      color: '#16191F',
    },
    campaignPageHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    flexAlignCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    campaignPageGrid: {
      padding: '0.75rem 0 0.45rem 0.625rem',
      borderRadius: '20px',
      '&& > div': {
        minHeight: 'auto',
      },
    },
    headCellClass: {
      borderWidth: '1px',
      border: '1px solid var(--border-extra-light, #E4E8EF)',
      background: 'var(--neutral-100, #F1F4F9)',
      color: ' var(--text-dark, #16191F)',
      fontFamily: 'Public Sans',
      fontSize: '0.71875rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
    },
    headClass: {
      height: '3rem',
      color: ' var(--text-dark, #16191F)',
      fontFamily: 'Public Sans',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    taskHyperLink: {
      color: 'blue',
    },
    bodyCellClass: {
      height: THEME.REM_SPACES['3'],
    },
    custom: {
      border: '1px solid #ccc',
      background: '#fff',
    },
    url: {
      color: 'blue',
    },
    headInstances: {
      lineHeight: 1.3,
      padding: '5px 15px',
    },
    megaphoneWrap: {
      borderRadius: '50%',
      marginRight: '8px',
      width: '42px',
      height: '40px',
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    campaignName: {
      '&&': {
        fontWeight: 600,
        fontSize: '24px',
      },
    },
    flex: {
      display: 'flex',
    },
    marginRight10: {
      marginRight: '10px',
    },
    marginTop1: {
      marginTop: '5px',
    },
    colorWhite: {
      color: '#fff',
    },
    cursor: {
      cursor: 'pointer',
    },
    campaignInstanceHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
    },
    campaignIBox: {
      '&&': {
        marginRight: '20px',
        borderRadius: '50%',
        padding: '7px',
        background: '#fff',
        width: '41px',
        height: '41px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    campaignIMG: {
      fill: '#000',
    },
    instanceTitle: {
      '&&': {
        color: '#1F8AC9',
        fontSize: '15px',
        fontWight: '700',
      },
    },
    campaignHName: {
      '&&': {
        fontSize: '27px',
        color: '#000',
        fontWeight: 600,
        lineHeight: '1.1',
        display: 'flex',
      },
    },
    instanceColor: {
      '&&': {
        fontSize: '13px',
        borderRadius: '15px',
        fontWeight: 500,
        padding: '6px 9px 5px',
        marginLeft: '30px',
        display: 'flex',
        alignItems: 'center',
      },
    },
    instancesTabs: {
      background: '#E5E4EA',
      borderRadius: '40px',
      padding: '0 10px',
    },
    bgWhite: {
      '&&': {
        background: '#fff',
      },
    },
    bgNone: {
      background: 'transparent',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
  }),
);

export const TabsStyle = {
  justifyContent: 'center',
  fontSize: '14px',
  padding: '7px 20px',
  width: 'auto',
  borderRadius: '30px',
  cursor: 'pointer',
  opacity: '1',
  fontWeight: '600',
  color: '#16191F',
  textTransform: 'none',
};

export const TabsParent = {
  display: 'flex',
  padding: '5px 0',
  margin: 0,
  textAlign: 'center',
  fontSize: '13px',
};

export const CampaignInstanceSx = {
  fontSize: '13px',
  ml: 3,
  fontWeight: '500',
};

export const cardCustomPadding = {
  padding: '0.063rem 1.25rem 0.375rem 0.25rem',
};

export const flexGrow = {
  flexGrow: 1,
};

export const cardTableCustomPaddingSx = {
  padding: '0.3rem 0',
  flexGrow: 1,
};
