import { Link } from 'react-router-dom';

import { useTableCellLinkStyles } from '#/components/common/TableCellLink/TableCellLink.styles';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';

const TableCellLink = ({ link, text }) => {
  const classes = useTableCellLinkStyles();
  return (
    <TypographyWithFontFamily variant="Default_Bold">
      <Link className={classes.link} to={link}>
        {text}
      </Link>
    </TypographyWithFontFamily>
  );
};

export default TableCellLink;
