import { IDynamicFileUploadReferenceProps } from '../../../../interfaces/dynamicRibbon/dynamicRibbon';
import FileView from '../../../shared/files/file-view';

const DynamicFileUploadReference = ({ component }: IDynamicFileUploadReferenceProps) => {
  return (
    <FileView
      deleteLink=""
      downloadLink={`/file-upload/files/${component.content}`}
      fileId={component.content}
      readonly={true}
    />
  );
};

export default DynamicFileUploadReference;
