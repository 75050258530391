import { AutocompleteChangeReason } from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, MutationDefinition } from '@reduxjs/toolkit/query';
import { FC, useEffect, useState } from 'react';

import {
  getProductMixAttributes,
  getProductMixCategories,
  IAttribute,
  ICategory,
  ICompanyAccountProductMix,
  ISubcategory,
} from '#/api/common/productMix';
import { IRequestDto } from '#/api/requestDtoInterface';

import AlertError from '#/components/common/Alert/AlertError/AlertError';

import PermissionWrapper from '#/utils/PermissionWrapper';

import Card from '../card/card';
import Loader from '../loader/loader';
import { useSnackbarError } from '../snackbar/useSnackbarError';
import ProductMixInput from './ProductMixInput';
import EditButtonsProductMix from './view/EditProductMix';

export type ProductMixProps = {
  targetId: string | undefined;
  getSelectedObjects: (targetId: string | undefined) => Promise<IRequestDto<ICompanyAccountProductMix>>;
  putSelectedObjects: MutationTrigger<
    MutationDefinition<
      {
        productId: string | undefined;
        data: {
          categoryIds: string[];
          subCategoryIds: string[];
          attributeIds: string[];
        };
      },
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
      string,
      void,
      'api'
    >
  >;
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  requiredEditPermission?: string[];
};

const ProductMix: FC<ProductMixProps> = ({
  targetId,
  getSelectedObjects,
  putSelectedObjects,
  isLoading,
  error,
  requiredEditPermission = [],
}) => {
  const { setError } = useSnackbarError();

  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<{
    categories: ICategory[];
    attributes: IAttribute[];
  }>({
    categories: [],
    attributes: [],
  });
  const [selectedOptions, setSelectedOptions] = useState<{
    selectedCategories: ICategory[];
    selectedSubCategories: ISubcategory[];
    selectedAttributes: IAttribute[];
  }>({
    selectedCategories: [],
    selectedSubCategories: [],
    selectedAttributes: [],
  });

  const getData = async () => {
    setLoading(true);
    try {
      const res1: [IRequestDto<ICategory[]>, IRequestDto<IAttribute[]>] = await Promise.all([
        getProductMixCategories(),
        getProductMixAttributes(),
      ]);
      const res2: IRequestDto<ICompanyAccountProductMix> = await getSelectedObjects(targetId);

      setOptions({
        categories: res1[0].data,
        attributes: res1[1].data,
      });

      const selectedCategoriesWithAssociatedSubCategories = res2.data.categories.map((el) =>
        res1[0].data.find((looked) => el.id === looked.id),
      ) as ICategory[];

      setSelectedOptions({
        selectedCategories: selectedCategoriesWithAssociatedSubCategories,
        selectedSubCategories: res2.data.subCategories,
        selectedAttributes: res2.data.attributes,
      });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const saveHandler = async () => {
    try {
      const body = {
        categoryIds: selectedOptions.selectedCategories.map((el) => el.id),
        subCategoryIds: selectedOptions.selectedSubCategories.map((el) => el.id),
        attributeIds: selectedOptions.selectedAttributes.map((el) => el.id),
      };
      await putSelectedObjects({ productId: targetId, data: body });
      setEditMode(false);
      await getData();
    } catch (error) {
      setError(error);
    }
  };

  const onCategoryChange = (_: unknown, updatedSelectedCategories: ICategory[], reason: AutocompleteChangeReason) => {
    const isLastElementRemoved = updatedSelectedCategories.length === 0;

    switch (reason) {
      case 'selectOption':
        setSelectedOptions((prevState) => ({
          ...prevState,
          selectedCategories: updatedSelectedCategories,
        }));
        break;
      case 'removeOption':
        if (isLastElementRemoved) {
          setSelectedOptions((prevState) => ({
            selectedCategories: [],
            selectedSubCategories: [],
            selectedAttributes: prevState.selectedAttributes,
          }));
        } else {
          setSelectedOptions((prevState) => {
            const updatedSelectedCategoriesSubCategoriesIDs = updatedSelectedCategories[0].subcategories.map(
              (el) => el.id,
            );
            const filteredSubCategories = prevState.selectedSubCategories.filter((el) =>
              updatedSelectedCategoriesSubCategoriesIDs.includes(el.id),
            );

            return {
              ...prevState,
              selectedCategories: updatedSelectedCategories,
              selectedSubCategories: filteredSubCategories,
            };
          });
        }

        break;
      default:
        break;
    }
  };

  const onSubCategoryChange = (_: unknown, updatedSelectedSubCategories: ISubcategory[]) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      selectedSubCategories: updatedSelectedSubCategories,
    }));
  };

  const onAttributesChange = (_: unknown, updatedSelectedAttributes: IAttribute[]) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      selectedAttributes: updatedSelectedAttributes,
    }));
  };

  const optionsSubCategories = selectedOptions.selectedCategories
    .map((el) => el.subcategories.map((el) => ({ id: el.id, subcategoryName: el.subcategoryName })))
    .flat();

  useEffect(() => {
    getData();
  }, []);

  const editModeHandler = async () => {
    if (editMode) {
      await getData();
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  };

  return (
    <Card>
      {loading ? (
        <Loader />
      ) : (
        <>
          <AlertError error={error} />
          <PermissionWrapper requiredPermissions={requiredEditPermission}>
            <EditButtonsProductMix
              editMode={editMode}
              saveHandler={saveHandler}
              saveLoading={isLoading}
              updateEditMode={editModeHandler}
            />
          </PermissionWrapper>

          <>
            <ProductMixInput
              editMode={editMode}
              label="Categories"
              noSelectedOptionsMessage="No product categories defined"
              onOptionChange={onCategoryChange}
              options={options.categories}
              selectedOptions={selectedOptions.selectedCategories}
              subLabel="Define product Categories for campaign matching"
            />
            <ProductMixInput
              editMode={editMode}
              label="Sub Categories"
              noSelectedOptionsMessage="No product Sub Categories defined"
              onOptionChange={onSubCategoryChange}
              options={optionsSubCategories}
              selectedOptions={selectedOptions.selectedSubCategories}
              subLabel="Define product subcategories for campaign matching"
            />
            <ProductMixInput
              editMode={editMode}
              label="Attributes"
              noSelectedOptionsMessage="No product Attributes defined"
              onOptionChange={onAttributesChange}
              options={options.attributes}
              selectedOptions={selectedOptions.selectedAttributes}
              subLabel="Define product attributes for campaign matching"
            />
          </>
        </>
      )}
    </Card>
  );
};

export default ProductMix;
