import { Box } from '@mui/material';
import { FC } from 'react';

import Checkbox from '#/components/common/inputs/Checkbox/Checkbox';

import { useAppSelector } from '#/hooks';

interface ICommunicationsSelectInvitationStatusProps {
  handleChange: (value: string | string[] | boolean, updateFilterName: string) => void;
  includeAccepted?: boolean;
}

const CommunicationsSelectInvitationStatus: FC<ICommunicationsSelectInvitationStatusProps> = ({
  handleChange,
  includeAccepted,
}) => {
  const { selectedCampaigns } = useAppSelector((state) => state.communications);

  if (selectedCampaigns.length >= 2) {
    return null;
  }

  return (
    <Box>
      <Checkbox
        label="Include Accepted Brands"
        onChange={(e) => handleChange(e.target.checked, 'includeAccepted')}
        value={includeAccepted}
      />
    </Box>
  );
};

export default CommunicationsSelectInvitationStatus;
