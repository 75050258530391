import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const useInputsStyle = makeStyles(() =>
  createStyles({
    Left: {
      display: 'flex',
      flexDirection: 'row',
      textWrap: 'nowrap',
      '&& p': {
        alignSelf: 'center',
        paddingRight: THEME.REM_SPACES['0.5'],
      },
      '&& input': {
        padding: '0.438rem',
      },
    },
    Top: {
      display: 'flex',
      flexDirection: 'column',
      '&& input': {
        padding: '0.438',
      },
      '&& first-child': {
        paddingBottom: THEME.REM_SPACES['0.25'],
      },
    },
    Default: {
      '&& input': {
        padding: '0px',
      },
    },
    labelUppercase: {
      textTransform: 'uppercase',
    },
    textError: {
      '&&': {
        color: THEME.PALETTES.SEMANTIC.Error['500'],
        marginLeft: '0',
      },
    },
  }),
);
