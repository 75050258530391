import { createStyles, makeStyles } from '#/utils/theme';

export const usePendingInvitationsStyles = makeStyles(() =>
  createStyles({
    cardsRow: { display: 'flex', flexDirection: 'row', justifyContent: 'center' },
    cardsContainer: {
      width: '320px',
      minWidth: '320px',
      maxWidth: '320px',
      boxShadow: '0 3px 5px rgb(0 0 0 / 0.2)',
      padding: '1rem',
      borderRadius: '10px',
      marginLeft: '1rem',
      marginBottom: '1rem',
    },
  }),
);
