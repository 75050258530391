import { Box, SxProps, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';

import { THEME } from '../../../constants/theme/constants';

type TCard = {
  children: ReactNode;
  customStyles?: SxProps<Theme>;
  customClass?: string;
};

const Card: FC<TCard> = ({ children, customStyles, customClass, ...props }) => {
  return (
    <Box
      className={customClass}
      {...props}
      data-test={'card'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: THEME.PALETTES.TEXT.Light,
        borderRadius: '18px',
        padding: '1rem',
        mb: 3,
        ...customStyles,
      }}
    >
      {children}
    </Box>
  );
};

export default Card;
