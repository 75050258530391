import DeleteIcon from '../../../../assets/DeleteButtonIcon.svg';
import { IReportListItem } from '../../../../interfaces/reports/reports';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '../../../../interfaces/table/table';

// import EditIcon from '../../../../assets/Edit.svg';

export const getListTableColumns = (deleteReport: (id: string) => void) => {
  const tableColumns: ITableColumnsProps<IReportListItem>[] = [
    {
      fieldName: 'id',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      hide: true,
    },
    {
      fieldName: 'name',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Name',
    },
    {
      fieldName: 'type',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Report Type',
    },
    {
      fieldName: null,
      align: TableColumnAlign.CENTER,
      customHead: ' ',
      type: TableCellTypes.ICONS_ACTIONS,
      actionElementIdentifier: 'id',
      iconsActions: [
        {
          customIdentifier: 'id',
          action: deleteReport,
          icon: DeleteIcon,
        },
        // {
        //   customIdentifier: 'id',
        //   action: editReport,
        //   icon: EditIcon,
        // },
      ],
    },
  ];
  return tableColumns;
};
