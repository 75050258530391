import { IGeneralOverviewInstance } from '../../interfaces/forms/campaigns';
import { retailerAxios } from '../axiosConfig';
import { IRequestDto } from '../requestDtoInterface';

export const getCampaignInstanceGeneral = (
  instanceId: string | undefined,
): Promise<IRequestDto<IGeneralOverviewInstance>> => {
  return retailerAxios.get(`/instances/${instanceId}/overview/general`);
};

export const downloadAllAssets = (instanceId: string | undefined) => {
  return retailerAxios.get(`/instance-asset-metadata/${instanceId}/download-all`, { responseType: 'blob' });
};
