import { ISelectableTactic } from '#/interfaces/forms/campaigns';

import BoltIcon from '../../../assets/CampaignHistoryIcons/Bolt.svg';
import ChannelsIcon from '../../../assets/CampaignHistoryIcons/Channels.svg';
import CircleCheckIcon from '../../../assets/CampaignHistoryIcons/CircleCheck.svg';
import CommentsIcon from '../../../assets/CampaignHistoryIcons/Comments.svg';
import DownloadIcon from '../../../assets/CampaignHistoryIcons/Download.svg';
import EditIcon from '../../../assets/CampaignHistoryIcons/Edit.svg';
import EyeIcon from '../../../assets/CampaignHistoryIcons/Eye.svg';
import FilterIcon from '../../../assets/CampaignHistoryIcons/Filter.svg';
import GearSixIcon from '../../../assets/CampaignHistoryIcons/GearSix.svg';
import LayersIcon from '../../../assets/CampaignHistoryIcons/Layers.svg';
import LinkIcon from '../../../assets/CampaignHistoryIcons/Link.svg';
import ListIcon from '../../../assets/CampaignHistoryIcons/List.svg';
import MegaphoneIcon from '../../../assets/CampaignHistoryIcons/Megaphone.svg';
import PackageIcon from '../../../assets/CampaignHistoryIcons/Package.svg';
import SendIcon from '../../../assets/CampaignHistoryIcons/Send.svg';
import SendGreenIcon from '../../../assets/CampaignHistoryIcons/SendGreen.svg';
import SendRedIcon from '../../../assets/CampaignHistoryIcons/SendRed.svg';
import ShareIcon from '../../../assets/CampaignHistoryIcons/Share.svg';
import TrashIcon from '../../../assets/CampaignHistoryIcons/Trash.svg';
import WorkflowIcon from '../../../assets/CampaignHistoryIcons/Workflow.svg';

export const returnCampaignUrls = (path: string, campaignId: string) => {
  return `${path.split('/')[0]}/${campaignId}`;
};

export const returnCampaignInstancesUrls = (instancePath: string, instanceId: string) => {
  return `${instancePath.split('/')[0]}/${instanceId}`;
};

export enum EventType {
  CAMPAIGN_CLONE_V1 = 'CAMPAIGN_CLONE_V1',
  CAMPAIGN_CREATE_V1 = 'CAMPAIGN_CREATE_V1',
  CAMPAIGN_CREATE_TIER_V1 = 'CAMPAIGN_CREATE_TIER_V1',
  CAMPAIGN_CREATIVE_SPECS_DELETE_V1 = 'CAMPAIGN_CREATIVE_SPECS_DELETE_V1',
  CAMPAIGN_CREATIVE_SPECS_DOWNLOAD_EVENT_V1 = 'CAMPAIGN_CREATIVE_SPECS_DOWNLOAD_EVENT_V1',
  CAMPAIGN_CREATIVE_SPECS_UPLOAD_V1 = 'CAMPAIGN_CREATIVE_SPECS_UPLOAD_V1',
  CAMPAIGN_EDIT_GENERAL_V1 = 'CAMPAIGN_EDIT_GENERAL_V1',
  CAMPAIGN_EDIT_INTERNAL_SECTION_V1 = 'CAMPAIGN_EDIT_INTERNAL_SECTION_V1',
  CAMPAIGN_EDIT_MARKETING_INFO_V1 = 'CAMPAIGN_EDIT_MARKETING_INFO_V1',
  CAMPAIGN_DELETE_FILE_V1 = 'CAMPAIGN_DELETE_FILE_V1',
  CAMPAIGN_INTERNAL_REVIEW_INITIALIZED_V1 = 'CAMPAIGN_INTERNAL_REVIEW_INITIALIZED_V1',
  CAMPAIGN_INVENTORY_RESET_V1 = 'CAMPAIGN_INVENTORY_RESET_V1',
  CAMPAIGN_INVENTORY_SUBMIT_V1 = 'CAMPAIGN_INVENTORY_SUBMIT_V1',
  CAMPAIGN_EDIT_LEGAL_TERMS_V1 = 'CAMPAIGN_EDIT_LEGAL_TERMS_V1',
  CAMPAIGN_PRODUCT_MIX_LINK_V1 = 'CAMPAIGN_PRODUCT_MIX_LINK_V1',
  CAMPAIGN_REMOVE_TIER_V1 = 'CAMPAIGN_REMOVE_TIER_V1',
  CAMPAIGN_STORES_FILTER_LINK_V1 = 'CAMPAIGN_STORES_FILTER_LINK_V1',
  CAMPAIGN_UPDATE_STAGE_V1 = 'CAMPAIGN_UPDATE_STAGE_V1',
  CAMPAIGN_UPDATE_STATUS_V1 = 'CAMPAIGN_UPDATE_STATUS_V1',
  //instance
  CAMPAIGN_INSTANCE_ADD_TACTICS_V1 = 'CAMPAIGN_INSTANCE_ADD_TACTICS_V1',
  CAMPAIGN_INSTANCE_APPROVE_ALL_ASSETS_V1 = 'CAMPAIGN_INSTANCE_APPROVE_ALL_ASSETS_V1',
  CAMPAIGN_INSTANCE_BRAND_ACCEPTED_V1 = 'CAMPAIGN_INSTANCE_BRAND_ACCEPTED_V1',
  CAMPAIGN_INSTANCE_BRAND_APPLIED_V1 = 'CAMPAIGN_INSTANCE_BRAND_APPLIED_V1',
  CAMPAIGN_INSTANCE_BRAND_DECLINED_V1 = 'CAMPAIGN_INSTANCE_BRAND_DECLINED_V1',
  CAMPAIGN_INSTANCE_BRAND_REQUEST_REVISION_V1 = 'CAMPAIGN_INSTANCE_BRAND_REQUEST_REVISION_V1',
  CAMPAIGN_CREATE_INSTANCE_V1 = 'CAMPAIGN_CREATE_INSTANCE_V1',
  CAMPAIGN_INSTANCE_CREATE_TIER_V1 = 'CAMPAIGN_INSTANCE_CREATE_TIER_V1',
  CAMPAIGN_INSTANCE_EDIT_GENERAL_OVERVIEW_V1 = 'CAMPAIGN_INSTANCE_EDIT_GENERAL_OVERVIEW_V1',
  CAMPAIGN_INSTANCE_EDIT_INTERNAL_SECTION_V1 = 'CAMPAIGN_INSTANCE_EDIT_INTERNAL_SECTION_V1',
  CAMPAIGN_INSTANCE_EDIT_LEGAL_TERMS_V1 = 'CAMPAIGN_INSTANCE_EDIT_LEGAL_TERMS_V1',
  CAMPAIGN_INSTANCE_EDIT_MARKETING_INFO_V1 = 'CAMPAIGN_INSTANCE_EDIT_MARKETING_INFO_V1',
  CAMPAIGN_INSTANCE_INVENTORY_RESET_V1 = 'CAMPAIGN_INSTANCE_INVENTORY_RESET_V1',
  CAMPAIGN_INSTANCE_INVENTORY_SUBMIT_V1 = 'CAMPAIGN_INSTANCE_INVENTORY_SUBMIT_V1',
  CAMPAIGN_INSTANCE_REMOVE_TIER_V1 = 'CAMPAIGN_INSTANCE_REMOVE_TIER_V1',
  CAMPAIGN_INSTANCE_RETAILER_ACCEPTED_V1 = 'CAMPAIGN_INSTANCE_RETAILER_ACCEPTED_V1',
  CAMPAIGN_INSTANCE_RETAILER_CONFIRM_REVISION_V1 = 'CAMPAIGN_INSTANCE_RETAILER_CONFIRM_REVISION_V1',
  CAMPAIGN_INSTANCE_RETAILER_DECLINED_V1 = 'CAMPAIGN_INSTANCE_RETAILER_DECLINED_V1',
  CAMPAIGN_UPDATE_OVERVIEW_INSTANCE_V1 = 'CAMPAIGN_UPDATE_OVERVIEW_INSTANCE_V1',
  CAMPAIGN_INSTANCE_UPDATE_STATUS_V1 = 'CAMPAIGN_INSTANCE_UPDATE_STATUS_V1',
  PROMOTE_CAMPAIGN_SEND_INVITE_EVENT_V1 = 'PROMOTE_CAMPAIGN_SEND_INVITE_EVENT_V1',
  PROMOTE_CAMPAIGN_RESEND_ORIGINAL_INVITE_EVENT_V1 = 'PROMOTE_CAMPAIGN_RESEND_ORIGINAL_INVITE_EVENT_V1',
  CAMPAIGN_TASK_FINAL_INTERNAL_REVIEW_V1 = 'CAMPAIGN_TASK_FINAL_INTERNAL_REVIEW_V1',
  CAMPAIGN_TASK_BRAND_ADOPTION_V1 = 'CAMPAIGN_TASK_BRAND_ADOPTION_V1',
  CAMPAIGN_TASK_CHANNEL_REVIEW_V1 = 'CAMPAIGN_TASK_CHANNEL_REVIEW_V1',
  CAMPAIGN_TASK_UPLOAD_ASSETS_V1 = 'CAMPAIGN_TASK_UPLOAD_ASSETS_V1',
  CAMPAIGN_INSTANCE_TASK_BRAND_APPLY_V1 = 'CAMPAIGN_INSTANCE_TASK_BRAND_APPLY_V1',
  CAMPAIGN_TASK_TACTIC_SETUP_V1 = 'CAMPAIGN_TASK_TACTIC_SETUP_V1',
  CAMPAIGN_TASK_TACTIC_ACTIVE_V1 = 'CAMPAIGN_TASK_TACTIC_ACTIVE_V1',
  CAMPAIGN_TASK_APPROVE_BRAND_V1 = 'CAMPAIGN_TASK_APPROVE_BRAND_V1',
  CAMPAIGN_TASK_REVIEW_ASSETS_V1 = 'CAMPAIGN_TASK_REVIEW_ASSETS_V1',
  CAMPAIGN_TASK_TACTIC_COMPLETE_V1 = 'CAMPAIGN_TASK_TACTIC_COMPLETE_V1',
  CAMPAIGN_TASK_INVENTORY_REVIEW_V1 = 'CAMPAIGN_TASK_INVENTORY_REVIEW_V1',
}
export function getTimelineIcon(event: EventType) {
  switch (event) {
    case EventType.CAMPAIGN_CLONE_V1:
      return LayersIcon;
    case EventType.CAMPAIGN_CREATE_V1:
    case EventType.CAMPAIGN_CREATE_INSTANCE_V1:
      return MegaphoneIcon;
    case EventType.CAMPAIGN_CREATE_TIER_V1:
    case EventType.CAMPAIGN_INSTANCE_CREATE_TIER_V1:
      return ListIcon;
    case EventType.CAMPAIGN_CREATIVE_SPECS_DELETE_V1:
    case EventType.CAMPAIGN_DELETE_FILE_V1:
    case EventType.CAMPAIGN_REMOVE_TIER_V1:
    case EventType.CAMPAIGN_INSTANCE_REMOVE_TIER_V1:
      return TrashIcon;
    case EventType.CAMPAIGN_CREATIVE_SPECS_DOWNLOAD_EVENT_V1:
      return DownloadIcon;
    case EventType.CAMPAIGN_CREATIVE_SPECS_UPLOAD_V1:
    case EventType.CAMPAIGN_TASK_UPLOAD_ASSETS_V1:
      return ShareIcon;
    case EventType.CAMPAIGN_EDIT_GENERAL_V1:
    case EventType.CAMPAIGN_EDIT_INTERNAL_SECTION_V1:
    case EventType.CAMPAIGN_EDIT_MARKETING_INFO_V1:
    case EventType.CAMPAIGN_EDIT_LEGAL_TERMS_V1:
    case EventType.CAMPAIGN_INSTANCE_EDIT_GENERAL_OVERVIEW_V1:
    case EventType.CAMPAIGN_INSTANCE_EDIT_INTERNAL_SECTION_V1:
    case EventType.CAMPAIGN_INSTANCE_EDIT_LEGAL_TERMS_V1:
    case EventType.CAMPAIGN_INSTANCE_EDIT_MARKETING_INFO_V1:
    case EventType.CAMPAIGN_UPDATE_OVERVIEW_INSTANCE_V1:
      return EditIcon;
    case EventType.CAMPAIGN_INTERNAL_REVIEW_INITIALIZED_V1:
    case EventType.CAMPAIGN_TASK_FINAL_INTERNAL_REVIEW_V1:
    case EventType.CAMPAIGN_TASK_CHANNEL_REVIEW_V1:
    case EventType.CAMPAIGN_TASK_REVIEW_ASSETS_V1:
    case EventType.CAMPAIGN_TASK_INVENTORY_REVIEW_V1:
      return EyeIcon;
    case EventType.CAMPAIGN_INVENTORY_RESET_V1:
    case EventType.CAMPAIGN_INVENTORY_SUBMIT_V1:
    case EventType.CAMPAIGN_INSTANCE_INVENTORY_RESET_V1:
    case EventType.CAMPAIGN_INSTANCE_INVENTORY_SUBMIT_V1:
      return PackageIcon;
    case EventType.CAMPAIGN_PRODUCT_MIX_LINK_V1:
      return LinkIcon;
    case EventType.CAMPAIGN_STORES_FILTER_LINK_V1:
      return FilterIcon;
    case EventType.CAMPAIGN_UPDATE_STAGE_V1:
    case EventType.CAMPAIGN_UPDATE_STATUS_V1:
    case EventType.CAMPAIGN_INSTANCE_UPDATE_STATUS_V1:
      return WorkflowIcon;
    case EventType.CAMPAIGN_INSTANCE_ADD_TACTICS_V1:
      return ChannelsIcon;
    case EventType.CAMPAIGN_INSTANCE_APPROVE_ALL_ASSETS_V1:
    case EventType.CAMPAIGN_TASK_APPROVE_BRAND_V1:
    case EventType.CAMPAIGN_TASK_TACTIC_COMPLETE_V1:
      return CircleCheckIcon;
    case EventType.CAMPAIGN_INSTANCE_BRAND_ACCEPTED_V1:
    case EventType.CAMPAIGN_INSTANCE_RETAILER_ACCEPTED_V1:
    case EventType.CAMPAIGN_TASK_BRAND_ADOPTION_V1:
      return SendGreenIcon;
    case EventType.CAMPAIGN_INSTANCE_BRAND_APPLIED_V1:
    case EventType.PROMOTE_CAMPAIGN_RESEND_ORIGINAL_INVITE_EVENT_V1:
    case EventType.CAMPAIGN_INSTANCE_TASK_BRAND_APPLY_V1:
      return SendIcon;
    case EventType.CAMPAIGN_INSTANCE_BRAND_DECLINED_V1:
    case EventType.CAMPAIGN_INSTANCE_RETAILER_DECLINED_V1:
      return SendRedIcon;
    case EventType.CAMPAIGN_INSTANCE_BRAND_REQUEST_REVISION_V1:
    case EventType.CAMPAIGN_INSTANCE_RETAILER_CONFIRM_REVISION_V1:
      return CommentsIcon;
    case EventType.CAMPAIGN_TASK_TACTIC_SETUP_V1:
      return GearSixIcon;
    case EventType.CAMPAIGN_TASK_TACTIC_ACTIVE_V1:
      return BoltIcon;
    default:
      return MegaphoneIcon;
  }
}

export const getTacticUniqueIdentifier = (tactic: ISelectableTactic) =>
  `${tactic.area}-${tactic.channelPartnerId}-${tactic.subChannel}-${tactic.channel}`;
