import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../../../../constants/theme/constants';

export const useProductFeedStyles = makeStyles((theme) =>
  createStyles({
    flex: {
      display: 'flex',
    },
    input: {
      width: '5rem',
    },
    form: {
      display: 'flex',
      alignItems: 'center',
      width: '43.75rem',
      margin: '0 auto -3.125rem',
    },
    inputStyle: {
      width: '90%',
      marginRight: '2%',
    },
    notFound: {
      textAlign: 'center',
      marginTop: THEME.REM_SPACES['5'],
    },
    selectContainer: {
      display: 'flex',
      justifyContent: 'end',
      marginBottom: THEME.REM_SPACES['1'],
    },
    imageFix: {
      width: '3.125rem',
    },
    editButtons: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: theme.custom.REM_SPACES['1'],
    },
    editCancelSaveButtons: {
      display: 'flex',
      columnGap: theme.custom.REM_SPACES['0.5'],
    },
  }),
);

export const productImage = {
  width: '3.125rem',
};
