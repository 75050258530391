import { makeStyles, createStyles } from '#/utils/theme';

export const useTablePaginationsStyles = makeStyles(() =>
  createStyles({
    paginationContainer: {
      marginTop: '16px',
      '& ul': {
        justifyContent: 'center',
      },
      '& .MuiPaginationItem-root.Mui-selected, & .MuiPaginationItem-root.Mui-selected:hover': {
        background: '#D8EDFA',
      },
    },
  }),
);
