import { Box } from '@mui/material';
import { FC } from 'react';

import { BASE_PATH, HOST_URL } from '#/constants/general';

import { camelOrPascalToRegular } from '#/utils/textFormatters';

import { getAggregateColumnMapper } from '../../../../constants/tables/taskTable/tasksTable';
import { formatDateAndTime } from '../../../../utils/utils';
import { useDetailsSectionStyles } from './DetailsSection.styles';
import InfoRow from './InfoRow';

interface DetailsSectionProps {
  targetObjectType: string;
  status: string;
  taskStage: string;
  lastUpdated: string;
  description: string;
  taskType: string;
  targetObjectId: string;
  targetObjectName: string;
  targetObjectPath: string;
}

const DetailsSection: FC<DetailsSectionProps> = ({
  targetObjectName,
  targetObjectType,
  lastUpdated,
  description,
  targetObjectPath,
}) => {
  const classes = useDetailsSectionStyles();
  const formattedLastUpdated = formatDateAndTime(lastUpdated);
  const campaignUrl = `${HOST_URL}${BASE_PATH}${targetObjectPath}`;

  const aggregatedColumnValue = getAggregateColumnMapper(targetObjectType);
  const targetObjectTypeValue = camelOrPascalToRegular(aggregatedColumnValue);

  return (
    <Box className={classes.section}>
      <Box className={classes.boxGrid}>
        <InfoRow
          label={'TARGET OBJECT (for more info please click on this link)'}
          link={campaignUrl}
          value={targetObjectName}
        />
        <InfoRow label={'LAST UPDATED'} value={formattedLastUpdated} />
        <InfoRow label={'TARGET OBJECT TYPE'} value={targetObjectTypeValue} />
      </Box>
      <InfoRow label={'DESCRIPTION'} value={description} />
    </Box>
  );
};

export default DetailsSection;
