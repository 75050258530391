import { CalendarModes, CampaignListModes } from '#/components/common/TabModeToggle';

import { ISortProps } from '#/interfaces/table/table';

export type Filter = {
  key: string;
  value: string;
  operation?: string;
};

export interface FiltersState<T> {
  pageNumber: number;
  numberOfElementsPerPage: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortableColumns: any;
  sortParams: ISortProps;
  alwaysActiveFilters: Filter[];
  filters: Filter[];
  searchText: string;
  searchDropdownValue: string;
  searchOperation: string;
  viewMode: T;
  searchQuery: string;
}

export enum INPUT_TYPE {
  TEXT = 'text',
  NUMBER = 'number',
  SELECT = 'select',
  DATE = 'date',
  CURRENCY = 'currency',
  DECIMALS = 'decimals',
  INTERVAL = 'interval',
}

export type FiltersActions = {
  setSearchDropdownValue: (newSearchDropdownValue: string) => void;
  setSearchText: (newSearchText: string) => void;
  clearFilters: () => void;
  addFilter: () => void;
  removeFilter: (filter: Filter) => void;
  loadFilters: () => void;
  setViewMode: (newViewMode) => void;
  setSortParam: (newSearchDropdownValue) => void;
  setPage: (newPageNumber: number) => void;
  setNumberOfElementsPerPage: (newNumberOfElementsPerPage: number) => void;
  setSearchOperation: (newOperationText: string) => void;
};

export type FilterOptions<T extends CampaignListModes | CalendarModes> = {
  viewMode: T;
  alwaysActiveFilters: Filter[];
  filters?: Filter[];
};
