import { Link, useLocation } from 'react-router-dom';

import { useUnexistingRouteStyles } from './UnexistingRoute.styles';

export const UnexistingRoute = () => {
  const classes = useUnexistingRouteStyles();
  const location = useLocation();

  return (
    <div className={classes.wrapperDiv}>
      <p
        className={classes.text}
      >{`Following route: "${location.pathname}" does not exist or you don't have the permission to see it`}</p>
      <Link className={classes.link} to="/">
        Click here to go back home
      </Link>
    </div>
  );
};
