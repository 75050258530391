import { flow } from 'fp-ts/function';

import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { Task } from '#/store/api/tasks/interfaces';

import { camelOrPascalToRegular } from '#/utils/textFormatters';
import { convertAllCapsUnderscoreStrings, formatDateAndTime } from '#/utils/utils';

export const getAggregateColumnMapper = (value: string | number) => `${value}`.replace('Aggregate', '');

export const TaskTableColumns = (goToTarget) => {
  const TaskTable: ITableColumnsProps<Task>[] = [
    {
      fieldName: 'displayName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Name',
      linkable: true,
      baseUrl: '/tasks/overview',
      actionElementIdentifier: 'aggregateId',
    },
    {
      fieldName: 'aggregateId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Id',
    },
    {
      fieldName: null,
      align: TableColumnAlign.CENTER,
      actionElementIdentifier: 'targetObjectName',
      type: TableCellTypes.REACT_TABLE_DATA_ELEMENT,
      element: goToTarget,
      customHead: 'Target Object',
    },
    {
      fieldName: 'targetObjectType',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.STRING,
      customHead: 'Target Type',
      hide: false,
      textFormatter: flow(getAggregateColumnMapper, camelOrPascalToRegular),
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.STRING,
      customHead: 'Status',
      hide: false,
      textFormatter: convertAllCapsUnderscoreStrings,
    },
    {
      fieldName: 'submittedAction',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.STRING,
      customHead: 'Submitted Action',
      hide: false,
      textFormatter: convertAllCapsUnderscoreStrings,
    },
    {
      fieldName: 'lastUpdated',
      align: TableColumnAlign.RIGHT,
      type: TableCellTypes.STRING,
      customHead: 'Last Updated',
      hide: false,
      textFormatter: formatDateAndTime,
    },
  ];

  return { TaskTable };
};
