import { ITacticRow } from '../../../interfaces/channelsAndTactics/channelAndTactics';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '../../../interfaces/table/table';

export const tacticsTableColumns: ITableColumnsProps<ITacticRow>[] = [
  {
    fieldName: 'tactic',
    align: TableColumnAlign.LEFT,
    type: TableCellTypes.STRING,
    customHead: ' ',
  },
];
