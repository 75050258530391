import { IStore } from '#/interfaces/stores/general';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { ICampaignBrand, ICampaignChannelPartner, ICampaignRetailer } from '#/store/api/campaigns/campaignsInterfaces';

import { currencyFormatter, percentageFormatter } from '#/utils/textFormatters';
import { convertAllCapsUnderscoreStrings } from '#/utils/utils';

export const CampaignTableColumns = (
  brandOrderTextFormatter?: (text: string | number) => string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columNames: any = [],
) => {
  const sortableColumns = columNames.map((el) => el.columnName);

  const RetailerCampaignTable: ITableColumnsProps<ICampaignRetailer>[] = [
    {
      fieldName: 'campaignId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Campaign Id',
      hide: true,
    },
    {
      fieldName: 'campaignName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Name',
      linkable: true,
      baseUrl: '/campaigns/overview',
      actionElementIdentifier: 'campaignId',
      sortable: sortableColumns.includes('campaignName'),
    },
    {
      fieldName: 'campaignId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'ID',
      sortable: sortableColumns.includes('campaignId'),
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Status',
      sortable: sortableColumns.includes('status'),

      textFormatter: convertAllCapsUnderscoreStrings,
    },
    {
      fieldName: 'stage',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Stage',
      sortable: sortableColumns.includes('stage'),
      textFormatter: convertAllCapsUnderscoreStrings,
    },
    {
      fieldName: 'campaignGoal',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      textFormatter: currencyFormatter,
      itemClass: 'text-right',
      customHead: 'Goal',
      sortable: sortableColumns.includes('campaignGoal'),
    },
    {
      fieldName: 'percentOfGoal',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: '% of Goal',
      textFormatter: percentageFormatter,
      sortable: sortableColumns.includes('percentOfGoal'),
    },
    {
      fieldName: 'creationDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Creation Date',
      sortable: sortableColumns.includes('creationDate'),
    },
    {
      fieldName: 'startDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Start Date',
      sortable: sortableColumns.includes('startDate'),
    },
    {
      fieldName: 'endDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'End Date',
      sortable: sortableColumns.includes('endDate'),
    },
  ];

  const BrandCampaignTable: ITableColumnsProps<ICampaignBrand>[] = [
    {
      fieldName: 'campaignName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Name',
      linkable: true,
      baseUrl: '/campaigns/brand-orders?',
      actionElementIdentifier: 'campaignId',
      sortable: sortableColumns.includes('campaignName'),
    },
    {
      fieldName: 'campaignId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'ID',
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Status',
      textFormatter: convertAllCapsUnderscoreStrings,
      sortable: sortableColumns.includes('status'),
    },

    {
      fieldName: 'campaignId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      hide: true,
      isKey: true,
    },
    {
      fieldName: 'creationDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Creation Date',
      sortable: sortableColumns.includes('creationDate'),
    },
    {
      fieldName: 'startDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Start Date',
      sortable: sortableColumns.includes('startDate'),
    },
    {
      fieldName: 'endDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'End Date',
      sortable: sortableColumns.includes('endDate'),
    },
    {
      fieldName: 'submissionDeadline',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Submission Deadline',
    },
  ];

  const ChannelPartnerCampaignTable: ITableColumnsProps<ICampaignChannelPartner>[] = [
    {
      fieldName: 'campaignName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Name',
      linkable: true,
      baseUrl: '/campaigns/overview',
      actionElementIdentifier: 'campaignId',
      sortable: sortableColumns.includes('campaignName'),
    },
    {
      fieldName: 'campaignId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'ID',
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Status',
      textFormatter: convertAllCapsUnderscoreStrings,
      sortable: sortableColumns.includes('status'),
    },
    {
      fieldName: 'stage',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Stage',
      textFormatter: convertAllCapsUnderscoreStrings,
      sortable: sortableColumns.includes('stage'),
    },
    {
      fieldName: 'campaignId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Campaign Id',
      hide: true,
    },
    {
      fieldName: 'creationDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Creation Date',
      sortable: sortableColumns.includes('creationDate'),
    },
    {
      fieldName: 'startDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Start Date',
      sortable: sortableColumns.includes('startDate'),
    },
    {
      fieldName: 'endDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'End Date',
      sortable: sortableColumns.includes('endDate'),
    },
    {
      fieldName: 'submissionDeadline',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Submission Deadline',
    },
  ];

  const campaignStoreTable: ITableColumnsProps<IStore>[] = [
    {
      fieldName: 'banner',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Banner',
    },

    {
      fieldName: 'storeId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Store ID',
      isKey: true,
    },
    {
      fieldName: 'address',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Address',
    },
    {
      fieldName: 'city',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'City',
    },
    {
      fieldName: 'state',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'State',
    },
    {
      fieldName: 'zipCode',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Zip Code',
    },
  ];

  return {
    ChannelPartnerCampaignTable,
    RetailerCampaignTable,
    BrandCampaignTable,

    campaignStoreTable,
  };
};

export const CAMPAIGN_ALL = 'ALL';
