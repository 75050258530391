import { Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';
import Loader from '#/components/common/loader/loader';
import { THEME } from '#/constants/theme/constants';
import { UsersType } from '#/constants/usersRoles/usersRole';

import { IAddUserWizardForm } from '#/interfaces/forms/addUserWizard';

import { useGetUserRolesQuery } from '#/store/api/users/users';

import Select from '../common/inputs/Select/Select';
import { TypographyWithFontFamily } from '../common/typography/TypographyWithFontFamily';
import { gridFlexSx, headerWithSpacingSx } from './AddUserWizard.style';

const UserFormOverview = () => {
  const methods = useFormContext<IAddUserWizardForm>();
  const isCompanySelected = methods.watch('selectedCompany.companyId', '')?.length < 1;

  const { data, isFetching } = useGetUserRolesQuery({ userType: UsersType.BRAND });
  if (isFetching) return <Loader />;

  return (
    <>
      <Grid container>
        <Grid item xs={4.9}>
          <TypographyWithFontFamily sx={headerWithSpacingSx} variant="H5_Base">
            User
          </TypographyWithFontFamily>
        </Grid>
        <Grid item xs={5}>
          <TypographyWithFontFamily sx={headerWithSpacingSx} variant="H5_Base">
            Company
          </TypographyWithFontFamily>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: '1rem' }} sx={gridFlexSx}>
        <Grid item xs={2.4}>
          <TextFieldOld
            editMode={false}
            label="User"
            placeholder={`${methods.watch('user.firstName', '')} ${methods.watch('user.lastName', '')}`}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TextFieldOld editMode={false} label="Email" placeholder={methods.watch('user.email')} />
        </Grid>
        <Grid item xs={2.4}>
          <TextFieldOld editMode={false} label="Company" placeholder={methods.watch('selectedCompany.name')} />
        </Grid>
        <Grid item xs={2.4}>
          <TextFieldOld editMode={false} label="Company Type" placeholder={methods.watch('selectedCompany.type')} />
        </Grid>
        <Grid item xs={2.4}>
          <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Error}>
            {methods.formState.errors.defaultRole?.message}
          </TypographyWithFontFamily>
          <Controller
            control={methods.control}
            defaultValue="4"
            name={'defaultRole'}
            render={({ field: { onChange, value } }) => (
              <Select
                editMode={!isCompanySelected}
                label="Default Role"
                onChange={onChange}
                options={data?.map((item) => ({ label: item.name, value: item.id })) || []}
                value={value}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UserFormOverview;
