import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useSnackbarError } from '#/components/common/snackbar/useSnackbarError';

import { returnYupSchemaShapeAssets } from '#/hooks/forms/tacticMetadata/utils';
import useGetResource from '#/hooks/useGetResource';

import { ITacticMetadataDTO, TacticMetadataForm } from '#/interfaces/tacticMetadata/tacticMetadata';

import { useEditInstanceAssetMetadataFormMutation } from '#/store/api/campaigns/tacticAssetForm';
import { useGetRibbonTasksInstanceAssetsMutation } from '#/store/api/ribbon/ribbon';

export const useAssetMetadataForm = (
  inputs: ITacticMetadataDTO[],
  tacticId: string | undefined,
  onClose?: () => void,
) => {
  const { resourceId } = useGetResource();
  const [trigger, _] = useGetRibbonTasksInstanceAssetsMutation({ fixedCacheKey: 'instances_assets_dynamic_ribbon' });
  const schema = yup.object().shape(returnYupSchemaShapeAssets(inputs));

  const { setError } = useSnackbarError();

  const [triggerEditInstanceMetadataForm, { isLoading, error }] = useEditInstanceAssetMetadataFormMutation();

  const {
    register,
    clearErrors,
    control,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<TacticMetadataForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (formData: TacticMetadataForm) => {
    try {
      await triggerEditInstanceMetadataForm({
        formData: JSON.stringify({ metadata: formData }),
        id: tacticId,
      }).unwrap();
      if (onClose) {
        onClose();
        setTimeout(() => {
          trigger({ instanceId: resourceId });
        }, 2000);
      }
    } catch (error) {
      setError(error);
    }
  });

  return {
    register,
    clearErrors,
    control,
    watch,
    setValue,
    reset,
    onSubmit,
    errors,
    schema,
    isLoading,
    error,
  };
};
