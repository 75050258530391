import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';
import { getColorByStatus } from '../../../utils/utils';

export const useTasksSingleHeaderStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: THEME.REM_SPACES[2],
      justifyContent: 'space-between',
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    icon: {
      mr: 2,
      borderRadius: '50%',
      padding: '7px',
      background: '#fff',
      width: '41px',
      height: '41px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    ml1half: {
      '&&': {
        marginLeft: THEME.REM_SPACES['1'],
      },
    },
    tittleColor: {
      '&&': {
        color: THEME.PALETTES.TEXT.Action_Secondary,
      },
    },
    flexWithCenterAlignItems: {
      display: 'flex',
      alignItems: 'center',
    },
    campaignCustomStyle: {
      '&&': {
        fontSize: '1.3125rem',
        color: THEME.PALETTES.UI.Neutral['900'],
        fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
        lineHeight: '1.1',
        display: 'flex',
      },
    },
  }),
);

export const styles = {
  cloneButton: { width: '10rem' },
};

export const statusSx = (status: string) => {
  return {
    fontSize: '0.625rem',
    background: getColorByStatus(status),
    borderRadius: '15px',
    fontWeight: THEME.FONT_WEIGHTS.MEDIUM,
    padding: '0.375rem 0.563rem 0.375rem 0.563rem',
    ml: 2,
    display: 'flex',
    color: THEME.PALETTES.TEXT.Light,
    alignItems: 'center',
  };
};
