import { useGetBrandCampaignCalendarQuery } from '#/store/api/campaigns/campaigns';

import Loader from '../common/loader/loader';
import useGetPeriodsAndMarketingDay from '../features/campaigns/useGetPeriodsAndMarketingDay';
import Calendar from './Calendar';

const CalendarContainerBrand = () => {
  const { data: campaignCalendar, isLoading: campaignCalendarLoading } = useGetBrandCampaignCalendarQuery({});

  const { periods, retailerAccountCalendar, retailerAccountCalendarLoading, periodsLoading } =
    useGetPeriodsAndMarketingDay();

  if (campaignCalendarLoading || retailerAccountCalendarLoading || periodsLoading) return <Loader />;

  if (!campaignCalendar) return null;

  return (
    <Calendar
      periods={periods}
      retailerAccountCalendar={retailerAccountCalendar}
      useGetCampaignCalendarQuery={useGetBrandCampaignCalendarQuery}
    />
  );
};

export default CalendarContainerBrand;
