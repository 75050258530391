import { FC } from 'react';

import { useGetChannelPartnerCalendarQuery } from '../../../store/api/campaigns/campaigns';
import CampaignDetailChannelPartner from '../../common/CampaignDetail/CampaignDetailChannelPartner';
import Loader from '../../common/loader/loader';

interface EventModalChannelPartnerContainerProps {
  campaignId: string;
}

const EventModalChannelPartnerContainer: FC<EventModalChannelPartnerContainerProps> = ({ campaignId }) => {
  const { data, isLoading } = useGetChannelPartnerCalendarQuery({ campaignId });

  if (isLoading) return <Loader />;

  if (!data) return null;

  return <CampaignDetailChannelPartner data={data} />;
};
export default EventModalChannelPartnerContainer;
