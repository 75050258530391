import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useAssociateUserToCompanyStyles } from '#/components/addUserWizard/AssociateUserToCompany.styles';
import { getTableColumns } from '#/components/addUserWizard/AssociateUserToCompany.tableColumns';
import SearchFilters from '#/components/common/SearchFilters/SearchFilters';
import useSearchFilters from '#/components/common/SearchFilters/useSearchFilters.hook';

import { IAddUserWizardForm } from '#/interfaces/forms/addUserWizard';

import { useGetCompaniesAddUserRetailerFilterQuery, useGetCompaniesRetailerQuery } from '#/store/api/company/company';

import Loader from '../common/loader/loader';
import { Table } from '../common/table/tableComponent/Table';

const AssociateUserToCompany = () => {
  const classes = useAssociateUserToCompanyStyles();

  const { setValue, watch } = useFormContext<IAddUserWizardForm>();

  const { data: filterData, isError: filterDataIsError } = useGetCompaniesAddUserRetailerFilterQuery({});

  const { actions, state } = useSearchFilters(undefined, false, filterData?.filters);

  const {
    data: companiesData,
    isError: companiesIsError,
    isFetching: companiesIsFetching,
    isLoading: companiesIsLoading,
  } = useGetCompaniesRetailerQuery({
    pageNumber: state.pageNumber,
    numberOfRows: state.numberOfElementsPerPage,
    searchQuery: state.searchQuery,
  });

  if (companiesIsLoading) return <Loader />;

  const { filteredTableColumns } = getTableColumns(companiesData, watch('selectedCompany'), setValue);

  return (
    <Box className={classes.container}>
      <Box>
        <SearchFilters
          columnNames={filterData?.filters}
          currentIndex={companiesData?.currentIndex}
          filterDataIsError={filterDataIsError}
          hideViewMode
          isError={companiesIsError}
          isFetching={companiesIsFetching}
          isNoResults={!companiesData || companiesData?.companies.length === 0}
          totalPages={companiesData?.totalPages}
          totalResults={companiesData?.totalResults}
          {...state}
          {...actions}
        >
          <Table
            columns={filteredTableColumns}
            containerClass={classes.table}
            data={companiesData?.companies || []}
            headClass={classes.tableHeader}
          />
        </SearchFilters>
      </Box>
    </Box>
  );
};

export default AssociateUserToCompany;
