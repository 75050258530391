import { useParams } from 'react-router';

export interface IResource {
  resourceId: string;
  resourceType: RESOURCE_TYPES;
}

export enum RESOURCE_TYPES {
  campaign = 'campaign',
  instances = 'instances',
  brands = 'brands',
  agencies = 'agencies',
}

const MapperArr: { type: RESOURCE_TYPES; id: string }[] = [
  { type: RESOURCE_TYPES.campaign, id: 'campaignId' },
  { type: RESOURCE_TYPES.instances, id: 'instanceId' },
  { type: RESOURCE_TYPES.brands, id: 'brandId' },
  { type: RESOURCE_TYPES.agencies, id: 'agencyId' },
];

const useGetResource = (): IResource => {
  const params = useParams();

  const activeResource = MapperArr.find((el) => params[el.id]);

  let result: IResource;

  if (activeResource) {
    result = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      resourceId: params[activeResource.id],
      resourceType: activeResource.type,
    };
  } else {
    result = {
      resourceId: '',
      resourceType: RESOURCE_TYPES.campaign,
    };
  }
  return result;
};

export default useGetResource;
