import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router';

import CompanyBillingForm from '#/components/common/forms/companies/CompanyBillingForm/CompanyBillingForm';
import CompanyGeneralForm from '#/components/common/forms/companies/CompanyGeneralForm/CompanyGeneralForm';
import AssociatedAgencies from '#/components/features/partners/brands/AssociatedAgencies/AssociatedAgencies';
import BrandUserList from '#/components/features/partners/brands/BrandUserList/BrandUserList';
import CompanyHierarchy from '#/components/features/partners/brands/viewEditSections/companyHierarchy/companyHierarchy';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import { getCompanyAccountProductMix } from '../../../../api/common/productMix';
import {
  useGetCompanyAccountQuery,
  usePutCompanyAccountProductMixMutation,
} from '../../../../store/api/company/company';
import ProductMix from '../../../common/ProductMix/ProductMix';
import Loader from '../../../common/loader/loader';

const Brand = () => {
  const { brandId } = useParams();
  const { data, isLoading, refetch } = useGetCompanyAccountQuery({ companyId: brandId });

  const [
    putCompanyAccountProductMix,
    { isLoading: isPutCompanyAccountProductMixLoading, error: putCompanyAccountProductMixError },
  ] = usePutCompanyAccountProductMixMutation();

  useEffect(() => {
    refetch();
  }, [brandId]);

  if (isLoading) return <Loader />;
  if (!data) return null;

  return (
    <Box key={brandId}>
      <CompanyGeneralForm />
      <BrandUserList />
      <AssociatedAgencies />
      <ProductMix
        error={putCompanyAccountProductMixError}
        getSelectedObjects={getCompanyAccountProductMix}
        isLoading={isPutCompanyAccountProductMixLoading}
        putSelectedObjects={putCompanyAccountProductMix}
        requiredEditPermission={[USER_PERMISSIONS.PARTNERS_COMPANY_BRAND_PRODUCT_MIX_SECTION_WRITE]}
        targetId={brandId}
      />
      <CompanyBillingForm />
      {brandId && <CompanyHierarchy companyId={brandId} />}
    </Box>
  );
};
export default Brand;
