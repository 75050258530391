import { Box, SelectChangeEvent } from '@mui/material';
import { FC } from 'react';

import CalendarIcon from '#/assets/CalendarIcon.svg';
import CalendarWhiteIcon from '#/assets/CalendarWhiteIcon.svg';
import TimelineIcon from '#/assets/TimelineIcon.svg';
import TimelineWhiteIcon from '#/assets/TimelineWhiteIcon.svg';

import TabModeToggle, { CalendarModes } from '../common/TabModeToggle';
import Select from '../common/inputs/Select/Select';
import { TypographyWithFontFamily } from '../common/typography/TypographyWithFontFamily';
import { useHeaderStyles } from './Header.styles';
import Legend from './Legend';

interface HeaderProps {
  calendarViewHandler: (event: SelectChangeEvent) => void;
  view: string;
  isViewSelectEnabled: boolean;
  selectedTab: CalendarModes;
  setSelectedTab: React.Dispatch<React.SetStateAction<CalendarModes>>;
  startDayOfWeek: number;
  marketingStartDate: string;
}

const Header: FC<HeaderProps> = ({
  calendarViewHandler,
  view,
  isViewSelectEnabled,
  selectedTab,
  setSelectedTab,
  marketingStartDate,
  startDayOfWeek,
}) => {
  const classes = useHeaderStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.optionsContainer}>
        <TypographyWithFontFamily className={classes.header} variant="Label_Base">
          VIEW:
        </TypographyWithFontFamily>
        <TabModeToggle
          iconsMapper={[
            {
              value: CalendarModes.MONTH,
              iconsSources: {
                active: CalendarWhiteIcon,
                inactive: CalendarIcon,
              },
            },
            {
              value: CalendarModes.TIMELINE,
              iconsSources: {
                active: TimelineWhiteIcon,
                inactive: TimelineIcon,
              },
            },
          ]}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <Select
          className={classes.select}
          editMode={isViewSelectEnabled}
          label="Calendar Type"
          labelPosition="Left"
          onChange={calendarViewHandler}
          options={[
            { label: 'Marketing', value: 'Marketing' },
            { label: 'Standard', value: 'Standard' },
          ]}
          value={view}
        />
      </Box>
      {isViewSelectEnabled && <Legend marketingStartDate={marketingStartDate} startDayOfWeek={startDayOfWeek} />}
    </Box>
  );
};

export default Header;
