import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const useDynamicSingleTierStyles = makeStyles(() =>
  createStyles({
    container: {
      flexDirection: 'column',
      overflowX: 'auto',
      overflowY: 'hidden',
      display: 'flex',
    },
    headerContainer: {
      height: '100%',
      maxWidth: '22rem',
      overflowY: 'auto',
      flex: 1,
      minHeight: THEME.REM_SPACES[10],
      maxHeight: THEME.REM_SPACES[15],
      display: 'flex',
      padding: THEME.REM_SPACES[1],
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: THEME.REM_SPACES[0.5],
      alignSelf: 'stretch',
      borderRadius: THEME.REM_SPACES[0.5],
      border: `1px solid ${THEME.PALETTES.BORDER.Selected}`,
      background: THEME.PALETTES.UI.Secondary[100],
      marginRight: THEME.REM_SPACES[1],
    },
  }),
);

export const styles = {
  label: {
    paddingBottom: THEME.REM_SPACES[0.5],
    marginBottom: THEME.REM_SPACES[0.5],
    color: THEME.PALETTES.UI.Neutral[600],
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
};
