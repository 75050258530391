import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const usePartnerUsersGeneralInfoEditStyle = makeStyles(() =>
  createStyles({
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    buttonsContainer: {
      display: 'flex',
      columnGap: THEME.REM_SPACES[0.5],
    },
    editGrid: {
      columnGap: THEME.REM_SPACES[1],
      rowGap: THEME.REM_SPACES[0.5],
    },
  }),
);
