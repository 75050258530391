import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../../../constants/theme/constants';

export const useChannelsAndTacticsStyles = makeStyles(() =>
  createStyles({
    cellText: {
      backgroundColor: THEME.PALETTES.UI.Neutral[200],
      borderRadius: '0.3rem',
      padding: '0.2rem',
    },
    extraRow: {
      width: '100%',
    },
    extraRowCell: {
      padding: '0.4rem 0.8rem',
      border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      fontWeight: THEME.FONT_WEIGHTS.BOLD,
    },
    extraRowCellShort: {
      border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      fontWeight: THEME.FONT_WEIGHTS.BOLD,
    },
    extraRowCellShortBrand: {
      padding: '0.5rem 1rem',
      border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      fontWeight: THEME.FONT_WEIGHTS.BOLD,
    },
    headCellClass: {
      padding: '0.4rem 0.8rem',
      border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      fontWeight: THEME.FONT_WEIGHTS.BOLD,
      backgroundColor: THEME.PALETTES.UI.Neutral[200],
    },
    noMargin: {
      marginBottom: 0,
    },
    pageContainer: {
      background: THEME.PALETTES.OTHER.White,
      borderRadius: THEME.REM_SPACES[1],
      padding: THEME.REM_SPACES[1],
      display: 'flex',
      flexDirection: 'column',
      rowGap: THEME.REM_SPACES[1],
    },
    pageElementContainer: {
      border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      padding: THEME.REM_SPACES[1],
      borderRadius: THEME.REM_SPACES[1],
      display: 'flex',
      flexDirection: 'column',
      rowGap: THEME.REM_SPACES[1],
    },
    tableContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: THEME.REM_SPACES[2],
    },
    tierName: {
      marginTop: 0,
      marginBottom: '1.125rem',
      fontWeigth: THEME.FONT_WEIGHTS.SEMIBOLD,
      fontSize: '1.2rem',
      color: THEME.PALETTES.UI.Neutral[900],
    },
  }),
);
