import { IReports } from '#/interfaces/reports/reports';

import { IReportAddFormDTO } from '#/store/api/reports/reportsInterfaces';

import { baseApi } from '../../base';
import { SearchFilters } from '../campaigns/campaignsInterfaces';

const reportsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getReportFilter: builder.query<{ filters: SearchFilters[]; reportId: string }, unknown>({
      query: ({ reportId = '' }) => `report/report/filters/${reportId}`,
    }),

    getReportDetails: builder.query<
      IReports,
      {
        reportId?: string;
        searchQuery: string;
      }
    >({
      query: ({ searchQuery = '', reportId = '' }) => {
        const searchParams = new URLSearchParams();

        searchParams.set('searchQuery', searchQuery);

        return `report/report/data/${reportId}?${searchParams}`;
      },
    }),
    getReportAltExport: builder.mutation<
      { contentDisposition: string | null; file: Blob },
      {
        altId?: string;
        searchQuery: string;
      }
    >({
      query: ({ searchQuery = '', altId = '' }) => {
        const searchParams = new URLSearchParams();
        searchParams.set('searchQuery', searchQuery);
        return {
          url: `report/report/alt/export/${altId}?${searchParams}`,
          method: 'GET',
          responseHandler: async (response) => {
            return { file: await response.blob(), contentDisposition: response.headers.get('content-disposition') };
          },
        };
      },
    }),
    getReportDetailsByAltId: builder.query<
      IReports,
      {
        altId?: string;
        searchQuery: string;
      }
    >({
      query: ({ searchQuery = '', altId = '' }) => {
        const searchParams = new URLSearchParams();

        searchParams.set('searchQuery', searchQuery);

        return `report/report/alt/data/${altId}?${searchParams}`;
      },
    }),
    postReportAdd: builder.mutation<void, { data: IReportAddFormDTO }>({
      query: ({ data }) => ({
        url: 'report/report/add',
        method: 'POST',
        body: { data },
      }),
    }),
    deleteReportDelete: builder.mutation<void, { reportId: string | undefined }>({
      query: ({ reportId }) => ({
        url: `report/report/delete/${reportId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetReportFilterQuery,
  useGetReportDetailsQuery,
  useGetReportAltExportMutation,
  useGetReportDetailsByAltIdQuery,
  usePostReportAddMutation,
  useDeleteReportDeleteMutation,
} = reportsApi;
