import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const useDatePickerStyles = makeStyles((theme) =>
  createStyles({
    input: {
      display: 'flex',
      height: THEME.REM_SPACES[2],
      Left: THEME.REM_SPACES['0.5'],
      alignItems: 'center',
      flex: '1 0 0',
      color: 'var(--text-dark, #16191F)',
      fontFamily: 'Public Sans',
      fontSize: '0.813rem',
      fontWeight: 400,
      padding: '0px',
      margin: '0px',

      '&& :first-child': {
        borderRadius: THEME.REM_SPACES[0.5],
      },

      '&& input': {
        height: THEME.REM_SPACES[2],
        padding: `0 0 0 ${THEME.REM_SPACES[1]}`,
        margin: '0px',
        color: THEME.PALETTES.UI.Neutral['900'],
        fontFamily: 'Public Sans',
        fontSize: '0.813rem',
        fontWeight: 400,
      },
      '&& button': {
        padding: '0px',
        margin: '0px',
      },
    },
    height: {
      height: THEME.REM_SPACES[2],
      padding: '0px',
      margin: '0px',
      display: 'flex',

      '&& input': {
        height: THEME.REM_SPACES[2],
        padding: '0px',
        margin: '0px',
      },
    },
    label: {
      marginTop: THEME.REM_SPACES['0.25'],
    },
    disabled: {
      borderRadius: THEME.REM_SPACES['0.5'],
      border: THEME.PALETTES.UI.Neutral['200'],
      background: THEME.PALETTES.UI.Neutral['200'],
      '&& input': {
        '-webkit-text-fill-color': theme.custom.PALETTES.TEXT.Dark,
      },
    },
  }),
);
