import { CreativeSpecs } from '#/components/campaigns/campaignAssets/CreativeSpecs';
import DynamicRibbonTasksCampaignBrandAdoption from '#/components/features/campaigns/DynamicRibbonTasksCampaignBrandAdoption';

import PermissionWrapper from '#/utils/PermissionWrapper';

import { USER_PERMISSIONS } from '../../../constants/permissions/userPermissions';
import CampaignOverviewInternalContainer from '../../campaigns/CampaignOverviewInternalContainer';
import CampaignOverviewLegalContainer from '../../campaigns/CampaignOverviewLegalContainer';
import CampaignOverviewMarketingContainer from '../../campaigns/CampaignOverviewMarketingContainer';
import CampaignOverviewGeneralContainer from '../../campaigns/campaignOverviewGeneral/CampaignOverviewGeneralContainer';
import { IVerticalLayoutProp, VerticalLayout } from '../../common/pageLayouts/VerticalLayout';

const CampaignOverview = () => {
  const componentsToRender: IVerticalLayoutProp[] = [
    {
      component: <CampaignOverviewGeneralContainer />,
      requiredPermission: USER_PERMISSIONS.CAMPAIGN_OVERVIEW_GENERAL_READ,
    },
    {
      component: <CampaignOverviewInternalContainer />,
      requiredPermission: USER_PERMISSIONS.CAMPAIGN_OVERVIEW_INTERNAL_READ,
    },
    {
      component: <CampaignOverviewMarketingContainer />,
      requiredPermission: USER_PERMISSIONS.CAMPAIGN_OVERVIEW_MARKETING_READ,
    },
    {
      component: <CampaignOverviewLegalContainer />,
      requiredPermission: USER_PERMISSIONS.CAMPAIGN_OVERVIEW_LEGAL_READ,
    },
    {
      component: <CreativeSpecs />,
      requiredPermission: USER_PERMISSIONS.CAMPAIGN_OVERVIEW_CREATIVE_SPECS_READ,
    },
  ];

  return (
    <>
      <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.PROMOTE_PENDING_INVITATIONS]}>
        <DynamicRibbonTasksCampaignBrandAdoption />
      </PermissionWrapper>
      <VerticalLayout components={componentsToRender} />
    </>
  );
};

export default CampaignOverview;
