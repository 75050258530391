import { useParams } from 'react-router';

import Loader from '#/components/common/loader/loader';
import { CampaignInstancesBrand } from '#/components/features/campaigns/instances/list/CampaignInstancesBrand/CampaignInstancesBrand';

import { useGetInstancesBrandFilterQuery } from '#/store/api/campaigns/instances';

export const CampaignInstancesBrandContainer = () => {
  const { campaignId } = useParams();
  const { isLoading: filterDataIsLoading } = useGetInstancesBrandFilterQuery({ campaignId });

  if (filterDataIsLoading) return <Loader />;

  return <CampaignInstancesBrand />;
};
