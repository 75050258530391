import { Box } from '@mui/material';

import { useAppDispatch, useAppSelector } from '#/hooks';

import { ICommunicationsRecipients } from '#/interfaces/forms/campaignInvitation';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { useGetCommunicationsRecipientsMutation } from '#/store/api/communications/communications';
import { communicationsPromote } from '#/store/slices';

import { convertAllCapsUnderscoreStrings } from '#/utils/utils';

import { Table } from '../../../common/table/tableComponent/Table';

const CommunicationSelectRecipientsTable = () => {
  const [, { data }] = useGetCommunicationsRecipientsMutation({
    fixedCacheKey: 'communications-recipients',
  });
  const dispatch = useAppDispatch();
  const selectedRecipientsIds = useAppSelector((state) =>
    communicationsPromote.getSelectedRecipientsIds(state.communications),
  );
  const tableColumns: ITableColumnsProps<ICommunicationsRecipients>[] = [
    {
      fieldName: null,
      customHead: ' ',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.CHECKBOX,
      checkBoxAction: (checked: boolean, userId: string) => {
        const recipientItemCheck = data?.find((recipient) => recipient.userId === userId);
        recipientItemCheck && dispatch(communicationsPromote.actions.updateSelectedRecipients(recipientItemCheck));
      },
      actionElementIdentifier: 'userId',
      actionElementSecondIdentifier: 'email',
      checkFromList: selectedRecipientsIds,
      hasCheckAll: true,
      checkAllAction: (checked) => {
        if (checked && data) {
          dispatch(communicationsPromote.actions.selectAllRecipients(data));
        } else {
          dispatch(communicationsPromote.actions.clearSelectedRecipients());
        }
      },
    },
    {
      fieldName: 'fullName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Name',
    },
    {
      fieldName: 'email',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Email',
    },
    {
      fieldName: 'jobTitle',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Job Title',
    },
    {
      fieldName: 'companyName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Company Name',
    },
    {
      fieldName: 'companyType',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Company Type',
    },
    {
      fieldName: 'invitationStatus',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Invitation Status',
      textFormatter: (text) => convertAllCapsUnderscoreStrings(text.toString()) || 'Not Sent',
    },
  ];

  if (data && data.length === 0) {
    return <Box>No recipients available to select</Box>;
  }

  return <Table columns={tableColumns} data={data || []} />;
};
export default CommunicationSelectRecipientsTable;
