import { Box } from '@mui/system';
import dayjs from 'dayjs';
import React, { FC, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import AlertError from '#/components/common/Alert/AlertError/AlertError';
import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';

import { usePostDataJobMutation } from '#/store/api/dataJob/dataJob';

import ArchiveIcon from '../../../../assets/ArchiveIcon.png';
import DeleteBlueIcon from '../../../../assets/DeleteBlueIcon.svg';
import DownloadIconBlue from '../../../../assets/DownloadIconBlue.svg';
import FileBlueIcon from '../../../../assets/FileBlueIcon.svg';
import PlusIcon from '../../../../assets/Plus.svg';
import { THEME } from '../../../../constants/theme/constants';
import NewButton from '../../../common/button/NewButton';
import Modal from '../../../common/modal/Modal';
import { useSnackbarError } from '../../../common/snackbar/useSnackbarError';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { useAddDataJobStyle } from './AddDataJob.style';

interface IAddDataJobProps {
  isAddDataJobOpen: boolean;
  setIsAddDataJobOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => void;
}

const AddDataJob: FC<IAddDataJobProps> = ({ isAddDataJobOpen, setIsAddDataJobOpen, refetch }) => {
  const [postDataJob, { error }] = usePostDataJobMutation();
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const dopHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.items) {
      setCurrentFile(e.dataTransfer.items[0].getAsFile());
    } else {
      setCurrentFile(e.dataTransfer.files[0]);
    }
  };
  const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const handleBrowseFile = () => {
    inputRef.current?.click();
  };
  const handleDownloadFile = () => {
    const link = document.createElement('a');
    link.href = (currentFile && URL.createObjectURL(currentFile)) || '';
    link.setAttribute('download', currentFile?.name || 'file.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const classes = useAddDataJobStyle();
  const { register, handleSubmit } = useForm<{ name: string }>();
  const { setError } = useSnackbarError();
  const onClose = () => setIsAddDataJobOpen(false);
  const onSubmit = handleSubmit(async (data) => {
    try {
      await postDataJob({ name: data.name, formData: currentFile }).unwrap();
      onClose();
      refetch();
    } catch (error) {
      setError(error);
    }
  });

  return (
    <Modal
      content={
        <form onSubmit={onSubmit}>
          <AlertError error={error} />
          <TextFieldOld editMode label="Data Job Name" register={register('name')} />
          <Box className={classes.modalBody}>
            {!currentFile && (
              <Box
                className={classes.uploadContainer}
                onClick={handleBrowseFile}
                onDragOver={dragOverHandler}
                onDrop={dopHandler}
              >
                <input
                  ref={inputRef}
                  onChange={(e) => setCurrentFile(e.target.files?.[0] || null)}
                  style={{ display: 'none' }}
                  type="file"
                />
                <img alt="File" src={FileBlueIcon} />
                <TypographyWithFontFamily>Drag & Drop to Upload File</TypographyWithFontFamily>
                <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Action_Secondary}>
                  Browse File
                </TypographyWithFontFamily>
              </Box>
            )}
            {currentFile && (
              <Box className={classes.previewFile}>
                <Box className={classes.previewLeft}>
                  <img alt="Archive" className={classes.ArchiveIcon} src={ArchiveIcon} />
                  <Box>
                    <TypographyWithFontFamily>{currentFile?.name}</TypographyWithFontFamily>
                    <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray}>
                      Uploaded at {dayjs().format('MM/DD/YYYY')}
                    </TypographyWithFontFamily>
                  </Box>
                </Box>
                <Box className={classes.previewRight}>
                  <img
                    alt="Download File"
                    className={classes.previewIcon}
                    onClick={handleDownloadFile}
                    src={DownloadIconBlue}
                  />
                  <img
                    alt="Delete File"
                    className={classes.previewIcon}
                    onClick={() => setCurrentFile(null)}
                    src={DeleteBlueIcon}
                  />
                </Box>
              </Box>
            )}
          </Box>

          <Box className={classes.buttonsContainer}>
            <NewButton onClick={onClose} text="Cancel" variant="Secondary" />
            <NewButton text="Submit" type="submit" />
          </Box>
        </form>
      }
      handleCloseModal={onClose}
      icon={PlusIcon}
      modalTitle="Add Data Job"
      open={isAddDataJobOpen}
    />
  );
};

export default AddDataJob;
