import { Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';

import { useDataCy } from '#/hooks/useDataCy';

import { USER_PERMISSIONS } from '../../../constants/permissions/userPermissions';
import { useOverviewEditGeneral } from '../../../hooks/forms/campaigns/useOverviewEditGeneral';
import DatePicker from '../../common/inputs/DatePicker/DatePicker';
import Loader from '../../common/loader/loader';
import { useCampaignStyles } from '../campaign.style';
import { TopSection } from '../topSection/TopSection';
import { useCampaignOverviewGeneral } from './CampaignOverviewGeneral.styles';

const CampaignOverviewGeneral = () => {
  const generateDataCy = useDataCy();

  const [editMode, setEditMode] = useState(false);
  const styles = useCampaignStyles();
  const classes = useCampaignOverviewGeneral();

  const { onSubmit, control, errors, register, setValue, isLoading, dataLoading, reset, refetch, error } =
    useOverviewEditGeneral(() => setEditMode(false));

  return (
    <>
      <form onSubmit={onSubmit}>
        <Box className={styles.wrapperDivOverviewGeneral} data-cy={generateDataCy('tab', 'CampaignOverviewGeneral')}>
          <TopSection
            dataLoading={dataLoading}
            editMode={editMode}
            error={error}
            isLoading={isLoading}
            onSubmit={onSubmit}
            refetch={refetch}
            requiredPermission={USER_PERMISSIONS.CAMPAIGN_OVERVIEW_GENERAL_WRITE}
            reset={reset}
            sectionName="General"
            setEditMode={setEditMode}
          />
          {dataLoading ? (
            <Loader />
          ) : (
            <Box className={styles.boxGrid}>
              <Box>
                <TextFieldOld
                  className={classes.input}
                  editMode={editMode}
                  error={errors.campaignName?.message}
                  label="Campaign Name"
                  labelPosition="Top"
                  register={register('campaignName')}
                ></TextFieldOld>
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      control={control}
                      {...register('startDate')}
                      render={({ field }) => {
                        return (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              editMode={editMode}
                              error={errors.startDate?.message}
                              label="CAMPAIGN START"
                              onChange={(value) => {
                                setValue('startDate', dayjs(value).format('YYYY-MM-DD'), { shouldValidate: true });
                              }}
                              value={field.value}
                            />
                          </LocalizationProvider>
                        );
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box>
                <Box className={styles.marginBottom}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      control={control}
                      {...register('submissionDeadline')}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            editMode={editMode}
                            error={errors.submissionDeadline?.message}
                            label="BRAND RESPONSE DEADLINE"
                            onChange={(value) => {
                              setValue('submissionDeadline', dayjs(value).format('YYYY-MM-DD'), {
                                shouldValidate: true,
                              });
                            }}
                            value={field.value}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      control={control}
                      {...register('endDate')}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            editMode={editMode}
                            error={errors.endDate?.message}
                            label="CAMPAIGN END"
                            onChange={(value) => {
                              setValue('endDate', dayjs(value).format('YYYY-MM-DD'), { shouldValidate: true });
                            }}
                            value={field.value}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </form>
    </>
  );
};
export default CampaignOverviewGeneral;
