import * as React from 'react';
import MUITextField, { TextFieldProps as DefaultTextFieldProps } from '@mui/material/TextField';
import classNames from 'classnames';
import { DebouncedFunc, debounce, omit } from 'lodash';
import { ChangeEventHandler, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { THEME } from '#/constants/theme/constants';

import { useDataCy } from '#/hooks/useDataCy';

import { TypographyWithFontFamily } from '../../typography/TypographyWithFontFamily';
import { useInputsStyle } from '../Inputs.styles';
import { disabledInputSx, styles, useTextFieldStyle } from './TextField.styles';

const DEBOUNCE_TIME = 1000; // 1s;

export type NonEmptyString<T extends string> = '' extends T ? never : T;

type DataCyUnion<R extends string> =
  | {
      label?: undefined | '';
      dataCy: string;
    }
  | {
      label: NonEmptyString<R>;
      dataCy?: string;
    };

type FormHooksUnion<T extends string, P extends string> =
  | {
      disabled: true;
      onChange?: undefined;
      /**
       * @deprecated Use onChange & value
       */
      formHooks?: undefined;
    }
  | (
      | ({
          disabled?: boolean;
        } & {
          onChange?: undefined;
          /**
           * @deprecated Use onChange & value
           */
          formHooks: {
            register: UseFormRegisterReturn<P>;
            setValue: (value: T) => void;
          };
        })
      | {
          onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
          /**
           * @deprecated Use onChange & value
           */
          formHooks?: undefined;
        }
    );

type TextFieldProps<T extends string, P extends string, R extends string> = Omit<
  DefaultTextFieldProps,
  'defaultValue' | 'error' | 'value' | 'onChange' | 'label'
> & {
  defaultValue?: T;
  value?: T;
  labelPosition?: 'Top' | 'Left' | 'Default';
  error?: ReactNode | undefined;
  formattersDebounce?: number;
  formatters?: {
    preview?: (value: string) => string;
    typing?: ((value: string) => string) & { reverse?: (value: string) => number | string };
  };
} & DataCyUnion<R> &
  FormHooksUnion<T, P>;

const TextField = <T extends string, P extends string, R extends string>(props: TextFieldProps<T, P, R>) => {
  const {
    error,
    placeholder,
    labelPosition = 'Top',
    InputProps,
    className,
    multiline,
    disabled,
    onBlur,
    value,
    rows = 1,
    style,
    formatters,
    formattersDebounce = DEBOUNCE_TIME,
    defaultValue,
    // we are ignoring the following because we need the discriminated union to work
    onChange: _onChange,
    formHooks: _formHooks,
    label: _label,
    ...rest
  } = props;

  const inputsClasses = useInputsStyle();
  const classes = useTextFieldStyle();
  const generateDataCy = useDataCy();

  const refCallback = useCallback((ref: HTMLInputElement) => {
    props.formHooks?.register.ref(ref);

    // This is used to extract the value from ref
    if (typeof ref?.value !== 'undefined' && initialRunRef.current) {
      setInternalValue(formatInternalValue(ref.value));
      initialRunRef.current = false;
    }
  }, []);

  const formatInternalValue = (value: string) =>
    disabled ? formatters?.preview?.(value) ?? value : formatters?.typing?.(value) ?? value;

  const cancelFormatInternalValue = (value: string) => formatters?.typing?.reverse?.(value) ?? value;

  const debounceChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (formatters?.typing) {
      onChangeTimeout.current?.cancel();
      onChangeTimeout.current = debounce(() => {
        const newValue = formatInternalValue(event.target.value);
        const newCleanValue = cancelFormatInternalValue(newValue).toString();

        const cleanEvent = {
          ...event,
          target: { ...event.target, value: newCleanValue },
        };

        if (props.onChange) {
          props.onChange?.(cleanEvent);
        } else if (!props.disabled) {
          props.formHooks.setValue(newCleanValue as T);
        }

        setInternalValue(newValue);
      }, formattersDebounce);
      onChangeTimeout.current();
    } else {
      const newCleanValue = cancelFormatInternalValue(event.target.value).toString();
      const cleanEvent = {
        ...event,
        target: { ...event.target, value: newCleanValue },
      };

      if (props.onChange) {
        props.onChange?.(cleanEvent);
      } else if (!props.disabled) {
        props.formHooks.setValue(newCleanValue as T);
      }
    }
  };

  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    setInternalValue(event.target.value);
    debounceChange(event);
  };

  useEffect(() => {
    if (typeof value !== 'undefined') {
      setInternalValue(formatInternalValue(value));
    }
  }, [value]);

  const [internalValue, setInternalValue] = useState(formatInternalValue(defaultValue ?? value ?? ''));
  const onChangeTimeout = useRef<DebouncedFunc<() => void>>();
  const initialRunRef = useRef(true);

  const stateStyle = disabled && styles.disabled;

  const dataCy = useMemo(
    /* @FIXME when label is set dynamically, sometimes it can be undefined */
    () => generateDataCy('TextField', props.label ?? props.dataCy ?? ''),
    [props.label, props.dataCy],
  );

  return (
    <div className={classNames(inputsClasses[labelPosition], className)}>
      {props.label && (
        <TypographyWithFontFamily
          className={classes.labelUppercase}
          color={THEME.PALETTES.TEXT.Gray}
          component="label"
          htmlFor={dataCy}
          variant="Label_Base"
        >
          {props.label}
        </TypographyWithFontFamily>
      )}
      <MUITextField
        FormHelperTextProps={{
          className: inputsClasses.textError,
        }}
        InputProps={{
          style: {
            ...styles.input,
            ...stateStyle,
            ...style,
          },
          ...(onBlur && { onBlur }),
          ...InputProps,
        }}
        className={`${classes.input} ${disabled ? classes.readOnly : ''}`}
        data-cy={dataCy}
        disabled={disabled}
        fullWidth
        helperText={error}
        multiline={multiline}
        name={dataCy}
        onChange={onChangeHandler}
        placeholder={placeholder}
        rows={rows}
        sx={disabledInputSx}
        value={internalValue}
        {...rest}
      />
      {/** This input is used to register the input in the form */}
      {props.formHooks && (
        <input ref={refCallback} style={{ display: 'none' }} type="text" {...omit(props.formHooks.register, 'ref')} />
      )}
    </div>
  );
};

export default TextField;
