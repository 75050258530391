import { Box } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router';

import PartnersIcon from '#/assets/PartnerGreyIcon.svg';

import NewButton from '#/components/common/button/NewButton';
import Card from '#/components/common/card/card';
import Modal from '#/components/common/modal/Modal';
import BrandsTableContainer from '#/components/features/associateAgencyToBrands/BrandsTableContainer';
import getTableColumns from '#/components/features/partners/agencies/AssociatedBrands/AssociatedBrands.tables';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import { useGetAssociatedCompaniesQuery } from '#/store/api/company/company';

import PermissionWrapper from '#/utils/PermissionWrapper';

import ContactsIcon from '../../../../../assets/ContactsIcon.svg';
import PlusIcon from '../../../../../assets/Plus.svg';
import { Table } from '../../../../common/table/tableComponent/Table';
import { TypographyWithFontFamily } from '../../../../common/typography/TypographyWithFontFamily';
import { useAssociatedBrandsStyle } from './AssociatedBrands.style';

const AssociatedBrands = () => {
  const classes = useAssociatedBrandsStyle();
  const { agencyId } = useParams();
  const tableColumns = getTableColumns();
  const [isOpen, setIsOpens] = useState(false);

  const { data } = useGetAssociatedCompaniesQuery({ companyId: agencyId });

  if (!data) return null;

  return (
    <Card>
      <Box className={classes.flexWithSpaceBetween}>
        <Box className={classes.headerContainer}>
          <img alt="contacts icon" src={ContactsIcon} />
          <TypographyWithFontFamily variant="H3_Base">Associated Brands</TypographyWithFontFamily>
        </Box>
        <PermissionWrapper
          requiredPermissions={[USER_PERMISSIONS.PARTNERS_COMPANY_AGENCY_ASSOCIATE_BRANDS_SECTION_WRITE]}
        >
          <NewButton icon={PlusIcon} onClick={() => setIsOpens(true)} text={'Associate Brands'} />
        </PermissionWrapper>
      </Box>
      <Table columns={tableColumns} containerClass={classes.tableContainer} data={data} />

      <Modal
        content={<BrandsTableContainer onClose={() => setIsOpens(false)} />}
        handleCloseModal={() => setIsOpens(false)}
        icon={PartnersIcon}
        modalTitle="Associate Brands"
        open={isOpen}
        size="tall"
      />
    </Card>
  );
};

export default AssociatedBrands;
