import { useEffect, useState } from 'react';

import { EditableCellTypes, ITableColumnsProps } from '../../../../../interfaces/table/table';
import { EditableCellDatePicker } from './EditableCellDatePicker';
import { EditableCellInput } from './EditableCellInput';

export const EditableInput = <T,>({
  column,
  data,
  type,
}: {
  column: ITableColumnsProps<T>;
  data: T;
  type: EditableCellTypes;
}) => {
  const [isEditMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const setInputToDefaultValue = () => {
    setEditMode(false);
    const element = data[column.fieldName as keyof T] ? String(data[column.fieldName as keyof T]) : '';
    const formattedElement = column.textFormatter ? column.textFormatter(element) : element;
    setInputValue(formattedElement);
  };

  const setInputValueToTableData = () => {
    if (inputValue && column.setEditableTableValues) {
      column.setEditableTableValues(
        String(data[column.actionElementIdentifier as keyof T]),
        inputValue,
        String(column.actionElementIdentifier as keyof T),
      );
    }
    setEditMode(false);
  };

  useEffect(setInputToDefaultValue, []);

  switch (type) {
    case EditableCellTypes.INPUT:
      return (
        <EditableCellInput<T>
          column={column}
          inputValue={inputValue}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          setInputToDefaultValue={setInputToDefaultValue}
          setInputValue={setInputValue}
          setInputValueToTableData={setInputValueToTableData}
        />
      );
    case EditableCellTypes.DATE_PICKER:
      return (
        <EditableCellDatePicker
          inputValue={inputValue}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          setInputToDefaultValue={setInputToDefaultValue}
          setInputValue={setInputValue}
          setInputValueToTableData={setInputValueToTableData}
        />
      );
    default:
      return null;
  }
};
