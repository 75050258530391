import { BarChartData, IPieChartComponentData, IPieChartData } from '#/interfaces/dashboard/charts';
import { IPendingTasks } from '#/interfaces/dashboard/pendingTasks';
import { AlertFields, IReports, IReportsHead } from '#/interfaces/reports/reports';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { currencyFormatter } from '#/utils/textFormatters';

const CHART_FIELDS = ['completed', 'booked', 'pending'];
const FISCAL_YEAR_GOAL = 'fiscal_year_goal';
const ONE_THOUSAND = 1000;
const ONE_MILLION = 1000000;
const ONE_BILLION = 1000000000;

export const generateTableProps = (tableData?: IReports, cropData = false) => {
  if (!tableData) {
    return {
      tableColumns: [],
      tableValues: [],
    };
  }

  const { head, data } = tableData;
  const mappedHeaderIndex = {};
  head.forEach((item, index) => {
    mappedHeaderIndex[index] = item.fieldName;
  });

  // any used here because table data type is dynamic
  const tableColumns: ITableColumnsProps<Record<string, unknown>>[] = head.map((element) => {
    const {
      fieldName,
      align,
      type,
      isKey,
      hide,
      customHead,
      linkable,
      baseUrl,
      actionElementIdentifier,
      currency,
      urlExtraParams,
    } = element;

    return {
      fieldName,
      align: align ? (align as TableColumnAlign) : TableColumnAlign.LEFT,
      type: type ? (type as TableCellTypes) : TableCellTypes.STRING,
      ...(customHead && { customHead }),
      ...(isKey && { isKey }),
      ...(hide && { hide }),
      ...(linkable && { linkable }),
      ...(baseUrl && { baseUrl }),
      ...(urlExtraParams && { urlExtraParams }),
      ...(actionElementIdentifier && { actionElementIdentifier }),
      ...(currency && {
        itemClass: 'text-right',
        textFormatter: currencyFormatter,
      }),
      headerTooltip: customHead ? customHead : fieldName,
      sortable: true,
    };
  });

  const croppedData = cropData ? data.slice(0, 5) : data;

  const tableValues = croppedData.map((element) => {
    const dataElement = {};
    element.forEach((item, itemIndex: number) => {
      Object.keys(mappedHeaderIndex).forEach((key, index) => {
        if (index === itemIndex) {
          dataElement[mappedHeaderIndex[key]] = item;
        }
      });
    });
    return dataElement;
  });

  return { tableColumns, tableValues };
};

export const generateBarChartData = (reportData: IReports) => {
  const { head, data } = reportData;
  const chartData: BarChartData[] = [];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  data.forEach((dataElement) => {
    const element = {
      //FIXME: this is a temporary solution, we need to find a way to get the month name correctly
      Month: months[(dataElement[head.findIndex((el) => el.fieldName === 'month')] as number) - 1],
      Pending: dataElement[head.findIndex((el) => el.fieldName === 'pending')] as number,
      Booked: dataElement[head.findIndex((el) => el.fieldName === 'booked')] as number,
      Completed: dataElement[head.findIndex((el) => el.fieldName === 'completed')] as number,
    };
    chartData.push(element);
  });

  return chartData;
};

export const generatePieChartData = (reportData: IReports): IPieChartComponentData => {
  const { head, data } = reportData;
  const chartData: IPieChartData[] = [];
  let total = 0;
  let fiscalYearGoal = 0;
  head.forEach((dataElement, index) => {
    if (CHART_FIELDS.includes(dataElement.fieldName)) {
      const valueIndex = CHART_FIELDS.indexOf(dataElement.fieldName);
      const element = {
        name: dataElement.fieldName,
        value: Number(data[0][index]),
      };
      total += Number(data[0][index]);
      chartData[valueIndex] = element;
    } else if (dataElement.fieldName === FISCAL_YEAR_GOAL) {
      fiscalYearGoal = Number(data[0][index]);
    }
  });
  chartData.push({ name: 'toGoal', value: fiscalYearGoal - total < 0 ? 0 : fiscalYearGoal - total });

  return { chartData, fiscalYearGoal };
};

export const formatFiscalYearGoal = (goal: number): string => {
  if (goal >= ONE_THOUSAND && goal < ONE_MILLION) {
    const value = (goal / ONE_THOUSAND).toFixed(2);
    return `$${value}K`;
  } else if (goal >= ONE_MILLION && goal < ONE_BILLION) {
    const value = (goal / ONE_MILLION).toFixed(2);
    return `$${value}M`;
  } else if (goal >= ONE_BILLION) {
    const value = (goal / ONE_BILLION).toFixed(2);
    return `$${value}B`;
  }
  const value = goal.toFixed(2);
  return `$${value}`;
};

export const generatePendingTasksData = (pendingTasks: IReports) => {
  const { data, head } = pendingTasks;
  const tasks: IPendingTasks[] = [];

  data.forEach((taskElement) => {
    const element = {
      //@FIXME: this is a temporary solution, we need to find a way to get the task type correctly
      name: taskElement[head.findIndex((el) => el.fieldName === 'task_type')] as string,
      count: taskElement[head.findIndex((el) => el.fieldName === 'task_count')] as number,
    };
    tasks.push(element);
  });

  return tasks;
};

export const generateAlertsData = (pendingAlerts: IReports<AlertFields>) => {
  const { data, head } = pendingAlerts;
  return data.map((alertElement) => {
    return {
      name: alertElement[head.findIndex((el) => el.fieldName === 'alert_name')] as string,
      count: alertElement[head.findIndex((el) => el.fieldName === 'alert_count')] as number,
      url: `/reports/${alertElement[head.findIndex((el) => el.fieldName === 'redirectReportId')]}`,
    };
  });
};

export const generateWhereTooltipText = (headData: IReportsHead[]) => {
  const availableFields = headData.map((element) => element.fieldName);
  return `Query example: company_name='my_company'. All available field names are: ${availableFields.join(
    ', ',
  )}. Hover the table head to see each field name.`;
};

export const generateOrderTooltipText = (headData: IReportsHead[]) => {
  const availableFields = headData.map((element) => element.fieldName);
  return `Query example: company_name=asc&campaign_budget=desc. All available field names are: ${availableFields.join(
    ', ',
  )}. Hover the table head to see each field name.`;
};

export const generateUrl = <T extends string>(reports: IReports<T>, id: T) => {
  const { data, head } = reports;
  const reportId = data[0]?.at(head.findIndex((el) => el.fieldName === id));

  return reportId ? `/reports/${reportId}` : undefined;
};
