import useGetResource from '#/hooks/useGetResource';

import { useGetCampaignOverviewMarketingSectionQuery } from '../../store/api/campaigns/campaigns';
import Loader from '../common/loader/loader';
import CampaignOverviewMarketing from './CampaignOverviewMarketing';

const CampaignOverviewMarketingContainer = () => {
  const resource = useGetResource();
  const { data, isLoading } = useGetCampaignOverviewMarketingSectionQuery({ resource });

  if (isLoading) return <Loader />;
  if (!data) return null;

  return <CampaignOverviewMarketing />;
};
export default CampaignOverviewMarketingContainer;
