import { useParams } from 'react-router';

import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import { usePutCampaignProductMixMutation } from '#/store/api/campaign/campaign';

import { getCampaignProductMix } from '../../api/common/productMix';
import ProductMix from '../common/ProductMix/ProductMix';

const CampaignsProductMix = () => {
  const { campaignId } = useParams();
  const [putCampaignProductMix, { isLoading, error }] = usePutCampaignProductMixMutation();

  return (
    <ProductMix
      error={error}
      getSelectedObjects={getCampaignProductMix}
      isLoading={isLoading}
      putSelectedObjects={putCampaignProductMix}
      requiredEditPermission={[USER_PERMISSIONS.CAMPAIGNS_TARGET_PRODUCT_MIX]}
      targetId={campaignId}
    />
  );
};

export default CampaignsProductMix;
