import { makeStyles, createStyles } from '#/utils/theme';

export const useDetailsSectionStyles = makeStyles(() =>
  createStyles({
    section: {
      background: '#fff',
      borderRadius: '1.875rem',
      padding: ' 1.25rem',
      marginBottom: '1.25rem',
    },

    boxGrid: {
      '&&': {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill,minmax(35%, 1fr))',
        gridGap: '0.1em',
      },
    },
  }),
);
