import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import classNames from 'classnames';
import { FC, ReactNode, useState } from 'react';

import { useCollapseStyle } from '#/components/common/CollapseComponent/CollapseComponent.style';
import NewButton from '#/components/common/button/NewButton';

type CollapseComponentProps = {
  actionTitle: ReactNode;
  content: ReactNode;
};

const CollapseComponent: FC<CollapseComponentProps> = ({ actionTitle, content }) => {
  const classes = useCollapseStyle();
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Box className={classes.container}>
      <Accordion className={classNames(classes.resetDefault)} expanded={isExpanded}>
        <AccordionSummary className={classNames(classes.resetDefault, classes.resetHeight)}>
          <Box className={classes.summaryContainer}>
            {actionTitle}
            <NewButton
              className={classes.expandCollapseButton}
              onClick={() => setIsExpanded(!isExpanded)}
              text={isExpanded ? 'Collapse Selection' : 'Expand Selection'}
              variant="Secondary"
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.resetDefault}>{content}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CollapseComponent;
