import { makeStyles, createStyles } from '#/utils/theme';

export const useTraffickingSheetLinkStyle = makeStyles(() =>
  createStyles({
    link: {
      '&&': {
        textDecoration: 'none',
      },
    },
  }),
);
