import { createStyles, makeStyles } from '#/utils/theme';

export const useCompaniesStyles = makeStyles(() =>
  createStyles({
    addBrandModal: {
      '&&': {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
      },
    },
  }),
);
