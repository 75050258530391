import { Box } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { useModalBottomContainerStyles } from '#/components/common/modal/ModalBottomContainer.styles';

const ModalBottomContainer: FC<PropsWithChildren> = ({ children }) => {
  const classes = useModalBottomContainerStyles();
  return <Box className={classes.container}>{children}</Box>;
};

export default ModalBottomContainer;
