import { useParams } from 'react-router';

import { ReportDetails } from '#/components/features/reports/ReportsDetails';

import { useGetReportFilterQuery } from '#/store/api/reports/reports';

import Loader from '../../common/loader/loader';

export const ReportDetailsContainer = () => {
  const { reportId } = useParams();

  const { isLoading: filterDataIsLoading } = useGetReportFilterQuery({ reportId });

  if (filterDataIsLoading) return <Loader />;

  return <ReportDetails />;
};
