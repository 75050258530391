import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { isEqual } from 'lodash';
import { useForm } from 'react-hook-form';

import { useSnackbarError } from '../../../components/common/snackbar/useSnackbarError';
import { useSnackbarSuccess } from '../../../components/common/snackbar/useSnackbarSuccess';
import {
  useGetCampaignOverviewLegalSectionQuery,
  usePutCampaignOverviewLegalSectionMutation,
} from '../../../store/api/campaigns/campaigns';
import { ICampaignOverviewLegalTerms } from '../../../store/api/campaigns/campaignsInterfaces';
import useGetResource from '../../useGetResource';

export const useOverviewEditLegal = (cancelEditMode: () => void) => {
  const resource = useGetResource();
  const [trigger, result] = usePutCampaignOverviewLegalSectionMutation();
  const { data, isFetching, refetch } = useGetCampaignOverviewLegalSectionQuery({
    campaignId: resource.resourceId,
  });
  const { setSuccess } = useSnackbarSuccess();

  const dataDefaultValues = {
    termAndConditionsFileId: data?.termAndConditionsFileId,
    additionalTerms: data?.additionalTerms,
    customTermAndConditionsFileId: data?.customTermAndConditionsFileId,
  };

  const schema = yup
    .object()
    .shape({
      termAndConditionsFileId: yup.number().nullable(),
      additionalTerms: yup.string().nullable(),
      customTermAndConditionsFileId: yup.number().nullable(),
    })
    .required();
  const {
    register,
    setValue,
    reset,
    handleSubmit,
    watch,
    formState: { errors, defaultValues },
  } = useForm<ICampaignOverviewLegalTerms>({
    resolver: yupResolver(schema),
    defaultValues: dataDefaultValues,
  });
  const { setError } = useSnackbarError();

  const onSubmit = handleSubmit(async (formData: ICampaignOverviewLegalTerms) => {
    if (!isEqual(formData, defaultValues)) {
      try {
        await trigger({ resource, body: formData }).unwrap();
        setSuccess('Your action is being processed. Please refresh this section if data is not updated.');
        reset(formData);
        cancelEditMode();
      } catch (error) {
        setError((error as FetchBaseQueryError).data);
      }
    } else {
      cancelEditMode();
    }
  });

  const onRefetch = async () => {
    try {
      await refetch().unwrap();
      reset(dataDefaultValues);
    } catch (error) {
      setError('We are unable to re fetch the data');
    }
  };

  return {
    watch,
    errors,
    register,
    reset,
    setValue,
    onSubmit,
    isLoading: result.isLoading,
    dataLoading: isFetching,
    refetch: onRefetch,
    error: result.error,
  };
};
