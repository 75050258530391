import { useParams } from 'react-router';

import { useGetCampaignOverviewLegalSectionQuery } from '../../store/api/campaigns/campaigns';
import Loader from '../common/loader/loader';
import CampaignOverviewLegal from './CampaignOverviewLegal';

const CampaignOverviewLegalContainer = () => {
  const { campaignId } = useParams();
  const { data, isLoading } = useGetCampaignOverviewLegalSectionQuery({ campaignId });

  if (isLoading) return <Loader />;
  if (!data) return null;

  return <CampaignOverviewLegal />;
};
export default CampaignOverviewLegalContainer;
