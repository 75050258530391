import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { TypographyWithFontFamily } from '../typography/TypographyWithFontFamily';
import { useCommittedBrandsLinkStyles } from './CommittedBrandsLink.styles';

type CommittedBrandsLinkProps = {
  committedBrands: number;
  brandsTargeted: number;
  campaignId: string;
};

const CommittedBrandsLink: FC<CommittedBrandsLinkProps> = ({ committedBrands, brandsTargeted, campaignId }) => {
  const classes = useCommittedBrandsLinkStyles();

  return (
    <TypographyWithFontFamily variant="Text_Body_500">
      <Link
        className={classNames(classes.links, {
          [classes.disabledLinks]: committedBrands === 0 || committedBrands === null,
        })}
        to={`/campaigns/instances/${campaignId}`}
      >
        {committedBrands ? committedBrands : 0} of {brandsTargeted}
      </Link>
    </TypographyWithFontFamily>
  );
};

export default CommittedBrandsLink;
