import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { Outlet } from 'react-router-dom';

import CampaignInstanceHeaderButtons from '#/components/features/campaigns/CampaignHeaderButtons/CampaignInstanceHeaderButtons';

import UserTypeWrapper from '#/utils/UserTypeWrapper';
import { convertAllCapsUnderscoreStrings } from '#/utils/utils';

import CampaignIcon from '../../../../assets/Megaphone.svg';
import { USER_PERMISSIONS } from '../../../../constants/permissions/userPermissions';
import { ROUTE_PATHS } from '../../../../constants/routing/routePaths';
import { useGetInstanceQuery } from '../../../../store/api/campaigns/instances';
import Loader from '../../../common/loader/loader';
import { useSnackbarError } from '../../../common/snackbar/useSnackbarError';
import { SubmenuBar } from '../../../common/submenuBar/SubmenuBar';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import CompanyLogo from '../../../shared/companyLogo/CompanyLogo';
import { CompanyLogoSX, statusSx, useCampaignsSingleHeaderStyles } from '../campaignsSingleHeader.styles';
import { returnCampaignInstancesUrls } from '../utils';

const CampaignInstanceHeader = () => {
  const classes = useCampaignsSingleHeaderStyles();
  const { instanceId } = useParams();
  const { setError } = useSnackbarError();
  const navigate = useNavigate();

  const { data, isLoading, error, isError } = useGetInstanceQuery({ instanceId });

  if (isError) {
    setError(error);
  }
  const { campaignName, instanceStatus, brandLogoId, brandName, campaignId } = data || {};

  const campaignSubmenu = [
    {
      label: '1. Overview',
      url: returnCampaignInstancesUrls(ROUTE_PATHS.CAMPAIGN_INSTANCES_OVERVIEW, instanceId || ''),
      permission: USER_PERMISSIONS.CAMPAIGN_INSTANCE_OVERVIEW,
    },
    {
      label: '2. Channel & Tactics',
      url: returnCampaignInstancesUrls(ROUTE_PATHS.CAMPAIGN_INSTANCES_CHANNEL_TACTICS, instanceId || ''),
      permission: [
        USER_PERMISSIONS.CAMPAIGN_CHANNEL_TACTICS,
        USER_PERMISSIONS.CAMPAIGN_BRAND_CHANNEL_TACTICS,
        USER_PERMISSIONS.CHANNEL_PARTNER_CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS_RIBBON_READ,
      ],
    },
    {
      label: '3. Assets',
      url: returnCampaignInstancesUrls(ROUTE_PATHS.CAMPAIGN_INSTANCES_ASSETS, instanceId || ''),
      permission: [USER_PERMISSIONS.CAMPAIGN_INSTANCE_ASSETS, USER_PERMISSIONS.CAMPAIGN_INSTANCE_BRAND_ASSETS],
    },
    {
      label: 'History',
      url: returnCampaignInstancesUrls(ROUTE_PATHS.CAMPAIGN_INSTANCE_HISTORY, instanceId || ''),
      permission: [USER_PERMISSIONS.CAMPAIGN_INSTANCE_HISTORY_READ],
    },
    {
      hide: instanceStatus === 'BRAND_ADOPTION',
      label: 'Brand Order Details',
      url: returnCampaignInstancesUrls(ROUTE_PATHS.CAMPAIGN_BRAND_ORDER_DETAILS, instanceId || ''),
    },
  ];

  if (isLoading) {
    return <Loader />;
  }

  const linkHeader = (
    <TypographyWithFontFamily
      cName={classes.campaignNameCustomStyle}
      onClick={() => navigate(`/campaigns/instances/${campaignId}`)}
    >
      {campaignName}
    </TypographyWithFontFamily>
  );

  return (
    <>
      <Box className={classes.headerContainer}>
        <Box className={classes.icon}>
          <img alt="CampaignIcon" src={CampaignIcon} />
        </Box>
        <Box className={classes.ml1half}>
          <Box className={classes.flexWithCenterAlignItems}>
            <UserTypeWrapper
              agency={<TypographyWithFontFamily variant="H3_Base">{campaignName}</TypographyWithFontFamily>}
              brand={<TypographyWithFontFamily variant="H3_Base">{campaignName}</TypographyWithFontFamily>}
              channel-partner={linkHeader}
              retailer={linkHeader}
            />

            <Box className={classes.brandSection}>
              <CompanyLogo
                companyName={brandName}
                customStyles={CompanyLogoSX}
                imageId={brandLogoId}
                size="small"
                viewOnly
              />

              <TypographyWithFontFamily cName={classes.campaignCustomStyle}>{brandName}</TypographyWithFontFamily>
            </Box>
            <Box sx={statusSx(instanceStatus)}>{convertAllCapsUnderscoreStrings(instanceStatus)}</Box>
            <TypographyWithFontFamily cName={classes.ml1half} variant="Tag_Base">
              ID: {instanceId}
            </TypographyWithFontFamily>
          </Box>
        </Box>
        <UserTypeWrapper
          retailer={<CampaignInstanceHeaderButtons actions={data?.actions || []} name={data?.campaignName || ''} />}
        />
      </Box>
      <SubmenuBar tabs={campaignSubmenu} />
      <Outlet />
    </>
  );
};

export default CampaignInstanceHeader;
