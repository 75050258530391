import { createStyles, makeStyles } from '#/utils/theme';

export const useTabModeToggleStyles = makeStyles((theme) =>
  createStyles({
    container: {
      borderBottom: 'none',
      width: theme.custom.REM_SPACES['6'],
    },
    tab: {
      borderRadius: '10px',
      color: theme.custom.PALETTES.OTHER.White,
      maxWidth: theme.custom.REM_SPACES['2.5'],
      minWidth: theme.custom.REM_SPACES['2.5'],
    },
    tabActive: {
      background: theme.custom.PALETTES.UI.Primary.Base,
    },
  }),
);
