import { createStyles, makeStyles } from '#/utils/theme';

export const useCampaignThumbnailStyles = makeStyles((theme) =>
  createStyles({
    thumbnailSection: {
      height: theme.custom.REM_SPACES[5],
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
    },
    image: { height: theme.custom.REM_SPACES[5], position: 'absolute' },
  }),
);

export const styles = {
  thumbnailEmptyState: {
    margin: 'auto',
  },
};
