import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const useCheckboxStyle = makeStyles(() =>
  createStyles({
    readOnlyContainer: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: THEME.REM_SPACES[0.5],
      paddingBottom: THEME.REM_SPACES[0.5],
    },
    readOnlyLabel: {
      '&&': {
        alignSelf: 'center',
        paddingLeft: THEME.REM_SPACES[0.5],
      },
    },
  }),
);
