import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const useReportsAdminPageStyle = makeStyles(() =>
  createStyles({
    container: {
      background: THEME.PALETTES.OTHER.White,
      padding: THEME.REM_SPACES[1],
      borderRadius: THEME.REM_SPACES[1],
    },
    content: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    tableHeadCell: {
      '&&': {
        height: THEME.REM_SPACES[2.5],
        fontWeight: 600,
        border: 'none',
        borderBottom: `1px solid ${THEME.PALETTES.UI.Neutral[900]}`,
        background: THEME.PALETTES.OTHER.White,
      },
    },
    tableContainer: {
      '&& > table': {
        border: 'none',
      },
    },
    tableBodyCell: {
      height: THEME.REM_SPACES[2.5],
      borderLeft: 'none',
      borderRight: 'none',
    },
    addButton: {
      '&&': {
        marginLeft: THEME.REM_SPACES[1],
      },
    },
  }),
);

export const useReportsAdminFormStyle = makeStyles(() =>
  createStyles({
    buttonsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      columnGap: '0.625rem',
      alignSelf: 'flex-end',
      padding: THEME.REM_SPACES[1],
    },
    customField: {
      height: THEME.REM_SPACES[6],
    },
  }),
);

export const headerWithSpacingSx = {
  margin: `${THEME.REM_SPACES[1]} 0`,
};

export const customInputStyle = { height: 'auto', padding: THEME.REM_SPACES[0.5] };
