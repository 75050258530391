import { Box } from '@mui/system';
import classnames from 'classnames';
import { FC } from 'react';

import ReloadIcon from '../../../assets/Refresh.svg';
import { styles, useRefetchStyle } from './Refetch.styles';

interface IRefetchProps {
  reFetchFn: () => void;
  isLoading: boolean;
  customStyle?: string;
}

const Refetch: FC<IRefetchProps> = ({ reFetchFn, isLoading, customStyle }) => {
  const classes = useRefetchStyle();

  return (
    <Box
      className={classnames(classes.refetchIconContainer, customStyle)}
      onClick={reFetchFn}
      sx={isLoading ? styles.refetchIsLoading : {}}
    >
      <img alt="refresh icon" className={classes.refetchIcon} src={ReloadIcon} />
    </Box>
  );
};
export default Refetch;
