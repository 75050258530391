import { SearchFilters } from '#/store/api/campaigns/campaignsInterfaces';

import { ICampaignInstanceProductsList } from '../../../interfaces/campaigns/campaigns';
import { ICampaignInstanceChannelsAndTactics } from '../../../interfaces/campaigns/campaignsInstances';
import { baseApi } from '../../base';

export const productFeedDefaultValues = {
  pageNumber: 1,
  numberOfRows: 10,
  queryInput: '',
};

const productsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCampaignInstanceTactics: builder.query<ICampaignInstanceChannelsAndTactics, { instanceId: string | undefined }>({
      query: ({ instanceId }) => `retailer/instances/brand/${instanceId}/channels-and-tactics`,
      providesTags: ['CHECKBOX_ACTION'],
    }),

    getCampaignInstanceTacticProductsAll: builder.query<
      ICampaignInstanceProductsList,
      {
        instanceTacticId: string;
        pageNumber?: number;
        numberOfRows?: number;
        queryInput?: string;
      }
    >({
      query: ({
        instanceTacticId,
        pageNumber = productFeedDefaultValues.pageNumber,
        numberOfRows = productFeedDefaultValues.numberOfRows,
        queryInput = '',
      }) => {
        const searchParams = new URLSearchParams();

        searchParams.set('pageNumber', pageNumber.toString());
        searchParams.set('numberOfRows', numberOfRows.toString());
        searchParams.set('queryInput', queryInput);
        return `retailer/instance-tactic/${instanceTacticId}/product-feed/all?${searchParams}`;
      },
      providesTags: ['INSTANCE_TACTICS_PRODUCT_FEED'],
    }),
    getCampaignInstanceTacticProductsSelected: builder.query<
      ICampaignInstanceProductsList,
      {
        instanceTacticId: string;
        pageNumber?: number;
        numberOfRows?: number;
        queryInput?: string;
      }
    >({
      query: ({
        instanceTacticId,
        pageNumber = productFeedDefaultValues.pageNumber,
        numberOfRows = productFeedDefaultValues.numberOfRows,
        queryInput = '',
      }) => {
        const searchParams = new URLSearchParams();

        searchParams.set('pageNumber', pageNumber.toString());
        searchParams.set('numberOfRows', numberOfRows.toString());
        searchParams.set('queryInput', queryInput);
        return `retailer/instance-tactic/${instanceTacticId}/product-feed/selected?${searchParams}`;
      },
      providesTags: ['INSTANCE_TACTICS_PRODUCT_FEED'],
    }),

    putSelectCampaignInstanceTacticProduct: builder.mutation<
      void,
      {
        instanceTacticId: string;
        selectedProducts: { productFeedIds: string[] };
      }
    >({
      query: ({ instanceTacticId, selectedProducts }) => {
        return {
          url: `retailer/instance-tactic/${instanceTacticId}/product-feed/select`,
          method: 'PUT',
          body: selectedProducts,
        };
      },
      invalidatesTags: (result, error) => (error ? [] : ['INSTANCE_TACTICS_PRODUCT_FEED']),
    }),

    deleteSelectCampaignInstanceTacticProduct: builder.mutation<
      void,
      {
        instanceTacticId: string;
        selectedProducts: { productFeedIds: string[] };
      }
    >({
      query: ({ instanceTacticId, selectedProducts }) => {
        return {
          url: `retailer/instance-tactic/${instanceTacticId}/product-feed/unselect`,
          method: 'DELETE',
          body: selectedProducts,
        };
      },
      invalidatesTags: (result, error) => (error ? [] : ['INSTANCE_TACTICS_PRODUCT_FEED']),
    }),
    getProductFeedFilters: builder.query<{ filters: SearchFilters[] }, unknown>({
      query: () => `retailer/product-feed/filters`,
    }),
  }),
});

export const {
  usePutSelectCampaignInstanceTacticProductMutation,
  useDeleteSelectCampaignInstanceTacticProductMutation,
  useGetCampaignInstanceTacticsQuery,
  useGetProductFeedFiltersQuery,
  useGetCampaignInstanceTacticProductsAllQuery,
  useGetCampaignInstanceTacticProductsSelectedQuery,
} = productsApi;

export { productsApi };
