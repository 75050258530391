import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { useForm } from 'react-hook-form';

import { useSnackbarError } from '../../../components/common/snackbar/useSnackbarError';
import { useSnackbarSuccess } from '../../../components/common/snackbar/useSnackbarSuccess';
import { IEditGeneralOverview } from '../../../interfaces/forms/campaigns';
import {
  useGetCampaignOverviewGeneralQuery,
  usePutCampaignOverviewGeneralMutation,
} from '../../../store/api/campaigns/campaigns';
import useGetResource, { RESOURCE_TYPES } from '../../useGetResource';

export const useOverviewEditGeneral = (cancelEditMode: () => void) => {
  const resource = useGetResource();
  const [trigger, result] = usePutCampaignOverviewGeneralMutation();
  const { data, isFetching, refetch } = useGetCampaignOverviewGeneralQuery({ resource });
  const { setSuccess } = useSnackbarSuccess();
  const dataDefaultValues = {
    campaignName: data?.name,
    startDate: dayjs(data?.startDate).format('YYYY-MM-DD'),
    endDate: dayjs(data?.endDate).format('YYYY-MM-DD'),
    submissionDeadline: dayjs(data?.submissionDeadline).format('YYYY-MM-DD'),
  };
  const schema = yup
    .object()
    .shape({
      campaignName: yup.string().required(),
      startDate: yup.string().required(),
      endDate: yup.string().required(),
      submissionDeadline: yup.string().required(),
    })
    .required();
  const {
    register,
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, defaultValues },
  } = useForm<IEditGeneralOverview>({
    resolver: yupResolver(schema),
    defaultValues: dataDefaultValues,
  });

  const { setError } = useSnackbarError();

  const onSubmit = handleSubmit(async (formData: IEditGeneralOverview) => {
    if (resource.resourceType === RESOURCE_TYPES.campaign) {
      if (!isEqual(formData, defaultValues)) {
        try {
          await trigger({ resource, body: formData }).unwrap();
          setSuccess('Your action is being processed. Please refresh this section if data is not updated.');
          reset(formData);
          cancelEditMode();
        } catch (error) {
          setError((error as FetchBaseQueryError).data);
        }
      } else {
        cancelEditMode();
      }
    }
  });

  const onRefetch = async () => {
    try {
      await refetch().unwrap();
      reset(dataDefaultValues);
    } catch (error) {
      setError('We are unable to re fetch the data');
    }
  };
  return {
    onSubmit,
    control,
    reset,
    errors,
    register,
    setValue,
    isLoading: result.isLoading,
    dataLoading: isFetching,
    refetch: onRefetch,
    error: result.error,
  };
};
