import { useParams } from 'react-router';

import SearchFilters from '#/components/common/SearchFilters/SearchFilters';
import useSearchFilters from '#/components/common/SearchFilters/useSearchFilters.hook';
import { CampaignListModes } from '#/components/common/TabModeToggle';
import Loader from '#/components/common/loader/loader';
import getCampaignInstancesChannelPartnerTableColumns from '#/components/features/campaigns/instances/list/CampaignInstancesChannelPartner/CampaignInstancesChannelPartner.columns';

import {
  useGetInstancesChannelPartnerFilterQuery,
  useGetInstancesChannelPartnerQuery,
} from '#/store/api/campaigns/instances';

import { Table } from '../../../../../common/table/tableComponent/Table';

export const CampaignInstancesChannelPartner = () => {
  const { campaignId } = useParams();

  const { data: filterData, isError: filterDataIsError } = useGetInstancesChannelPartnerFilterQuery({ campaignId });

  const { actions, state } = useSearchFilters(
    {
      viewMode: CampaignListModes.LIST,
      filters: [],
      alwaysActiveFilters: [],
    },
    true,
    filterData?.filters,
  );

  const {
    data: instancesData,
    isError: instancesIsError,
    isFetching: instancesIsFetching,
    isLoading: instancesIsLoading,
  } = useGetInstancesChannelPartnerQuery({
    campaignId,
    pageNumber: state.pageNumber,
    numberOfRows: state.numberOfElementsPerPage,
    searchQuery: state.searchQuery,
  });

  if (instancesIsLoading) return <Loader />;

  const columns = getCampaignInstancesChannelPartnerTableColumns(filterData ? filterData.filters : []);

  return (
    <>
      <SearchFilters
        columnNames={filterData?.filters}
        currentIndex={instancesData?.currentIndex}
        filterDataIsError={filterDataIsError}
        hideViewMode
        isError={instancesIsError}
        isFetching={instancesIsFetching}
        isNoResults={!instancesData || instancesData.instances.length === 0}
        totalPages={instancesData?.totalPages}
        totalResults={instancesData?.totalResults}
        {...state}
        {...actions}
      >
        <Table
          columns={columns}
          data={instancesData?.instances || []}
          displayStatusColor
          sortProps={{
            sortParams: state.sortParams,
            setSortParams: actions.setSortParam,
          }}
        />
      </SearchFilters>
    </>
  );
};
