import { Box, Checkbox, Grid } from '@mui/material';
import { Control, Controller, UseFormWatch } from 'react-hook-form';

import { THEME } from '../../../../constants/theme/constants';
import { ITacticMetadataDTO, TacticMetadataForm } from '../../../../interfaces/tacticMetadata/tacticMetadata';
import { InfoTooltip } from '../../../features/reports/InfoTooltip';
import { TypographyWithFontFamily } from '../../typography/TypographyWithFontFamily';
import { useTacticMetadataFormStyle } from '../TacticMetadataForm.style';

interface IProps {
  input: ITacticMetadataDTO;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<TacticMetadataForm, any>;
  watch: UseFormWatch<TacticMetadataForm>;
  helperTextSubtitle?: boolean;
  editMode?: boolean;
}

export const CheckBoxSingleOptionInput = ({
  control,
  input,
  watch,
  helperTextSubtitle = false,
  editMode = true,
}: IProps) => {
  const isChecked = Boolean(watch(String(input.id)));
  const classes = useTacticMetadataFormStyle();

  return (
    <Grid container spacing={{ xs: '1rem' }} xs={12}>
      <Grid item xs={9}>
        <Controller
          control={control}
          name={String(input.id)}
          render={({ field }) => (
            <Box>
              <TypographyWithFontFamily
                cName={classes.labelUppercase}
                color={THEME.PALETTES.TEXT.Gray}
                variant="Label_Base"
              >
                {input.label} {input.required ? '*' : ''}
              </TypographyWithFontFamily>
              {helperTextSubtitle && (
                <TypographyWithFontFamily gutterBottom variant={'Label_Base'}>
                  {input.helperText}
                </TypographyWithFontFamily>
              )}
              <Checkbox {...field} checked={isChecked} disabled={input.isDisabled || !editMode} />
            </Box>
          )}
        />
      </Grid>
      <Grid item xs={3}>
        {!helperTextSubtitle && input.helperText && <InfoTooltip text={input.helperText} />}
      </Grid>
    </Grid>
  );
};
