import { THEME } from '#/constants/theme/constants';

import { makeStyles, createStyles } from '#/utils/theme';

// page container
export const useCommunicationsChartStyle = makeStyles((theme) =>
  createStyles({
    pageContainer: {
      display: 'flex',
      flexDirection: 'column',
      background: theme.custom.PALETTES.OTHER.White,
      borderRadius: theme.custom.REM_SPACES[2],
      padding: theme.custom.REM_SPACES[1],
    },
    chartWrapperDiv: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '20rem',
    },
    tableWrapper: {
      margin: `${theme.custom.REM_SPACES[2]} 0 ${theme.custom.REM_SPACES[1]} 0`,
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: `0 ${theme.custom.REM_SPACES[1]}`,
      marginBottom: theme.custom.REM_SPACES[1],
    },
    datePickerContainer: {
      display: 'flex',
      align: 'center',
    },
    datePickerAndErrorContainer: {
      display: 'flex',
      align: 'center',
      flexDirection: 'column',
    },
    date: {
      fontSize: '0.7rem',
    },
    datePicker: {
      marginLeft: theme.custom.REM_SPACES[1],
    },
    hide: {
      visibility: 'hidden',
    },
  }),
);

export const useCustomTooltipStyle: any = makeStyles((theme) =>
  createStyles({
    wrapperDiv: {
      display: 'flex',
      flexDirection: 'column',
      background: theme.custom.PALETTES.OTHER.White,
      borderRadius: theme.custom.REM_SPACES[2],
      padding: theme.custom.REM_SPACES[1],
    },
    tooltipsContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: theme.custom.REM_SPACES[10],
    },
    tooltipRow: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.custom.REM_SPACES[0.5],
      justifyContent: 'space-between',
    },
    tooltipColorIcon: {
      height: theme.custom.REM_SPACES[1],
      width: theme.custom.REM_SPACES[1],
      borderRadius: '5%',
    },
    legendText: {
      margin: `0 0 0 0.25rem`,
      fontWeight: theme.custom.FONT_WEIGHTS.REGULAR,
    },
    tooltipLabel: {
      fontWeight: theme.custom.FONT_WEIGHTS.BOLD,
      margin: `0 0 ${theme.custom.REM_SPACES[0.5]} 0`,
    },
    tooltipRowLabel: {
      display: 'flex',
      alignItems: 'center',
    },
    tooltipNumber: {
      margin: 0,
      fontWeight: theme.custom.FONT_WEIGHTS.BOLD,
    },
  }),
);

export const errorMessageSx = {
  marginLeft: THEME.REM_SPACES[1],
};

export const chartMargin = {
  top: -5,
  right: 30,
  left: 20,
  bottom: 5,
};
