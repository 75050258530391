import theme, { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const useGeneralSettingStyle = makeStyles(() =>
  createStyles({
    dateContainer: {
      background: '#FFF',
      borderRadius: '1rem',
      padding: '0.5rem 1rem 1rem 1rem',
    },
    dateContent: {
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: '1rem',
      marginTop: '2rem',
    },
    dataFeedContainer: {
      marginTop: '1rem',
      background: '#FFF',
      borderRadius: '1rem',
      padding: '0.5rem 1rem 1rem 1rem',
      minHeight: '42.5rem',
    },
    dataFeedHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    dataContent: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: THEME.REM_SPACES['0.5'],
      marginTop: '2rem',
    },
    uploadContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      background: THEME.PALETTES.UI.Secondary[100],
      border: `1px dashed  ${THEME.PALETTES.UI.Secondary.Base}`,
      padding: `${THEME.REM_SPACES[1]} ${THEME.REM_SPACES[0.5]}`,
      rowGap: THEME.REM_SPACES[0.5],
      borderRadius: THEME.REM_SPACES[0.5],
      width: '20rem',
      marginBottom: THEME.REM_SPACES[1],
      marginTop: THEME.REM_SPACES[0.5],
      cursor: 'pointer',
    },
    datePicker: {
      '& .MuiInputBase-root': {
        height: '1.875rem',
        overflow: 'hidden',
        borderRadius: '0.5rem',
        color: '#16191F',
      },
    },
    dayOfWeek: {
      width: '2.5rem',
      borderRadius: '0.5rem',
      height: '2.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    selectedDayOfWeek: {
      background: '#D8EDFA',
    },
    hiddenInput: {
      display: 'none',
    },
    goalInputContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '0.7rem',
    },
    hidden: {
      display: 'none',
    },
    loader: {
      marginLeft: THEME.REM_SPACES[0.5],
    },
    sectionCard: {
      marginTop: theme.custom.REM_SPACES[1],
    },
    fileContainer: {
      width: '25rem',
    },
  }),
);

export const dragAndDropTextStyle = {
  color: '#16191F',
  fontWeight: '400',
  fontSize: '1.0625rem',
};
export const browseFileTextStyle = {
  color: '#1F8AC9',
  fontWeight: '700',
  fontSize: '0.9375rem',
  cursor: 'pointer',
};
export const upperCaseHeader = {
  textTransform: 'uppercase',
};
