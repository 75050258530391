import { makeStyles, createStyles } from '#/utils/theme';

export const pageLayoutStyles = makeStyles(() =>
  createStyles({
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      background: '#fff',
      borderRadius: '1.8rem',
      padding: ' 1.3rem',
    },
  }),
);
