import { Box } from '@mui/system';
import { FC } from 'react';

import { THEME } from '../../../constants/theme/constants';
import { TypographyWithFontFamily } from '../typography/TypographyWithFontFamily';
import { useLabelWithValueStyles } from './LabelWithValue.styles';

type LabelWithValueProps = {
  label: string;
} & ({ value: undefined; placeholder: string } | { value: string; placeholder?: string });

const LabelWithValue: FC<LabelWithValueProps> = ({ label, value, placeholder }) => {
  const classes = useLabelWithValueStyles();

  return (
    <Box className={classes.container}>
      <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} variant="Label_Base">
        {label}
      </TypographyWithFontFamily>
      {typeof value === 'string' ? (
        <TypographyWithFontFamily variant="Text_Body_500">{value}</TypographyWithFontFamily>
      ) : (
        <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Medium} variant="Text_Body_500">
          {placeholder}
        </TypographyWithFontFamily>
      )}
    </Box>
  );
};

export default LabelWithValue;
