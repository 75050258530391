import { Box } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router';

import { useSearchErrorStyles } from '#/components/common/SearchError/SearchError.Styles';
import { FiltersActions } from '#/components/common/SearchFilters/SearchFilters.types';
import NewButton from '#/components/common/button/NewButton';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';

type SearchErrorProps = {
  clearFilters: FiltersActions['clearFilters'];
};
const SearchError: FC<SearchErrorProps> = ({ clearFilters }) => {
  const classes = useSearchErrorStyles();
  const navigate = useNavigate();
  const refreshPage = () => navigate(0);
  return (
    <Box className={classes.container}>
      <Box className={classes.messageContainer}>
        <TypographyWithFontFamily classNames={classes.message} variant="H2_Base">
          We encountered and error. Please try clearing your filters or if the problem persists refresh the page.
        </TypographyWithFontFamily>
      </Box>
      <NewButton className={classes.button} onClick={clearFilters} text="Clear Filters" variant="Primary" />
      <NewButton className={classes.button} onClick={refreshPage} text="Refresh Page" variant="Secondary" />
      <img alt="default logo" className={classes.image} src={`/custom/DefaultLogo.png`} />
    </Box>
  );
};

export default SearchError;
