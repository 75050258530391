import dayjs from 'dayjs';

import { THEME } from '#/constants/theme/constants';
import { UsersType } from '#/constants/usersRoles/usersRole';

const { SUPER_ADMIN, ADMIN, RETAILER, BRAND, CHANNEL_PARTNER, AGENCY } = UsersType;

export const getColorByStatus = (status: string | undefined) => {
  const color = status ? THEME.PALETTES.STATUS[status] : undefined;
  if (!color) return `${THEME.PALETTES.STATUS.PENDING}`;
  return `${color}`;
};

export const formatDateAndTime = (text: string | number) => dayjs(text).format('MM-DD-YYYY HH:mm:ss').toString();

export function formatDate(date: Date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1),
    day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export const formatColumnToHaveComma = (val: string, index: number, array: unknown[]) => {
  return index < array.length - 1 ? `${val}, ` : `${val}`;
};

export const formatUserType = (userType: string) => {
  switch (userType) {
    case SUPER_ADMIN:
      return 'Super Admin';
    case RETAILER:
      return 'Retailer';
    case BRAND:
      return 'Brand';
    case ADMIN:
      return 'Admin';
    case CHANNEL_PARTNER:
      return 'Channel Partner';
    case AGENCY:
      return 'Agency';
    default:
      return userType;
  }
};

export const convertAllCapsUnderscoreStrings = (el: string | number = '') => {
  const status = String(el);
  if (el === null) return '';
  return status
    .split('_')
    .map((word) => word.slice(0, 1) + word.slice(1).toLowerCase())
    .join(' ');
};

export const createPartnersCompanyLink = (companyType: string, companyId: string) => {
  if (companyType === 'AGENCY') {
    return `/partners/agencies/${companyId}`;
  } else if (companyType === 'BRAND') {
    return `/partners/brands/${companyId}`;
  } else {
    return `/partners/channel-partner/${companyId}`;
  }
};

export const updateCommunicationStateSelected = (array: string[], value: string) => {
  if (array.includes(value)) {
    return array.filter((item) => item !== value);
  }
  return [...array, value];
};

export const checkArrayExistsAndContainsNonEmptyElements = (array: string[] | undefined) => {
  return array?.join('').split('')?.length;
};
