import { Box } from '@mui/material';
import { flow } from 'fp-ts/lib/function';
import { useState } from 'react';

import TextField from '#/components/common/inputs/TextField/TextField';
import Loader from '#/components/common/loader/loader';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import { useOverviewEditInternal } from '#/hooks/forms/campaigns/useOverviewEditInternal';
import { useDataCy } from '#/hooks/useDataCy';

import { createFormatterWithReverse, currencyFormatter, extractNumber, numberFormatter } from '#/utils/textFormatters';

import { useCampaignOverviewInternalStyles } from './CampaignOverviewInternal.styles';
import { TopSection } from './topSection/TopSection';

const CampaignOverviewInternal = () => {
  const generateDataCy = useDataCy();

  const [editMode, setEditMode] = useState(false);
  const classes = useCampaignOverviewInternalStyles();
  const { onSubmit, errors, register, isLoading, dataLoading, reset, refetch, setValue, error } =
    useOverviewEditInternal(() => setEditMode(false));

  return (
    <>
      <form onSubmit={onSubmit}>
        <Box className={classes.form} data-cy={generateDataCy('box', 'CampaignOverviewInternal')}>
          <TopSection
            dataLoading={dataLoading}
            editMode={editMode}
            error={error}
            isLoading={isLoading}
            onSubmit={onSubmit}
            refetch={refetch}
            requiredPermission={USER_PERMISSIONS.CAMPAIGN_OVERVIEW_INTERNAL_WRITE}
            reset={reset}
            sectionName="Internal"
            setEditMode={setEditMode}
          />
          {dataLoading ? (
            <Loader />
          ) : (
            <>
              <Box className={classes.row}>
                <TextField
                  disabled={!editMode}
                  error={errors.goal?.message?.toString()}
                  formHooks={{
                    register: register('goal'),
                    setValue: (value) => setValue('goal', value),
                  }}
                  formatters={{
                    preview: currencyFormatter,
                    typing: createFormatterWithReverse(flow(extractNumber, currencyFormatter), extractNumber),
                  }}
                  label="Campaign Goal"
                />
                <TextField
                  disabled={!editMode}
                  error={errors.brandsTargeted?.message?.toString()}
                  formHooks={{
                    register: register('brandsTargeted'),
                    setValue: (value) => setValue('brandsTargeted', value),
                  }}
                  formatters={{
                    preview: numberFormatter,
                    typing: createFormatterWithReverse(flow(extractNumber, numberFormatter), extractNumber),
                  }}
                  label="Target Number of Brands"
                />
              </Box>
              <TextField
                disabled={!editMode}
                error={errors.description?.message?.toString()}
                formHooks={{
                  register: register('description'),
                  setValue: (value) => setValue('description', value),
                }}
                label="Description"
                labelPosition="Top"
                multiline
                rows={5}
              />
            </>
          )}
        </Box>
      </form>
    </>
  );
};
export default CampaignOverviewInternal;
