import Loader from '#/components/common/loader/loader';
import { CampaignsRetailer } from '#/components/features/campaigns/Campaigns/CampaignsRetailer';

import { useGetCampaignsRetailerFilterQuery } from '#/store/api/campaigns/campaigns';

export const CampaignsRetailerContainer = () => {
  const { isLoading: filterDataIsLoading } = useGetCampaignsRetailerFilterQuery({});

  if (filterDataIsLoading) return <Loader />;

  return <CampaignsRetailer />;
};
