import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';

import UserTypeWrapper from '#/utils/UserTypeWrapper';

import {
  ICampaignBrand,
  ICampaignChannelPartner,
  ICampaignRetailer,
} from '../../../store/api/campaigns/campaignsInterfaces';
import EventModalBrand from '../../common/CampaignDetail/CampaignDetailBrand';
import EventModalChannelPartner from '../../common/CampaignDetail/CampaignDetailChannelPartner';
import EventModalRetailer from '../../common/CampaignDetail/CampaignDetailRetailer';
import { useCampaignCardViewStyles } from './CampaignCardView.styles';

interface CampaignCardViewProps {
  data: ICampaignBrand[] | ICampaignChannelPartner[] | ICampaignRetailer[];
}

const CampaignCardView: FC<CampaignCardViewProps> = ({ data }) => {
  const classes = useCampaignCardViewStyles();
  return (
    <Grid
      data-testId="campaigns-container"
      item
      lg={4}
      mb={2}
      md={6}
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, 350px);',
        gridGap: '1rem',
      }}
      xs={6}
    >
      {data.map((item: ICampaignBrand | ICampaignChannelPartner | ICampaignRetailer) => (
        <Box key={item.campaignId} className={classes.container}>
          <UserTypeWrapper
            agency={<EventModalBrand data={item as ICampaignBrand} />}
            brand={<EventModalBrand data={item as ICampaignBrand} />}
            channel-partner={<EventModalChannelPartner data={item as ICampaignChannelPartner} />}
            retailer={<EventModalRetailer data={item as ICampaignRetailer} />}
          />
        </Box>
      ))}
    </Grid>
  );
};
export default CampaignCardView;
