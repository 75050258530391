import { FC } from 'react';

import AlertError from '#/components/common/Alert/AlertError/AlertError';

import CopyIcon from '../../../assets/CopyTierEnabledIcon.svg';
import DeleteButtonIcon from '../../../assets/DeleteButtonIcon.svg';
import { USER_PERMISSIONS } from '../../../constants/permissions/userPermissions';
import { getUserHasPermission } from '../../../constants/permissions/utils';
import useGetResource from '../../../hooks/useGetResource';
import {
  useDeleteCampaignTierMutation,
  usePostCopyCampaignTierMutation,
} from '../../../store/api/campaigns/campaignChannelsAndTactics';
import NewButton from '../../common/button/NewButton';
import { useSnackbarError } from '../../common/snackbar/useSnackbarError';
import { useCampaignTierStyle } from './CampaignTier.style';

interface ICopyTierProps {
  tierCopy: string | undefined;
  isOnInstance?: boolean;
}

const CopyTier: FC<ICopyTierProps> = ({ tierCopy, isOnInstance }) => {
  const { setError } = useSnackbarError();
  const [triggerCopy, { isLoading: isLoadingCopy, error: triggerCopyError }] = usePostCopyCampaignTierMutation();
  const [triggerDelete, { isLoading: isLoadingDelete, error: triggerDeleteError }] = useDeleteCampaignTierMutation();

  const error = triggerCopyError || triggerDeleteError;

  const resource = useGetResource();
  const onCopyTier = async () => {
    try {
      await triggerCopy({ resource, body: { tierId: tierCopy } }).unwrap();
    } catch (error) {
      setError(error);
    }
  };
  const onDelete = async () => {
    try {
      await triggerDelete({ resource, tierId: tierCopy });
      // fetchTiers?.();
    } catch (error) {
      setError(error);
    }
  };
  const userHasDeleteTierShellPermission = getUserHasPermission(USER_PERMISSIONS.CAMPAIGN_CHANNEL_TACTICS_DELETE);
  const userHasDeleteTierInstancePermission = getUserHasPermission(
    USER_PERMISSIONS.CAMPAIGN_INSTANCE_CHANNEL_TACTICS_DELETE,
  );
  const classes = useCampaignTierStyle();

  return (
    <div className={classes.copyContainer}>
      <AlertError error={error} />
      {((userHasDeleteTierShellPermission && !isOnInstance) ||
        (userHasDeleteTierInstancePermission && isOnInstance)) && (
        <NewButton
          icon={DeleteButtonIcon}
          iconPosition="left"
          loading={isLoadingDelete}
          onClick={onDelete}
          text="Delete Tier"
          variant="Secondary"
        />
      )}
      <NewButton
        icon={CopyIcon}
        iconPosition="left"
        loading={isLoadingCopy}
        onClick={onCopyTier}
        text="Copy Tier"
        variant="Secondary"
      />
    </div>
  );
};
export default CopyTier;
