import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const useOwnerSectionStyles = makeStyles(() =>
  createStyles({
    container: { display: 'flex', flexDirection: 'column' },
    nameContainer: {
      marginLeft: THEME.REM_SPACES['1'],
    },
    textAlignCenter: {
      '&&': {
        fontSize: '0.75rem',
        alignItems: 'center',
        display: 'flex',
      },
    },
  }),
);
