import { Stack, Step, StepLabel, Stepper } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';

import { communicationsBreadcrumbList } from '#/constants/general';

import StepCurrentIcon from '../../../../assets/StepCurrentIcon.svg';
import StepSuccessIcon from '../../../../assets/StepSuccessIcon.svg';
import StepUncompletedIcon from '../../../../assets/StepUncompletedIcon.svg';
import { useCampaignInvitationsStyles } from '../../../campaignInvitations/CampaignInvitations.styles';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';

interface IActiveTab {
  activeTab: number;
}

const CommunicationsBreadcrumb: FC<IActiveTab> = ({ activeTab }) => {
  const classes = useCampaignInvitationsStyles();

  return (
    <Box className={classes.breadcrumbWrapper}>
      <Stack className={classes.stepperContainer}>
        <Stepper activeStep={activeTab} className={classes.stepper}>
          {communicationsBreadcrumbList.map((step: string, index: number) => (
            <Step key={step}>
              {index < activeTab && (
                <StepLabel icon={<img alt="Previous Step" src={StepSuccessIcon} />}>
                  <TypographyWithFontFamily>{step}</TypographyWithFontFamily>
                </StepLabel>
              )}
              {index === activeTab && (
                <StepLabel icon={<img alt="Current Step" src={StepCurrentIcon} />}>{step}</StepLabel>
              )}
              {index > activeTab && (
                <StepLabel icon={<img alt="Next Step" src={StepUncompletedIcon} />}>{step}</StepLabel>
              )}
            </Step>
          ))}
        </Stepper>
      </Stack>
    </Box>
  );
};

export default CommunicationsBreadcrumb;
