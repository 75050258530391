import useGetResource from '../../hooks/useGetResource';
import { useGetCampaignOverviewInternalSectionQuery } from '../../store/api/campaigns/campaigns';
import Loader from '../common/loader/loader';
import CampaignOverviewInternal from './CampaignOverviewInternal';

const CampaignOverviewInternalContainer = () => {
  const resource = useGetResource();
  const { data, isLoading } = useGetCampaignOverviewInternalSectionQuery({ resource });

  if (isLoading) return <Loader />;
  if (!data) return null;

  return <CampaignOverviewInternal />;
};
export default CampaignOverviewInternalContainer;
