import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const useCampaignTierStyle = makeStyles(() =>
  createStyles({
    tierContainer: {
      border: `1px solid  ${THEME.PALETTES.BORDER.Extra_Light}`,
      padding: THEME.REM_SPACES[1],
      borderRadius: THEME.REM_SPACES[0.5],
    },
    form: {
      display: 'flex',
      columnGap: THEME.REM_SPACES[1],
    },
    container: {
      padding: '1rem',
    },
    bottomButtons: {
      maxWidth: '9.3rem',
      minWidth: '9.3rem',
      alignItems: 'end',
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'end',
    },
    cancelContainer: {
      width: '9.3rem',
      display: 'flex',
      flexDirection: 'row',
    },
    tacticsTableContainer: {
      marginTop: THEME.REM_SPACES[1],
    },
    copyButtonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: THEME.REM_SPACES[1],
    },
    copyContainer: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: THEME.REM_SPACES[1],
    },
  }),
);
