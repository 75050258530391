import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { THEME } from '#/constants/theme/constants';

import { PostCampaignForm } from '#/store/api/campaigns/campaignsInterfaces';

import CalendarIcon from '../../../../assets/CalendarIcon.svg';
import CalendarWhiteIcon from '../../../../assets/CalendarWhiteIcon.svg';
import NewButton from '../../button/NewButton';
import DatePicker from '../../inputs/DatePicker/DatePicker';
import { useCalendarDatePickerInputComboStyles } from './CalendarDatePickerInputCombo.styles';

interface CalendarDatePickerInputComboProps {
  //FIXME: fix any
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<PostCampaignForm, any>;
  //FIXME: fix any
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (date: any) => void;
  selectingDate: 'startDate' | 'endDate' | 'submissionDeadline' | '';
  label: string;
  name: 'name' | 'goal' | 'brandsTargeted' | 'startDate' | 'endDate' | 'submissionDeadline';
  //FIXME: fix any
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelection: (date: any) => void;
  legendColor?: string;
  isPickerVisible?: boolean;
}

const CalendarDatePickerInputCombo: FC<CalendarDatePickerInputComboProps> = ({
  control,
  onChange,
  selectingDate,
  label,
  name,
  setSelection,
  legendColor = THEME.PALETTES.UI.Primary.Base,
  isPickerVisible = true,
}) => {
  const classes = useCalendarDatePickerInputComboStyles();
  const isSelecting = selectingDate === name;
  const variant = isSelecting ? 'Primary' : 'Secondary';
  return (
    <Box className={classes.container}>
      <Box className={classes.width100}>
        <Box className={classes.labelContainer}>
          <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} variant="Label_Base">
            {label}
          </TypographyWithFontFamily>
          {isPickerVisible && <Box className={classes.legendDot} sx={{ background: legendColor }} />}
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker dataCy={label} onChange={onChange} value={field.value} />
              </LocalizationProvider>
            )}
          />
        </LocalizationProvider>
      </Box>
      {isPickerVisible && (
        <NewButton
          className={classes.pickerButton}
          dataCy="calendar-picker-button"
          icon={isSelecting ? CalendarWhiteIcon : CalendarIcon}
          onClick={setSelection}
          variant={variant}
        />
      )}
    </Box>
  );
};

export default CalendarDatePickerInputCombo;
