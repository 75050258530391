import { Box } from '@mui/material';
import { useState } from 'react';

import PlusIcon from '#/assets/Plus.svg';

import AddUserWizard from '#/components/addUserWizard/AddUserWizard';
import AlertError from '#/components/common/Alert/AlertError/AlertError';
import SearchFilters from '#/components/common/SearchFilters/SearchFilters';
import useSearchFilters from '#/components/common/SearchFilters/useSearchFilters.hook';
import NewButton from '#/components/common/button/NewButton';
import Loader from '#/components/common/loader/loader';
import { Table } from '#/components/common/table/tableComponent/Table';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';
import { getUserHasPermission } from '#/constants/permissions/utils';
import { PartnersTable } from '#/constants/tables/partners/partnersTable';

import { useGetCompanyUserAllFiltersQuery, useGetCompanyUserAllQuery } from '#/store/api/users/users';

import PermissionWrapper from '#/utils/PermissionWrapper';

import { usePartnerUsersStyle } from './PartnerUsers.styles';

const PartnerUsersRetailer = () => {
  const { data: filterData, isError: filterDataIsError } = useGetCompanyUserAllFiltersQuery({});

  const { actions, state } = useSearchFilters(undefined, true, filterData?.filters);

  const classes = usePartnerUsersStyle();

  const [isAddUserWizardOpen, setIsAddUserWizardOpen] = useState(false);

  const {
    data: companyUserData,
    isError: companyUserIsError,
    isFetching: companyUserIsFetching,
    isLoading: companyUserIsLoading,
  } = useGetCompanyUserAllQuery({
    pageNumber: state.pageNumber,
    numberOfRows: state.numberOfElementsPerPage,
    searchQuery: state.searchQuery,
  });

  const userHasPartnersUsersActivateDeactivateUserWrite = getUserHasPermission(
    USER_PERMISSIONS.PARTNERS_USERS_ACTIVATE_DEACTIVATE_USER_WRITE,
  );

  const [error, setError] = useState();

  const { partnerUsersColumnTable } = PartnersTable(
    filterData ? filterData.filters : [],
    userHasPartnersUsersActivateDeactivateUserWrite,
    setError,
  );

  if (companyUserIsLoading) return <Loader />;

  return (
    <>
      <AlertError error={error} />
      <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.PARTNERS_USERS_ADD_USER_WRITE]}>
        <Box className={classes.addButtonContainer}>
          <NewButton
            icon={PlusIcon}
            iconPosition="left"
            onClick={() => setIsAddUserWizardOpen(true)}
            text="Add New User"
          />
        </Box>
      </PermissionWrapper>
      <SearchFilters
        columnNames={filterData?.filters}
        currentIndex={companyUserData?.currentIndex}
        filterDataIsError={filterDataIsError}
        hideViewMode
        isError={companyUserIsError}
        isFetching={companyUserIsFetching}
        isNoResults={!companyUserData || companyUserData.users.length === 0}
        totalPages={companyUserData?.totalPages}
        totalResults={companyUserData?.totalResults}
        {...state}
        {...actions}
      >
        <Table
          columns={partnerUsersColumnTable}
          data={companyUserData?.users || []}
          displayStatusColor
          sortProps={{
            sortParams: state.sortParams,
            setSortParams: actions.setSortParam,
          }}
        />
      </SearchFilters>
      {isAddUserWizardOpen && <AddUserWizard onClose={() => setIsAddUserWizardOpen(false)} />}
    </>
  );
};
export default PartnerUsersRetailer;
