import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useDataCy } from '#/hooks/useDataCy';

import { retailerAxios } from '../../../api/axiosConfig';
import { useCampaignStyles } from '../../campaigns/campaign.style';
import SubmissionDeadline from '../../common/SubmissionDeadline/SubmissionDeadline';
import Card from '../../common/card/card';
import { useSnackbarError } from '../../common/snackbar/useSnackbarError';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import { BrandsCommittedTable } from './BrandsCommittedTable';

interface ICampaignBrandData {
  brandsTargeted: number;
  participatingBrands: number;
}
const CampaignBrands = () => {
  const generateDataCy = useDataCy();

  const [brandData, setBrandData] = useState<ICampaignBrandData | null>(null);
  const { campaignId } = useParams();
  const { setError } = useSnackbarError();
  const styles = useCampaignStyles();

  const fetchBrandData = async () => {
    try {
      const { data } = await retailerAxios.get(`/campaign/${campaignId}/brands`);
      setBrandData(data);
    } catch (error) {
      setError(error);
    }
  };
  useEffect(() => {
    fetchBrandData();
  }, []);
  return (
    <Card>
      <SubmissionDeadline />
      <Box className={styles.campaignBrandsBoxWrap}>
        <TypographyWithFontFamily cName={styles.campaignBrandsTitle}>Brand Settings</TypographyWithFontFamily>
        <TypographyWithFontFamily cName={styles.campaignBrandsParagraph}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </TypographyWithFontFamily>
        <Box className={styles.campaignBrandsBox}>
          <Box>
            <TypographyWithFontFamily cName={styles.campaignBrandsLabels}>
              Target Number of Brands
            </TypographyWithFontFamily>
            <TypographyWithFontFamily
              data-cy={generateDataCy('TypographyWithFontFamily', 'CampaignBrands-Target-Number-of-Brands')}
            >
              {brandData?.brandsTargeted}
            </TypographyWithFontFamily>
          </Box>
          <Box>
            <TypographyWithFontFamily cName={styles.campaignBrandsLabels}>
              Participating Brands
            </TypographyWithFontFamily>
            <TypographyWithFontFamily
              data-cy={generateDataCy('TypographyWithFontFamily', 'CampaignBrands-Participating-Brands')}
            >
              {brandData?.participatingBrands}
            </TypographyWithFontFamily>
          </Box>
        </Box>
        <TypographyWithFontFamily cName={styles.campaignBrandsTitle2}>Participating Brands</TypographyWithFontFamily>
        <TypographyWithFontFamily cName={styles.campaignsBrandsPar}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </TypographyWithFontFamily>
        <Box className={styles.campaignBrandsTable}>
          <TypographyWithFontFamily cName={styles.campaignBrandsPromoteText}>
            Campaign is ready to promote to brands.
          </TypographyWithFontFamily>
          <TypographyWithFontFamily>
            Please select brands to invite to the campaign in the promote section.
          </TypographyWithFontFamily>
        </Box>
      </Box>
      <BrandsCommittedTable />
    </Card>
  );
};

export default CampaignBrands;
