import { Box } from '@mui/material';
import React, { FC } from 'react';

import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import { useCampaignDynamicRibbonModal } from '../../features/campaigns/instances/overview/CampaignDynamicRibbonModal.styles';

interface IBrandOrderModalItemProps {
  spacingBottom?: boolean;
  itemTitle: string;
  itemValue: string;
}

const BrandOrderModalItem: FC<IBrandOrderModalItemProps> = ({ spacingBottom = false, itemTitle, itemValue }) => {
  const classes = useCampaignDynamicRibbonModal();
  return (
    <Box className={spacingBottom ? classes.dflexWithMargin : classes.dflex}>
      <TypographyWithFontFamily cName={classes.boldLabel}>{itemTitle}</TypographyWithFontFamily>
      <TypographyWithFontFamily cName={classes.label}>{itemValue}</TypographyWithFontFamily>
    </Box>
  );
};

export default BrandOrderModalItem;
