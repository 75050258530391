import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { isEqual } from 'lodash';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useSnackbarError } from '#/components/common/snackbar/useSnackbarError';
import { useSnackbarSuccess } from '#/components/common/snackbar/useSnackbarSuccess';

import useGetResource from '#/hooks/useGetResource';

import { useGetCompanyAccountQuery, usePutEditCompanyMutation } from '#/store/api/company/company';
import { PutCompanyAccountGeneralInfo } from '#/store/api/company/companyInterfaces';

export const useEditCompanyGeneralForm = (updateEditMode: React.Dispatch<React.SetStateAction<boolean>>) => {
  const { resourceId } = useGetResource();
  const [updateCompany, result] = usePutEditCompanyMutation();
  const { data, isFetching, refetch, isSuccess } = useGetCompanyAccountQuery({ companyId: resourceId });

  const dataDefaultValues = {
    name: data?.name,
    companyUrl: data?.companyURL,
    mailingAddress1: data?.mailingAddress1,
    mailingAddress2: data?.mailingAddress2,
    city: data?.city,
    state: data?.state,
    zip: data?.zip,
    vendorId: data?.vendorId,
    logoId: data?.logoId,
    legalName: data?.legalName,
    companyType: data?.companyType,
    parentId: data?.parentId,
  };

  const { setSuccess } = useSnackbarSuccess();
  const { setError } = useSnackbarError();

  const schema = yup
    .object()
    .shape({
      parentId: yup.string().nullable(),
      companyUrl: yup.string(),
      mailingAddress1: yup.string(),
      mailingAddress2: yup.string(),
      city: yup.string(),
      state: yup.string(),
      zip: yup.string(),
      vendorId: yup.string(),
      name: yup.string().required('Company name is required'),
      logoId: yup.string().nullable(),
      legalName: yup.string().max(128, 'Legal name should have less than or equal than 128  characters'),
      companyType: yup.string(),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    watch,
    formState: { errors, defaultValues },
  } = useForm<PutCompanyAccountGeneralInfo>({
    resolver: yupResolver(schema),
    defaultValues: dataDefaultValues,
  });

  const onSubmit = handleSubmit(async (formData: PutCompanyAccountGeneralInfo) => {
    if (!isEqual(formData, defaultValues)) {
      try {
        const updatedData = { ...formData, country: 'United States', parentId: formData?.parentId || null };
        await updateCompany({
          data: updatedData,
          companyId: resourceId,
        }).unwrap();
        setSuccess('Your action is being processed. Please refresh this section if data is not updated.');
        updateEditMode(false);
      } catch (error) {
        setError((error as FetchBaseQueryError).data);
      }
    } else {
      updateEditMode(false);
    }
  });

  const onRefetch = async () => {
    try {
      await refetch().unwrap();
      reset(dataDefaultValues);
    } catch (error) {
      setError('We are unable to re fetch the data');
    }
  };

  useEffect(() => {
    if (isSuccess) {
      reset(dataDefaultValues);
    }
  }, [isSuccess]);

  return {
    onSubmit,
    control,
    reset,
    errors,
    register,
    setValue,
    isLoading: result.isLoading,
    dataLoading: isFetching,
    refetch: onRefetch,
    watch,
    data,
    error: result.error,
  };
};
