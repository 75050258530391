import { Typography, TypographyProps } from '@mui/material';
import classnames from 'classnames';
import { FC } from 'react';

import { THEME } from '#/constants/theme/constants';

import { TypographyVariantOptions } from './Typography.types';
import { TypographyMapper } from './TypographyWithFontFamily.mapper';

type TypographyWithFontFamilyProps = {
  variant?: TypographyVariantOptions;
  /**
   * @deprecated Use className instead
   */
  cName?: string;
  /**
   * @deprecated Use className instead
   */
  classNames?: string;
} & Omit<TypographyProps, 'variant' | 'component'> &
  (
    | {
        component: 'label';
        htmlFor: string;
      }
    | {
        component?: Exclude<TypographyProps['component'], 'label'> | undefined;
        htmlFor?: undefined;
      }
  );

export const TypographyWithFontFamily: FC<TypographyWithFontFamilyProps> = ({
  color = THEME.PALETTES.TEXT.Dark,
  children,
  cName,
  onClick,
  variant = 'Default_Base',
  gutterBottom = false,
  classNames,
  className,
  sx,
  ...rest
}) => {
  return (
    <Typography
      {...rest}
      className={classnames(className ?? cName ?? classNames)}
      color={color}
      gutterBottom={gutterBottom}
      onClick={onClick ? onClick : undefined}
      sx={{ ...TypographyMapper[variant].styles, ...sx }}
      variant={TypographyMapper[variant].variant}
    >
      {children}
    </Typography>
  );
};
