import { createStyles, makeStyles } from '#/utils/theme';

import brandConstants, { THEME } from '../../../constants/theme/constants';

export const useDashboardPageStyles = makeStyles(() =>
  createStyles({
    column: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    dashboardContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    double: {
      width: '65%',
    },
    single: {
      width: '33%',
    },
  }),
);

export const useChartsStyles = makeStyles((theme) =>
  createStyles({
    barChartContainer: {
      width: '100%',
      height: '25rem',
      paddingTop: theme.custom.REM_SPACES[2.5],
    },
    barChartItem: {
      left: theme.custom.REM_SPACES[1],
    },
    chartsContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    pieChartContainer: {
      width: '30%',
      paddingTop: theme.custom.REM_SPACES[2.5],
    },
    pieChartLabel: {
      fontSize: '1.7rem',
      fontWeight: theme.custom.FONT_WEIGHTS.BOLD,
      fontFamily: 'Roboto',
    },
    wrapperDiv: {
      display: 'flex',
      flexDirection: 'column',
      background: theme.custom.PALETTES.OTHER.White,
      borderRadius: theme.custom.REM_SPACES[2],
      padding: theme.custom.REM_SPACES[1],
      marginBottom: theme.custom.REM_SPACES[1],
    },
  }),
);

export const useCustomTooltipStyles = makeStyles((theme) =>
  createStyles({
    wrapperDiv: {
      display: 'flex',
      flexDirection: 'column',
      background: theme.custom.PALETTES.OTHER.White,
      borderRadius: theme.custom.REM_SPACES[2],
      padding: theme.custom.REM_SPACES[1],
    },
    textContent: {
      display: 'flex',
    },
    label: {
      marginRight: theme.custom.REM_SPACES[0.5],
    },
  }),
);

export const useQuickLinksStyles = makeStyles((theme) =>
  createStyles({
    link: {
      display: 'flex',
      textDecoration: 'none',
      marginBottom: theme.custom.REM_SPACES[0.5],
      color: theme.custom.PALETTES.TEXT.Action_Secondary,
      textDecorationLine: 'none',
    },
    linksContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.custom.REM_SPACES[3],
    },
    linkIcon: {
      alignSelf: 'flex-start',
      marginRight: theme.custom.REM_SPACES[0.5],
    },
    linkText: {
      margin: 0,
    },
    wrapperDiv: {
      display: 'flex',
      flexDirection: 'column',
      background: theme.custom.PALETTES.OTHER.White,
      borderRadius: theme.custom.REM_SPACES[2],
      padding: theme.custom.REM_SPACES[1],
      marginBottom: theme.custom.REM_SPACES[1],
      width: '100%',
      minWidth: theme.custom.REM_SPACES[15],
    },
  }),
);

export const usePendingTasksStyles = makeStyles((theme) =>
  createStyles({
    count: {
      alignSelf: 'center',
      width: '1.75rem',
      height: theme.custom.REM_SPACES[1],
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      margin: 0,
      backgroundColor: theme.custom.PALETTES.SEMANTIC.Error[500],
      color: theme.custom.PALETTES.TEXT.Light,
      borderRadius: '40%',
    },
    countText: {
      margin: 0,
      fontSize: brandConstants.FONT_SM,
    },
    row: {
      display: 'flex',
      textDecoration: 'none',
      marginBottom: theme.custom.REM_SPACES[0.5],
      color: theme.custom.PALETTES.TEXT.Action_Secondary,
      textDecorationLine: 'none',
      justifyContent: 'space-between',
    },
    text: {
      margin: 0,
      maxWidth: '80%',
      marginRight: theme.custom.REM_SPACES[0.5],
      overflow: 'hidden',
    },
  }),
);

export const usePendingCampaignStyles = makeStyles((theme) =>
  createStyles({
    wrapperDiv: {
      display: 'flex',
      flexDirection: 'column',
      background: theme.custom.PALETTES.OTHER.White,
      borderRadius: theme.custom.REM_SPACES[2],
      padding: theme.custom.REM_SPACES[1],
      marginBottom: theme.custom.REM_SPACES[1],
      width: '100%',
      minWidth: theme.custom.REM_SPACES[15],
    },
    noBorder: {
      '&&': {
        border: 'none',
      },
    },
    showAll: {
      textDecoration: 'none',
      fontWeight: theme.custom.FONT_WEIGHTS.SEMIBOLD,
      color: theme.custom.PALETTES.TEXT.Action_Secondary,
      marginLeft: '0.8rem',
    },
  }),
);

export const headClass = {
  background: 'transparent',
  border: 'none',
  fontSize: '0.875rem',
};

export const legendStyle = {
  paddingLeft: THEME.REM_SPACES[2],
};

export const marginBottom = {
  marginBottom: THEME.REM_SPACES[0.5],
};

export const pageNameCustomStyle = { marginBottom: THEME.REM_SPACES[0.5] };

export const chartTick = {
  fontSize: '0.8rem',
};

export const pieChartNoDataMessageSx = {
  width: '30%',
};

export const pendingTasksNoDataMessageSx = {
  marginRight: '0',
  marginBottom: THEME.REM_SPACES[1],
  width: '100%',
};

export const barStyle = {
  cursor: 'pointer',
};
