import { Box } from '@mui/system';
import classNames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form/dist/types';

import { useDataCy } from '#/hooks/useDataCy';

import { getCampaignThumbnailFile } from '../../../api/campaign/campaignOverview';
import { uploadFileCustomLink } from '../../../api/files';
import DeleteIcon from '../../../assets/DeleteIconBlue.svg';
import ImageThumbnailIcon from '../../../assets/ImageThumbnailIcon.svg';
import { THEME } from '../../../constants/theme/constants';
import Loader from '../loader/loader';
import { useSnackbarError } from '../snackbar/useSnackbarError';
import { TypographyWithFontFamily } from '../typography/TypographyWithFontFamily';
import { useImageDownloadUploadStyles } from './ImageDownloadUpload.style';

interface IImageDownloadUploadEditModeProps {
  editMode: boolean;
  // FIXME: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
  registerName: string;
  uploadLink: string;
}
interface IImageDownloadUploadReadOnlyModeProps {
  editMode?: false;
}
type ImageDownloadUploadProps = {
  fileId: string | undefined;
  extensions?: string[];
  renderImageTag?: boolean;
  customStyles?: { [key: string]: string };
} & (IImageDownloadUploadReadOnlyModeProps | IImageDownloadUploadEditModeProps);

const ImageDownloadUpload: FC<ImageDownloadUploadProps> = (props) => {
  const classes = useImageDownloadUploadStyles();
  const generateDataCy = useDataCy();

  const { fileId, editMode, renderImageTag } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const { setError } = useSnackbarError();

  const [thumbnailLink, setThumbnailLink] = useState('');

  const updateThumbnailLink = async (id: string) => {
    const { data } = await getCampaignThumbnailFile(id);
    if (!data) {
      setThumbnailLink('');
      return;
    }
    const url = URL.createObjectURL(data);
    setThumbnailLink(url);
  };
  useEffect(() => {
    if (!fileId || thumbnailLink) return;
    updateThumbnailLink(fileId);
  }, [fileId, thumbnailLink]);

  if (isUploading) {
    return <Loader />;
  }

  if (!fileId) {
    if (!editMode) return <></>;

    //upload file and replace file id with the new one
    const updateFile = async (currentFile: File | undefined | null) => {
      if (editMode) {
        //check file extensions before upload
        const isCorrectFileExtension =
          props?.extensions && !props?.extensions?.includes('.' + currentFile?.name?.split('.')?.[1] || '');
        if (isCorrectFileExtension) {
          setUploadError('File extension not allowed');
          return;
        }
        setUploadError('');
        setIsUploading(true);
        const { setValue, uploadLink } = props;
        const formData = new FormData();
        formData.append('file', currentFile as Blob);
        try {
          const response = await uploadFileCustomLink(uploadLink, formData);
          setValue(props.registerName, response?.data);
          await updateThumbnailLink(response?.data);
        } catch (error) {
          setError(error);
        } finally {
          setIsUploading(false);
        }
      }
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentFile = e.target.files?.[0];
      updateFile(currentFile);
    };

    const handleBrowseFile = () => {
      inputRef.current?.click();
    };

    const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
    };

    const dopHandler = async (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      let currentFile: File | null;
      if (e.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file
        currentFile = e.dataTransfer.items[0].getAsFile();
      } else {
        // Use DataTransfer interface to access the file
        currentFile = e.dataTransfer.files[0];
      }
      updateFile(currentFile);
    };

    return (
      <Box sx={{ height: '100%' }}>
        <input
          ref={inputRef}
          accept={props?.extensions?.join(', ') || 'image/*'}
          data-cy={generateDataCy('input', `DownloadUpload${editMode ? props.registerName : ''}`)}
          hidden={true}
          onChange={handleFileChange}
          type="file"
        />
        <Box
          className={classNames(classes.thumbnailContainer, editMode && classes.thumbnailBorder)}
          onDragOver={(e) => dragOverHandler(e)}
          onDrop={(e) => dopHandler(e)}
        >
          <img alt="thumbnail icon" src={ImageThumbnailIcon} />
          <TypographyWithFontFamily>Drag & Drop to Upload Image</TypographyWithFontFamily>
          <TypographyWithFontFamily
            cName={classes.browseFile}
            color={THEME.PALETTES.ICONS.Action_Secondary}
            onClick={handleBrowseFile}
          >
            Browse file
          </TypographyWithFontFamily>
        </Box>
        <TypographyWithFontFamily>{uploadError}</TypographyWithFontFamily>
      </Box>
    );
  } else {
    const onDeleteFile = () => {
      if (editMode) {
        const { setValue, registerName } = props;
        setValue(registerName, '');
        setThumbnailLink('');
      }
    };
    return (
      <div
        className={classes.thumbnailContainer}
        data-cy={generateDataCy('input', `DownloadUpload${editMode ? props.registerName : ''}`)}
      >
        {renderImageTag ? (
          <img alt={`image-${thumbnailLink}`} src={thumbnailLink} />
        ) : (
          <Box
            className={classes.thumbnail}
            style={{
              backgroundImage: `url(${thumbnailLink})`,
            }}
            sx={props.customStyles}
          ></Box>
        )}
        {editMode && <img className={classes.deleteIcon} onClick={onDeleteFile} src={DeleteIcon} />}
      </div>
    );
  }
};

export default ImageDownloadUpload;
