import { Box } from '@mui/system';
import classnames from 'classnames';

import Loader from '#/components/common/loader/loader';

import { useAppSelector } from '#/hooks';

import { formatUserType } from '#/utils/utils';

import { useUserProfileStyles } from './UserProfile.styles';
import { UserProfilePicture } from './UserProfilePicture';

export const UserProfileAccount = () => {
  const classes = useUserProfileStyles();
  const { user } = useAppSelector((state) => state.user);

  if (!user) {
    return <Loader />;
  }

  return (
    <div className={classes.wrapper}>
      <UserProfilePicture autoSave avatarId={user.avatarId} needsRefresh />
      <Box className={classes.flexContainer} sx={{ display: 'flex' }}>
        <Box>
          <p className={classes.userNameField}>NAME</p>
          <p className={classnames(classes.userNameField, classes.userName)}>{`${user.firstName} ${user.lastName}`}</p>
        </Box>
        <Box>
          <p className={classes.userNameField}>USER TYPE</p>
          <p className={classnames(classes.userNameField, classes.userName)}>{formatUserType(user.userType)}</p>
        </Box>
      </Box>
    </div>
  );
};
