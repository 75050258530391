import TableLogoCell from '#/components/common/tableLogoCell/TableLogoCell';

import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { CompanyAccountAssociatedCompany } from '#/store/api/company/companyInterfaces';

export const getTableColumns = (): ITableColumnsProps<CompanyAccountAssociatedCompany>[] => {
  const createCustomNameImageView = (
    aggregateId: string,
    logoId: string | null,
    companyName: string,
    companyType: string,
  ) => (
    <TableLogoCell
      link={companyType === 'AGENCY' ? `/partners/agencies/${aggregateId}` : `/partners/brands/${aggregateId}`}
      logoId={logoId}
      name={companyName}
    />
  );

  return [
    {
      fieldName: 'aggregateId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Campaign Id',
      hide: true,
    },
    {
      fieldName: 'companyName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Brand',
      actionElementIdentifier: 'aggregateId',
      actionElementSecondIdentifier: 'logoId',
      actionElementThirdIdentifier: 'companyName',
      actionElementFourthIdentifier: 'companyType',
      getCustomElement: createCustomNameImageView,
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Brand Status',
    },
    {
      fieldName: 'parentName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Parent Company',
    },
    {
      fieldName: 'categories',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Categories',
    },
    {
      fieldName: 'subCategories',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Sub-Categories',
    },
  ];
};
export default getTableColumns;
