import { Box, Stack, Step, StepLabel, Stepper } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import AssociateUserToCompanyContainer from '#/components/addUserWizard/AssociateUserToCompanyContainer';
import AlertError from '#/components/common/Alert/AlertError/AlertError';
import ModalBottomContainer from '#/components/common/modal/ModalBottomContainer';

import { CompanyAccountById } from '#/store/api/company/companyInterfaces';
import { ISingleUserData } from '#/store/api/users/usersInterfaces';

import PlusIcon from '../../assets/Plus.svg';
import StepCurrentIcon from '../../assets/StepCurrentIcon.svg';
import StepSuccessIcon from '../../assets/StepSuccessIcon.svg';
import StepUncompletedIcon from '../../assets/StepUncompletedIcon.svg';
import { UsersRoles } from '../../constants/usersRoles/usersRole';
import { useAddUserWizardForm } from '../../hooks/forms/useAddUserWizardForm';
import { IPartnerUserAccess } from '../../interfaces/partners/users/partnerUsers';
import NewButton from '../common/button/NewButton';
import Modal from '../common/modal/Modal';
import { TypographyWithFontFamily } from '../common/typography/TypographyWithFontFamily';
import AddCompanyUser from './AddCompanyUser';
import { useAddUserWizardStyle } from './AddUserWizard.style';
import CreateCompany from './CreateCompany';
import UserFormOverview from './UserFormOverview';
import UserRequestData from './UserRequestData';

interface IAddUserWizardProps {
  onClose: () => void;
  companyData?: CompanyAccountById;
  userRequestData?: IPartnerUserAccess;
  isEditAssociatedBrands?: boolean;
  fullUserData?: ISingleUserData | undefined;
  isAddUserWizardOpen?: boolean;
}

enum UserWizardSteps {
  PopulateUserInfo = 'Populate User Info',
  SelectCompany = 'Select Company',
}

const AddUserWizard: FC<IAddUserWizardProps> = ({
  onClose,
  companyData,
  userRequestData,
  isEditAssociatedBrands,
  fullUserData,
  isAddUserWizardOpen,
}) => {
  const [activeFormStep, setActiveFormStep] = useState(isEditAssociatedBrands ? 2 : 0);
  const formSteps =
    companyData?.companyType === 'BRAND'
      ? [UserWizardSteps.PopulateUserInfo]
      : companyData?.companyType === 'AGENCY'
        ? [UserWizardSteps.PopulateUserInfo]
        : [UserWizardSteps.PopulateUserInfo, UserWizardSteps.SelectCompany];
  const classes = useAddUserWizardStyle();
  const methods = useAddUserWizardForm(onClose, isEditAssociatedBrands, fullUserData?.userId);

  const error = methods.error || methods.putClaimsError;

  const [isCreateCompanyOpen, setIsCreateCompanyOpen] = useState(false);
  useEffect(() => {
    if (companyData) {
      methods.reset({
        selectedCompany: {
          companyId: companyData.companyId,
          name: companyData.name,
          type: companyData.companyType,
        },
        user: {
          state: '',
        },
      });
    }
    if (userRequestData) {
      methods.reset({
        user: {
          title: userRequestData.title,
          firstName: userRequestData.firstName,
          lastName: userRequestData.lastName,
          phoneNumber: '',
          email: userRequestData.email,
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          country: 'United States',
          zipCode: '',
          department: '',
          avatar: '',
          keyContact: false,
          billingContact: false,
        },
      });
    }
    if (isEditAssociatedBrands && fullUserData) {
      methods.reset({
        user: {
          firstName: fullUserData.firstName,
          lastName: fullUserData.lastName,
          email: fullUserData.emailAddress,
        },
        defaultRole: UsersRoles.Brand,
        selectedCompany: {
          companyId: fullUserData.companyId,
        },
      });
    }
  }, [companyData?.companyId, userRequestData?.id, isEditAssociatedBrands, fullUserData?.userId]);

  const onNext = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const triggerResult =
      formSteps[activeFormStep] === UserWizardSteps.PopulateUserInfo
        ? await methods.trigger('user')
        : await methods.trigger('selectedCompany');
    if (activeFormStep < 2 && triggerResult) {
      setActiveFormStep((prev) => prev + 1);
      setIsCreateCompanyOpen(false);
    }
  };

  const onBack = () => {
    setActiveFormStep((prev) => prev - 1);
    setIsCreateCompanyOpen(false);
  };
  const [userImageId, setUserImageId] = useState('');
  return (
    <Modal
      content={
        <Box className={classes.modalContainer}>
          <Box>
            <AlertError error={error} />
            <Stack className={classes.stepperContainer}>
              <Stepper activeStep={activeFormStep} className={classes.stepper}>
                {formSteps.map((step: string, index: number) => (
                  <Step key={step}>
                    {index < activeFormStep && (
                      <StepLabel icon={<img src={StepSuccessIcon} />}>
                        <TypographyWithFontFamily>{step}</TypographyWithFontFamily>
                      </StepLabel>
                    )}
                    {index === activeFormStep && (
                      <StepLabel icon={<img src={StepCurrentIcon} />}>
                        <TypographyWithFontFamily variant="Default_Bold">{step}</TypographyWithFontFamily>
                      </StepLabel>
                    )}
                    {index > activeFormStep && (
                      <StepLabel icon={<img src={StepUncompletedIcon} />}>
                        <TypographyWithFontFamily>{step}</TypographyWithFontFamily>
                      </StepLabel>
                    )}
                  </Step>
                ))}
              </Stepper>
            </Stack>
            {userRequestData && <UserRequestData userRequestData={userRequestData} />}
            <FormProvider {...methods}>
              <form onSubmit={methods.onSubmit}>
                <UserFormOverview />

                {formSteps[activeFormStep] === UserWizardSteps.PopulateUserInfo && (
                  <AddCompanyUser setUserImageId={setUserImageId} userImageId={userImageId} />
                )}
                {formSteps[activeFormStep] === UserWizardSteps.SelectCompany && <AssociateUserToCompanyContainer />}
                <ModalBottomContainer>
                  <Box>
                    {!isEditAssociatedBrands && activeFormStep > 0 && (
                      <NewButton onClick={onBack} text="Back" variant="Secondary" />
                    )}
                  </Box>
                  <Box>
                    {formSteps[activeFormStep] === UserWizardSteps.SelectCompany && userRequestData && (
                      <NewButton onClick={() => setIsCreateCompanyOpen(true)} text="Create Company" variant="Primary" />
                    )}
                  </Box>

                  <Box className={classes.rightButtonsContainer}>
                    <NewButton onClick={onClose} text="Cancel" variant="Secondary" />
                    {formSteps.length - 1 !== activeFormStep ? (
                      <NewButton className={classes.lastButton} onClick={onNext} text="Next" variant="Primary" />
                    ) : (
                      <NewButton
                        className={classes.lastButton}
                        disabled={!methods.watch('selectedCompany')?.companyId}
                        loading={methods.isLoading || methods.isPutClaimsSetLoading}
                        text="Save"
                        type="submit"
                        variant="Primary"
                      />
                    )}
                  </Box>
                </ModalBottomContainer>
              </form>
            </FormProvider>
            {isCreateCompanyOpen && <CreateCompany onClose={() => setIsCreateCompanyOpen(false)} />}
          </Box>
        </Box>
      }
      handleCloseModal={onClose}
      icon={PlusIcon}
      modalTitle="Add User"
      open={isAddUserWizardOpen}
    />
  );
};

export default AddUserWizard;
