import { FC, useState } from 'react';

import { getDynamicRibbon, postDynamicRibbon, putDynamicRibbon } from '#/api/dynamicRibbon';

import { IDynamicButtonProps, IPostDynamicRibbonBody } from '#/interfaces/dynamicRibbon/dynamicRibbon';

import NewButton from '../../../common/button/NewButton';
import { useSnackbarError } from '../../../common/snackbar/useSnackbarError';

const httpMethodMapper = {
  PUT: putDynamicRibbon,
  POST: postDynamicRibbon,
  GET: getDynamicRibbon,
};

const DynamicButton: FC<IDynamicButtonProps> = ({
  component,
  isLast,
  submitCallback,
  watch,
  setOpen,
  setModalData,
  errors,
  disabled = false,
}) => {
  const [loading, setLoading] = useState(false);
  const { setError } = useSnackbarError();
  const onClick = async () => {
    const resource: IPostDynamicRibbonBody = {} as IPostDynamicRibbonBody;
    component.content.bodyParameters &&
      component.content.bodyParameters.forEach((param) => {
        if (param === 'timestamp') {
          resource['timestamp'] = new Date();
        } else {
          resource[param] = watch(param);
        }
      });
    try {
      setLoading(true);
      if (component.modalOpen === true) {
        const response = await httpMethodMapper[component.content.httpMethod](component.content.uri, resource);
        setModalData?.(response?.data);
        setOpen?.(true);
        return;
      }

      await httpMethodMapper[component.content.httpMethod](component.content.uri, resource);
      submitCallback?.();
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getDisabledStatus = () => {
    const hasAtLeastOneTask = watch('taskActions') ? watch('taskActions')?.some((el) => el.action) : true;
    const hasTaskError = Boolean(errors?.taskActions);

    if (disabled) return true;
    if (hasTaskError) return true;
    if (!hasAtLeastOneTask) return true;
    if (component.modalOpen ? !watch('tierId') : component.disabled) return true;

    return false;
  };

  return (
    <NewButton
      disabled={getDisabledStatus()}
      loading={loading}
      onClick={onClick}
      text={component?.label || ''}
      variant={isLast ? 'Primary' : 'Secondary'}
    />
  );
};

export default DynamicButton;
