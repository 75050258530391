import { Box } from '@mui/material';

import { currencyFormatter } from '#/utils/textFormatters';

import { useCustomTooltipStyles } from '../Dashboard.styles';

type PayloadMin = {
  dataKey: string;
  fill: string;
  value: string | number;
};
interface IProps<T extends PayloadMin> {
  active?: boolean;

  payload?: T[];
  label?: string;
}

export const CustomTooltip = <T extends PayloadMin>({ active, payload, label }: IProps<T>) => {
  const classes = useCustomTooltipStyles();

  if (active && payload && payload.length) {
    return (
      <Box className={classes.wrapperDiv}>
        <p className="label">{`${label} : ${currencyFormatter(payload[0].value)}`}</p>
        <Box>
          {payload.map((payloadItem) => (
            <Box key={payloadItem.dataKey} className={classes.textContent}>
              <Box className={classes.label}>{`${payloadItem.dataKey}:`}</Box>
              <Box style={{ color: payloadItem.fill }}>{currencyFormatter(payloadItem.value)}</Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  return null;
};
