import { useParams } from 'react-router';

import { Table } from '#/components/common/table/tableComponent/Table';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { AssociateAgencyBrandTable } from '#/constants/tables/partners/company/companyTable';

import { useGetHierarchyListQuery } from '#/store/api/company/company';

const CompanyHierarchyTable = () => {
  const { brandId } = useParams();
  const { BrandHierarchyTable } = AssociateAgencyBrandTable();
  const { data } = useGetHierarchyListQuery({ companyId: brandId });
  return (
    <>
      {data ? (
        <Table columns={BrandHierarchyTable} data={data} maxHeight="21rem" />
      ) : (
        <TypographyWithFontFamily variant={'Default_Bold'}>There are no associated brands</TypographyWithFontFamily>
      )}
    </>
  );
};

export default CompanyHierarchyTable;
