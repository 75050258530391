import { Box } from '@mui/material';
import { FC, useState } from 'react';

import { numberFormatter } from '#/utils/textFormatters';

import {
  putCampaignTacticChannelPartnerCampaignInventoryConfirm,
  putCampaignTacticChannelPartnerCampaignInventoryReject,
  putCampaignTacticChannelPartnerRejectTacticMetadata,
  putInstanceTacticChannelPartnerCampaignInventoryBook,
  putInstanceTacticChannelPartnerCampaignInventoryReject,
  putInstanceTacticChannelPartnerCampaignTacticMetadata,
} from '../../../../../../api/campaign/campaigns';
import { USER_PERMISSIONS } from '../../../../../../constants/permissions/userPermissions';
import { getUserHasPermission } from '../../../../../../constants/permissions/utils';
import useCurrentPath from '../../../../../../hooks/useCurrentPath.hook';
import useGetResource from '../../../../../../hooks/useGetResource';
import { IChannelAndTacticsResponseTactic } from '../../../../../../interfaces/forms/campaigns';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '../../../../../../interfaces/table/table';
import {
  useGetCampaignTiersChannelPartnerQuery,
  usePutCampaignTacticChannelPartnerCampaignInventoryConfirmMutation,
  usePutCampaignTacticChannelPartnerCampaignInventoryRejectMutation,
} from '../../../../../../store/api/campaigns/campaignChannelsAndTacticsChannelPartner';
import AddDetailsToSpecificTierChannelPartner from '../../../../../campaigns/AddDetailsToSpecificTierChannelPartner';
import { useCampaignTierTacticsTableStyle } from '../../../../../campaigns/CampaignTierTacticsTableStyle';
import NewButton from '../../../../../common/button/NewButton';
import { useSnackbarError } from '../../../../../common/snackbar/useSnackbarError';
import { Table } from '../../../../../common/table/tableComponent/Table';
import { styles } from '../../../../../features/campaigns/campaign/channelsAndTactics/ChannelPartnerView/TacticsTable.styles';

interface ITactic {
  campaignTacticId: string;
  channelPartnerId: string;
  channelPartnerName: string;
  tierId: string;
  status: string;
  channel: string;
  subChannel: string;
  tactic: string;
  placement: string;
  targeting: string;
  startDate: string;
  endDate: string;
  budget: string;
  units: string;
  rateType: string;
  rateValue: number;
}
interface INewCampaignTierTacticsTableProps {
  tactics?: IChannelAndTacticsResponseTactic[];
}
const NewCampaignTierTacticsTableChannelPartner: FC<INewCampaignTierTacticsTableProps> = ({ tactics }) => {
  const userHasReadInventoryPermission = getUserHasPermission(
    USER_PERMISSIONS.CHANNEL_PARTNER_CAMPAIGN_CHANNEL_AND_TACTICS_INVENTORY_READ,
  );

  const { resourceId } = useGetResource();
  const { data: campaignData } = useGetCampaignTiersChannelPartnerQuery({ campaignId: resourceId });

  const [selectedTactic, setSelectedTactic] = useState<IChannelAndTacticsResponseTactic | undefined>(undefined);
  const classes = useCampaignTierTacticsTableStyle();
  const { setError } = useSnackbarError();

  const locationMethodsMapper = {
    'instances/channel-tactics/:instanceId': {
      confirmMethod: putInstanceTacticChannelPartnerCampaignInventoryBook,
      rejectMethod: putInstanceTacticChannelPartnerCampaignInventoryReject,
      updateMetadataMethod: putInstanceTacticChannelPartnerCampaignTacticMetadata,
      confirmText: 'Book',
      confirmButtonDisabledStatus: 'BOOKED',
    },
    'campaigns/channel-tactics/:campaignId': {
      confirmMethod: putCampaignTacticChannelPartnerCampaignInventoryConfirm,
      rejectMethod: putCampaignTacticChannelPartnerCampaignInventoryReject,
      updateMetadataMethod: putCampaignTacticChannelPartnerRejectTacticMetadata,
      confirmText: 'Confirm',
      confirmButtonDisabledStatus: 'CONFIRMED',
    },
  };
  const actions = ({ rowData }: { rowData }) => {
    const currentPath = useCurrentPath();
    const { campaignTacticId } = rowData;
    const [triggerPutCampaignTacticChannelPartnerCampaignInventoryConfirmMutation] =
      usePutCampaignTacticChannelPartnerCampaignInventoryConfirmMutation();
    const [putCampaignTacticChannelPartnerCampaignInventoryRejectMutation] =
      usePutCampaignTacticChannelPartnerCampaignInventoryRejectMutation();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);

    const isConfirmButtonDisabled =
      rowData?.status === locationMethodsMapper[currentPath].confirmButtonDisabledStatus ||
      !!campaignData?.inventorySubmitted;
    const isRejectButtonDisabled = rowData?.status === 'REJECTED' || !!campaignData?.inventorySubmitted;

    const confirmHandler = async () => {
      try {
        setConfirmLoading(true);
        await triggerPutCampaignTacticChannelPartnerCampaignInventoryConfirmMutation(campaignTacticId).unwrap();
      } catch (error) {
        setError(error);
      } finally {
        setConfirmLoading(false);
      }
    };

    const rejectHandler = async () => {
      try {
        setRejectLoading(true);
        await putCampaignTacticChannelPartnerCampaignInventoryRejectMutation(campaignTacticId).unwrap();
      } catch (error) {
        setError(error);
      } finally {
        setRejectLoading(false);
      }
    };

    return (
      <Box className={classes.inventoryButtons}>
        <NewButton
          disabled={isConfirmButtonDisabled}
          loading={confirmLoading}
          onClick={confirmHandler}
          sx={styles.actionButton}
          text={locationMethodsMapper[currentPath].confirmText}
          variant="Secondary"
        />
        <NewButton
          disabled={isRejectButtonDisabled}
          loading={rejectLoading}
          onClick={rejectHandler}
          sx={styles.actionButton}
          text="Reject"
          variant="Secondary"
        />
      </Box>
    );
  };

  const tableColumns: ITableColumnsProps<ITactic>[] = [
    {
      fieldName: null,
      align: TableColumnAlign.CENTER,
      actionElementIdentifier: 'campaignTacticId',
      type: TableCellTypes.REACT_TABLE_DATA_ELEMENT,
      //FIXME: fix any
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      element: actions as any,
      customHead: 'Status',
      hide: !userHasReadInventoryPermission,
    },
    {
      fieldName: 'channel',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Channel',
    },
    {
      fieldName: 'subChannel',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Sub-channel',
    },
    {
      fieldName: 'tactic',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.ACTION,
      customHead: 'Tactic',
      referenceAction: (campaignTacticId) => {
        setSelectedTactic(tactics?.find((tactic) => tactic.campaignTacticId === campaignTacticId));
      },
      actionButtonTextFromMapping: 'tactic',
      itemClass: classes.areaButton,
      actionElementIdentifier: 'campaignTacticId',
    },
    {
      fieldName: 'channelPartnerName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Channel Partner',
    },
    {
      fieldName: 'placement',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Placement',
    },
    {
      fieldName: 'targeting',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Targeting',
    },
    {
      fieldName: 'rateType',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Rate Type',
    },
    {
      fieldName: 'rateValue',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Rate',
    },
    {
      fieldName: 'units',
      align: TableColumnAlign.LEFT,
      customHead: 'Units',
      textFormatter: numberFormatter,
      type: TableCellTypes.STRING,
    },
    {
      fieldName: 'startDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Start Date',
    },
    {
      fieldName: 'endDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'End Date',
    },
  ];

  return (
    <Box>
      {!!selectedTactic && (
        <AddDetailsToSpecificTierChannelPartner selectedTactic={selectedTactic} setSelectedTactic={setSelectedTactic} />
      )}
      {<Table columns={tableColumns} data={tactics || []} />}
    </Box>
  );
};

export default NewCampaignTierTacticsTableChannelPartner;
