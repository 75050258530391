import { DayCellContentArg } from '@fullcalendar/core';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { FC } from 'react';

import { THEME } from '../../constants/theme/constants';
import { IPeriod } from '../../store/api/periods/periodsInterfaces';
import { TypographyWithFontFamily } from '../common/typography/TypographyWithFontFamily';
import { useCellStyles } from './Cell.styles';

interface CellProps {
  dayCell: DayCellContentArg;
  periods: IPeriod[] | undefined;
}
const Cell: FC<CellProps> = ({ dayCell, periods }) => {
  const { date, dayNumberText } = dayCell;
  const classes = useCellStyles();

  const isStartOfPeriod = periods?.find((period) => {
    return dayjs(date).isSame(dayjs(period.startOfPeriod), 'day');
  });

  const startOfPeriodBackgroundColor = THEME.PALETTES.SEMANTIC.Success['700'];
  const startOfPeriodTextColor = THEME.PALETTES.TEXT.Light;

  const defaultBackgroundColor = THEME.PALETTES.TEXT.Light;
  const defaultTextColor = THEME.PALETTES.TEXT.Black;

  return (
    <Box
      className={classes.container}
      style={{
        background: isStartOfPeriod ? startOfPeriodBackgroundColor : defaultBackgroundColor,
      }}
    >
      <TypographyWithFontFamily color={isStartOfPeriod ? startOfPeriodTextColor : defaultTextColor}>
        {dayNumberText}
      </TypographyWithFontFamily>
    </Box>
  );
};

export default Cell;
