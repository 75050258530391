import NewButton from '#/components/common/button/NewButton';

import { useDeleteInactivateUserMutation, usePutActivateUserMutation } from '#/store/api/users/users';
import { CompanyUser } from '#/store/api/users/usersInterfaces';

import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '../../../interfaces/table/table';

export const PartnersTable = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columNames: any = [],
  userHasPartnersUsersActivateDeactivateUserWrite: boolean,
  setError,
) => {
  const sortableColumns = columNames.map((el) => el.columnName);

  const composeUrl = (itemIdentifier: string, urlIdentifier?: string) => {
    if (urlIdentifier && urlIdentifier === 'AGENCY') {
      return `/partners/agencies/${itemIdentifier}`;
    }
    if (urlIdentifier && urlIdentifier === 'CHANNEL_PARTNER') {
      return `/partners/channel-partners/${itemIdentifier}`;
    }
    return `/partners/brands/${itemIdentifier}`;
  };

  const formatCompanyType = (companyType: string) => {
    if (companyType === 'CHANNEL_PARTNER') {
      return <>Channel Partner</>;
    }

    return <>{companyType.charAt(0) + companyType.slice(1).toLowerCase()}</>;
  };

  const createCustomNameImageView = (userId, status) => {
    const [putActivateUser, { isLoading: putActivateUserLoading }] = usePutActivateUserMutation();
    const [deleteInactivateUser, { isLoading: deleteInactivateUserLoading }] = useDeleteInactivateUserMutation();

    return status === 'Inactive' ? (
      <NewButton
        loading={putActivateUserLoading}
        onClick={() => {
          try {
            putActivateUser({ userId }).unwrap();
          } catch (error) {
            setError(error);
          }
        }}
        text="Activate"
      />
    ) : (
      <NewButton
        loading={deleteInactivateUserLoading}
        onClick={() => {
          try {
            deleteInactivateUser({ userId }).unwrap();
          } catch (error) {
            setError(error);
          }
        }}
        text="Deactivate"
      />
    );
  };

  const partnerUsersColumnTable: ITableColumnsProps<CompanyUser>[] = [
    {
      fieldName: 'companyId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      hide: true,
    },
    {
      fieldName: 'fullName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Name',
      linkable: true,
      baseUrl: '/partners/users',
      actionElementIdentifier: 'userId',
      sortable: sortableColumns.includes('fullName'),
    },
    {
      fieldName: 'companyName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Company Name ',
      linkable: true,
      baseUrl: '/partners/brands',
      composeUrl: composeUrl,
      urlIdentifier: 'companyType',
      actionElementIdentifier: 'companyId',
      sortable: sortableColumns.includes('companyName'),
    },
    {
      fieldName: 'companyType',
      align: TableColumnAlign.LEFT,
      customHead: 'Company Type',
      type: TableCellTypes.CUSTOM_ELEMENT,
      actionElementIdentifier: 'companyType',
      getCustomElement: formatCompanyType,
      sortable: sortableColumns.includes('companyType'),
    },
    {
      fieldName: 'jobTitle',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Job Title',
      sortable: sortableColumns.includes('jobTitle'),
    },
    {
      fieldName: 'defaultRole',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Default Role',
      sortable: sortableColumns.includes('defaultRole'),
    },
    {
      fieldName: 'email',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Email',
      sortable: sortableColumns.includes('email'),
    },
    {
      fieldName: 'phone',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Phone',
      sortable: sortableColumns.includes('phone'),
    },
    {
      fieldName: 'lastLogin',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Last Login',
      sortable: sortableColumns.includes('lastLogin'),
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Turbyne Status',
      sortable: sortableColumns.includes('status'),
    },
    {
      fieldName: null,
      align: TableColumnAlign.LEFT,
      customHead: 'Actions',
      type: TableCellTypes.CUSTOM_ELEMENT,
      getCustomElement: createCustomNameImageView,
      actionElementIdentifier: 'userId',
      actionElementSecondIdentifier: 'status',
      hide: !userHasPartnersUsersActivateDeactivateUserWrite,
    },
  ];

  return { partnerUsersColumnTable };
};
