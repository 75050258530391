import { EventContentArg } from '@fullcalendar/core';
import { Tooltip } from '@mui/material';
import { FC } from 'react';

import { TypographyWithFontFamily } from '../common/typography/TypographyWithFontFamily';
import { styles } from './Event.styles';

interface EventProps {
  content: EventContentArg;
}

const Event: FC<EventProps> = ({ content }) => {
  const { backgroundColor, event } = content;
  return (
    <Tooltip
      arrow
      title={
        <TypographyWithFontFamily color={backgroundColor} variant="H5_Base">
          {event.title}
        </TypographyWithFontFamily>
      }
    >
      <div>
        <TypographyWithFontFamily sx={styles.title}>{event.title}</TypographyWithFontFamily>
      </div>
    </Tooltip>
  );
};

export default Event;
