import * as yup from 'yup';

import { ITacticMetadataDTO, InputTypes } from '../../../interfaces/tacticMetadata/tacticMetadata';

const MB_TO_BITES = 1000000;

export const returnYupSchemaShape = (inputs: ITacticMetadataDTO[]) => {
  let schema = {};
  inputs.forEach((field) => {
    schema = {
      ...schema,
      ...getYupValidation(field),
    };
  });
  return schema;
};

export const returnYupSchemaShapeAssets = (inputs: ITacticMetadataDTO[]) => {
  let schema = {};
  inputs.forEach((field) => {
    schema = {
      ...schema,
      ...getYupValidationAssets(field),
    };
  });
  return schema;
};

const getYupValidation = (input: ITacticMetadataDTO) => {
  switch (input.inputType) {
    case InputTypes.TEXT:
      return getYupTextInputValidation(input);
    case InputTypes.AREA:
      return getYupTextInputValidation(input);
    case InputTypes.NUMBER:
      return getYupNumericInputValidation(input);
    case InputTypes.DROPDOWN:
      return getYupSelectionInputValidation(input);
    case InputTypes.FILE:
      return getYupFileInputValidation(input);
    case InputTypes.CHECKBOX_LIST:
      return getYupCheckBoxListInputValidation(input);
    case InputTypes.RADIO:
      return getYupRadioBoxInputValidation(input);
    case InputTypes.SINGLE_OPTION_CHECKBOX:
      return getYupSingleOptionCheckBoxInputValidation(input);
  }
};
const getYupValidationAssets = (input: ITacticMetadataDTO) => {
  switch (input.inputType) {
    case InputTypes.TEXT:
      return getYupTextInputValidation(input);
    case InputTypes.AREA:
      return getYupTextInputValidation(input);
    case InputTypes.NUMBER:
      return getYupNumericInputValidation(input);
    case InputTypes.DROPDOWN:
      return getYupSelectionInputValidation(input);
    case InputTypes.FILE:
      return getYupAssetFileInputValidation(input);
    case InputTypes.CHECKBOX_LIST:
      return getYupCheckBoxListInputValidation(input);
    case InputTypes.RADIO:
      return getYupRadioBoxInputValidation(input);
    case InputTypes.SINGLE_OPTION_CHECKBOX:
      return getYupSingleOptionCheckBoxInputValidation(input);
    case InputTypes.PRODUCT_SELECTOR:
      return getYupProductInputValidation(input);
  }
};

const getYupTextInputValidation = (input: ITacticMetadataDTO) => {
  const inputName = String(input.id);
  return {
    [inputName]: yup.string().test({
      name: 'customValidation',
      test: function (value) {
        const { validations } = input as ITacticMetadataDTO;
        const errors: string[] = [];

        if (input.required && !value) {
          errors.push('Required Input');
          throw new yup.ValidationError(errors.join(';'), value, inputName);
        }

        const isRegexValid = validations?.regexValidation?.validation
          ? new RegExp(validations.regexValidation.validation).test(value || '')
          : true;

        const isMinLengthValid =
          value && validations?.lengthValidation?.minLength
            ? value.length > validations?.lengthValidation?.minLength
            : true;

        const isMaxLengthValid =
          value && validations?.lengthValidation?.maxLength
            ? value.length < validations?.lengthValidation?.maxLength
            : true;

        if (!isRegexValid) {
          errors.push(
            validations?.regexValidation?.errorMessage
              ? validations?.regexValidation?.errorMessage
              : 'Regex Validation Failed',
          );
        }

        if (!isMinLengthValid) {
          errors.push(
            validations?.lengthValidation?.errorMessage
              ? String(validations?.lengthValidation?.errorMessage)
              : 'Min Length Validation Failed',
          );
        }

        if (!isMaxLengthValid) {
          errors.push(
            validations?.lengthValidation?.errorMessage
              ? String(validations?.lengthValidation?.errorMessage)
              : 'Max Length Validation Failed',
          );
        }

        if (errors.length > 0) {
          throw new yup.ValidationError(errors.join(';'), value, inputName);
        }

        return true;
      },
    }),
  };
};

const getYupNumericInputValidation = (input: ITacticMetadataDTO) => {
  const inputName = String(input.id);
  return {
    [inputName]: yup.number().test({
      name: 'customValidation',
      test: function (value) {
        const { validations } = input as ITacticMetadataDTO;
        const errors: string[] = [];

        if (input.required && !value) {
          errors.push('Required Input');
          throw new yup.ValidationError(errors.join(';'), value, inputName);
        }

        const isMinValueValid =
          value && validations?.numericValidation?.minValue ? value > validations?.numericValidation?.minValue : true;

        const isMaxValueValid =
          value && validations?.numericValidation?.maxValue ? value < validations?.numericValidation?.maxValue : true;

        if (!isMinValueValid) {
          errors.push(
            validations?.numericValidation?.errorMessage
              ? String(validations?.numericValidation?.errorMessage)
              : 'Min Value Validation Failed',
          );
        }

        if (!isMaxValueValid) {
          errors.push(
            validations?.numericValidation?.errorMessage
              ? String(validations?.numericValidation?.errorMessage)
              : 'Max Value Validation Failed',
          );
        }

        if (errors.length > 0) {
          throw new yup.ValidationError(errors.join(';'), value, inputName);
        }

        return true;
      },
    }),
  };
};

const getYupSelectionInputValidation = (input: ITacticMetadataDTO) => {
  const inputName = String(input.id);
  return {
    [inputName]: yup.string().test({
      name: 'customValidation',
      test: function (value) {
        const errors: string[] = [];

        if (input.required && !value) {
          errors.push('Required Input');
          throw new yup.ValidationError(errors.join(';'), value, inputName);
        }

        return true;
      },
    }),
  };
};

const getYupCheckBoxListInputValidation = (input: ITacticMetadataDTO) => {
  const inputName = String(input.id);
  return {
    [inputName]: yup.object().test({
      name: 'customValidation',
      test: function (value) {
        const errors: string[] = [];

        if (input.required && !Object.keys(value).some((val) => value[val] === true)) {
          errors.push('Required Input');
          throw new yup.ValidationError(errors.join(';'), value, inputName);
        }

        return true;
      },
    }),
  };
};

const getYupSingleOptionCheckBoxInputValidation = (input: ITacticMetadataDTO) => {
  const inputName = String(input.id);
  return {
    [inputName]: yup.boolean(),
  };
};

const getYupRadioBoxInputValidation = (input: ITacticMetadataDTO) => {
  const inputName = String(input.id);
  return {
    [inputName]: yup.string().test({
      name: 'customValidation',
      test: function (value) {
        const errors: string[] = [];

        if (input.required && !value) {
          errors.push('Required Input');
          throw new yup.ValidationError(errors.join(';'), value, inputName);
        }

        return true;
      },
    }),
  };
};

const getYupFileInputValidation = (input: ITacticMetadataDTO) => {
  const inputName = String(input.id);
  return {
    [inputName]: yup.mixed().test({
      name: 'customValidation',
      test: function (value) {
        const { validations } = input as ITacticMetadataDTO;
        const errors: string[] = [];

        if (input.required && !value) {
          errors.push('Required Input');
          throw new yup.ValidationError(errors.join(';'), value, inputName);
        }

        const isMaxSizeValid =
          value && validations?.fileValidation?.size
            ? (value as File).size < validations?.fileValidation?.size * MB_TO_BITES
            : true;

        const isFormatValid =
          value && validations?.fileValidation?.format
            ? getIsFormatValid((value as File).type, validations?.fileValidation?.format)
            : true;

        if (!isMaxSizeValid) {
          errors.push(
            validations?.fileValidation?.errorMessage
              ? String(validations?.fileValidation?.errorMessage)
              : 'File Size Too Large',
          );
        }

        if (!isFormatValid) {
          errors.push(
            validations?.fileValidation?.errorMessage
              ? String(validations?.fileValidation?.errorMessage)
              : 'File Format Not Supported',
          );
        }

        if (errors.length > 0) {
          throw new yup.ValidationError(errors.join(';'), value, inputName);
        }

        return true;
      },
    }),
  };
};

const getIsFormatValid = (format: string, allowedFormats: string): boolean => {
  const splitFormats = allowedFormats.replace(/\s/g, '').toLowerCase().split(',');
  return !!splitFormats.find(
    (allowedFormat) => allowedFormat === format?.toLowerCase() || allowedFormat === format?.split('/')[1].toLowerCase(),
  );
};

const getYupAssetFileInputValidation = (input: ITacticMetadataDTO) => {
  const inputName = String(input.id);
  return {
    [inputName]: yup.string().test({
      name: 'customValidation',
      test: function (value) {
        const errors: string[] = [];

        if (input.required && !value) {
          errors.push('Required Input');
          throw new yup.ValidationError(errors.join(';'), value, inputName);
        }

        if (errors.length > 0) {
          throw new yup.ValidationError(errors.join(';'), value, inputName);
        }

        return true;
      },
    }),
  };
};
const getYupProductInputValidation = (input: ITacticMetadataDTO) => {
  const inputName = String(input.id);
  return {
    [inputName]: yup
      .array()
      .min(input.required ? 1 : 0, 'A product must be selected')
      .test({
        name: 'customValidation',
        test: function (value) {
          const errors: string[] = [];

          if (input.required && !value) {
            errors.push('Required Input');
            throw new yup.ValidationError(errors.join(';'), value, inputName);
          }

          if (errors.length > 0) {
            throw new yup.ValidationError(errors.join(';'), value, inputName);
          }

          return true;
        },
      }),
  };
};
