import { ISingleCompanyItem } from '../../interfaces/forms/brands';
import { retailerAxios } from '../axiosConfig';
import { IRequestDto } from '../requestDtoInterface';

export const getChannelPartnerGeneralInfo = (
  channelId: string | undefined,
): Promise<IRequestDto<ISingleCompanyItem>> => {
  return retailerAxios.get(`/channel-partner-account/${channelId}/general-info`);
};

export const getChannelPartnerBillingInfo = (
  channelId: string | undefined,
): Promise<IRequestDto<ISingleCompanyItem>> => {
  return retailerAxios.get(`/channel-partner-account/${channelId}/billing-info`);
};

export const getChannelPartnerUsers = (channelPartnerId: string | undefined) => {
  return retailerAxios.get(`/channel-partner-account/${channelPartnerId}/users`);
};
