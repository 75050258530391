import { FC } from 'react';

import Loader from '#/components/common/loader/loader';
import ProductsFeed from '#/components/features/campaigns/instances/channelsAndTactics/productsFeed/ProductsFeed';

import { useGetProductFeedFiltersQuery } from '../../../../../../store/api/products/products';

interface ProductsFeedContainerProps {
  upnCodesViewInstanceId: string;
}

const ProductsFeedContainer: FC<ProductsFeedContainerProps> = ({ upnCodesViewInstanceId }) => {
  const { isLoading: filterDataIsLoading } = useGetProductFeedFiltersQuery({});

  if (filterDataIsLoading) return <Loader />;

  return <ProductsFeed upnCodesViewInstanceId={upnCodesViewInstanceId} />;
};

export default ProductsFeedContainer;
