import { Box, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDataCy } from '#/hooks/useDataCy';

import {
  getInstanceBrandOverviewLegalTerms,
  getInstanceOverviewLegalTerms,
} from '../../../../../api/campaign/campaigns';
import { USER_PERMISSIONS } from '../../../../../constants/permissions/userPermissions';
import { getUserHasPermission } from '../../../../../constants/permissions/utils';
import { THEME } from '../../../../../constants/theme/constants';
import { useOverviewEditLegal } from '../../../../../hooks/forms/campaigns/use-overview-edit-legal';
import { useRequest } from '../../../../../hooks/useRequest';
import {
  additionalTermsAndConditionsEditModeTextAreaSx,
  additionalTermsAndConditionsReadOnlyModeTextAreaSx,
  upperCaseSx,
  useCampaignOverviewLegalStyles,
} from '../../../../campaigns/CampaignOverviewLegal.style';
import { DeprecatedTopSection } from '../../../../campaigns/topSection/DeprecatedTopSection';
import SingleDownloadUpload from '../../../../common/downloadUpload/SingleDownloadUpload';
import Loader from '../../../../common/loader/loader';
import { TypographyWithFontFamily } from '../../../../common/typography/TypographyWithFontFamily';

const CampaignInstanceLegal = () => {
  const { instanceId } = useParams();
  const classes = useCampaignOverviewLegalStyles();
  const generateDataCy = useDataCy();

  const [editMode, setEditMode] = useState(false);

  const hasBrandPermission = getUserHasPermission(USER_PERMISSIONS.BRAND_CAMPAIGN_INSTANCE_OVERVIEW_LEGAL_TERMS_READ);
  const getLegalData = hasBrandPermission ? getInstanceBrandOverviewLegalTerms : getInstanceOverviewLegalTerms;
  const getCampaignLegalAction = useMemo(() => getLegalData(instanceId), [editMode]);
  const { response, loading } = useRequest(getCampaignLegalAction);

  const { watch, errors, register, reset, setValue, onSubmit } = useOverviewEditLegal(() => setEditMode(false));
  const { additionalTerms, termAndConditionsFileId, customTermAndConditionsFileId } = response?.data || {};

  useEffect(() => {
    reset({
      additionalTerms: additionalTerms,
      customTermAndConditionsFileId: customTermAndConditionsFileId,
      termAndConditionsFileId: termAndConditionsFileId,
    });
  }, [additionalTerms, customTermAndConditionsFileId, termAndConditionsFileId, editMode]);

  if (loading) return <Loader />;

  return (
    <>
      <form onSubmit={onSubmit}>
        <Box className={classes.legalContainer} data-cy={generateDataCy('box', 'CampaignOverviewLegal')}>
          <DeprecatedTopSection
            editMode={editMode}
            requiredPermission={USER_PERMISSIONS.CAMPAIGN_OVERVIEW_LEGAL_WRITE}
            sectionName="Legal"
            setEditMode={setEditMode}
          />
          <Box className={classes.legalContent}>
            <Box className={classes.legalFiles}>
              <Box>
                <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} sx={upperCaseSx} variant="Label_Base">
                  General Terms & Conditions
                </TypographyWithFontFamily>
                <SingleDownloadUpload
                  editMode={editMode}
                  fileId={watch('termAndConditionsFileId')?.toString()}
                  registerName="termAndConditionsFileId"
                  setValue={setValue}
                  uploadLink={`/campaign/legal-terms`}
                />
              </Box>
              <Box>
                <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} sx={upperCaseSx} variant="Label_Base">
                  Custom Terms & Conditions
                </TypographyWithFontFamily>
                <SingleDownloadUpload
                  editMode={editMode}
                  fileId={watch('customTermAndConditionsFileId')?.toString()}
                  registerName="customTermAndConditionsFileId"
                  setValue={setValue}
                  uploadLink={`/campaign/custom-legal-terms`}
                />
              </Box>
            </Box>
            <Box className={classes.additionalTermsContainer}>
              {/* right side */}
              <Box>
                <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} sx={upperCaseSx} variant="Label_Base">
                  Additional Terms (Optional)
                </TypographyWithFontFamily>
                <TextField
                  date-cy="Additional Terms Optional"
                  helperText={errors.additionalTerms?.message?.toString()}
                  {...register('additionalTerms')}
                  disabled={!editMode}
                  fullWidth
                  multiline
                  rows={6}
                  sx={
                    editMode
                      ? additionalTermsAndConditionsEditModeTextAreaSx
                      : additionalTermsAndConditionsReadOnlyModeTextAreaSx
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default CampaignInstanceLegal;
