import Loader from '#/components/common/loader/loader';
import { CampaignsChannelPartner } from '#/components/features/campaigns/Campaigns/CampaignsChannelPartner';

import { useGetCampaignsChannelPartnerFilterQuery } from '#/store/api/campaigns/campaigns';

export const CampaignsChannelPartnerContainer = () => {
  const { isLoading: filterDataIsLoading } = useGetCampaignsChannelPartnerFilterQuery({});

  if (filterDataIsLoading) return <Loader />;

  return <CampaignsChannelPartner />;
};
