import * as React from 'react';
import { Dispatch, ReactElement, SetStateAction } from 'react';

import { ButtonVariantOptions } from '#/components/common/button/NewButton.type';
import { THEME } from '#/constants/theme/constants';

export interface ITableColumnsProps<T> {
  fieldName: keyof T | null;
  align: TableColumnAlign;
  type: TableCellTypes;
  filtrable?: boolean;
  sortable?: boolean;
  sortLookUpFieldName?: string;
  isKey?: boolean;
  element?: React.FC<React.PropsWithChildren<{ column: ITableColumnsProps<T>; rowData: T }>>;
  textFormatter?: (text: string | number) => string;
  referenceAction?: (text: string) => void;
  itemClass?: string;
  actionElementIdentifier?: string;
  actionElementSecondIdentifier?: string;
  actionElementThirdIdentifier?: string;
  actionElementFourthIdentifier?: string;
  selectOptions?: string[];
  selectedValue?: string;
  getSelectedValue?: (itemIdentifier: string) => string;
  linkable?: boolean;
  baseUrl?: string;
  composeUrl?: (itemIdentifier: string, urlIdentifier?: string) => string;
  urlExtraParams?: IUrlExtraParams;
  urlIdentifier?: string;
  customHead?: string;
  checkBoxColor?: string;
  showRadioButton?: boolean;
  checkBoxAction?: (isChecked: boolean, itemIdentifier: string, actionElementSecondIdentifier: string) => void;
  checkAllAction?: (isChecked: boolean) => void;
  hasCheckAll?: boolean;
  checkBoxLoading?: boolean;
  indeterminateCheckAll?: boolean;
  checkAllValue?: boolean;
  checkFromList?: string[];
  actionButtonText?: string;
  actionButtonTextFromMapping?: string;
  moreActions?: IMoreActions[];
  iconsActions?: IIconsActions[];
  hide?: boolean;
  hideElementIfNull?: boolean;
  tooltipText?: string;
  tooltipFromProp?: string;
  headerTooltip?: string;
  setEditableTableValues?: (elementId: string, value: string, columnIdentifier: string) => void;
  columnsToAggregate?: string[];
  hideTableHead?: boolean;
  //@FIXME - we should implement this in a better way
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCustomElement?: (value: any, secondVal?: any, thirdVal?: any, fourthVal?: any) => ReactElement | null;
}

export interface IMoreActions {
  name?: string;
  action?: (itemIdentifier: string) => void;
}

export interface IIconsActions {
  icon?: string;
  action?: (itemIdentifier: string) => void;
  classes?: {
    container?: string;
    icon?: string;
    button?: string;
  };
  text?: string;
  isButton?: boolean;
  buttonVariant?: ButtonVariantOptions;
  customIdentifier?: string;
  hideByProp?: string;
  hideByPropValue?: string;
}

export interface ISortProps {
  column: string;
  order: string;
}

export interface ISortStateProps {
  sortParams: ISortProps;
  setSortParams: Dispatch<SetStateAction<ISortProps>>;
}

export interface IUrlExtraParams {
  [key: string]: string;
}

export enum FilterOrders {
  ASC = 'asc',
  DESC = 'desc',
}

export enum TableColumnAlign {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum TableCellTypes {
  STRING = 'string',
  ELEMENT = 'element',
  ACTION = 'action',
  CHECKBOX = 'checkbox',
  MORE_ACTIONS = 'more_actions',
  ICONS_ACTIONS = 'icons_actions',
  EDITABLE_INPUT = 'editable_input',
  EDITABLE_DATE_PICKER = 'editable_date_picker',
  AGGREGATE_COLUMNS = 'aggregate_columns',
  CUSTOM_ELEMENT = 'custom_element',
  REACT_TABLE_DATA_ELEMENT = 'react_table_data_element',
}

export enum EditableCellTypes {
  INPUT = 'input',
  DATE_PICKER = 'date_picker',
}

export const StatusColors = {
  PENDING: THEME.PALETTES.STATUS.PENDING,
  REJECTED: THEME.PALETTES.STATUS.REJECTED,
  CANCELLED: THEME.PALETTES.STATUS.CANCELLED,
  CANCELED: THEME.PALETTES.STATUS.CANCELED,
  COMPLETED: THEME.PALETTES.STATUS.COMPLETED,
  COMPLETE: THEME.PALETTES.STATUS.COMPLETED,
  ACTIVE: THEME.PALETTES.STATUS.ACTIVE,
  CREATED: THEME.PALETTES.STATUS.PENDING,
  BRAND_APPLY: THEME.PALETTES.STATUS.PENDING,
  BRAND_DECLINE: THEME.PALETTES.STATUS.REJECTED,
  RETAILER_DECLINE: THEME.PALETTES.STATUS.REJECTED,
  BRAND_ACCEPT: THEME.PALETTES.STATUS.ACTIVE,
  BRAND_REQUEST_REVISION: THEME.PALETTES.STATUS.PENDING,
  RETAILER_ACCEPT: THEME.PALETTES.STATUS.ACTIVE,
  BOOKED: THEME.PALETTES.STATUS.COMPLETED,
  CONFIRMED: THEME.PALETTES.STATUS.COMPLETED,
  PENDING_REVIEW: THEME.PALETTES.STATUS.PENDING,
} as const;
