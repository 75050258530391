import { Box } from '@mui/system';
import classNames from 'classnames';
import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';
import { THEME } from '#/constants/theme/constants';

import { ICampaignRetailer } from '#/store/api/campaigns/campaignsInterfaces';

import { currencyFormatter, percentageFormatter } from '#/utils/textFormatters';
import { convertAllCapsUnderscoreStrings } from '#/utils/utils';

import useCloneCampaign from '../../../hooks/useCloneCampaign.hook';
import PermissionWrapper from '../../../utils/PermissionWrapper';
import OwnerSection from '../../Calendar/EventModal/OwnerSection';
import CampaignThumbnail from '../CampaignThumbnail/CampaignThumbnail';
import CommittedBrandsLink from '../CommittedBrandsLink/CommittedBrandsLink';
import LabelWithValue from '../LabelWithValue/LabelWithValue';
import NewButton from '../button/NewButton';
import { TypographyWithFontFamily } from '../typography/TypographyWithFontFamily';
import { styles, useCampaignDetailStyles } from './CampaignDetail.styles';

interface CampaignDetailRetailerProps {
  data: ICampaignRetailer;
}

const CampaignDetailRetailer: FC<CampaignDetailRetailerProps> = ({ data }) => {
  const navigate = useNavigate();

  const { loading, cloneCampaignHandler } = useCloneCampaign(data.campaignId);

  const navigateToCampaign = () => {
    navigate(`/campaigns/overview/${data.campaignId}`);
  };

  const classes = useCampaignDetailStyles();

  return (
    <Box>
      <CampaignThumbnail thumbnailId={data.thumbnailId} />
      <Box className={classes.row}>
        <TypographyWithFontFamily className={classes.campaignName} variant="H5_Base">
          <Link className={classNames(classes.links)} to={`/campaigns/overview/${data.campaignId}`}>
            {data.campaignName}
          </Link>
        </TypographyWithFontFamily>
      </Box>

      <Box className={classNames(classes.row, classes.topBorder)}>
        <LabelWithValue label={'STAGE:'} value={convertAllCapsUnderscoreStrings(data.stage)} />
        <LabelWithValue label={'STATUS:'} value={convertAllCapsUnderscoreStrings(data.status)} />
      </Box>

      <Box className={classes.row}>
        <LabelWithValue label={'MARKET DATES:'} value={`${data.startDate}-${data.endDate}`} />
        <LabelWithValue
          label={'DEADLINE:'}
          placeholder={'No submission deadline provided'}
          value={data.submissionDeadline}
        />
      </Box>

      <Box className={classes.row}>
        <Box className={classes.container}>
          <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} variant="Label_Base">
            COMMITTED BRANDS:
          </TypographyWithFontFamily>
          <CommittedBrandsLink
            brandsTargeted={data.brandsTargeted}
            campaignId={data.campaignId}
            committedBrands={data.committedBrands}
          />
        </Box>
        <LabelWithValue label={'CAMPAIGN GOAL:'} value={currencyFormatter(data.campaignGoal)} />
        <LabelWithValue label={'% OF GOAL:'} value={percentageFormatter(data.percentOfGoal)} />
      </Box>

      <Box className={classNames(classes.bottomSection, classes.topBorder)}>
        <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.USER_READ]}>
          <OwnerSection ownerId={data.ownerId} />
        </PermissionWrapper>
        <Box className={classes.buttonsSection}>
          <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.CAMPAIGN_CREATE]}>
            <NewButton
              loading={loading}
              onClick={cloneCampaignHandler}
              sx={styles.cloneButtonMargin}
              text="Clone"
              variant="Secondary"
            />
          </PermissionWrapper>
          <NewButton loading={false} onClick={navigateToCampaign} text="Open" variant="Secondary" />
        </Box>
      </Box>
    </Box>
  );
};
export default CampaignDetailRetailer;
