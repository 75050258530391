import { Box, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { getCalendarData } from '#/api/dataFeed';

import AlertError from '#/components/common/Alert/AlertError/AlertError';
import Loader from '#/components/common/loader/loader';
import CreativeSpecsSetting from '#/components/features/settings/dataAndCalendar/CreativeSpecsSetting';
import LegalTermsSetting from '#/components/features/settings/dataAndCalendar/LegalTermsSetting';
import { daysOfWeek } from '#/constants/daysOfWeek/daysOfWeek';
import { THEME } from '#/constants/theme/constants';

import { useRequest } from '#/hooks/useRequest';

import { IDayOfWeek } from '#/interfaces/settings/calendar';

import {
  usePutRetailerAccountFiscalYearGoalMutation,
  usePutRetailerAccountFiscalYearMutation,
  usePutRetailerAccountMarketingWeekStartMutation,
} from '#/store/api/retailerAccount/retailerAccount';

import CalendarGreyIcon from '../../../../assets/CalendarGreyIcon.svg';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { FiscalYearGoal } from './FiscalYearGoal';
import { upperCaseHeader, useGeneralSettingStyle } from './GeneralSettings.style';

const GeneralSettings = () => {
  const [
    putRetailerAccountFiscalYear,
    { isLoading: isPutRetailerAccountFiscalYearLoading, error: putRetailerAccountFiscalYearError },
  ] = usePutRetailerAccountFiscalYearMutation();
  const [
    putRetailerAccountMarketingWeekStart,
    { isLoading: isPutRetailerAccountMarketingWeekStartLoading, error: putRetailerAccountMarketingWeekStartError },
  ] = usePutRetailerAccountMarketingWeekStartMutation();
  const [_, { error: putRetailerAccountFiscalYearGoalError }] = usePutRetailerAccountFiscalYearGoalMutation({
    fixedCacheKey: 'putRetailerAccountFiscalYearGoal',
  });

  const error =
    putRetailerAccountFiscalYearError ||
    putRetailerAccountMarketingWeekStartError ||
    putRetailerAccountFiscalYearGoalError;

  const [dayOfWeek, setDayOfWeek] = useState<string | null>(null);
  const [fiscalDate, setFiscalDate] = useState<Dayjs | null>(null);
  const classes = useGeneralSettingStyle();

  const getCalendarDataAction = useMemo(() => getCalendarData(), []);
  const { response: calendarResponse } = useRequest(getCalendarDataAction);

  useEffect(() => {
    setDayOfWeek(calendarResponse?.data.retailerPeriods || null);
    setFiscalDate(dayjs(calendarResponse?.data.fiscalYear, 'YYYY-MM-DD'));
  }, [calendarResponse]);

  useEffect(() => {
    dayOfWeek &&
      !isEqual(dayOfWeek, calendarResponse?.data.retailerPeriods) &&
      putRetailerAccountMarketingWeekStart({ weekStartDay: dayOfWeek });
    fiscalDate?.isValid() &&
      !isEqual(fiscalDate?.toDate(), dayjs(calendarResponse?.data.fiscalYear).toDate()) &&
      putRetailerAccountFiscalYear({ fiscalYearStart: fiscalDate });
  }, [dayOfWeek, fiscalDate]);

  return (
    <Box>
      <AlertError error={error} />
      <Box className={classes.dateContainer}>
        <TypographyWithFontFamily variant="H5_Base">Calendar</TypographyWithFontFamily>
        <Box className={classes.dateContent}>
          <Box>
            <TypographyWithFontFamily
              color={THEME.PALETTES.TEXT.Gray}
              gutterBottom
              sx={upperCaseHeader}
              variant="Label_Base"
            >
              Fiscal Year Start:
            </TypographyWithFontFamily>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                  className={classes.datePicker}
                  format="MM/DD"
                  loading={isPutRetailerAccountFiscalYearLoading}
                  onChange={(newFiscalDate) => setFiscalDate(newFiscalDate)}
                  openTo="month"
                  slots={{
                    openPickerIcon: () => <img alt="Calendar Icon" src={CalendarGreyIcon} />,
                  }}
                  value={fiscalDate}
                  views={['month', 'day']}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>

          <Box>
            <TypographyWithFontFamily
              color={THEME.PALETTES.TEXT.Gray}
              gutterBottom
              sx={upperCaseHeader}
              variant="Label_Base"
            >
              Marketing Week Start Day:
            </TypographyWithFontFamily>
            {isPutRetailerAccountMarketingWeekStartLoading ? (
              <Loader />
            ) : (
              <Stack direction="row" spacing={2}>
                {daysOfWeek.map((day: IDayOfWeek) => (
                  <Box
                    key={day.value}
                    className={`${classes.dayOfWeek} ${day.value === dayOfWeek ? classes.selectedDayOfWeek : ''}`}
                    onClick={() => setDayOfWeek(day.value)}
                  >
                    {day.label}
                  </Box>
                ))}
              </Stack>
            )}
          </Box>
          <FiscalYearGoal goal={calendarResponse?.data.fiscalYearGoal || 0} />
        </Box>
      </Box>
      <CreativeSpecsSetting />
      <LegalTermsSetting />
    </Box>
  );
};

export default GeneralSettings;
