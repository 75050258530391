import ChannelPartnerRetailer from '#/components/features/partners/channelPartners/ChannelPartnerRetailer';

import { useGetChanelPartnerAccountAllFilteredFiltersQuery } from '#/store/api/partners/promote';

import Loader from '../../../common/loader/loader';

const ChannelPartnerRetailerContainer = () => {
  const { isLoading: filterDataIsLoading } = useGetChanelPartnerAccountAllFilteredFiltersQuery({});

  if (filterDataIsLoading) return <Loader />;

  return <ChannelPartnerRetailer />;
};
export default ChannelPartnerRetailerContainer;
