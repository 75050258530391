import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { user } from '#/store/slices';

import { useSnackbarError } from '../components/common/snackbar/useSnackbarError';
import { IAxiosError } from '../interfaces/error/error';

// these will be moved into interfaces file once the new folder structure is added
interface IRequestState<T> {
  loading: boolean;
  error?: IError | null;
  response?: T | null;
}

interface IError {
  status: number;
  statusText: string;
  name: string;
  message: string;
}

// setRequest will be used when pagination will be implemented
export function useRequest<T>(
  initialRequest?: Promise<T> | T,
): IRequestState<T> & { setRequest: (request: Promise<T> | T) => void } {
  const [request, setRequest] = useState(initialRequest);
  const { setError } = useSnackbarError();
  const [result, setResult] = useState<IRequestState<T>>({
    loading: true,
    error: null,
    response: null,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setResult({
      ...result,
      loading: true,
    });
    setRequest(initialRequest);
  }, [initialRequest]);

  useEffect(() => {
    async function loadData(): Promise<void> {
      try {
        const response = await request;
        setResult({ response, error: null, loading: false });
      } catch (error) {
        const { message, response, name } = error as IAxiosError;
        const { status, statusText } = response;
        const errorBody = {
          status,
          statusText,
          message,
          name,
        };
        if (status === 401) {
          dispatch(user.actions.logout());
        }
        setError(error);
        setResult({ response: null, error: errorBody as IError, loading: false });
      }
    }

    loadData();
  }, [request]);

  return { ...result, setRequest };
}
