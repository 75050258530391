import { Box } from '@mui/material';
import { useState } from 'react';

import SearchFilters from '#/components/common/SearchFilters/SearchFilters';
import useSearchFilters from '#/components/common/SearchFilters/useSearchFilters.hook';
import AddNewCompanyForm from '#/components/common/forms/companies/AddNewCompanyForm/AddNewCompanyForm';
import Loader from '#/components/common/loader/loader';
import Modal from '#/components/common/modal/Modal';
import { useCompaniesStyles } from '#/components/features/partners/companies/Companies.styles';
import { getCompaniesTableColumns } from '#/components/features/partners/companies/Companies.tables';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import { useGetCompaniesRetailerFilterQuery, useGetCompaniesRetailerQuery } from '#/store/api/company/company';

import PermissionWrapper from '#/utils/PermissionWrapper';

import PlusIcon from '../../../../assets/Plus.svg';
import NewButton from '../../../common/button/NewButton';
import Card from '../../../common/card/card';
import { Table } from '../../../common/table/tableComponent/Table';

const Companies = () => {
  const classes = useCompaniesStyles();
  const [open, setOpen] = useState(false);

  const { data: filterData, isError: filterDataIsError } = useGetCompaniesRetailerFilterQuery({});

  const { actions, state } = useSearchFilters(undefined, true, filterData?.filters);

  const { data, isError, isFetching, isLoading } = useGetCompaniesRetailerQuery({
    pageNumber: state.pageNumber,
    numberOfRows: state.numberOfElementsPerPage,
    searchQuery: state.searchQuery,
  });

  const companyTable = getCompaniesTableColumns(filterData ? filterData.filters : []);
  const handleOpenModal = () => setOpen(!open);

  if (isLoading) return <Loader />;

  return (
    <>
      <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.PARTNERS_COMPANIES_ADD_COMPANY_WRITE]}>
        <Card>
          <Box className={classes.addBrandModal}>
            <NewButton icon={PlusIcon} iconPosition="left" onClick={handleOpenModal} text="Add Company" />
          </Box>
        </Card>
      </PermissionWrapper>
      <SearchFilters
        columnNames={filterData?.filters}
        currentIndex={data?.currentIndex}
        filterDataIsError={filterDataIsError}
        hideViewMode
        isError={isError}
        isFetching={isFetching}
        isNoResults={!data || data.companies.length === 0}
        totalPages={data?.totalPages}
        totalResults={data?.totalResults}
        {...state}
        {...actions}
      >
        <Table
          columns={companyTable}
          data={data?.companies || []}
          sortProps={{
            sortParams: state.sortParams,
            setSortParams: actions.setSortParam,
          }}
        />
      </SearchFilters>
      <Modal
        content={<AddNewCompanyForm handleOpenModal={handleOpenModal} />}
        handleCloseModal={handleOpenModal}
        icon={PlusIcon}
        modalTitle="Add Company"
        open={open}
        size="large"
      />
    </>
  );
};
export default Companies;
