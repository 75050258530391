export const formatLabel = (label: string | undefined) => {
  if (!label) {
    return '';
  }
  const result = label.replace(/[A-Z]/g, (match) => ' ' + match.toLowerCase());
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const textFormatter = (text: string | number) => {
  return String(text).split('_').join(' ');
};
