import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';
import { getColorByStatus } from '../../../utils/utils';

export const useCampaignsSingleHeaderStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: THEME.REM_SPACES[1],
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: THEME.REM_SPACES[1],
      marginRight: THEME.REM_SPACES[1],
    },
    icon: {
      mr: 2,
      borderRadius: '50%',
      padding: '7px',
      background: '#fff',
      width: '41px',
      height: '41px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    ml1half: {
      '&&': {
        marginLeft: THEME.REM_SPACES['1'],
        display: 'flex',
        flexDirection: 'row',
        flexGrow: '1',
        textWrap: 'nowrap',
      },
    },
    tittleColor: {
      '&&': {
        color: THEME.PALETTES.TEXT.Action_Secondary,
      },
    },
    flexWithCenterAlignItems: {
      display: 'flex',
      alignItems: 'center',
    },
    campaignCustomStyle: {
      '&&': {
        fontSize: '1.3125rem',
        color: THEME.PALETTES.UI.Neutral['900'],
        fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
        lineHeight: '1.1',
        display: 'flex',
        alignSelf: 'center',
      },
    },
    campaignNameCustomStyle: {
      '&&': {
        fontSize: '1.3125rem',
        fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
        lineHeight: '1.1',
        display: 'flex',
        alignSelf: 'center',
        cursor: 'pointer',
        color: THEME.PALETTES.TEXT.Action_Secondary,
      },
    },
    brandSection: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',

      marginLeft: THEME.REM_SPACES['0.5'],
    },
    brandName: {
      alignSelf: 'center',
    },
    buttonsContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: THEME.REM_SPACES[1],
    },
  }),
);

export const styles = {
  promoteButton: { width: THEME.REM_SPACES[10], marginLeft: THEME.REM_SPACES[1] },
  cloneButton: { width: THEME.REM_SPACES[10] },
};

export const statusSx = (status: string | undefined) => {
  return {
    fontSize: '0.625rem',
    background: getColorByStatus(status),
    borderRadius: '15px',
    fontWeight: THEME.FONT_WEIGHTS.MEDIUM,
    padding: '0.375rem 0.563rem 0.375rem 0.563rem',
    ml: 2,
    display: 'flex',
    color: THEME.PALETTES.TEXT.Light,
    alignItems: 'center',
  };
};

export const CompanyLogoSX = {
  color: THEME.PALETTES.UI.Primary['900'],
  background: THEME.PALETTES.TEXT.Light,
  fontWeight: THEME.FONT_WEIGHTS.BOLD,
  border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
};
