import { Box } from '@mui/material';
import { useParams } from 'react-router';
import { Outlet } from 'react-router-dom';

import { useGetTaskQuery } from '#/store/api/tasks/tasks';

import CampaignIcon from '../../../assets/Megaphone.svg';
import Loader from '../../common/loader/loader';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import { statusSx } from '../campaigns/campaignsSingleHeader.styles';
import { useTasksSingleHeaderStyles } from './TasksSingleHeader.styles';

const TasksSingleHeader = () => {
  const { aggregateId } = useParams();
  const { data, isLoading } = useGetTaskQuery({ taskId: aggregateId });

  const classes = useTasksSingleHeaderStyles();

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  const { status, displayName } = data;

  return (
    <Box className={classes.section}>
      <Box className={classes.container}>
        <Box className={classes.headerContainer}>
          <Box className={classes.icon}>
            <img alt="CampaignIcon" src={CampaignIcon} />
          </Box>
          <Box className={classes.ml1half}>
            <TypographyWithFontFamily className={classes.tittleColor} variant="H6_Base">
              Tasks
            </TypographyWithFontFamily>
            <Box className={classes.flexWithCenterAlignItems}>
              <TypographyWithFontFamily className={classes.campaignCustomStyle}>{displayName}</TypographyWithFontFamily>
              <Box sx={statusSx(status)}>{status}</Box>
              <TypographyWithFontFamily className={classes.ml1half} variant="Tag_Base">
                ID: {aggregateId}
              </TypographyWithFontFamily>
            </Box>
          </Box>
        </Box>
      </Box>
      <Outlet />
    </Box>
  );
};

export default TasksSingleHeader;
