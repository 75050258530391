import { Box } from '@mui/material';
import React, { FC, useState } from 'react';
import { FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import EditIcon from '#/assets/Edit.svg';

import { useAssetProductStyles } from '#/components/campaigns/campaignAssets/assetsProduct/AssetProduct.style';
import AssetProductModal from '#/components/campaigns/campaignAssets/assetsProduct/AssetProductModal';
import NewButton from '#/components/common/button/NewButton';
import TextField from '#/components/common/inputs/TextField/TextField';
import ProductModal from '#/components/common/modal/ProductModal';
import { errorTextSx } from '#/components/common/tacticMetadataForm/TacticMetadataForm.style';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';

import { ICampaignInstanceProductsListSingle } from '#/interfaces/campaigns/campaigns';
import { ITacticMetadataDTO, TacticMetadataForm } from '#/interfaces/tacticMetadata/tacticMetadata';

import theme from '#/utils/theme';

interface IAssetProductProps {
  instanceTacticId: string;
  editMode: boolean;
  setValue: UseFormSetValue<TacticMetadataForm>;
  input: ITacticMetadataDTO;
  watch: UseFormWatch<TacticMetadataForm>;
  errors: FieldErrors<TacticMetadataForm>;
}
const AssetProduct: FC<IAssetProductProps> = ({ instanceTacticId, editMode, input, watch, setValue, errors }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedProduct = watch(String(input.id));
  const classes = useAssetProductStyles();
  const existingErrors = errors[String(input.id)]?.message || '';

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box>
          <TypographyWithFontFamily
            className={classes.labelUppercase}
            color={theme.custom.PALETTES.TEXT.Gray}
            variant="Label_Base"
          >
            {input.label} {input.required ? '*' : ''}
          </TypographyWithFontFamily>
          <TypographyWithFontFamily gutterBottom variant={'Label_Base'}>
            {input.helperText}
          </TypographyWithFontFamily>
          <TextField
            dataCy="product-selector"
            disabled
            placeholder="No Product Selected"
            value={selectedProduct?.[0]?.universalProductCode || ''}
          />
        </Box>
        {editMode && (
          <NewButton icon={EditIcon} onClick={() => setIsOpen(true)} text="Select Product" variant="Ghost" />
        )}
      </Box>
      <Box className={classes.errorContainer}>
        <TypographyWithFontFamily sx={errorTextSx} variant="Default_Base">
          {String(existingErrors)}
        </TypographyWithFontFamily>
      </Box>
      <ProductModal
        content={
          <AssetProductModal
            instanceTacticId={instanceTacticId}
            selectProduct={(newValue: ICampaignInstanceProductsListSingle[]) => setValue(String(input.id), newValue)}
            selectedProduct={watch(String(input.id), [])}
          />
        }
        handleCloseModal={() => setIsOpen(false)}
        modalTitle="Select a product"
        open={isOpen}
        size="wizard"
      />
    </Box>
  );
};

export default AssetProduct;
