import { Grid } from '@mui/material';
import { flow } from 'fp-ts/function';
import { useMemo } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types';

import TextField, { NonEmptyString } from '#/components/common/inputs/TextField/TextField';

import { createFormatterWithReverse, extractNumber, numberFormatter } from '#/utils/textFormatters';

import { ITacticMetadataDTO, TacticMetadataForm } from '../../../../interfaces/tacticMetadata/tacticMetadata';
import { InfoTooltip } from '../../../features/reports/InfoTooltip';
import { TypographyWithFontFamily } from '../../typography/TypographyWithFontFamily';
import { errorTextSx, useTacticMetadataFormStyle } from '../TacticMetadataForm.style';

interface IProps {
  register: UseFormRegister<TacticMetadataForm>;
  setValue: UseFormSetValue<TacticMetadataForm>;
  errors: FieldErrors<TacticMetadataForm>;
  input: ITacticMetadataDTO;
  helperTextSubtitle?: boolean;
  editMode?: boolean;
}

export const NumericInput = ({ setValue, register, errors, input, helperTextSubtitle = false, editMode }: IProps) => {
  const existingErrors = errors[String(input.id)]?.message || '';

  const type = useMemo(() => {
    if (input.label.length > 0) {
      return {
        label: (input.label + (input.required ? ' *' : '')) as NonEmptyString<typeof input.label>,
      };
    }

    return {
      dataCy: String(input.id),
    };
  }, [input.label, input.id]);
  const classes = useTacticMetadataFormStyle();

  return (
    <Grid container spacing={{ xs: '1rem' }} xs={12}>
      <Grid className={helperTextSubtitle ? classes.numberHelperTextSubtitleContainer : ''} item xs={9}>
        <TextField
          disabled={input.isDisabled || !editMode}
          formHooks={{
            register: register(String(input.id)),
            setValue: (value) => setValue(String(input.id), value),
          }}
          formatters={{
            preview: (v) =>
              //@TODO currently in metadata form we only have "Number" as type, so we assume that is not a currency field
              numberFormatter(v, {
                maximumFractionDigits: 2,
              }),
            typing: createFormatterWithReverse(
              flow(extractNumber, (v) =>
                numberFormatter(v, {
                  maximumFractionDigits: 2,
                }),
              ),
              extractNumber,
            ),
          }}
          helperText={helperTextSubtitle ? input.helperText : ''}
          {...type}
        />
      </Grid>
      <Grid item xs={3}>
        {!helperTextSubtitle && input.helperText && <InfoTooltip text={input.helperText} />}
      </Grid>
      <Grid item xs={12}>
        <TypographyWithFontFamily sx={errorTextSx} variant="Default_Base">
          {String(existingErrors)}
        </TypographyWithFontFamily>
      </Grid>
    </Grid>
  );
};
