import theme from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

// retailer assets
export const containerBoxSx = {
  background: THEME.PALETTES.OTHER.White,
  borderRadius: THEME.REM_SPACES['1'],
  padding: THEME.REM_SPACES['1'],
};
export const overviewContainerBoxSx = {
  marginTop: theme.spacing(2),
  border: `1px solid ${theme.custom.PALETTES.BORDER.Extra_Light}`,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
};
export const fileBoxSx = {
  border: `1px solid ${THEME.PALETTES.UI.Neutral['200']}`,
  width: '31.25rem',
  display: 'flex',
  justifyContent: 'space-between',
  padding: THEME.REM_SPACES['0.5'],
  marginTop: THEME.REM_SPACES['1'],
  borderRadius: THEME.REM_SPACES['0.5'],
};
export const firstBoxSx = { display: 'flex', columnGap: THEME.REM_SPACES['0.5'] };
export const secondBoxSx = { display: 'flex', columnGap: THEME.REM_SPACES['0.25'] };
