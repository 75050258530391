import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import CollapseComponent from '#/components/common/CollapseComponent/CollapseComponent';
import CommunicationsSelectRecipientsTableSelected from '#/components/features/communications/tables/CommunicationsSelectRecipientsTableSelected';
import { BASE_PATH, COMMUNICATIONS_TABS } from '#/constants/general';
import { ROUTE_PATHS } from '#/constants/routing/routePaths';

import { useAppDispatch, useAppSelector } from '#/hooks';

import { communicationsPromote } from '#/store/slices';

import { useCampaignInvitationsStyles } from '../../../../campaignInvitations/CampaignInvitations.styles';
import NewButton from '../../../../common/button/NewButton';
import { TypographyWithFontFamily } from '../../../../common/typography/TypographyWithFontFamily';
import CommunicationsSelectedCampaigns from '../../shared/CommunicationsSelectedCampaigns';
import CustomCommunicationsModal from '../../shared/CustomCommunicationsModal';
import CommunicationsSelectRecipientsTable from '../../tables/CommunicationsSelectRecipientsTable';
import CommunicationsFilterRecipients from './CommunicationsFilterRecipients';

const CommunicationsSelectRecipients = () => {
  const classes = useCampaignInvitationsStyles();
  const navigate = useNavigate();
  const { selectedRecipients, selectedCampaigns } = useAppSelector((state) => state.communications);
  const { COMMUNICATIONS, PROMOTE_MARKETING, PROMOTE } = ROUTE_PATHS;
  const dispatch = useAppDispatch();

  const handleClick = (param: string) => {
    param === 'next' ? navigate(`${BASE_PATH}/${COMMUNICATIONS}/${PROMOTE_MARKETING}`) : navigate(-1);
  };

  useEffect(() => {
    if (selectedCampaigns.length === 0) {
      navigate(`${BASE_PATH}/${COMMUNICATIONS}/${PROMOTE}`);
    }
  }, []);

  return (
    <CustomCommunicationsModal
      activeTab={COMMUNICATIONS_TABS.COMMUNICATIONS_INVITE_RECIPIENTS}
      modalTitle="Invite Recipients"
    >
      <Stack className={classes.selectBrandsContainer} direction={'row'}>
        <Box className={classes.invitationDetailsContainer}>
          <CommunicationsFilterRecipients />
        </Box>
        <Box className={classes.selectBrandsOptionsContainer}>
          <Box className={classes.buttonWrap}>
            <NewButton
              className={classes.mr15}
              onClick={() => handleClick('back')}
              text="Back"
              type="button"
              variant="Secondary"
            />
            <NewButton
              disabled={!selectedRecipients.length}
              onClick={() => handleClick('next')}
              text="Next"
              type="button"
            />
          </Box>
          <CommunicationsSelectedCampaigns />
          {selectedRecipients.length !== 0 && (
            <CollapseComponent
              actionTitle={
                <Box className={classes.buttonWrapSpace}>
                  <TypographyWithFontFamily variant="H5_Base">
                    Selected Recipient(s) {selectedRecipients.length} *
                  </TypographyWithFontFamily>
                  <NewButton
                    onClick={() => dispatch(communicationsPromote.actions.clearSelectedRecipients())}
                    text="Clear selection"
                    variant="Ghost"
                  />
                </Box>
              }
              content={<CommunicationsSelectRecipientsTableSelected />}
            />
          )}
          <TypographyWithFontFamily className={classes.mt05} variant="H5_Base">
            Available Recipients for Selection
          </TypographyWithFontFamily>
          <CommunicationsSelectRecipientsTable />
        </Box>
      </Stack>
    </CustomCommunicationsModal>
  );
};

export default CommunicationsSelectRecipients;
