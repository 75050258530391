import { Box, Typography } from '@mui/material';
import React, { useRef } from 'react';

import { uploadJobFile } from '../../../../api/dataFeed';
import FileIcon from '../../../../assets/FileIcon.svg';
import { THEME } from '../../../../constants/theme/constants';
import { useGeneralSettingStyle } from './GeneralSettings.style';

export interface IUploadJobFileProps {
  jobId: string;
  refresh: () => unknown;
}
const UploadJobFile = ({ jobId, refresh }: IUploadJobFileProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleBrowseFile = () => {
    inputRef.current?.click();
  };
  const dopHandler = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (e.dataTransfer.items) {
      await uploadJobFile(jobId, e.dataTransfer.items[0].getAsFile());
    } else {
      await uploadJobFile(jobId, e.dataTransfer.files[0]);
    }
    refresh();
  };
  const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await uploadJobFile(jobId, e.target.files?.[0] || null);
    refresh();
  };
  const classes = useGeneralSettingStyle();

  return (
    <Box
      className={classes.uploadContainer}
      onClick={handleBrowseFile}
      onDragOver={dragOverHandler}
      onDrop={dopHandler}
    >
      <input ref={inputRef} className={classes.hiddenInput} onChange={(e) => handleFileChange(e)} type="file" />
      <img src={FileIcon} />
      <Typography>Drag & Drop to Upload File</Typography>
      <Typography color={THEME.PALETTES.TEXT.Action_Secondary} sx={{ textTransform: 'none' }} variant="button">
        Browse File
      </Typography>
    </Box>
  );
};

export default UploadJobFile;
