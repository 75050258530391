import { THEME } from '#/constants/theme/constants';

import { createStyles, makeStyles } from '#/utils/theme';

export const useConfirmationModalStyles = makeStyles(() =>
  createStyles({
    content: {
      width: '20rem',
      minHeight: '5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },

    addBrandMargin: {
      marginTop: '0.625rem',
    },
    dialogTitle: {
      '&&': {
        background: THEME.PALETTES.UI.Primary['900'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxHeight: '4rem',
      },
    },
    closeImg: {
      cursor: 'pointer',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    warning: {
      textAlign: 'center',
    },
  }),
);
