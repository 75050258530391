import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const useNewButtonStyles = makeStyles(() =>
  createStyles({
    textContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      fontSize: '0.75rem',
      fontWeight: THEME.FONT_WEIGHTS.BOLD,
    },
    text: {
      width: '100%',
      paddingLeft: THEME.REM_SPACES['0.25'],
      paddingRight: THEME.REM_SPACES['0.25'],
      textWrap: 'nowrap',
    },
    loading: {
      width: '100%',
      '&& svg': {
        color: THEME.PALETTES.UI.Primary.Base,
      },
    },
    Secondary: {
      width: '100%',
      '&& svg': {
        color: THEME.PALETTES.UI.Primary.Base,
      },
    },
    Primary: {
      width: '100%',
      '&& svg': {
        color: THEME.PALETTES.UI.Neutral['100'],
      },
    },
    Ghost: {
      width: '100%',
      '&& svg': {
        color: THEME.PALETTES.UI.Primary.Base,
      },
    },
  }),
);
