import * as React from 'react';
import { Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { FC } from 'react';

import { currencyFormatter } from '#/utils/textFormatters';

import { THEME } from '../../../../constants/theme/constants';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { useTacticsHeaderStyles } from './TacticsHeader.styles';

interface TacticsHeaderProps {
  tierName: string;
  tierBudget: string;
}

const TacticsHeader: FC<TacticsHeaderProps> = ({ tierName, tierBudget }) => {
  const classes = useTacticsHeaderStyles();

  const tooltip = (
    <TypographyWithFontFamily classNames={classes.tierName} color={THEME.PALETTES.TEXT.Light} variant="H5_Base">
      {tierName}
    </TypographyWithFontFamily>
  );

  return (
    <Box className={classes.tierHeader}>
      <Tooltip title={tooltip}>
        <Box>
          <TypographyWithFontFamily classNames={classes.tierName} variant="H3_Base">
            {tierName}
          </TypographyWithFontFamily>
        </Box>
      </Tooltip>
      <TypographyWithFontFamily variant="H3_Regular">{currencyFormatter(tierBudget)}</TypographyWithFontFamily>
    </Box>
  );
};

export default TacticsHeader;
