import { makeStyles, createStyles } from '#/utils/theme';

export const useSubmissionStyle = makeStyles(() =>
  createStyles({
    wrapper: {
      width: '200px',
    },
    boxTable: {
      border: '1px solid #ddd',
      padding: '20px',
      borderRadius: '6px',
      textAlign: 'center',
    },
    boxTable2: {
      marginTop: '30px',
      border: '1px solid #ddd',
      padding: '20px',
      borderRadius: '6px',
    },
    title: {
      '&&': {
        fontSize: '13px',
        color: '#777',
        fontWeight: 500,
      },
    },
    secondTitle: {
      '&&': {
        fontSize: '26px',
        color: '333',
        fontWeight: 600,
      },
    },
  }),
);
