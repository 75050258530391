import { createStyles, makeStyles } from '#/utils/theme';

export const useIntervalInputStyles = makeStyles((theme) =>
  createStyles({
    container: { display: 'flex', flexDirection: 'row', flexGrow: '1' },
    intervalPicker: {
      width: '50%',
      paddingLeft: theme.custom.REM_SPACES[1],
      paddingRight: theme.custom.REM_SPACES[1],
      display: 'flex',
      borderColor: 'white',
    },
    to: {
      alignSelf: 'center',
    },
  }),
);
