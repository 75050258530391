import { Box } from '@mui/material';
import classnames from 'classnames';

import CheckFalseIcon from '../../../../assets/CheckFalseIcon.svg';
import CheckTrueIcon from '../../../../assets/CheckTrueIcon.svg';
import { TiersProps } from '../../../../interfaces/dynamicRibbon/dynamicRibbon';
import TacticsGrid from '../common/TacticsGrid';
import TacticsHeader from '../common/TacticsHeader';
import { useDynamicTiersStyles } from './DynamicTiers.styles';

const Tiers = ({ tiers, setValue, watch, setSelectedTier }: TiersProps) => {
  const classes = useDynamicTiersStyles();

  return (
    <Box className={classes.container}>
      {tiers.map((tier) => {
        const isSelected = watch('tierId') === tier.tierId;
        const onSelectHandler = () => {
          if (setSelectedTier) {
            setSelectedTier(tier.tierId);
          }
          setValue('tierId', tier.tierId);
        };

        return (
          <Box
            key={tier.tierId}
            className={classnames(classes.tierContainer, isSelected && classes.tierContainerSelected)}
            onClick={onSelectHandler}
          >
            <Box className={classes.tierHeaderContainer}>
              <TacticsHeader tierBudget={tier.tierBudget} tierName={tier.tierName} />
              <img
                alt="tacticsCheckbox"
                className={classes.checkIcon}
                src={isSelected ? CheckTrueIcon : CheckFalseIcon}
              />
            </Box>
            <TacticsGrid tactics={tier.tierTactics} />
          </Box>
        );
      })}
    </Box>
  );
};

export default Tiers;
