import { Box } from '@mui/material';
import classNames from 'classnames';
import { useParams } from 'react-router';

import { useAssociatedAgenciesStyles } from '#/components/features/partners/brands/AssociatedAgencies/AssociatedAgencies.styles';
import getAssociatedAgenciesTables from '#/components/features/partners/brands/AssociatedAgencies/AssociatedAgencies.tables';

import { useGetAssociatedCompaniesQuery } from '#/store/api/company/company';

import ContactsIcon from '../../../../../assets/ContactsIcon.svg';
import Card from '../../../../common/card/card';
import { Table } from '../../../../common/table/tableComponent/Table';
import { TypographyWithFontFamily } from '../../../../common/typography/TypographyWithFontFamily';

const AssociatedAgencies = () => {
  const { brandId } = useParams();

  const tableColumns = getAssociatedAgenciesTables();

  const classes = useAssociatedAgenciesStyles();

  const { data: response } = useGetAssociatedCompaniesQuery({ companyId: brandId });

  if (!response) return null;

  return (
    <Card>
      <Box className={classNames(classes.associateWrap, classes.flexBetween)}>
        <Box className={classes.flexCenter}>
          <img alt="contacts" className={classes.mrImg} src={ContactsIcon} />
          <TypographyWithFontFamily variant="H3_Base">Associated Agencies</TypographyWithFontFamily>
        </Box>
      </Box>
      {response.length ? (
        <Table
          bodyCellClass={classes.bodyCellClass}
          columns={tableColumns}
          data={response}
          headCellClass={classes.headCellClass}
          headClass={classes.headClass}
          tableClass={classes.custom}
        />
      ) : (
        <Box>There are no associated agencies.</Box>
      )}
    </Card>
  );
};

export default AssociatedAgencies;
