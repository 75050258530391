import { Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import classnames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import { getUserTemporaryAvatar, postAvatar, saveLogo } from '#/api/avatar';
import CameraIconBlue from '#/assets/CameraIconBlue.svg';

import { useAppSelector } from '#/hooks';

import log from '#/utils/log';

import { avatarSx, avatarSxSmall, useUserProfileStyles } from './UserProfile.styles';

interface UserProfilePicture {
  autoSave: boolean;
  setUserImageId?: React.Dispatch<React.SetStateAction<string>>;
  imageId?: number | string;
  avatarId?: number | string | null;
  viewOnly?: boolean;
  small?: boolean;
  needsRefresh?: boolean;
}
export const UserProfilePicture: FC<UserProfilePicture> = ({
  autoSave,
  setUserImageId,
  imageId,
  viewOnly,
  avatarId,
  small,
  needsRefresh = false,
}) => {
  const classes = useUserProfileStyles();
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [imageLink, setImageLink] = useState('');
  const navigate = useNavigate();
  const getTemporaryImageAvatar = async () => {
    const { data: imageId } = await postAvatar(currentFile);
    setUserImageId && setUserImageId(imageId as string);
    const imageData = await getUserTemporaryAvatar(imageId);
    setImageLink(URL.createObjectURL(imageData.data));
    needsRefresh && navigate(0);
  };
  const { user } = useAppSelector((state) => state.user);
  useEffect(() => {
    if (currentFile) {
      getTemporaryImageAvatar();

      if (autoSave) {
        saveImage();
      }
    }
  }, [currentFile]);

  const getTemporaryAvatar = async (path: string | number) => {
    try {
      const { data } = await getUserTemporaryAvatar(path);
      setImageLink(URL.createObjectURL(data));
    } catch (error) {
      log.error('Error getting temporary avatar', error);
    }
  };

  useEffect(() => {
    const path = imageId ? imageId : avatarId;
    if (path) {
      getTemporaryAvatar(path);
    }
  }, [imageId, avatarId]);

  const dropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (e.dataTransfer.items) {
      setCurrentFile(e.dataTransfer.items[0].getAsFile());
    } else {
      setCurrentFile(e.dataTransfer.files[0]);
    }
  };

  const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentFile(e.target?.files?.[0] || null);
  };

  const handleBrowseFile = () => {
    inputRef.current?.click();
  };

  const saveImage = async () => {
    if (!user) return;
    const { data: imageId } = await postAvatar(currentFile);
    await saveLogo(imageId, user.userId);
  };

  return (
    <>
      {viewOnly ? (
        <Avatar className={classnames(avatarSx, small && avatarSxSmall)} src={imageLink} />
      ) : (
        <Box onClick={handleBrowseFile} onDragOver={(e) => dragOverHandler(e)} onDrop={(e) => dropHandler(e)}>
          <input ref={inputRef} className={classes.displayNone} onChange={(e) => handleFileChange(e)} type="file" />
          <Badge
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={<img alt="badge" className={classes.avatarIcon} src={CameraIconBlue} />}
            className={classes.badgeMargin}
            overlap="circular"
          >
            <Avatar src={imageLink} sx={avatarSx} />
          </Badge>
        </Box>
      )}
    </>
  );
};
