import { Box, Grid } from '@mui/material';
import React, { FC } from 'react';

import { useAddDetailsToSpecificTierStyles } from '#/components/campaigns/AddDetailsToSpecificTier.styles';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';

import { IEditDetails } from '#/store/api/campaigns/campaignsInterfaces';

type AddDetailsHeaderProps = {
  detailsData: IEditDetails;
  campaignName: string;
} & ({ campaignId: string; instanceId?: never } | { campaignId?: never; instanceId: string });

const AddDetailsHeader: FC<AddDetailsHeaderProps> = ({ detailsData, campaignName, ...props }) => {
  const classes = useAddDetailsToSpecificTierStyles();

  return (
    <>
      <Grid item xs={12}>
        <Box className={classes.flexParent}>
          <TypographyWithFontFamily variant="Default_Bold">Campaign: </TypographyWithFontFamily>
          <TypographyWithFontFamily>{campaignName}</TypographyWithFontFamily>
        </Box>
        <Box className={classes.flexParent}>
          <TypographyWithFontFamily variant="Default_Bold">Tier: </TypographyWithFontFamily>
          <TypographyWithFontFamily>{detailsData.tierName}</TypographyWithFontFamily>
        </Box>
        <Box className={classes.flexParent}>
          <TypographyWithFontFamily variant="Default_Bold">Tactic: </TypographyWithFontFamily>
          <TypographyWithFontFamily>{detailsData.tactic}</TypographyWithFontFamily>
        </Box>
        <Box className={classes.flexParent}>
          {props?.campaignId ? (
            <>
              <TypographyWithFontFamily variant="Default_Bold">Campaign ID: </TypographyWithFontFamily>
              <TypographyWithFontFamily>{props?.campaignId}</TypographyWithFontFamily>
            </>
          ) : (
            <>
              <TypographyWithFontFamily variant="Default_Bold">Campaign Instance ID: </TypographyWithFontFamily>
              <TypographyWithFontFamily>{props?.instanceId}</TypographyWithFontFamily>
            </>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default AddDetailsHeader;
