import { IResource } from '../../../hooks/useGetResource';
import { baseApi } from '../../base';
import { ICampaignHistory } from '../campaigns/campaignsInterfaces';

const campaignHistoryInstances = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCampaignHistoryInstances: builder.query<ICampaignHistory[], { resource: IResource }>({
      query: ({ resource }) => `/retailer/instances/${resource.resourceId}/history`,
      providesTags: ['CAMPAIGN_HISTORY_INSTANCES'],
    }),
  }),
});

export const { useGetCampaignHistoryInstancesQuery } = campaignHistoryInstances;

export { campaignHistoryInstances };
