import { makeStyles, createStyles } from '#/utils/theme';

export const useChannelsAndTacticsStyle = makeStyles(() =>
  createStyles({
    channelsAndTacticsContainer: {
      background: '#FFF',
      minHeight: '50rem',
      padding: '1rem',
      borderRadius: '1rem',
      display: 'flex',
      columnGap: '1rem',
    },
    channelsAndSubChannelsContainer: {
      width: '17.8rem',
    },
    collapsible: {
      '&.MuiListItemButton-root': {
        flexDirection: 'row-reverse',
        columnGap: '0.25rem',
        paddingLeft: '0',
        borderRadius: '0.5rem',
      },
    },
    list: {
      width: '100%',
      maxWidth: '17.8rem',
    },
    channelTacticsContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      rowGap: '1rem',
    },
    selectedSubChannel: {
      background: '#D8EDFA',
    },
    subChannel: {
      borderRadius: '0.5rem',
    },
    tacticsTableContainer: {
      border: '1px solid #E4E8EF',
      padding: '1rem',
      width: '100%',
      borderRadius: '1rem',
      marginBottom: '1rem',
      '& thead': {
        display: 'none',
      },
      '& tr': {
        borderLeft: 'none',
        borderRight: 'none',
      },
    },
    tacticsTableHeaderCell: {
      border: 'none',
    },
    tacticsTableBodyCell: {
      height: '2.5rem',
    },
    tacticsTableBody: {
      '& td:last-child div': {
        justifyContent: 'flex-end',
      },
      '& td': {
        borderLeft: 'none',
        borderRight: 'none',
      },
      '& tr:first-child td': {
        borderTop: 'hidden',
      },
      '& tr:last-child td': {
        borderBottom: 'hidden',
      },
      '& tr td': {
        borderLeft: 'hidden',
        borderRight: 'hidden',
      },
    },
    subChannelContainer: { display: 'flex', justifyContent: 'space-between' },
  }),
);
export const headerSx = { fontWeight: '700', color: '#16191F', marginBottom: '0.5rem' };
export const headerTacticsSx = { fontWeight: '700', color: '#16191F', marginBottom: '1rem' };
export const listItemButtonSx = { paddingLeft: '2.5rem' };
export const numberOfTacticsSx = { color: '#16191F', fontWeight: '400' };
