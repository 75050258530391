import { THEME } from '#/constants/theme/constants';

import { createStyles, makeStyles } from '#/utils/theme';

export const useTacticMetadataFormStyle = makeStyles((theme) =>
  createStyles({
    formContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      backgroundColor: theme.custom.PALETTES.BORDER.Extra_Light,
    },
    buttonsContainer: {
      marginTop: theme.custom.REM_SPACES[1],
    },
    checkBoxList: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.custom.REM_SPACES[1],
    },
    labelUppercase: {
      textTransform: 'uppercase',
    },
    inputContainer: {
      padding: `0 ${theme.custom.REM_SPACES[1]}`,
    },
    whiteContainer: {
      backgroundColor: theme.custom.PALETTES.OTHER.White,
    },
    checkBoxContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    numberHelperTextSubtitleContainer: {
      '& .MuiTextField-root': {
        flexDirection: 'column-reverse',
        '& .MuiFormHelperText-root': {
          color: theme.custom.PALETTES.TEXT.Dark,
          fontWeight: theme.custom.FONT_WEIGHTS.MEDIUM,
          fontSize: '0.719rem',
          margin: '0.313rem 0',
        },
      },
    },
    selectContainerDisabled: {
      '&& .MuiOutlinedInput-root': {
        background: theme.custom.PALETTES.UI.Neutral[200],
      },
    },
  }),
);

export const formStyle = { width: '100%' };
export const errorTextSx = {
  color: THEME.PALETTES.TEXT.Error,
};
export const checkboxListContainerSx = { marginTop: '1rem' };
