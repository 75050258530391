import { Box, Modal } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router';

import { BASE_PATH, COMMUNICATIONS_TABS } from '#/constants/general';
import { ROUTE_PATHS } from '#/constants/routing/routePaths';
import { THEME } from '#/constants/theme/constants';

import SendIcon from '../../../../assets/PromoteWhiteIcon.svg';
import XIcon from '../../../../assets/XIcon.svg';
import { useCampaignInvitationsStyles } from '../../../campaignInvitations/CampaignInvitations.styles';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import CommunicationsBreadCrumb from '../breadcrumb/CommunicationsBreadcrumb';

interface ICustomCommunicationsModal {
  activeTab: COMMUNICATIONS_TABS;
  modalTitle: string;
  children: React.ReactNode;
}

const CustomCommunicationsModal: FC<ICustomCommunicationsModal> = ({ activeTab, children, modalTitle }) => {
  const { COMMUNICATIONS, CAMPAIGNS_PROMOTE } = ROUTE_PATHS;
  const classes = useCampaignInvitationsStyles();
  const navigate = useNavigate();
  const onClose = () => navigate(`${BASE_PATH}/${COMMUNICATIONS}/${CAMPAIGNS_PROMOTE}`, { replace: true });

  return (
    <Modal className={classes.modal} open>
      <Box className={classes.formContainer}>
        <Box className={classes.modalWrapper}>
          <Box className={classes.modalHeader}>
            <Box className={classes.modalTitle}>
              <img alt="Send" src={SendIcon} />
              <TypographyWithFontFamily color={THEME.PALETTES.OTHER.White} variant="H4_Base">
                {modalTitle}
              </TypographyWithFontFamily>
            </Box>
            <Box className={classes.modalClose}>
              <img alt="close" onClick={onClose} src={XIcon} />
            </Box>
          </Box>
        </Box>
        <Box>
          <CommunicationsBreadCrumb activeTab={activeTab} />
          {children}
        </Box>
      </Box>
    </Modal>
  );
};
export default CustomCommunicationsModal;
