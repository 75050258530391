import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';

import Card from '#/components/common/card/card';
import getBrandUserListTableColumns from '#/components/features/partners/brands/BrandUserList/BrandUserList.tables';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import { useGetCompanyAccountQuery } from '#/store/api/company/company';

import PermissionWrapper from '#/utils/PermissionWrapper';

import { getBrandUsers } from '../../../../../api/partners/brandUsers';
import ContactsIcon from '../../../../../assets/ContactsIcon.svg';
import PlusIcon from '../../../../../assets/Plus.svg';
import { useRequest } from '../../../../../hooks/useRequest';
import { IBrandUser } from '../../../../../interfaces/partners/brands/brandUserList';
import AddUserWizard from '../../../../addUserWizard/AddUserWizard';
import NewButton from '../../../../common/button/NewButton';
import { Table } from '../../../../common/table/tableComponent/Table';
import { TypographyWithFontFamily } from '../../../../common/typography/TypographyWithFontFamily';
import { useBrandUserListStyle } from './BrandUserList.styles';

const BrandUserList = () => {
  const { brandId } = useParams();
  const { data } = useGetCompanyAccountQuery({ companyId: brandId });

  const [isAddUserWizardOpen, setIsAddUserWizardOpen] = useState(false);
  const getBrandUsersAction = useMemo(() => getBrandUsers(brandId), [isAddUserWizardOpen]);
  const { response } = useRequest(getBrandUsersAction);
  const changeDisplayData = (data: IBrandUser[]) =>
    data.map((user: IBrandUser) => ({
      ...user,
      keyContact: user.keyContact ? true : null,
      billingContact: user.billingContact ? true : null,
    }));

  const classes = useBrandUserListStyle();
  const tableColumns = getBrandUserListTableColumns();

  if (!data) return null;

  return (
    <Card>
      <Box className={classes.headerContainer}>
        <Box className={classes.headerLeft}>
          <img alt="contacts" src={ContactsIcon} />
          <TypographyWithFontFamily cName={classes.header} variant="H4_Base">
            Users
          </TypographyWithFontFamily>
        </Box>
        <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.PARTNERS_COMPANY_BRAND_USERS_SECTION_WRITE]}>
          <Box>
            <NewButton
              icon={PlusIcon}
              iconPosition="left"
              onClick={() => setIsAddUserWizardOpen(true)}
              text="Add New User"
              variant="Primary"
            />
          </Box>
        </PermissionWrapper>
      </Box>
      <Table
        bodyCellClass={classes.tableCell}
        columns={tableColumns}
        containerClass={classes.tableContainer}
        data={changeDisplayData(response?.data || [])}
        headCellClass={classes.tableHeader}
      />
      {isAddUserWizardOpen && <AddUserWizard companyData={data} onClose={() => setIsAddUserWizardOpen(false)} />}
    </Card>
  );
};

export default BrandUserList;
