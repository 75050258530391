import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const useAddDataJobStyle = makeStyles(() =>
  createStyles({
    modalBody: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '21rem',
      marginTop: THEME.REM_SPACES[1],
    },
    uploadContainer: {
      background: THEME.PALETTES.UI.Secondary[100],
      height: '24.5rem',
      borderRadius: THEME.REM_SPACES[1],
      border: `1px dashed ${THEME.PALETTES.UI.Secondary.Base}`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    dragDropText: {
      '&.MuiTypography-root': {
        color: THEME.PALETTES.TEXT.Action_Secondary,
      },
    },
    bowseFileText: {
      '&.MuiTypography-root': {
        color: THEME.PALETTES.TEXT.Action_Secondary,
      },
    },
    previewFile: {
      width: '23.1875rem',
      borderRadius: THEME.REM_SPACES[0.5],
      border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      padding: THEME.REM_SPACES[0.5],
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    previewLeft: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: THEME.REM_SPACES[0.5],
    },
    ArchiveIcon: {
      width: '3rem',
      height: '2.5rem',
      objectFit: 'cover',
    },
    fileName: {
      '&.MuiTypography-root': {
        color: THEME.PALETTES.UI.Neutral.Base[900],
      },
    },
    uploadText: {
      '&.MuiTypography-root': {
        color: THEME.PALETTES.UI.Neutral.Base,
      },
    },
    previewRight: {
      display: 'flex',
      columnGap: THEME.REM_SPACES[0.5],
    },
    previewIcon: {
      cursor: 'pointer',
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      columnGap: THEME.REM_SPACES[0.5],
      marginTop: THEME.REM_SPACES[1],
    },
  }),
);
