import PartnersIcon from '../../../../assets/BluePartnersIcon.svg';
import ReportsIcon from '../../../../assets/BlueReportsIcon.svg';
import DownloadIconBlue from '../../../../assets/DownloadIconBlue.svg';
import EyeIcon from '../../../../assets/EyeIcon.svg';
import { IQuickLinks } from '../../../../interfaces/dashboard/quickLinks';

export const retailerLinks: IQuickLinks[] = [
  {
    name: 'Reports',
    url: '/reports',
    icon: ReportsIcon,
  },
  {
    name: 'Partners / Users',
    url: '/partners/users',
    icon: PartnersIcon,
  },
  {
    name: 'Partners / User Access Requests',
    url: '/partners/user-access',
    icon: PartnersIcon,
  },
  {
    name: 'Turbyne Video Guide',
    url: 'https://player.vimeo.com/video/1009805758?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    icon: EyeIcon,
    rel: 'noopener noreferrer',
    target: '_blank',
  },
  {
    name: 'Shopper Marketing Playbook',
    url: 'https://turbdemosfiles.blob.core.windows.net/turbyne-retailer/c9117175-e7ad-48cd-b0eb-ab337057b3f1-Sprouts-Shopper-Marketing-2025-Playbook.pdf',
    icon: DownloadIconBlue,
    rel: 'noopener noreferrer',
    target: '_blank',
  },
];

export const brandLinks: IQuickLinks[] = [
  {
    name: 'Turbyne Video Guide',
    url: 'https://player.vimeo.com/video/1009805758?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    icon: EyeIcon,
    rel: 'noopener noreferrer',
    target: '_blank',
  },
  {
    name: 'Shopper Marketing Playbook',
    url: 'https://turbdemosfiles.blob.core.windows.net/turbyne-retailer/c9117175-e7ad-48cd-b0eb-ab337057b3f1-Sprouts-Shopper-Marketing-2025-Playbook.pdf',
    icon: DownloadIconBlue,
    rel: 'noopener noreferrer',
    target: '_blank',
  },
  {
    name: 'Reports',
    url: '/reports',
    icon: ReportsIcon,
  },
];
