import { FC } from 'react';

import { useAppSelector } from '#/hooks';

export enum USER_TYPES {
  RETAILER = 'retailer',
  BRAND = 'brand',
  CHANNEL_PARTNER = 'channel-partner',
  AGENCY = 'agency',
}

export enum USER_TYPES_HIDDEN {
  SUPER_ADMIN = 'super_admin',
}

type UserTypeWrapperProps = {
  [key in USER_TYPES]?: React.ReactElement;
};

const userMap = {
  [USER_TYPES.CHANNEL_PARTNER]: USER_TYPES.CHANNEL_PARTNER,
  [USER_TYPES.BRAND]: USER_TYPES.BRAND,
  [USER_TYPES.AGENCY]: USER_TYPES.BRAND,
  [USER_TYPES.RETAILER]: USER_TYPES.RETAILER,
  [USER_TYPES_HIDDEN.SUPER_ADMIN]: USER_TYPES.RETAILER,
};

const UserTypeWrapper: FC<UserTypeWrapperProps> = (props) => {
  const { user } = useAppSelector((state) => state.user);
  if (!user) return null;

  const { userType } = user;
  return props[userMap[userType]];
};

export default UserTypeWrapper;
