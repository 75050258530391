import { Box } from '@mui/material';
import classnames from 'classnames';
import React, { FC, useState } from 'react';
import { FieldErrors, UseFormRegister, UseFormUnregister } from 'react-hook-form';

import { useAppSelector } from '#/hooks';
import { USER_TYPES } from '#/utils/UserTypeWrapper';

import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';

import { IDynamicRibbonModalData } from '#/interfaces/campaigns/campaignsInstances';
import { ISubmitCampaignDynamicRibbon } from '#/interfaces/forms/campaigns';

import { usePostCampaignDynamicRibbonMutation } from '#/store/api/campaigns/instances';

import { currencyFormatter } from '#/utils/textFormatters';

import NewButton from '../../../../common/button/NewButton';
import SingleDownloadUpload from '../../../../common/downloadUpload/SingleDownloadUpload';
import Checkbox from '../../../../common/inputs/Checkbox/Checkbox';
import Select from '../../../../common/inputs/Select/Select';
import Modal from '../../../../common/modal/Modal';
import { TypographyWithFontFamily } from '../../../../common/typography/TypographyWithFontFamily';
import BrandOrderModalItem from '../../../../shared/BrandOrderModalItem/BrandOrderModalItem';
import { useCampaignDynamicRibbonModal } from './CampaignDynamicRibbonModal.styles';

interface ICampaignDynamicRibbonModal {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: IDynamicRibbonModalData;
  errors: FieldErrors<ISubmitCampaignDynamicRibbon>;
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  register: UseFormRegister<ISubmitCampaignDynamicRibbon>;
  unregister: UseFormUnregister<ISubmitCampaignDynamicRibbon>;
}

const CampaignDynamicRibbonModal: FC<ICampaignDynamicRibbonModal> = ({
  onSubmit,
  errors,
  open,
  setOpen,
  register,
  data,
  unregister,
}) => {
  const user = useAppSelector((state) => state.user);
  const isBrandUser = user.user?.userType === USER_TYPES.BRAND;
  const isAgencyUser = user.user?.userType === USER_TYPES.AGENCY;
  const [_, { isLoading }] = usePostCampaignDynamicRibbonMutation({ fixedCacheKey: 'confirm-ribbon' });
  const classes = useCampaignDynamicRibbonModal();

  const [terms, setTerms] = useState(false);

  return (
    <Modal
      content={
        <form onSubmit={onSubmit}>
          <TypographyWithFontFamily>Brand Order Details</TypographyWithFontFamily>
          <Box className={classes.wrap}>
            <TypographyWithFontFamily cName={classes.mb1}>
              Please confirm details below for your campaign submission
            </TypographyWithFontFamily>
            <Box className={classes.dflex}>
              {/*left */}
              <Box className={classes.width50}>
                <BrandOrderModalItem itemTitle="Tier Name" itemValue={data.tierName} />
                <BrandOrderModalItem itemTitle="Tier Budget" itemValue={currencyFormatter(data.contractAmount)} />
                <BrandOrderModalItem itemTitle="Campaign Start Date" itemValue={data.campaignStartDate} />
                <BrandOrderModalItem itemTitle="Campaign End Date" itemValue={data.campaignEndDate} spacingBottom />
                <BrandOrderModalItem itemTitle="Brand" itemValue={data.brandName} />
                <BrandOrderModalItem itemTitle="Brand Legal Name" itemValue={data.brandLegalName} />
                <BrandOrderModalItem itemTitle="Brand Vendor ID" itemValue={data.brandVendorId} />
                <BrandOrderModalItem itemTitle="Agency" itemValue={data.agencyName} />
                <BrandOrderModalItem itemTitle="Agency Legal Name" itemValue={data.agencyLegalName} />
                <BrandOrderModalItem itemTitle="Agency Vendor ID" itemValue={data.agencyVendorId} spacingBottom />
                <Box className={classnames(classes.flexCenter, classes.marginB1)}>
                  <TypographyWithFontFamily cName={classes.boldLabel}>Primary Contact</TypographyWithFontFamily>
                  <Select
                    className={classes.selectWidth}
                    dataCy="primary-contacts"
                    label={undefined}
                    options={data.primaryContacts?.map((contact: { name: string; id: string }) => ({
                      label: contact.name,
                      value: contact.id,
                    }))}
                    register={register('primaryContactId')}
                    useRegister
                  />
                </Box>
                <TypographyWithFontFamily cName={classnames(classes.marginB1, classes.errorColor)} variant="H6_Regular">
                  {errors.adOpsContactId?.message}
                </TypographyWithFontFamily>
                <Box className={classnames(classes.flexCenter, classes.marginB1)}>
                  <TypographyWithFontFamily cName={classes.boldLabel}>Ad Ops Contact</TypographyWithFontFamily>
                  <Select
                    className={classes.selectWidth}
                    dataCy="ad-ops-contactid"
                    label={undefined}
                    options={data.adOpsContacts?.map((contact: { name: string; id: string }) => ({
                      label: contact.name,
                      value: contact.id,
                    }))}
                    register={register('adOpsContactId')}
                    useRegister
                  />
                </Box>
                <TypographyWithFontFamily cName={classnames(classes.marginB1, classes.errorColor)} variant="H6_Regular">
                  {errors.primaryContactId?.message}
                </TypographyWithFontFamily>

                <Box className={classnames(classes.flexCenter, classes.marginB1)}>
                  <TypographyWithFontFamily cName={classes.boldLabel}>Category Manager</TypographyWithFontFamily>
                  <Select
                    className={classes.selectWidth}
                    dataCy="categoryManagers"
                    label={undefined}
                    options={data.categoryManagers?.map((contact: { name: string; id: string }) => ({
                      label: contact.name,
                      value: contact.id,
                    }))}
                    register={register('categoryManagerId')}
                    useRegister
                  />
                </Box>
                <TypographyWithFontFamily cName={classnames(classes.marginB1, classes.errorColor)} variant="H6_Regular">
                  {errors.categoryManagerId?.message}
                </TypographyWithFontFamily>
              </Box>
              {/* right */}
              <Box className={classes.lh1}>
                <BrandOrderModalItem itemTitle="Contracting Party Name" itemValue={data.contractingPartyName} />
                <BrandOrderModalItem
                  itemTitle="Contracting Party Vendor ID"
                  itemValue={data.contractingPartyVendorId}
                />
                <BrandOrderModalItem itemTitle="Contract Signer" itemValue={data.contractSignerName} />
                <BrandOrderModalItem
                  itemTitle="Contract Signer Email"
                  itemValue={data.contractSignerEmail}
                  spacingBottom
                />
                {!isBrandUser && !isAgencyUser && (
                  <>
                    <Box className={classes.flexCenter}>
                      <TypographyWithFontFamily cName={classes.boldLabel}>Billing Type</TypographyWithFontFamily>
                      <TypographyWithFontFamily>
                        {data.billingType === null ? 'No billing type selected' : data.billingType}
                      </TypographyWithFontFamily>
                    </Box>
                    <TypographyWithFontFamily
                      cName={classnames(classes.marginB1, classes.errorColor)}
                      variant="H6_Regular"
                    >
                      {errors.billingType?.message}
                    </TypographyWithFontFamily>

                    <Box className={classes.dflex}>
                      <TypographyWithFontFamily cName={classes.boldLabel}>PO #</TypographyWithFontFamily>
                      <TextFieldOld
                        dataCy="purchaseOrderNumber"
                        editMode
                        label={undefined}
                        register={register('purchaseOrderNumber')}
                      />
                      <br />
                    </Box>
                    <TypographyWithFontFamily
                      cName={classnames(classes.marginB1, classes.errorColor)}
                      variant="H6_Regular"
                    >
                      {errors.purchaseOrderNumber?.message}
                    </TypographyWithFontFamily>
                  </>
                )}
              </Box>
            </Box>
            <Box className={classes.mb1}>
              <Checkbox
                label="By Checking the box you confirm that you are able to sign on behalf of the contractic party, repesent the brand, and agree to the terms and conditions"
                onChange={() => setTerms(!terms)}
                value={terms}
              />
            </Box>
            <Box className={classes.dflex}>
              {/*left */}
              <Box className={classes.bottomColumnWidth}>
                <Box>
                  <TypographyWithFontFamily cName={classes.boldLabel}>Legal Terms</TypographyWithFontFamily>
                  <SingleDownloadUpload fileId={data.legalTermsId} />
                </Box>
                <Box className={classes.mt1}>
                  <TypographyWithFontFamily cName={classes.boldLabel}>Custom Legal Terms</TypographyWithFontFamily>
                  <SingleDownloadUpload fileId={data.customTermsAndConditionsId} />
                </Box>
              </Box>
              {/* right*/}
              <Box>
                <TypographyWithFontFamily cName={classes.boldLabel}>Additional Terms</TypographyWithFontFamily>
                <TypographyWithFontFamily cName={classes.label}>{data.additionalTerms}</TypographyWithFontFamily>
              </Box>
            </Box>
            <Box className={classes.buttonAlign}>
              <NewButton disabled={!terms} loading={isLoading} text="Confirm" type="submit" />
            </Box>
          </Box>
        </form>
      }
      handleCloseModal={() => {
        setOpen(!open);
        unregister();
      }}
      modalTitle="Campaign Submission Confirmation"
      open={open}
      size={'wide'}
    />
  );
};

export default CampaignDynamicRibbonModal;
