import { Box } from '@mui/material';
import classnames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';

import { useDataCy } from '#/hooks/useDataCy';

import { getReportsList } from '../../../api/reports/reports';
import ExpandIcon from '../../../assets/ListIcon.svg';
import ReportsIcon from '../../../assets/Reports.svg';
import { useRequest } from '../../../hooks/useRequest';
import Card from '../../common/card/card';
import DisplayPageName from '../../common/displayPageName/DisplayPageName';
import Loader from '../../common/loader/loader';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import { pageContainer, pageTitleSx, useReportsPageStyles } from './Reports.style';

export const Reports = (): JSX.Element => {
  const classes = useReportsPageStyles();
  const { reportId } = useParams();
  const [isMenuExpanded, expandMenu] = useState(true);
  const generateDataCy = useDataCy();

  const getReports = useMemo(() => getReportsList(), []);
  const { response, loading } = useRequest(getReports);

  useEffect(() => {
    if (reportId) {
      expandMenu(false);
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!response || !response.data.length) {
    return <TypographyWithFontFamily variant="H5_Base">No Reports available</TypographyWithFontFamily>;
  }

  return (
    <Box className={classes.container} data-cy={generateDataCy('box', 'ReportsPageContainer')}>
      <Card customStyles={pageContainer} data-cy={generateDataCy('card', 'ReportsPageContent')}>
        {isMenuExpanded ? (
          <Box className={classes.listContainer}>
            <Box className={classes.listTitleContainer}>
              <DisplayPageName
                customStyles={pageTitleSx}
                customVariant="H4_Base"
                imgSrc={ReportsIcon}
                pageName="Reports"
              />
              <button
                className={classes.downloadButton}
                onClick={() => {
                  expandMenu(false);
                }}
              >
                <img alt="Expand" src={ExpandIcon} />
              </button>
            </Box>
            <Box className={classes.divider} />
            {response.data.map((element) => (
              <Link
                key={element.id}
                className={classnames(classes.listElement, {
                  [classes.active]: reportId && String(reportId) === String(element.id),
                })}
                to={String(element.id)}
              >
                {element.name}
              </Link>
            ))}
          </Box>
        ) : (
          <Box className={classnames(classes.listContainer, classes.expandButtonContainer)}>
            <button
              className={classes.downloadButton}
              onClick={() => {
                expandMenu(true);
              }}
            >
              <img alt="Expand" src={ExpandIcon} />
            </button>
          </Box>
        )}
        <Box className={classes.dataContainer}>
          {reportId ? (
            <Outlet key={reportId} />
          ) : (
            <TypographyWithFontFamily variant="H5_Base">No Report Selected</TypographyWithFontFamily>
          )}
        </Box>
      </Card>
    </Box>
  );
};
