import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import { ChannelPartnerAccount } from '#/store/api/partners/promoteInterfaces';

import CircleCheckIcon from '../../../../assets/CircleCheckIcon.svg';
import { TypographyWithFontFamily } from '../../../../components/common/typography/TypographyWithFontFamily';
import { useBrandStyles } from '../../../../components/features/partners/brands/brands.styles';
import CompanyLogo from '../../../../components/shared/companyLogo/CompanyLogo';
import { IChannelPartnerUsers } from '../../../../interfaces/partners/channelPartners/channelPartners';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '../../../../interfaces/table/table';
import { formatColumnToHaveComma } from '../../../../utils/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CompanyChannelPartnersTable = (columNames: any = []) => {
  const sortableColumns = columNames.map((el) => el.columnName);

  const classes = useBrandStyles();
  const createCustomNameImageView = (channelPartnerId: string, logoId: string | null, channelPartnerName: string) => (
    <Box className={classes.flexCenter}>
      <CompanyLogo companyName={channelPartnerName} imageId={logoId} size="small" viewOnly />
      <Link className={classes.url} to={`/partners/channel-partners/${channelPartnerId}`}>
        {channelPartnerName}
      </Link>
    </Box>
  );
  const getCustomElement = (val?: { id: string; name: string }[]) => {
    if (!val) {
      return null;
    }
    return (
      <TypographyWithFontFamily cName={classes.customHrefColor}>
        {val.map((el, index: number) => {
          return (
            <Link key={el.id} to={`/partners/users/${el.id}`}>
              {formatColumnToHaveComma(el.name, index, val)}
            </Link>
          );
        })}
      </TypographyWithFontFamily>
    );
  };
  const ChannelPartnersTable: ITableColumnsProps<ChannelPartnerAccount>[] = [
    {
      fieldName: 'aggregateId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Channel Partner Id',
      hide: true,
    },
    {
      fieldName: 'companyName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Company Name',
      actionElementIdentifier: 'aggregateId',
      actionElementSecondIdentifier: 'logoId',
      actionElementThirdIdentifier: 'companyName',
      getCustomElement: createCustomNameImageView,
      sortable: sortableColumns.includes('companyName'),
    },
    {
      fieldName: 'companyParent',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Parent Company ',
      sortable: sortableColumns.includes('companyParent'),
    },
    {
      fieldName: 'vendorId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Vendor ID',
      sortable: sortableColumns.includes('vendorId'),
    },
    {
      fieldName: 'keyContacts',
      align: TableColumnAlign.LEFT,
      customHead: 'Key Contacts',
      type: TableCellTypes.CUSTOM_ELEMENT,
      actionElementIdentifier: 'keyContacts',
      getCustomElement: getCustomElement,
      sortable: sortableColumns.includes('keyContacts'),
    },
    {
      fieldName: 'channelTactics',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.STRING,
      customHead: 'Associated Channels',
      sortable: sortableColumns.includes('channelTactics'),
    },
  ];
  return ChannelPartnersTable;
};

export const CompanyChannelPartnerUsersTable = () => {
  const ChannelPartnerUsers: ITableColumnsProps<IChannelPartnerUsers>[] = [
    {
      fieldName: 'userId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Campaign Id',
      hide: true,
    },
    {
      fieldName: 'fullName',
      align: TableColumnAlign.LEFT,
      linkable: true,
      actionElementIdentifier: 'userId',
      type: TableCellTypes.STRING,
      customHead: 'User Name',
      baseUrl: '/partners/users',
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Channel Partner User Status',
    },
    {
      fieldName: 'keyContact',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.ELEMENT,
      customHead: 'Key Contact',
      element: () => <img alt="circle icon" src={CircleCheckIcon} />,
      hideElementIfNull: true,
    },
    {
      fieldName: 'billingContact',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.ELEMENT,
      customHead: 'Billing Contact',
      element: () => <img alt="circle icon" src={CircleCheckIcon} />,
      hideElementIfNull: true,
    },
    {
      fieldName: 'jobTitle',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Job Title',
    },
    {
      fieldName: 'email',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Email',
    },
    {
      fieldName: 'phone',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Phone',
    },
    {
      fieldName: 'lastLogin',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Last Login',
    },
  ];
  return ChannelPartnerUsers;
};
