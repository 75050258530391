import React, { FC } from 'react';

import DownloadIcon from '#/assets/DownloadIconBlue.svg';

import {
  traffickingLineDataDetailAltId,
  traffickingLineDetailAltId,
} from '#/components/campaigns/campaignAssets/constants';
import NewButton from '#/components/common/button/NewButton';

import useGetResource from '#/hooks/useGetResource';

import { useGetReportAltExportMutation } from '#/store/api/reports/reports';

interface DownloadTraffickingSheetProps {
  tacticId?: string;
}
const DownloadTraffickingSheet: FC<DownloadTraffickingSheetProps> = ({ tacticId }) => {
  const [getReportAltExportQuery] = useGetReportAltExportMutation();
  const { resourceId } = useGetResource();

  const onDownload = async () => {
    const { file, contentDisposition } = await getReportAltExportQuery({
      altId: tacticId ? traffickingLineDataDetailAltId : traffickingLineDetailAltId,
      searchQuery: tacticId ? `order_id='${resourceId}' && tactic_id='${tacticId}'` : '',
    }).unwrap();
    const match = contentDisposition?.match(/filename="([^"]+)"/);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.setAttribute('download', match?.[1] || 'report.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  return <NewButton icon={DownloadIcon} onClick={onDownload} text="" variant={'Ghost'} />;
};

export default DownloadTraffickingSheet;
