import { Box } from '@mui/material';
import classnames from 'classnames';
import { FC, useState } from 'react';

import Close from '#/assets/Close.svg';
import Error from '#/assets/Error.svg';

import { useAlertStyles } from '#/components/common/Alert/Alert.styles';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';

interface AlertProps {
  text: string;
}

const Alert: FC<AlertProps> = ({ text }) => {
  const [isVisible, setVisible] = useState(true);
  const classes = useAlertStyles();
  return (
    <Box className={classnames(classes.container, { [classes.closed]: !isVisible })}>
      <Box className={classes.iconTextContainer}>
        <img alt="error_icon" className={classes.icon} src={Error} />
        <TypographyWithFontFamily className={classes.text} variant="Text_Body_500">
          {text}
        </TypographyWithFontFamily>
      </Box>
      <img alt="error_icon" className={classes.icon} onClickCapture={() => setVisible(false)} src={Close} />
    </Box>
  );
};

export default Alert;
