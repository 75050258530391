import { Box } from '@mui/material';
import classnames from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';

import { getChannelPartnerBillingInfo } from '../../../../api/partners/channelPartner';
import ReceiptLogo from '../../../../assets/Receipt.svg';
import { useRequest } from '../../../../hooks/useRequest';
import { ISingleCompanyItem } from '../../../../interfaces/forms/brands';
import { IChannelPartnerViewProps } from '../../../../interfaces/partners/company/company';
import Card from '../../../common/card/card';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { mt2Sx, useBrandStyles } from '../brands/brands.styles';

const ChannelPartnerBilling: FC<IChannelPartnerViewProps> = ({ channelId }) => {
  const classes = useBrandStyles();

  const [data, setData] = useState({} as ISingleCompanyItem);
  const getChannelPartnerBilling = useMemo(() => getChannelPartnerBillingInfo(channelId), []);
  const { response } = useRequest(getChannelPartnerBilling);

  useEffect(() => {
    if (response) {
      setData(response?.data);
    }
  }, [response]);

  const { billingStreetAddress, billingCity, billingState, billingZip, ein, billType } = data;
  const composeAddress = `${billingStreetAddress} ${billingCity} ${billingState} ${billingZip}`;

  return (
    <Card customStyles={mt2Sx}>
      <Box className={classes.brandsWrap}>
        <TypographyWithFontFamily
          cName={classnames(classes.companyName, classes.wordWrap, classes.mb20)}
          variant="Default_Bold"
        >
          <img alt="receipt logo" className={classes.mrImg} src={ReceiptLogo} />
          <span>Billing Info</span>
        </TypographyWithFontFamily>
      </Box>
      <Box className={classes.brandsWrap}>
        <Box className={classes.fullWidth}>
          <Box className={classes.flex}>
            <Box className={classes.mr}>
              <TypographyWithFontFamily cName={classes.brandViewLabel}>Billing Address</TypographyWithFontFamily>
              <TypographyWithFontFamily cName={classes.brandViewName}>{composeAddress}</TypographyWithFontFamily>
            </Box>
            <Box className={classes.mr}>
              <TypographyWithFontFamily cName={classes.brandViewLabel}>Tax ID</TypographyWithFontFamily>
              <TypographyWithFontFamily cName={classes.brandViewName}>{ein}</TypographyWithFontFamily>
              <Box className={classes.mt30}>
                <TypographyWithFontFamily cName={classes.brandViewLabel}>W9</TypographyWithFontFamily>
                <TypographyWithFontFamily cName={classes.brandViewName}>W9file.pdf</TypographyWithFontFamily>
              </Box>
            </Box>
            <Box className={classes.mr}>
              <TypographyWithFontFamily cName={classes.brandViewLabel}>Billing</TypographyWithFontFamily>
              <TypographyWithFontFamily cName={classes.brandViewName}>
                {billType === 'BILL_BACK' ? 'Bill Back' : 'Direct Billing'}
              </TypographyWithFontFamily>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default ChannelPartnerBilling;
