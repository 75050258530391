import { Box } from '@mui/material';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';

import { BarChartData } from '#/interfaces/dashboard/charts';
import { IReports } from '#/interfaces/reports/reports';

import { currencyFormatter } from '#/utils/textFormatters';

import Loader from '../../../common/loader/loader';
import { NoDataMessage } from '../../../common/noDataMessage/NoDataMessage';
import { generateBarChartData } from '../../reports/utils';
import { barStyle, chartTick, legendStyle, useChartsStyles } from '../Dashboard.styles';
import { CustomTooltip } from './CustomTooltip';
import { chartFills } from './constants';

interface IProps {
  data?: IReports;
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => void;
}

export const BarCharts = ({ data, isLoading, isFetching, refetch }: IProps) => {
  const classes = useChartsStyles();

  if (isLoading) {
    return (
      <Box className={classes.barChartContainer}>
        <Loader />
      </Box>
    );
  }

  if (!data) {
    return <NoDataMessage dataLoading={isFetching} message="No Data Available" refetch={refetch} />;
  }

  const chartData: BarChartData[] = generateBarChartData(data);

  return (
    <Box className={classes.barChartContainer}>
      <ResponsiveContainer height="100%" width="95%">
        <BarChart
          className={classes.barChartItem}
          data={chartData}
          layout="horizontal"
          // this event handler will be used to handle user redirect
          onClick={(data) => {
            console.log(data.activePayload && data.activePayload[0].payload);
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey="Month" tick={chartTick} />
          <YAxis tick={chartTick} tickFormatter={currencyFormatter} width={90} />
          <Tooltip content={<CustomTooltip />} />
          <Legend align="left" wrapperStyle={legendStyle} />
          <Bar barSize={50} dataKey="Completed" fill={chartFills[0]} stackId="a" style={barStyle} />
          <Bar barSize={50} dataKey="Booked" fill={chartFills[1]} stackId="a" style={barStyle} />
          <Bar barSize={50} dataKey="Pending" fill={chartFills[2]} stackId="a" style={barStyle} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
