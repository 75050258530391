import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const usePartnerUsersStyle = makeStyles((theme) =>
  createStyles({
    container: {
      background: THEME.PALETTES.OTHER.White,
      padding: THEME.REM_SPACES[1],
      borderRadius: THEME.REM_SPACES[1],
    },
    table: {
      background: THEME.PALETTES.OTHER.White,
      '& td:last-child > div button': {
        justifyContent: 'flex-start',
        border: 'none',
      },
    },
    tableHeader: {
      background: '#F0F4F9',
    },
    mr1: {
      marginRight: THEME.REM_SPACES['1'],
    },
    filterContainer: {
      background: THEME.PALETTES.OTHER.White,
      marginBottom: THEME.REM_SPACES[1],
      marginTop: THEME.REM_SPACES[1],
      borderRadius: '18px',
    },
    autocomplete: {
      width: '100%',
      '& .MuiChip-root': {
        background: THEME.PALETTES.UI.Secondary[200],
      },
    },
    searchField: {
      padding: '0',
      width: '100%',
      '& input': { padding: '0 8px', height: '1px' },
      '& label': {
        top: '-1.25rem',
      },
    },
    headClass: {
      padding: '0.625rem',
    },
    bodyCellClass: {
      padding: '0.625rem',
    },
    headCellClass: {
      background: theme.palette.secondary.main,
      '&&': {
        padding: '0.875rem',
      },
    },
    button: {
      background: theme.palette.primary.main,
      borderRadius: '1.875rem',
      alignSelf: 'start',
      color: THEME.PALETTES.OTHER.White,
      fontSize: '0.75rem',
      padding: ' 0.5rem 0.9rem',
      outline: 'none',
      border: 0,
      cursor: 'pointer',
    },
    customStack: {
      marginBottom: 3,
      alignItems: 'center',
    },
    iconStack: {
      '&&': {
        width: THEME.REM_SPACES[2.5],
        height: THEME.REM_SPACES[2.5],
        background: THEME.PALETTES.OTHER.White,
        borderRadius: '50%',
        paddingTop: '0.35rem',
      },
    },
    addButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: THEME.REM_SPACES[1],
    },
    itemsList: {
      backgroundColor: THEME.PALETTES.OTHER.White,
    },
  }),
);
