import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const useCampaignOverviewGeneral = makeStyles(() =>
  createStyles({
    input: {
      marginBottom: THEME.REM_SPACES['1'],
    },
  }),
);
