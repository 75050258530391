import { Box } from '@mui/system';
import classNames from 'classnames';
import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { BASE_PATH } from '#/constants/general';
import { ROUTE_PATHS } from '#/constants/routing/routePaths';

import { ICampaignBrand } from '../../../store/api/campaigns/campaignsInterfaces';
import { convertAllCapsUnderscoreStrings } from '../../../utils/utils';
import CampaignThumbnail from '../CampaignThumbnail/CampaignThumbnail';
import LabelWithValue from '../LabelWithValue/LabelWithValue';
import NewButton from '../button/NewButton';
import { TypographyWithFontFamily } from '../typography/TypographyWithFontFamily';
import { useCampaignDetailStyles } from './CampaignDetail.styles';

interface CampaignDetailBrandProps {
  data: ICampaignBrand;
}

const CampaignDetailBrand: FC<CampaignDetailBrandProps> = ({ data }) => {
  const navigate = useNavigate();
  const classes = useCampaignDetailStyles();

  const { BRAND_ORDERS, CAMPAIGNS } = ROUTE_PATHS;
  const navigateToBrandOrders = () => {
    navigate(`${BASE_PATH}/${CAMPAIGNS}/${BRAND_ORDERS}?campaignId=${data.campaignId}%5E%3D`);
  };

  return (
    <Box>
      <CampaignThumbnail thumbnailId={data.thumbnailId} />
      <Box className={classes.row}>
        <TypographyWithFontFamily classNames={classes.campaignName} variant="H4_Base">
          <Link
            className={classes.links}
            to={`${BASE_PATH}/${CAMPAIGNS}/${BRAND_ORDERS}?campaignId=${data.campaignId}%5E%3D`}
          >
            {data.campaignName}
          </Link>
        </TypographyWithFontFamily>
      </Box>

      <Box className={classNames(classes.row, classes.topBorder)}>
        <LabelWithValue label={'STATUS:'} value={convertAllCapsUnderscoreStrings(data.status)} />
      </Box>

      <Box className={classes.row}>
        <LabelWithValue label={'MARKET DATES:'} value={`${data.startDate}-${data.endDate}`} />
        <LabelWithValue
          label={'DEADLINE:'}
          placeholder={'No submission deadline provided'}
          value={data.submissionDeadline}
        />
      </Box>

      <Box className={classNames(classes.bottomSectionBrand, classes.topBorder)}>
        <Box className={classes.buttonsSectionBrand}>
          <NewButton onClick={navigateToBrandOrders} text="Open" variant="Secondary" />
        </Box>
      </Box>
    </Box>
  );
};
export default CampaignDetailBrand;
