import { TableCellTypes, TableColumnAlign } from '../../../../../interfaces/table/table';

export const alignOptions = [
  {
    label: 'Left',
    value: TableColumnAlign.LEFT,
  },
  {
    label: 'Right',
    value: TableColumnAlign.RIGHT,
  },

  {
    label: 'Center',
    value: TableColumnAlign.CENTER,
  },
];

export const columnTypeOptions = [
  {
    label: 'String',
    value: TableCellTypes.STRING,
  },
];

export const reportTypeOptions = [
  {
    label: 'Sync',
    value: 'sync',
  },
  {
    label: 'Async',
    value: 'async',
  },
];
