import { Box } from '@mui/material';

import { useCustomTooltipStyle } from './CommunicationsChart.styles';
import { legendColors } from './utils';

interface IProps {
  active?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: { payload: any[] };
  label?: string;
}

export const CustomTooltip = ({ active, payload, label }: IProps) => {
  const classes = useCustomTooltipStyle();

  if (active && payload) {
    return (
      <Box className={classes.wrapperDiv}>
        <p className={classes.tooltipLabel}>{label}</p>
        <Box className={classes.tooltipsContainer}>
          {legendColors.map((legendElement) => (
            <Box key={legendElement.title} className={classes.tooltipRow}>
              <Box className={classes.tooltipRowLabel}>
                <Box className={classes.tooltipColorIcon} sx={{ backgroundColor: legendElement.color }}></Box>
                <p className={classes.legendText}>{legendElement.title}</p>
              </Box>
              <p className={classes.tooltipNumber}>{payload[0]?.payload[legendElement.title.toLowerCase()]}</p>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  return null;
};
