import { styled } from '#/utils/theme';

export const Container = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  backgroundColor: theme.custom.PALETTES.OTHER.White,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100svh',
  padding: theme.custom.DEFAULT_SPACING['6'],
}));

export const Text = styled('p')(({ theme }) => ({
  fontSize: theme.custom.FONT_SIZE.xxl,
  color: theme.custom.PALETTES.TEXT.Dark,
}));
