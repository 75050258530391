import { Box } from '@mui/material';
import { useState } from 'react';

import { Campaigns } from '#/components/features/campaigns/Campaigns/Campaigns';

import CampaignIcon from '../../../assets/CampaignModal.svg';
import Megaphone from '../../../assets/Megaphone.svg';
import PlusIcon from '../../../assets/Plus.svg';
import { USER_PERMISSIONS } from '../../../constants/permissions/userPermissions';
import PermissionWrapper from '../../../utils/PermissionWrapper';
import { useCampaignStyles } from '../../campaigns/campaign.style';
import NewButton from '../../common/button/NewButton';
import DisplayPageName from '../../common/displayPageName/DisplayPageName';
import AddNewCampaign from '../../common/forms/campaigns/AddNewCampaign';
import Loader from '../../common/loader/loader';
import Modal from '../../common/modal/Modal';
import useGetPeriodsAndMarketingDay from './useGetPeriodsAndMarketingDay';

const CampaignsView = () => {
  const classes = useCampaignStyles();

  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const { periods, retailerAccountCalendar, retailerAccountCalendarLoading, periodsLoading } =
    useGetPeriodsAndMarketingDay();

  if (retailerAccountCalendarLoading || periodsLoading) return <Loader />;

  return (
    <>
      <Box className={classes.campaignPageHeader}>
        <DisplayPageName imgSrc={Megaphone} pageName="Campaigns" />
        <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.CAMPAIGN_CREATE]}>
          <NewButton
            icon={PlusIcon}
            iconPosition="left"
            loading={retailerAccountCalendarLoading || periodsLoading}
            onClick={openModal}
            text="Create Campaign"
          />
        </PermissionWrapper>
      </Box>
      <Campaigns />
      <Modal
        content={
          <AddNewCampaign
            handleClose={closeModal}
            periods={periods}
            retailerAccountCalendar={retailerAccountCalendar}
          />
        }
        handleCloseModal={closeModal}
        icon={CampaignIcon}
        modalTitle="Create Campaign"
        open={open}
        size={'wide'}
      />
    </>
  );
};
export default CampaignsView;
