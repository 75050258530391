import { IEngagementStatsData } from '#/store/api/campaigns/campaignEngagementInterfaces';

import { IResource } from '../../../hooks/useGetResource';
import { baseApi } from '../../base';

const campaignEngagement = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCampaignEngagement: builder.query<IEngagementStatsData[], { resource: IResource }>({
      query: ({ resource }) => `/retailer/campaign-engagement?campaignId=${resource.resourceId}`,
      providesTags: ['CAMPAIGN_ENGAGEMENT_STATS'],
    }),
  }),
});

export const { useGetCampaignEngagementQuery } = campaignEngagement;

export { campaignEngagement };
