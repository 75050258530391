import { TableCell, Tooltip } from '@mui/material';
import classnames from 'classnames';

import { ITableColumnsProps, TableColumnAlign } from '../../../../../interfaces/table/table';
import Checkbox from '../../../inputs/Checkbox/Checkbox';
import { tableDefaultPadding, tooltipSx, useTableStyles } from '../Table.style';

export const TableHeadCell = <T,>({
  column,
  headCellClass,
  tablePadding,
  headCustomStyle,
}: {
  column: ITableColumnsProps<T>;
  headCellClass?: string;
  tablePadding?: string;
  headCustomStyle?: { [key: string]: string };
}) => {
  const classes = useTableStyles();

  const getColumnElement = (column: ITableColumnsProps<T>) => {
    if (column.hasCheckAll) {
      return (
        <Checkbox
          indeterminate={column.indeterminateCheckAll}
          onChange={(event) => {
            if (column.checkAllAction && column.actionElementIdentifier) {
              return column.checkAllAction(Boolean(event.target.checked));
            }
          }}
          value={column.checkAllValue}
        />
      );
    } else {
      return column.customHead ? String(column.customHead) : String(column.fieldName);
    }
  };

  // this approach will be removed once classes are fixed
  const tableHeadStyle = {
    padding: tablePadding ? tablePadding : tableDefaultPadding.padding,
    ...(headCustomStyle && { ...headCustomStyle }),
  };

  const cellElement = (
    <TableCell
      key={String(column.fieldName)}
      align={column.align || TableColumnAlign.LEFT}
      className={classnames(classes.tableHeadCell, classes.tableCell, headCellClass, {
        hidden: column.hide,
      })}
      style={tableHeadStyle}
    >
      {getColumnElement(column)}
    </TableCell>
  );

  if (column.headerTooltip) {
    return (
      <Tooltip
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': tooltipSx,
          },
        }}
        disableFocusListener
        disableTouchListener
        placement="top-start"
        title={column.headerTooltip}
      >
        {cellElement}
      </Tooltip>
    );
  }

  return cellElement;
};
