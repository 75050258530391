import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const useUserSettingsStyle = makeStyles((theme) =>
  createStyles({
    container: {
      background: '#FFF',
      padding: '1rem',
      borderRadius: '1rem',
    },
    content: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    tableHeadCell: {
      '&&': {
        height: '2.5rem',
        fontWeight: 600,
        border: 'none',
        borderBottom: `1px solid ${THEME.PALETTES.UI.Neutral[900]}`,
        background: THEME.PALETTES.OTHER.White,
      },
    },
    tableContainer: {
      '&& > table': {
        border: 'none',
      },
    },
    tableBodyCell: {
      height: '2.5rem',
      borderLeft: 'none',
      borderRight: 'none',
    },
    sortFilterContainer: {
      display: 'flex',
      gap: '0.5rem',
      alignItems: 'center',
    },
    statusContainer: {
      display: 'flex',
      columnGap: '0.5rem',
      alignItems: 'center',
    },
    statusItem: {
      padding: '0 0.625rem',
      borderRadius: '0.5rem',
      cursor: 'pointer',
      userSelect: 'none',
      display: 'flex',
      alignItems: 'center',
    },
    statusItemSelected: {
      background: '#EBE9F9',
    },
    mb1: {
      '&&': {
        marginBottom: THEME.REM_SPACES['1'],
      },
    },
    tableButtons: {
      display: 'flex',
      justifyContent: 'center',
    },
    customHrefColor: {
      '&& > a': {
        color: theme.custom.PALETTES.TEXT.Action_Secondary,
        textDecoration: 'none',
        fontWeight: theme.custom.FONT_WEIGHTS.BOLD,
      },
    },
  }),
);

export const SelecLabelSx = {
  fontSize: '1rem',
  fontWeight: 500,
  letterSpacing: '0rem',
  textTransform: 'uppercase',
  color: '#707070',
};
export const SelectSx = {
  height: '1.875rem',
  width: '8.75rem',
};
