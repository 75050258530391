import { Box } from '@mui/material';

import ReportsIcon from '#/assets/ReportsMenuBlack.svg';

import DisplayPageName from '#/components/common/displayPageName/DisplayPageName';
import { useSnackbarError } from '#/components/common/snackbar/useSnackbarError';
import { generateUrl } from '#/components/features/reports/utils';

import { RevenueByMonthFields } from '#/interfaces/reports/reports';

import { useGetRevenueByMonthQuery, useGetRevenueByYearQuery } from '#/store/api/dashboard/dashboard';

import { pageNameCustomStyle, useChartsStyles } from '../Dashboard.styles';
import { BarCharts } from './BarCharts';

export const Charts: React.FunctionComponent = (): JSX.Element => {
  const classes = useChartsStyles();
  const { setError } = useSnackbarError();

  const {
    data: revenueByMonthData,
    isLoading: revenueByMonthIsLoading,
    isFetching: revenueByMonthIsFetching,
    error: revenueByMonthError,
    isError: revenueByMonthIsError,
    refetch: revenueByMonthRefetch,
  } = useGetRevenueByMonthQuery();

  const {
    // data: revenueByYearData,
    // isLoading: revenueByYearIsLoading,
    isFetching: revenueByYearIsFetching,
    error: revenueByYearError,
    isError: revenueByYearIsError,
    refetch: revenueByYearRefetch,
  } = useGetRevenueByYearQuery();

  if (revenueByMonthIsError) {
    setError(revenueByMonthError);
  }

  if (revenueByYearIsError) {
    setError(revenueByYearError);
  }

  const handleRefetch = () => {
    revenueByMonthRefetch();
    revenueByYearRefetch();
  };

  const redirectTo = revenueByMonthData && generateUrl<RevenueByMonthFields>(revenueByMonthData, 'redirectReportId');

  return (
    <Box className={classes.wrapperDiv}>
      <DisplayPageName
        customStyles={pageNameCustomStyle}
        dataLoading={revenueByMonthIsFetching || revenueByYearIsFetching}
        imgSrc={ReportsIcon}
        pageName="Campaign Monthly Revenue"
        redirectTo={redirectTo}
        refetch={handleRefetch}
      />
      <Box className={classes.chartsContainer}>
        <BarCharts
          data={revenueByMonthData}
          isFetching={revenueByMonthIsFetching}
          isLoading={revenueByMonthIsLoading}
          refetch={revenueByMonthRefetch}
        />
        {/* <PieCharts
          data={revenueByYearData}
          isFetching={revenueByYearIsFetching}
          isLoading={revenueByYearIsLoading}
          refetch={revenueByYearRefetch}
        /> */}
      </Box>
    </Box>
  );
};
