import { ICreativeSpecs } from '#/store/api/campaigns/createiveSpecsInterfaces';

import { IResource } from '../../../hooks/useGetResource';
import { baseApi } from '../../base';

const creativeSpecs = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOverviewCreativeSpecs: builder.query<ICreativeSpecs, { resource: IResource }>({
      query: ({ resource }) => `/retailer/${resource.resourceType}/${resource.resourceId}/overview/creative-specs`,
      providesTags: ['CAMPAIGN_CREATIVE_SPECS'],
    }),
    putOverviewCreativeSpecs: builder.mutation<void, { resource: IResource; fileIds: string[] }>({
      query: ({ resource, fileIds }) => ({
        url: `/retailer/${resource.resourceType}/${resource.resourceId}/overview/creative-specs`,
        method: 'PUT',
        body: {
          fileIds,
        },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_CREATIVE_SPECS']),
    }),
  }),
});

export const { useGetOverviewCreativeSpecsQuery, usePutOverviewCreativeSpecsMutation } = creativeSpecs;

export { creativeSpecs };
