import { makeStyles, createStyles } from '#/utils/theme';

export const useWYSIWYGEditorStyles = makeStyles(() =>
  createStyles({
    editorWrapper: {
      border: '1px solid #E4E8EF',
      padding: '16px',
      borderRadius: '8px',
      minHeight: '200px',
      overflow: 'hidden'
    },
    editor: {
      minHeight: '200px',
    },
  }),
);
