import { createStyles, makeStyles } from '#/utils/theme';

export const useGeneralInformationRowStyles = makeStyles(() =>
  createStyles({
    row: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);
