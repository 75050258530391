import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const useTextFieldStyle = makeStyles((theme) =>
  createStyles({
    input: {
      '&& input': {
        padding: `calc(${THEME.REM_SPACES['0.5']} - 1px);`,
        borderRadius: '10px',
      },
      '& .Mui-disabled': {
        opacity: '2',
      },
    },
    labelUppercase: {
      textTransform: 'uppercase',
    },
    readOnly: {
      '&& input': {
        padding: `calc(${THEME.REM_SPACES['0.5']} - 1px);`,
        '-webkit-text-fill-color': THEME.PALETTES.TEXT.Dark,
      },
      '&& ::placeholder': {
        opacity: '1',
        '-webkit-text-fill-color': THEME.PALETTES.TEXT.Dark,
        userSelect: 'text',
      },
    },
  }),
);

export const styles = {
  input: {
    display: 'flex',
    alignSelf: 'center',
    textAlign: 'center',
    alignItems: 'center',
    flex: '1 0 0',
    borderRadius: THEME.REM_SPACES['0.5'],
    fontFamily: 'Public Sans',
    fontSize: '0.813rem',
    fontWeight: 400,
    background: THEME.PALETTES.TEXT.Light,
  } as React.CSSProperties,
  disabled: {
    borderRadius: THEME.REM_SPACES['0.5'],
    border: THEME.PALETTES.UI.Neutral['200'],
    background: THEME.PALETTES.UI.Neutral['200'],
  },
  fixedHeight: {
    height: THEME.REM_SPACES['2'],
  },
};

export const disabledInputSx = {
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: 'rgba(0, 0, 0, 0.38)',
  },
};
