import { ISingleUserData, IUserView } from '#/store/api/users/usersInterfaces';

import { ICreateUserBrand } from '../interfaces/forms/users';
import { IUserCompanyData } from '../interfaces/user/user';
import { retailerAxios, userAxios } from './axiosConfig';
import { IRequestDto } from './requestDtoInterface';

export const getUserData = (currentUserId: string): Promise<IRequestDto<ISingleUserData>> => {
  return userAxios.get(`user/${currentUserId}`);
};

export const getAllUsers = (): Promise<IRequestDto<IUserView[]>> => {
  return userAxios.get(`user/all`);
};

export const createBrandUser = (data: ICreateUserBrand): Promise<IRequestDto<ICreateUserBrand>> => {
  return retailerAxios.post('/company-user/signup/form', data);
};

export const deleteCompanyUserSignUp = (email: string) => {
  return retailerAxios.delete('/company-user/signup', { data: { email } });
};

export const getCompanyProfileInfo = (): Promise<IRequestDto<IUserCompanyData>> => {
  return retailerAxios.get('company-account/company-profile');
};
