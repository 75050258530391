import { Box } from '@mui/material';

import { USER_PERMISSIONS } from '../../../../../../constants/permissions/userPermissions';
import { getUserHasPermission } from '../../../../../../constants/permissions/utils';
import useGetResource from '../../../../../../hooks/useGetResource';
import { IChannelAndTacticsResponseTier } from '../../../../../../interfaces/forms/campaigns';
import { useGetCampaignTiersQuery } from '../../../../../../store/api/campaigns/campaignChannelsAndTactics';
import PermissionWrapper from '../../../../../../utils/PermissionWrapper';
import Loader from '../../../../../common/loader/loader';
import { TypographyWithFontFamily } from '../../../../../common/typography/TypographyWithFontFamily';
import CampaignTier from '../../../CampaignTier';
import CreateCampaignTier from '../../../CreateCampaignTier';
import { useCampaignChannelsAndTacticsStyle } from './CampaignChannelsAndTactics.style';

const CampaignChannelsAndTactics = () => {
  const classes = useCampaignChannelsAndTacticsStyle();
  const userHasCreatePermission = getUserHasPermission(USER_PERMISSIONS.CAMPAIGN_CHANNEL_TACTICS_CREATE);
  const resource = useGetResource();
  const { data, isFetching, refetch } = useGetCampaignTiersQuery({ resource });

  return (
    <Box className={classes.channelAndTacticsContainer}>
      <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.CAMPAIGN_CHANNEL_TACTICS_CREATE]}>
        <CreateCampaignTier />
      </PermissionWrapper>
      {isFetching ? (
        <Loader />
      ) : data ? (
        data?.tiers?.map((tier: IChannelAndTacticsResponseTier) => (
          <Box key={tier.tierName} className={classes.tierContainer}>
            <CampaignTier tier={tier} tierRefetch={refetch} userHasCreatePermission={userHasCreatePermission} />
          </Box>
        ))
      ) : (
        <TypographyWithFontFamily variant={'Default_Bold'}>No tiers added</TypographyWithFontFamily>
      )}
    </Box>
  );
};

export default CampaignChannelsAndTactics;
