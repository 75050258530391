import { IPendingInvitation, PromoteCampaigns } from '#/interfaces/promote/promote';

import { SearchFilters } from '#/store/api/campaigns/campaignsInterfaces';

import { baseApi } from '../../base';

const promoteApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPendingInvitationsFilters: builder.query<{ filters: SearchFilters[] }, unknown>({
      query: () => `retailer/promote/filters`,
    }),

    getPendingInvitations: builder.query<
      PromoteCampaigns<IPendingInvitation>,
      {
        pageNumber: number;
        numberOfRows: number;
        searchQuery: string;
      }
    >({
      query: ({ pageNumber, numberOfRows, searchQuery = '' }) => {
        const searchParams = new URLSearchParams();
        searchParams.set('pageNumber', pageNumber.toString());
        searchParams.set('numberOfRows', numberOfRows.toString());
        searchParams.set('searchQuery', searchQuery);
        return `retailer/promote/pending-invitations?${searchParams}`;
      },
    }),
  }),
});

export const { useGetPendingInvitationsQuery, useGetPendingInvitationsFiltersQuery } = promoteApi;
