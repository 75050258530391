import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';

import { useSnackbarError } from '#/components/common/snackbar/useSnackbarError';
import { BASE_PATH, HOST_URL } from '#/constants/general';

import { useAppSelector } from '#/hooks';

import { usePostCampaignMutation } from '#/store/api/campaigns/campaigns';
import { PostCampaignForm } from '#/store/api/campaigns/campaignsInterfaces';

import { formatDate } from '#/utils/utils';

export const useAddNewCampaignForm = () => {
  const [triggerCall, result] = usePostCampaignMutation();
  const { setError } = useSnackbarError();

  const schema = yup
    .object()
    .shape({
      name: yup.string().required(),
      goal: yup.string().required(),
      brandsTargeted: yup.number().required(),
      startDate: yup.date(),
      submissionDeadline: yup
        .date()
        .required()
        .max(yup.ref('startDate'))
        .typeError('Submission deadline must be before start date'),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm<PostCampaignForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      startDate: dayjs().format('MM/DD/YYYY'),
      endDate: dayjs().format('MM/DD/YYYY'),
      brandsTargeted: 1,
      goal: '0',
      submissionDeadline: dayjs().subtract(1, 'day').format('MM/DD/YYYY'),
    },
  });
  const { user } = useAppSelector((state) => state.user);

  const onSubmit = handleSubmit(async (formData: PostCampaignForm) => {
    if (!user) return;
    try {
      const body = {
        ...formData,
        startDate: formatDate(new Date(formData.startDate)),
        endDate: formatDate(new Date(formData.endDate)),
        submissionDeadline: formatDate(new Date(formData.submissionDeadline)),
        ownerId: user.userId,
      };
      const response = await triggerCall({ body }).unwrap();
      const newCampaignOverviewLink = `${HOST_URL}${BASE_PATH}/campaigns/overview/${response}`;

      window.location.replace(newCampaignOverviewLink);
    } catch (error) {
      setError((error as FetchBaseQueryError).data);
    }
  });

  return {
    register,
    onSubmit,
    errors,
    handleSubmit,
    setValue,
    control,
    trigger,
    watch,
    isLoading: result.isLoading,
    error: result.error,
  };
};
