import { BrandOrdersBrandContainer } from '#/components/features/campaigns/BrandOrders/BrandOrdersBrand/BrandOrdersBrandContainer';
import { BrandOrdersChannelPartnerContainer } from '#/components/features/campaigns/BrandOrders/BrandOrdersChannelPartner/BrandOrdersChannelPartnerContainer';
import { BrandOrdersRetailerContainer } from '#/components/features/campaigns/BrandOrders/BrandOrdersRetailer/BrandOrdersRetailerContainer';

import UserTypeWrapper from '#/utils/UserTypeWrapper';

const BrandOrders = () => (
  <UserTypeWrapper
    agency={<BrandOrdersBrandContainer />}
    brand={<BrandOrdersBrandContainer />}
    channel-partner={<BrandOrdersChannelPartnerContainer />}
    retailer={<BrandOrdersRetailerContainer />}
  />
);

export default BrandOrders;
