import { Autocomplete, AutocompleteChangeReason, Box, Chip, TextField } from '@mui/material';
import { FC } from 'react';

import { IAttribute, ICategory, ISubcategory } from '#/api/common/productMix';

import { THEME } from '#/constants/theme/constants';

import { TypographyWithFontFamily } from '../typography/TypographyWithFontFamily';
import { useProductMixInputStyles } from './ProductMixInput.style';

interface ProductMixInputProps {
  options: ICategory[] | ISubcategory[] | IAttribute[];
  selectedOptions: ICategory[] | ISubcategory[] | IAttribute[];
  //FIXME: fix any
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  onOptionChange: (event: unknown, value: any, reason: AutocompleteChangeReason) => void;
  label: string;
  subLabel: string;
  editMode: boolean;
  noSelectedOptionsMessage: string;
}
const ProductMixInput: FC<ProductMixInputProps> = ({
  options,
  selectedOptions,
  onOptionChange,
  label,
  subLabel,
  editMode = false,
  noSelectedOptionsMessage,
}) => {
  const classes = useProductMixInputStyles();

  const getOptionLabel = (option: ICategory & ISubcategory & IAttribute) =>
    option.categoryName || option.subcategoryName || option.attributeName;

  const renderInput = (params) => (
    <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
        className: classes.autoCompleteInput,
        type: 'search',
      }}
      size="small"
    />
  );

  return (
    <Box className={classes.container}>
      <TypographyWithFontFamily variant="H6_Base">{label}</TypographyWithFontFamily>
      <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} variant="Small_Base">
        {subLabel}
      </TypographyWithFontFamily>
      {editMode ? (
        <Autocomplete
          disableClearable
          freeSolo={false}
          fullWidth
          //@TODO - this type makes no sense as string
          getOptionLabel={getOptionLabel as (option: ICategory & ISubcategory & IAttribute) => string}
          id="tags-standard"
          isOptionEqualToValue={(option, value) => option.id === value.id}
          multiple
          onChange={onOptionChange}
          options={options as (ICategory & ISubcategory & IAttribute)[]}
          renderInput={renderInput}
          value={selectedOptions as (ICategory & ISubcategory & IAttribute)[]}
        />
      ) : (
        <Box className={classes.chipsContainer}>
          {selectedOptions.length !== 0 ? (
            selectedOptions.map((option) => <Chip key={option.id} label={getOptionLabel(option)} />)
          ) : (
            <TypographyWithFontFamily>{noSelectedOptionsMessage}</TypographyWithFontFamily>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ProductMixInput;
