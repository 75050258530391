import { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { BASE_PATH, HOST_URL } from '#/constants/general';

import { TaskTableColumns } from '../../../constants/tables/taskTable/tasksTable';
import { useGetTasksQuery } from '../../../store/api/tasks/tasks';
import Card from '../../common/card/card';
import Loader from '../../common/loader/loader';
import { NoDataMessage } from '../../common/noDataMessage/NoDataMessage';
import { useSnackbarError } from '../../common/snackbar/useSnackbarError';
import { SubmenuBar } from '../../common/submenuBar/SubmenuBar';
import { Table } from '../../common/table/tableComponent/Table';
import { TableRefresh } from '../../common/tableRefresh/TableRefresh';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import { refetchButtonStyle, styles, useTaskViewStyles } from './TasksView.styles';
import { tasksSubmenu } from './tasksSubmenuOptions';

const TasksView = () => {
  const location = useLocation();
  const selectedTasksStatus = location.pathname.split('tasks/')[1];
  const classes = useTaskViewStyles();
  const { setError } = useSnackbarError();

  const { data, error, isError, isFetching, refetch } = useGetTasksQuery({ status: selectedTasksStatus });
  const goToTarget = ({ rowData }) => {
    const { targetObjectName, targetObjectPath } = rowData;
    const campaignUrl = `${HOST_URL}${BASE_PATH}${targetObjectPath}`;

    return (
      <Link className={classes.targetObjectLink} to={campaignUrl}>
        <TypographyWithFontFamily sx={styles.targetObjectText}>{targetObjectName}</TypographyWithFontFamily>
      </Link>
    );
  };

  let renderedElement: ReactElement = <></>;

  if (isError) {
    setError(error);
  }

  if (!data || !data.length) {
    renderedElement = <NoDataMessage dataLoading={isFetching} message="No Tasks Available" refetch={refetch} />;
  }

  if (data && data.length) {
    const { TaskTable } = TaskTableColumns(goToTarget);

    renderedElement = (
      <Card>
        <TableRefresh customStyle={refetchButtonStyle} dataLoading={isFetching} refetch={refetch} />
        {isFetching ? (
          <Loader />
        ) : (
          <Table
            bodyCellClass={classes.bodyCellClass}
            columns={TaskTable}
            data={data}
            displayStatusColor
            headCellClass={classes.headCellClass}
            headClass={classes.headClass}
            tableBodyPadding="15px"
            tableHeadPadding="15px"
          />
        )}
      </Card>
    );
  }

  return (
    <>
      <SubmenuBar locationIndex={2} tabs={tasksSubmenu} />
      {renderedElement}
    </>
  );
};
export default TasksView;
