import { Autocomplete, TextField } from '@mui/material';
import { Box } from '@mui/system';
import classNames from 'classnames';
import React from 'react';

import { ICompanyTable } from '../../../interfaces/forms/brands';
import { useBrandStyles } from '../../features/partners/brands/brands.styles';

interface IAutoCompleteFilter {
  // FIXME: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // FIXME: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFilteredData: React.Dispatch<React.SetStateAction<any>>;
  filterBy: string;
  withCard?: boolean;
  fullWidth?: boolean;
  customStyle?: string;
}

const AutoCompleteFilter = ({
  data,
  setFilteredData,
  filterBy,
  withCard = true,
  fullWidth = true,
  customStyle,
}: IAutoCompleteFilter) => {
  const classes = useBrandStyles();
  const filteredData = (value: string) =>
    data.filter((item: ICompanyTable) => item[filterBy].toLowerCase().includes(value.toLowerCase()));

  const clearFilteredData = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    e.target.value === '' && setFilteredData([]);

  return (
    <Box
      className={classNames(
        customStyle,
        classes.autocompleteWidth,
        withCard && classes.card,
        !fullWidth && classes.marginLeft,
      )}
    >
      <Autocomplete
        disableClearable
        disabled={data?.length === 0}
        freeSolo
        onChange={(e, value: string) => setFilteredData(filteredData(value))}
        options={data?.map((option: { filterBy: string }) => option[filterBy])}
        renderInput={(params) => (
          <TextField
            onChange={clearFilteredData}
            {...params}
            InputProps={{
              ...params.InputProps,
              className: classes.autocompleteInput,
              type: 'search',
            }}
            placeholder="Filter"
            size="small"
          />
        )}
      />
    </Box>
  );
};
export default AutoCompleteFilter;
