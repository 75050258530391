import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import ArrowDownIcon from '#/assets/ArrowDownIcon.svg';

import AssetFileInput from '#/components/campaigns/campaignAssets/AssetFileInput';
import DownloadAssetsFiles from '#/components/campaigns/campaignAssets/DownloadAssetsFiles';
import DownloadTraffickingSheet from '#/components/campaigns/campaignAssets/DownloadTraffickingSheet';
import {
  accordionDetailsSx,
  accordionSummarySx,
  accordionSx,
  useTacticsAssetsStyle,
} from '#/components/campaigns/campaignAssets/TacticsAssets.style';
import TraffickingSheetLink from '#/components/campaigns/campaignAssets/TraffickingSheetLink';
import AssetProduct from '#/components/campaigns/campaignAssets/assetsProduct/AssetProduct';
import AlertError from '#/components/common/Alert/AlertError/AlertError';
import NewButton from '#/components/common/button/NewButton';
import Loader from '#/components/common/loader/loader';
import { CheckBoxListInput } from '#/components/common/tacticMetadataForm/inputs/CheckBoxListInput';
import { CheckBoxSingleOptionInput } from '#/components/common/tacticMetadataForm/inputs/CheckBoxSingleOptionInput';
import { DropdownInput } from '#/components/common/tacticMetadataForm/inputs/DropdownInput';
import { NumericInput } from '#/components/common/tacticMetadataForm/inputs/NumericInput';
import { RadioBoxInput } from '#/components/common/tacticMetadataForm/inputs/RadioBoxInput';
import { TextAreaInput } from '#/components/common/tacticMetadataForm/inputs/TextAreaInput';
import { TextInput } from '#/components/common/tacticMetadataForm/inputs/TextInput';
import { extractInputsFromMetadata, getFormResetValues } from '#/components/common/tacticMetadataForm/utils';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import { useAssetMetadataForm } from '#/hooks/forms/assetsMetadata/useAssetsMetadataForm';
import useGetResource from '#/hooks/useGetResource';

import { IInstanceTactics } from '#/interfaces/forms/tacticAssets';
import { InputTypes } from '#/interfaces/tacticMetadata/tacticMetadata';

import { useGetInstanceQuery } from '#/store/api/campaigns/instances';
import { useGetInstanceAssetMetadataQuery } from '#/store/api/campaigns/tacticAssetForm';

import PermissionWrapper from '#/utils/PermissionWrapper';

import EditIcon from '../../../assets/Edit.svg';
import { useAppSelector } from '#/hooks';
import { UsersType } from '#/constants/usersRoles/usersRole';

interface ICampaignAssetsFormProps {
  tactic: IInstanceTactics;
  formIndex: number;
  updateFormStatus: (index: number, isValid: boolean) => void;
}

const CampaignAssetsForm: FC<ICampaignAssetsFormProps> = ({ tactic, formIndex, updateFormStatus }) => {
  const {
    data: tacticsMetadataData,
    refetch,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetInstanceAssetMetadataQuery({ id: tactic.instanceTacticId });
  const { user: userData } = useAppSelector((state) => state.user);

  const classes = useTacticsAssetsStyle();
  const inputFields = tacticsMetadataData ? extractInputsFromMetadata(tacticsMetadataData) : [];
  const [editMode, setEditMode] = useState(false);

  const {
    onSubmit,
    register,
    errors,
    control,
    watch,
    reset,
    setValue,
    isLoading: saveIsLoading,
    error,
  } = useAssetMetadataForm(inputFields, tactic.instanceTacticId, async () => {
    await refetch().unwrap();
    setEditMode(false);
  });

  const { resourceId } = useGetResource();
  const { data: headerData } = useGetInstanceQuery({ instanceId: resourceId });
  const canEdit = headerData?.instanceStatus !== 'COMPLETE' && headerData?.instanceStatus !== 'CANCELED';

  useEffect(() => {
    if (!tacticsMetadataData) return;

    const metadata = tacticsMetadataData.metadata;
    const hasRequiredFields = metadata.some((item) => item.metadataAttribute.required && !item.metadataValue);

    updateFormStatus(formIndex, !hasRequiredFields);
  }, [tacticsMetadataData]);

  useEffect(() => {
    if (!tacticsMetadataData || !isSuccess) return;

    const defaultFields = getFormResetValues(tacticsMetadataData);
    reset(defaultFields);
  }, [tacticsMetadataData, isSuccess, reset]);

  const onCancel = () => {
    if (!tacticsMetadataData) return;

    const defaultFields = getFormResetValues(tacticsMetadataData);
    reset(defaultFields);
    setEditMode(false);
  };

  const returnInputs = inputFields.map((data) => {
    const commonProps = {
      editMode,
      errors,
      input: data,
      watch,
      register,
      setValue,
    };

    switch (data.inputType) {
      case InputTypes.TEXT:
        return (
          <Box key={data.id} className={classes.inputContainer}>
            <TextInput {...commonProps} helperTextSubtitle />
          </Box>
        );
      case InputTypes.AREA:
        return (
          <Box key={data.id} className={classes.inputContainer}>
            <TextAreaInput {...commonProps} helperTextSubtitle />
          </Box>
        );
      case InputTypes.NUMBER:
        return (
          <Box key={data.id} className={classes.inputContainer}>
            <NumericInput {...commonProps} helperTextSubtitle />
          </Box>
        );
      case InputTypes.DROPDOWN:
        return (
          <Box key={data.id} className={classes.inputContainer}>
            <DropdownInput {...commonProps} control={control} helperTextSubtitle />
          </Box>
        );
      case InputTypes.CHECKBOX_LIST:
        return (
          <Box key={data.id} className={classes.inputContainer}>
            <CheckBoxListInput {...commonProps} control={control} helperTextSubtitle />
          </Box>
        );
      case InputTypes.SINGLE_OPTION_CHECKBOX:
        return (
          <Box key={data.id} className={classes.inputContainer}>
            <CheckBoxSingleOptionInput {...commonProps} control={control} helperTextSubtitle />
          </Box>
        );
      case InputTypes.RADIO:
        return (
          <Box key={data.id} className={classes.inputContainer}>
            <RadioBoxInput {...commonProps} control={control} helperTextSubtitle />
          </Box>
        );
      case InputTypes.FILE:
        return (
          <Box key={data.id} className={classes.inputContainer}>
            <AssetFileInput {...commonProps} tacticId={tactic.instanceTacticId} />
          </Box>
        );
      case InputTypes.PRODUCT_SELECTOR:
        return (
          <Box key={data.id} className={classes.inputContainer}>
            <AssetProduct {...commonProps} instanceTacticId={tactic.instanceTacticId} />
          </Box>
        );
      default:
        return null;
    }
  });

  return (
    <Accordion defaultExpanded sx={accordionSx}>
      <Box className={classes.assetHeader}>
        <AccordionSummary expandIcon={<img src={ArrowDownIcon} />} sx={accordionSummarySx}>
          <Box className={classes.headerText}>
            <TypographyWithFontFamily variant={'H6_Base'}>{tactic.tactic}</TypographyWithFontFamily>
            <TypographyWithFontFamily>
              {tactic.channel} / {tactic.subChannel}: {tactic.channelPartnerName}
            </TypographyWithFontFamily>
            <TypographyWithFontFamily>Due Date: {tactic.creativeDueDate}</TypographyWithFontFamily>
          </Box>
        </AccordionSummary>
        {userData?.userType !== UsersType.AGENCY && userData?.userType !== UsersType.BRAND && (
          <AccordionActions>
            <TraffickingSheetLink tacticId={tactic.instanceTacticId} />
            <DownloadTraffickingSheet tacticId={tactic.instanceTacticId} />
            <DownloadAssetsFiles tacticId={tactic.instanceTacticId} />
          </AccordionActions>
        )}
      </Box>
      <AlertError error={error} />

      <AccordionDetails sx={accordionDetailsSx}>
        {isLoading || isFetching ? (
          <Loader />
        ) : (
          <form encType="multipart/form-data" onSubmit={onSubmit}>
            {canEdit && (
              <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.CAMPAIGN_INSTANCE_ASSETS_WRITE]}>
                <Box className={classes.topButtonsContainer}>
                  {editMode ? (
                    <>
                      <NewButton onClick={onCancel} text={'Cancel'} variant={'Secondary'} />
                      <NewButton loading={saveIsLoading} text={'Save'} type={'submit'} />
                    </>
                  ) : (
                    <NewButton
                      icon={EditIcon}
                      iconPosition={'left'}
                      onClick={() => setEditMode(true)}
                      text={'Edit'}
                      variant={'Ghost'}
                    />
                  )}
                </Box>
              </PermissionWrapper>
            )}
            {returnInputs}
          </form>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CampaignAssetsForm;
