import { Box, FormHelperText, Grid } from '@mui/material';
import { useState } from 'react';

import { TopSection } from '#/components/campaigns/topSection/TopSection';
import SingleDownloadUpload from '#/components/common/downloadUpload/SingleDownloadUpload';
import { useCompanyBillingFormStyles } from '#/components/common/forms/companies/CompanyBillingForm/CompanyBillingForm.styles';
import RadioGroup from '#/components/common/inputs/RadioGroup/RadioGroup';
import Select from '#/components/common/inputs/Select/Select';
import TextField from '#/components/common/inputs/TextField/TextField';
import Loader from '#/components/common/loader/loader';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import useGetResource, { RESOURCE_TYPES } from '#/hooks/useGetResource';

import { UsStates } from '../../../../../constants/statesPlaceholder';
import { useEditCompanyBillingForm } from '../../../../../hooks/forms/companies/useEditCompanyBillingForm';
import Card from '../../../card/card';
import { TypographyWithFontFamily } from '../../../typography/TypographyWithFontFamily';

const CompanyBillingForm = () => {
  const classes = useCompanyBillingFormStyles();
  const { resourceType } = useGetResource();

  const requiredPermission =
    resourceType === RESOURCE_TYPES.agencies
      ? USER_PERMISSIONS.PARTNERS_COMPANY_AGENCY_BILLING_SECTION_WRITE
      : USER_PERMISSIONS.PARTNERS_COMPANY_BRAND_BILLING_SECTION_WRITE;

  const [editMode, setEditMode] = useState(false);

  const {
    onSubmit,
    errors,
    register,
    setValue,
    isLoading: putCompanyUserContactTypeLoading,
    dataLoading,
    reset,
    refetch,
    watch,
    error,
  } = useEditCompanyBillingForm(() => setEditMode(false));

  return (
    <Card>
      <form onSubmit={onSubmit}>
        <TopSection
          dataLoading={dataLoading}
          editMode={editMode}
          error={error}
          isLoading={putCompanyUserContactTypeLoading}
          onSubmit={onSubmit}
          refetch={refetch}
          requiredPermission={requiredPermission}
          reset={reset}
          sectionName="Billing Info"
          setEditMode={setEditMode}
        />
        {dataLoading ? (
          <Loader />
        ) : (
          <>
            <Grid className={classes.viewGrid} container>
              <Grid item xs={3.3}>
                <TextField
                  disabled={!editMode}
                  error={errors?.billingAddress1?.message}
                  label="Line Address 1"
                  onChange={(event) => setValue('billingAddress1', event.target.value)}
                  value={watch('billingAddress1')}
                />
              </Grid>
              <Grid item xs={3.3}>
                <TextField
                  disabled={!editMode}
                  error={errors?.billingAddress2?.message}
                  label="Line Address 2"
                  onChange={(event) => setValue('billingAddress2', event.target.value)}
                  value={watch('billingAddress2')}
                />
              </Grid>
              <Grid item xs={3.3}>
                <TextField
                  disabled={!editMode}
                  error={errors?.billingCity?.message}
                  label="City"
                  onChange={(event) => setValue('billingCity', event.target.value)}
                  value={watch('billingCity')}
                />
              </Grid>
              <Grid item xs={3.3}>
                <Select
                  key={watch('billingState')}
                  defaultValue={watch('billingState')}
                  editMode={editMode}
                  label="State"
                  options={UsStates.map((state) => ({ label: state.label, value: state.code }))}
                  register={register('billingState')}
                  useRegister
                />
                <FormHelperText>
                  <TypographyWithFontFamily>{errors?.billingState?.message}</TypographyWithFontFamily>
                </FormHelperText>
              </Grid>
              <Grid item xs={3.3}>
                <TextField
                  disabled={!editMode}
                  error={errors?.billingZip?.message}
                  label="Zip"
                  onChange={(event) => setValue('billingZip', event.target.value)}
                  value={watch('billingZip')}
                />
              </Grid>
              <Grid item xs={3.3}>
                <TextField
                  disabled={!editMode}
                  error={errors?.ein?.message}
                  label="Tax id"
                  onChange={(event) => setValue('ein', event.target.value)}
                  value={watch('ein')}
                />
              </Grid>
            </Grid>
            <Box className={classes.lastRow}>
              <RadioGroup
                disabled={!editMode}
                label="BILLING"
                onChange={(event) => setValue('billType', event?.target.value)}
                options={[
                  { label: 'BillBack', value: 'BILL_BACK' },
                  { label: 'Direct Billing', value: 'DIRECT_BILLING' },
                ]}
                register={register}
                registerParam="billType"
                value={watch('billType')}
              />
              <SingleDownloadUpload
                editMode={editMode}
                fileId={watch('w9FormId')}
                label="W9 Form"
                registerName="w9FormId"
                setValue={setValue}
                uploadLink="/company-account/w9form"
              />
            </Box>
          </>
        )}
      </form>
    </Card>
  );
};
export default CompanyBillingForm;
