import { Grid } from '@mui/material';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';
import { THEME } from '#/constants/theme/constants';

import { ITacticMetadataDTO, TacticMetadataForm } from '../../../../interfaces/tacticMetadata/tacticMetadata';
import { InfoTooltip } from '../../../features/reports/InfoTooltip';
import { TypographyWithFontFamily } from '../../typography/TypographyWithFontFamily';
import { errorTextSx, useTacticMetadataFormStyle } from '../TacticMetadataForm.style';

interface IProps {
  register: UseFormRegister<TacticMetadataForm>;
  errors: FieldErrors<TacticMetadataForm>;
  input: ITacticMetadataDTO;
  helperTextSubtitle?: boolean;
  editMode?: boolean;
}

export const TextInput = ({ register, errors, input, helperTextSubtitle = false, editMode = true }: IProps) => {
  const existingErrors = errors[String(input.id)]?.message || '';
  const classes = useTacticMetadataFormStyle();
  return (
    <Grid container spacing={{ xs: '1rem' }} xs={12}>
      <Grid item xs={9}>
        <TypographyWithFontFamily cName={classes.labelUppercase} color={THEME.PALETTES.TEXT.Gray} variant="Label_Base">
          {input.label} {input.required ? '*' : ''}
        </TypographyWithFontFamily>
        {helperTextSubtitle && (
          <TypographyWithFontFamily gutterBottom variant={'Label_Base'}>
            {input.helperText}
          </TypographyWithFontFamily>
        )}
        <TextFieldOld editMode={!input.isDisabled && editMode} label={''} register={register(String(input.id))} />
      </Grid>
      <Grid item xs={3}>
        {!helperTextSubtitle && input.helperText && <InfoTooltip text={input.helperText} />}
      </Grid>
      <Grid item xs={12}>
        <TypographyWithFontFamily sx={errorTextSx} variant="Default_Base">
          {String(existingErrors)}
        </TypographyWithFontFamily>
      </Grid>
    </Grid>
  );
};
