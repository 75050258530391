import { baseApi } from '../../base';
import { IPeriod } from './periodsInterfaces';

const periodsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPeriods: builder.query<IPeriod[], null>({
      query: () => `/retailer/periods`,
    }),
  }),
});

export const { useGetPeriodsQuery } = periodsApi;

export { periodsApi };
