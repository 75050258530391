import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { SearchFilters } from '#/store/api/campaigns/campaignsInterfaces';
import { CampaignInstanceBrand } from '#/store/api/campaigns/instancesInterfaces';

import { currencyFormatter } from '#/utils/textFormatters';
import { convertAllCapsUnderscoreStrings } from '#/utils/utils';

const getCampaignInstancesBrandTableColumns = (
  columNames: SearchFilters[] = [],
): ITableColumnsProps<CampaignInstanceBrand>[] => {
  const sortableColumns = columNames.map((el) => el.columnName);

  return [
    {
      fieldName: 'displayName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Name',
      linkable: true,
      baseUrl: `/instances/overview`,
      actionElementIdentifier: 'instanceId',
      sortable: sortableColumns.includes('displayName'),
    },
    {
      fieldName: 'brandName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Brand Name',
      sortable: sortableColumns.includes('brandName'),
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Status',
      textFormatter: convertAllCapsUnderscoreStrings,
      sortable: sortableColumns.includes('status'),
    },
    {
      fieldName: 'ownerName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Owner',
      sortable: sortableColumns.includes('ownerName'),
    },
    {
      fieldName: 'tier',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Tier',
      sortable: sortableColumns.includes('tier'),
    },
    {
      fieldName: 'budget',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Budget',
      textFormatter: currencyFormatter,
      itemClass: 'text-right',
      sortable: sortableColumns.includes('budget'),
    },
    {
      fieldName: 'numberOfTactics',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: '# of Tactics',
      sortable: sortableColumns.includes('numberOfTactics'),
    },
    {
      fieldName: 'bookedTacticInventory',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Booked Tactic Inventory',
      sortable: sortableColumns.includes('bookedTacticInventory'),
    },
    {
      fieldName: 'pendingAssets',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Pending Assets',
      textFormatter: (text) => text?.toString() || '0',
      sortable: sortableColumns.includes('pendingAssets'),
    },
    {
      fieldName: 'inReviewAssets',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'In Review',
      textFormatter: (text) => text?.toString() || '0',
      sortable: sortableColumns.includes('inReviewAssets'),
    },
    {
      fieldName: 'approvedAssets',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Approved',
      textFormatter: (text) => text?.toString() || '0',
      sortable: sortableColumns.includes('approvedAssets'),
    },
    {
      fieldName: 'totalAssets',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Total',
      textFormatter: (text) => text?.toString() || '0',
      sortable: sortableColumns.includes('totalAssets'),
    },
    {
      fieldName: 'instanceId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Instance ID',
      hide: true,
      isKey: true,
    },
  ];
};

export default getCampaignInstancesBrandTableColumns;
