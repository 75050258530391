import Loader from '#/components/common/loader/loader';
import Workflow from '#/components/features/settings/workflows/components/Workflow';

import { useGetWorkflowAllQuery } from '#/store/api/workflow/workflow';

const Workflows = () => {
  const { data: workflows, isLoading } = useGetWorkflowAllQuery();

  if (isLoading) return <Loader />;
  if (!workflows) return null;

  return Object.entries(workflows).map(([aggregateType, workflows]) => (
    <Workflow key={aggregateType} aggregateType={aggregateType} workflows={workflows} />
  ));
};

export default Workflows;
