import { IAddDetailsToTierForm } from '#/components/campaigns/addDetailsToSpecificTier';

import { IResource } from '../../../hooks/useGetResource';
import { ICampaignTierMetadataFields } from '../../../interfaces/campaigns/campaigns';
import { IAddTierForm, ISaveTacticsBody, ITacticsTree } from '../../../interfaces/forms/campaigns';
import { baseApi } from '../../base';
import { IChannelAndTacticsResponseDisplayShell, IEditDetails } from '../campaigns/campaignsInterfaces';

const campaignChannelAndTacticsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCampaignTiersInstance: builder.query<IChannelAndTacticsResponseDisplayShell, { resource: IResource }>({
      query: ({ resource }) => `/retailer/instances/${resource.resourceId}/tiers/all`,
      providesTags: ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS'],
    }),
    postCampaignTierInstance: builder.mutation<void, { resource: IResource; body: IAddTierForm }>({
      query: ({ resource, body }) => ({
        url: `/retailer/instances/${resource.resourceId}/tier`,
        method: 'POST',
        body: { ...body, numberOfBrands: body.targetBrands },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS']),
    }),
    putCampaignTierInstance: builder.mutation<void, { body: IAddTierForm }>({
      query: ({ body }) => ({
        url: `/retailer/instance-tier/${body.tierId}`,
        method: 'PUT',
        body: { ...body, budget: body.targetBudget, numberOfBrands: body.targetBrands },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS']),
    }),
    getChannelAndTacticsTreeInstance: builder.query<ITacticsTree, void>({
      query: () => `/retailer/channel-tactic/all/tree`,
      providesTags: ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS'],
    }),
    putCampaignTierTacticsInstance: builder.mutation<void, { body: ISaveTacticsBody }>({
      query: ({ body }) => ({
        url: `/retailer/instance-tier/${body.tierId}/tactics`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS']),
    }),
    postCopyCampaignTierInstance: builder.mutation<void, { resource: IResource; body: { tierId: string | undefined } }>(
      {
        query: ({ resource, body }) => ({
          url: `/retailer/instances/${resource.resourceId}/copy-tier`,
          method: 'POST',
          body,
        }),
        invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS']),
      },
    ),

    deleteCampaignTierInstance: builder.mutation<void, { resource: IResource; tierId: string | undefined }>({
      query: ({ resource, tierId }) => ({
        url: `/retailer/instances/${resource.resourceId}/tier/${tierId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS']),
    }),
    getCampaignTierMetadataInstance: builder.query<
      ICampaignTierMetadataFields,
      { campaignTacticId: string | undefined }
    >({
      query: ({ campaignTacticId }) => `/retailer/instance-tactic/${campaignTacticId}/tactic-metadata`,
      providesTags: ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS_METADATA'],
    }),
    putCampaignTacticBudgetInstance: builder.mutation<void, { campaignTacticId: string | undefined; budget: number }>({
      query: ({ campaignTacticId, budget }) => ({
        url: `/retailer/instance-tactic/${campaignTacticId}/tactic-budget`,
        method: 'PUT',
        body: { budget },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS']),
    }),
    putCampaignTacticTacticMetadataInstance: builder.mutation<
      void,
      { campaignTacticId: string; leadTimeInDays: number; metadataValues: { [key: string]: string } }
    >({
      query: ({ campaignTacticId, leadTimeInDays, metadataValues }) => ({
        url: `/retailer/instance-tactic/${campaignTacticId}/tactic-metadata`,
        method: 'PUT',
        body: {
          leadTimeInDays,
          metadataValues: {
            ...metadataValues,
          },
        },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS_METADATA']),
    }),
    getCampaignTiersDetailsInstance: builder.query<IEditDetails, { campaignTacticId: string | undefined }>({
      query: ({ campaignTacticId }) => `/retailer/instance-tactic/${campaignTacticId}/details`,
      providesTags: ['CAMPAIGN_CHANNEL_AND_TACTICS'],
    }),
    putCampaignTacticDetailsEditInstance: builder.mutation<
      void,
      {
        campaignTacticId: string;
        startDate: string;
        endDate: string;
        leadTime: number;
        creativeDueDate: string;
        adSize: string;
        mediaNotes: string;
        formData: IAddDetailsToTierForm;
      }
    >({
      query: ({ campaignTacticId, startDate, endDate, leadTime, creativeDueDate, adSize, mediaNotes, formData }) => ({
        url: `/retailer/instance-tactic/${campaignTacticId}/details`,
        method: 'PUT',
        body: {
          ...formData,
          startDate,
          endDate,
          leadTime,
          creativeDueDate,
          adSize,
          mediaNotes,
        },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_CHANNEL_AND_TACTICS']),
    }),
    putCampaignTacticPeriodInstance: builder.mutation<
      void,
      {
        campaignTacticId: string;
        startDate: string;
        endDate: string;
      }
    >({
      query: ({ campaignTacticId, startDate, endDate }) => ({
        url: `/retailer/instance-tactic/${campaignTacticId}/period`,
        method: 'PUT',
        body: {
          startDate,
          endDate,
        },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS']),
    }),
    putCampaignTacticUnitsInstance: builder.mutation<
      void,
      {
        campaignTacticId: string;
        units: string;
      }
    >({
      query: ({ campaignTacticId, units }) => ({
        url: `/retailer/instance-tactic/${campaignTacticId}/units`,
        method: 'PUT',
        body: {
          units,
        },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS']),
    }),
  }),
});

export const {
  useGetCampaignTiersInstanceQuery,
  useDeleteCampaignTierInstanceMutation,
  useGetChannelAndTacticsTreeInstanceQuery,
  usePostCampaignTierInstanceMutation,
  usePostCopyCampaignTierInstanceMutation,
  usePutCampaignTierInstanceMutation,
  usePutCampaignTierTacticsInstanceMutation,
  useGetCampaignTierMetadataInstanceQuery,
  usePutCampaignTacticTacticMetadataInstanceMutation,
  usePutCampaignTacticBudgetInstanceMutation,
  useGetCampaignTiersDetailsInstanceQuery,
  usePutCampaignTacticDetailsEditInstanceMutation,
  usePutCampaignTacticPeriodInstanceMutation,
  usePutCampaignTacticUnitsInstanceMutation,
} = campaignChannelAndTacticsApi;

export { campaignChannelAndTacticsApi };
