import { IMetadataDTO } from '#/interfaces/tacticMetadata/tacticMetadata';

import { baseApi } from '../../base';

const tacticMetadataFormApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getInstanceMetadataForm: builder.query<IMetadataDTO, { id: string | undefined }>({
      query: ({ id }) => `/retailer/instance-tactic-metadata/${id}`,
      providesTags: ['CAMPAIGN_CHANNEL_AND_TACTICS_INSTANCE'],
    }),
    getShellMetadataForm: builder.query<IMetadataDTO, { id: string | undefined }>({
      query: ({ id }) => `/retailer/campaign-tactic-metadata/${id}`,
      providesTags: ['CAMPAIGN_CHANNEL_AND_TACTICS'],
    }),
    editInstanceMetadataForm: builder.mutation<void, { id: string | undefined; formData: string }>({
      query: ({ id, formData }) => ({
        url: `/retailer/instance-tactic-metadata/${id}`,
        method: 'PUT',
        body: formData,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['CAMPAIGN_CHANNEL_AND_TACTICS_INSTANCE'],
    }),
    editShellMetadataForm: builder.mutation<void, { id: string | undefined; formData: string }>({
      query: ({ id, formData }) => ({
        url: `/retailer/campaign-tactic-metadata/${id}`,
        method: 'PUT',
        body: formData,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['CAMPAIGN_CHANNEL_AND_TACTICS'],
    }),
  }),
});

export const {
  useGetInstanceMetadataFormQuery,
  useGetShellMetadataFormQuery,
  useEditInstanceMetadataFormMutation,
  useEditShellMetadataFormMutation,
} = tacticMetadataFormApi;
