import { Dayjs } from 'dayjs';

import { baseApi } from '../../base';
import { IRetailerAccountCalendar } from './retailerAccountInterfaces';

const retailerAccountApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRetailerAccountCalendar: builder.query<IRetailerAccountCalendar, null>({
      query: () => `/retailer/retailer-account/calendar`,
    }),
    getRetailerAccountLegalTerms: builder.query<string, void>({
      query: () => `/retailer/retailer-account/legal-terms`,
      providesTags: ['LEGAL_TERMS'],
    }),
    putRetailerAccountLegalTerms: builder.mutation<
      void,
      {
        legalTermsId: string;
      }
    >({
      query: ({ legalTermsId }) => {
        return {
          url: `/retailer/retailer-account/legal-terms`,
          method: 'PUT',
          body: {
            fileId: legalTermsId,
          },
        };
      },
      invalidatesTags: ['LEGAL_TERMS'],
    }),
    getRetailerAccountCreativeSpecs: builder.query<string[], void>({
      query: () => `/retailer/retailer-account/creative-specs`,
      providesTags: ['CREATIVE_SPECS'],
    }),
    putRetailerAccountCreativeSpecs: builder.mutation<void, { creativeSpecs: string[] }>({
      query: ({ creativeSpecs }) => {
        return {
          url: `/retailer/retailer-account/creative-specs`,
          method: 'PUT',
          body: { fileIds: creativeSpecs },
        };
      },
      invalidatesTags: ['CREATIVE_SPECS'],
    }),
    putRetailerAccountMarketingWeekStart: builder.mutation<void, { weekStartDay: string }>({
      query: ({ weekStartDay }) => {
        return {
          url: `/retailer/retailer-account/marketing-week-start?weekStartDay=${weekStartDay}`,
          method: 'PUT',
        };
      },
    }),
    putRetailerAccountFiscalYear: builder.mutation<void, { fiscalYearStart: Dayjs | null }>({
      query: ({ fiscalYearStart }) => {
        return {
          url: `/retailer/retailer-account/fiscal-year?fiscalYearStart=${fiscalYearStart?.format('MM-DD-YYYY')}`,
          method: 'PUT',
        };
      },
    }),
    putRetailerAccountFiscalYearGoal: builder.mutation<void, { goal: number }>({
      query: ({ goal }) => {
        return {
          url: `/retailer/retailer-account/fiscal-year-goal?fiscalYearGoal=${goal}`,
          method: 'PUT',
        };
      },
    }),
  }),
});

export const {
  useGetRetailerAccountCalendarQuery,
  useGetRetailerAccountLegalTermsQuery,
  usePutRetailerAccountLegalTermsMutation,
  useGetRetailerAccountCreativeSpecsQuery,
  usePutRetailerAccountCreativeSpecsMutation,
  usePutRetailerAccountMarketingWeekStartMutation,
  usePutRetailerAccountFiscalYearMutation,
  usePutRetailerAccountFiscalYearGoalMutation,
} = retailerAccountApi;

export { retailerAccountApi };
