import { USER_PERMISSIONS } from './permissions/userPermissions';
import { ROUTE_PATHS } from './routing/routePaths';

const {
  PARTNERS_BRANDS,
  PARTNERS_USERS,
  PARTNERS_USER_ACCESS,
  PROMOTE_PENDING_INVITATIONS,
  SETTINGS_GENERAL,
  ADMIN_DATA_JOB_READ,
  ADMIN_DATA_JOB_DELETE,
  ADMIN_DATA_JOB_WRITE,
  SETTINGS_PRODUCT_MIX,
  SETTINGS_USER,
  ADMIN_SETTINGS_REPORTS,
} = USER_PERMISSIONS;

export const campaignsSubmenu = [
  {
    label: 'Campaigns',
    url: ROUTE_PATHS.CAMPAIGNS_ALL,
  },
  {
    label: 'Brand Orders',
    url: ROUTE_PATHS.BRAND_ORDERS,
  },
];

export const campaignsSubmenuBrand = [
  {
    label: 'Brand Orders',
    url: ROUTE_PATHS.BRAND_ORDERS,
  },
];

export const partnersSubmenu = [
  { label: 'Companies', url: ROUTE_PATHS.COMPANIES, permission: PARTNERS_BRANDS },
  { label: 'Users', url: ROUTE_PATHS.PARTNERS_USERS, permission: PARTNERS_USERS },
  { label: 'User Access', url: ROUTE_PATHS.USER_ACCESS, permission: PARTNERS_USER_ACCESS },
  { label: 'Channel Partners', url: ROUTE_PATHS.CHANNEL_PARTNERS, permission: PARTNERS_USER_ACCESS },
];

export const communicationsSubmenu = [
  {
    label: 'Promote',
    url: ROUTE_PATHS.PROMOTE,
    permission: PROMOTE_PENDING_INVITATIONS,
  },
];

export const settingsSubmenu = [
  { label: 'General', url: ROUTE_PATHS.SETTINGS_JOBS, permission: SETTINGS_GENERAL },
  {
    label: 'Data Jobs',
    url: ROUTE_PATHS.SETTINGS_DATA_JOBS,
    permission: [ADMIN_DATA_JOB_READ, ADMIN_DATA_JOB_WRITE, ADMIN_DATA_JOB_DELETE],
  },
  { label: 'Product Mix', url: ROUTE_PATHS.PRODUCT_MIX, permission: SETTINGS_PRODUCT_MIX },
  { label: 'Channels & Tactics', url: ROUTE_PATHS.SETTINGS_CHANNELS_AND_TACTICS },
  { label: 'Users', url: ROUTE_PATHS.SETTINGS_USER, permission: SETTINGS_USER },
  { label: 'Reports', url: ROUTE_PATHS.SETTINGS_REPORT, permission: ADMIN_SETTINGS_REPORTS },
  { label: 'Workflows', url: ROUTE_PATHS.SETTINGS_WORKFLOWS },
];

export const userProfileSubmenu = [
  { label: 'Account Management', url: ROUTE_PATHS.ACCOUNT_MANAGEMENT },
  // { label: 'Notifications', url: ROUTE_PATHS.ACCOUNT_NOTIFICATIONS },
];
