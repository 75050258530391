import TableLogoCell from '#/components/common/tableLogoCell/TableLogoCell';

import { CompanyAccountAssociatedBrands, CompanyAccountAssociatedCompany } from '#/store/api/company/companyInterfaces';

import { getBrandAddress } from '#/utils/utilsReact';

import CircleCheckIcon from '../../../../assets/CircleCheckIcon.svg';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '../../../../interfaces/table/table';

// TODO: check if still used
export const AssociateAgencyBrandTable = () => {
  const createCustomNameImageView = (
    aggregateId: string,
    logoId: string | null,
    companyName: string,
    companyType: string,
  ) => (
    <TableLogoCell
      link={companyType === 'AGENCY' ? `/partners/agencies/${aggregateId}` : `/partners/brands/${aggregateId}`}
      logoId={logoId}
      name={companyName}
    />
  );

  const AssociateAgencyWithBrandTable: ITableColumnsProps<CompanyAccountAssociatedCompany>[] = [
    {
      fieldName: 'aggregateId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Campaign Id',
      hide: true,
    },
    {
      fieldName: 'companyName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Agency',
      actionElementIdentifier: 'aggregateId',
      actionElementSecondIdentifier: 'logoId',
      actionElementThirdIdentifier: 'companyName',
      actionElementFourthIdentifier: 'companyType',
      getCustomElement: createCustomNameImageView,
    },
    {
      fieldName: 'parentName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Parent Company',
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Brand Status',
    },
    {
      fieldName: 'vendorId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Vendor ID',
    },
    {
      fieldName: 'numberOfUsers',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.STRING,
      customHead: 'Users',
    },
    {
      fieldName: null,
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Address',
      actionElementIdentifier: 'mailingAddress1',
      actionElementSecondIdentifier: 'city',
      actionElementThirdIdentifier: 'state',
      actionElementFourthIdentifier: 'zip',
      getCustomElement: getBrandAddress,
    },
  ];

  const AssociateBrandWithAgencyTable: ITableColumnsProps<CompanyAccountAssociatedCompany>[] = [
    {
      fieldName: 'aggregateId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Campaign Id',
      hide: true,
    },
    {
      fieldName: 'companyName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Brand',
      actionElementIdentifier: 'aggregateId',
      actionElementSecondIdentifier: 'logoId',
      actionElementThirdIdentifier: 'companyName',
      actionElementFourthIdentifier: 'companyType',
      getCustomElement: createCustomNameImageView,
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Brand Status',
    },
    {
      fieldName: 'parentName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Parent Company',
    },
    {
      fieldName: 'categories',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Categories',
    },
    {
      fieldName: 'subCategories',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Sub-Categories',
    },
  ];

  const AssociatedUserBrands: ITableColumnsProps<CompanyAccountAssociatedBrands>[] = [
    {
      fieldName: 'companyId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Campaign Id',
      hide: true,
    },
    {
      fieldName: 'companyName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Brand',
      actionElementIdentifier: 'companyId',
      actionElementSecondIdentifier: 'logoId',
      actionElementThirdIdentifier: 'companyName',
      actionElementFourthIdentifier: 'companyType',
      getCustomElement: createCustomNameImageView,
    },
    {
      fieldName: 'role',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      sortable: true,
      customHead: 'Role',
    },
    {
      fieldName: 'keyContact',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.ELEMENT,
      customHead: 'Key Contact',
      element: () => <img alt="circle icon" src={CircleCheckIcon} />,
      hideElementIfNull: true,
    },
    {
      fieldName: 'billingContact',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.ELEMENT,
      customHead: 'Billing Contact',
      element: () => <img alt="circle icon" src={CircleCheckIcon} />,
      hideElementIfNull: true,
    },
  ];
  const BrandHierarchyTable: ITableColumnsProps<CompanyAccountAssociatedCompany>[] = [
    {
      fieldName: 'aggregateId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Campaign Id',
      hide: true,
    },
    {
      fieldName: 'companyName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Brand',
      actionElementIdentifier: 'aggregateId',
      actionElementSecondIdentifier: 'logoId',
      actionElementThirdIdentifier: 'companyName',
      actionElementFourthIdentifier: 'companyType',
      getCustomElement: createCustomNameImageView,
    },
    {
      fieldName: 'parentName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Parent Company',
      linkable: true,
      baseUrl: '/partners/brands',
      actionElementIdentifier: 'parentId',
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Brand Status',
    },
    {
      fieldName: 'vendorId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Vendor ID',
    },
    {
      fieldName: 'numberOfUsers',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.STRING,
      customHead: 'Users',
    },
    {
      fieldName: null,
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Address',
      actionElementIdentifier: 'mailingAddress1',
      actionElementSecondIdentifier: 'city',
      actionElementThirdIdentifier: 'state',
      actionElementFourthIdentifier: 'zip',
      getCustomElement: getBrandAddress,
    },
  ];

  return { AssociateAgencyWithBrandTable, AssociateBrandWithAgencyTable, AssociatedUserBrands, BrandHierarchyTable };
};
