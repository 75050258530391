import { IRateCards } from '#/store/api/rateCards/rateCardsInterfaces';

import { baseApi } from '../../base';

const rateCardsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRateCards: builder.query<IRateCards, { channelTacticId: string }>({
      query: ({ channelTacticId }) => `/retailer/rate-cards/${channelTacticId}`,
      providesTags: ['RATE_CARDS'],
    }),
  }),
});

export const { useGetRateCardsQuery } = rateCardsApi;

export { rateCardsApi };
