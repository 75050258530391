import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Grid } from '@mui/material';
import { Control, Controller, FieldErrors, UseFormWatch } from 'react-hook-form';

import { THEME } from '../../../../constants/theme/constants';
import { ITacticMetadataDTO, TacticMetadataForm } from '../../../../interfaces/tacticMetadata/tacticMetadata';
import { InfoTooltip } from '../../../features/reports/InfoTooltip';
import { TypographyWithFontFamily } from '../../typography/TypographyWithFontFamily';
import { errorTextSx, useTacticMetadataFormStyle } from '../TacticMetadataForm.style';

interface IProps {
  errors: FieldErrors<TacticMetadataForm>;
  input: ITacticMetadataDTO;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<TacticMetadataForm, any>;
  watch: UseFormWatch<TacticMetadataForm>;
  helperTextSubtitle?: boolean;
  editMode?: boolean;
}

export const RadioBoxInput = ({
  errors,
  input,
  control,
  watch,
  helperTextSubtitle = false,
  editMode = true,
}: IProps) => {
  const classes = useTacticMetadataFormStyle();

  const existingErrors = errors[String(input.id)]?.message || '';

  const checkedValues = watch(String(input.id));

  return (
    <Grid container xs={12}>
      <Grid item xs={9}>
        <TypographyWithFontFamily cName={classes.labelUppercase} color={THEME.PALETTES.TEXT.Gray} variant="Label_Base">
          {input.label} {input.required ? '*' : ''}
        </TypographyWithFontFamily>
        {helperTextSubtitle && (
          <TypographyWithFontFamily gutterBottom variant={'Label_Base'}>
            {input.helperText}
          </TypographyWithFontFamily>
        )}
      </Grid>
      <Grid item xs={3}>
        {!helperTextSubtitle && input.helperText && <InfoTooltip text={input.helperText} />}
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={String(input.id)}
          render={({ field }) => (
            <RadioGroup {...field} row>
              {input.listValues?.map((value) => {
                let isChecked = false;
                if (value && checkedValues) {
                  isChecked = checkedValues === value.value;
                }
                return (
                  <FormControlLabel
                    key={value.value}
                    checked={isChecked}
                    control={<Radio />}
                    disabled={input.isDisabled || !editMode}
                    label={value.displayValue}
                    value={value.value}
                  />
                );
              })}
            </RadioGroup>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <TypographyWithFontFamily sx={errorTextSx} variant="Default_Base">
          {String(existingErrors)}
        </TypographyWithFontFamily>
      </Grid>
    </Grid>
  );
};
