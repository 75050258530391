import { usePromoteCampaignStyles } from '#/components/features/communications/PromoteCampaigns.styles';

import { useAppDispatch } from '#/hooks';

import { IPendingInvitation } from '#/interfaces/promote/promote';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { communicationsPromote } from '#/store/slices';

import { currencyFormatter, percentageFormatter } from '#/utils/textFormatters';

const useGetPromoteCampaignTableColumns = (
  selectedCampaigns: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columNames: any[],
  userHasCommunicationsPromotePromoteSectionWrite: boolean,
) => {
  const dispatch = useAppDispatch();
  const classes = usePromoteCampaignStyles();

  const sortableColumns = columNames.map((el) => el.columnName);
  const tableColumns: ITableColumnsProps<IPendingInvitation>[] = [
    {
      fieldName: null,
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.CHECKBOX,
      customHead: ' ',
      checkBoxAction: (checked, campaignId) => {
        dispatch(
          communicationsPromote.actions.updateSelectedItems({
            itemId: campaignId,
            updateFor: 'selectedCampaigns',
          }),
        );
      },
      hasCheckAll: false,
      checkFromList: selectedCampaigns,
      actionElementIdentifier: 'campaignId',
      hide: !userHasCommunicationsPromotePromoteSectionWrite,
    },
    {
      fieldName: 'campaignName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      linkable: true,
      baseUrl: '/campaigns/overview',
      actionElementIdentifier: 'campaignId',
      customHead: 'Campaign',
      itemClass: classes.link,
      sortable: sortableColumns.includes('campaignName'),
    },
    {
      fieldName: 'lastPromotionDate',
      align: TableColumnAlign.RIGHT,
      type: TableCellTypes.STRING,
      customHead: 'Last Promotion Date',
      sortable: sortableColumns.includes('lastPromotionDate'),
    },
    {
      fieldName: 'brandsTargeted',
      align: TableColumnAlign.RIGHT,
      type: TableCellTypes.STRING,
      customHead: 'Target Brands',
      sortable: sortableColumns.includes('brandsTargeted'),
    },
    {
      fieldName: 'brandsInvitedCount',
      align: TableColumnAlign.RIGHT,
      type: TableCellTypes.STRING,
      textFormatter: (el) => el?.toString() || '0',
      customHead: 'Invited',
      sortable: sortableColumns.includes('brandsInvitedCount'),
    },
    {
      fieldName: 'brandsAcceptedCount',
      align: TableColumnAlign.RIGHT,
      type: TableCellTypes.STRING,
      textFormatter: (el) => el?.toString() || '0',
      customHead: 'Accepted',
      sortable: sortableColumns.includes('brandsAcceptedCount'),
    },
    {
      fieldName: 'brandsCommittedCount',
      align: TableColumnAlign.RIGHT,
      type: TableCellTypes.STRING,
      textFormatter: (el) => el?.toString() || '0',
      customHead: 'Committed',
      sortable: sortableColumns.includes('brandsCommittedCount'),
    },
    {
      fieldName: 'brandsDeclinedCount',
      align: TableColumnAlign.RIGHT,
      type: TableCellTypes.STRING,
      customHead: 'Brands Declined',
      textFormatter: (el) => el?.toString() || '0',
      sortable: sortableColumns.includes('brandsDeclinedCount'),
    },
    {
      fieldName: 'acceptDeadline',
      align: TableColumnAlign.RIGHT,
      type: TableCellTypes.STRING,
      customHead: 'Accept Deadline',
      sortable: sortableColumns.includes('acceptDeadline'),
    },
    {
      fieldName: 'daysBeforeStart',
      align: TableColumnAlign.RIGHT,
      type: TableCellTypes.STRING,
      customHead: 'Days Remaining',
      textFormatter: (days) => days?.toString() || '0',
    },
    {
      fieldName: 'startDate',
      align: TableColumnAlign.RIGHT,
      type: TableCellTypes.STRING,
      customHead: 'Start Date',
      sortable: sortableColumns.includes('startDate'),
    },
    {
      fieldName: 'endDate',
      align: TableColumnAlign.RIGHT,
      type: TableCellTypes.STRING,
      customHead: 'End Date',
      sortable: sortableColumns.includes('endDate'),
    },
    {
      fieldName: 'campaignGoal',
      align: TableColumnAlign.RIGHT,
      type: TableCellTypes.STRING,
      textFormatter: currencyFormatter,
      customHead: 'Goal',
      sortable: sortableColumns.includes('campaignGoal'),
    },
    {
      fieldName: 'committedGoal',
      align: TableColumnAlign.RIGHT,
      type: TableCellTypes.STRING,
      textFormatter: currencyFormatter,
      customHead: 'Committed($)',
      sortable: sortableColumns.includes('committedGoal'),
    },
    {
      fieldName: 'percentOfGoal',
      align: TableColumnAlign.RIGHT,
      type: TableCellTypes.STRING,
      textFormatter: percentageFormatter,
      customHead: '% to Goal',
      sortable: sortableColumns.includes('percentOfGoal'),
    },
  ];
  return tableColumns;
};

export default useGetPromoteCampaignTableColumns;
