import { THEME } from '#/constants/theme/constants';

import { createStyles, makeStyles } from '#/utils/theme';

export const useAddNewCampaignStyles = makeStyles((theme) =>
  createStyles({
    errorMessage: {
      color: theme.custom.PALETTES.TEXT.Error,
    },
    row: { display: 'flex', justifyContent: 'space-between  ', width: '100%' },
    lastRow: { marginBottom: theme.custom.REM_SPACES[0.5] },

    inputContainer: { width: '49%' },
    calendarHeader: {
      height: '100%',
      '&& .fc-event': {
        borderWidth: '5px',
        marginTop: '-0.5rem',
      },
      '&& .fc-col-header-cell': {
        color: theme.custom.PALETTES.TEXT.Light,
        textAlign: 'center',
        fontFamily: 'Public Sans',
        fontSize: '0.8125rem',
        fontWeight: 400,
        padding: theme.custom.REM_SPACES['0.5'],
        background: theme.custom.PALETTES.UI.Neutral['600'],
      },
      '&& .fc-header-toolbar': {
        marginBottom: theme.custom.REM_SPACES[0.5],
      },
      '&& .fc .fc-multimonth-title': {
        padding: '0.1rem',
        fontSize: theme.custom.REM_SPACES['1'],
      },
      '&& .fc .fc-multimonth-multicol .fc-multimonth-month': {
        padding: '0.1rem',
      },
      '&& button': {
        borderRadius: theme.custom.REM_SPACES['2.5'],
        borderWidth: 0,
        background: theme.custom.PALETTES.UI.Primary.Base,
        color: theme.custom.PALETTES.TEXT.Light,
        textTransform: 'none',
        '&:hover': {
          background: theme.custom.PALETTES.UI.Primary['700'],
        },
        fontFamily: 'Public Sans, sans-serif',
        textAlign: 'center',
        fontSize: '0.75rem',
        fontWeight: theme.custom.FONT_WEIGHTS.SEMIBOLD,
        lineHeight: '1.53125rem',
      },
      '&& h2': {
        fontWeight: theme.custom.FONT_WEIGHTS.SEMIBOLD,
        fontSize: theme.custom.REM_SPACES['1'],
      },
    },
    submitButton: {
      width: theme.custom.REM_SPACES['9'],
    },
  }),
);

export const style = {
  campaignName: { marginBottom: THEME.REM_SPACES['0.5'] },
  field: { marginBottom: THEME.REM_SPACES['0.5'], marginTop: THEME.REM_SPACES['0.5'] },
  date: { marginTop: THEME.REM_SPACES['0.25'] },
  cancelButton: { marginRight: THEME.REM_SPACES['0.5'], marginBottom: THEME.REM_SPACES['0.25'] },
  bottomButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `1px solid ${THEME.PALETTES.BORDER.Light}`,
    paddingTop: THEME.REM_SPACES['0.5'],
  },
  leftButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
};
