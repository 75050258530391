const useGetSelectedTableActions = <T>(
  filteredElements: T[],
  selectedElements: T[],
  setSelectedElements: (updatedSelectedElements: T[]) => void,
  lookUpElement = 'aggregateId',
) => {
  const filteredElementsIds = filteredElements.map((el) => el[lookUpElement]);
  const selectedElementsIds = selectedElements.map((el) => el[lookUpElement]);

  const filteredCheckAllValue = filteredElementsIds.every((el) => selectedElementsIds.includes(el));
  const indeterminateFilteredCheckAll = filteredElementsIds.some((el) => selectedElementsIds.includes(el));
  const selectedCheckAllValue = selectedElementsIds.length > 0;
  const filteredCheckFromList = selectedElements.map((el) => el[lookUpElement]);
  const selectedCheckFromList = selectedElements.map((el) => el[lookUpElement]);

  const selectedCheckBoxAction = (checked: boolean, selectedID: string) => {
    if (checked) {
      let newCheckList;
      const res = filteredElements.find((el) => el[lookUpElement] === selectedID);
      if (res) {
        newCheckList = [...selectedElements, res];
      } else {
        newCheckList = [...selectedElements];
      }
      setSelectedElements(newCheckList);
    } else {
      const newCheckList = selectedElements.filter((el) => el[lookUpElement] !== selectedID);
      setSelectedElements(newCheckList);
    }
  };

  const selectedCheckAllAction = () => {
    setSelectedElements([]);
  };

  const filteredCheckBoxAction = (checked: boolean, selectedID: string) => {
    if (checked) {
      let newCheckList;
      const res = filteredElements.find((el) => el[lookUpElement] === selectedID);
      if (res) {
        newCheckList = [...selectedElements, res];
      } else {
        newCheckList = [...selectedElements];
      }
      setSelectedElements(newCheckList);
    } else {
      const newCheckList = selectedElements.filter((el) => el[lookUpElement] !== selectedID);
      setSelectedElements(newCheckList);
    }
  };

  const filteredCheckAllAction = (checked: boolean) => {
    if (checked) {
      setSelectedElements([...selectedElements, ...filteredElements]);
    } else {
      const selectedElementsWithoutCurrentFilteredElements = selectedElements.filter(
        (el) => !filteredElementsIds.includes(el[lookUpElement]),
      );
      setSelectedElements(selectedElementsWithoutCurrentFilteredElements);
    }
  };

  return {
    filteredCheckAllValue,
    indeterminateFilteredCheckAll,
    selectedCheckAllValue,
    filteredCheckFromList,
    selectedCheckFromList,
    selectedCheckBoxAction,
    selectedCheckAllAction,
    filteredCheckBoxAction,
    filteredCheckAllAction,
  };
};

export default useGetSelectedTableActions;
