import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { communicationsSubmenu } from '#/constants/submenu';

import PromoteIcon from '../../../assets/PromoteIcon.svg';
import DisplayPageName from '../../common/displayPageName/DisplayPageName';
import { SubmenuBar } from '../../common/submenuBar/SubmenuBar';

export const Communications = () => {
  return (
    <Box>
      <DisplayPageName imgSrc={PromoteIcon} pageName="Communications" />
      <SubmenuBar tabs={communicationsSubmenu} />
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
};
