import { useAppSelector } from '#/hooks';

export interface IUserPermissions {
  retailerPermission?: string;
  brandPermission?: string;
  channelPartnerPermission?: string;
}

interface IReturnedPermissions {
  retailerPermission: boolean;
  brandPermission: boolean;
  channelPartnerPermission: boolean;
  hasNoPermissions: boolean;
}

export const useUserPermissionType = (permissionsObj: IUserPermissions) => {
  const { permissions } = useAppSelector((state) => state.user);

  const returnedUserPermissionsObject: IReturnedPermissions = {
    retailerPermission: false,
    brandPermission: false,
    channelPartnerPermission: false,
    hasNoPermissions: true,
  };

  Object.keys(returnedUserPermissionsObject).forEach((key) => {
    if (permissions.includes(String(permissionsObj[key as keyof IUserPermissions]))) {
      returnedUserPermissionsObject[key as keyof IReturnedPermissions] = true;
      returnedUserPermissionsObject.hasNoPermissions = false;
    }
  });

  return returnedUserPermissionsObject;
};
