import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { IPeriod } from '../../store/api/periods/periodsInterfaces';
import { TypographyWithFontFamily } from '../common/typography/TypographyWithFontFamily';

dayjs.extend(isBetween);

export enum CalendarLocationEnum {
  'DEFAULT' = 'DEFAULT',
  'NEW_CAMPAIGN' = 'NEW_CAMPAIGN',
}

const getWeekNumberContent = (
  week: { date: Date; text: string },
  isViewSelectEnabled: boolean,
  periods: IPeriod[] | undefined,
  calendarLocation: CalendarLocationEnum = CalendarLocationEnum.DEFAULT,
) => {
  const { date, text } = week;
  const isFullscreen = calendarLocation !== CalendarLocationEnum.NEW_CAMPAIGN;
  let result = isFullscreen ? text.replace(' ', '') : '';
  const textVariant = 'Small_Base';

  if (!periods || !isViewSelectEnabled)
    return <TypographyWithFontFamily variant={textVariant}>{result}</TypographyWithFontFamily>;

  for (const period of periods) {
    if (dayjs(date).isBetween(dayjs(period.startOfPeriod), dayjs(period.endOfPeriod), 'day', '[]')) {
      result += ' ' + period.periodName;
      break;
    }
  }

  return (
    <TypographyWithFontFamily sx={{ margin: 0 }} variant={textVariant}>
      {result}
    </TypographyWithFontFamily>
  );
};

export default getWeekNumberContent;
