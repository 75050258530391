import SortDownIcon from '../../../../../assets/SortDownIcon.svg';
import SortIcon from '../../../../../assets/SortIcon.svg';
import SortUpIcon from '../../../../../assets/SortUpIcon.svg';
import { FilterOrders } from '../../../../../interfaces/table/table';

export const SortIconComponent = ({ order, displayDefault }: { order: string; displayDefault: boolean }) => {
  if (displayDefault) {
    return <img src={SortIcon} />;
  }
  if (order === FilterOrders.ASC) {
    return <img src={SortUpIcon} />;
  }
  return <img src={SortDownIcon} />;
};
