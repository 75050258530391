import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import CollapseComponent from '#/components/common/CollapseComponent/CollapseComponent';
import CommunicationsSelectBrandsTableContainer from '#/components/features/communications/tables/CommunicationsSelectBrandsTableContainer';
import CommunicationsSelectBrandsTableSelected from '#/components/features/communications/tables/CommunicationsSelectBrandsTableSelected';
import { BASE_PATH, COMMUNICATIONS_TABS } from '#/constants/general';
import { ROUTE_PATHS } from '#/constants/routing/routePaths';

import { useAppDispatch, useAppSelector } from '#/hooks';

import { communicationsPromote } from '#/store/slices';

import { useCampaignInvitationsStyles } from '../../../../campaignInvitations/CampaignInvitations.styles';
import NewButton from '../../../../common/button/NewButton';
import { TypographyWithFontFamily } from '../../../../common/typography/TypographyWithFontFamily';
import CommunicationsSelectedCampaigns from '../../shared/CommunicationsSelectedCampaigns';
import CustomCommunicationsModal from '../../shared/CustomCommunicationsModal';
import CommunicationsFilterBrands, { ICommunicationsFilterBrandsState } from './CommunicationsFilterBrands';

const initialState = {
  filterType: 'ALL',
  parentCompanies: '',
  invitationStatus: '',
  additionalCategoryIds: [],
  additionalSubCategoryIds: [],
  additionalAttributeIds: [],
  includeAccepted: false,
};

const CommunicationsSelectBrands = () => {
  const classes = useCampaignInvitationsStyles();
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const { COMMUNICATIONS, PROMOTE_INVITE_RECIPIENTS, PROMOTE } = ROUTE_PATHS;

  const handleNextClick = () => {
    navigate(`${BASE_PATH}/${COMMUNICATIONS}/${PROMOTE_INVITE_RECIPIENTS}`);
  };
  const { selectedBrands, selectedCampaigns } = useAppSelector((state) => state.communications);
  const dispatch = useAppDispatch();
  const campaigns = searchParams.get('campaigns');

  useEffect(() => {
    if (campaigns) {
      dispatch(communicationsPromote.actions.setSelectedCampaigns(campaigns.split(',')));
      return;
    }

    if (selectedCampaigns.length === 0) {
      navigate(`${BASE_PATH}/${COMMUNICATIONS}/${PROMOTE}`);
    }
  }, [campaigns]);

  const [brandsFilters, setBrandsFilters] = useState(initialState as ICommunicationsFilterBrandsState);

  return (
    <CustomCommunicationsModal activeTab={COMMUNICATIONS_TABS.COMMUNICATIONS_INVITE_BRANDS} modalTitle="Invite Brands">
      <Stack className={classes.selectBrandsContainer} direction={'row'}>
        <Box className={classes.invitationDetailsContainer}>
          <CommunicationsFilterBrands brandsFilters={brandsFilters} setBrandsFilters={setBrandsFilters} />
        </Box>
        <Box className={classes.selectBrandsOptionsContainer}>
          <Box className={classes.buttonWrap}>
            <NewButton disabled={!selectedBrands.length} onClick={handleNextClick} text="Next" type="button" />
          </Box>
          <CommunicationsSelectedCampaigns />
          {selectedBrands.length !== 0 && (
            <CollapseComponent
              actionTitle={
                <Box className={classes.buttonWrapSpace}>
                  <TypographyWithFontFamily variant="H5_Base">
                    Selected Brand(s) {selectedBrands.length} *
                  </TypographyWithFontFamily>
                  <NewButton
                    onClick={() => dispatch(communicationsPromote.actions.clearSelectedBrands())}
                    text="Clear selection"
                    variant="Ghost"
                  />
                </Box>
              }
              content={<CommunicationsSelectBrandsTableSelected />}
            />
          )}
          <TypographyWithFontFamily className={classes.mt05} variant="H5_Base">
            Available Brands for Selection
          </TypographyWithFontFamily>
          <CommunicationsSelectBrandsTableContainer brandsFilters={brandsFilters} />
        </Box>
      </Stack>
    </CustomCommunicationsModal>
  );
};

export default CommunicationsSelectBrands;
