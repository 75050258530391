import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { usePostCompanyUserSignupFormMutation } from '#/store/api/company/company';

import { useSnackbarError } from '../../../components/common/snackbar/useSnackbarError';
import { ICreateUserBrand } from '../../../interfaces/forms/users';

export const useCreateBrandUser = () => {
  const [postCompanyUserSignupFormMutation, { error }] = usePostCompanyUserSignupFormMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const schema = yup
    .object()
    .shape({
      firstName: yup
        .string()
        .required('First Name is a required field.')
        .max(64, 'First Name must be at most 64 characters')
        .matches(/^[a-zA-Z\s'\-]{1,64}$/, { message: 'First Name can not contain numbers' }),
      lastName: yup
        .string()
        .required('Last Name is a required field.')
        .max(64, 'Last Name must be at most 64 characters')
        .matches(/^[a-zA-Z\s'\-]{1,64}$/, { message: 'Last Name can not contain numbers' }),
      companyName: yup.string().required('Company Name is a required field.'),
      departmentName: yup.string().required('Department Name is a required field.'),
      title: yup.string().required('Title is a required field.'),
      email: yup.string().required('Email is a required field').email(),
      comments: yup.string(),
    })
    .required();

  const {
    register,
    clearErrors,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ICreateUserBrand>({ resolver: yupResolver(schema) });

  const [errorMessage, setErrorMessage] = useState('');
  const [successfulCreated, setSuccessfulCreated] = useState(false);
  const { setError } = useSnackbarError();

  const fetchCreateBrandUser = async (data: ICreateUserBrand) => {
    setLoading(true);
    try {
      await postCompanyUserSignupFormMutation({ data }).unwrap();
      setSuccessfulCreated(true);
      setErrorMessage('');
      clearErrors();
      setLoading(false);
      navigate('/login', { state: { requestSent: true } });
    } catch (error: unknown) {
      setError(error);
      if (error instanceof AxiosError) {
        setErrorMessage(error.response?.data.message);
      }
      setLoading(false);
    }
  };

  const onSubmit = handleSubmit(async (formData: ICreateUserBrand) => {
    await fetchCreateBrandUser(formData);
  });

  return {
    successfulCreated,
    register,
    setValue,
    watch,
    onSubmit,
    errors,
    errorMessage,
    handleSubmit,
    clearErrors,
    loading,
    error,
  };
};
