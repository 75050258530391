import { Box } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import Loader from '#/components/common/loader/loader';
import UploadJobFile from '#/components/features/settings/dataAndCalendar/UploadJobFile';
import FileView from '#/components/shared/files/file-view';

import { useGetDataJobsQuery } from '#/store/api/dataJob/dataJob';

import PlusIcon from '../../../../assets/Plus.svg';
import { THEME } from '../../../../constants/theme/constants';
import NewButton from '../../../common/button/NewButton';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { upperCaseHeader, useGeneralSettingStyle } from '../dataAndCalendar/GeneralSettings.style';
import AddDataJob from './AddDataJob';

const DataJobs = () => {
  const classes = useGeneralSettingStyle();
  const [isAddDataJobOpen, setIsAddDataJobOpen] = useState(false);
  const { data: jobsResponse, refetch, isLoading, isFetching } = useGetDataJobsQuery();

  const { setValue, watch } = useForm();

  return (
    <Box>
      <Box className={classes.dataFeedContainer}>
        <Box className={classes.dataFeedHeaderContainer}>
          <TypographyWithFontFamily variant="H5_Base">Data Feeds</TypographyWithFontFamily>
          <NewButton
            icon={PlusIcon}
            iconPosition="left"
            onClick={() => setIsAddDataJobOpen(true)}
            text="Add New Data Job"
          />
        </Box>
        <Box className={classes.dataContent}>
          <form>
            {isLoading || isFetching ? (
              <Loader />
            ) : (
              <>
                {jobsResponse?.map((job) => (
                  <Box key={job.id}>
                    <TypographyWithFontFamily
                      color={THEME.PALETTES.TEXT.Gray}
                      sx={upperCaseHeader}
                      variant="Label_Base"
                    >
                      {job.name}
                    </TypographyWithFontFamily>
                    {job.jobInputFileId ? (
                      <>
                        <FileView
                          defaultFileName={job.jobInputFileName}
                          deleteLink={`/file-upload?fileId=${job.jobInputFileId}`}
                          downloadLink={`/file-upload/files/${job.jobInputFileId}`}
                          fileFor={job.name}
                          fileId={job.jobInputFileId}
                          preventFetch
                          readonly={false}
                          refreshAfterDelete={refetch}
                          setValue={setValue}
                          timeStamp={job.jobInputFileTimestamp}
                          watch={watch}
                        />
                      </>
                    ) : (
                      <>
                        <UploadJobFile jobId={job.id.toString()} refresh={refetch} />
                      </>
                    )}
                  </Box>
                ))}
              </>
            )}
          </form>
        </Box>
      </Box>
      {isAddDataJobOpen && (
        <AddDataJob isAddDataJobOpen={isAddDataJobOpen} refetch={refetch} setIsAddDataJobOpen={setIsAddDataJobOpen} />
      )}
    </Box>
  );
};

export default DataJobs;
