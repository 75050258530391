import { Box, Collapse, Divider, List, ListItemButton, ListItemText } from '@mui/material';
import { FC, useMemo, useState } from 'react';

import { getChannelPartnerChannelsAndTactics } from '../../../../api/channelPartnerAccount';
import ArrowDownIcon from '../../../../assets/ArrowDownIcon.svg';
import ArrowLeftIcon from '../../../../assets/ArrowLeftIcon.svg';
import ChannelsIcon from '../../../../assets/ChannelsIcon.svg';
import { getTacticsTableColumns } from '../../../../constants/tables/singleChannelPartner/singleChannelPartner';
import { useRequest } from '../../../../hooks/useRequest';
import { ISelectedSubChannel, ITacticRow } from '../../../../interfaces/partners/channelPartners/channelPartners';
import { Table } from '../../../common/table/tableComponent/Table';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import {
  listItemButtonSx,
  numberOfTacticsSx,
  useChannelAndTacticsSingleChannelPartnerStyle,
} from './ChannelAndTacticsSingleChannelPartner.style';

interface IChannelAndTacticsSingleChannelPartnerProps {
  channelPartnerId: string | undefined;
}

const ChannelAndTacticsSingleChannelPartner: FC<IChannelAndTacticsSingleChannelPartnerProps> = ({
  channelPartnerId,
}) => {
  const getChannelPartnerChannelsAndTacticsAction = useMemo(
    () => getChannelPartnerChannelsAndTactics(channelPartnerId),
    [channelPartnerId],
  );
  const { response } = useRequest(getChannelPartnerChannelsAndTacticsAction);
  const classes = useChannelAndTacticsSingleChannelPartnerStyle();
  const [selectedChannel, setSelectedChannel] = useState('');
  const [selectedSubChannel, setSelectedSubChannel] = useState<ISelectedSubChannel>();

  const displayTactics: ITacticRow[] =
    selectedSubChannel?.tactics?.map((tactic) => ({
      tactic,
    })) || [];

  const tacticsTableColumns = getTacticsTableColumns();
  return (
    <Box className={classes.channelsAndTacticsContainer}>
      <Box className={classes.channelsAndSubChannelsContainer}>
        <Box className={classes.headerContainer}>
          <img src={ChannelsIcon} />
          <TypographyWithFontFamily variant="H4_Base">Channels</TypographyWithFontFamily>
        </Box>
        <List className={classes.list} component="nav">
          {response?.data?.channels.map((channel) => (
            <Box key={channel.channel}>
              <ListItemButton className={classes.collapsible} onClick={() => setSelectedChannel(channel.channel)}>
                <ListItemText primary={channel.channel} />
                {channel.channel === selectedChannel ? <img src={ArrowDownIcon} /> : <img src={ArrowLeftIcon} />}
              </ListItemButton>
              {channel.subChannels?.map((subChannel) => (
                <Collapse
                  key={subChannel.subChannel}
                  in={channel.channel === selectedChannel}
                  onClick={() =>
                    setSelectedSubChannel({
                      subChannel: subChannel.subChannel,
                      tactics: subChannel.tactics?.map((tactic) => tactic.tactic),
                    })
                  }
                  timeout="auto"
                >
                  <List
                    className={`${classes.subChannel} ${
                      selectedSubChannel?.subChannel === subChannel.subChannel ? classes.selectedSubChannel : ''
                    }`}
                    component="div"
                    disablePadding
                  >
                    <ListItemButton sx={listItemButtonSx}>
                      <ListItemText
                        primary={
                          <Box className={classes.subChannelContainer}>
                            <TypographyWithFontFamily>{subChannel.subChannel}</TypographyWithFontFamily>
                            <TypographyWithFontFamily sx={numberOfTacticsSx}>
                              {subChannel.tactics.length.toString()}
                            </TypographyWithFontFamily>
                          </Box>
                        }
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
              ))}
            </Box>
          ))}
        </List>
      </Box>
      <Divider flexItem orientation="vertical" />
      <Box className={classes.channelTacticsContainer}>
        <Box>
          <TypographyWithFontFamily cName={classes.mb2} gutterBottom variant="H5_Base">
            Channel Tactics
          </TypographyWithFontFamily>
          {selectedSubChannel ? (
            <Box className={classes.tacticsTableContainer}>
              <Table
                bodyCellClass={classes.tacticsTableBodyCell}
                columns={tacticsTableColumns}
                containerClass={classes.tacticsTableBody}
                data={displayTactics || []}
                headCellClass={classes.tacticsTableHeaderCell}
                headClass={classes.hide}
              />
            </Box>
          ) : (
            <Box>
              <TypographyWithFontFamily>Please select a channel</TypographyWithFontFamily>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ChannelAndTacticsSingleChannelPartner;
