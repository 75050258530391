import { createStyles, makeStyles } from '#/utils/theme';

export const useCompanyHierarchyStyles = makeStyles((theme) =>
  createStyles({
    card: {
      minHeight: '50rem',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    headerText: {
      display: 'flex',
      columnGap: theme.custom.REM_SPACES['0.5'],
    },
    companyHierarchyTable: {
      marginTop: theme.custom.REM_SPACES['1'],
    },
    nodeContainer: {
      border: `1px solid ${theme.custom.PALETTES.UI.Neutral['200']}`,
      borderRadius: theme.custom.REM_SPACES['0.5'],
      background: theme.custom.PALETTES.UI.Secondary['300'],
      padding: theme.custom.REM_SPACES['1'],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    currentNode: {
      background: theme.custom.PALETTES.UI.Secondary['600'],
    },
    treeContainer: { height: '25rem' },
  }),
);
