import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import React, { FC } from 'react';

import { ICampaignHistory } from '../../../store/api/campaigns/campaignsInterfaces';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import { campaignTimelineStyle, timelineStyleSx } from './campaignTimelineStyle';
import { getTimelineIcon } from './utils';

interface ICampaignTimelineProps {
  campaignHistory: ICampaignHistory[];
}
const CampaignTimeline: FC<ICampaignTimelineProps> = ({ campaignHistory }) => {
  const classes = campaignTimelineStyle();
  return (
    <Box className={classes.timeLineContainer}>
      <Timeline>
        {campaignHistory.map((event) => (
          <TimelineItem key={event.lastUpdated}>
            <TimelineOppositeContent
              align="right"
              color="text.secondary"
              sx={timelineStyleSx.timelineOppositeContent}
              variant="body2"
            >
              {dayjs(event.lastUpdated).format('MM/DD/YYYY HH:mm:ss A')}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <Box className={classes.iconContainer}>
                <img alt={'timeline-icon'} className={classes.icon} src={getTimelineIcon(event.eventType)} />
              </Box>

              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={timelineStyleSx.timelineContent}>
              <TypographyWithFontFamily>{event.action}</TypographyWithFontFamily>
              {event.user.toLowerCase() !== 'system user' && (
                <TypographyWithFontFamily color="text.secondary">By {event.user}</TypographyWithFontFamily>
              )}
              <TypographyWithFontFamily color="text.secondary">{event.comment}</TypographyWithFontFamily>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
};

export default CampaignTimeline;
