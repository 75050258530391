import { createTheme } from '@mui/material/styles';
import { createStyled } from '@mui/system';
import { createUseStyles, DefaultTheme, Styles } from 'react-jss';

import { THEME } from '#/constants/theme/constants';

const COLORS = {
  // https://chir.ag/projects/name-that-color
  // This colors should only be used in the theme, and should not be import from it
  HOLLYWOOD_CERISE: '#f50aa2',
  INDIGO: '#5846CB',
  JAPANESE_LAUREL: '#008000',
  RED: '#FF0000',
  WHITE: '#ffffff',
  WHITE_LILAC: '#f2f4fa',
} as const;

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.INDIGO,
    },
    secondary: {
      main: COLORS.WHITE_LILAC,
    },

    success: {
      main: COLORS.JAPANESE_LAUREL,
    },
    error: {
      main: COLORS.RED,
    },

    background: {
      default: COLORS.WHITE,
    },
  },

  typography: {
    fontFamily: 'Public-Sans, sans-serif',
  },
  spacing: THEME.DEFAULT_SPACING,
  custom: THEME,
});

export const styled = createStyled({ defaultTheme: theme });

export const createStyles = <Props extends string = string, Values = unknown, Theme = DefaultTheme>(
  styles: Styles<Props, Values, Theme>,
): Styles<Props, Values, Theme> => styles;

export const makeStyles = <Props extends string = string, Values = unknown, Theme = DefaultTheme>(
  createStyles: (muiTheme: typeof theme) => Styles<Props, Values, Theme>,
) => createUseStyles(createStyles(theme));

export default theme;
