import { Box, SxProps, Theme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import classnames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';

import { getTemporaryAvatar, postCompanyLogo } from '../../../api/avatar';
import CameraIconBlue from '../../../assets/CameraIconBlue.svg';
import { styles, useCompanyLogo } from './companyLogo.styles';

interface ICompanyLogo {
  setUserImageId?: React.Dispatch<React.SetStateAction<string>>;
  imageId?: string | null;
  viewOnly?: boolean;
  size?: 'small' | 'normal';
  companyName?: string | undefined;
  customStyles?: SxProps<Theme>;
}

const CompanyLogo: FC<ICompanyLogo> = ({
  setUserImageId,
  imageId,
  viewOnly,
  size = 'normal',
  companyName,
  customStyles,
}) => {
  const classes = useCompanyLogo();
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [imageLink, setImageLink] = useState('');

  const getFirstTwoPreview = () => {
    if (companyName) {
      const splitCompanyName = companyName.split(' ');
      return splitCompanyName.length >= 2
        ? splitCompanyName[0].charAt(0) + splitCompanyName[1].charAt(0).toUpperCase()
        : splitCompanyName[0].charAt(0).toUpperCase();
    }
  };

  useEffect(() => {
    if (currentFile) {
      (async () => {
        const { data: imageId } = await postCompanyLogo(currentFile);
        setUserImageId && setUserImageId(imageId as string);
        const imageData = await getTemporaryAvatar(imageId);
        setImageLink(URL.createObjectURL(imageData.data));
      })();
    }
  }, [currentFile]);

  useEffect(() => {
    if (imageId) {
      (async () => {
        const { data } = await getTemporaryAvatar(imageId);
        setImageLink(URL.createObjectURL(data));
      })();
    }
  }, [imageId]);

  const dropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!e.dataTransfer.items) {
      setCurrentFile(e.dataTransfer.files[0]);
    }
    setCurrentFile(e.dataTransfer.items[0].getAsFile());
  };

  const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => e.preventDefault();
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => setCurrentFile(e.target?.files?.[0] || null);
  const handleBrowseFile = () => inputRef.current?.click();

  return (
    <>
      {viewOnly ? (
        imageLink ? (
          <Box className={classes.align} sx={size === 'small' ? styles.avatarSmall : styles.avatarSx}>
            <img src={imageLink} />
          </Box>
        ) : (
          <Box className={classnames(classes.placeholderCustomBadge)} sx={customStyles}>
            {getFirstTwoPreview()}
          </Box>
        )
      ) : (
        <Box onClick={handleBrowseFile} onDragOver={(e) => dragOverHandler(e)} onDrop={(e) => dropHandler(e)}>
          <input ref={inputRef} className={classes.displayNone} onChange={(e) => handleFileChange(e)} type="file" />
          <Badge
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <img
                alt="badge"
                className={size === 'small' ? classes.smallAvatarIcon : classes.avatarIcon}
                src={CameraIconBlue}
              />
            }
            overlap="circular"
          >
            <Avatar src={imageLink} sx={size === 'small' ? styles.avatarSmall : styles.avatarSx} />
          </Badge>
        </Box>
      )}
    </>
  );
};

export default CompanyLogo;
