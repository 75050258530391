import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const useDynamicRibbonStyles = makeStyles((theme) =>
  createStyles({
    ribbonContainer: {
      padding: '0px',
      '&& .MuiButtonBase-root': { minHeight: '0px', height: THEME.REM_SPACES[2] },
      '&& .MuiAccordionDetails-root .MuiAccordionSummary-root': {
        padding: '0px',
      },
      '& ::-webkit-scrollbar': {
        width: '1px',
        height: '2px',
      },
      '& ::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#16191F',
        outline: '1px solid slategrey',
      },
      '&.MuiPaper-root': {
        boxShadow: 'none',
      },
    },
    ribbonTitleContainer: {
      '& .MuiAccordionSummary-content': {
        display: 'flex',
        columnGap: '8px',
      },
    },
    ribbonTitleText: {
      '&.MuiTypography-root': {
        fontWeight: 'bold',
        fontSize: '20px',
      },
    },
    label: {
      '&.MuiTypography-root': {
        marginBottom: '8px',
        color: '#606670',
        textTransform: 'uppercase',
        fontWeight: 'bold',
      },
    },
    commentsInput: {
      '& .MuiInputBase-root': {
        height: '80px',
        width: '1250px',
        marginBottom: '8px',
      },
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    rightButtonsContainer: {
      display: 'flex',
      columnGap: '8px',
    },
    lastButton: {
      '&.MuiButton-root': {
        background: '#5846CB ',
        borderRadius: '40px',
        border: '1px solid #5846CB',
        fontWeight: 'bold',
        marginRight: '8px',
        '&:hover': {
          background: '#5846CB',
          boxShadows: 'none',
        },
      },
    },
    singleText: {
      background: '#EBF6FD',
      height: '128px',
      borderRadius: '16px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px dashed #1F8BC9',
    },
    singleTextContent: {
      '&.MuiTypography-root': {
        color: '#1F8AC9',
      },
    },
    dynamicInventoryContainer: {
      width: '100%',
      background: THEME.PALETTES.UI.Secondary['100'],
      padding: '1rem',
      borderRadius: '1rem',
      display: 'flex',
      flexDirection: 'column',
    },
    inventoryTactic: {
      display: 'flex',
      columnGap: '1.5rem',
      alignSelf: 'center',
    },
    accordionDetails: {
      '&& .MuiAccordionDetails-root': {
        padding: '0px',
      },
      padding: '0px',
      marginTop: THEME.REM_SPACES[1],
    },
  }),
);

export const styles = {
  padding0: {
    padding: '0px',
  },
};
