import { Box } from '@mui/material';

import { useDataCy } from '#/hooks/useDataCy';

import { TypographyWithFontFamily } from '../typography/TypographyWithFontFamily';
import { useSubmissionStyle } from './submissionDeadline.styles';

const SubmissionDeadline = () => {
  const styles = useSubmissionStyle();
  const generateDataCy = useDataCy();

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.boxTable}>
        <span className={styles.title}>Brand Response Deadline</span>
        <TypographyWithFontFamily
          cName={styles.secondTitle}
          data-cy={generateDataCy('TypographyWithFontFamily', 'SubmissionDeadlineDate')}
        >
          mm/dd/yyyy
        </TypographyWithFontFamily>
      </Box>
      <Box className={styles.boxTable2}>
        <span className={styles.title}>Days remaning</span>
        <TypographyWithFontFamily
          cName={styles.secondTitle}
          data-cy={generateDataCy('TypographyWithFontFamily', 'SubmissionDeadlineRemainingDays')}
        >
          2
        </TypographyWithFontFamily>
      </Box>
    </Box>
  );
};

export default SubmissionDeadline;
