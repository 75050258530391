import { createStyles, makeStyles } from '#/utils/theme';

export const useSearchFiltersStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    resultContainer: { flexGrow: 1 },
    paginationContainer: {
      paddingLeft: theme.custom.REM_SPACES['1'],
      paddingRight: theme.custom.REM_SPACES['1'],
    },
  }),
);

export const customStyles = {
  noVerticalSpace: { marginBottom: 0, paddingBottom: 0, PaddingTop: 0 },
};
