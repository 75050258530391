import { useParams } from 'react-router';

import Loader from '#/components/common/loader/loader';
import { CampaignInstancesChannelPartner } from '#/components/features/campaigns/instances/list/CampaignInstancesChannelPartner/CampaignInstancesChannelPartner';

import { useGetInstancesChannelPartnerFilterQuery } from '#/store/api/campaigns/instances';

export const CampaignInstancesChannelPartnerContainer = () => {
  const { campaignId } = useParams();
  const { isLoading: filterDataIsLoading } = useGetInstancesChannelPartnerFilterQuery({ campaignId });

  if (filterDataIsLoading) return <Loader />;

  return <CampaignInstancesChannelPartner />;
};
