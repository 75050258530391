import SearchFilters from '#/components/common/SearchFilters/SearchFilters';
import useSearchFilters from '#/components/common/SearchFilters/useSearchFilters.hook';
import Loader from '#/components/common/loader/loader';
import CampaignCardView from '#/components/features/campaigns/CampaignCardView';

import {
  useGetCampaignsChannelPartnerFilterQuery,
  useGetCampaignsChannelPartnerQuery,
} from '#/store/api/campaigns/campaigns';

import { CampaignTableColumns } from '../../../../constants/tables/campaignTable/campaignTable';
import { CampaignListModes, ListCardsView } from '../../../common/TabModeToggle';
import { Table } from '../../../common/table/tableComponent/Table';

export const CampaignsChannelPartner = () => {
  const { data: filterData, isError: filterDataIsError } = useGetCampaignsChannelPartnerFilterQuery({});

  const { actions, state } = useSearchFilters(undefined, true, filterData?.filters);

  const {
    data: campaignsData,
    isError: campaignsIsError,
    isFetching: campaignsIsFetching,
    isLoading: campaignsIsLoading,
  } = useGetCampaignsChannelPartnerQuery({
    pageNumber: state.pageNumber,
    numberOfRows: state.numberOfElementsPerPage,
    searchQuery: state.searchQuery,
  });

  const { ChannelPartnerCampaignTable } = CampaignTableColumns(undefined, filterData ? filterData.filters : []);

  if (campaignsIsLoading) return <Loader />;

  return (
    <SearchFilters
      columnNames={filterData?.filters}
      currentIndex={campaignsData?.currentIndex}
      filterDataIsError={filterDataIsError}
      iconsMapper={ListCardsView}
      isError={campaignsIsError}
      isFetching={campaignsIsFetching}
      isNoResults={!campaignsData || campaignsData.campaigns.length === 0}
      totalPages={campaignsData?.totalPages}
      totalResults={campaignsData?.totalResults}
      {...state}
      {...actions}
    >
      {state.viewMode === CampaignListModes.LIST ? (
        <Table
          columns={ChannelPartnerCampaignTable}
          data={campaignsData?.campaigns || []}
          displayStatusColor
          sortProps={{
            sortParams: state.sortParams,
            setSortParams: actions.setSortParam,
          }}
        />
      ) : (
        <CampaignCardView data={campaignsData?.campaigns || []} />
      )}
    </SearchFilters>
  );
};
