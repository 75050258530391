import { createStyles, makeStyles, styled } from '#/utils/theme';

export const Container = styled('div')(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
}));

export const ItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: 460,
  height: 572,
}));

export const FormContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: 473,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  borderRadius: 16,
  boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
}));

export const HeadLogo = styled('div')(({ theme }) => ({
  display: 'flex',
  maxWidth: theme.custom.REM_SPACES[10],
}));

export const useLoginStyles = makeStyles((theme) =>
  createStyles({
    errorMessage: {
      color: theme.custom.PALETTES.TEXT.Error,
      margin: 0,
      marginTop: 5,
      fontSize: 12,
    },
    form: {
      height: 208,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      marginTop: '-0.625rem',
    },
    formContainer: {
      width: '100%',
      height: 473,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'white',
      borderRadius: 16,
      boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
    },
    formContent: {
      height: 356,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      padding: '0.625rem 1.875rem',
    },
    formInput: {
      width: '100%',
      border: `1px solid ${theme.custom.PALETTES.BORDER.Extra_Light}`,
      outline: 'none',
      lineHeight: 1.9,
      borderRadius: 8,
      padding: '3px 5px',
    },
    formHead: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: 120,
      backgroundColor: 'white',
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      borderBottom: `1px solid ${theme.custom.PALETTES.BORDER.Extra_Light}`,
      padding: 24,
      '& p': {
        fontSize: 18,
        fontWeight: 700,
        margin: 0,
      },
    },
    inputContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    inputLabel: {
      fontSize: 13,
      color: theme.custom.PALETTES.UI.Neutral['600'],
    },
    fullWidth: {
      width: '100%',
      marginBottom: '-0.625rem',
    },
    hidden: {
      visibility: 'hidden',
    },
    linksContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    logo: {
      margin: 'auto',
      marginBottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    submit: {
      marginTop: 20,
      cursor: 'pointer',
      color: theme.custom.PALETTES.TEXT.Light,
      backgroundColor: theme.custom.PALETTES.UI.Primary['600'],
      borderRadius: 40,
    },
    title: {
      fontSize: 21,
      fontWeight: 700,
      margin: 0,
    },
    url: {
      textDecoration: 'none',
      color: theme.custom.PALETTES.TEXT.Action_Secondary,
      fontSize: 14,
    },
  }),
);

export const useLogoutStyles = makeStyles(() =>
  createStyles({
    logoutButton: {
      backgroundColor: 'transparent',
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      border: 'none',
      cursor: 'pointer',
      fontWeight: 600,
      fontSize: '1rem',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    image: {
      marginRight: 8,
    },
  }),
);
