import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { RootState } from '#/store/reducers';
import { messagesSlice } from '#/store/slices';

import log from '#/utils/log';

export const useWebsocket = () => {
  const dispatch = useDispatch();
  const messages = useSelector((state: RootState) => state.messages);

  const connectWebsocket = (authorization: string) => {
    dispatch(messagesSlice.actions.connectWs(authorization));
  };

  const disconnectWebsocket = () => {
    dispatch(messagesSlice.actions.disconnectWs());
  };

  React.useEffect(() => {
    if (messages.isConnected) {
      dispatch(
        messagesSlice.actions.subscribeToTopic({
          topic: '/user/specific',
          callback: (message) => {
            log.debug('Received message: ', message);
          },
        }),
      );
    }
  }, [messages.isConnected]);

  return {
    connectWebsocket,
    disconnectWebsocket,
  };
};
