import { Box } from '@mui/material';
import classnames from 'classnames';

import { Alerts } from '#/components/features/dashboard/Alerts/Alerts';
import PendingInvitations from '#/components/features/dashboard/PendingInvitations/PendingInvitations';

import UserTypeWrapper from '#/utils/UserTypeWrapper';

import DashboardIcon from '../../../assets/ChartLineUp.svg';
import { USER_PERMISSIONS } from '../../../constants/permissions/userPermissions';
import { getUserHasPermission } from '../../../constants/permissions/utils';
import { IUserPermissions, useUserPermissionType } from '../../../hooks/useUserPermissionType';
import { IQuickLinks } from '../../../interfaces/dashboard/quickLinks';
import DisplayPageName from '../../common/displayPageName/DisplayPageName';
import { useDashboardPageStyles } from './Dashboard.styles';
import { Charts } from './charts/Charts';
import { PendingCampaigns } from './pendingCampaigns/PendingCampaigns';
import { PendingTasks } from './pendingTasks/PendingTasks';
import { QuickLinks } from './quickLinks/QuickLinks';
import { brandLinks, retailerLinks } from './quickLinks/constants';

const permissionsObj: IUserPermissions = {
  retailerPermission: USER_PERMISSIONS.DASHBOARD_QUICK_LINKS_RETAILER_READ,
  brandPermission: USER_PERMISSIONS.DASHBOARD_QUICK_LINKS_BRAND_READ,
  channelPartnerPermission: USER_PERMISSIONS.DASHBOARD_QUICK_LINKS_CHANNEL_PARTNER_READ,
};

const Dashboard = (): JSX.Element => {
  const classes = useDashboardPageStyles();
  const { retailerPermission, brandPermission, channelPartnerPermission } = useUserPermissionType(permissionsObj);
  const userHasChartPermission = getUserHasPermission(USER_PERMISSIONS.DASHBOARD_OVERVIEW_READ);

  let quickLinks: IQuickLinks[] = [];
  if (retailerPermission) {
    quickLinks = retailerLinks;
  } else if (brandPermission || channelPartnerPermission) {
    quickLinks = brandLinks;
  }

  return (
    <Box data-testid="dashboardPage">
      <DisplayPageName imgSrc={DashboardIcon} pageName="Dashboard" />
      <Box className={classes.dashboardContainer}>
        <Box className={classnames(classes.column, classes.double)}>
          {userHasChartPermission && <Charts />}
          <UserTypeWrapper agency={<PendingInvitations />} brand={<PendingInvitations />} />
          <PendingCampaigns />
        </Box>
        <Box className={classnames(classes.column, classes.single)}>
          <Alerts />
          <PendingTasks />
          <Box className={classes.column}>{!!quickLinks.length && <QuickLinks links={quickLinks} />}</Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Dashboard;
