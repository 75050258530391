import { IMetadataDTO, TacticMetadataForm } from '#/interfaces/tacticMetadata/tacticMetadata';

export const getFormResetValues = (formData: IMetadataDTO): TacticMetadataForm => {
  const defaultFields = {};
  formData.metadata.forEach((input) => {
    if (input.metadataValue) {
      defaultFields[String(input.metadataAttribute.id)] = input.metadataValue;
    } else if (input.metadataAttribute.defaultValue) {
      defaultFields[String(input.metadataAttribute.id)] = input.metadataAttribute.defaultValue;
    }
  });

  return defaultFields;
};

export const extractInputsFromMetadata = (inputs: IMetadataDTO) => {
  return inputs?.metadata?.map((data) => data.metadataAttribute);
};
