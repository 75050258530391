import { Box, Grid } from '@mui/material';
import { flow } from 'fp-ts/function';
import { FC, useState } from 'react';

import TextField from '#/components/common/inputs/TextField/TextField';
import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';

import { createFormatterWithReverse, currencyFormatter, extractNumber, numberFormatter } from '#/utils/textFormatters';

import EditIcon from '../../../assets/Edit.svg';
import { USER_PERMISSIONS } from '../../../constants/permissions/userPermissions';
import { useAddTireForm } from '../../../hooks/forms/campaigns/useAddTireForm';
import { IChannelAndTacticsResponseTactic, IChannelAndTacticsResponseTier } from '../../../interfaces/forms/campaigns';
import EditComboButton from '../../../utils/EditComboButton';
import PermissionWrapper from '../../../utils/PermissionWrapper';
import NewCampaignTierTacticsTable from '../../campaigns/NewCampaignTierTacticsTable';
import NewButton from '../../common/button/NewButton';
import { useCampaignTierStyle } from './CampaignTier.style';
import AddTacticsToTier from './addTacticsToTier';
import CopyTier from './copyTier';

interface ICampaignTierProps {
  tier?: IChannelAndTacticsResponseTier;
  userHasCreatePermission?: boolean;
  isOnInstance?: boolean;
  tierRefetch?: () => void;
}

const CampaignTier: FC<ICampaignTierProps> = ({ tier, userHasCreatePermission, isOnInstance = false, tierRefetch }) => {
  const [editMode, setEditMode] = useState(false);
  const { reset, errors, register, watch, onSubmitEdit, refetch, isPutCampaignTierLoading, dataLoading, setValue } =
    useAddTireForm(() => setEditMode(false), tier);
  const [isAddTacticsModalOpen, setIsAddTacticsModalOpen] = useState(false);

  const onAddTactics = () => {
    setIsAddTacticsModalOpen((prev: boolean) => !prev);
  };
  const getUniqueTactics = (tierTactics: IChannelAndTacticsResponseTactic[] | undefined) => {
    return Array.from(
      new Set(
        tierTactics?.map((obj) =>
          JSON.stringify({
            channel: obj.channel,
            subChannel: obj.subChannel,
            area: obj.tactic,
            channelPartnerId: obj.channelPartnerId,
          }),
        ),
      ).values(),
    ).map((str) => JSON.parse(str));
  };

  const classes = useCampaignTierStyle();

  return (
    <Box>
      <Box className={classes.tierContainer}>
        <Box>
          <form className={classes.form} onSubmit={onSubmitEdit}>
            <Grid columnGap={'0.5rem'} container>
              <Grid item xs={2.9}>
                <TextFieldOld
                  editMode={editMode}
                  error={errors.tierName?.message}
                  label="TIER NAME"
                  register={register('tierName')}
                  value={tier?.tierName}
                />
              </Grid>
              <Grid item xs={2.9}>
                <TextField
                  disabled={!editMode}
                  error={errors.targetBudget?.message}
                  formHooks={{
                    register: register('targetBudget'),
                    setValue: (value) => setValue('targetBudget', parseFloat(value)),
                  }}
                  formatters={{
                    preview: currencyFormatter,
                    typing: createFormatterWithReverse(flow(extractNumber, currencyFormatter), extractNumber),
                  }}
                  label="TARGET BUDGET"
                />
              </Grid>
              <Grid item xs={2.9}>
                <TextField
                  disabled={!editMode}
                  error={errors.targetBrands?.message}
                  formHooks={{
                    register: register('targetBrands'),
                    setValue: (value) => setValue('targetBrands', parseFloat(value)),
                  }}
                  formatters={{
                    preview: numberFormatter,
                    typing: createFormatterWithReverse(flow(extractNumber, numberFormatter), extractNumber),
                  }}
                  label="TARGET BRANDS"
                />
              </Grid>
              <Grid item xs={2.9}>
                <TextField
                  disabled
                  formatters={{
                    preview: currencyFormatter,
                  }}
                  label="TARGET TOTAL"
                  value={(watch('targetBudget') * watch('targetBrands')).toString()}
                />
              </Grid>
            </Grid>
            <div className={classes.bottomButtons}>
              <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.CAMPAIGN_CHANNEL_TACTICS_WRITE]}>
                <EditComboButton
                  dataLoading={dataLoading}
                  edit={editMode}
                  isLoading={isPutCampaignTierLoading}
                  onSubmit={onSubmitEdit}
                  refetch={refetch}
                  reset={reset}
                  setEdit={setEditMode}
                />
              </PermissionWrapper>
            </div>
          </form>
        </Box>
        <Box className={classes.tacticsTableContainer}>
          <NewCampaignTierTacticsTable tactics={tier?.tactics} tierId={tier?.tierId} tierRefetch={tierRefetch} />
        </Box>
        {userHasCreatePermission && (
          <Box className={classes.copyButtonsContainer}>
            <NewButton icon={EditIcon} iconPosition="left" onClick={onAddTactics} text="Edit Tactics" variant="Ghost" />
            <CopyTier isOnInstance={isOnInstance} tierCopy={tier?.tierId} />
          </Box>
        )}
      </Box>

      {isAddTacticsModalOpen && (
        <AddTacticsToTier
          isAddTacticsModalOpen={isAddTacticsModalOpen}
          savedTactics={getUniqueTactics(tier?.tactics)}
          setIsAddTacticsModalOpen={setIsAddTacticsModalOpen}
          tierId={tier?.tierId}
          tierName={tier?.tierName}
        />
      )}
    </Box>
  );
};
export default CampaignTier;
