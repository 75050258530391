export const PUBLIC_ROUTE_PATHS = {
  LOGIN: 'login',
  SIGNUP: 'request-account',
  CALLBACK: 'login/callback',
} as const;

export type PUBLIC_ROUTE_PATHS = (typeof PUBLIC_ROUTE_PATHS)[keyof typeof PUBLIC_ROUTE_PATHS];

export const ROUTE_PATHS = {
  //private
  ROOT: '',

  PRODUCT_MIX: 'product-mix',
  USER_ACCESS: 'user-access',

  BRANDS: 'brands',
  BRAND_SINGLE: 'brands/:brandId',
  BRAND_ORDERS: 'brand-orders',

  CAMPAIGNS: 'campaigns',
  CAMPAIGNS_ALL: 'all',
  CAMPAIGNS_ACTIVE: 'active',
  CAMPAIGNS_PENDING: 'pending',
  CAMPAIGNS_COMPLETE: 'complete',
  CAMPAIGNS_REJECTED: 'rejected',
  CAMPAIGNS_CANCELED: 'cancelled',
  CAMPAIGN_OVERVIEW: 'overview/:campaignId',
  CAMPAIGN_CHANNEL_TACTICS: 'channel-tactics/:campaignId',
  CAMPAIGN_TARGET_PRODUCT_MIX: 'target-product-mix/:campaignId',
  CAMPAIGN_BRANDS: 'brands/:campaignId',
  CAMPAIGN_INVITE: 'invite/:campaignId',
  CAMPAIGN_INSTANCES: 'instances/:campaignId',
  CAMPAIGN_HISTORY: 'history/:campaignId',
  CAMPAIGN_ENGAGEMENT_STATS: 'engagement-stats/:campaignId',

  CAMPAIGN_INSTANCES_OVERVIEW: 'overview/:instanceId',
  CAMPAIGN_INSTANCES_CHANNEL_TACTICS: 'channel-tactics/:instanceId',
  CAMPAIGN_INSTANCES_ASSETS: 'assets/:instanceId',
  CAMPAIGN_INSTANCE_HISTORY: 'history/:instanceId',
  CAMPAIGN_BRAND_ORDER_DETAILS: 'brand-order-details/:instanceId',
  CAMPAIGN_INSTANCE: 'instances',

  TASKS: 'tasks',
  TASKS_ALL: 'all',
  TASKS_OPEN: 'open',
  TASKS_CLOSED: 'closed',

  TASKS_OVERVIEW: 'overview/:aggregateId',

  PROMOTE: 'promote',
  COMMUNICATIONS: 'communications',
  PROMOTE_PENDING_INVITATIONS: 'pending-invitations',
  CAMPAIGNS_PROMOTE: 'promote',
  CAMPAIGNS_INVITE_BRAND: 'invite-brands',
  PROMOTE_INVITE_RECIPIENTS: 'invite-recipients',
  PROMOTE_MARKETING: 'marketing',
  PROMOTE_REVIEW: 'review',
  PROMOTE_BRAND_ENGAGEMENT: 'brand-engagement',
  PROMOTE_RESPONSES: 'responses',
  COMPANIES: 'companies',
  PARTNERS: 'partners',
  CHANNEL_PARTNERS: 'channel-partners',
  CHANNEL_PARTNERS_SINGLE: 'channel-partners/:channelId',
  PARTNERS_BRANDS: 'brands',
  PARTNERS_AGENCIES: 'agencies',
  PARTNERS_AGENCY: 'agencies/:agencyId',
  PARTNERS_USERS: 'users',
  PARTNERS_USERS_SINGLE: 'users/:userId',

  WORKFLOW: 'workflow',

  CALENDAR: 'calendar',

  REPORTS: 'reports',
  REPORT_DETAILS: ':reportId',

  SETTINGS: 'settings',
  SETTINGS_CHANNELS_AND_TACTICS: 'channels-and-tactics',
  SETTINGS_USER: 'user',
  SETTINGS_JOBS: 'general',
  SETTINGS_REPORT: 'report',
  SETTINGS_DATA_JOBS: 'data-jobs',
  SETTINGS_WORKFLOWS: 'workflows',

  ACCOUNT: 'account',
  ACCOUNT_MANAGEMENT: 'management',
  ACCOUNT_NOTIFICATIONS: 'notifications',

  SA_CREATE_CHANNEL_PARTNER: 'super-admin/create-channel-partner',
  SA_CHANNEL_PARTNER_LIST: 'super-admin/channel-partner-list',
  SA_CHANNEL_PARTNER_COMMUNICATION_METHOD: 'super-admin/channel-partner-communication-method',

  //public
  ...PUBLIC_ROUTE_PATHS,
} as const;

export type ROUTE_PATHS = (typeof ROUTE_PATHS)[keyof typeof ROUTE_PATHS];
