import { makeStyles, createStyles } from '#/utils/theme';

export const useUnexistingRouteStyles = makeStyles((theme) =>
  createStyles({
    wrapperDiv: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    text: { fontSize: 24, fontWeight: 700 },
    link: {
      textDecoration: 'none',
      color: theme.custom.PALETTES.TEXT.Action_Secondary,
    },
  }),
);
