import { Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import classnames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';

import { THEME } from '#/constants/theme/constants';

import {
  useGetGeneralCommunicationsQuery,
  useGetPromoteCommunicationsQuery,
} from '#/store/api/communications/communications';

import DatePicker from '../../../common/inputs/DatePicker/DatePicker';
import Loader from '../../../common/loader/loader';
import { NoDataMessage } from '../../../common/noDataMessage/NoDataMessage';
import { useSnackbarError } from '../../../common/snackbar/useSnackbarError';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { chartMargin, errorMessageSx, useCommunicationsChartStyle } from './CommunicationsChart.styles';
import { CustomTooltip } from './CustomTooltip';
import { formatMessagesEngagement } from './utils';

// this will differentiate what api endpoint should be called inside the component
interface IProps {
  isGeneralCommunications: boolean;
}

export const CommunicationsChart: FC<IProps> = ({ isGeneralCommunications }) => {
  const classes = useCommunicationsChartStyle();
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [isErrorVisible, setErrorVisibility] = useState(false);
  const { setError } = useSnackbarError();

  useEffect(() => {
    if (startDate > endDate) {
      setErrorVisibility(true);
    } else {
      setErrorVisibility(false);
    }
  }, [startDate, endDate]);

  const { data, error, isLoading } = isGeneralCommunications
    ? useGetGeneralCommunicationsQuery(
        { startDate, endDate },
        {
          skip: startDate > endDate,
        },
      )
    : useGetPromoteCommunicationsQuery(
        { startDate, endDate },
        {
          skip: startDate > endDate,
        },
      );

  if (error) {
    setError(error);
  }

  if (isLoading) return <Loader />;

  if (!data) {
    return <NoDataMessage message="No Data Available" />;
  }

  const chartData = formatMessagesEngagement(data);

  return (
    <Box className={classes.chartWrapperDiv}>
      <Box className={classes.headerContainer}>
        <TypographyWithFontFamily variant="H5_Base">Sending Overview</TypographyWithFontFamily>
        <Box className={classes.datePickerAndErrorContainer}>
          <Box className={classes.datePickerContainer}>
            <Box className={classes.datePicker}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label=""
                  onChange={(value: Dayjs | null) => {
                    setStartDate(dayjs(value).format('YYYY-MM-DD'));
                  }}
                  value={startDate}
                />
              </LocalizationProvider>
            </Box>
            <Box className={classes.datePicker}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label=""
                  onChange={(value: Dayjs | null) => {
                    setEndDate(dayjs(value).format('YYYY-MM-DD'));
                  }}
                  value={endDate}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <TypographyWithFontFamily
            className={classnames({ [classes.hide]: !isErrorVisible })}
            color={THEME.PALETTES.TEXT.Error}
            sx={errorMessageSx}
          >
            Selected Start Date must be earlier than Selected End Date
          </TypographyWithFontFamily>
        </Box>
      </Box>
      <ResponsiveContainer height="100%" width="100%">
        <LineChart data={chartData} height={300} margin={chartMargin} width={500}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis className={classes.date} dataKey="time" />
          <YAxis dataKey="total" />
          <Line dataKey="accepted" name="Accepted" stroke={THEME.PALETTES.LEGEND.ACCEPTED} type="monotone" />
          <Line dataKey="delivered" name="Delivered" stroke={THEME.PALETTES.LEGEND.DELIVERED} type="monotone" />
          <Line dataKey="failed" name="Failed" stroke={THEME.PALETTES.LEGEND.FAILED} type="monotone" />
          <Line dataKey="opened" name="Opened" stroke={THEME.PALETTES.LEGEND.OPENED} type="monotone" />
          <Line dataKey="clicked" name="Clicked" stroke={THEME.PALETTES.CHARTS.BOOKED} type="monotone" />
          <Tooltip content={<CustomTooltip />} />
          <Legend iconType="circle" />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
