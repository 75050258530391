import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useSnackbarError } from '../../../components/common/snackbar/useSnackbarError';
import { IAddTierForm } from '../../../interfaces/forms/campaigns';
import {
  usePostCampaignTierInstanceMutation,
  usePutCampaignTierInstanceMutation,
} from '../../../store/api/instances/campaignInstanceChannelsAndTactics';
import useGetResource from '../../useGetResource';

export const useAddTireInstancesForm = (close?: () => void) => {
  const resource = useGetResource();
  const [triggerPost, { error: triggerPostError }] = usePostCampaignTierInstanceMutation();
  const [triggerPut, { error: triggerPutError }] = usePutCampaignTierInstanceMutation();

  const error = triggerPostError || triggerPutError;

  const schema = yup
    .object()
    .shape({
      tierName: yup.string().required('Tier name is required'),
      targetBudget: yup.number().typeError('Target budget must be a number').required('Target budget is required'),
      targetBrands: yup.number().typeError('Target brands must be a number').required('Target brands is required'),
      targetTotal: yup.number().required('Target brands is required'),
    })
    .required();

  const {
    register,
    clearErrors,
    control,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IAddTierForm>({ resolver: yupResolver(schema) });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorMessage] = useState('');
  const { setError } = useSnackbarError();

  const onSubmit = handleSubmit(async (formData: IAddTierForm) => {
    try {
      await triggerPost({ resource, body: formData }).unwrap();
      reset();
    } catch (error) {
      setError(error);
    }
    clearErrors();
  });

  const onSubmitEdit = handleSubmit(async (formData: IAddTierForm) => {
    try {
      await triggerPut({ body: formData }).unwrap();
      reset();
      close?.();
    } catch (error) {
      setError(error);
    }
    clearErrors();
  });

  return {
    register,
    onSubmit,
    errors,
    errorMessage,
    reset,
    handleSubmit,
    control,
    watch,
    setValue,
    onSubmitEdit,
    error,
  };
};
