import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import React, { FC } from 'react';

import NewButton from '#/components/common/button/NewButton';
import { useSnackbarError } from '#/components/common/snackbar/useSnackbarError';

import { useGetInstanceAssetMetadataTacticDownloadMutation } from '#/store/api/campaigns/tacticAssetForm';

interface DownloadAssetsFilesProps {
  tacticId: string;
}
const DownloadAssetsFiles: FC<DownloadAssetsFilesProps> = ({ tacticId }) => {
  const [getInstanceAssetMetadataTacticDownloadMutation] = useGetInstanceAssetMetadataTacticDownloadMutation();
  const { setError } = useSnackbarError();

  const onDownload = async () => {
    try {
      const { file, contentDisposition } = await getInstanceAssetMetadataTacticDownloadMutation({
        instanceTacticId: tacticId,
      }).unwrap();
      const match = contentDisposition?.match(/filename=([^"]+)/);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(file);
      link.setAttribute('download', match?.[1] || 'report.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setError((error as FetchBaseQueryError).data);
    }
  };
  return <NewButton onClick={onDownload} text="Download Files" variant="Secondary" />;
};

export default DownloadAssetsFiles;
