import { Box, FormHelperText, Grid } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';

import { UsStates } from '../../constants/statesPlaceholder';
import { IAddUserWizardForm } from '../../interfaces/forms/addUserWizard';
import Checkbox from '../common/inputs/Checkbox/Checkbox';
import Select from '../common/inputs/Select/Select';
import { TypographyWithFontFamily } from '../common/typography/TypographyWithFontFamily';
import { UserProfilePicture } from '../features/userProfile/UserProfilePicture';
import { contactCheckboxContainerSx, userInfoHeaderSx } from './AddUserWizard.style';

interface IAddCompanyUserProps {
  userImageId: string;
  setUserImageId: React.Dispatch<React.SetStateAction<string>>;
}

const AddCompanyUser: FC<IAddCompanyUserProps> = ({ userImageId, setUserImageId }) => {
  const {
    register,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<IAddUserWizardForm>();
  useEffect(() => {
    setValue('user.avatar', userImageId);
  }, [userImageId]);
  return (
    <Box>
      <TypographyWithFontFamily sx={userInfoHeaderSx} variant="H5_Base">
        User Info
      </TypographyWithFontFamily>
      <Grid container spacing={{ xs: '1rem' }}>
        <Grid item xs={6}>
          <TextFieldOld
            editMode
            error={errors?.user?.firstName?.message}
            label="First Name"
            register={register('user.firstName')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldOld
            editMode
            error={errors?.user?.lastName?.message}
            label="Last Name"
            register={register('user.lastName')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldOld editMode error={errors?.user?.email?.message} label="Email" register={register('user.email')} />
        </Grid>
        <Grid item xs={6}>
          <TextFieldOld
            editMode
            error={errors?.user?.title?.message}
            label="Job Title"
            register={register('user.title')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldOld
            editMode
            error={errors?.user?.phoneNumber?.message}
            label="Phone"
            register={register('user.phoneNumber')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldOld
            editMode
            error={errors?.user?.department?.message}
            label="Department"
            register={register('user.department')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldOld
            editMode
            error={errors?.user?.addressLine1?.message}
            label="Address Line 1"
            register={register('user.addressLine1')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldOld
            editMode
            error={errors?.user?.addressLine2?.message}
            label="Address Line 2"
            register={register('user.addressLine2')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldOld editMode error={errors?.user?.city?.message} label="City" register={register('user.city')} />
        </Grid>
        <Grid item xs={4}>
          <TextFieldOld
            editMode
            error={errors?.user?.country?.message}
            label="Country"
            register={register('user.country')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldOld
            editMode
            error={errors?.user?.zipCode?.message}
            label="Postal Code"
            register={register('user.zipCode')}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            label="State"
            onChange={(event) => setValue('user.state', event.target.value)}
            options={UsStates.map((state) => ({ label: state.label, value: state.code }))}
            value={watch('user.state')}
          />
          <FormHelperText>
            <TypographyWithFontFamily>{errors?.user?.state?.message}</TypographyWithFontFamily>
          </FormHelperText>
        </Grid>
        <Grid item xs={3}>
          <TypographyWithFontFamily variant="H6_Regular">Upload Image / Avatar</TypographyWithFontFamily>
          <UserProfilePicture autoSave={false} imageId={watch('user.avatar')} setUserImageId={setUserImageId} />
          <FormHelperText>
            <TypographyWithFontFamily>{errors?.user?.avatar?.message}</TypographyWithFontFamily>
          </FormHelperText>
        </Grid>
        <Grid container item sx={contactCheckboxContainerSx} xs={8}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={'user.keyContact'}
              render={({ field }) => <Checkbox label="Key Contact" onChange={field.onChange} value={field.value} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={'user.billingContact'}
              render={({ field }) => <Checkbox label="Billing Contact" onChange={field.onChange} value={field.value} />}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddCompanyUser;
