import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../constants/theme/constants';

export const useCampaignTierTacticsTableStyle = makeStyles(() =>
  createStyles({
    areaButton: {
      '&&': {
        border: 'none',
        color: THEME.PALETTES.TEXT.Action_Secondary,
        fontWeight: 600,
        fontSize: '0.8125rem',
      },
      '&&:hover': {
        border: 'none',
        color: THEME.PALETTES.TEXT.Action_Secondary,
        fontWeight: 600,
        fontSize: '0.8125rem',
        background: THEME.PALETTES.OTHER.White,
      },
    },
    inventoryButtons: {
      display: 'flex',
      columnGap: THEME.REM_SPACES['0.5'],
      justifyContent: 'center',
    },
  }),
);
