import * as React from 'react';
import { pipe } from 'fp-ts/lib/function';

import { isDevelopment } from '#/constants/general';

import log from '#/utils/log';
import { camelOrPascalToDash, nonAlphaNumericToDash } from '#/utils/textFormatters';

const usedIds = new Map<string, symbol>();

export const useDataCy = () => {
  const instance = React.useRef(Symbol('useDataCy'));

  const cleanUp = React.useCallback(() => {
    usedIds.forEach((value, key) => {
      if (value === instance.current) {
        usedIds.delete(key);
      }
    });
  }, []);

  const generateDataCy = React.useCallback(
    /**
     *
     * @param type Should be a string that describes the type of element, e.g. 'button', 'input', 'select', etc.
     * @param description Should be a string that describes the element, e.g. 'save', 'name', 'product', etc.
     */
    (type: string, description: string) => {
      const id = `${type}-${pipe(description, nonAlphaNumericToDash, camelOrPascalToDash)}`.toLowerCase();
      //@TODO Because we are using StrictMode, this will be called twice in development mode, so it will always log a warning
      if (usedIds.has(id) && usedIds.get(id) !== instance.current && !isDevelopment) {
        log.warn(`Duplicate data-cy id: ${id}`);
      }
      usedIds.set(id, instance.current);

      return id;
    },
    [],
  );

  React.useEffect(() => cleanUp, []);

  return generateDataCy;
};
