import { ITacticRow } from '../../../interfaces/partners/channelPartners/channelPartners';
import { ITableColumnsProps, TableColumnAlign, TableCellTypes } from '../../../interfaces/table/table';

export const getTacticsTableColumns = (): ITableColumnsProps<ITacticRow>[] => [
  {
    fieldName: 'tactic',
    align: TableColumnAlign.LEFT,
    type: TableCellTypes.STRING,
    customHead: ' ',
  },
];
