import { Box, Grid } from '@mui/material';
import { flow } from 'fp-ts/function';
import { FC, useState } from 'react';

import TextField from '#/components/common/inputs/TextField/TextField';
import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';

import { createFormatterWithReverse, currencyFormatter, extractNumber } from '#/utils/textFormatters';

import { useAddTireForm } from '../../../../../../hooks/forms/campaigns/useAddTireForm';
import { IChannelAndTacticsResponseTier } from '../../../../../../interfaces/forms/campaigns';
import { useCampaignTierStyle } from '../../../CampaignTier.style';
import NewCampaignTierTacticsTableBrand from './NewCampaignTierTacticsTableBrand';

interface ICampaignTireProps {
  tier: IChannelAndTacticsResponseTier;
  fetchTiers?: () => Promise<void>;
  userHasCreatePermission?: boolean;
  isOnInstance?: boolean;
}
const CampaignTierBrand: FC<ICampaignTireProps> = ({ tier }) => {
  const [editMode, setEditMode] = useState(false);
  const { errors, register, onSubmitEdit, setValue } = useAddTireForm(() => setEditMode(false), tier);

  const classes = useCampaignTierStyle();

  return (
    <Box className={classes.tierContainer}>
      <Box>
        <form className={classes.form} onSubmit={onSubmitEdit}>
          <Grid columnGap={'0.5rem'} container>
            <Grid item xs={2.9}>
              <TextFieldOld
                editMode={editMode}
                error={errors.tierName?.message}
                label="TIER NAME"
                register={register('tierName')}
                value={tier?.tierName}
              />
            </Grid>
            <Grid item xs={2.9}>
              <TextField
                disabled={!editMode}
                error={errors.targetBudget?.message}
                formHooks={{
                  register: register('targetBudget'),
                  setValue: (value) => setValue('targetBudget', parseFloat(value)),
                }}
                formatters={{
                  preview: currencyFormatter,
                  typing: createFormatterWithReverse(flow(extractNumber, currencyFormatter), extractNumber),
                }}
                label="TARGET BUDGET"
              />
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box className={classes.tacticsTableContainer}>
        <NewCampaignTierTacticsTableBrand tactics={tier?.tactics} />
      </Box>
    </Box>
  );
};
export default CampaignTierBrand;
