import { Box, Stack } from '@mui/material';

import { InvitationDownloadListType } from '#/constants/campaignInvitations/campaignInvitationsRecipients';

import { useAppSelector } from '#/hooks';

import { usePostCompanyAccountSelectedBrandsQuery } from '#/store/api/company/company';
import { communicationsPromote } from '#/store/slices';

import { TypographyWithFontFamily } from '../common/typography/TypographyWithFontFamily';
import CompanyLogo from '../shared/companyLogo/CompanyLogo';
import { useCampaignInvitationsStyles } from './CampaignInvitations.styles';
import DownloadRecipients from './DownloadRecipients';

const CampaignBrandsList = () => {
  const { selectedRecipients } = useAppSelector((state) => state.communications);
  const selectedBrandsIds = useAppSelector((state) => communicationsPromote.getSelectedBrandsIds(state.communications));
  const { data: selectedBrands } = usePostCompanyAccountSelectedBrandsQuery({ selectedBrandsIds });
  const selectedEmails = useAppSelector((state) =>
    communicationsPromote.getSelectedRecipientsEmails(state.communications),
  );

  const classes = useCampaignInvitationsStyles();
  return (
    <Box>
      <TypographyWithFontFamily variant="H5_Base">
        Selected Brands ({selectedBrandsIds.length})
      </TypographyWithFontFamily>
      <DownloadRecipients idList={selectedBrandsIds || []} type={InvitationDownloadListType.BRANDS} />
      <Box className={classes.campaignRecipients}>
        {selectedBrands?.map((item) => (
          <Stack key={item.companyName} className={classes.campaignBrandListStack} direction="row" spacing="8px">
            <CompanyLogo companyName={item.companyName} imageId={item.logoId} size="small" viewOnly />
            <TypographyWithFontFamily className={classes.marginLeftFix}>{item.companyName}</TypographyWithFontFamily>
          </Stack>
        ))}
      </Box>
      <TypographyWithFontFamily variant="H5_Base">
        Selected Recipients ({selectedRecipients.length})
      </TypographyWithFontFamily>
      <DownloadRecipients idList={selectedEmails} type={InvitationDownloadListType.RECIPIENTS} />
      <Box className={classes.campaignRecipients}>
        {selectedEmails.map((email: string) => (
          <Stack key={email} className={classes.campaignBrandListStack} direction="row" spacing="8px">
            <TypographyWithFontFamily>{email}</TypographyWithFontFamily>
          </Stack>
        ))}
      </Box>
    </Box>
  );
};

export default CampaignBrandsList;
