import { Icon, Tab, Tabs } from '@mui/material';
import classNames from 'classnames';

import CalendarIcon from '#/assets/CalendarIcon.svg';
import CalendarWhiteIcon from '#/assets/CalendarWhiteIcon.svg';
import GridIcon from '#/assets/GridIcon.svg';
import GridIconWhite from '#/assets/GridIconWhite.svg';
import ListIcon from '#/assets/ListIcon.svg';
import ListIconWhite from '#/assets/ListIconWhite.svg';
import TimelineIcon from '#/assets/TimelineIcon.svg';
import TimelineWhiteIcon from '#/assets/TimelineWhiteIcon.svg';

import { useTabModeToggleStyles } from '#/components/common/TabModeToggle.styles';

import { useDataCy } from '#/hooks/useDataCy';

export enum CampaignListModes {
  'LIST',
  'GRID',
}

export enum CalendarModes {
  'MONTH',
  'TIMELINE',
}

export const ListCardsView: TabsIconMapper<CampaignListModes>[] = [
  {
    value: CampaignListModes.LIST,
    iconsSources: {
      active: ListIconWhite,
      inactive: ListIcon,
    },
  },
  {
    value: CampaignListModes.GRID,
    iconsSources: {
      active: GridIconWhite,
      inactive: GridIcon,
    },
  },
];

export const MonthTimelineView: TabsIconMapper<CalendarModes>[] = [
  {
    value: CalendarModes.MONTH,
    iconsSources: {
      active: CalendarWhiteIcon,
      inactive: CalendarIcon,
    },
  },
  {
    value: CalendarModes.TIMELINE,
    iconsSources: {
      active: TimelineWhiteIcon,
      inactive: TimelineIcon,
    },
  },
];

export type TabsIconMapper<T> = {
  value: T;
  iconsSources: {
    active: string;
    inactive: string;
  };
};

type TabModeToggleProps<T> = {
  selectedTab: T;
  setSelectedTab: React.Dispatch<React.SetStateAction<T>>;
  iconsMapper: TabsIconMapper<T>[];
};

const TabModeToggle = <T,>({ selectedTab, setSelectedTab, iconsMapper }: TabModeToggleProps<T>) => {
  const generateDataCy = useDataCy();

  const onViewModeChange = (event: React.SyntheticEvent, newViewMode: T) => {
    setSelectedTab(newViewMode);
  };

  const classes = useTabModeToggleStyles();
  return (
    <Tabs className={classes.container} onChange={onViewModeChange}>
      {iconsMapper.map((val) => {
        const isActiveTab = val.value === selectedTab;
        const iconSrc = isActiveTab ? val.iconsSources.active : val.iconsSources.inactive;

        return (
          <Tab
            key={String(val.value)}
            className={classNames(classes.tab, { [classes.tabActive]: isActiveTab })}
            data-cy={generateDataCy('tab', 'TabModeToggle')}
            label={
              <Icon>
                <img alt="campaign" src={iconSrc} />
              </Icon>
            }
          />
        );
      })}
    </Tabs>
  );
};

export default TabModeToggle;
