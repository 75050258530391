import { Box, Grid } from '@mui/material';
import { flow } from 'fp-ts/function';
import { FC, useState } from 'react';

import TextField from '#/components/common/inputs/TextField/TextField';
import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';

import { createFormatterWithReverse, extractNumber, numberFormatter } from '#/utils/textFormatters';

import { useAddTireForm } from '../../../../../hooks/forms/campaigns/useAddTireForm';
import { IChannelAndTacticsResponseTier } from '../../../../../interfaces/forms/campaigns';
import { useCampaignTierStyle } from '../../CampaignTier.style';
import NewCampaignTierTacticsTableChannelPartnerInstances from './NewCampaignTierTacticsTableChannelPartnerInstances';

interface ICampaignTireProps {
  tier?: IChannelAndTacticsResponseTier;
  fetchTiers?: () => Promise<void>;
  userHasCreatePermission?: boolean;
  isOnInstance?: boolean;
}
const CampaignTierChannelPartnerInstances: FC<ICampaignTireProps> = ({ tier }) => {
  const [editMode, setEditMode] = useState(false);
  const { errors, register, onSubmitEdit, setValue } = useAddTireForm(() => setEditMode(false), tier);

  const classes = useCampaignTierStyle();

  return (
    <Box>
      <Box className={classes.tierContainer}>
        <Box>
          <form className={classes.form} onSubmit={onSubmitEdit}>
            <Grid columnGap={'0.5rem'} container>
              <Grid item xs={2.9}>
                <TextFieldOld
                  editMode={editMode}
                  error={errors.tierName?.message}
                  label="TIER NAME"
                  register={register('tierName')}
                  value={tier?.tierName}
                />
              </Grid>
              <Grid item xs={2.9}>
                <TextField
                  disabled={!editMode}
                  error={errors.targetBrands?.message}
                  formHooks={{
                    register: register('targetBrands'),
                    setValue: (value) => setValue('targetBrands', parseFloat(value)),
                  }}
                  formatters={{
                    preview: numberFormatter,
                    typing: createFormatterWithReverse(flow(extractNumber, numberFormatter), extractNumber),
                  }}
                  label="TARGET BRANDS"
                />
              </Grid>
            </Grid>
          </form>
        </Box>
        <Box className={classes.tacticsTableContainer}>
          <NewCampaignTierTacticsTableChannelPartnerInstances tactics={tier?.tactics} />
        </Box>
      </Box>
    </Box>
  );
};
export default CampaignTierChannelPartnerInstances;
