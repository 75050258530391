export interface ITacticMetadataForm {
  inputType: InputTypes;
  inputName: string;
  label: string;
  helperText?: string;
  defaultValue?: string;
  placeholder?: string;
  listValues?: IListValues[];
  validations?: IValidations;
  isDisabled?: boolean;
  required?: boolean;
}

export interface ITacticMetadataDTO extends ITacticMetadataForm {
  id: string;
}

export interface IMetadataDTO {
  metadata: {
    metadataAttribute: ITacticMetadataDTO;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadataValue: any;
  }[];
}

interface IRegexValidation {
  validation: string;
  errorMessage?: string;
}

interface INumericValidation {
  minValue?: number;
  maxValue?: number;
  errorMessage?: string;
}

interface ILengthValidation {
  minLength?: number;
  maxLength?: number;
  errorMessage?: string;
}

interface IFileValidation {
  format?: string;
  size?: number;
  errorMessage?: string;
}

interface IValidations {
  regexValidation?: IRegexValidation;
  numericValidation?: INumericValidation;
  lengthValidation?: ILengthValidation;
  fileValidation?: IFileValidation;
}

interface IListValues {
  value: string;
  displayValue: string;
}

export interface TacticMetadataForm {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export enum InputTypes {
  TEXT = 'Text',
  AREA = 'Area',
  NUMBER = 'Number',
  DROPDOWN = 'Dropdown',
  RADIO = 'Radio',
  CHECKBOX_LIST = 'Checkbox_List',
  FILE = 'File',
  SINGLE_OPTION_CHECKBOX = 'Single_Option_Checkbox',
  PRODUCT_SELECTOR = 'Product_Selector',
}
