import { Box } from '@mui/material';
import { useState } from 'react';

import Card from '#/components/common/card/card';
import Loader from '#/components/common/loader/loader';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import getTableColumns from '#/components/features/partners/agencies/AgencyUsers/AgencyUsers.table';
import { CompanyType } from '#/constants/companyType/CompanyType';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import useGetResource from '#/hooks/useGetResource';

import { useGetCompanyAccountQuery, useGetCompanyUsersQuery } from '#/store/api/company/company';

import PermissionWrapper from '#/utils/PermissionWrapper';

import ContactsIcon from '../../../../../assets/ContactsIcon.svg';
import PlusIcon from '../../../../../assets/Plus.svg';
import AddUserWizard from '../../../../addUserWizard/AddUserWizard';
import NewButton from '../../../../common/button/NewButton';
import { Table } from '../../../../common/table/tableComponent/Table';
import { useAgencyUsersStyle } from './AgencyUsers.style';

const AgencyUsers = () => {
  const { resourceId } = useGetResource();

  const [isAddUserWizardOpen, setIsAddUserWizardOpen] = useState(false);

  const classes = useAgencyUsersStyle();

  const { data: companyAccount } = useGetCompanyAccountQuery({ companyId: resourceId });
  const { data, isFetching, refetch } = useGetCompanyUsersQuery({
    companyId: resourceId,
    companyType: CompanyType.AGENCY,
  });

  const tableColumns = getTableColumns();

  return (
    <Card>
      <Box className={classes.agencyUserHeaderContainer}>
        <Box className={classes.agencyUserHeaderContainerLeft}>
          <img alt="contacts icon" src={ContactsIcon} />
          <TypographyWithFontFamily variant="H3_Base">Users</TypographyWithFontFamily>
        </Box>
        <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.PARTNERS_COMPANY_AGENCY_USERS_SECTION_WRITE]}>
          <NewButton
            icon={PlusIcon}
            iconPosition="left"
            onClick={() => setIsAddUserWizardOpen(true)}
            text="Add New User"
          />
        </PermissionWrapper>
      </Box>
      {isFetching ? (
        <Loader />
      ) : (
        <Table
          columns={tableColumns}
          containerClass={classes.tableBody}
          data={data || []}
          headCellClass={classes.tableHeader}
        />
      )}

      {isAddUserWizardOpen && (
        <AddUserWizard
          companyData={companyAccount}
          isAddUserWizardOpen={isAddUserWizardOpen}
          onClose={() => {
            setIsAddUserWizardOpen(false);
            refetch();
          }}
        />
      )}
    </Card>
  );
};

export default AgencyUsers;
