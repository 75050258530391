import { retailerAxios } from './axiosConfig';

export const getFile = async (downloadLink: string, fileId: string) => {
  return retailerAxios.get(downloadLink, {
    params: { id: fileId },
    responseType: 'blob',
  });
};

export const deleteFile = (deleteLink: string) => {
  return retailerAxios.delete(deleteLink);
};
export const downloadFile = (downloadLink: string, fileId: string) => {
  return retailerAxios.get(downloadLink, {
    params: { id: fileId },
    responseType: 'blob',
  });
};
