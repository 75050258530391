import { createStyles, makeStyles } from '#/utils/theme';

export const useActiveFiltersStyles = makeStyles((theme) =>
  createStyles({
    card: { padding: 0 },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    selectTextComboContainer: {
      display: 'flex',
      flexDirection: 'row',
      flex: '1',
    },
    select: {
      width: '25%',
      paddingLeft: theme.custom.REM_SPACES[0.25],
      '&& .MuiSelect-select': {
        width: '100%',
      },
      alignSelf: 'center',
    },
    input: {
      width: '75%',
      paddingLeft: theme.custom.REM_SPACES[1],
      paddingRight: theme.custom.REM_SPACES[1],
      alignSelf: 'center',
    },
    secondDropdown: {
      width: '75%',
      paddingLeft: theme.custom.REM_SPACES[1],
      paddingRight: theme.custom.REM_SPACES[1],
      alignSelf: 'center',
    },
    datePicker: {
      width: '75%',
      paddingLeft: theme.custom.REM_SPACES[1],
      paddingRight: theme.custom.REM_SPACES[1],
      display: 'flex',

      borderColor: 'white',
    },
    activeFiltersContainer: { display: 'flex', flexDirection: 'row-reverse', flexWrap: 'wrap' },
    clearButton: {
      marginLeft: theme.custom.REM_SPACES[0.5],
      marginTop: theme.custom.REM_SPACES[0.25],
    },
    chip: {
      background: theme.custom.PALETTES.UI.Secondary[200],
      marginLeft: theme.custom.REM_SPACES[0.25],
      alignSelf: 'center',
      marginTop: theme.custom.REM_SPACES[0.25],
    },
    resultContainer: { flexGrow: 1 },
    paginationContainer: {
      padding: `${theme.custom.REM_SPACES['0.5']} ${theme.custom.REM_SPACES['1.5']}`,
    },
  }),
);
