import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { useTraffickingSheetLinkStyle } from '#/components/campaigns/campaignAssets/TraffickingSheetLink.style';
import {
  traffickingLineDataDetailAltId,
  traffickingLineDetailAltId,
} from '#/components/campaigns/campaignAssets/constants';
import Loader from '#/components/common/loader/loader';

import useGetResource from '#/hooks/useGetResource';

import { useGetReportDetailsByAltIdQuery } from '#/store/api/reports/reports';

interface TraffickingSheetUrlProps {
  tacticId?: string;
}
const TraffickingSheetLink: FC<TraffickingSheetUrlProps> = ({ tacticId }) => {
  const { resourceId } = useGetResource();
  const classes = useTraffickingSheetLinkStyle();
  const { data, isLoading } = useGetReportDetailsByAltIdQuery({
    altId: tacticId ? traffickingLineDataDetailAltId : traffickingLineDetailAltId,
    searchQuery: '',
  });
  const searchParams = new URLSearchParams(`?order_id=${resourceId}${tacticId ? '^=&tactic_id=' + tacticId : ''}^=`);
  if (isLoading) {
    return <Loader />;
  }
  return (
    <Link className={classes.link} to={`/reports/${data?.reportId}?${searchParams}`}>
      Trafficking Sheet
    </Link>
  );
};

export default TraffickingSheetLink;
