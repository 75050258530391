import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import EditIcon from '#/assets/Edit.svg';

import SearchFilters from '#/components/common/SearchFilters/SearchFilters';
import useSearchFilters from '#/components/common/SearchFilters/useSearchFilters.hook';
import NewButton from '#/components/common/button/NewButton';
import Loader from '#/components/common/loader/loader';
import { useProductFeedStyles } from '#/components/features/campaigns/instances/channelsAndTactics/productsFeed/productFeed.styles';
import ProductFeedTable from '#/constants/tables/productFeed/productFeedTable';

import { ICampaignInstanceProductListData } from '#/interfaces/campaigns/campaigns';

import UserTypeWrapper from '#/utils/UserTypeWrapper';

import {
  useDeleteSelectCampaignInstanceTacticProductMutation,
  useGetCampaignInstanceTacticProductsAllQuery,
  useGetCampaignInstanceTacticProductsSelectedQuery,
  useGetProductFeedFiltersQuery,
  usePutSelectCampaignInstanceTacticProductMutation,
} from '../../../../../../store/api/products/products';

interface IProductsFeedPops {
  upnCodesViewInstanceId: string;
}

const ProductsFeed: FC<IProductsFeedPops> = ({ upnCodesViewInstanceId }) => {
  const [editMode, setEditMode] = useState(false);
  const useGetCampaignInstanceTacticProductsQuery = editMode
    ? useGetCampaignInstanceTacticProductsAllQuery
    : useGetCampaignInstanceTacticProductsSelectedQuery;

  const { data: filterData, isError: filterDataIsError } = useGetProductFeedFiltersQuery({});

  const { actions, state } = useSearchFilters(undefined, false, filterData?.filters);

  const { data, isLoading, isError, isSuccess } = useGetCampaignInstanceTacticProductsQuery({
    instanceTacticId: upnCodesViewInstanceId,
    pageNumber: state.pageNumber,
    numberOfRows: state.numberOfElementsPerPage,
    queryInput: state.searchQuery,
  });
  const classes = useProductFeedStyles();

  const [putSelectCampaignInstanceTacticProduct, { isLoading: isPutSelectCampaignInstanceTacticProductLoading }] =
    usePutSelectCampaignInstanceTacticProductMutation();
  const [deleteSelectCampaignInstanceTacticProduct, { isLoading: isDeleteSelectCampaignInstanceTacticProductLoading }] =
    useDeleteSelectCampaignInstanceTacticProductMutation();
  const isSaveButtonLoading =
    isPutSelectCampaignInstanceTacticProductLoading || isDeleteSelectCampaignInstanceTacticProductLoading;
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);

  const getProductsData = async () => {
    setSelectedBrands(
      data?.products
        .map((item: ICampaignInstanceProductListData) => ({
          ...item.projection,
          selected: item.selected,
        }))
        .map((item) => item.selected && item.universalProductCode)
        .filter((item): item is string => typeof item === 'string') || [],
    );
  };
  useEffect(() => {
    getProductsData();
  }, [isSuccess]);

  const onSave = async () => {
    await putSelectCampaignInstanceTacticProduct({
      instanceTacticId: upnCodesViewInstanceId,
      selectedProducts: { productFeedIds: selectedBrands || [] },
    }).unwrap();
    const oldProducts =
      data?.products
        .map((item: ICampaignInstanceProductListData) => ({
          ...item.projection,
          selected: item.selected,
        }))
        .map((item) => item.selected && item.universalProductCode)
        .filter((item): item is string => typeof item === 'string') || [];
    const productsToDelete: string[] = oldProducts.filter((element) => !selectedBrands.includes(element));
    await deleteSelectCampaignInstanceTacticProduct({
      instanceTacticId: upnCodesViewInstanceId,
      selectedProducts: { productFeedIds: productsToDelete },
    }).unwrap();
    setEditMode(false);
    actions.setPage(1);
  };
  const formatData = data?.products.map((item: ICampaignInstanceProductListData) => ({
    ...item.projection,
    selected: item.selected,
  }));
  const getEditButtons = () => (
    <Box className={classes.editButtons}>
      {editMode ? (
        <Box className={classes.editCancelSaveButtons}>
          <NewButton
            disabled={isSaveButtonLoading}
            onClick={() => {
              setEditMode(false);
              actions.setPage(1);
            }}
            text={'Cancel'}
            variant={'Secondary'}
          />
          <NewButton loading={isSaveButtonLoading} onClick={onSave} text={'Save'} />
        </Box>
      ) : (
        <NewButton
          disabled={isLoading}
          icon={EditIcon}
          onClick={() => {
            setEditMode(true);
            actions.setPage(1);
          }}
          text={'Edit Products'}
          variant={'Ghost'}
        />
      )}
    </Box>
  );
  if (isLoading) return <Loader />;
  return (
    <Box>
      <UserTypeWrapper agency={getEditButtons()} brand={getEditButtons()} retailer={getEditButtons()} />
      <SearchFilters
        columnNames={filterData?.filters}
        currentIndex={data?.pageNumber}
        filterDataIsError={filterDataIsError}
        isError={isError}
        isFetching={isLoading}
        isNoResults={!data || data.products.length === 0}
        totalPages={data?.totalPages}
        totalResults={data?.totalPages}
        {...state}
        {...actions}
        hideViewMode
      >
        <ProductFeedTable
          actions={actions}
          data={formatData || []}
          editable={editMode}
          selectedBrands={selectedBrands}
          setSelectedBrands={setSelectedBrands}
          sortableColumns={filterData?.filters.map((column) => column.columnName) || []}
          state={state}
          upnCodesViewInstanceId={upnCodesViewInstanceId}
        />
      </SearchFilters>
    </Box>
  );
};

export default ProductsFeed;
