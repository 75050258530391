import { Box } from '@mui/material';

import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { useGeneralInformationRowStyles } from '#/components/features/campaigns/instances/list/CampaignInstancesRetailer/components/GeneralInformationRow/GeneralInformationRow.styles';
import { formatLabel } from '#/components/features/campaigns/instances/list/utils';

import { getFormattedValue } from '#/utils/textFormatters';

interface IProps {
  label?: string;
  value?: string;
}

export const GeneralInformationRow = ({ label, value }: IProps) => {
  const classes = useGeneralInformationRowStyles();

  return (
    <Box className={classes.row}>
      <TypographyWithFontFamily variant="Default_Bold">{formatLabel(label)}</TypographyWithFontFamily>
      <TypographyWithFontFamily variant="Default_Base">{getFormattedValue(value, label)}</TypographyWithFontFamily>
    </Box>
  );
};
