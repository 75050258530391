import dayjs from 'dayjs';
import { useState } from 'react';
import { UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';

import { THEME } from '#/constants/theme/constants';

import { PostCampaignForm } from '#/store/api/campaigns/campaignsInterfaces';

const useNewCampaignInterval = (
  setValue: UseFormSetValue<PostCampaignForm>,
  trigger: UseFormTrigger<PostCampaignForm>,
  watch: UseFormWatch<PostCampaignForm>,
) => {
  const isDefaultValues =
    dayjs(watch('startDate')).isSame(dayjs(), 'date') && dayjs(watch('endDate')).isSame(dayjs(), 'date');

  const [selectingDate, setSelectingDate] = useState<'startDate' | 'endDate' | 'submissionDeadline' | ''>('startDate');

  const iseSelectingSubmission = selectingDate === 'submissionDeadline';
  const isSelectingStart = selectingDate === 'startDate';
  const isSelectingEnd = selectingDate === 'endDate';
  const isNotSelecting = selectingDate === '';

  const setSubmissionDeadline = () => {
    setSelectingDate('submissionDeadline');
  };

  const setSelectionStart = () => {
    setSelectingDate('startDate');
  };

  const setSelectionEnd = () => {
    setSelectingDate('endDate');
  };

  const resetSelection = () => {
    setSelectingDate('');
  };

  const onDateSelect = (date: Date | string) => {
    if (iseSelectingSubmission) {
      onSubmissionDeadlineSelect(date);
    }
    if (isSelectingStart) {
      onStartChange(date);
    }
    if (selectingDate === 'endDate') {
      onEndChange(date);
    }
  };

  const onSubmissionDeadlineSelect = (date: Date | string): void => {
    setValue('submissionDeadline', dayjs(date).toString(), { shouldValidate: true });
    trigger(['submissionDeadline', 'startDate']);
    setSelectingDate('startDate');
  };

  const onStartChange = (date: Date | string): void => {
    setValue('startDate', dayjs(date).toString(), { shouldValidate: true });
    trigger(['endDate', 'startDate', 'submissionDeadline']);
    setSelectingDate('endDate');
  };

  const onEndChange = (date: Date | string): void => {
    setValue('endDate', dayjs(date).toString(), { shouldValidate: true });
    trigger(['endDate', 'startDate']);
    setSelectingDate('submissionDeadline');
  };

  const newCampaignIntervalEvent = [
    {
      start: new Date(watch('startDate')),
      end: dayjs(watch('endDate')).add(1, 'day').toDate(),
      title: '',
      allDay: true,
      borderColor: THEME.PALETTES.UI.Primary.Base,
    },
    {
      start: new Date(watch('submissionDeadline')),
      end: dayjs(watch('submissionDeadline')).add(1, 'day').toDate(),
      title: '',
      allDay: true,
      borderColor: THEME.PALETTES.UI.Secondary.Base,
    },
  ];

  return {
    isDefaultValues,
    newCampaignIntervalEvent,
    selectingDate,
    isSelectingStart,
    isSelectingEnd,
    isNotSelecting,
    onStartChange,
    onEndChange,
    resetSelection,
    setSelectionStart,
    setSelectionEnd,
    onDateSelect,
    setSubmissionDeadline,
    onSubmissionDeadlineSelect,
  };
};

export default useNewCampaignInterval;
