import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const useAddRetailerUserModalStyle = makeStyles(() =>
  createStyles({
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      columnGap: THEME.REM_SPACES[0.5],
    },
  }),
);

export const addRetailerUserStyleSx = {
  userInfoHeaderSx: {
    margin: `${THEME.REM_SPACES[1]} 0`,
  },
  contactCheckboxContainerSx: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'self-start',
  },
  gridSpaceBetweenSx: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  headerWithSpacingSx: {
    margin: `${THEME.REM_SPACES[1]} 0`,
  },
  gridFlexSx: {
    display: 'flex',
    alignItems: 'flex-end',
  },
};
