import { createStyles, makeStyles } from '#/utils/theme';

export const useTableLogoCellStyles = makeStyles((theme) =>
  createStyles({
    logoContainer: {
      minHeight: theme.custom.REM_SPACES[2],
      minWidth: theme.custom.REM_SPACES[2],
      height: theme.custom.REM_SPACES[2],
      width: theme.custom.REM_SPACES[2],
      marginRight: theme.custom.REM_SPACES[0.5],
    },
    url: {
      color: theme.custom.PALETTES.TEXT.Action_Secondary,
      fontWeight: theme.custom.FONT_WEIGHTS.SEMIBOLD,
      fontSize: '0.8125rem',
      textDecoration: ' none',
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);
