import { THEME } from '#/constants/theme/constants';

import { createStyles, makeStyles } from '#/utils/theme';

export const useModalStyles = makeStyles(() =>
  createStyles({
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    addBrandMargin: {
      marginTop: '0.625rem',
    },
    dialogTitle: {
      '&&': {
        background: THEME.PALETTES.UI.Primary['900'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxHeight: '4rem',
      },
    },
    mrImg: {
      marginRight: THEME.REM_SPACES['0.5'],
    },
    closeImg: {
      cursor: 'pointer',
    },
  }),
);

export const modalSize = (size: string) => {
  let modalSx: object = {};
  switch (size) {
    case 'small':
      modalSx = {
        width: { xs: '90%', sm: '30rem' },
      };
      break;
    case 'large':
      modalSx = {
        width: { xs: '90%', sm: '35rem', md: '50rem' },
      };
      break;
    case 'tall':
      modalSx = {
        width: { xs: '90%', sm: '35rem', md: '75rem' },
        height: '80%',
      };
      break;
    case 'wide':
      modalSx = {
        width: { xs: '90%', sm: '35rem', md: '60rem' },
      };
      break;
    case 'wizard':
      modalSx = {
        minWidth: '100%',
        minHeight: '100%',
      };
      break;
  }
  return {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        maxWidth: '100%',
        margin: 0,
        ...modalSx,
        background: 'transparent',
        borderRadius: '8px',
        overflowX: 'hidden',
        padding: '0',
      },
    },
  };
};

export const styles = {
  titlePaddingSx: {
    padding: { xs: '0.2rem 0.6rem', md: '0.8rem 1.2rem' },
    '&& h4': {
      fontSize: { xs: '0.85rem', md: '1.125rem' },
    },
  },
  backDropOpacity: {
    backdrop: { sx: { background: 'rgba(0, 0, 0, 0.8)' } },
  },

  contentBackground: {
    '&&': {
      background: THEME.PALETTES.TEXT.Light,
      paddingTop: '0.625rem',
    },
  },
};

export const modalBottomButtons = {
  background: THEME.PALETTES.TEXT.Light,
  textAlign: 'center',
  paddingTop: '0.938rem',
};
