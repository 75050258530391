import Loader from '#/components/common/loader/loader';
import { BrandOrdersRetailer } from '#/components/features/campaigns/BrandOrders/BrandOrdersRetailer/BrandOrdersRetailer';

import { useGetBrandOrdersRetailerFilterQuery } from '#/store/api/campaigns/campaigns';

export const BrandOrdersRetailerContainer = () => {
  const { isLoading: filterDataIsLoading } = useGetBrandOrdersRetailerFilterQuery({});

  if (filterDataIsLoading) return <Loader />;

  return <BrandOrdersRetailer />;
};
