import { makeStyles, createStyles } from '#/utils/theme';
import theme from '#/utils/theme';

export const useImageModalStyle = makeStyles(() =>
  createStyles({
    imageContainer: {
      '& img': { width: '100%', height: '80vh', objectFit: 'contain' },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing[1],
    },
  }),
);
