import { retailerAxios, userAxios } from './axiosConfig';

export const postCompanyLogo = (file: File | null) => {
  //upload the avatar image and get back an id
  const formData = new FormData();
  formData.append('file', file as Blob);
  return retailerAxios.post(`/company-account/logo`, formData, {
    params: {
      userId: '1',
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const postAvatar = (file: File | null) => {
  //upload the avatar image and get back an id
  const formData = new FormData();
  formData.append('file', file as Blob);
  return userAxios.post(`/user/avatar`, formData, {
    params: {
      userId: '1',
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getTemporaryAvatar = (imageId: string) => {
  return retailerAxios.get(`/api/v1/file-upload/files/${imageId}`, { responseType: 'blob' });
};

export const getUserTemporaryAvatar = (imageId: number | string | undefined) => {
  return userAxios.get(`/file-upload/files/${imageId}`, { responseType: 'blob' });
};

export const saveLogo = (avatarId: string, userId: string) => {
  // POST/PUT the avatar id
  return userAxios.put(`/user/avatar`, {
    userId, //need to be provided
    avatarId,
  });
};
