import { createStyles, makeStyles } from '#/utils/theme';

export const useLoaderStyle = makeStyles(() =>
  createStyles({
    wrapLoader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      flexGrow: 1,
    },
  }),
);

export const fullPageLoaderSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'white',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
};
