import { Box, Icon } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import Refetch from '../reFetchComponent/Refetch';
import { TypographyVariantOptions } from '../typography/Typography.types';
import { TypographyWithFontFamily } from '../typography/TypographyWithFontFamily';
import { useDisplayPageNameStyles } from './DisplayPageName.styles';

interface IDisplayPageNameProps {
  pageName: string;
  imgSrc: string;
  customVariant?: TypographyVariantOptions;
  customStyles?: { [key: string]: string };
  refetch?: () => void;
  dataLoading?: boolean;
  redirectTo?: string;
}

const DisplayPageName: FC<IDisplayPageNameProps> = ({
  pageName,
  imgSrc,
  customVariant,
  customStyles,
  refetch,
  dataLoading,
  redirectTo,
}) => {
  const classes = useDisplayPageNameStyles();

  const textElement = redirectTo ? (
    <Link className={classes.redirect} to={redirectTo}>
      {pageName}
    </Link>
  ) : (
    <TypographyWithFontFamily variant={customVariant || 'H3_Base'}>{pageName}</TypographyWithFontFamily>
  );

  return (
    <Box className={classes.wrap} style={customStyles}>
      <Box className={classes.textWrap}>
        <Icon className={classes.iconWrapper}>
          <img alt="page logo" src={imgSrc} />
        </Icon>
        {textElement}
      </Box>
      {refetch && <Refetch isLoading={!!dataLoading} reFetchFn={refetch} />}
    </Box>
  );
};

export default DisplayPageName;
