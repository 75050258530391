import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { FC } from 'react';

import RadioOff from '#/assets/RadioOffIcon.svg';
import RadioOn from '#/assets/RadioOnIcon.svg';

import { useCampaignInvitationsStyles } from '#/components/campaignInvitations/CampaignInvitations.styles';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';

import { useAppSelector } from '#/hooks';

interface ICommunicationsSelectMatchingCampaignProps {
  handleChange: (value: string | string[], updateFilterName: string) => void;
  filterType: string;
}

const CommunicationsSelectMatchingCampaign: FC<ICommunicationsSelectMatchingCampaignProps> = ({
  handleChange,
  filterType,
}) => {
  const classes = useCampaignInvitationsStyles();
  const { selectedCampaigns } = useAppSelector((state) => state.communications);
  return (
    <>
      <Box className={classes.mb1}>
        <FormControl>
          <RadioGroup onChange={(e) => handleChange(e.target.value, 'filterType')} value={filterType}>
            <FormControlLabel
              className={classes.fixWithNegativeMargin}
              control={
                <Radio
                  checkedIcon={<img alt="checkbox selected" src={RadioOn} />}
                  icon={<img alt="checkbox unselected" src={RadioOff} />}
                />
              }
              label={<TypographyWithFontFamily cName={classes.mb0}>All Brands</TypographyWithFontFamily>}
              value="ALL"
            />
            <FormControlLabel
              className={classes.fixWithNegativeMargin}
              control={
                <Radio
                  checkedIcon={<img alt="checkbox selected" src={RadioOn} />}
                  icon={<img alt="checkbox unselected" src={RadioOff} />}
                />
              }
              label={<TypographyWithFontFamily>Matching Campaign Categories/Attributes</TypographyWithFontFamily>}
              value="MATCHING_PRODUCT_MIX"
            />
            {selectedCampaigns.length <= 1 && (
              <FormControlLabel
                control={
                  <Radio
                    checkedIcon={<img alt="checkbox selected" src={RadioOn} />}
                    icon={<img alt="checkbox unselected" src={RadioOff} />}
                  />
                }
                label={<TypographyWithFontFamily>All Invited</TypographyWithFontFamily>}
                value="INVITED"
              />
            )}
          </RadioGroup>
        </FormControl>
      </Box>
    </>
  );
};

export default CommunicationsSelectMatchingCampaign;
