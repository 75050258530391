import DefaultFileIcon from '../assets/DefaultFileIcon.png';
import PDFIcon from '../assets/PDFIcon.png';
import CSVIcon from '../assets/csvIcon.png';

export const getFileIcon = (fileExtension: string | undefined) => {
  switch (fileExtension?.toLowerCase()) {
    case 'csv':
      return CSVIcon;
    case 'pdf':
      return PDFIcon;
    default:
      return DefaultFileIcon;
  }
};
