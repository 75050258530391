import Loader from '#/components/common/loader/loader';
import { BrandOrdersChannelPartner } from '#/components/features/campaigns/BrandOrders/BrandOrdersChannelPartner/BrandOrdersChannelPartner';

import { useGetBrandOrdersChannelPartnerFilterQuery } from '#/store/api/campaigns/campaigns';

export const BrandOrdersChannelPartnerContainer = () => {
  const { isLoading: filterDataIsLoading } = useGetBrandOrdersChannelPartnerFilterQuery({});

  if (filterDataIsLoading) return <Loader />;

  return <BrandOrdersChannelPartner />;
};
