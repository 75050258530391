import { Box } from '@mui/material';

import { IDynamicSingleTextProps } from '../../../../interfaces/dynamicRibbon/dynamicRibbon';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { useDynamicRibbonStyles } from '../DynamicRibbon.styles';

const DynamicSingleText = ({ component }: IDynamicSingleTextProps) => {
  const classes = useDynamicRibbonStyles();
  return (
    <Box className={classes.singleText}>
      <TypographyWithFontFamily cName={classes.singleTextContent}>{component.content}</TypographyWithFontFamily>
    </Box>
  );
};

export default DynamicSingleText;
