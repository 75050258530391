import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const useUserProfileStyles = makeStyles(() =>
  createStyles({
    avatarIcon: {
      width: '2rem',
      height: '2rem',
      border: `1px solid ${THEME.PALETTES.UI.Primary.Base}`,
      borderRadius: '0.7rem',
      cursor: 'pointer',
      background: THEME.PALETTES.TEXT.Light,
      padding: '0.2rem',
    },
    buttonsWrapper: {
      margin: '1rem 0',
    },
    displayNone: {
      display: 'none',
    },
    editButton: {
      border: 'none',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      outline: 'none',
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'flexStart',
      flexDirection: 'column',
      width: '100%',
    },
    icon: { width: '30px', height: '30px' },
    inputsContainer: {
      flexDirection: 'row',
      marginBottom: '0.5rem',
      alignItems: 'center',
      marginTop: '0.2rem',
    },
    saveButton: {
      border: '1px solid #C9CFD7',
      padding: '0.5rem 1rem',
      backgroundColor: 'white',
      color: 'black',
      borderRadius: '0.5rem',
      cursor: 'pointer',

      '&:active': {
        backgroundColor: '#E4E8EF',
      },
    },
    userInput: {
      fontSize: '1rem',
      marginRight: '1rem',
      border: '1px solid #E4E8EF',
      borderRadius: '0.3rem',
      padding: '0.6rem',
      width: '25%',
      minWidth: 150,
      outline: 'none',
    },
    userName: {
      color: '#16191F',
    },
    userNameField: {
      color: '#606670',
      fontSize: '1rem',
      margin: '1rem 0 0 0',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      alignItems: 'flex-start',
      padding: '0.8rem',
      borderRadius: '1rem',
    },
    badgeMargin: {
      marginTop: '0.938rem',
    },
  }),
);

export const avatarSx = { width: '5.5rem', height: '5.5rem' };
export const avatarSxSmall = { width: '2.5rem', height: '2.5rem' };
