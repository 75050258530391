import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const useChannelAndTacticsSingleChannelPartnerStyle = makeStyles(() =>
  createStyles({
    channelsAndTacticsContainer: {
      background: THEME.PALETTES.OTHER.White,
      minHeight: 'auto',
      padding: THEME.REM_SPACES['1'],
      borderRadius: THEME.REM_SPACES['1'],
      display: 'flex',
      columnGap: THEME.REM_SPACES['1'],
      marginBottom: THEME.REM_SPACES['1'],
    },
    channelsAndSubChannelsContainer: {
      width: '17.8rem',
    },
    collapsible: {
      '&.MuiListItemButton-root': {
        flexDirection: 'row-reverse',
        columnGap: THEME.REM_SPACES['0.25'],
        paddingLeft: '0',
        borderRadius: THEME.REM_SPACES['0.5'],
      },
    },
    list: {
      width: '100%',
      maxWidth: '17.8rem',
    },
    channelTacticsContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      rowGap: THEME.REM_SPACES['1'],
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      columnGap: THEME.REM_SPACES['0.5'],
    },
    selectedSubChannel: {
      background: THEME.PALETTES.UI.Secondary['200'],
    },
    subChannel: {
      borderRadius: THEME.REM_SPACES['0.5'],
    },
    tacticsTableContainer: {
      width: '100%',
      borderRadius: THEME.REM_SPACES['1'],
      marginBottom: THEME.REM_SPACES['1'],
      '& th': {
        display: 'none',
      },
    },
    tacticsTableHeaderCell: {
      border: 'none',
    },
    hide: {
      display: 'none',
    },
    custom: { border: 0 },
    tacticsTableBodyCell: {
      height: THEME.REM_SPACES['2.5'],
    },
    tacticsTableBody: {
      '& td:last-child div': {
        justifyContent: 'flex-end',
      },
      '& td': {
        borderLeft: 'none',
        borderRight: 'none',
      },
      '& tr:first-child td': {
        borderTop: 'none',
      },
      '& tr:last-child td': {
        borderBottom: 'none',
      },
    },
    subChannelContainer: { display: 'flex', justifyContent: 'space-between' },
    mb2: {
      '&&': {
        marginBottom: THEME.REM_SPACES['1.5'],
      },
    },
  }),
);

export const headerSx = {
  fontWeight: THEME.FONT_WEIGHTS.BOLD,
  color: THEME.PALETTES.UI.Neutral['900'],
  marginBottom: THEME.REM_SPACES['0.5'],
};
export const listItemButtonSx = { paddingLeft: THEME.REM_SPACES['2.5'], minWidth: '17rem' };
export const numberOfTacticsSx = { color: THEME.PALETTES.UI.Neutral['900'], fontWeight: THEME.FONT_WEIGHTS.BOLD };
