import { Middleware } from 'redux';

import { messagesSlice } from '#/store/slices';

import log from '#/utils/log';
import { stomp } from '#/utils/websocket';

const FORCE_DISCONNECT_TIMEOUT = 30 * 1000; // 30 seconds

const webSocketMiddleware: Middleware = (store) => {
  return (next) => (action) => {
    const client = stomp.get();

    if (messagesSlice.actions.connectWs.match(action)) {
      if (client) {
        return;
      }

      // Initialize WebSocket connection
      stomp.initialize(action.payload, {
        onConnect: (frame) => {
          log.debug('Connected: ' + frame);
          store.dispatch(messagesSlice.actions.setConnectionState(true));
        },
        onStompError: (frame) => {
          log.error('Broker reported error: ' + frame.headers['message']);
          log.error('Additional details: ' + frame.body);
          store.dispatch(messagesSlice.actions.setConnectionState(false));
        },
      });
    }

    if (messagesSlice.actions.subscribeToTopic.match(action)) {
      if (!client) {
        return;
      }

      log.debug('Subscribing to topic: ' + action.payload.topic);
      client.subscribe(action.payload.topic, action.payload.callback);
    }

    if (messagesSlice.actions.disconnectWs.match(action)) {
      if (!client) {
        return;
      }

      // They recommend to force disconnect after a wait time
      const forceTimeout = window.setTimeout(() => {
        client.deactivate({ force: true }).catch((error) => {
          log.error('Failed to force disconnect', error);
        });
      }, FORCE_DISCONNECT_TIMEOUT);

      client
        .deactivate()
        .then(() => {
          window.clearTimeout(forceTimeout);
        })
        .catch((error) => {
          log.error('Error while disconnecting', error);
        });
    }

    return next(action);
  };
};

export default webSocketMiddleware;
