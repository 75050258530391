import React from 'react';

import useGetResource from '../../../hooks/useGetResource';
import { useGetCampaignHistoryQuery } from '../../../store/api/campaigns/campaignHistory';
import CampaignTimeline from './CampaignTimeline';

const CampaignHistory = () => {
  const resource = useGetResource();
  const { data } = useGetCampaignHistoryQuery({ resource });
  return <>{data && <CampaignTimeline campaignHistory={data} />}</>;
};

export default CampaignHistory;
