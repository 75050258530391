import { createStyles, makeStyles } from '#/utils/theme';

export const useAssociateCompanyToCompanyForUserStyles = makeStyles((theme) =>
  createStyles({
    marginTop1: {
      marginTop: theme.custom.REM_SPACES['1'],
    },
    tableHeader: {
      background: theme.custom.PALETTES.UI.Neutral[100],
      height: theme.custom.REM_SPACES['2.5'],
      color: theme.custom.PALETTES.TEXT.Dark,
      fontSize: '0.71875rem',
      fontWeight: theme.custom.FONT_WEIGHTS.SEMIBOLD,
    },
    tableCell: {
      height: theme.custom.REM_SPACES['2.5'],
      color: theme.custom.PALETTES.TEXT.Dark,
      fontWeight: theme.custom.FONT_WEIGHTS.REGULAR,
      fontSize: '0.8125rem',
    },
    tableContainer: {
      margin: `${theme.custom.REM_SPACES[1]} 0`,
      '& th:first-child': {
        borderRight: 'none',
        width: '4rem',
      },
      '& th:nth-child(2)': {
        borderLeft: 'none',
        width: '7rem',
      },
      '& tr td:first-child': {
        borderRight: 'none',
      },
      '& tr td:nth-child(2)': {
        borderLeft: 'none',
      },
    },
    container: {
      marginBottom: theme.custom.REM_SPACES[4],
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    lastButton: {
      marginLeft: theme.custom.REM_SPACES[1],
    },
  }),
);
