import axios from 'axios';

import { GATEWAY_API_URL } from '#/constants/general';

const retailerAxios = axios.create({
  baseURL: `${GATEWAY_API_URL}/retailer`,
});

const userAxios = axios.create({
  baseURL: `${GATEWAY_API_URL}/user`,
});

const rootAxios = axios.create({
  baseURL: `${GATEWAY_API_URL}`,
});

const reportAxios = axios.create({
  baseURL: `${GATEWAY_API_URL}/report`,
});

export { retailerAxios, userAxios, rootAxios, reportAxios };

export const setOktaTokenToAxios = async (token: string) => {
  retailerAxios.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      } else {
        delete config.headers['Authorization'];
      }
      return config;
    },
    (error) => {
      return error;
    },
  );

  userAxios.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      } else {
        delete config.headers['Authorization'];
      }
      return config;
    },
    (error) => {
      return error;
    },
  );

  rootAxios.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      } else {
        delete config.headers['Authorization'];
      }
      return config;
    },
    (error) => {
      return error;
    },
  );

  reportAxios.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      } else {
        delete config.headers['Authorization'];
      }
      return config;
    },
    (error) => {
      return error;
    },
  );
};
