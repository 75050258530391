import { Grid, GridSize } from '@mui/material';
import { Box } from '@mui/system';
import { AxiosError } from 'axios';
import React, { FC, ReactElement, useMemo, useRef, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { downloadFile } from '#/api/downloadUpload';
import { getMultipleFilesMetadata, uploadFileCustomLink } from '#/api/files';

import { THEME } from '#/constants/theme/constants';

import { useDataCy } from '#/hooks/useDataCy';
import { useRequest } from '#/hooks/useRequest';

import { getFileIcon } from '#/utils/fileIcon';

import DeleteIcon from '../../../assets/DeleteIconBlue.svg';
import DownloadIcon from '../../../assets/DownloadIconBlue.svg';
import FileIcon from '../../../assets/FileIcon.svg';
import Loader from '../loader/loader';
import { useSnackbarError } from '../snackbar/useSnackbarError';
import { TypographyWithFontFamily } from '../typography/TypographyWithFontFamily';
import { useMultipleDownloadUploadStyles } from './MultipleDownloadUpload.style';

interface IMultipleDownloadUploadEditModeProps {
  editMode: boolean;
  // FIXME: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
  registerName: string;
  uploadLink: string;
}
interface IMultipleDownloadUploadReadOnlyModeProps {
  editMode?: false;
}
type MultipleDownloadUploadProps = {
  filesIds: string[];
  extensions?: string[];
  customUploadComponent?: ReactElement;
  filesGridSize?: GridSize;
  hideFiles?: boolean;
  uploadCallBack?: () => void;
} & (IMultipleDownloadUploadReadOnlyModeProps | IMultipleDownloadUploadEditModeProps);

const MultipleDownloadUpload: FC<MultipleDownloadUploadProps> = (props) => {
  const classes = useMultipleDownloadUploadStyles();
  const generateDataCy = useDataCy();

  const { filesIds, editMode, customUploadComponent, hideFiles } = props;
  const getFileMetadataAction = useMemo(
    () => (filesIds?.length > 0 ? getMultipleFilesMetadata(filesIds) : null),
    [filesIds?.length],
  );
  const { response } = useRequest(getFileMetadataAction);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const { setError } = useSnackbarError();

  const updateFile = async (currentFiles: FileList) => {
    if (editMode) {
      const isCorrectFileExtension =
        props?.extensions &&
        !props?.extensions?.includes('.' + currentFiles[currentFiles.length - 1]?.name?.split('.')?.[1] || '');
      if (isCorrectFileExtension) {
        setUploadError('File extension not allowed');
        return;
      }
      setUploadError('');
      setIsUploading(true);
      const { setValue } = props;
      const formData = new FormData();
      for (let i = 0; i < currentFiles.length; i++) {
        formData.append('file', currentFiles?.[i]);
      }
      try {
        const response = await uploadFileCustomLink(props.uploadLink, formData);
        if (Array.isArray(response?.data)) setValue(props.registerName, [...filesIds, ...response.data]);
        props?.uploadCallBack?.();
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          setError(error.response?.data);
        }
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentFiles = e.target.files;
    if (!currentFiles) return;
    updateFile(currentFiles);
  };

  const handleBrowseFile = () => {
    inputRef.current?.click();
  };

  const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const dopHandler = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const currentFiles = e.dataTransfer.files;
    updateFile(currentFiles);
  };

  const onDownloadFile = async (fileId: string) => {
    const response = await downloadFile(fileId);
    const match = response?.headers['content-disposition'].match(/filename="([^"]+)"/);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(response?.data);
    link.setAttribute('download', match?.[1] || 'test.pdf');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const onDeleteFile = (fileId: string | undefined) => {
    if (editMode) {
      const { setValue, registerName } = props;
      setValue(
        registerName,
        filesIds.filter((file) => file !== fileId),
      );
    }
  };
  if (!editMode && !filesIds.length) {
    return <TypographyWithFontFamily variant={'Small_Bold'}>No Files Added</TypographyWithFontFamily>;
  }
  return (
    <>
      {editMode && (
        <>
          {isUploading ? (
            <Loader />
          ) : (
            <Box>
              <input
                ref={inputRef}
                accept={props?.extensions?.join(', ') || '*'}
                data-cy={generateDataCy('input', `DownloadUpload${props.registerName}`)}
                hidden={true}
                multiple
                onChange={handleFileChange}
                type="file"
              />
              <Box>
                {customUploadComponent ? (
                  <Box onClick={handleBrowseFile} onDragOver={dragOverHandler} onDrop={dopHandler}>
                    {customUploadComponent}
                  </Box>
                ) : (
                  <Box
                    className={classes.downloadUploadContainer}
                    onClick={handleBrowseFile}
                    onDragOver={dragOverHandler}
                    onDrop={dopHandler}
                  >
                    <Box>
                      <img src={FileIcon} />
                    </Box>
                    <TypographyWithFontFamily variant="Default_Base">
                      Drag and drop to upload files
                    </TypographyWithFontFamily>
                    <Box>
                      <TypographyWithFontFamily
                        cName={classes.browseFile}
                        color={THEME.PALETTES.ICONS.Action_Secondary}
                      >
                        Browse file
                      </TypographyWithFontFamily>
                    </Box>
                  </Box>
                )}
              </Box>

              <TypographyWithFontFamily>{uploadError}</TypographyWithFontFamily>
            </Box>
          )}
        </>
      )}
      <Grid className={classes.grid} container>
        {!hideFiles &&
          response?.map((file) => (
            <Grid key={file.fileId} className={classes.fileViewContainer} item xs={props?.filesGridSize || 12}>
              <Box className={classes.imageNameContainer}>
                <img className={classes.icon} src={getFileIcon(file?.fileName?.split('.')?.[1])} />
                <Box className={classes.fileTextContainer}>
                  {file?.fileName ? (
                    <>
                      <TypographyWithFontFamily>{file?.fileName}</TypographyWithFontFamily>
                      <TypographyWithFontFamily cName={classes.uploadDateText}>
                        Uploaded at {file?.timestamp}
                      </TypographyWithFontFamily>
                    </>
                  ) : (
                    <TypographyWithFontFamily>File not found</TypographyWithFontFamily>
                  )}
                </Box>
              </Box>
              <Box className={classes.buttonsContainer}>
                {file?.fileName && (
                  <img className={classes.fileButton} onClick={() => onDownloadFile(file?.fileId)} src={DownloadIcon} />
                )}
                {editMode && (
                  <img className={classes.fileButton} onClick={() => onDeleteFile(file?.fileId)} src={DeleteIcon} />
                )}
              </Box>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default MultipleDownloadUpload;
