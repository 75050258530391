import { Box } from '@mui/material';

import { IDynamicTextLabelProps } from '../../../../interfaces/dynamicRibbon/dynamicRibbon';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { styles } from './DynamicTextLabel.styles';

const DynamicTextLabel = ({ component }: IDynamicTextLabelProps) => {
  return (
    <Box>
      <TypographyWithFontFamily sx={styles.label}>{component.content}</TypographyWithFontFamily>
    </Box>
  );
};

export default DynamicTextLabel;
