import { createStyles, makeStyles } from '#/utils/theme';

export const usePromoteCampaignStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.custom.PALETTES.OTHER.White,
      padding: 10,
      borderRadius: 15,
    },
    buttonWrapSpace: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.custom.REM_SPACES['1'],
      alignItems: 'center',
    },
    link: {
      color: theme.custom.PALETTES.TEXT.Action_Secondary,
    },
  }),
);
