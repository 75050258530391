import { createStyles, makeStyles } from '#/utils/theme';

export const useTacticsHeaderStyles = makeStyles(() =>
  createStyles({
    tierHeader: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '90%',
    },
    tierName: {
      textTransform: 'capitalize',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  }),
);
