import { makeStyles, createStyles } from '#/utils/theme';

export const useFileViewStyles = makeStyles(() =>
  createStyles({
    fileViewContainer: {
      border: '1px solid #E4E8EF',
      width: '25rem',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '8px',
      marginTop: '16px',
      borderRadius: '8px',
      marginBottom: '8px',
    },
    imageNameContainer: {
      display: 'flex',
      columnGap: '8px',
    },
    fileTextContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    uploadDateText: {
      '&.MuiTypography-root': {
        fontWeight: 400,
        color: '#606670',
      },
    },
    buttonsContainer: {
      display: 'flex',
      columnGap: '6px',
    },
  }),
);
