import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { editCampaignInstanceInternal } from '../../../../api/campaign/campaigns';
import { useSnackbarError } from '../../../../components/common/snackbar/useSnackbarError';
import { ICampaignInstanceInternalForm } from '../../../../interfaces/campaigns/campaigns';

export const useEditCampaignInstanceInternal = (refresh: () => void) => {
  const schema = yup
    .object()
    .shape({
      description: yup.string().nullable(),
      goal: yup.string().required('Please enter a valid campaign goal'),
    })
    .required();

  const { instanceId } = useParams();

  const {
    register,
    clearErrors,
    control,
    setValue,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ICampaignInstanceInternalForm>({ resolver: yupResolver(schema) });
  const { setError } = useSnackbarError();

  const [errorMessage, setErrorMessage] = useState('');
  const [successfulCreated, setSuccessfulCreated] = useState(false);

  const onSubmit = handleSubmit(async (formData: ICampaignInstanceInternalForm) => {
    try {
      await editCampaignInstanceInternal(instanceId, formData);
      setSuccessfulCreated(true);
      setErrorMessage('');
      refresh();
    } catch (error: unknown) {
      setError(error);
      if (error instanceof AxiosError) {
        setErrorMessage(error.response?.data?.message);
      }
      setSuccessfulCreated(false);
    }
    clearErrors();
  });

  return {
    successfulCreated,
    register,
    onSubmit,
    errors,
    errorMessage,
    reset,
    handleSubmit,
    control,
    setValue,
    watch,
  };
};
