import { Box, TableCell as MUTCell, TableRow } from '@mui/material';
import { FC, useState } from 'react';

import { currencyFormatter, numberFormatter } from '#/utils/textFormatters';

import { IChannelAndTacticsResponseTactic } from '../../../../../../interfaces/forms/campaigns';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '../../../../../../interfaces/table/table';
import { convertAllCapsUnderscoreStrings } from '../../../../../../utils/utils';
import AddDetailsToSpecificTierBrand from '../../../../../campaigns/AddDetailsToSpecificTierBrand';
import { useCampaignTierTacticsTableStyle } from '../../../../../campaigns/CampaignTierTacticsTableStyle';
import { Table } from '../../../../../common/table/tableComponent/Table';
import { useChannelsAndTacticsStyles } from '../ChannelsAndTactics.style';

interface INewCampaignTierTacticsTableProps {
  tactics?: IChannelAndTacticsResponseTactic[];
}
const NewCampaignTierTacticsTableBrand: FC<INewCampaignTierTacticsTableProps> = ({ tactics = [] }) => {
  const classes = useCampaignTierTacticsTableStyle();
  const [selectedTactic, setSelectedTactic] = useState<IChannelAndTacticsResponseTactic>();

  const tableColumns: ITableColumnsProps<IChannelAndTacticsResponseTactic>[] = [
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Status',
      textFormatter: convertAllCapsUnderscoreStrings,
    },
    {
      fieldName: 'channel',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Channel',
    },
    {
      fieldName: 'subChannel',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Sub-channel',
    },
    {
      fieldName: 'tactic',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.ACTION,
      customHead: 'Tactic',
      referenceAction: (campaignTacticId: string) => {
        setSelectedTactic(tactics?.find((tactic) => tactic.campaignTacticId === campaignTacticId));
      },
      actionButtonTextFromMapping: 'tactic',
      itemClass: classes.areaButton,
      actionElementIdentifier: 'campaignTacticId',
    },
    {
      fieldName: 'channelPartnerName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Channel Partner',
    },
    {
      fieldName: 'placement',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Placement',
    },
    {
      fieldName: 'targeting',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Targeting',
    },
    {
      fieldName: 'rateType',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Rate Type',
    },
    {
      fieldName: 'rateValue',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Rate',
    },
    {
      fieldName: 'units',
      align: TableColumnAlign.LEFT,
      customHead: 'Units',
      textFormatter: numberFormatter,
      type: TableCellTypes.STRING,
    },
    {
      fieldName: 'budget',
      align: TableColumnAlign.LEFT,
      customHead: 'Budget',
      type: TableCellTypes.STRING,
      textFormatter: currencyFormatter,
      itemClass: 'text-right',
    },
    {
      fieldName: 'startDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Start Date',
    },
    {
      fieldName: 'endDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'End Date',
    },
  ];
  const extraRowClasses = useChannelsAndTacticsStyles();
  const extraRow = () => {
    const totalBudget = (tactics ?? []).reduce((acc, tactic) => acc + (parseFloat(tactic.budget) || 0), 0);
    return (
      <TableRow className={extraRowClasses.extraRow}>
        <MUTCell className={extraRowClasses.extraRowCell} colSpan={10}>
          Total Budget
        </MUTCell>
        <MUTCell align={'right'} className={extraRowClasses.extraRowCellShortBrand} colSpan={1}>
          {!isNaN(totalBudget) ? `$ ${totalBudget}` : ''}
        </MUTCell>
      </TableRow>
    );
  };
  return (
    <Box>
      {!!selectedTactic && (
        <AddDetailsToSpecificTierBrand selectedTactic={selectedTactic} setSelectedTactic={setSelectedTactic} />
      )}
      <Table columns={tableColumns} data={tactics} tableExtraRow={extraRow()} />
    </Box>
  );
};

export default NewCampaignTierTacticsTableBrand;
