import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { pipe } from 'fp-ts/lib/function';

import { GATEWAY_API_URL } from '#/constants/general';

import { RootState } from '#/store/reducers';
import { user } from '#/store/slices';

import log from '#/utils/log';

import { TAG_TYPES } from './tags/tags';

const baseQuery = fetchBaseQuery({
  baseUrl: GATEWAY_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const authToken = (getState() as RootState).user.authToken;
    if (authToken) {
      const authorization = `Bearer ${authToken}`;
      headers.set('authorization', authorization);
    }
    return headers;
  },
});

type withValidation = (query: typeof baseQuery) => BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;
const withValidation: withValidation = (query) => async (args, api, extraOptions) => {
  const result = await query(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    log.debug(`Unauthorized request`, args);
    api.dispatch(user.actions.logout());
  }
  return result;
};

export const baseApi = createApi({
  tagTypes: TAG_TYPES,
  baseQuery: pipe(baseQuery, withValidation),
  endpoints: () => ({}),
});
