import { useParams } from 'react-router';

import AlertError from '#/components/common/Alert/AlertError/AlertError';
import Loader from '#/components/common/loader/loader';
import PartnerUsersGeneralInfo from '#/components/features/partners/users/PartnerUsersGeneralInfo';

import { usePutCompanyUserContactTypeMutation } from '#/store/api/company/company';

import { UsersType } from '../../../../constants/usersRoles/usersRole';
import { useGetUserQuery, usePutUserMutation } from '../../../../store/api/users/users';
import AssociatedBrands from './AssociatedBrands';
import PartnerUserCompanyInfo from './PartnerUserCompanyInfo';

const PartnerUsersSingle = () => {
  const { userId } = useParams();

  const { data: userData, isLoading } = useGetUserQuery({ userId });
  const [_1, { error: editUserError }] = usePutUserMutation({ fixedCacheKey: 'partners/users/edit' });
  const [_2, { error: companyUserContactTypeError }] = usePutCompanyUserContactTypeMutation({
    fixedCacheKey: 'user-data-put',
  });

  if (isLoading) return <Loader />;
  if (!userData) return null;

  return (
    <>
      <AlertError error={editUserError || companyUserContactTypeError} />

      <PartnerUsersGeneralInfo />

      {userData?.companyId && <PartnerUserCompanyInfo />}

      {(userData?.userType === UsersType.AGENCY || userData?.userType === UsersType.BRAND) && <AssociatedBrands />}
    </>
  );
};

export default PartnerUsersSingle;
