import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { MainMenu } from '../sideMenu/MainMenu';
import { usePageLayoutStyles } from './PageLayout.style';

const PageLayout: FC = () => {
  const classes = usePageLayoutStyles();

  return (
    <div className={classes.pageContainer}>
      <div className={classes.menuContainer}>
        <MainMenu />
      </div>
      <div className={classes.contentContainer}>
        <Outlet />
      </div>
    </div>
  );
};

export default PageLayout;
