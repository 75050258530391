import { TableRow } from '@mui/material';
import classnames from 'classnames';
import { isEqual } from 'lodash';
import { memo } from 'react';
import { TableVirtuoso } from 'react-virtuoso';

import { TableCell } from '#/components/common/table/tableComponent/body/TableCell';
import { TableSortHeadCell } from '#/components/common/table/tableComponent/head/TableSortHeadCell';

import useWindowDimensions from '#/hooks/useWindowDimensions';

import { ISortStateProps, ITableColumnsProps } from '../../../../interfaces/table/table';
import { useTableStyles } from './Table.style';

export const VirtualizedTable = <T,>({
  columns,
  data,
  tableClass,
  headCellClass,
  bodyCellClass,
  containerClass,
  sortProps,
  tableBodyPadding,
  tableHeadPadding,
  headCustomStyle,
}: {
  tableClass?: string;
  columns: ITableColumnsProps<T>[];
  data: T[];
  headCellClass?: string;
  bodyCellClass?: string;
  containerClass?: string;
  sortProps: ISortStateProps;
  tableBodyPadding?: string;
  tableHeadPadding?: string;
  headCustomStyle?: { [key: string]: string };
}): JSX.Element => {
  const { height } = useWindowDimensions(500);
  const classes = useTableStyles();
  const keyProperty = columns.find((column) => column.isKey === true)?.fieldName;

  return (
    <TableVirtuoso
      className={classnames(containerClass, tableClass, classes.virtualizedTableContainer)}
      data={data}
      fixedHeaderContent={() => (
        <TableRow sx={{ background: 'white' }}>
          {columns.map((col) => (
            <TableSortHeadCell
              key={String(col.fieldName)}
              column={col}
              headCellClass={headCellClass}
              headCustomStyle={headCustomStyle}
              sortLookUpFieldName={col.sortLookUpFieldName}
              sortProps={sortProps}
              tablePadding={tableHeadPadding}
            />
          ))}
        </TableRow>
      )}
      itemContent={(rowIndex, dataEl: T) =>
        Object.values(columns).map((column, columnIndex) => (
          <TableCell
            key={`col-${String(dataEl[keyProperty as keyof T])}-${rowIndex}-${columnIndex}`}
            bodyCellClass={bodyCellClass}
            column={column}
            data={dataEl}
            tablePadding={tableBodyPadding}
          />
        ))
      }
      style={{ height: height - 200 }}
      totalCount={data.length}
    />
  );
};

function arePropsEqual(oldProps, newProps) {
  return isEqual(oldProps.data, newProps.data);
}

const MemoizedReportsDetailsVirtualizedTable = memo(VirtualizedTable<Record<string, unknown>>, arePropsEqual);

export default MemoizedReportsDetailsVirtualizedTable;
