import { useEffect } from 'react';

import { BASE_PATH, HOST_URL } from '#/constants/general';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import { useAppSelector } from '#/hooks/index';

import { usePostCampaignCloneMutation } from '#/store/api/campaign/campaign';

import { IUserPermissions, useUserPermissionType } from './useUserPermissionType';

const permissionsObj: IUserPermissions = {
  retailerPermission: USER_PERMISSIONS.CAMPAIGN_CREATE,
};

const useCloneCampaign = (campaignId: string | undefined) => {
  const [postCampaignClone, { isLoading, error, data, isSuccess }] = usePostCampaignCloneMutation();
  const { retailerPermission: isVisible } = useUserPermissionType(permissionsObj);
  const { user } = useAppSelector((state) => state.user);

  const cloneCampaignHandler = () => {
    if (!user) return;

    postCampaignClone({ campaignId, ownerId: user.userId });
  };

  useEffect(() => {
    if (isSuccess) {
      const newCampaignOverviewLink = `${HOST_URL}${BASE_PATH}/campaigns/overview/${data}`;
      window.location.replace(newCampaignOverviewLink);
    }
  }, [isSuccess]);

  return {
    loading: isLoading,
    isVisible,
    cloneCampaignHandler,
    error,
  };
};

export default useCloneCampaign;
