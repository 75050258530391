import { FC } from 'react';
import { useNavigate } from 'react-router';

import { useDynamicNavigateButtonStyles } from '#/components/features/dynamicRibbon/dynamicComponents/DynamicNavigateButton.styles';
import { BASE_PATH } from '#/constants/general';

import { IDynamicNavigateButtonProps } from '#/interfaces/dynamicRibbon/dynamicRibbon';

import NewButton from '../../../common/button/NewButton';

const DynamicNavigateButton: FC<IDynamicNavigateButtonProps> = ({ component }) => {
  const navigate = useNavigate();
  const classes = useDynamicNavigateButtonStyles();
  const onClick = async () => {
    navigate(`${BASE_PATH}${component.content}`);
  };

  return <NewButton className={classes.button} onClick={onClick} text={component?.label || ''} variant="Primary" />;
};

export default DynamicNavigateButton;
