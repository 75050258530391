import { Box, Grid } from '@mui/material';
import classnames from 'classnames';
import { useState } from 'react';

import AlertError from '#/components/common/Alert/AlertError/AlertError';

import { useDataCy } from '#/hooks/useDataCy';

import {
  useDeleteProductCategoryMutation,
  useDeleteProductMixAttributeMutation,
  useDeleteProductMixSubcategoryMutation,
  usePostProductMixAttributeMutation,
  usePostProductMixCategoryMutation,
  usePostProductMixSubcategoryMutation,
} from '#/store/api/productMix/productMix';

import Card from '../../../../common/card/card';
import { useProductMixStyles } from '../productMix.styles';
import ProductMixAttributesTable from '../tables/ProductMixAttributesTable';
import ProductMixCategoriesTable from '../tables/ProductMixCategoriesTable';
import ProductMixSubCategoriesTable from '../tables/ProductMixSubcategoriesTable';

const ProductMixGlobal = () => {
  const styles = useProductMixStyles();
  const generateDataCy = useDataCy();

  const [_1, { error: postProductMixSubcategoryError }] = usePostProductMixSubcategoryMutation({
    fixedCacheKey: 'postProductMixSubcategory',
  });
  const [_2, { error: deleteProductMixCategoryError }] = useDeleteProductMixSubcategoryMutation({
    fixedCacheKey: 'deleteProductMixCategory',
  });
  const [_3, { error: postProductMixCategoryError }] = usePostProductMixCategoryMutation({
    fixedCacheKey: 'postProductMixCategory',
  });
  const [_4, { error: deleteProductCategoryError }] = useDeleteProductCategoryMutation({
    fixedCacheKey: 'deleteProductMixCategory',
  });

  const [_5, { error: postProductMixAttributeError }] = usePostProductMixAttributeMutation({
    fixedCacheKey: 'postProductMixAttribute',
  });
  const [_6, { error: deleteProductMixAttributeLoading }] = useDeleteProductMixAttributeMutation({
    fixedCacheKey: 'deleteProductMixAttribute',
  });

  const [selectedId, setSelectedId] = useState<string>('');

  const error =
    postProductMixSubcategoryError ||
    deleteProductMixCategoryError ||
    postProductMixCategoryError ||
    deleteProductCategoryError ||
    postProductMixAttributeError ||
    deleteProductMixAttributeLoading;

  return (
    <Card customStyles={{ padding: '2rem 1.4rem' }}>
      <AlertError error={error} />
      <div className={styles.wrap}>
        <Grid container>
          <Grid item>
            <Box className={classnames(styles.column)} data-cy={generateDataCy('box', 'categoriesContainer')}>
              <ProductMixCategoriesTable selectedId={selectedId} setSelectedId={setSelectedId} />
            </Box>
          </Grid>
          <Grid item>
            <Box className={classnames(styles.column)} data-cy={generateDataCy('box', 'subCategoriesContainer')}>
              <ProductMixSubCategoriesTable selectedId={selectedId} />
            </Box>
          </Grid>
          <Grid item>
            <Box className={classnames(styles.column)} data-cy={generateDataCy('box', 'productMixContainer')}>
              <ProductMixAttributesTable />
            </Box>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default ProductMixGlobal;
