import { flow } from 'lodash';
import { useNavigate } from 'react-router';

import TableCellLink from '#/components/common/TableCellLink/TableCellLink';
import { useTableStyles } from '#/components/common/table/tableComponent/Table.style';
import { ROUTE_PATHS } from '#/constants/routing/routePaths';

import { useDataCy } from '#/hooks/useDataCy';

import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { BrandOrderRetailer } from '#/store/api/campaigns/brandOrdersInterfaces';
import { SearchFilters } from '#/store/api/campaigns/campaignsInterfaces';

import { capitalizeAll, currencyFormatter } from '#/utils/textFormatters';

const useBrandOrdersRetailerTableColumns = (columNames: SearchFilters[] = []) => {
  const sortableColumns = columNames.map((el) => el.columnName);
  const navigate = useNavigate();
  const classes = useTableStyles();
  const generateDataCy = useDataCy();

  const tableColumns: ITableColumnsProps<BrandOrderRetailer>[] = [
    {
      fieldName: 'instanceId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Order ID',
      actionElementIdentifier: 'instanceId',
      sortable: sortableColumns.includes('instanceId'),
      getCustomElement: (instanceId) => (
        <TableCellLink link={`/${ROUTE_PATHS.CAMPAIGN_INSTANCE}/overview/${instanceId}`} text={instanceId} />
      ),
    },
    {
      fieldName: 'campaignName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Campaign',
      linkable: true,
      baseUrl: '/campaigns/overview',
      actionElementIdentifier: 'campaignId',
      sortable: sortableColumns.includes('campaignName'),
    },
    {
      fieldName: 'brandName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Brand Name',
      sortable: sortableColumns.includes('brandName'),
      linkable: true,
      baseUrl: ['', ROUTE_PATHS.PARTNERS, ROUTE_PATHS.PARTNERS_BRANDS].join('/'),
      actionElementIdentifier: 'brandId',
    },
    {
      fieldName: 'agencies',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.REACT_TABLE_DATA_ELEMENT,
      customHead: 'Agency Name',
      element: ({ rowData }) =>
        rowData.agencies.map((agency, index) => (
          <>
            <span
              key={agency.id}
              className={classes.link}
              data-cy={generateDataCy('span', `TableCell-${String(agency.name)}`)}
              onClick={() => navigate(['', ROUTE_PATHS.PARTNERS, ROUTE_PATHS.PARTNERS_AGENCIES, agency.id].join('/'))}
            >
              {agency.name}
            </span>
            {index < rowData.agencies.length - 1 && <span>, </span>}
          </>
        )),
    },
    {
      fieldName: 'primaryContactName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Primary Contact',
      linkable: true,
      baseUrl: '/partners/users',
      actionElementIdentifier: 'primaryContactId',
      sortable: sortableColumns.includes('primaryContactName'),
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Status',
      textFormatter: flow(String, capitalizeAll),
      sortable: sortableColumns.includes('status'),
    },
    {
      fieldName: 'budget',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Budget',
      textFormatter: currencyFormatter,
      itemClass: 'text-right',
      sortable: sortableColumns.includes('budget'),
    },
    {
      fieldName: 'appliedDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Applied Date',
      sortable: sortableColumns.includes('appliedDate'),
    },
    {
      fieldName: 'startDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Start Date',
      sortable: sortableColumns.includes('startDate'),
    },
    {
      fieldName: 'endDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'End Date',
      sortable: sortableColumns.includes('endDate'),
    },
  ];

  return tableColumns;
};
export default useBrandOrdersRetailerTableColumns;
