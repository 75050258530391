import { Box } from '@mui/material';

import { useNewCampaignIntervalIndicatorStyles } from '#/components/common/forms/campaigns/NewCampaignIntervalIndicator.styles';

const NewCampaignIntervalIndicator = () => {
  const classes = useNewCampaignIntervalIndicatorStyles();
  return <Box className={classes.container} />;
};

export default NewCampaignIntervalIndicator;
