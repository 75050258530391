import { THEME } from '#/constants/theme/constants';

import { createStyles, makeStyles } from '#/utils/theme';

export const useCampaignDynamicRibbonModal = makeStyles((theme) =>
  createStyles({
    wrap: {
      padding: THEME.REM_SPACES['1'],
      border: `1px solid ${THEME.PALETTES.BORDER.Light}`,
    },
    mb1: {
      '&&': {
        marginBottom: THEME.REM_SPACES['1.5'],
      },
    },
    mt1: {
      '&&': {
        marginTop: THEME.REM_SPACES['1.5'],
      },
    },
    dflex: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    brandCustomPaddingCard: {
      padding: theme.custom.REM_SPACES['2'],
    },
    brandButton: {
      margin: `${theme.custom.REM_SPACES['1']} ${theme.custom.REM_SPACES['3']} 0`,
      display: 'flex',
      justifyContent: 'end',
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    marginB1: {
      '&&': {
        marginBottom: THEME.REM_SPACES['1'],
      },
    },
    errorColor: {
      color: theme.custom.PALETTES.TEXT.Error,
    },
    selectWidth: {
      minWidth: '11rem',
    },
    dflexWithMargin: {
      display: 'flex',
      marginBottom: THEME.REM_SPACES['1.5'],
    },
    boldLabel: {
      '&&': {
        fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
        fontSize: '0.813rem',
        minWidth: '12.375rem',
      },
    },
    label: {
      '&&': {
        fontSize: '0.813rem',
        minWidth: '11.375rem',
      },
    },
    width50: {
      width: '50%',
    },
    bottomColumnWidth: {
      width: '45%',
      marginRight: '5%',
    },
    buttonAlign: {
      justifyContent: 'end',
      display: 'flex',
    },
    lh1: {
      lineHeight: '1.6',
    },
  }),
);
