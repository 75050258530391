import { Box } from '@mui/material';
import { isEqual } from 'lodash';

import RefreshIcon from '#/assets/Refresh.svg';

import { useCampaignInvitationsStyles } from '#/components/campaignInvitations/CampaignInvitations.styles';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import CommunicationsSelectInvitationStatus from '#/components/features/communications/filters/CommunicationsSelectInvitationStatus';
import CommunicationsSelectMatchingCampaign from '#/components/features/communications/filters/CommunicationsSelectMatchingCampaign/CommunicationsSelectMatchingCampaign';
import ProductMixSelect from '#/components/shared/ProductMixSelect/ProductMixSelect';

export interface ICommunicationsFilterBrandsState {
  filterType: string;
  additionalCategoryIds: string[];
  additionalSubCategoryIds: string[];
  additionalAttributeIds: string[];
  includeAccepted: boolean;
}

const initialState = {
  filterType: 'ALL',
  parentCompanies: '',
  additionalCategoryIds: [],
  additionalSubCategoryIds: [],
  additionalAttributeIds: [],
  includeAccepted: false,
};

const CommunicationsFilterBrands = ({ brandsFilters, setBrandsFilters }) => {
  const classes = useCampaignInvitationsStyles();
  const handleChange = (value: string | string[] | boolean, updateFilterName: string) =>
    setBrandsFilters({ ...brandsFilters, [updateFilterName]: value });

  return (
    <>
      <Box className={classes.flexWithSpace}>
        <TypographyWithFontFamily className={classes.mb05} variant="H5_Base">
          Additional Filters
        </TypographyWithFontFamily>
        {!isEqual(brandsFilters, initialState) && (
          <TypographyWithFontFamily className={classes.clearAll} onClick={() => setBrandsFilters(initialState)}>
            <img alt="refresh icon" className={classes.refreshIcon} src={RefreshIcon} />
            Clear additional Filters
          </TypographyWithFontFamily>
        )}
      </Box>
      <CommunicationsSelectMatchingCampaign filterType={brandsFilters.filterType} handleChange={handleChange} />
      <ProductMixSelect
        additionalAttributeIds={brandsFilters.additionalAttributeIds}
        additionalCategoryIds={brandsFilters.additionalCategoryIds}
        additionalSubCategoryIds={brandsFilters.additionalSubCategoryIds}
        handleChange={handleChange}
      />
      <CommunicationsSelectInvitationStatus
        handleChange={handleChange}
        includeAccepted={brandsFilters.includeAccepted}
      />
    </>
  );
};
export default CommunicationsFilterBrands;
