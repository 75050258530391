import { retailerAxios } from '../axiosConfig';
import { IRequestDto } from '../requestDtoInterface';

export interface ICompanyAccountProductMix {
  companyId: string;
  categories: ICategory[];
  subCategories: ISubcategory[];
  attributes: IAttribute[];
}

export interface ICategory {
  id: string;
  categoryName: string;
  subcategories: ISubcategory[];
}

export interface ISubcategory {
  id: string;
  subcategoryName: string;
}

export interface IAttribute {
  id: string;
  attributeName: string;
}

export const getProductMixCategories = (): Promise<IRequestDto<ICategory[]>> => {
  return retailerAxios.get('/product-mix/categories');
};

export const getProductMixAttributes = (): Promise<IRequestDto<IAttribute[]>> => {
  return retailerAxios.get('/product-mix/attributes');
};

export const getCampaignProductMix = (
  campaignId: string | undefined,
): Promise<IRequestDto<ICompanyAccountProductMix>> => {
  return retailerAxios.get(`campaign/${campaignId}/product-mix`);
};

export const getCompanyAccountProductMix = (
  brandId: string | undefined,
): Promise<IRequestDto<ICompanyAccountProductMix>> => {
  return retailerAxios.get(`company-account/${brandId}/product-mix`);
};
