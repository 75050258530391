import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { useLoaderStyle } from './Loader.style';

interface IProps {
  customWidth?: string;
}

export default function Loader({ customWidth }: IProps) {
  const classes = useLoaderStyle();
  return (
    <Box className={classes.wrapLoader} sx={{ ...(customWidth && { width: customWidth }) }}>
      <CircularProgress />
    </Box>
  );
}
