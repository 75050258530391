import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Card from '#/components/common/card/card';
import SingleDownloadUpload from '#/components/common/downloadUpload/SingleDownloadUpload';
import Loader from '#/components/common/loader/loader';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { useGeneralSettingStyle } from '#/components/features/settings/dataAndCalendar/GeneralSettings.style';

import {
  useGetRetailerAccountLegalTermsQuery,
  usePutRetailerAccountLegalTermsMutation,
} from '#/store/api/retailerAccount/retailerAccount';

const LegalTermsSetting = () => {
  const classes = useGeneralSettingStyle();
  const { data, isLoading } = useGetRetailerAccountLegalTermsQuery();
  const [triggerPutSRetailerAccountLegalTermsMutation] = usePutRetailerAccountLegalTermsMutation();
  const { watch, setValue } = useForm();
  useEffect(() => {
    if (watch('legalTerms') !== data) {
      triggerPutSRetailerAccountLegalTermsMutation({ legalTermsId: watch('legalTerms', '') });
    }
  }, [watch('legalTerms')]);
  return (
    <Card customClass={classes.sectionCard}>
      <TypographyWithFontFamily gutterBottom variant={'H5_Base'}>
        Legal Terms
      </TypographyWithFontFamily>
      {isLoading ? (
        <Loader />
      ) : (
        <Box className={classes.fileContainer}>
          <SingleDownloadUpload
            editMode
            extensions={['.pdf']}
            fileId={watch('legalTerms', data || '')}
            registerName={'legalTerms'}
            setValue={setValue}
            uploadLink={'/retailer-account/uploads/legal-terms'}
          />
        </Box>
      )}
    </Card>
  );
};

export default LegalTermsSetting;
