import SearchFilters from '#/components/common/SearchFilters/SearchFilters';
import useSearchFilters from '#/components/common/SearchFilters/useSearchFilters.hook';
import { CampaignListModes, ListCardsView } from '#/components/common/TabModeToggle';
import Loader from '#/components/common/loader/loader';
import { Table } from '#/components/common/table/tableComponent/Table';
import CampaignCardView from '#/components/features/campaigns/CampaignCardView';
import { CampaignTableColumns } from '#/constants/tables/campaignTable/campaignTable';

import { useGetCampaignsRetailerFilterQuery, useGetCampaignsRetailerQuery } from '#/store/api/campaigns/campaigns';

export const CampaignsRetailer = () => {
  const { data: filterData, isError: filterDataIsError } = useGetCampaignsRetailerFilterQuery({});

  const { actions, state } = useSearchFilters(undefined, true, filterData?.filters);

  const {
    data: campaignsData,
    isError: campaignsIsError,
    isFetching: campaignsIsFetching,
    isLoading: campaignsIsLoading,
  } = useGetCampaignsRetailerQuery({
    pageNumber: state.pageNumber,
    numberOfRows: state.numberOfElementsPerPage,
    searchQuery: state.searchQuery,
  });

  if (campaignsIsLoading) return <Loader />;

  const { RetailerCampaignTable } = CampaignTableColumns(undefined, filterData ? filterData.filters : []);
  return (
    <SearchFilters
      columnNames={filterData?.filters}
      currentIndex={campaignsData?.currentIndex}
      filterDataIsError={filterDataIsError}
      iconsMapper={ListCardsView}
      isError={campaignsIsError}
      isFetching={campaignsIsFetching}
      isNoResults={!campaignsData || campaignsData.campaigns.length === 0}
      totalPages={campaignsData?.totalPages}
      totalResults={campaignsData?.totalResults}
      {...state}
      {...actions}
    >
      {state.viewMode === CampaignListModes.LIST ? (
        <Table
          columns={RetailerCampaignTable}
          data={campaignsData?.campaigns || []}
          displayStatusColor
          sortProps={{
            sortParams: state.sortParams,
            setSortParams: actions.setSortParam,
          }}
        />
      ) : (
        <CampaignCardView data={campaignsData?.campaigns || []} />
      )}
    </SearchFilters>
  );
};
