import { CircularProgress, SxProps, Theme } from '@mui/material';
import MUIButton from '@mui/material/Button';
import { FC } from 'react';

import { useDataCy } from '#/hooks/useDataCy';

import { ButtonMapper } from './NewButton.mapper';
import { useNewButtonStyles } from './NewButton.styles';
import { ButtonVariantOptions } from './NewButton.type';

export type NewButtonProps = {
  customId?: string;
  variant?: ButtonVariantOptions;
  disabled?: boolean;
  icon?: string;
  iconPosition?: 'left' | 'right';
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  type?: 'button' | 'reset' | 'submit' | undefined;
  sx?: SxProps<Theme>;
  className?: string;
  size?: 'default' | 'small';
  loading?: boolean;
  isVisible?: boolean;
  minWidth?: string;
} & ({ text?: undefined; dataCy: string } | { text: string; dataCy?: string });

const NewButton: FC<NewButtonProps> = ({
  variant = 'Primary',
  disabled = false,
  text,
  icon,
  iconPosition = 'left',
  onClick,
  type = 'button',
  sx,
  className,
  size = 'default',
  loading = false,
  isVisible = true,
  minWidth,
  dataCy,
  customId = '',
  ...props
}) => {
  const generateDataCy = useDataCy();

  const classes = useNewButtonStyles();
  const height = size === 'default' ? '2.2rem' : '1.5rem';

  return (
    <MUIButton
      className={className}
      data-cy={generateDataCy('button', text ?? dataCy)}
      disabled={disabled || loading}
      id={customId}
      onClick={onClick}
      sx={{
        ...ButtonMapper[variant].styles,
        height,
        ...sx,
        display: isVisible ? 'flex' : 'none',
        pointerEvents: loading && 'none',
        opacity: loading && '0.5',
        minWidth: `${text === '' ? '0.2' : minWidth}rem`,
      }}
      type={type}
      variant={ButtonMapper[variant].variant}
      {...props}
    >
      {loading ? (
        <CircularProgress className={classes[variant]} size="1rem" />
      ) : text ? (
        <div className={classes.textContainer}>
          {icon && iconPosition === 'left' && <img alt="icon" src={icon} />}

          <p className={classes.text}>{text}</p>
          {icon && iconPosition === 'right' && <img alt="icon" src={icon} />}
        </div>
      ) : (
        <img alt="icon" src={icon} />
      )}
    </MUIButton>
  );
};

export default NewButton;
