import {
  AlertFields,
  IReports,
  PendingCampaignsFields,
  RevenueByMonthFields,
  RevenueByYearFields,
} from '#/interfaces/reports/reports';

import { baseApi } from '#/store/base';

const dashboardApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPendingCampaigns: builder.query<IReports<PendingCampaignsFields>, void>({
      query: () => 'report/dashboard/pendingCampaigns',
    }),
    getRevenueByMonth: builder.query<IReports<RevenueByMonthFields>, void>({
      query: () => 'report/dashboard/revenueByMonth',
    }),
    getRevenueByYear: builder.query<IReports<RevenueByYearFields>, void>({
      query: () => 'report/dashboard/revenueByYear',
    }),
    getPendingTasks: builder.query<IReports, void>({
      query: () => 'report/dashboard/pendingTasks',
    }),
    getAlerts: builder.query<IReports<AlertFields>, void>({
      query: () => 'report/dashboard/pendingAlerts',
    }),
  }),
});

export const {
  useGetPendingCampaignsQuery,
  useGetRevenueByMonthQuery,
  useGetRevenueByYearQuery,
  useGetPendingTasksQuery,
  useGetAlertsQuery,
} = dashboardApi;
