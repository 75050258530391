import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import MaterialUiPagination from '@mui/material/Pagination';
import classnames from 'classnames';
import { FC } from 'react';

import { useDataCy } from '#/hooks/useDataCy';

import { TypographyWithFontFamily } from '../typography/TypographyWithFontFamily';
import { usePaginationStyles } from './Pagination.style';
import { ITEMS_PER_PAGE_OPTIONS } from './constants';

type PaginationProps = {
  totalPages: number;
  totalResults?: number;
  page: number;
  setPage: (newPage: number) => void;
  itemsPerPage: number;
  setItemsPerPage?: (newNumberOfElementsPerPage: number) => void;
  showTotalResults?: boolean;
};

export const Pagination: FC<PaginationProps> = ({
  totalPages,
  page,
  setPage,
  itemsPerPage,
  setItemsPerPage,
  totalResults = 0,
  showTotalResults = true,
}) => {
  const classes = usePaginationStyles();
  const generateDataCy = useDataCy();

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const onHandleItemsPerPageChange = (event: SelectChangeEvent) => {
    if (setItemsPerPage) {
      setItemsPerPage(Number(event.target.value));
    }
  };

  const isLastPage = page === totalPages;
  const intervalStart = (page - 1) * itemsPerPage + 1;
  const intervalEnd = isLastPage ? totalResults : page * itemsPerPage;

  return (
    <Box className={classes.container} data-cy={generateDataCy('Box', 'pagination-container')}>
      {showTotalResults && (
        <Box className={classes.section33}>
          <TypographyWithFontFamily variant="Small_Base">
            {intervalStart} - {intervalEnd} of {totalResults} results
          </TypographyWithFontFamily>
        </Box>
      )}
      <Box className={classnames(classes.container, classes.section33)}>
        <MaterialUiPagination classes={{ ul: classes.ul }} count={totalPages} onChange={handlePageChange} page={page} />
      </Box>
      {setItemsPerPage && (
        <Box className={classnames(classes.container, classes.itemsPerPage, classes.section33)}>
          <>
            <TypographyWithFontFamily classNames={classes.marginRight} variant="Default_Base">
              Items Per Page:
            </TypographyWithFontFamily>
            <Select
              className={classes.selectSx}
              onChange={onHandleItemsPerPageChange}
              size="small"
              value={String(itemsPerPage)}
            >
              {ITEMS_PER_PAGE_OPTIONS.map((element) => (
                <MenuItem key={element} value={element}>
                  {element}
                </MenuItem>
              ))}
            </Select>
          </>
        </Box>
      )}
    </Box>
  );
};
