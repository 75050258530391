import { Box, Grid } from '@mui/material';
import { FC } from 'react';

import { THEME } from '../../../../constants/theme/constants';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { useTacticsGridStyles } from './TacticsGrid.styles';

interface TacticsGridProps {
  tactics?: string[];
}

const TacticsGrid: FC<TacticsGridProps> = ({ tactics = [] }) => {
  const classes = useTacticsGridStyles();
  return (
    <Box className={classes.container}>
      <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} variant="Label_Base">
        TACTICS ({tactics.length})
      </TypographyWithFontFamily>
      <Grid className={classes.fullWidth} container>
        {tactics.map((tactic, index) => (
          <Grid key={index} item xs={6}>
            <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Dark} variant="Text_Body_S_500">
              {tactic}
            </TypographyWithFontFamily>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TacticsGrid;
