import { Box } from '@mui/material';

import { useNoResultsStyles } from '#/components/common/NoResults/NoResults.Styles';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';

const NoResults = () => {
  const classes = useNoResultsStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.messageContainer}>
        <TypographyWithFontFamily classNames={classes.message} variant="H2_Base">
          No results found
        </TypographyWithFontFamily>
      </Box>
      <img alt="default logo" className={classes.image} src={`/custom/DefaultLogo.png`} />
    </Box>
  );
};

export default NoResults;
