import { FormHelperText, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import AlertError from '#/components/common/Alert/AlertError/AlertError';
import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';
import Loader from '#/components/common/loader/loader';
import { UsStates } from '#/constants/statesPlaceholder';
import { UsersType } from '#/constants/usersRoles/usersRole';

import { useAddRetailerUserForm } from '#/hooks/forms/useAddRetailerUserForm';

import { useGetUserRolesQuery } from '#/store/api/users/users';

import PlusIcon from '../../../../assets/Plus.svg';
import NewButton from '../../../common/button/NewButton';
import Checkbox from '../../../common/inputs/Checkbox/Checkbox';
import Select from '../../../common/inputs/Select/Select';
import Modal from '../../../common/modal/Modal';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { UserProfilePicture } from '../../userProfile/UserProfilePicture';
import { addRetailerUserStyleSx, useAddRetailerUserModalStyle } from './AddRetailerUserModal.style';

interface IAddRetailerUserModalProps {
  onClose: () => void;
}

const AddRetailerUserModal: FC<IAddRetailerUserModalProps> = ({ onClose }) => {
  const classes = useAddRetailerUserModalStyle();
  const { contactCheckboxContainerSx, gridFlexSx, gridSpaceBetweenSx, headerWithSpacingSx, userInfoHeaderSx } =
    addRetailerUserStyleSx;
  const { onSubmit, register, errors, watch, control, setValue, error } = useAddRetailerUserForm(onClose);
  const [userImageId, setUserImageId] = useState('');
  useEffect(() => {
    setValue('avatar', userImageId);
  }, [userImageId]);

  const { data, isFetching } = useGetUserRolesQuery({ userType: UsersType.RETAILER });

  if (isFetching) return <Loader />;

  return (
    <Modal
      content={
        <form onSubmit={onSubmit}>
          <AlertError error={error} />
          <Box>
            <Box>
              <Grid container>
                <Grid item xs={4.9}>
                  <TypographyWithFontFamily sx={headerWithSpacingSx} variant="H5_Base">
                    User
                  </TypographyWithFontFamily>
                </Grid>
              </Grid>
              <Grid container spacing={{ xs: '1rem' }} sx={gridFlexSx}>
                <Grid item xs={2.4}>
                  <TextFieldOld
                    editMode={false}
                    label="User"
                    placeholder={`${watch('firstName', '')} ${watch('lastName', '')}`}
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <TextFieldOld editMode={false} label="Email" placeholder={watch('email', '')} />
                </Grid>
                <Grid item sx={gridSpaceBetweenSx} xs={2.4}>
                  <Controller
                    control={control}
                    name={'defaultRole'}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        editMode
                        label="Default Role"
                        onChange={onChange}
                        options={data?.map((item) => ({ label: item.name, value: item.id })) || []}
                        value={value}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <TypographyWithFontFamily sx={userInfoHeaderSx} variant="H5_Base">
                User Info
              </TypographyWithFontFamily>
              <Grid container spacing={{ xs: '1rem' }}>
                <Grid item xs={6}>
                  <TextFieldOld
                    editMode
                    error={errors?.firstName?.message}
                    label="First Name"
                    register={register('firstName')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldOld
                    editMode
                    error={errors?.lastName?.message}
                    label="Last Name"
                    register={register('lastName')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldOld editMode error={errors?.email?.message} label="Email" register={register('email')} />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldOld
                    editMode
                    error={errors?.title?.message}
                    label="Job Title"
                    register={register('title')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldOld
                    editMode
                    error={errors?.phoneNumber?.message}
                    label="Phone"
                    register={register('phoneNumber')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldOld
                    editMode
                    error={errors?.department?.message}
                    label="Department"
                    register={register('department')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldOld
                    editMode
                    error={errors?.addressLine1?.message}
                    label="Address Line 1"
                    register={register('addressLine1')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldOld
                    editMode
                    error={errors?.addressLine2?.message}
                    label="Address Line 2"
                    register={register('addressLine2')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldOld editMode error={errors?.city?.message} label="City" register={register('city')} />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldOld
                    editMode
                    error={errors?.country?.message}
                    label="Country"
                    register={register('country')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldOld
                    editMode
                    error={errors?.zipCode?.message}
                    label="Postal Code"
                    register={register('zipCode')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name={'state'}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        defaultValue=""
                        label="State"
                        onChange={onChange}
                        options={UsStates.map((state) => ({
                          label: state.label,
                          value: state.code,
                        }))}
                        useRegister
                        value={value}
                      />
                    )}
                  />
                  <FormHelperText>
                    <TypographyWithFontFamily>{errors?.state?.message}</TypographyWithFontFamily>
                  </FormHelperText>
                </Grid>
                <Grid item xs={3}>
                  <TypographyWithFontFamily variant="Label_Base">Upload Image / Avatar</TypographyWithFontFamily>
                  <UserProfilePicture autoSave={false} imageId={watch('avatar')} setUserImageId={setUserImageId} />
                  <FormHelperText>
                    <TypographyWithFontFamily>{errors?.avatar?.message}</TypographyWithFontFamily>
                  </FormHelperText>
                </Grid>
                <Grid container item sx={contactCheckboxContainerSx} xs={8}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={'keyContact'}
                      render={({ field }) => (
                        <Checkbox label="Key Contact" onChange={field.onChange} value={field.value} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={'billingContact'}
                      render={({ field }) => (
                        <Checkbox label="Billing Contact" onChange={field.onChange} value={field.value} />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box>
            <Box className={classes.buttonsContainer}>
              <NewButton onClick={onClose} text="Cancel" variant="Secondary" />
              <NewButton text="Save" type="submit" variant="Primary" />
            </Box>
          </Box>
        </form>
      }
      handleCloseModal={onClose}
      icon={PlusIcon}
      modalTitle="Add Retailer User"
      open
    />
  );
};

export default AddRetailerUserModal;
