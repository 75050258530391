import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { EditorState } from 'draft-js';
import { useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate } from 'react-router';

import { BASE_PATH, COMMUNICATIONS_TABS } from '#/constants/general';
import { ROUTE_PATHS } from '#/constants/routing/routePaths';
import { THEME } from '#/constants/theme/constants';

import { useAppDispatch, useAppSelector } from '#/hooks';

import { communicationsPromote } from '#/store/slices';

import RadioOff from '../../../../../assets/RadioOffIcon.svg';
import RadioOn from '../../../../../assets/RadioOnIcon.svg';
import CampaignBrandsList from '../../../../campaignInvitations/CampaignBrandsList';
import {
  greyColorTextSx,
  marginBottom025Sx,
  marginBottom05Sx,
  marginTop1Sx,
  useCampaignInvitationsStyles,
} from '../../../../campaignInvitations/CampaignInvitations.styles';
import NewButton from '../../../../common/button/NewButton';
import DatePicker from '../../../../common/inputs/DatePicker/DatePicker';
import { useDatePickerStyles } from '../../../../common/inputs/DatePicker/DatePicker.styles';
import TextField from '../../../../common/inputs/TextField/TextField';
import { TypographyWithFontFamily } from '../../../../common/typography/TypographyWithFontFamily';
import CommunicationsSelectedCampaigns from '../../shared/CommunicationsSelectedCampaigns';
import CustomCommunicationsModal from '../../shared/CustomCommunicationsModal';

const CommunicationsSelectMarketing = () => {
  const classes = useCampaignInvitationsStyles();
  const navigate = useNavigate();
  const { COMMUNICATIONS, PROMOTE_REVIEW, PROMOTE } = ROUTE_PATHS;
  const datePickerClasses = useDatePickerStyles();
  const { subjectLine, sendSchedule, day, time, message, selectedCampaigns } = useAppSelector(
    (state) => state.communications,
  );
  const dispatch = useAppDispatch();

  const handleClick = (param: string) => {
    param === 'next' ? navigate(`${BASE_PATH}/${COMMUNICATIONS}/${PROMOTE_REVIEW}`) : navigate(-1);
  };
  const handleOnChange = (value: string | undefined | EditorState, updateFor: string) => {
    dispatch(
      communicationsPromote.actions.updateMarketingStrings({
        itemKey: value,
        updateFor: updateFor,
      }),
    );
  };

  useEffect(() => {
    if (selectedCampaigns.length === 0) {
      navigate(`${BASE_PATH}/${COMMUNICATIONS}/${PROMOTE}`);
    }
  }, []);

  return (
    <CustomCommunicationsModal
      activeTab={COMMUNICATIONS_TABS.COMMUNICATIONS_MARKETING}
      modalTitle="Marketing Materials"
    >
      <Stack className={classes.selectBrandsContainer} direction={'row'}>
        <Box className={classes.invitationDetailsContainer}>
          <CampaignBrandsList />
        </Box>
        <Box className={classes.selectBrandsOptionsContainer}>
          <Box className={classes.buttonWrap}>
            <NewButton
              className={classes.mr15}
              onClick={() => handleClick('back')}
              text="Back"
              type="button"
              variant="Secondary"
            />
            <NewButton disabled={!subjectLine.length} onClick={() => handleClick('next')} text="Next" type="button" />
          </Box>
          <CommunicationsSelectedCampaigns />
          <Box className={classes.buttonWrap}>
            <Box className={classes.marketingMaterialsContainer}>
              <Box className={classes.invitationBodyContainer}>
                <TypographyWithFontFamily sx={{ ...marginTop1Sx, ...marginBottom05Sx }} variant="H5_Base">
                  Send Schedule
                </TypographyWithFontFamily>
                <TypographyWithFontFamily sx={greyColorTextSx} variant="Small_Base">
                  Schedule when this invite should get sent to brands
                </TypographyWithFontFamily>
                <FormControl>
                  <RadioGroup onChange={(e) => handleOnChange(e.target.value, 'sendSchedule')} row value={sendSchedule}>
                    <FormControlLabel
                      control={
                        <Radio
                          checkedIcon={<img alt="checkbox selected" src={RadioOn} />}
                          icon={<img alt="checkbox unselected" src={RadioOff} />}
                        />
                      }
                      label={
                        <TypographyWithFontFamily sx={greyColorTextSx} variant="Label_Base">
                          SEND IMMEDIATELY
                        </TypographyWithFontFamily>
                      }
                      value="immediately"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checkedIcon={<img alt="checkbox selected" src={RadioOn} />}
                          icon={<img alt="checkbox unselected" src={RadioOff} />}
                        />
                      }
                      label={
                        <TypographyWithFontFamily sx={greyColorTextSx} variant="Label_Base">
                          SCHEDULE SEND
                        </TypographyWithFontFamily>
                      }
                      value="schedule"
                    />
                  </RadioGroup>
                </FormControl>
                <Box className={classes.dayAndTimeContainer}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box className={classes.datePickerContainer}>
                      <TypographyWithFontFamily gutterBottom sx={greyColorTextSx}>
                        SELECT DAY
                      </TypographyWithFontFamily>
                      <DatePicker
                        dataCy="datePicker"
                        editMode={sendSchedule !== 'immediately'}
                        onChange={(newValue) => handleOnChange(newValue?.toString(), 'day')}
                        value={day}
                      />
                    </Box>
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['TimePicker', 'TimePicker']}>
                      <Box className={classes.timePicker}>
                        <TypographyWithFontFamily gutterBottom sx={greyColorTextSx}>
                          ENTER TIME (BASED ON YOUR TIMEZONE)
                        </TypographyWithFontFamily>
                        <TimePicker
                          className={`${sendSchedule === 'immediately' && datePickerClasses.disabled}`}
                          disabled={sendSchedule === 'immediately'}
                          onChange={(newValue) => handleOnChange(newValue?.toString(), 'time')}
                          slotProps={{
                            field: {
                              className: datePickerClasses.input,
                            },
                            textField: { size: 'small', fullWidth: true },
                          }}
                          value={dayjs(time)}
                        />
                      </Box>
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
                <Box>
                  <TypographyWithFontFamily sx={{ ...marginTop1Sx, ...marginBottom05Sx }} variant="H5_Base">
                    Subject line *
                  </TypographyWithFontFamily>
                  <TextField
                    dataCy="subject-line"
                    onChange={(e) => handleOnChange(e.target.value, 'subjectLine')}
                    value={subjectLine}
                  />
                </Box>
                <Box className={classes.messageBodyContainer}>
                  <TypographyWithFontFamily sx={{ ...marginTop1Sx, ...marginBottom025Sx }} variant="H5_Base">
                    Message Body
                  </TypographyWithFontFamily>
                  <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} sx={marginBottom05Sx} variant="Small_Base">
                    Provide highlights and details for brands to consider participating in your campaign.
                  </TypographyWithFontFamily>
                  <Editor
                    editorClassName={classes.editor}
                    editorState={message}
                    onEditorStateChange={(value) => handleOnChange(value, 'message')}
                    toolbarClassName="toolbarClassName"
                    toolbarHidden
                    wrapperClassName={classes.editorWrapper}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Stack>
    </CustomCommunicationsModal>
  );
};

export default CommunicationsSelectMarketing;
