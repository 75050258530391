import { Box } from '@mui/material';

import { IDynamicSingleTierProps } from '../../../../interfaces/dynamicRibbon/dynamicRibbon';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import TacticsGrid from '../common/TacticsGrid';
import TacticsHeader from '../common/TacticsHeader';
import { styles, useDynamicSingleTierStyles } from './DynamicSingleTier.styles';

const DynamicSingleTier = ({ component }: IDynamicSingleTierProps) => {
  const classes = useDynamicSingleTierStyles();
  return (
    <Box className={classes.container}>
      <TypographyWithFontFamily sx={styles.label}>Selected Tier</TypographyWithFontFamily>
      <Box className={classes.headerContainer}>
        <TacticsHeader tierBudget={component.content.tierBudget} tierName={component.content.tierName} />
        <TacticsGrid tactics={component.content.tierTactics} />
      </Box>
    </Box>
  );
};

export default DynamicSingleTier;
