import useGetSelectedTableActions from '#/hooks/useGetSelectedTableActions';

import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { SearchFilters } from '#/store/api/campaigns/campaignsInterfaces';
import { CompanyAccount, CompanyAccountAssociatedCompany } from '#/store/api/company/companyInterfaces';

import { getBrandAddress } from '#/utils/utilsReact';

export const getTableColumns = (
  companies: CompanyAccount[],
  selectedBrands: CompanyAccountAssociatedCompany[],
  setSelectedBrands: React.Dispatch<React.SetStateAction<CompanyAccountAssociatedCompany[]>>,
  columNames: SearchFilters[],
) => {
  const sortableColumns = columNames.map((el) => el.columnName);

  const {
    filteredCheckAllValue,
    selectedCheckAllValue,
    indeterminateFilteredCheckAll,
    filteredCheckFromList,
    selectedCheckFromList,
    selectedCheckBoxAction,
    selectedCheckAllAction,
    filteredCheckBoxAction,
    filteredCheckAllAction,
  } = useGetSelectedTableActions(companies, selectedBrands, setSelectedBrands);

  const selectedTableColumns: ITableColumnsProps<CompanyAccountAssociatedCompany> = {
    fieldName: null,
    align: TableColumnAlign.CENTER,
    type: TableCellTypes.CHECKBOX,
    customHead: ' ',
    checkBoxAction: selectedCheckBoxAction,
    checkFromList: filteredCheckFromList,
    hasCheckAll: true,
    checkAllValue: selectedCheckAllValue,
    checkAllAction: selectedCheckAllAction,
    actionElementIdentifier: 'aggregateId',
  };

  const filteredTableColumns: ITableColumnsProps<CompanyAccount> = {
    fieldName: null,
    align: TableColumnAlign.CENTER,
    type: TableCellTypes.CHECKBOX,
    customHead: ' ',
    checkBoxAction: filteredCheckBoxAction,
    checkFromList: selectedCheckFromList,
    hasCheckAll: true,
    checkAllValue: filteredCheckAllValue,
    indeterminateCheckAll: indeterminateFilteredCheckAll,
    checkAllAction: filteredCheckAllAction,
    actionElementIdentifier: 'aggregateId',
  };

  const commonTableColumns: ITableColumnsProps<CompanyAccountAssociatedCompany | CompanyAccount>[] = [
    {
      fieldName: 'companyName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Brand Name',
      sortable: sortableColumns.includes('companyName'),
    },
    {
      fieldName: 'parentName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Parent Company',
      sortable: sortableColumns.includes('companyParent'),
    },
    {
      fieldName: 'categories',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Categories',
      sortable: sortableColumns.includes('categories'),
    },
    {
      fieldName: 'subCategories',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Sub-Categories',
      sortable: sortableColumns.includes('subCategories'),
    },
    {
      fieldName: null,
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Address',
      actionElementIdentifier: 'mailingAddress1',
      actionElementSecondIdentifier: 'city',
      actionElementThirdIdentifier: 'state',
      actionElementFourthIdentifier: 'zip',
      getCustomElement: getBrandAddress,
    },
  ];

  // TODO: switch to a better logic to decide which interface to
  return {
    selectedTableColumns: [
      selectedTableColumns,
      ...commonTableColumns,
    ] as unknown as ITableColumnsProps<CompanyAccountAssociatedCompany>[],
    filteredTableColumns: [
      filteredTableColumns,
      ...commonTableColumns,
    ] as unknown as ITableColumnsProps<CompanyAccount>[],
  };
};
