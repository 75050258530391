import { TableBody as MUTBody, TableRow } from '@mui/material';
import classnames from 'classnames';

import { ITableColumnsProps, StatusColors } from '../../../../../interfaces/table/table';
import { useTableStyles } from '../Table.style';
import { TableCell } from './TableCell';

export const TableBody = <T,>({
  columns,
  data,
  bodyCellClass,
  displayStatusColor,
  tablePadding,
  selectedId,
  selectedRowClass,
  tableExtraRow,
}: {
  columns: ITableColumnsProps<T>[];
  data: T[];
  bodyCellClass?: string;
  displayStatusColor?: boolean;
  tablePadding?: string;
  selectedId?: string;
  selectedRowClass?: string;
  tableExtraRow?: JSX.Element;
}): JSX.Element => {
  const classes = useTableStyles();

  const keyProperty = columns.find((column) => column.isKey === true)?.fieldName;
  const selectedClass = selectedRowClass ? selectedRowClass : classes.defaultSelectedTableRow;

  return (
    <MUTBody>
      <>
        {data.map((dataEl, rowIndex) => {
          let customRowStatusStyle = {};
          if (displayStatusColor) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const status = (dataEl as any)?.status;
            const statusColor = status ? StatusColors[status] : '#E4E8EF';
            customRowStatusStyle = {
              boxShadow: `inset 11px 0 0px -7px ${statusColor}`,
            };
          }

          return (
            <TableRow
              key={`${String(dataEl[keyProperty as keyof T])}-${rowIndex}`}
              className={classnames({ [selectedClass]: !!selectedId && dataEl[keyProperty as keyof T] === selectedId })}
              style={customRowStatusStyle}
            >
              {columns.map((column, columnIndex) => (
                <TableCell
                  key={`col-${String(dataEl[keyProperty as keyof T])}-${rowIndex}-${columnIndex}`}
                  bodyCellClass={bodyCellClass}
                  column={column}
                  data={dataEl}
                  tablePadding={tablePadding}
                />
              ))}
            </TableRow>
          );
        })}
        {tableExtraRow ? tableExtraRow : null}
      </>
    </MUTBody>
  );
};
