import { createStyles, makeStyles } from '#/utils/theme';

export const useAgencyUsersStyle = makeStyles((theme) =>
  createStyles({
    agencyUserHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.custom.REM_SPACES[1],
    },
    agencyUserHeaderContainerLeft: {
      display: 'flex',
      columnGap: theme.custom.REM_SPACES[0.5],
      alignItems: 'center',
    },
    tableHeader: {
      fontWeight: 600,
      fontSize: '13px',
      background: theme.custom.PALETTES.UI.Neutral[100],
      height: theme.custom.REM_SPACES[2.5],
    },

    tableBody: {
      '& td:first-child': {
        fontWeight: 'bold',
      },
      marginBottom: theme.custom.REM_SPACES[1],
    },
    customHrefColor: {
      '&& > a': {
        color: theme.custom.PALETTES.TEXT.Action_Secondary,
        textDecoration: 'none',
        fontWeight: theme.custom.FONT_WEIGHTS.BOLD,
      },
    },
  }),
);
