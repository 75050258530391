import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const useImageDownloadUploadStyles = makeStyles(() =>
  createStyles({
    browseFile: {
      cursor: 'pointer',
      color: THEME.PALETTES.ICONS.Action_Secondary,
    },
    thumbnailContainer: {
      width: '100%',
      height: '94%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: THEME.REM_SPACES[0.5],
      position: 'relative',
    },
    thumbnailBorder: {
      border: `1px dashed ${THEME.PALETTES.BORDER.Extra_Light}`,
    },
    thumbnail: {
      backgroundRepeat: ' no-repeat',
      width: '100%',
      height: '100%',
      borderRadius: '0.5rem',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundColor: 'rgba(0,0,0,0.02)',
    },
    deleteIcon: {
      position: 'absolute',
      bottom: THEME.REM_SPACES[1],
      right: THEME.REM_SPACES[1],
      background: THEME.PALETTES.OTHER.White,
      borderRadius: THEME.REM_SPACES[5],
      padding: THEME.REM_SPACES[0.5],
    },
  }),
);
