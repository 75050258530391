import { Box, Grid } from '@mui/material';
import { isEqual } from 'lodash';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { TopSection } from '#/components/campaigns/topSection/TopSection';
import Card from '#/components/common/card/card';
import Loader from '#/components/common/loader/loader';
import CompanyLogo from '#/components/shared/companyLogo/CompanyLogo';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import { IAddChannelPartnerUserForm } from '../../../../interfaces/forms/addChanelPartnerUser';
import {
  useGetCompanyAccountUserCompanyQuery,
  usePutCompanyUserContactTypeMutation,
} from '../../../../store/api/company/company';
import { convertAllCapsUnderscoreStrings, createPartnersCompanyLink } from '../../../../utils/utils';
import Checkbox from '../../../common/inputs/Checkbox/Checkbox';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { useUserDataStyles } from './UserData.style';

interface UserDataProps {
  companyUserId: string | undefined;
}

const UserData: FC<UserDataProps> = ({ companyUserId }) => {
  const [edit, setEdit] = useState(false);

  const { userId } = useParams();
  const classes = useUserDataStyles();

  const { data, refetch, isLoading, isFetching } = useGetCompanyAccountUserCompanyQuery({
    userId,
    companyUserId,
  });

  if (!data) {
    return null;
  }

  const { companyType, companyName, logoId, companyId } = data;

  const { handleSubmit, reset, control, formState } = useForm<IAddChannelPartnerUserForm>({
    defaultValues: {
      keyContact: data.keyContact,
      billingContact: data.billingContact,
    },
  });

  const [trigger, { isLoading: putCompanyUserContactTypeLoading, error }] = usePutCompanyUserContactTypeMutation({
    fixedCacheKey: 'user-data-put',
  });

  const onSubmit = handleSubmit(async (formData: IAddChannelPartnerUserForm) => {
    if (!isEqual(formData, formState.defaultValues)) {
      await trigger({ userId, userFormData: formData });
    }
    setEdit(false);
  });

  const readOnly = !edit;

  return (
    <Card>
      <TopSection
        customHeader={
          <Box className={classes.wrap}>
            <CompanyLogo companyName={companyName} imageId={logoId} size="small" viewOnly />
            <TypographyWithFontFamily cName={classes.customHrefColor} variant={'H4_Base'}>
              <Link to={createPartnersCompanyLink(companyType, companyId)}>{companyName}</Link>
            </TypographyWithFontFamily>
          </Box>
        }
        dataLoading={isLoading}
        editMode={edit}
        error={error}
        isLoading={putCompanyUserContactTypeLoading}
        onSubmit={onSubmit}
        refetch={refetch}
        requiredPermission={USER_PERMISSIONS.PARTNERS_USERS_BILLING_SECTION_WRITE}
        reset={reset}
        sectionName="UserInfo"
        setEditMode={setEdit}
      />
      {isFetching ? (
        <Loader />
      ) : (
        <Grid className={classes.viewGrid} container>
          <Grid item xs={3}>
            <TypographyWithFontFamily variant="Label_Base">Company Type</TypographyWithFontFamily>
            <TypographyWithFontFamily>{convertAllCapsUnderscoreStrings(companyType)}</TypographyWithFontFamily>
          </Grid>
          <Grid alignSelf="end" item xs={3}>
            <Controller
              control={control}
              name={'keyContact'}
              render={({ field }) => (
                <Checkbox label="Key Contact" onChange={field.onChange} readOnly={readOnly} value={field.value} />
              )}
            />
          </Grid>
          <Grid alignSelf="end" item xs={3}>
            <Controller
              control={control}
              name={'billingContact'}
              render={({ field }) => (
                <Checkbox label="Billing contact" onChange={field.onChange} readOnly={readOnly} value={field.value} />
              )}
            />
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

export default UserData;
