import { Grid } from '@mui/material';
import { flow } from 'fp-ts/function';
import React, { FC } from 'react';
import { UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { IAddDetailsToTierForm } from '#/components/campaigns/addDetailsToSpecificTier';
import Select from '#/components/common/inputs/Select/Select';
import TextField from '#/components/common/inputs/TextField/TextField';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';

import { IChannelAndTacticsResponseTactic } from '#/interfaces/forms/campaigns';

import { IEditDetails } from '#/store/api/campaigns/campaignsInterfaces';
import { IRateCards } from '#/store/api/rateCards/rateCardsInterfaces';

import { createFormatterWithReverse, currencyFormatter, extractNumber, numberFormatter } from '#/utils/textFormatters';
import { checkArrayExistsAndContainsNonEmptyElements } from '#/utils/utils';

interface IRateCardsDetailsProps {
  register: UseFormRegister<IAddDetailsToTierForm>;
  setValue: UseFormSetValue<IAddDetailsToTierForm>;
  rateCardsData: IRateCards;
  editMode?: boolean;
  hideBudget?: boolean;
  selectedTactic: IChannelAndTacticsResponseTactic;
  detailsData: IEditDetails;
  watch: UseFormWatch<IAddDetailsToTierForm>;
}
const RateCardsDetails: FC<IRateCardsDetailsProps> = ({
  register,
  setValue,
  rateCardsData,
  selectedTactic,
  detailsData,
  editMode = true,
  hideBudget = false,
  watch,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <TypographyWithFontFamily variant={'Default_Bold'}>Tactic Details</TypographyWithFontFamily>
      </Grid>
      <Grid item xs={3}>
        {checkArrayExistsAndContainsNonEmptyElements(rateCardsData?.rateType) ? (
          <Select
            defaultValue={selectedTactic?.rateType}
            editMode={editMode}
            label="Rate Type"
            options={rateCardsData.rateType.map((rateType) => ({ label: rateType, value: rateType }))}
            register={register('rateType')}
            useRegister
          />
        ) : (
          <TextField
            disabled={!editMode}
            formHooks={{
              register: register('rateType'),
              setValue: (value) => setValue('rateType', value),
            }}
            label="Rate Type"
          />
        )}
      </Grid>
      <Grid item xs={3}>
        {checkArrayExistsAndContainsNonEmptyElements(rateCardsData?.rate) ? (
          <Select
            // defaultValue={selectedTactic?.rateValue} //TODO: aad when provided
            editMode={editMode}
            label="Rate"
            options={rateCardsData.rate.map((rate) => ({ label: rate, value: rate }))}
            register={register('rateValue')}
            useRegister
          />
        ) : (
          <TextField
            disabled={!editMode}
            formHooks={{
              register: register('rateValue'),
              setValue: (value) => setValue('rateValue', parseFloat(value)),
            }}
            formatters={{
              preview: (v) =>
                //@TODO currently in metadata form we only have "Number" as type, so we assume that is not a currency field
                numberFormatter(v, {
                  maximumFractionDigits: 2,
                }),
              typing: createFormatterWithReverse(
                flow(extractNumber, (v) =>
                  numberFormatter(v, {
                    maximumFractionDigits: 2,
                  }),
                ),
                extractNumber,
              ),
            }}
            label="Rate"
          />
        )}
      </Grid>
      <Grid item xs={3}>
        {checkArrayExistsAndContainsNonEmptyElements(rateCardsData?.units) ? (
          <Select
            defaultValue={selectedTactic?.units}
            editMode={editMode}
            label="Units"
            options={rateCardsData.units.map((unit) => ({ label: unit, value: unit }))}
            register={register('units')}
            useRegister
          />
        ) : (
          <TextField
            disabled={!editMode}
            formHooks={{
              register: register('units'),
              setValue: (value) => setValue('units', value),
            }}
            formatters={{
              preview: numberFormatter,
              typing: createFormatterWithReverse(flow(extractNumber, numberFormatter), extractNumber),
            }}
            label="Units"
          />
        )}
      </Grid>
      <Grid item xs={3}>
        {!hideBudget && (
          <>
            {checkArrayExistsAndContainsNonEmptyElements(rateCardsData?.budget) ? (
              <Select
                defaultValue={selectedTactic?.budget}
                editMode={editMode}
                label="Budget"
                options={rateCardsData.budget.map((budget) => ({ label: budget, value: budget }))}
                register={register('budget')}
                useRegister
              />
            ) : (
              <TextField
                disabled={!editMode}
                formHooks={{
                  register: register('budget'),
                  setValue: (value) => setValue('budget', parseFloat(value)),
                }}
                formatters={{
                  preview: currencyFormatter,
                  typing: createFormatterWithReverse(flow(extractNumber, currencyFormatter), extractNumber),
                }}
                label="Budget"
              />
            )}
          </>
        )}
      </Grid>
      <Grid item xs={4}>
        {checkArrayExistsAndContainsNonEmptyElements(rateCardsData?.placement) ? (
          <Select
            defaultValue={selectedTactic?.placement}
            editMode={editMode}
            label="Placement"
            options={rateCardsData.placement.map((placement) => ({ label: placement, value: placement }))}
            register={register('placement')}
            useRegister
          />
        ) : (
          <TextField
            disabled={!editMode}
            label="Placement"
            onChange={(e) => setValue('placement', e.target.value)}
            value={watch('placement')}
          />
        )}
      </Grid>
      <Grid item xs={4}>
        {checkArrayExistsAndContainsNonEmptyElements(rateCardsData?.targeting) ? (
          <Select
            defaultValue={selectedTactic?.targeting}
            editMode={editMode}
            label="Targeting"
            options={rateCardsData.targeting.map((targeting) => ({ label: targeting, value: targeting }))}
            register={register('targeting')}
            useRegister
          />
        ) : (
          <TextField
            disabled={!editMode}
            label="Targeting"
            onChange={(e) => setValue('targeting', e.target.value)}
            value={watch('targeting')}
          />
        )}
      </Grid>
      <Grid item xs={4}>
        {checkArrayExistsAndContainsNonEmptyElements(rateCardsData?.adSize) ? (
          <Select
            defaultValue={detailsData?.adSize}
            editMode={editMode}
            label="Ad Size"
            options={rateCardsData.adSize.map((adSize) => ({ label: adSize, value: adSize }))}
            register={register('adSize')}
            useRegister
          />
        ) : (
          <TextField
            disabled={!editMode}
            label="Ad Size"
            onChange={(e) => setValue('adSize', e.target.value)}
            value={watch('adSize')}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {checkArrayExistsAndContainsNonEmptyElements(rateCardsData?.notes) ? (
          <Select
            defaultValue={detailsData?.mediaNotes}
            editMode={editMode}
            label="Notes"
            options={rateCardsData.notes.map((note) => ({ label: note, value: note }))}
            register={register('notes')}
            useRegister
          />
        ) : (
          <TextField
            disabled={!editMode}
            label="Media Notes"
            multiline
            onChange={(e) => setValue('notes', e.target.value)}
            rows={4}
            value={watch('notes')}
          />
        )}
      </Grid>
    </>
  );
};

export default RateCardsDetails;
