import { Box } from '@mui/system';
import { Link } from 'react-router-dom';

import { useAppSelector } from '#/hooks';

import { ROUTE_PATHS } from '../../../constants/routing/routePaths';
import { formatUserType } from '../../../utils/utils';
import { LogoutButton } from '../../features/login/LogoutButton';
import { UserProfilePicture } from '../../features/userProfile/UserProfilePicture';
import Loader from '../loader/loader';
import { TypographyWithFontFamily } from '../typography/TypographyWithFontFamily';
import { useMainMenuStyles, userProfileTextSx } from './MainMenu.style';

export const UserProfile = () => {
  const { user } = useAppSelector((state) => state.user);
  const classes = useMainMenuStyles();

  if (!user) {
    return (
      <div className={classes.loaderContainer}>
        <div className={classes.loader}>
          <Loader />
        </div>
        <LogoutButton />
      </div>
    );
  }

  return (
    <div className={classes.userProfileContainer}>
      <Link className={classes.userNameContainer} to={`${ROUTE_PATHS.ACCOUNT}/${ROUTE_PATHS.ACCOUNT_MANAGEMENT}`}>
        <UserProfilePicture autoSave={false} avatarId={user.avatarId} small viewOnly />
        <Box className={classes.marginLeft5}>
          <TypographyWithFontFamily
            sx={userProfileTextSx}
          >{`${user.firstName} ${user.lastName}`}</TypographyWithFontFamily>
          <TypographyWithFontFamily variant="Default_Base">{formatUserType(user.userType)}</TypographyWithFontFamily>
          <TypographyWithFontFamily variant="Default_Base">{formatUserType(user.roleName)}</TypographyWithFontFamily>
        </Box>
      </Link>
      <LogoutButton />
    </div>
  );
};
