import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { useSnackbarError } from '#/components/common/snackbar/useSnackbarError';
import { BASE_PATH } from '#/constants/general';

import { ISubmitCampaignDynamicRibbon } from '#/interfaces/forms/campaigns';

import { usePostCampaignDynamicRibbonMutation } from '#/store/api/campaigns/instances';

export const useSubmitCampaignDynamicRibbon = () => {
  const { instanceId } = useParams();
  const navigate = useNavigate();
  const [submitCampaignDynamicRibbon] = usePostCampaignDynamicRibbonMutation({ fixedCacheKey: 'confirm-ribbon' });
  const schema = yup
    .object()
    .shape({
      comment: yup.string(),
      tierId: yup.string(),
      primaryContactId: yup.string().required('Please select primary contact'),
      adOpsContactId: yup.string().nullable(),
      categoryManagerId: yup.string().required('Please select Category Manager'),
      purchaseOrderNumber: yup.string(),
      taskActions: yup.array().of(
        yup.object().shape({
          taskId: yup.string(),
          action: yup.string().required(),
          comment: yup
            .string()
            .max(4000)
            .when('action', ([action]: string[], schema) => {
              if (action === 'REQUEST_REVISION') {
                return schema.required('Please provide a comment');
              } else {
                return schema;
              }
            }),
        }),
      ),
    })
    .required();
  const {
    register,
    reset,
    setValue,
    watch,
    handleSubmit,
    trigger,
    unregister,
    formState: { errors },
  } = useForm<ISubmitCampaignDynamicRibbon>({
    resolver: yupResolver(schema),
  });
  const { setError } = useSnackbarError();
  const onSubmit = handleSubmit(async (formData: ISubmitCampaignDynamicRibbon) => {
    const updatedData = {
      ...formData,
      tierId: watch()?.tierId,
      comment: watch()?.comment,
      adOpsContactId: formData.adOpsContactId ? formData.adOpsContactId : null,
    };
    submitCampaignDynamicRibbon({ instanceId, data: updatedData })
      .unwrap()
      .then(() => {
        navigate(`${BASE_PATH}/instances/brand-order-details/${instanceId}`);
        navigate(0);
      })
      .catch(() => {
        setError({ status: 500, message: 'Something went wrong, please try again' });
      });
  });
  return {
    register,
    setValue,
    handleSubmit,
    errors,
    watch,
    onSubmit,
    reset,
    trigger,
    unregister,
  };
};
