import { Link } from 'react-router-dom';

import CircleCheckIcon from '#/assets/CircleCheckIcon.svg';

import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { useAgencyUsersStyle } from '#/components/features/partners/agencies/AgencyUsers/AgencyUsers.style';

import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { CompanyAccountUser } from '#/store/api/company/companyInterfaces';

import { formatColumnToHaveComma } from '#/utils/utils';

const getTableColumns = (): ITableColumnsProps<CompanyAccountUser>[] => {
  const classes = useAgencyUsersStyle();

  return [
    {
      fieldName: 'userId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Campaign Id',
      hide: true,
    },
    {
      fieldName: 'fullName',
      align: TableColumnAlign.LEFT,
      linkable: true,
      actionElementIdentifier: 'userId',
      type: TableCellTypes.STRING,
      customHead: 'User Name',
      baseUrl: '/partners/users',
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      sortable: true,
      customHead: 'User Agency Status',
    },
    {
      fieldName: 'keyContact',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.ELEMENT,
      customHead: 'Key Contact',
      element: () => <img alt="circle icon" src={CircleCheckIcon} />,
      hideElementIfNull: true,
    },
    {
      fieldName: 'billingContact',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.ELEMENT,
      customHead: 'Billing Contact',
      element: () => <img alt="circle icon" src={CircleCheckIcon} />,
      hideElementIfNull: true,
    },
    {
      fieldName: 'brands',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Brands',
      actionElementIdentifier: 'brands',
      getCustomElement: (
        brands: {
          brandId: string;
          brandName: string;
        }[],
      ) => (
        <TypographyWithFontFamily className={classes.customHrefColor}>
          {brands?.map((brand, index) => (
            <Link key={brand.brandId} to={`/partners/brands/${brand.brandId}`}>
              {formatColumnToHaveComma(brand.brandName, index, brands)}
            </Link>
          ))}
        </TypographyWithFontFamily>
      ),
    },
    {
      fieldName: 'jobTitle',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      sortable: true,
      customHead: 'Job Title',
    },
    {
      fieldName: 'email',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      sortable: true,
      customHead: 'Email',
    },
    {
      fieldName: 'phone',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      sortable: true,
      customHead: 'Phone',
    },
    {
      fieldName: 'lastLogin',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      sortable: true,
      customHead: 'Last Login',
      textFormatter: (lastLogin) => (lastLogin ? new Date(lastLogin).toString() : ''),
    },
  ];
};

export default getTableColumns;
