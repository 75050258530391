import { useEffect } from 'react';

import DynamicRibbon from '#/components/features/dynamicRibbon/DynamicRibbon';

import useGetResource from '#/hooks/useGetResource';

import { useGetRibbonTasksCampaignBrandAdoptionMutation } from '#/store/api/ribbon/ribbon';

const DynamicRibbonTasksCampaignBrandAdoption = () => {
  const resource = useGetResource();

  const [trigger, { data: data, isLoading }] = useGetRibbonTasksCampaignBrandAdoptionMutation();

  useEffect(() => {
    trigger({ campaignId: resource.resourceId });
  }, []);

  return <DynamicRibbon isLoading={isLoading} response={data} />;
};

export default DynamicRibbonTasksCampaignBrandAdoption;
