import { createStyles, makeStyles } from '#/utils/theme';

export const useLegendStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    legendContainer: { display: 'flex', flexDirection: 'row', marginRight: theme.custom.REM_SPACES['0.5'] },
    legendDot: {
      borderRadius: 20,
      width: theme.custom.REM_SPACES['0.5'],
      height: theme.custom.REM_SPACES['0.5'],
      display: 'inline-block',
      margin: 'auto',
    },

    startOfPeriod: {
      background: theme.custom.PALETTES.SEMANTIC.Success['700'],
    },
    legendHeader: { marginRight: theme.custom.REM_SPACES['0.5'], alignSelf: 'center' },
    legendText: {
      marginLeft: theme.custom.REM_SPACES['0.5'],
    },
  }),
);
