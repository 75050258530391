import Loader from '#/components/common/loader/loader';
import PartnerUsersRetailer from '#/components/features/partners/users/PartnerUsersRetailer';

import { useGetCompanyUserAllFiltersQuery } from '#/store/api/users/users';

const PartnerUsersRetailerContainer = () => {
  const { isLoading: filterDataIsLoading } = useGetCompanyUserAllFiltersQuery({});

  if (filterDataIsLoading) return <Loader />;

  return <PartnerUsersRetailer />;
};
export default PartnerUsersRetailerContainer;
