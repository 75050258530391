import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../../../../constants/theme/constants';

export const useCampaignChannelsAndTacticsStyle = makeStyles(() =>
  createStyles({
    channelAndTacticsContainer: {
      background: THEME.PALETTES.OTHER.White,
      borderRadius: THEME.REM_SPACES[1],
      padding: THEME.REM_SPACES[1],
      display: 'flex',
      flexDirection: 'column',
      rowGap: THEME.REM_SPACES[1],
    },
    tierContainer: {
      marginTop: THEME.REM_SPACES[1],
    },

    inventoryContainer: {
      border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      padding: THEME.REM_SPACES[1],
      borderRadius: THEME.REM_SPACES[1],
      display: 'flex',
      flexDirection: 'column',
      rowGap: THEME.REM_SPACES[1],
    },
  }),
);
