import React from 'react';

import useGetResource from '../../../hooks/useGetResource';
import { useGetCampaignHistoryInstancesQuery } from '../../../store/api/instances/campaignHistoryInstances';
import CampaignTimeline from './CampaignTimeline';

const CampaignHistoryInstance = () => {
  const resource = useGetResource();
  const { data } = useGetCampaignHistoryInstancesQuery({ resource });
  return <>{data && <CampaignTimeline campaignHistory={data} />}</>;
};

export default CampaignHistoryInstance;
