import { Box } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { useTableLogoCellStyles } from '#/components/common/tableLogoCell/TableLogoCell.styles';
import CompanyLogo from '#/components/shared/companyLogo/CompanyLogo';

type TableLogoCellProps = {
  name: string;
  logoId: string | undefined | null;
  link: string;
};

const TableLogoCell: FC<TableLogoCellProps> = ({ name, logoId, link }) => {
  const classes = useTableLogoCellStyles();
  return (
    <Box className={classes.flexCenter}>
      <Box className={classes.logoContainer}>
        <CompanyLogo companyName={name} imageId={logoId} size="small" viewOnly />
      </Box>
      <Link className={classes.url} to={link}>
        {name}
      </Link>
    </Box>
  );
};

export default TableLogoCell;
