import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MessagesState {
  isConnected: boolean;
  messages: unknown[];
}

const initialState: MessagesState = {
  isConnected: false,
  messages: [],
};

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    connectWs(_state, _action: PayloadAction<string>) {
      // Logic is handled in the middleware
    },
    disconnectWs(_state, _action: PayloadAction<void>) {
      // Logic is handled in the middleware
    },
    setConnectionState(state, action: PayloadAction<boolean>) {
      state.isConnected = action.payload;
    },
    subscribeToTopic<T>(_state, _action: PayloadAction<{ topic: string; callback: (arg: T) => void }>) {
      // Logic is handled in the middleware
    },
    messageReceived(state, action: PayloadAction<unknown>) {
      state.messages.push(action.payload);
    },
  },
});

export const { actions } = messagesSlice;

export default messagesSlice;
