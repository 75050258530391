import Card from '#/components/common/card/card';
import { DynamicRibbonTasksInstanceProducts } from '#/components/features/campaigns/instances/channelsAndTactics/DynamicRibbonTasksInstanceProducts';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import PermissionWrapper from '#/utils/PermissionWrapper';

import useGetResource from '../../../../../hooks/useGetResource';
import { useGetInstancesBrandTiersAllQuery } from '../../../../../store/api/instances/campaignInstanceChannelsAndTacticsBrand';
import Loader from '../../../../common/loader/loader';
import { TypographyWithFontFamily } from '../../../../common/typography/TypographyWithFontFamily';
import CampaignInstanceTactics from './CampaignInstanceTactics';
import CampaignTierBrandInstances from './CampaignTierBrandInstances';

export const BrandInstanceChannelsAndTactics = () => {
  const resource = useGetResource();
  const { data, isFetching, refetch } = useGetInstancesBrandTiersAllQuery({ resource });

  return (
    <>
      <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.CAMPAIGN_INSTANCE_BRAND_CHANNEL_TACTICS]}>
        <DynamicRibbonTasksInstanceProducts />
      </PermissionWrapper>
      <Card>
        <CampaignInstanceTactics />
        {isFetching ? (
          <Loader />
        ) : data ? (
          data?.tiers?.map((tier) => {
            const filteredTactics = tier.tactics.filter((tactic) => tactic.channel !== 'Assets');
            console.log('debug', tier.tierName, tier.tactics, filteredTactics);
            return (
              <CampaignTierBrandInstances
                key={tier.tierName}
                tier={{ ...tier, tactics: filteredTactics }}
                tierRefetch={refetch}
              />
            );
          })
        ) : (
          <TypographyWithFontFamily variant={'Default_Bold'}>No tiers added</TypographyWithFontFamily>
        )}
      </Card>
    </>
  );
};
