import { Box, FormHelperText, Grid } from '@mui/material';
import { useEffect, useState } from 'react';

import { TopSection } from '#/components/campaigns/topSection/TopSection';
import { useCompanyGeneralFormStyles } from '#/components/common/forms/companies/CompanyGeneralForm/CompanyGeneralForm.styles';
import Select from '#/components/common/inputs/Select/Select';
import TextField from '#/components/common/inputs/TextField/TextField';
import Loader from '#/components/common/loader/loader';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';
import { UsStates } from '#/constants/statesPlaceholder';

import useGetResource, { RESOURCE_TYPES } from '#/hooks/useGetResource';

import { useGetCompanyBrandsByIdQuery } from '#/store/api/company/company';

import { useEditCompanyGeneralForm } from '../../../../../hooks/forms/companies/useEditCompanyGeneralForm';
import CompanyLogo from '../../../../shared/companyLogo/CompanyLogo';
import Card from '../../../card/card';

const CompanyGeneralForm = () => {
  const classes = useCompanyGeneralFormStyles();
  const { resourceId, resourceType } = useGetResource();
  const { data: companyOptionsData, isLoading: brandOptionsIsLoading } = useGetCompanyBrandsByIdQuery({
    companyId: resourceId,
  });
  const requiredPermission =
    resourceType === RESOURCE_TYPES.agencies
      ? USER_PERMISSIONS.PARTNERS_COMPANY_AGENCY_GENERAL_SECTION_WRITE
      : USER_PERMISSIONS.PARTNERS_COMPANY_BRAND_GENERAL_SECTION_WRITE;

  const [editMode, setEditMode] = useState(false);

  const {
    onSubmit,
    errors,
    register,
    setValue,
    isLoading: putCompanyUserContactTypeLoading,
    dataLoading,
    reset,
    refetch,
    data,
    watch,
    error,
  } = useEditCompanyGeneralForm(() => setEditMode(false));

  const [userImageId, setUserImageId] = useState('');

  useEffect(() => {
    if (userImageId) {
      setValue('logoId', userImageId);
    }
  }, [userImageId]);

  useEffect(() => {
    setUserImageId(data?.logoId || '');
  }, [data?.logoId]);

  return (
    <form onSubmit={onSubmit}>
      <Card>
        <TopSection
          customHeader={
            <Box className={classes.wrap}>
              <CompanyLogo
                companyName={data?.name || ''}
                imageId={userImageId}
                setUserImageId={setUserImageId}
                size="small"
                viewOnly={!editMode}
              />
              <TextField
                className={classes.name}
                disabled={!editMode}
                error={errors?.name?.message}
                label="Company Name"
                onChange={(event) => setValue('name', event.target.value)}
                value={watch('name')}
              />
            </Box>
          }
          dataLoading={dataLoading}
          editMode={editMode}
          error={error}
          isLoading={putCompanyUserContactTypeLoading}
          onSubmit={onSubmit}
          refetch={refetch}
          requiredPermission={requiredPermission}
          reset={reset}
          sectionName="UserInfo"
          setEditMode={setEditMode}
        />
        {dataLoading || brandOptionsIsLoading ? (
          <Loader />
        ) : (
          <Grid className={classes.viewGrid} container>
            <Grid item xs={3.3}>
              <TextField
                disabled={true}
                error={errors?.companyType?.message}
                label="Company Type"
                onChange={() => null}
                value={watch('companyType')}
              />
            </Grid>
            <Grid item xs={3.3}>
              <TextField
                disabled={!editMode}
                error={errors?.legalName?.message}
                label="Legal name"
                onChange={(event) => setValue('legalName', event.target.value)}
                value={watch('legalName')}
              />
            </Grid>
            {resourceType === RESOURCE_TYPES.brands && (
              <Grid item xs={3.3}>
                <Select
                  key={watch('parentId')}
                  defaultValue={watch('parentId') || ''}
                  displayEmpty
                  editMode={editMode}
                  label="Parent"
                  options={[
                    { label: '--None--', value: '' },
                    ...(companyOptionsData ?? []).map((company) => ({
                      label: company.companyName,
                      value: company.companyId,
                    })),
                  ]}
                  register={register('parentId')}
                  useRegister
                />
                <FormHelperText>
                  <TypographyWithFontFamily>{errors?.parentId?.message}</TypographyWithFontFamily>
                </FormHelperText>
              </Grid>
            )}
            <Grid item xs={3.3}>
              <TextField
                disabled={!editMode}
                error={errors?.vendorId?.message}
                label="Vendor Id"
                onChange={(event) => setValue('vendorId', event.target.value)}
                value={watch('vendorId')}
              />
            </Grid>
            <Grid item xs={3.3}>
              <TextField
                disabled={!editMode}
                error={errors?.companyUrl?.message}
                label="Company Url"
                onChange={(event) => setValue('companyUrl', event.target.value)}
                value={watch('companyUrl')}
              />
            </Grid>
            <Grid item xs={3.3}>
              <TextField
                disabled={!editMode}
                error={errors?.mailingAddress1?.message}
                label="MAILING ADDRESS 1"
                onChange={(event) => setValue('mailingAddress1', event.target.value)}
                value={watch('mailingAddress1')}
              />
            </Grid>
            <Grid item xs={3.3}>
              <TextField
                disabled={!editMode}
                error={errors?.mailingAddress2?.message}
                label="MAILING ADDRESS 2"
                onChange={(event) => setValue('mailingAddress2', event.target.value)}
                value={watch('mailingAddress2')}
              />
            </Grid>
            <Grid item xs={3.3}>
              <TextField
                disabled={!editMode}
                error={errors?.city?.message}
                label="City"
                onChange={(event) => setValue('city', event.target.value)}
                value={watch('city')}
              />
            </Grid>
            <Grid item xs={3.3}>
              <Select
                key={watch('state')}
                defaultValue={watch('state')}
                editMode={editMode}
                label="State"
                options={UsStates.map((state) => ({ label: state.label, value: state.code }))}
                register={register('state')}
                useRegister
              />
              <FormHelperText>
                <TypographyWithFontFamily>{errors?.state?.message}</TypographyWithFontFamily>
              </FormHelperText>
            </Grid>
            <Grid item xs={3.3}>
              <TextField
                disabled={!editMode}
                error={errors?.zip?.message}
                label="Zip"
                onChange={(event) => setValue('zip', event.target.value)}
                value={watch('zip')}
              />
            </Grid>
          </Grid>
        )}
      </Card>
    </form>
  );
};
export default CompanyGeneralForm;
