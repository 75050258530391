import { Box, Tab, Tabs } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import { useAppSelector } from '#/hooks';
import { useDataCy } from '#/hooks/useDataCy';

import { ISubMenuBarTabs } from '#/interfaces/submenuBar/submenuBar';

import { boxSx, returnTabSx, tabsSx, useSubmenuStyles } from './SubmenuBar.styles';

export const SubmenuBar = ({ tabs, locationIndex = 0 }: { tabs: ISubMenuBarTabs[]; locationIndex?: number }) => {
  const classes = useSubmenuStyles();
  const location = useLocation();
  const userPermissions = useAppSelector((state) => state.user.permissions);
  const generateDataCy = useDataCy();

  const breadcrumbs = location.pathname.split('/');
  const filteredTabs = tabs.filter((tab) => {
    if (typeof tab.permission === 'string') {
      return !tab.permission || (tab.permission && userPermissions?.includes(tab.permission));
    } else {
      return (
        !tab.permission ||
        (tab.permission && tab.permission?.some((tabPermission) => userPermissions?.includes(tabPermission)))
      );
    }
  });

  return (
    <Box sx={boxSx}>
      <Tabs sx={tabsSx}>
        {filteredTabs.map((tab) => {
          if (tab.hide !== true)
            return (
              <Link key={tab.url} className={classes.link} to={tab.url}>
                <Tab
                  data-cy={generateDataCy('Tab', tab.label)}
                  label={tab.label}
                  sx={returnTabSx(breadcrumbs, tab.url.split('/')[locationIndex])}
                ></Tab>
              </Link>
            );
        })}
      </Tabs>
    </Box>
  );
};
