import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const useTopSectionStyles = makeStyles(() =>
  createStyles({
    buttonsSection: { color: 'blue', fontSize: '0.8rem' },
    container: { display: 'flex', flexDirection: 'column' },
    rowContainer: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
    editButton: { border: 'none', backgroundColor: 'transparent', cursor: 'pointer', color: '#1F8AC9' },
    deprecatedContainer: { display: 'flex', justifyContent: 'space-between' },
  }),
);

export const style = {
  sectionName: {
    marginBottom: THEME.REM_SPACES['0.25'],
  },
  saveButton: {
    marginLeft: THEME.REM_SPACES['0.25'],
  },
};

export const typographySx = { fontSize: '1.3rem', mb: 3 };
export const buttonSx = { marginRight: '1.3rem' };
