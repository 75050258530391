import { CheckBox, RadioButtonChecked, RadioButtonUnchecked, CheckBoxOutlineBlank } from '@mui/icons-material';
import { FormControlLabel, Checkbox as MUICheckbox } from '@mui/material';
import React, { FC } from 'react';

import Loader from '#/components/common/loader/loader';

import { useDataCy } from '#/hooks/useDataCy';

import CheckFalseIcon from '../../../../assets/CheckFalseIcon.svg';
import CircleCheckIcon from '../../../../assets/CircleCheckIcon.svg';
import { THEME } from '../../../../constants/theme/constants';
import { TypographyWithFontFamily } from '../../typography/TypographyWithFontFamily';
import { useCheckboxStyle } from './Checkbox.styles';

type CheckboxProps = {
  value?: boolean;
  indeterminate?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  isLoading?: boolean;
  showRadioButton?: boolean;
};
const Checkbox: FC<CheckboxProps> = ({
  value,
  indeterminate,
  onChange,
  label = '',
  defaultChecked = false,
  disabled = false,
  readOnly = false,
  className,
  isLoading = false,
  showRadioButton = false,
}) => {
  const classes = useCheckboxStyle();
  const generateDataCy = useDataCy();
  if (readOnly) {
    const readOnlyCircleSrc = value ? CircleCheckIcon : CheckFalseIcon;
    return (
      <div className={classes.readOnlyContainer} data-cy={generateDataCy('div', `Checkbox-${label}-readonly`)}>
        <img alt="circle icon" src={readOnlyCircleSrc} />
        <TypographyWithFontFamily
          classNames={classes.readOnlyLabel}
          color={THEME.PALETTES.TEXT.Dark}
          variant="Text_Body_500"
        >
          {label}
        </TypographyWithFontFamily>
      </div>
    );
  }

  const labelColor = disabled ? THEME.PALETTES.TEXT.Gray : THEME.PALETTES.TEXT.Dark;
  const checkbox = isLoading ? (
    <Loader />
  ) : (
    <MUICheckbox
      checked={value}
      checkedIcon={showRadioButton ? <RadioButtonChecked /> : <CheckBox />}
      className={className}
      defaultChecked={defaultChecked}
      icon={showRadioButton ? <RadioButtonUnchecked /> : <CheckBoxOutlineBlank />}
      indeterminate={value ? false : indeterminate}
      onChange={onChange}
    />
  );

  if (label)
    return (
      <FormControlLabel
        control={checkbox}
        data-cy={generateDataCy('FormControlLabel', `Checkbox-${label}`)}
        disabled={disabled}
        label={
          <TypographyWithFontFamily color={labelColor} variant="Text_Body_500">
            {label}
          </TypographyWithFontFamily>
        }
      />
    );

  return checkbox;
};

export default Checkbox;
