import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const useDynamicTiersStyles = makeStyles(() =>
  createStyles({
    container: {
      flexDirection: 'row',
      overflowX: 'auto',
      overflowY: 'hidden',
      display: 'flex',
      ['@media (max-width:1385px)']: { flexDirection: 'column' },
    },
    tierContainer: {
      maxWidth: '22rem',
      minWidth: '15rem',
      overflowY: 'auto',
      flex: 1,
      minHeight: THEME.REM_SPACES[10],
      maxHeight: THEME.REM_SPACES[15],
      display: 'flex',
      padding: THEME.REM_SPACES[1],
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: THEME.REM_SPACES[0.5],
      alignSelf: 'stretch',
      borderRadius: THEME.REM_SPACES[0.5],
      border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      justifyContent: 'space-between',
      marginRight: THEME.REM_SPACES[1],
      ['@media (max-width:1385px)']: {
        flexDirection: 'column',
        marginBottom: THEME.REM_SPACES[1],
        maxWidth: '100%',
      },
    },

    tierContainerSelected: {
      border: `1px solid ${THEME.PALETTES.BORDER.Selected}`,
      background: THEME.PALETTES.UI.Secondary[100],
    },
    tierHeaderContainer: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' },
    checkIcon: {
      alignSelf: 'start',
    },
  }),
);

export const styles = {
  label: {
    paddingBottom: THEME.REM_SPACES[0.5],
    marginBottom: THEME.REM_SPACES[0.5],
    color: THEME.PALETTES.UI.Neutral[600],
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
};
