import { FormControlLabel, RadioGroup as MUIRadioGroup, Radio } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';

import { useDataCy } from '#/hooks/useDataCy';

import { THEME } from '../../../../constants/theme/constants';
import { TypographyWithFontFamily } from '../../typography/TypographyWithFontFamily';
import { useInputsStyle } from '../Inputs.styles';
import { useRadioGroupStyles } from './RadioGroup.styles';

interface RadioOption {
  value: string;
  label: string;
}

type RadioGroupProps = {
  editMode?: boolean;
  placeholder?: string;
  labelPosition?: 'Top' | 'Left' | 'Default';
  className?: string;
  options: RadioOption[];
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  // FIXME: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  // FIXME: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any;
  // FIXME: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerParam: any;
} & (
  | {
      label?: undefined;
      dataCy: string;
    }
  | {
      label: string;
      dataCy?: string;
    }
);

const RadioGroup: FC<RadioGroupProps> = ({
  label,
  onChange,
  value = '',
  options,
  className,
  labelPosition = 'Default',
  register,
  registerParam,
  dataCy,
  disabled = false,
}) => {
  const inputsClasses = useInputsStyle();
  const classes = useRadioGroupStyles();
  const generateDataCy = useDataCy();

  return (
    <div className={classNames(inputsClasses[labelPosition], classes.base, className)}>
      {label !== '' && (
        <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} variant="Label_Base">
          {label}
        </TypographyWithFontFamily>
      )}
      <MUIRadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        data-cy={generateDataCy('RadioGroup', label ?? dataCy)}
        name="controlled-radio-buttons-group"
        onChange={onChange}
        value={value}
      >
        {options.map((option: RadioOption) => (
          <FormControlLabel
            key={option.value}
            {...register(registerParam)}
            control={<Radio />}
            data-cy={generateDataCy('FormControlLabel', option.label)}
            disabled={disabled}
            label={option.label}
            value={option.value}
          />
        ))}
      </MUIRadioGroup>
    </div>
  );
};

export default RadioGroup;
