import { createStyles, makeStyles } from '#/utils/theme';

export const useBrandsTableStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginBottom: theme.custom.REM_SPACES[4],
    },
    table: {
      overflowY: 'scroll',
      marginBottom: theme.custom.REM_SPACES[1],

      '& table': {
        borderCollapse: 'collapse',
      },
      '&  th:nth-child(1)': {
        borderRight: 'none',
        width: '10px',
      },
      '&  th:nth-child(2)': {
        borderLeft: 'none',
      },
      '&  td:nth-child(1)': {
        borderRight: 'none',
      },
      '&  td:nth-child(2)': {
        borderLeft: 'none',
      },
    },
    selectedTable: {
      overflow: 'hidden',
    },
    searchFiltersContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'auto',
    },
    collapseContainer: {
      marginBottom: theme.custom.REM_SPACES[0.5],
      marginLeft: theme.custom.REM_SPACES[1],
      marginRight: theme.custom.REM_SPACES[1],
      boxShadow: `1px 5px 5px ${theme.custom.PALETTES.UI.Neutral[200]}`,
      borderRadius: 20,
      overflow: 'auto',
      minHeight: theme.custom.REM_SPACES[3],
    },
    tableHeader: {},
  }),
);
