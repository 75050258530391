import { Box, Grid } from '@mui/material';
import { flow } from 'fp-ts/function';
import { useEffect } from 'react';

import AlertError from '#/components/common/Alert/AlertError/AlertError';
import TextField from '#/components/common/inputs/TextField/TextField';
import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';

import { useAddTireInstancesForm } from '#/hooks/forms/campaigns/useAddTireInstanceForm';
import { useDataCy } from '#/hooks/useDataCy';

import { createFormatterWithReverse, currencyFormatter, extractNumber, numberFormatter } from '#/utils/textFormatters';

import PlusBlueIcon from '../../../assets/PlusBlueIcon.svg';
import NewButton from '../../common/button/NewButton';
import { useCampaignTierStyle } from './CampaignTier.style';

const CreateCampaignTierInstance = () => {
  const { errors, handleSubmit, onSubmit, register, watch, setValue, error } = useAddTireInstancesForm();
  const classes = useCampaignTierStyle();
  const generateDataCy = useDataCy();

  useEffect(() => {
    const tempTargetTotal = watch('targetBudget') * watch('targetBrands');
    if (!isNaN(tempTargetTotal)) {
      setValue('targetTotal', tempTargetTotal);
    }
  }, [watch('targetBudget'), watch('targetBrands')]);

  return (
    <Box className={classes.tierContainer}>
      <AlertError error={error} />
      <Box>
        <form onSubmit={onSubmit}>
          <Grid columnGap={'0.5rem'} container>
            <Grid item xs={2.9}>
              <TextFieldOld
                data-cy={generateDataCy('TextField', 'tierName')}
                editMode
                error={errors.tierName?.message}
                label="TIER NAME"
                register={register('tierName')}
              />
            </Grid>
            <Grid item xs={2.9}>
              <TextField
                error={errors.targetBudget?.message}
                formHooks={{
                  register: register('targetBudget'),
                  setValue: (value) => setValue('targetBudget', parseFloat(value)),
                }}
                formatters={{
                  preview: currencyFormatter,
                  typing: createFormatterWithReverse(flow(extractNumber, currencyFormatter), extractNumber),
                }}
                label="TARGET BUDGET"
              />
            </Grid>
            <Grid item xs={2.9}>
              <TextField
                error={errors.targetBrands?.message}
                formHooks={{
                  register: register('targetBrands'),
                  setValue: (value) => setValue('targetBrands', parseFloat(value)),
                }}
                formatters={{
                  preview: numberFormatter,
                  typing: createFormatterWithReverse(flow(extractNumber, numberFormatter), extractNumber),
                }}
                label="TARGET BRANDS"
              />
            </Grid>
            <Grid item xs={2.9}>
              <TextField
                error={errors.targetTotal?.message}
                formHooks={{
                  register: register('targetTotal'),
                  setValue: (value) => setValue('targetTotal', parseFloat(value)),
                }}
                formatters={{
                  preview: currencyFormatter,
                  typing: createFormatterWithReverse(flow(extractNumber, currencyFormatter), extractNumber),
                }}
                label="TARGET TOTAL"
              />
            </Grid>
            <Box sx={{ marginTop: '16px' }}>
              <NewButton
                icon={PlusBlueIcon}
                iconPosition="left"
                onClick={handleSubmit(() => onSubmit())}
                text="Add Tier"
                variant="Secondary"
              />
            </Box>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};
export default CreateCampaignTierInstance;
