import { FormHelperText, Grid } from '@mui/material';
import { useState } from 'react';

import { TopSection } from '#/components/campaigns/topSection/TopSection';
import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import { UsStates } from '../../../../constants/statesPlaceholder';
import { useEditUserInfo } from '../../../../hooks/forms/users/useEditUserInfo';
import Card from '../../../common/card/card';
import Select from '../../../common/inputs/Select/Select';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { usePartnerUsersGeneralInfoEditStyle } from './PartnerUsersGeneralInfoEdit.style';

const PartnerUsersGeneralInfo = () => {
  const classes = usePartnerUsersGeneralInfoEditStyle();
  const [editMode, setEditMode] = useState(false);
  const { onSubmit, errors, register, isLoading, dataLoading, reset, refetch, watch, error } = useEditUserInfo(() =>
    setEditMode(false),
  );

  return (
    <Card>
      <form onSubmit={onSubmit}>
        <TopSection
          dataLoading={dataLoading}
          editMode={editMode}
          error={error}
          isLoading={isLoading}
          onSubmit={onSubmit}
          refetch={refetch}
          requiredPermission={USER_PERMISSIONS.PARTNERS_USERS_GENERAL_SECTION_WRITE}
          reset={reset}
          sectionName="General"
          setEditMode={setEditMode}
        />
        <Grid className={classes.editGrid} container>
          <Grid item xs={1.8}>
            <TextFieldOld
              editMode={editMode}
              error={errors?.firstName?.message}
              label="First Name"
              register={register('firstName')}
            />
          </Grid>
          <Grid item xs={1.8}>
            <TextFieldOld
              editMode={editMode}
              error={errors?.lastName?.message}
              label="Last Name"
              register={register('lastName')}
            />
          </Grid>
          <Grid item xs={1.8}>
            <TextFieldOld error={errors?.emailAddress?.message} label="Email" register={register('emailAddress')} />
          </Grid>
          <Grid item xs={1.8}>
            <TextFieldOld
              editMode={editMode}
              error={errors?.phoneNumber?.message}
              label="Phone Number"
              register={register('phoneNumber')}
            />
          </Grid>
          <Grid item xs={1.8}>
            <TextFieldOld
              editMode={editMode}
              error={errors?.addressLine1?.message}
              label="Address Line 1"
              register={register('addressLine1')}
            />
          </Grid>
          <Grid item xs={1.8}>
            <TextFieldOld
              editMode={editMode}
              error={errors?.addressLine2?.message}
              label="Address Line 2"
              register={register('addressLine2')}
            />
          </Grid>
          <Grid item xs={1.8}>
            <TextFieldOld editMode={editMode} error={errors?.city?.message} label="City" register={register('city')} />
          </Grid>
          <Grid item xs={1.8}>
            <Select
              key={watch('state')}
              defaultValue={watch('state')}
              editMode={editMode}
              label="State"
              options={UsStates.map((state) => ({ label: state.label, value: state.code }))}
              register={register('state')}
              useRegister
            />
            <FormHelperText>
              <TypographyWithFontFamily>{errors?.state?.message}</TypographyWithFontFamily>
            </FormHelperText>
          </Grid>
          <Grid item xs={1.8}>
            <TextFieldOld
              editMode={editMode}
              error={errors?.zipCode?.message}
              label="Zip Code"
              register={register('zipCode')}
            />
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

export default PartnerUsersGeneralInfo;
