import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../constants/theme/constants';

export const useCampaignOverviewLegalStyles = makeStyles(() =>
  createStyles({
    legalContainer: {
      marginTop: '1rem',
      border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      padding: `${THEME.REM_SPACES['1']}`,
      borderRadius: THEME.REM_SPACES['0.5'],
    },
    legalContent: {
      display: 'flex',
      justifyContent: 'space-between',
      columnGap: '1rem',
    },
    legalFiles: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      rowGap: '1rem',
    },
    additionalTermsContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

export const upperCaseSx = {
  textTransform: 'uppercase',
};
export const additionalTermsAndConditionsEditModeTextAreaSx = {
  borderRadius: THEME.REM_SPACES['0.5'],
  border: THEME.PALETTES.UI.Neutral['200'],
};
export const additionalTermsAndConditionsReadOnlyModeTextAreaSx = {
  background: THEME.PALETTES.UI.Neutral['200'],
  borderRadius: THEME.REM_SPACES['0.5'],
  border: THEME.PALETTES.UI.Neutral['200'],
};
