import { Box } from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';

import { useAssociateCompanyToCompanyForUserStyles } from '#/components/addUserWizard/AssociateCompanyToCompanyForUser.styles';
import { getTableColumns } from '#/components/addUserWizard/AssociateCompanyToCompanyForUser.tableColumns';
import AlertError from '#/components/common/Alert/AlertError/AlertError';
import NewButton from '#/components/common/button/NewButton';
import ModalBottomContainer from '#/components/common/modal/ModalBottomContainer';
import { useSnackbarError } from '#/components/common/snackbar/useSnackbarError';
import { useSnackbarSuccess } from '#/components/common/snackbar/useSnackbarSuccess';

import {
  useGetAssociatedCompaniesQuery,
  useGetCompanyAccountAssociatedBrandsQuery,
  useGetHierarchyListQuery,
} from '#/store/api/company/company';
import { CompanyAccountAssociatedCompany } from '#/store/api/company/companyInterfaces';
import { usePutClaimsSetMutation } from '#/store/api/users/users';

import AutoCompleteFilter from '../common/inputs/AutoCompleteFilter';
import Loader from '../common/loader/loader';
import { Table } from '../common/table/tableComponent/Table';

type AssociateCompanyToCompanyForUserProps = {
  companyId: string | undefined;
  userId: string | undefined;
  onClose: () => void;
  isBrandUser?: boolean;
};

// TODO: switch to a filtered/pagination BE call
const AssociateCompanyToCompanyForUser: FC<AssociateCompanyToCompanyForUserProps> = ({
  companyId,
  userId,
  onClose,
  isBrandUser,
}) => {
  const [putClaimsSet, { isLoading: isPutClaimsSetLoading, error }] = usePutClaimsSetMutation();
  const { data: associatedCompanies, isLoading } = isBrandUser
    ? useGetHierarchyListQuery({ companyId })
    : useGetAssociatedCompaniesQuery({ companyId });
  const { data: companyAccountAssociatedBrandsData } = useGetCompanyAccountAssociatedBrandsQuery({ userId });

  const navigation = useNavigate();

  const { setError } = useSnackbarError();
  const { setSuccess } = useSnackbarSuccess();

  const onSave = async () => {
    try {
      await putClaimsSet({ userId: userId, selectedCompanyToAssociate: [companyId || '', ...selectedBrands] }).unwrap();
      setSuccess('Your action is being processed. Please refresh this section if data is not updated.');
    } catch (error) {
      setError((error as FetchBaseQueryError).data);
    } finally {
      navigation(0);
      onClose();
    }
  };
  const [selectedBrands, setSelectedBrands] = useState(
    companyAccountAssociatedBrandsData?.map((el) => el.companyId) || [],
  );

  const [filteredData, setFilteredData] = useState<CompanyAccountAssociatedCompany[]>([]);

  const tableColumns = getTableColumns(associatedCompanies, selectedBrands, setSelectedBrands);
  const classes = useAssociateCompanyToCompanyForUserStyles();

  if (isLoading) return <Loader />;
  if (!associatedCompanies) return null;

  return (
    <Box className={classes.container}>
      <AlertError error={error} />
      <AutoCompleteFilter
        customStyle={classes.marginTop1}
        data={associatedCompanies}
        filterBy="companyName"
        setFilteredData={setFilteredData}
        withCard={false}
      />
      <Table
        bodyCellClass={classes.tableCell}
        columns={tableColumns}
        containerClass={classes.tableContainer}
        data={filteredData?.length ? filteredData : associatedCompanies}
        headCellClass={classes.tableHeader}
      />
      <ModalBottomContainer>
        <Box></Box>
        <Box className={classes.buttonsContainer}>
          <NewButton onClick={onClose} text="Cancel" variant="Secondary" />
          <NewButton
            className={classes.lastButton}
            loading={isPutClaimsSetLoading}
            onClick={onSave}
            text="Save"
            variant="Primary"
          />
        </Box>
      </ModalBottomContainer>
    </Box>
  );
};

export default AssociateCompanyToCompanyForUser;
