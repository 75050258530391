import SearchFilters from '#/components/common/SearchFilters/SearchFilters';
import useSearchFilters from '#/components/common/SearchFilters/useSearchFilters.hook';
import Loader from '#/components/common/loader/loader';
import { Table } from '#/components/common/table/tableComponent/Table';
import useBrandOrdersBrandTableColumns from '#/components/features/campaigns/BrandOrders/BrandOrdersBrand/BrandOrdersBrand.tables';

import { useGetBrandOrdersBrandFilterQuery, useGetBrandOrdersBrandQuery } from '#/store/api/campaigns/campaigns';

export const BrandOrdersBrand = () => {
  const { data: filterData, isError: filterDataIsError } = useGetBrandOrdersBrandFilterQuery({});

  const { actions, state } = useSearchFilters(undefined, true, filterData?.filters);

  const {
    data: brandOrdersData,
    isError: brandOrdersIsError,
    isFetching: brandOrdersIsFetching,
    isLoading: BrandOrdersIsLoading,
  } = useGetBrandOrdersBrandQuery({
    pageNumber: state.pageNumber,
    numberOfRows: state.numberOfElementsPerPage,
    searchQuery: state.searchQuery,
  });

  const columns = useBrandOrdersBrandTableColumns(filterData?.filters);

  if (BrandOrdersIsLoading) return <Loader />;

  return (
    <SearchFilters
      columnNames={filterData?.filters}
      currentIndex={brandOrdersData?.currentIndex}
      filterDataIsError={filterDataIsError}
      hideViewMode
      isError={brandOrdersIsError}
      isFetching={brandOrdersIsFetching}
      isNoResults={!brandOrdersData || brandOrdersData.brandOrders.length === 0}
      totalPages={brandOrdersData?.totalPages}
      totalResults={brandOrdersData?.totalResults}
      {...state}
      {...actions}
    >
      <Table
        columns={columns}
        data={brandOrdersData?.brandOrders || []}
        displayStatusColor
        sortProps={{
          sortParams: state.sortParams,
          setSortParams: actions.setSortParam,
        }}
      />
    </SearchFilters>
  );
};
