import { Select as MUISelect, MenuItem, SelectChangeEvent } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';

import { THEME } from '#/constants/theme/constants';

import { useDataCy } from '#/hooks/useDataCy';

import { TypographyWithFontFamily } from '../../typography/TypographyWithFontFamily';
import { useInputsStyle } from '../Inputs.styles';
import { useSelectStyle } from './Select.styles';

export interface SelectOption {
  label: string | undefined;
  value: string | undefined;
}

type SelectProps = {
  editMode?: boolean;
  placeholder?: string;
  labelPosition?: 'Top' | 'Left' | 'Default';
  className?: string;
  options: SelectOption[];
  onChange?: (event: SelectChangeEvent) => void;
  value?: string | number;
  defaultValue?: string;
  register?: Record<string, unknown>;
  useRegister?: boolean;
  displayEmpty?: boolean;
} & (
  | {
      label?: undefined;
      dataCy: string;
    }
  | {
      label: string;
      dataCy?: string;
    }
);

const Select: FC<SelectProps> = ({
  editMode = true,
  label,
  onChange,
  value = '',
  options,
  placeholder,
  className,
  labelPosition = 'Default',
  defaultValue = '',
  register,
  useRegister = false,
  dataCy,
  displayEmpty,
}) => {
  const inputsClasses = useInputsStyle();
  const classes = useSelectStyle();
  const generateDataCy = useDataCy();

  const attributes = !useRegister
    ? {
        onChange,
        value: typeof value !== 'undefined' ? `${value}` : undefined,
      }
    : {
        ...register,
      };
  return (
    <div className={classNames(inputsClasses[labelPosition], classes.base, className)}>
      {label !== '' && label && (
        <TypographyWithFontFamily
          cName={inputsClasses.labelUppercase}
          color={THEME.PALETTES.TEXT.Gray}
          variant="Label_Base"
        >
          {label}
        </TypographyWithFontFamily>
      )}
      <MUISelect
        defaultValue={defaultValue}
        disabled={!editMode}
        displayEmpty={displayEmpty}
        fullWidth
        placeholder={placeholder}
        {...attributes}
        data-cy={generateDataCy('Select', label ?? dataCy)}
      >
        {options.map((option: SelectOption) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MUISelect>
    </div>
  );
};

export default Select;
