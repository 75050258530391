import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { usePostUserMutation } from '#/store/api/users/users';

import { IAddChannelPartnerUserForm } from '../../interfaces/forms/addChanelPartnerUser';

export const useAddChannelPartnerUserForm = (
  onClose: () => void,
  channelPartnerId: string | undefined,
  setError: (error: unknown) => void,
) => {
  const [postUser, { error }] = usePostUserMutation();
  const schema = yup
    .object()
    .shape({
      title: yup.string().max(64),
      firstName: yup.string().max(64).required('First Name is required'),
      lastName: yup.string().max(64).required('Last Name is required'),
      phoneNumber: yup.string().matches(/^[\s()+-]*([0-9][\s()+-]*){6,20}$/, {
        message: 'Please enter a valid phone number',
        excludeEmptyString: true,
      }),
      email: yup.string().email('Email must be valid').required('Email is required'),
      addressLine1: yup.string(),
      addressLine2: yup.string(),
      city: yup.string(),
      state: yup.string(),
      country: yup.string().matches(/United States/, {
        message: 'Only United States is supported at the moment',
        excludeEmptyString: true,
      }),
      zipCode: yup
        .string()
        .matches(/^\d{5}(-\d{4})?$/, { message: 'Please enter a valid ZIP', excludeEmptyString: true }),
      department: yup.string(),
      avatar: yup.string().nullable(),
      keyContact: yup.boolean(),
      billingContact: yup.boolean(),
      defaultRole: yup.string().required('Default role is required'),
    })
    .required();
  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IAddChannelPartnerUserForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      keyContact: false,
      billingContact: false,
      country: 'United States',
      defaultRole: '5',
    },
  });

  const onSubmit = handleSubmit(async (formData: IAddChannelPartnerUserForm) => {
    try {
      const dataToSend = {
        ...formData,
        jobTitle: formData.title,
        avatarId: formData.avatar,
        userType: 'channel-partner',
        role: formData.defaultRole,
        companyId: channelPartnerId,
      };
      await postUser({ body: dataToSend });
      onClose();
    } catch (error) {
      setError(errors);
    }
  });
  return {
    register,
    errors,
    control,
    watch,
    setValue,
    onSubmit,
    error,
  };
};
