import { Box } from '@mui/material';
import React, { FC } from 'react';

import { getBrandsCsv, getRecipientsCsv } from '../../api/campaign/campaignInvitations';
import DownloadIconBlue from '../../assets/DownloadIconBlue.svg';
import { InvitationDownloadListType } from '../../constants/campaignInvitations/campaignInvitationsRecipients';
import { THEME } from '../../constants/theme/constants';
import { useSnackbarError } from '../common/snackbar/useSnackbarError';
import { TypographyWithFontFamily } from '../common/typography/TypographyWithFontFamily';
import { useCampaignInvitationsStyles } from './CampaignInvitations.styles';

interface IDownloadRecipientsProps {
  idList: string[] | undefined;
  type: InvitationDownloadListType;
}
const DownloadRecipients: FC<IDownloadRecipientsProps> = ({ idList, type }) => {
  const { setError } = useSnackbarError();
  const classes = useCampaignInvitationsStyles();

  const onDownload = async () => {
    try {
      let data, headers;
      if (type === InvitationDownloadListType.RECIPIENTS) {
        const response = await getRecipientsCsv(idList);
        data = response.data;
        headers = response.headers;
      } else {
        const response = await getBrandsCsv(idList);
        data = response.data;
        headers = response.headers;
      }

      const match = headers['content-disposition'].split('=')[1];
      const fileName = match;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(data);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setError(error);
    }
  };
  return (
    <Box className={classes.downloadContainer} onClick={onDownload}>
      <Box className={classes.campaignBrandListStack}>
        <img src={DownloadIconBlue} />
        <TypographyWithFontFamily cName={classes.campaignDownloadListText} color={THEME.PALETTES.TEXT.Action_Secondary}>
          Download List
        </TypographyWithFontFamily>
      </Box>
    </Box>
  );
};

export default DownloadRecipients;
