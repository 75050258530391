import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const useSubmenuStyles = makeStyles(() =>
  createStyles({
    link: {
      color: '#16191f',
      textDecoration: 'none',
    },
  }),
);

export const tabsSx = {
  display: 'flex',
  //TODO - this is a hack to fix the issue with the tabs not being centered
  padding: `0 !important`,
  margin: `0 !important`,
  textAlign: 'center',
  '& .MuiTabs-indicator': { display: 'none' },
};

export const returnTabSx = (breadCrumbs: string[], tabUrl: string) => ({
  borderRadius: '40px',
  fontSize: '0.8rem',
  height: '32px',
  minHeight: '32px',
  background: breadCrumbs.includes(tabUrl) ? '#fff' : '',
  opacity: '1',
  fontWeight: '600',
  color: THEME.PALETTES.TEXT.Dark,
  textTransform: 'none',
});

export const boxSx = { background: '#E5E4EA', borderRadius: '40px', my: 3, padding: '4px', height: 40 };
