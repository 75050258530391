import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../constants/theme/constants';

export const useCellStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingRight: THEME.REM_SPACES['0.25'],
      paddingLeft: THEME.REM_SPACES['0.25'],
      borderRadius: THEME.REM_SPACES['1.5'],
      width: THEME.REM_SPACES['2'],
      textAlign: 'center',
    },
    legendContainer: { display: 'flex', flexDirection: 'row' },
    legendDot: {
      borderRadius: 20,
      width: THEME.REM_SPACES['0.5'],
      height: THEME.REM_SPACES['0.5'],
      display: 'inline-block',
      margin: 'auto',
    },

    startOfPeriod: {
      background: THEME.PALETTES.SEMANTIC.Success['700'],
    },
  }),
);

export const styles = {
  legendHeader: { marginRight: THEME.REM_SPACES['0.5'] },
  legendText: {
    marginLeft: THEME.REM_SPACES['0.5'],
  },
};
