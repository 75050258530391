import { Grid } from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { ITacticMetadataDTO, TacticMetadataForm } from '../../../../interfaces/tacticMetadata/tacticMetadata';
import { InfoTooltip } from '../../../features/reports/InfoTooltip';
import { TypographyWithFontFamily } from '../../typography/TypographyWithFontFamily';
import { errorTextSx } from '../TacticMetadataForm.style';

interface IProps {
  errors: FieldErrors<TacticMetadataForm>;
  input: ITacticMetadataDTO;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<TacticMetadataForm, any>;
}

export const FileUploadInput = ({ errors, input, control }: IProps) => {
  const existingErrors = errors[String(input.id)]?.message || '';

  return (
    <Grid container spacing={{ xs: '1rem' }} xs={12}>
      <Grid item xs={9}>
        <Controller
          control={control}
          name={String(input.id)}
          render={({ field: { onChange } }) => (
            <input
              disabled={input.isDisabled}
              onChange={(event) => {
                if (event.target.files) {
                  onChange(event.target.files[0]);
                }
              }}
              type="file"
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        {input.helperText && <InfoTooltip text={input.helperText} />}
      </Grid>
      <Grid item xs={12}>
        <TypographyWithFontFamily sx={errorTextSx} variant="Default_Base">
          {String(existingErrors)}
        </TypographyWithFontFamily>
      </Grid>
    </Grid>
  );
};
