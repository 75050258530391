import { Box, TableCell as MUTCell, TableRow } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router';

import { currencyFormatter, numberFormatter } from '#/utils/textFormatters';

import { THEME } from '../../../../../constants/theme/constants';
import { IChannelAndTacticsResponseTactic } from '../../../../../interfaces/forms/campaigns';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '../../../../../interfaces/table/table';
import { useGetCampaignInstanceTacticsQuery } from '../../../../../store/api/products/products';
import { convertAllCapsUnderscoreStrings } from '../../../../../utils/utils';
import AddDetailsToSpecificTierBrandInstance from '../../../../campaigns/AddDetailsToSpecificTierBrandInstance';
import { useCampaignTierTacticsTableStyle } from '../../../../campaigns/CampaignTierTacticsTableStyle';
import { Table } from '../../../../common/table/tableComponent/Table';
import { useChannelsAndTacticsStyles } from '../../campaign/channelsAndTactics/ChannelsAndTactics.style';
import ProductFeedTableView from './ProductFeedTableView';
import { useAppSelector } from '#/hooks';
import { USER_TYPES } from '#/utils/UserTypeWrapper';

interface ITactic {
  campaignTacticId: string;
  channelPartnerId: string;
  channelPartnerName: string;
  tierId: string;
  status: string;
  channel: string;
  subChannel: string;
  tactic: string;
  placement: string;
  targeting: string;
  startDate: string;
  endDate: string;
  budget: string;
  units: string;
  rateType: string;
  rateValue: number;
}

interface INewCampaignTierTacticsTableProps {
  tactics?: IChannelAndTacticsResponseTactic[];
  tierId?: string;
  tierRefetch?: () => void;
}

const NewCampaignTierTacticsTableBrandInstances = ({
  tactics,
  tierId,
  tierRefetch,
}: INewCampaignTierTacticsTableProps) => {
  const [upnCodesViewInstanceId, setUpnCodesViewInstanceId] = useState<string | null>(null);
  const [upnCodesViewName, setUpnCodesViewName] = useState<string>('');

  const [selectedTactic, setSelectedTactic] = useState<IChannelAndTacticsResponseTactic | undefined>(undefined);
  const classes = useCampaignTierTacticsTableStyle();

  const { instanceId } = useParams();
  const user = useAppSelector((state) => state.user);

  const consumerFacing = user.user?.userType === USER_TYPES.BRAND || user.user?.userType === USER_TYPES.AGENCY;

  const { data: dataTactics, refetch } = useGetCampaignInstanceTacticsQuery({ instanceId });

  let tableColumns: ITableColumnsProps<ITactic>[] = [
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Status',
      textFormatter: convertAllCapsUnderscoreStrings,
    },
    {
      fieldName: 'channel',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Channel',
    },
    {
      fieldName: 'subChannel',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Sub-channel',
    },
    {
      fieldName: 'tactic',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.ACTION,
      customHead: 'Tactic',
      referenceAction: (instanceTacticId: string) => {
        setSelectedTactic(tactics?.find((tactic) => tactic.instanceTacticId === instanceTacticId));
      },
      actionButtonTextFromMapping: 'tactic',
      itemClass: classes.areaButton,
      actionElementIdentifier: 'instanceTacticId',
    },
    {
      fieldName: 'channelPartnerName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Channel Partner',
    },
    {
      fieldName: 'placement',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Placement',
    },
    {
      fieldName: 'targeting',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Targeting',
    },
    {
      fieldName: 'rateType',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Rate Type',
    },
    {
      fieldName: 'rateValue',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Rate',
    },
    {
      fieldName: 'units',
      align: TableColumnAlign.LEFT,
      customHead: 'Units',
      textFormatter: numberFormatter,
      type: TableCellTypes.STRING,
    },
    {
      fieldName: 'budget',
      align: TableColumnAlign.LEFT,
      customHead: 'Budget',
      type: TableCellTypes.STRING,
      textFormatter: currencyFormatter,
      itemClass: 'text-right',
    },
    {
      fieldName: 'startDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Start Date',
    },
    {
      fieldName: 'endDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'End Date',
    },
    {
      fieldName: null,
      align: TableColumnAlign.CENTER,
      customHead: 'Number of Products',
      type: TableCellTypes.CUSTOM_ELEMENT,
      actionElementIdentifier: 'instanceTacticId',
      getCustomElement: (instanceTacticId) => {
        const currentTactic = dataTactics?.tactics?.find((t) => t.instanceTacticId === instanceTacticId);
        return (
          <Box
            onClick={() => {
              setUpnCodesViewInstanceId(instanceTacticId);
              setUpnCodesViewName(currentTactic?.tacticName || '');
            }}
            sx={{
              cursor: 'pointer',
              color: THEME.PALETTES.TEXT.Action_Secondary,
            }}
          >
            {currentTactic?.upnCodes?.length}
          </Box>
        );
      },
    },
  ];

  // Replace the tactic column if consumerFacing is true
  if (consumerFacing) {
    tableColumns = tableColumns.map((column) =>
      column.fieldName === 'tactic'
        ? {
            fieldName: 'tactic',
            align: TableColumnAlign.LEFT,
            type: TableCellTypes.STRING,
            customHead: 'Tactic',
          }
        : column,
    );
  }

  // Filter out the "Number of Products" column if consumerFacing is true
  const filteredColumns = consumerFacing
    ? tableColumns.filter((column) => column.customHead !== 'Number of Products')
    : tableColumns;

  const extraRowClasses = useChannelsAndTacticsStyles();

  const extraRow = () => {
    const totalBudget = (tactics ?? []).reduce((acc, tactic) => acc + (parseFloat(tactic.budget) || 0), 0);
    return (
      <TableRow className={extraRowClasses.extraRow}>
        <MUTCell className={extraRowClasses.extraRowCell} colSpan={10}>
          Total Budget
        </MUTCell>
        <MUTCell align={'right'} className={extraRowClasses.extraRowCellShortBrand} colSpan={1}>
          {!isNaN(totalBudget) ? `$ ${totalBudget}` : ''}
        </MUTCell>
      </TableRow>
    );
  };

  return (
    <Box>
      {!!selectedTactic && (
        <AddDetailsToSpecificTierBrandInstance
          selectedTactic={selectedTactic}
          setSelectedTactic={setSelectedTactic}
          tierId={tierId}
          tierRefetch={tierRefetch}
        />
      )}
      <Table columns={filteredColumns} data={tactics || []} tableExtraRow={extraRow()} />
      {upnCodesViewInstanceId && (
        <ProductFeedTableView
          onClose={() => {
            setUpnCodesViewInstanceId(null);
            refetch();
          }}
          upnCodesViewInstanceId={upnCodesViewInstanceId}
          upnCodesViewName={upnCodesViewName}
        />
      )}
    </Box>
  );
};

export default NewCampaignTierTacticsTableBrandInstances;
