import { FC } from 'react';

import UserTypeWrapper from '#/utils/UserTypeWrapper';

import EventModalBrandContainer from './EventModalBrandContainer';
import EventModalChannelPartnerContainer from './EventModalChannelPartnerContainer';
import EventModalRetailerContainer from './EventModalRetailerContainer';

interface EventModalProps {
  campaignId: string;
}

const EventModal: FC<EventModalProps> = ({ campaignId }) => (
  <UserTypeWrapper
    agency={<EventModalBrandContainer campaignId={campaignId} />}
    brand={<EventModalBrandContainer campaignId={campaignId} />}
    channel-partner={<EventModalChannelPartnerContainer campaignId={campaignId} />}
    retailer={<EventModalRetailerContainer campaignId={campaignId} />}
  />
);

export default EventModal;
