import { Box, TextField } from '@mui/material';
import { useState } from 'react';

import { useDataCy } from '#/hooks/useDataCy';

import { USER_PERMISSIONS } from '../../constants/permissions/userPermissions';
import { THEME } from '../../constants/theme/constants';
import { useOverviewEditLegal } from '../../hooks/forms/campaigns/use-overview-edit-legal';
import SingleDownloadUpload from '../common/downloadUpload/SingleDownloadUpload';
import Loader from '../common/loader/loader';
import { TypographyWithFontFamily } from '../common/typography/TypographyWithFontFamily';
import {
  additionalTermsAndConditionsEditModeTextAreaSx,
  additionalTermsAndConditionsReadOnlyModeTextAreaSx,
  upperCaseSx,
  useCampaignOverviewLegalStyles,
} from './CampaignOverviewLegal.style';
import { TopSection } from './topSection/TopSection';

const CampaignOverviewLegal = () => {
  const generateDataCy = useDataCy();

  const [editMode, setEditMode] = useState(false);
  const classes = useCampaignOverviewLegalStyles();

  const { onSubmit, errors, register, isLoading, dataLoading, reset, refetch, watch, setValue, error } =
    useOverviewEditLegal(() => setEditMode(false));

  return (
    <>
      <form onSubmit={onSubmit}>
        <Box className={classes.legalContainer} data-cy={generateDataCy('box', 'CampaignOverviewLegal')}>
          <TopSection
            dataLoading={dataLoading}
            editMode={editMode}
            error={error}
            isLoading={isLoading}
            onSubmit={onSubmit}
            refetch={refetch}
            requiredPermission={USER_PERMISSIONS.CAMPAIGN_OVERVIEW_INTERNAL_WRITE}
            reset={reset}
            sectionName="Legal"
            setEditMode={setEditMode}
          />
          {dataLoading ? (
            <Loader />
          ) : (
            <Box className={classes.legalContent}>
              <Box className={classes.legalFiles}>
                <Box>
                  <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} sx={upperCaseSx} variant="Label_Base">
                    General Terms & Conditions
                  </TypographyWithFontFamily>
                  <SingleDownloadUpload
                    // customDeleteLink={`/campaign/${campaignId}/legal-terms`}
                    editMode={editMode}
                    fileId={watch('termAndConditionsFileId')?.toString()}
                    registerName="termAndConditionsFileId"
                    setValue={setValue}
                    uploadLink={`/campaign/legal-terms`}
                  />
                </Box>
                <Box>
                  <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} sx={upperCaseSx} variant="Label_Base">
                    Custom Terms & Conditions
                  </TypographyWithFontFamily>
                  <SingleDownloadUpload
                    editMode={editMode}
                    fileId={watch('customTermAndConditionsFileId')?.toString()}
                    registerName="customTermAndConditionsFileId"
                    setValue={setValue}
                    uploadLink={`/campaign/custom-legal-terms`}
                  />
                </Box>
              </Box>
              <Box className={classes.additionalTermsContainer}>
                {/* right side */}
                <Box>
                  <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} sx={upperCaseSx} variant="Label_Base">
                    Additional Terms (Optional)
                  </TypographyWithFontFamily>
                  <TextField
                    date-cy="Additional Terms Optional"
                    helperText={errors.additionalTerms?.message?.toString()}
                    {...register('additionalTerms')}
                    disabled={!editMode}
                    fullWidth
                    multiline
                    rows={6}
                    sx={
                      editMode
                        ? additionalTermsAndConditionsEditModeTextAreaSx
                        : additionalTermsAndConditionsReadOnlyModeTextAreaSx
                    }
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </form>
    </>
  );
};

export default CampaignOverviewLegal;
