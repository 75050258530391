import { Box } from '@mui/material';
import React, { FC } from 'react';

import EditIcon from '../../../../assets/Edit.svg';
import ProductMixIcon from '../../../../assets/ProductMixIcon.svg';
import NewButton from '../../button/NewButton';
import { TypographyWithFontFamily } from '../../typography/TypographyWithFontFamily';
import { useProductMixStyles } from '../productMix.styles';
import { styles } from './EditProductMix.styles';

interface IEditMode {
  editMode: boolean;
  updateEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  saveHandler?: () => void;
  saveLoading: boolean;
}

const EditButtonsProductMix: FC<IEditMode> = ({ editMode, updateEditMode, saveHandler, saveLoading = false }) => {
  const classes = useProductMixStyles();

  return (
    <Box className={classes.flexSpaceBetweenCenter}>
      <TypographyWithFontFamily cName={classes.typographyTitle} variant="H4_Base">
        <img alt="product mix icon" className={classes.mrImg} src={ProductMixIcon} />
        Product Mix
      </TypographyWithFontFamily>
      <>
        {!editMode ? (
          <Box className={classes.editBox} component="span" onClick={() => updateEditMode(true)}>
            <img alt="edit" className={classes.mrImg} src={EditIcon} />
            Edit
          </Box>
        ) : (
          <Box className={classes.justifyEnd}>
            <NewButton
              className={classes.mr125}
              onClick={() => updateEditMode(false)}
              sx={styles.button}
              text="Cancel"
              variant="Secondary"
            />
            <NewButton loading={saveLoading} onClick={saveHandler} sx={styles.button} text="Save" variant="Primary" />
          </Box>
        )}
      </>
    </Box>
  );
};
export default EditButtonsProductMix;
