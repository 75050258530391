import { Workflow } from '#/store/api/workflow/workflowInterfaces';

import { baseApi } from '../../base';

const workflow = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getWorkflowAll: builder.query<{ [key: string]: Workflow[] }, void>({
      query: () => `/workflow/workflow/all`,
      providesTags: ['WORKFLOW_ALL'],
    }),
    putWorkflowActivate: builder.mutation<void, { workflowId: string }>({
      query: ({ workflowId }) => ({
        url: `/workflow/workflow/${workflowId}/activate`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error) => (error ? [] : ['WORKFLOW_ALL']),
    }),
  }),
});

export const { useGetWorkflowAllQuery, usePutWorkflowActivateMutation } = workflow;
