import { createStyles, makeStyles } from '#/utils/theme';

import SearchIcon from '../../../assets/Search.svg';
import { THEME } from '../../../constants/theme/constants';

export const useProductMixInputStyles = makeStyles(() =>
  createStyles({
    autoCompleteInput: {
      background: `url(${SearchIcon} ) 5px center no-repeat`,
      paddingLeft: '30px!important',
      borderRadius: '8px',
      marginTop: '5px',
      flexWrap: 'wrap',
    },
    container: {
      '&& .MuiChip-root': {
        margin: THEME.REM_SPACES[0.25],
        backgroundColor: THEME.PALETTES.UI.Secondary['200'],
        color: THEME.PALETTES.TEXT.Dark,
      },
      paddingTop: THEME.REM_SPACES[1],
    },
    chipsContainer: {
      paddingTop: THEME.REM_SPACES[0.5],
    },
  }),
);
