import { createStyles, makeStyles } from '#/utils/theme';

export const useCollapseStyle = makeStyles((theme) =>
  createStyles({
    container: {
      margin: 0,
      padding: 0,
      paddingRight: theme.custom.REM_SPACES[1],
    },
    summaryContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flex: 1,
      alignItems: 'center',
    },

    resetDefault: {
      '&&': {
        border: 0,
        '&::before': {
          display: 'none',
        },
        margin: 0,
        padding: `${theme.custom.REM_SPACES[0.25]} ${theme.custom.REM_SPACES[0.5]} ${theme.custom.REM_SPACES[0.25]} ${theme.custom.REM_SPACES[0.5]}`,
        outline: 'none',
        boxShadow: 'none',
      },
    },
    resetHeight: {
      '&&': {
        height: theme.custom.REM_SPACES[0.5],
      },
    },
    expandCollapseButton: {
      minWidth: '11rem',
    },
  }),
);
