import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { IAxiosError } from '../../../interfaces/error/error';

export const useSnackbarError = () => {
  const { enqueueSnackbar } = useSnackbar();

  const setError = useCallback(
    (error: unknown): void => {
      //@ts-expect-error we need to display this error only if this is an axios error
      if ('message' in error) {
        const apiError = error as IAxiosError;
        const errorMessage = apiError.message
          ? apiError.message
          : `Request failed with code: ${apiError.response?.status}`;

        switch (apiError?.response?.status) {
          case 418:
            break;
          case 401:
            break;
          default:
            enqueueSnackbar(errorMessage, { variant: 'error' });
        }
      }
    },
    [enqueueSnackbar],
  );

  return { setError };
};
