import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { FC } from 'react';

import CloseIcon from '#/assets/CloseIcon.svg';

import { useConfirmationModalStyles } from '#/components/common/ConfirmationModal/ConfirmationModal.styles';
import NewButton from '#/components/common/button/NewButton';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { THEME } from '#/constants/theme/constants';

import { useDataCy } from '#/hooks/useDataCy';

export type ConfirmModalProps = {
  open?: boolean;
  handleCloseModal: React.Dispatch<React.SetStateAction<boolean>>;
  actionCallback: () => void;
  warningMessage?: string;
};

const ConfirmationModal: FC<ConfirmModalProps> = ({
  open = true,
  handleCloseModal,
  actionCallback,
  warningMessage = 'Are you sure you want to continue',
}) => {
  const classes = useConfirmationModalStyles();
  const generateDataCy = useDataCy();

  return (
    <>
      <Dialog data-cy={generateDataCy('Modal', 'Confirm Action')} open={open}>
        <DialogTitle className={classes.dialogTitle}>
          <Box className={classes.flex}>
            <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Light} variant="H4_Base">
              Confirm Action
            </TypographyWithFontFamily>
          </Box>
          <Box className={classes.addBrandMargin}>
            <img
              alt="close icon"
              className={classes.closeImg}
              onClick={() => handleCloseModal(!open)}
              src={CloseIcon}
            />
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box className={classes.content}>
            <TypographyWithFontFamily className={classes.warning} variant="H5_Regular">
              {warningMessage}
            </TypographyWithFontFamily>
          </Box>
          <Box className={classes.buttonsContainer}>
            <NewButton onClick={() => handleCloseModal(false)} text="Cancel" variant="Secondary" />
            <NewButton onClick={actionCallback} text="Confirm" />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConfirmationModal;
