import { Box, Grid } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';

import AddDetailsHeader from '#/components/campaigns/AddDetailsHeader';
import AddDetailsOverview from '#/components/campaigns/AddDetailsOverview';
import RateCardsDetails from '#/components/campaigns/RateCardsDetails';
import AlertError from '#/components/common/Alert/AlertError/AlertError';
import ModalBottomContainer from '#/components/common/modal/ModalBottomContainer';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';
import { getUserHasPermission } from '#/constants/permissions/utils';

import { useTacticDetailsForm } from '#/hooks/forms/tacticDetails/useTacticDetailsForm';

import { useGetShellMetadataFormQuery } from '#/store/api/campaigns/tacticMetadataForm';
import { useGetRateCardsQuery } from '#/store/api/rateCards/rateCards';

import PermissionWrapper from '#/utils/PermissionWrapper';

import EditIcon from '../../assets/EditIcon.svg';
import useGetResource from '../../hooks/useGetResource';
import { IAddDetailsToSpecificTierProps } from '../../interfaces/forms/campaigns';
import {
  useGetCampaignTiersDetailsQuery,
  usePutCampaignTacticBudgetMutation,
  usePutCampaignTacticDetailsEditMutation,
} from '../../store/api/campaigns/campaignChannelsAndTactics';
import { useGetCampaignHeaderQuery } from '../../store/api/campaigns/campaigns';
import NewButton from '../common/button/NewButton';
import Loader from '../common/loader/loader';
import Modal from '../common/modal/Modal';
import { useSnackbarError } from '../common/snackbar/useSnackbarError';
import { TacticMetadataForm } from '../common/tacticMetadataForm/TacticMetadataForm';
import { TypographyWithFontFamily } from '../common/typography/TypographyWithFontFamily';
import { styles, useAddDetailsToSpecificTierStyles } from './AddDetailsToSpecificTier.styles';

export interface IAddDetailsToTierForm {
  channelPartner: string;
  startDate: string;
  endDate: string;
  leadTimeInDays: number;
  creativeDueDate: string;
  adSize: string;
  notes: string;
  budget: number;
  placement: string;
  targeting: string;
  units: string;
  rateType: string;
  rateValue: number;
}

const AddDetailsToSpecificTier: FC<IAddDetailsToSpecificTierProps> = ({
  selectedTactic,
  setSelectedTactic,
  tierRefetch,
}) => {
  const [triggerPutBudget, { error: triggerPutBudgetError }] = usePutCampaignTacticBudgetMutation();
  const [triggerPutCampaignTacticDetailsEditMutation, { error: triggerPutCampaignTacticDetailsEditMutationError }] =
    usePutCampaignTacticDetailsEditMutation();

  const error = triggerPutBudgetError || triggerPutCampaignTacticDetailsEditMutationError;

  const userHasCampaignsChannelAndTacticsTacticModalWrite = getUserHasPermission(
    USER_PERMISSIONS.CAMPAIGNS_CHANNEL_AND_TACTICS_TACTIC_MODAL_WRITE,
  );

  const resource = useGetResource();
  const { data: headerData } = useGetCampaignHeaderQuery({ resource });
  const { setError } = useSnackbarError();
  const [budgetAndDetailsSucceeded, setBudgetAndDetailsSucceeded] = useState(false);
  const [metadataFormSucceeded, setMetadataFormSucceeded] = useState(false);

  const { data: detailsData, isLoading: detailsIsLoading } = useGetCampaignTiersDetailsQuery({
    campaignTacticId: selectedTactic?.campaignTacticId,
  });

  const {
    data: tacticsMetadataData,
    isLoading: isTacticsMetadataLoading,
    error: tacticsMetadataError,
  } = useGetShellMetadataFormQuery({ id: selectedTactic.campaignTacticId });

  if (tacticsMetadataError) {
    setError(tacticsMetadataError);
  }

  const { data: rateCardsData } = useGetRateCardsQuery({
    channelTacticId: selectedTactic?.channelTacticId,
  });
  const { control, register, handleSubmit, setValue, watch, errors } = useTacticDetailsForm(
    detailsData,
    selectedTactic,
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tacticMetadataFormSubmitRef = useRef<any>();

  const onClose = () => {
    if (tierRefetch) {
      tierRefetch();
    }
    setSelectedTactic(undefined);
  };

  useEffect(() => {
    if (budgetAndDetailsSucceeded && metadataFormSucceeded) {
      onClose();
    }
  }, [budgetAndDetailsSucceeded, metadataFormSucceeded]);

  const handleTacticMetadataFormSucces = () => {
    setMetadataFormSucceeded(true);
  };

  const handleMetadataFormDetailsSubmit = () => {
    if (tacticMetadataFormSubmitRef.current) {
      tacticMetadataFormSubmitRef.current.click();
    }
  };

  const trySubmit = async (formData: IAddDetailsToTierForm) => {
    try {
      await Promise.all([
        triggerPutBudget({ campaignTacticId: selectedTactic?.campaignTacticId, budget: formData.budget }).unwrap(),
        triggerPutCampaignTacticDetailsEditMutation({
          campaignTacticId: selectedTactic?.campaignTacticId,
          startDate: formData.startDate,
          endDate: formData.endDate,
          leadTime: formData.leadTimeInDays,
          creativeDueDate: formData.creativeDueDate,
          adSize: formData.adSize,
          mediaNotes: formData.notes,
          formData,
        }).unwrap(),
      ]);
      setBudgetAndDetailsSucceeded(true);
    } catch (error) {
      setError(error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = handleSubmit((formData: IAddDetailsToTierForm, e: any) => {
    e.preventDefault();
    trySubmit(formData);
  });

  const classes = useAddDetailsToSpecificTierStyles();
  const modalTitle = userHasCampaignsChannelAndTacticsTacticModalWrite
    ? `Edit details for ${detailsData?.tactic}`
    : `Details for ${detailsData?.tactic}`;

  return (
    <Box>
      <Modal
        content={
          <form className={classes.form} onSubmit={onSubmit}>
            <AlertError error={error} />
            <Box className={classes.formContainer}>
              <Box>
                {detailsIsLoading ? (
                  <Loader />
                ) : (
                  <>
                    <Grid container spacing={2}>
                      {detailsData && headerData && (
                        <AddDetailsHeader
                          campaignId={headerData.campaignId}
                          campaignName={headerData.campaignName}
                          detailsData={detailsData}
                        />
                      )}
                      {detailsData && (
                        <AddDetailsOverview
                          control={control}
                          detailsData={detailsData}
                          editMode={userHasCampaignsChannelAndTacticsTacticModalWrite}
                          errors={errors}
                          register={register}
                          selectedTactic={selectedTactic}
                          setValue={setValue}
                          watch={watch}
                        />
                      )}
                      {rateCardsData && detailsData && (
                        <RateCardsDetails
                          detailsData={detailsData}
                          rateCardsData={rateCardsData}
                          register={register}
                          selectedTactic={selectedTactic}
                          setValue={setValue}
                          watch={watch}
                        />
                      )}
                      <Grid item xs={12}>
                        <TypographyWithFontFamily variant={'Default_Bold'}>Additional Details</TypographyWithFontFamily>
                      </Grid>
                      <TacticMetadataForm
                        inputsData={tacticsMetadataData}
                        isInstance={false}
                        loading={isTacticsMetadataLoading}
                        onClose={handleTacticMetadataFormSucces}
                        submitRef={tacticMetadataFormSubmitRef}
                        tacticId={selectedTactic.campaignTacticId}
                      />
                    </Grid>
                  </>
                )}
              </Box>
            </Box>
            <PermissionWrapper
              requiredPermissions={[USER_PERMISSIONS.CAMPAIGNS_CHANNEL_AND_TACTICS_TACTIC_MODAL_WRITE]}
            >
              <ModalBottomContainer>
                <NewButton onClick={onClose} sx={styles.cancelButton} text="Cancel" variant="Secondary" />
                <NewButton onClick={handleMetadataFormDetailsSubmit} text="Save" type="submit" variant="Primary" />
              </ModalBottomContainer>
            </PermissionWrapper>
          </form>
        }
        handleCloseModal={onClose}
        icon={EditIcon}
        modalTitle={modalTitle}
        size="large"
      />
    </Box>
  );
};

export default AddDetailsToSpecificTier;
