import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';

import { infoCursorTooltipSx } from './Reports.style';

interface IProps {
  text: string;
}

export const InfoTooltip = ({ text }: IProps) => (
  <Tooltip sx={infoCursorTooltipSx} title={text}>
    <InfoIcon />
  </Tooltip>
);
