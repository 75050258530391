import { AxiosResponse } from 'axios';

import { IReportListItemDTO } from '#/interfaces/reports/reports';

import { reportAxios } from '../axiosConfig';

export const getReportsList = (): Promise<IReportListItemDTO> => {
  return reportAxios.get(`/report/all`);
};

export const downloadReport = (reportId: string | undefined, searchQuery: string): Promise<AxiosResponse<Blob>> => {
  return reportAxios.get(`/report/export/${reportId}?searchQuery=${searchQuery}`, {
    responseType: 'blob',
  });
};
