import { Box } from '@mui/material';
import { FC } from 'react';

import { useCampaignInvitationsStyles } from '#/components/campaignInvitations/CampaignInvitations.styles';
import TextField from '#/components/common/inputs/TextField/TextField';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { THEME } from '#/constants/theme/constants';

interface ICommunicationsUserCompanyProps {
  handleChange: (value: string | string[], updateFilterName: string) => void;
  userNames: string;
  userEmails: string;
  parentCompanies: string;
}

const CommunicationsUserCompany: FC<ICommunicationsUserCompanyProps> = ({
  handleChange,
  userNames,
  userEmails,
  parentCompanies,
}) => {
  const classes = useCampaignInvitationsStyles();

  return (
    <Box>
      <TextField
        className={classes.mb05}
        label="User"
        onChange={(e) => handleChange(e.target.value, 'userNames')}
        placeholder="Search for use by name"
        value={userNames}
      />
      <TextField
        className={classes.mb1}
        dataCy="search-by-email"
        onChange={(e) => handleChange(e.target.value, 'userEmails')}
        placeholder="Search for use by email"
        value={userEmails}
      />
      <TypographyWithFontFamily className={classes.customLabel} color={THEME.PALETTES.TEXT.Gray} variant="Label_Base">
        Parent Company
      </TypographyWithFontFamily>
      <TextField
        className={classes.mb1}
        dataCy="search-by-parent"
        onChange={(e) => handleChange(e.target.value, 'parentCompanies')}
        placeholder="Parent Company"
        value={parentCompanies}
      />
    </Box>
  );
};

export default CommunicationsUserCompany;
