import { Box } from '@mui/material';
import React, { FC } from 'react';

import { useImageModalStyle } from '#/components/campaigns/campaignAssets/ImageModal.style';
import NewButton from '#/components/common/button/NewButton';
import ImageDownloadUpload from '#/components/common/downloadUpload/ImageDownloadUpload';
import Modal from '#/components/common/modal/Modal';

import ImageThumbnailIconWhite from '../../../assets/ImageThumbnailIconWhite.svg';

export interface IImageModalProps {
  imageId: string;
  handleClose: () => void;
}
const ImageModal: FC<IImageModalProps> = ({ handleClose, imageId }) => {
  const classes = useImageModalStyle();
  return (
    <Modal
      content={
        <Box className={classes.imageContainer}>
          <ImageDownloadUpload fileId={imageId} renderImageTag />
          <Box className={classes.buttonContainer}>
            <NewButton onClick={handleClose} text={'Close'} variant={'Secondary'} />
          </Box>
        </Box>
      }
      handleCloseModal={handleClose}
      icon={ImageThumbnailIconWhite}
      modalTitle={'Asset'}
      size={'wizard'}
    />
  );
};

export default ImageModal;
