import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';

import { useSnackbarError } from '#/components/common/snackbar/useSnackbarError';

import { IAddUserWizardForm } from '#/interfaces/forms/addUserWizard';

import { usePostUserMutation, usePutClaimsSetMutation } from '#/store/api/users/users';

export const useAddUserWizardForm = (
  onClose: () => void,
  isEditAssociatedBrands: boolean | undefined,
  userId: string | undefined,
) => {
  const [putClaimsSet, { isLoading: isPutClaimsSetLoading, error: putClaimsError }] = usePutClaimsSetMutation();
  const [createCompanyUser, { error, isLoading }] = usePostUserMutation();
  const schema = userId
    ? yup.object().shape({
        user: yup
          .object({
            firstName: yup.string().max(64),
            lastName: yup.string().max(64),
            email: yup.string().email('Email must be valid'),
          })
          .required(),
        selectedCompany: yup.object({
          companyId: yup.string().required('Please select a company'),
          name: yup.string(),
          type: yup.string(),
        }),
        defaultRole: yup.string(),
      })
    : yup
        .object()
        .shape({
          user: yup
            .object({
              title: yup.string().max(64),
              firstName: yup.string().max(64).required('First Name is required'),
              lastName: yup.string().max(64).required('Last Name is required'),
              phoneNumber: yup.string().matches(/^[\s()+-]*([0-9][\s()+-]*){6,20}$/, {
                message: 'Please enter a valid phone number',
                excludeEmptyString: true,
              }),
              email: yup.string().email('Email must be valid').required('Email is required'),
              addressLine1: yup.string(),
              addressLine2: yup.string(),
              city: yup.string(),
              state: yup.string(),
              country: yup.string().matches(/United States/, {
                message: 'Only United States is supported at the moment',
                excludeEmptyString: true,
              }),
              zipCode: yup
                .string()
                .matches(/^\d{5}(-\d{4})?$/, { message: 'Please enter a valid ZIP', excludeEmptyString: true }),
              department: yup.string(),
              avatar: yup.string().nullable(),
              keyContact: yup.boolean(),
              billingContact: yup.boolean(),
            })
            .required(),
          selectedCompany: yup
            .object({
              companyId: yup.string().required('Please select a company'),
              name: yup.string().required(),
              type: yup.string().required(),
            })
            .required(),
          selectedCompanyToAssociate: yup.array().of(yup.string()).notRequired(),
          defaultRole: yup.string().required('Default role is required'),
        })
        .required();
  const {
    register,
    clearErrors,
    control,
    setValue,
    reset,
    handleSubmit,
    watch,
    getValues,
    getFieldState,
    trigger,
    formState,
    resetField,
    unregister,
    setFocus,
    formState: { errors },
  } = useForm<IAddUserWizardForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      user: {
        keyContact: false,
        billingContact: false,
        country: 'United States',
        state: '',
      },

      defaultRole: '4',
    },
  });
  const { setError } = useSnackbarError();
  const onSubmit = handleSubmit(async (formData: IAddUserWizardForm) => {
    try {
      if (isEditAssociatedBrands) {
        await putClaimsSet({ userId: userId, selectedCompanyToAssociate: formData.selectedCompanyToAssociate });
      } else {
        const dataToSend = {
          firstName: formData.user.firstName,
          lastName: formData.user.lastName,
          email: formData.user.email,
          title: formData.user.title,
          jobTitle: formData.user.title,
          phoneNumber: formData.user.phoneNumber,
          avatarId: formData.user.avatar,
          addressLine1: formData.user.addressLine1,
          addressLine2: formData.user.addressLine2,
          city: formData.user.city,
          state: formData.user.state,
          country: formData.user.country,
          zipCode: formData.user.zipCode,
          keyContact: formData.user.keyContact,
          billingContact: formData.user.billingContact,
          userType: formData.selectedCompany.type.toLowerCase(), //user type is company type
          role: formData.defaultRole,
          department: 'department',
          companyId: formData.selectedCompany.companyId,
        };
        await createCompanyUser({ body: dataToSend }).unwrap();
        onClose();
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response?.status === 400) {
        setError(error.response.data);
      }
    }
  });
  return {
    register,
    clearErrors,
    control,
    setValue,
    reset,
    handleSubmit,
    errors,
    onSubmit,
    watch,
    getValues,
    getFieldState,
    setError,
    trigger,
    formState,
    resetField,
    unregister,
    setFocus,
    error,
    isLoading,
    isPutClaimsSetLoading: isPutClaimsSetLoading,
    putClaimsError: putClaimsError,
  };
};
