import { Box, CircularProgress } from '@mui/material';
import { useMemo, useState } from 'react';

import AlertError from '#/components/common/Alert/AlertError/AlertError';

import { useDeleteReportDeleteMutation } from '#/store/api/reports/reports';

import { getReportsList } from '../../../../api/reports/reports';
import PlusIcon from '../../../../assets/Plus.svg';
import { THEME } from '../../../../constants/theme/constants';
import { useRequest } from '../../../../hooks/useRequest';
import NewButton from '../../../common/button/NewButton';
import Loader from '../../../common/loader/loader';
import { useSnackbarError } from '../../../common/snackbar/useSnackbarError';
import { Table } from '../../../common/table/tableComponent/Table';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { useReportsAdminPageStyle } from './ReportsAdminPage.style';
import { AddReportModal } from './addReport/AddReportModal';
import { getListTableColumns } from './getListTableColumns';

export const ReportAdminPage = () => {
  const [deleteReportDelete, { error, isLoading }] = useDeleteReportDeleteMutation();
  const [refreshCount, setRefreshCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useReportsAdminPageStyle();
  const getAllReportsAction = useMemo(() => getReportsList(), [refreshCount]);
  const { response, loading } = useRequest(getAllReportsAction);
  const { setError } = useSnackbarError();

  const deleteAction = async (id: string): Promise<void> => {
    try {
      await deleteReportDelete({ reportId: id }).unwrap();
      setRefreshCount((oldState) => oldState + 1);
    } catch (error) {
      setError(error);
    }
  };

  const contentElement = response?.data.length ? (
    <Table
      bodyCellClass={classes.tableBodyCell}
      columns={getListTableColumns(deleteAction)}
      containerClass={classes.tableContainer}
      data={response?.data || []}
      headCellClass={classes.tableHeadCell}
    />
  ) : (
    <TypographyWithFontFamily variant="H5_Base">There is no report data.</TypographyWithFontFamily>
  );

  return (
    <Box className={classes.container}>
      <AlertError error={error} />
      <Box className={classes.content}>
        {isLoading && <CircularProgress size={THEME.REM_SPACES[1.5]} />}
        <NewButton
          className={classes.addButton}
          icon={PlusIcon}
          iconPosition="left"
          onClick={() => {
            setIsModalOpen(true);
          }}
          text="Add New Report"
        />
      </Box>
      {loading ? <Loader /> : contentElement}
      {isModalOpen && (
        <AddReportModal
          onClose={() => {
            setIsModalOpen(false);
          }}
          setRefreshCount={setRefreshCount}
        />
      )}
    </Box>
  );
};
