import { createStyles, makeStyles } from '#/utils/theme';

export const useRefetchStyle = makeStyles(() =>
  createStyles({
    refetchIconContainer: {
      cursor: 'pointer',
      width: '20px',
      height: '20px',
      borderRadius: '50%',
    },
    refetchIcon: { width: '20px', height: '20px' },
  }),
);

export const styles = {
  refetchIsLoading: {
    opacity: '.4',
    pointerEvents: 'none',
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
    animation: 'spin 1s ease infinite',
  },
};
