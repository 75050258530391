import { EventClickArg, SlotLabelContentArg } from '@fullcalendar/core';
import multimonthPlugin from '@fullcalendar/multimonth';
import FullCalendar from '@fullcalendar/react';
import timelinePlugin from '@fullcalendar/timeline';
import { Box } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { FC, useRef, useState } from 'react';

import { CalendarModes } from '#/components/common/TabModeToggle';
import { FULL_CALENDAR_LICENSE } from '#/constants/general';
import { THEME } from '#/constants/theme/constants';

import { IPeriod } from '#/store/api/periods/periodsInterfaces';
import { IRetailerAccountCalendar } from '#/store/api/retailerAccount/retailerAccountInterfaces';

import Card from '../common/card/card';
import Loader from '../common/loader/loader';
import Modal from '../common/modal/Modal';
import { useCalendarStyles } from './Calendar.styles';
import Cell from './Cell';
import Event from './Event';
import EventModal from './EventModal/EventModal';
import Header from './Header';
import getMappedCalendarData from './getMappedCalendarData';
import getWeekNumberContent from './getWeekNumberContent';
import useCalendarYear from './useCalendarYear.hook';

interface ICalendarProps {
  retailerAccountCalendar: IRetailerAccountCalendar | undefined;
  periods: IPeriod[] | undefined;
  useGetCampaignCalendarQuery;
}

dayjs.extend(weekOfYear);

enum TIMELINE_VIEW_OPTIONS {
  DAYS,
  WEEKS,
  MONTHS,
}

const MONTHS_LABELS_LEVEL = 0;
const WEEKS_LABELS_LEVEL = 1;
const DAYS_LABELS_LEVEL = 2;

const Calendar: FC<ICalendarProps> = ({ retailerAccountCalendar, periods, useGetCampaignCalendarQuery }) => {
  const classes = useCalendarStyles();
  const [eventModalCampaignId, setEventModalCampaignId] = useState('');
  const isModalOpen = eventModalCampaignId !== '';
  const handleCloseModal = () => setEventModalCampaignId('');

  const isViewSelectEnabled = Boolean(periods) && Boolean(retailerAccountCalendar);

  const {
    initialDate,
    isCurrentYear,
    startDayOfWeek,
    view,
    calendarQueryParams,
    calendarKey,
    titleFormat,
    calendarViewHandler,
    nextYearHandler,
    previousYearHandler,
    resetYearHandler,
  } = useCalendarYear(isViewSelectEnabled, retailerAccountCalendar?.retailerPeriods);

  const { data: campaignCalendar, isFetching } = useGetCampaignCalendarQuery(calendarQueryParams);
  const handleEventClick = (clickInfo: EventClickArg) => {
    setEventModalCampaignId(clickInfo.event.id);
  };

  const slotLabelClassNamesHandler = (e: SlotLabelContentArg) => {
    const { level, date } = e;
    if (level === MONTHS_LABELS_LEVEL) {
      const monthNumberParity = dayjs(date).month() % 2 === 0;
      return classNames(classes.month, {
        [classes.evenMonth]: monthNumberParity,
        [classes.oddMonth]: !monthNumberParity,
      });
    }
    if (level === WEEKS_LABELS_LEVEL) {
      const monthNumberParity = dayjs(date).week() % 2 === 0;
      return classNames(classes.week, {
        [classes.evenWeek]: monthNumberParity,
        [classes.oddWeek]: !monthNumberParity,
      });
    }
    if (level === DAYS_LABELS_LEVEL) {
      return classes.days;
    }
    if (level === 3) {
      return classes.days;
    }
    return '';
  };

  const slotLabelContentHandler = (e: SlotLabelContentArg) => {
    const { level, text, date } = e;

    if (level === MONTHS_LABELS_LEVEL) {
      // raw text
      return text;
    }

    if (level === WEEKS_LABELS_LEVEL) {
      if (timelineView === TIMELINE_VIEW_OPTIONS.WEEKS) {
        return text;
      }
      if (timelineView === TIMELINE_VIEW_OPTIONS.DAYS) {
        // returns a typography component
        return getWeekNumberContent({ date, text }, isViewSelectEnabled, periods);
      }
    }
    if (level === DAYS_LABELS_LEVEL) {
      if (timelineView === TIMELINE_VIEW_OPTIONS.DAYS) {
        return dayjs(date).format('ddd');
      }
    }
    return text;
  };
  const [selectedTab, setSelectedTab] = useState<CalendarModes>(CalendarModes.MONTH);
  const calendarRef = useRef<FullCalendar>(null);

  const [timelineView, setTimelineView] = useState<TIMELINE_VIEW_OPTIONS>(TIMELINE_VIEW_OPTIONS.DAYS);
  const timelineViewConfiguration = {
    [TIMELINE_VIEW_OPTIONS.DAYS]: {
      slotLabelFormat: [{ month: 'long' }, { week: 'short' }, { day: 'numeric' }, { day: 'numeric' }],
      slotDuration: { day: 1 },
      slotMinWidth: 75,
    },
    [TIMELINE_VIEW_OPTIONS.WEEKS]: {
      slotLabelFormat: [{ month: 'long' }, { week: 'long' }],
      slotDuration: { week: 1 },
      slotMinWidth: 150,
    },
    [TIMELINE_VIEW_OPTIONS.MONTHS]: {
      slotLabelFormat: [{ month: 'long' }],
      slotDuration: { month: 1 },
      slotMinWidth: 300,
    },
  };

  const viewModeMapper = {
    [CalendarModes.MONTH]: {
      dayCellContent: (dayCell) => <Cell dayCell={dayCell} periods={periods} />,
      dayHeaderFormat: { weekday: 'long' },
      eventContent: (content) => <Event content={content} />,
      fixedWeekCount: false,
      multiMonthMaxColumns: 1,
      showNonCurrentDates: true,
      titleFormat: titleFormat,
      weekNumberContent: (weekNumber) => getWeekNumberContent(weekNumber, isViewSelectEnabled, periods),
      weekNumbers: true,
      initialView: 'multiMonthYear',
      customButtons: {
        leftCustomButton: {
          text: 'Prev Year',
          click: previousYearHandler,
        },
        rightCustomButton: {
          text: 'Next Year',
          click: nextYearHandler,
        },
        reset: {
          text: 'Switch to Current Year',
          click: resetYearHandler,
        },
      },
      headerToolbar: {
        start: 'title',
        right: 'reset leftCustomButton rightCustomButton',
      },
    },
    [CalendarModes.TIMELINE]: {
      slotDuration: timelineViewConfiguration[timelineView].slotDuration,
      slotLabelClassNames: slotLabelClassNamesHandler,
      slotLabelContent: slotLabelContentHandler,
      slotLabelFormat: timelineViewConfiguration[timelineView].slotLabelFormat,
      slotMinWidth: timelineViewConfiguration[timelineView].slotMinWidth,
      initialView: 'timelineYear',
      eventClassNames: classes.event,
      customButtons: {
        leftCustomButton: {
          text: 'Prev Year',
          click: previousYearHandler,
        },
        rightCustomButton: {
          text: 'Next Year',
          click: nextYearHandler,
        },
        reset: {
          text: 'Switch to Current Year',
          click: resetYearHandler,
        },
        days: {
          text: 'days',
          click: () => setTimelineView(TIMELINE_VIEW_OPTIONS.DAYS),
        },
        weeks: {
          text: 'weeks',
          click: () => setTimelineView(TIMELINE_VIEW_OPTIONS.WEEKS),
        },
        months: {
          text: 'months',
          click: () => setTimelineView(TIMELINE_VIEW_OPTIONS.MONTHS),
        },
      },
      headerToolbar: {
        start: 'title',
        center: 'days weeks months',
        right: 'reset leftCustomButton rightCustomButton',
      },
    },
  };

  return (
    <>
      <Card>
        <Header
          calendarViewHandler={calendarViewHandler}
          isViewSelectEnabled={isViewSelectEnabled}
          marketingStartDate={retailerAccountCalendar?.retailerPeriods || ''}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          startDayOfWeek={startDayOfWeek}
          view={view}
        />
      </Card>
      <Card customClass={classes.cardContainer}>
        {isFetching ? (
          <Loader />
        ) : (
          <Box
            className={classNames(classes.calendarHeader, {
              [classes.daysDisabled]: timelineView === TIMELINE_VIEW_OPTIONS.DAYS,
              [classes.weeksDisabled]: timelineView === TIMELINE_VIEW_OPTIONS.WEEKS,
              [classes.monthsDisabled]: timelineView === TIMELINE_VIEW_OPTIONS.MONTHS,
              [classes.disabledToolbarButton]: isCurrentYear,
              [classes.popOverHidden]: isModalOpen,
            })}
          >
            <FullCalendar
              key={calendarKey + selectedTab + timelineView}
              ref={calendarRef}
              eventBorderColor={THEME.PALETTES.OTHER.White}
              eventClick={handleEventClick}
              eventTextColor={THEME.PALETTES.TEXT.Dark}
              events={getMappedCalendarData(campaignCalendar)}
              firstDay={startDayOfWeek}
              height={'100%'}
              initialDate={initialDate}
              plugins={[multimonthPlugin, timelinePlugin]}
              schedulerLicenseKey={FULL_CALENDAR_LICENSE}
              {...viewModeMapper[String(selectedTab)]}
            />
          </Box>
        )}
      </Card>
      <Modal
        content={<EventModal campaignId={eventModalCampaignId} />}
        handleCloseModal={handleCloseModal}
        modalTitle="Campaign Details"
        open={isModalOpen}
        size="small"
      />
    </>
  );
};

export default Calendar;
