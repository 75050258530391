import { THEME } from '#/constants/theme/constants';

import { makeStyles, createStyles } from '#/utils/theme';

export const useCampaignInvitationsStyles = makeStyles((theme) =>
  createStyles({
    customMuiSelect: {
      height: '1.95rem',
      borderRadius: '8px',
      fontSize: '0.813rem',
      paddingLeft: '0.125rem',
    },
    productMixWidth: {
      width: '60%',
      marginLeft: '3.125rem',
    },
    mt05: {
      marginTop: theme.custom.REM_SPACES['1'],
    },
    productMixWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.custom.REM_SPACES['1'],
    },
    breadcrumbWrapper: {
      marginTop: `${theme.custom.REM_SPACES['1.5']} 0 `,
    },
    refreshIcon: { marginRight: '0.313rem', marginBottom: '-0.125rem' },
    clearAll: {
      fontSize: '0.813rem',
      cursor: 'pointer',
    },
    stackHeight: {
      height: '100%',
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& ::-webkit-scrollbar': {
        width: '2px',
      },
      '& ::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#16191F',
        outline: '1px solid slategrey',
      },
    },
    flexWithSpace: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    buttonWrap: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      paddingLeft: theme.custom.REM_SPACES['0.5'],
    },
    buttonWrapSpace: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      paddingLeft: 0,
    },
    modalHeader: {
      background: '#232038',
      height: '4rem',
      padding: '1.25rem 1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    mb05: {
      '&&': {
        marginBottom: THEME.REM_SPACES['0.5'],
      },
    },
    mb1: {
      '&&': {
        marginBottom: THEME.REM_SPACES['1'],
      },
    },
    customLabel: {
      '&&': {
        textTransform: 'uppercase',
      },
    },
    mb0: {
      '&&': {
        marginBottom: 0,
      },
    },
    mr15: {
      '&&': {
        marginRight: theme.custom.REM_SPACES['1'],
      },
    },
    fixWithNegativeMargin: {
      '&&': {
        marginBottom: '-0.938rem',
      },
    },
    modalTitle: { color: '#FFF', display: 'flex', columnGap: '8px' },
    modalClose: {},
    modalWrapper: {
      width: '100%',
      display: 'flex',
      flexGrow: 2,
      flexDirection: 'column',
    },
    invitationBodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '16px',
      width: '100%',
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    modalTextHeader: {
      color: '#16191F',
      fontSize: '20px',
      marginBottom: '16px',
      '&.MuiTypography-root': {
        fontWeight: 'bold',
        marginBottom: '8px',
      },
    },
    modalTextSubHeader: {
      color: '#606670',
      marginBottom: '16px',
    },
    stepperContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      height: '1.5rem',
      marginTop: '1rem',
    },
    stepper: {
      width: '700px',
    },
    formContainer: {
      height: '100%',
      width: '100%',
      background: 'white',
      overflowY: 'auto',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
      columnGap: '16px',
    },
    brandTableHeader: {
      background: '#F0F4F9',
      '& th:first-child': {
        borderRight: 'none',
        width: '2.5rem',
      },
      '& th:nth-child(2)': {
        borderLeft: 'none',
      },
    },
    brandTableBody: {
      overflowY: 'auto',
      maxHeight: '500px',
    },
    brandTableCell: {
      '&:first-child': {
        borderRight: 'none',
      },
      '&:nth-child(2)': {
        borderLeft: 'none',
        fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
      },
    },
    brandFilterSelect: {
      minWidth: '150px',
      height: '30px',
    },

    chipItem: {
      '&.MuiChip-root': {
        background: '#D8EDFA',
        marginRight: '0.5rem',
        marginTop: '0.5rem',
      },
    },
    subjectLineField: {
      '& .MuiInputBase-root': {
        height: '30px',
      },
    },
    editorWrapper: {
      border: '1px solid #E4E8EF',
      borderRadius: '8px',
      padding: '16px',
    },
    editor: {
      height: '200px',
      overflowY: 'visible',
    },
    reviewPageContainer: {
      display: 'flex',
      columnGap: '1rem',
      padding: '16px',
      flexGrow: 2,
    },
    reviewPageContainerRight: {
      border: '1px solid #E4E8EF',
      padding: '16px',
      borderRadius: '16px',
      width: '100%',
    },
    submitButton: {
      '&.MuiButton-root': {
        background: '#5846CB ',
        borderRadius: '40px',
        border: '1px solid #5846CB',
        fontWeight: 'bold',
        marginRight: '8px',
        '&:hover': {
          background: '#5846CB',
          boxShadows: 'none',
        },
      },
    },
    campaignBrandListContainer: {
      border: '1px solid #E4E8EF',
      borderRadius: '1rem',
      width: '23rem',
      padding: '1rem',
    },
    campaignBrandListStack: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: THEME.REM_SPACES[0.5],
      alignItems: 'center',
    },
    campaignRecipients: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '0.5rem',
      marginBottom: THEME.REM_SPACES[1],
    },
    campaignDownloadListText: {
      color: '#1F8AC9',
    },
    marketingMaterialsContainer: {
      display: 'flex',
      columnGap: '1rem',
      flexGrow: 2,
    },
    dayAndTimeContainer: {
      display: 'flex',
      alignItems: 'baseline',
      columnGap: '16px',
    },
    messageBodyContainer: {
      // width: '1000px',
    },
    selectBrandsContainer: {
      columnGap: '1rem',
      padding: '16px',
      flexGrow: 2,
    },
    invitationDetailsContainer: {
      border: `1px solid ${THEME.PALETTES.BORDER.Light}`,
      borderRadius: '16px',
      width: '30rem',
      padding: THEME.REM_SPACES['1'],
    },
    selectBrandsOptionsContainer: {
      border: `1px solid ${THEME.PALETTES.BORDER.Light}`,
      borderRadius: '16px',
      display: 'flex',
      flexDirection: 'column',
      rowGap: '16px',
      padding: '18px',
      width: '100%',
    },
    downloadContainer: {
      cursor: 'pointer',
      width: '10rem',
      marginBottom: '0.78rem',
    },
    datePickerContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    timePicker: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    marginLeftFix: { '&&': { marginLeft: '0px' } },
  }),
);
export const marginBottom025Sx = {
  marginBottom: THEME.REM_SPACES[0.25],
};
export const marginBottom05Sx = {
  marginBottom: THEME.REM_SPACES[0.5],
};
export const marginBottom1Sx = {
  marginBottom: THEME.REM_SPACES[1],
};
export const marginTop1Sx = {
  marginTop: THEME.REM_SPACES[1],
};
export const marginTop05Sx = {
  marginTop: THEME.REM_SPACES[0.5],
};
export const greyColorTextSx = {
  color: THEME.PALETTES.TEXT.Gray,
};
