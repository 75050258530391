import TableLogoCell from '#/components/common/tableLogoCell/TableLogoCell';
import { useBrandStyles } from '#/components/features/partners/brands/brands.styles';

import { useAppDispatch, useAppSelector } from '#/hooks';

import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { ICommunicationsCompaniesResponse } from '#/store/api/communications/communication-interface';
import { communicationsPromote } from '#/store/slices';

import { convertAllCapsUnderscoreStrings } from '#/utils/utils';

import { Table } from '../../../common/table/tableComponent/Table';

const CommunicationsSelectBrandsTableSelected = () => {
  const styles = useBrandStyles();
  const { selectedBrands, selectedCampaigns } = useAppSelector((state) => state.communications);
  const dispatch = useAppDispatch();
  const selectBrandIds = useAppSelector((state) => communicationsPromote.getSelectedBrandsIds(state.communications));

  const createCustomNameImageView = (companyId: string, logoId: string | null, companyName: string) => (
    <TableLogoCell link={`/partners/brands/${companyId}`} logoId={logoId} name={companyName} />
  );

  const tableColumns: ITableColumnsProps<ICommunicationsCompaniesResponse>[] = [
    {
      fieldName: null,
      customHead: ' ',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.CHECKBOX,
      checkBoxAction: (checked: boolean, aggregateId: string) => {
        const brandItemCheck = selectedBrands.find((brand) => brand.aggregateId === aggregateId);
        brandItemCheck && dispatch(communicationsPromote.actions.updateSelectedBrands(brandItemCheck));
      },
      actionElementIdentifier: 'aggregateId',
      checkFromList: selectBrandIds,
      hasCheckAll: false,
    },
    {
      fieldName: 'companyName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Company Name ',
      actionElementIdentifier: 'aggregateId',
      actionElementSecondIdentifier: 'logoId',
      actionElementThirdIdentifier: 'companyName',
      getCustomElement: createCustomNameImageView,
    },
    {
      hide: selectedCampaigns.length > 1,
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      textFormatter: (text) => convertAllCapsUnderscoreStrings(text.toString()) || 'Not Sent',
      customHead: 'Invitation Status',
    },
    {
      hide: selectedCampaigns.length > 1,
      fieldName: 'tierName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      textFormatter: (text) => text.toString() || 'N/A',
      customHead: 'Tier Level',
    },
    {
      hide: selectedCampaigns.length > 1,
      fieldName: 'tierBudget',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      textFormatter: (text) => `$${text.toString() || '0'}`,
      customHead: 'Amount',
    },
    {
      hide: selectedCampaigns.length > 1,
      fieldName: null,
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Agency',
    },
  ];
  if (!selectedBrands) return null;
  return <Table columns={tableColumns} containerClass={styles.availableBrandsTable} data={selectedBrands} />;
};
export default CommunicationsSelectBrandsTableSelected;
