import { THEME } from '#/constants/theme/constants';

import { createStyles, makeStyles } from '#/utils/theme';

import FilterIcon from '../../../../assets/FilterIcon2.svg';

/**
 * @deprecated Create new style file instead
 */
export const useBrandStyles = makeStyles((theme) =>
  createStyles({
    name: {
      minWidth: '20rem',
    },
    lastRow: {
      marginTop: '1rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    viewGrid: {
      columnGap: THEME.REM_SPACES[3.5],
      rowGap: THEME.REM_SPACES[0.5],
    },
    customHrefColor: {
      '&& > a': {
        color: THEME.PALETTES.TEXT.Action_Secondary,
        textDecoration: 'none',
        fontWeight: THEME.FONT_WEIGHTS.BOLD,
      },
    },
    wrap: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: THEME.REM_SPACES['1'],
      justifyContent: 'space-between',
    },
    editContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'space-between',
    },
    flex: {
      display: 'flex',
    },
    displayInline: { display: 'inline' },
    autocompleteInput: {
      '&&': {
        borderRadius: '0.5rem',
        background: `url(${FilterIcon} ) 11px center no-repeat`,
        paddingLeft: '2rem!important',
        outline: 'none',
      },
    },
    legal: {
      marginLeft: THEME.REM_SPACES['2'],
      display: 'flex',
      alignItems: 'baseline',
    },
    headCellClass: {
      borderWidth: '1px',
      border: '1px solid var(--border-extra-light, #E4E8EF)',
      background: 'var(--neutral-100, #F1F4F9)',
      color: ' var(--text-dark, #16191F)',
      fontSize: '0.71875rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
    },
    mb0: {
      '&&': {
        marginBottom: '0',
      },
    },
    marginLeft: {
      marginLeft: THEME.REM_SPACES['1'],
    },
    hide: {
      display: 'none',
    },
    headClass: {
      height: '48px',
      color: ' var(--text-dark, #16191F)',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    taskHyperLink: {
      color: 'blue',
    },
    bodyCellClass: {
      height: '48px',
    },
    url: {
      color: THEME.PALETTES.TEXT.Action_Secondary,
      fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
      fontSize: '0.8125rem',
      textDecoration: ' none',
    },
    dialog: {
      '& .MuiDialog-container': {
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '800px',
        },
      },
    },
    dialogTitle: {
      '&&': {
        background: '#232038',
        marginBottom: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },
    mrImg: {
      marginRight: THEME.REM_SPACES['0.5'],
    },
    custom: {
      border: '1px solid #ccc',
      background: '#fff',
    },
    colorWhite: {
      color: '#fff',
    },
    addBrandMargin: {
      marginTop: '0.625rem',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    marginInput: {
      '&&': {
        marginBottom: '1.25rem',
      },
    },
    width33: {
      width: '33.3%',
    },
    width33WithPadding: {
      padding: '0 1.25rem',
      width: '33.3%',
    },
    width50: {
      width: '50%',
    },
    error: {
      color: theme.custom.PALETTES.TEXT.Error,
      marginTop: '1.25rem',
    },
    redColor: {
      color: theme.custom.PALETTES.TEXT.Error,
    },
    success: {
      color: 'green',
    },
    bottomWrapDiv: {
      display: 'flex',
      justifyContent: 'end',
      borderTop: '1px solid #ccc',
      paddingTop: '1.25rem',
    },
    mr20: {
      '&&': {
        marginRight: '1.25rem',
      },
    },
    mr40: {
      '&&': {
        marginRight: THEME.REM_SPACES['2.5'],
      },
    },
    mb15: {
      '&&': {
        marginBottom: '0.938rem',
      },
    },
    mb05: {
      '&&': {
        marginBottom: THEME.REM_SPACES['0.5'],
      },
    },
    addBrandModal: {
      '&&': {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
      },
    },
    radioLabel: {
      '&&': {
        fontSize: '0.8125rem',
        color: '#16191F',
      },
    },
    edit: {
      color: '#5846CB',
      fontSize: '0.75rem',
      cursor: 'pointer',
      fontWeight: 600,
      display: 'flex',
      alignItems: 'end',
    },
    flexBetween: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    singleBrandWrap: {
      width: '100%',
    },
    brandsWrap: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'start',
    },
    brandViewLabel: {
      '&&': {
        textTransform: 'uppercase',
        color: '#606670',
        fontSize: '0.71875rem',
        fontWeight: 500,
        marginBottom: '0.1rem',
      },
    },
    brandViewName: {
      '&&': { fontSize: '0.8125rem', color: '#333' },
    },
    fullWidth: {
      width: '100%',
    },
    autocompleteWidth: {
      width: '100%',
    },
    red: {
      '&&': {
        color: 'red',
      },
    },
    mt30: {
      marginTop: THEME.REM_SPACES['2'],
    },
    mt15: {
      marginTop: THEME.REM_SPACES['1.5'],
    },
    mt1: {
      marginTop: THEME.REM_SPACES['1'],
    },
    mr5: {
      marginRight: '0.313rem',
    },
    mr1: {
      marginRight: THEME.REM_SPACES['1'],
    },
    companyUrl: {
      '&&': {
        color: '#0D99FF',
        fontSize: '0.813rem',
      },
    },
    wordWrap: {
      wordWrap: 'break-word',
    },
    companyName: {
      '&&': {
        display: 'flex',
        color: '#16191F',
        fontSize: '1.25rem',
      },
    },
    image: {
      marginRight: '1rem',
    },
    mb30: {
      '&&': {
        marginBottom: '1.875rem',
      },
    },
    mb20: {
      '&&': {
        marginBottom: '1.25rem',
      },
    },
    mr: {
      marginRight: '3.5rem',
    },
    editLabelInput: {
      '&&': {
        fontSize: '0.71875rem',
        display: 'block',
        color: '#606670',
        fontWeight: 500,
        textTransform: 'uppercase',
        paddingLeft: '0.313rem',
        marginBottom: '0.2rem',
      },
    },
    line: {
      whiteSpace: 'nowrap',
      marginRight: THEME.REM_SPACES['1'],
    },
    buttonsWrap: {
      display: 'flex',
      justifyContent: 'end',
      marginBottom: '1.25rem',
    },
    editInputItem: {
      width: '20%',
      marginRight: '1.25rem',
    },
    inputFlex: {
      width: '20%',
      display: 'flex',
    },
    customBillingDiv: {
      marginRight: '2.5rem',
      width: '33%',
    },
    width60: {
      width: '60%',
    },
    associateWrap: { display: 'flex', marginBottom: '1.25rem' },
    uploadSpace: {
      marginTop: THEME.REM_SPACES['1'],
      marginRight: THEME.REM_SPACES['3'],
    },
    card: {
      background: THEME.PALETTES.TEXT.Light,
      borderRadius: '18px',
      padding: THEME.REM_SPACES['1'],
      marginBottom: THEME.REM_SPACES['1.5'],
    },
    checkboxContainer: {
      display: 'flex',
      columnGap: '0.25rem',
      alignItems: 'center',
    },
    availableBrandsTable: {
      '& tr td:first-child': {
        width: '5rem',
      },
    },
  }),
);

export const inputStyle = {
  borderRadius: '8px',
  fontSize: '0.8125rem',
  color: '#16191F',
};
export const textFieldSX = {
  marginBottom: '1.5rem',
  '&  input': {
    padding: '0.563rem 0.625rem',
  },
  '& fieldset.MuiOutlinedInput-notchedOutline': {
    border: '1px solid #E4E8EF',
  },
};
export const textFieldSelect = {
  marginBottom: '1.5rem',
  borderRadius: '8px',
  '&  input': {
    padding: '0.563rem 0.625rem',
  },
  '& fieldset.MuiOutlinedInput-notchedOutline': {
    border: '1px solid #E4E8EF',
  },
};

export const outlinedSelect = { fontSize: '12px', padding: '0.4rem 1rem' };

export const mt2Sx = {
  marginTop: THEME.REM_SPACES['2'],
};
