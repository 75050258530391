import { Box } from '@mui/material';
import classnames from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';

import { getChannelPartnerGeneralInfo } from '../../../../api/partners/channelPartner';
import { useRequest } from '../../../../hooks/useRequest';
import { ISingleCompanyItem } from '../../../../interfaces/forms/brands';
import { IChannelPartnerViewProps } from '../../../../interfaces/partners/company/company';
import Card from '../../../common/card/card';
import Loader from '../../../common/loader/loader';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import CompanyLogo from '../../../shared/companyLogo/CompanyLogo';
import { useBrandStyles } from '../brands/brands.styles';

const ChannelPartnerGeneralInfo: FC<IChannelPartnerViewProps> = ({ channelId }) => {
  const classes = useBrandStyles();
  const [data, setData] = useState({} as ISingleCompanyItem);
  const getChannelPartnerGeneral = useMemo(() => getChannelPartnerGeneralInfo(channelId), []);
  const { response, loading } = useRequest(getChannelPartnerGeneral);

  useEffect(() => {
    if (response) {
      setData(response?.data);
    }
  }, [response]);

  if (loading) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }
  const { name, companyURL, city, parent, state, streetAddress, zip, vendorId, logoId } = data;
  const composeAddress = `${zip || ''} ${streetAddress || ''} ${city || ''} ${state || ''}`;
  return (
    <Card>
      <Box className={classes.brandsWrap}>
        <TypographyWithFontFamily
          cName={classnames(classes.companyName, classes.wordWrap, classes.mb20)}
          variant="Default_Bold"
        >
          <CompanyLogo companyName={name} imageId={logoId} size="small" viewOnly />
          <span>{name}</span>
        </TypographyWithFontFamily>
      </Box>
      <Box className={classes.brandsWrap}>
        <Box className={classes.fullWidth}>
          <Box className={classes.flex}>
            <Box className={classes.mr}>
              <TypographyWithFontFamily cName={classes.brandViewLabel}>Parent Company</TypographyWithFontFamily>
              <TypographyWithFontFamily cName={classes.brandViewName}>{parent}</TypographyWithFontFamily>
            </Box>
            <Box className={classes.mr}>
              <TypographyWithFontFamily cName={classes.brandViewLabel}>Company URL</TypographyWithFontFamily>
              <TypographyWithFontFamily cName={classes.companyUrl}>{companyURL}</TypographyWithFontFamily>
            </Box>
            <Box className={classes.mr}>
              <TypographyWithFontFamily cName={classes.brandViewLabel}>Vendor ID</TypographyWithFontFamily>
              <TypographyWithFontFamily cName={classes.brandViewName}>{vendorId}</TypographyWithFontFamily>
            </Box>
            <Box className={classes.mr}>
              <TypographyWithFontFamily cName={classes.brandViewLabel}>Mailing Address</TypographyWithFontFamily>
              <TypographyWithFontFamily cName={classes.brandViewName}>{composeAddress}</TypographyWithFontFamily>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default ChannelPartnerGeneralInfo;
