import { createStyles, makeStyles } from '#/utils/theme';

export const useAssociatedBrandsStyle = makeStyles((theme) =>
  createStyles({
    flexWithSpaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.custom.REM_SPACES['1'],
    },
    headerContainer: {
      display: 'flex',
      columnGap: theme.custom.REM_SPACES['0.5'],
      alignItems: 'center',
      marginBottom: theme.custom.REM_SPACES['0.5'],
    },
    tableContainer: {
      borderCollapse: 'collapse',
      '& th:nth-last-child(2)': {
        borderRight: 'none',
      },
      '& th:last-child': {
        borderLeft: 'none',
      },
    },
  }),
);
