import { Box } from '@mui/material';

import Refetch from '../reFetchComponent/Refetch';
import { TypographyWithFontFamily } from '../typography/TypographyWithFontFamily';
import { useNoDataMessageStyles } from './NoDataMessage.style';

interface IProps {
  message: string;
  refetch?: () => void;
  dataLoading?: boolean;
  customStyle?: { [key: string]: string };
}

export const NoDataMessage = ({ message, refetch, dataLoading, customStyle }: IProps) => {
  const classes = useNoDataMessageStyles();

  return (
    <Box className={classes.wrapper} sx={{ ...(customStyle && { ...customStyle }) }}>
      <TypographyWithFontFamily variant="H5_Base">{message}</TypographyWithFontFamily>
      {refetch && <Refetch isLoading={!!dataLoading} reFetchFn={refetch} />}
    </Box>
  );
};
