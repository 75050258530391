import { createStyles, makeStyles } from '#/utils/theme';

export const useHeaderStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    optionsContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    select: {
      margin: 'auto',
      width: '15rem',
    },
    header: {
      margin: 'auto',
      marginRight: theme.custom.REM_SPACES[0.5],
      color: theme.custom.PALETTES.TEXT.Gray,
    },
  }),
);
