import { ICommunicationsFilterBrandsState } from '#/components/features/communications/modalPages/communicationsSelectBrands/CommunicationsFilterBrands';
import { ICommunicationsFilterRecipientState } from '#/components/features/communications/modalPages/communicationsSelectRecipients/CommunicationsFilterRecipients';

import { ICommunicationsDTO } from '#/interfaces/communication/communication';
import { ICommunicationsRecipients } from '#/interfaces/forms/campaignInvitation';

import { SearchFilters } from '#/store/api/campaigns/campaignsInterfaces';
import {
  ICommunicationPromote,
  ICommunicationsCompaniesResponse,
  ICommunicationsSelectedCampaignsResponse,
  PromoteCompanies,
} from '#/store/api/communications/communication-interface';

import { baseApi } from '../../base';

const companyApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getGeneralCommunications: builder.query<ICommunicationsDTO, { startDate: string; endDate: string }>({
      query: ({ startDate, endDate }) =>
        `email/messages/engagement/distributed?startDate=${startDate}&endDate=${endDate}&operation=CAMPAIGN_GENERAL_ANNOUNCE`,
    }),
    getGeneralCommunicationsTableData: builder.query<ICommunicationsDTO, void>({
      query: () => `email/messages/engagement/distributed?operation=CAMPAIGN_GENERAL_ANNOUNCE`,
    }),
    getPromoteCommunications: builder.query<ICommunicationsDTO, { startDate: string; endDate: string }>({
      query: ({ startDate, endDate }) =>
        `email/messages/engagement/distributed?startDate=${startDate}&endDate=${endDate}&operation=CAMPAIGN_PROMOTE`,
    }),
    getCommunicationsPromoteCampaigns: builder.query<
      ICommunicationsSelectedCampaignsResponse[],
      { campaignIds: string[] }
    >({
      query: ({ campaignIds }) => {
        const searchParams = new URLSearchParams();
        searchParams.set('campaignIds', campaignIds.map((campaignId) => campaignId).toString());
        return `retailer/promote/selected-campaigns?${searchParams}`;
      },
    }),

    getCommunicationsCompanies: builder.query<
      PromoteCompanies<ICommunicationsCompaniesResponse>,
      {
        campaignIds: string[];
        filters?: ICommunicationsFilterBrandsState;
        searchQuery: string;
        pageNumber: number;
        numberOfRows: number;
      }
    >({
      query: ({ campaignIds, filters, searchQuery, pageNumber, numberOfRows }) => {
        const searchParams = new URLSearchParams();
        searchParams.set('campaignIds', campaignIds.map((campaignId) => campaignId).toString());
        searchParams.set('searchQuery', searchQuery);
        searchParams.set('numberOfRows', numberOfRows.toString());
        searchParams.set('pageNumber', pageNumber.toString());
        filters?.includeAccepted && searchParams.set('includeAccepted', filters?.includeAccepted.toString());
        filters && Object.keys(filters).map((key) => filters[key].length && searchParams.set(key, filters[key]));
        return `retailer/promote/companies?${searchParams}`;
      },
    }),
    getPromoteCompaniesFilter: builder.query<{ filters: SearchFilters[] }, { singleCampaign: boolean }>({
      query: ({ singleCampaign = true }) => {
        const searchParams = new URLSearchParams();
        searchParams.set('singleCampaign', String(singleCampaign));
        return `/retailer/promote/companies/filters?${searchParams}`;
      },
    }),

    getCommunicationsRecipients: builder.mutation<
      ICommunicationsRecipients[],
      { campaignIds: string[]; companyIds: string[]; filters?: ICommunicationsFilterRecipientState }
    >({
      query: ({ campaignIds, companyIds, filters }) => {
        const searchParams = new URLSearchParams();
        searchParams.set('campaignIds', campaignIds.map((campaignId) => campaignId).toString());
        searchParams.set('companyIds', companyIds.map((companyId) => companyId).toString());
        filters && Object.keys(filters).map((key) => filters[key].length && searchParams.set(key, filters[key]));
        return `retailer/promote/recipients?${searchParams}`;
      },
    }),

    postCommunicationsPromote: builder.mutation<void, { body: ICommunicationPromote }>({
      query: ({ body }) => ({
        url: `/retailer/promote`,
        method: 'POST',
        body: { ...body },
      }),
    }),
  }),
});

export const {
  useGetCommunicationsPromoteCampaignsQuery,
  useGetCommunicationsCompaniesQuery,
  useGetPromoteCompaniesFilterQuery,
  useGetCommunicationsRecipientsMutation,
  usePostCommunicationsPromoteMutation,
  useGetGeneralCommunicationsQuery,
  useGetPromoteCommunicationsQuery,
  useGetGeneralCommunicationsTableDataQuery,
} = companyApi;

export { companyApi };
