import { createStyles, makeStyles } from '#/utils/theme';

export const useCompanyBillingFormStyles = makeStyles((theme) =>
  createStyles({
    viewGrid: {
      columnGap: theme.custom.REM_SPACES[3.5],
      rowGap: theme.custom.REM_SPACES[0.5],
    },
    lastRow: {
      marginTop: theme.custom.REM_SPACES[1],
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  }),
);
