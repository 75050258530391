import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const useAssociatedBrandsStyle = makeStyles(() =>
  createStyles({
    associatedBrandsContainer: {
      background: THEME.PALETTES.OTHER.White,
      padding: THEME.REM_SPACES[1],
      borderRadius: THEME.REM_SPACES[1],
      marginTop: THEME.REM_SPACES['1.5'],
    },
    associatedBrandsHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: THEME.REM_SPACES['1'],
    },
  }),
);
