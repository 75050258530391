import { Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router';

import { getCampaignInstanceGeneral } from '#/api/campaign/campaignInstance';

import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';

import { useDataCy } from '#/hooks/useDataCy';

import { USER_PERMISSIONS } from '../../../../../constants/permissions/userPermissions';
import { useOverviewEditGeneral } from '../../../../../hooks/forms/campaigns/useOverviewEditGeneral';
import { useRequest } from '../../../../../hooks/useRequest';
import { useCampaignStyles } from '../../../../campaigns/campaign.style';
import { useCampaignOverviewGeneral } from '../../../../campaigns/campaignOverviewGeneral/CampaignOverviewGeneral.styles';
import { DeprecatedTopSection } from '../../../../campaigns/topSection/DeprecatedTopSection';
import DatePicker from '../../../../common/inputs/DatePicker/DatePicker';
import Loader from '../../../../common/loader/loader';

const CampaignInstanceGeneral = () => {
  const { instanceId } = useParams();
  const generateDataCy = useDataCy();
  const styles = useCampaignStyles();
  const classes = useCampaignOverviewGeneral();

  const [editMode, setEditMode] = useState(false);
  const getCampaignGeneralAction = useMemo(() => getCampaignInstanceGeneral(instanceId), [editMode]);
  const { response, loading } = useRequest(getCampaignGeneralAction);
  const { campaignName, campaignEndDate, campaignStartDate, submissionDeadline } = response?.data || {};
  //@TODO change put endpoint when available !!!
  const { onSubmit, control, reset, errors, register, setValue } = useOverviewEditGeneral(() => setEditMode(false));

  useEffect(() => {
    reset({
      campaignName: campaignName || '',
      endDate: dayjs(campaignEndDate).format('YYYY-MM-DD'),
      startDate: dayjs(campaignStartDate).format('YYYY-MM-DD'),
      submissionDeadline: dayjs(submissionDeadline).format('YYYY-MM-DD'),
    });
  }, [campaignName, campaignEndDate, campaignStartDate, submissionDeadline, editMode]);

  if (loading) return <Loader />;

  return (
    <>
      <form onSubmit={onSubmit}>
        <Box className={styles.wrapperDivOverviewGeneral} data-cy={generateDataCy('box', 'CampaignOverviewGeneral')}>
          <DeprecatedTopSection
            editMode={editMode}
            requiredPermission={USER_PERMISSIONS.CAMPAIGN_OVERVIEW_GENERAL_WRITE} //to be changed when endpoint will be available
            sectionName="General"
            setEditMode={setEditMode}
          />
          <Box className={styles.boxGrid}>
            <Box>
              <TextFieldOld
                className={classes.input}
                editMode={editMode}
                error={errors.campaignName?.message}
                label="Campaign Name"
                labelPosition="Top"
                register={register('campaignName')}
              ></TextFieldOld>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    control={control}
                    {...register('startDate')}
                    render={({ field }) => {
                      return (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            editMode={editMode}
                            error={errors.startDate?.message}
                            label="CAMPAIGN START"
                            onChange={(value) => {
                              setValue('startDate', dayjs(value).format('YYYY-MM-DD'), { shouldValidate: true });
                            }}
                            value={field.value}
                          />
                        </LocalizationProvider>
                      );
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box>
              <Box className={styles.marginBottom}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    control={control}
                    {...register('submissionDeadline')}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          editMode={editMode}
                          error={errors.submissionDeadline?.message}
                          label="BRAND RESPONSE DEADLINE"
                          onChange={(value) => {
                            setValue('submissionDeadline', dayjs(value).format('YYYY-MM-DD'), { shouldValidate: true });
                          }}
                          value={field.value}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    control={control}
                    {...register('endDate')}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          editMode={editMode}
                          error={errors.endDate?.message}
                          label="CAMPAIGN END"
                          onChange={(value) => {
                            setValue('endDate', dayjs(value).format('YYYY-MM-DD'), { shouldValidate: true });
                          }}
                          value={field.value}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};
export default CampaignInstanceGeneral;
