import { CampaignsBrandContainer } from '#/components/features/campaigns/Campaigns/CampaignsBrandContainer';
import { CampaignsChannelPartnerContainer } from '#/components/features/campaigns/Campaigns/CampaignsChannelPartnerContainer';
import { CampaignsRetailerContainer } from '#/components/features/campaigns/Campaigns/CampaignsRetailerContainer';

import UserTypeWrapper from '#/utils/UserTypeWrapper';

export const Campaigns = () => (
  <UserTypeWrapper
    agency={<CampaignsBrandContainer />}
    brand={<CampaignsBrandContainer />}
    channel-partner={<CampaignsChannelPartnerContainer />}
    retailer={<CampaignsRetailerContainer />}
  />
);
