import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import { downloadAllAssets } from '#/api/campaign/campaignInstance';
import CampaignAssetsForm from '#/components/campaigns/campaignAssets/CampaignAssetsForm';
import DownloadTraffickingSheet from '#/components/campaigns/campaignAssets/DownloadTraffickingSheet';
import TraffickingSheetLink from '#/components/campaigns/campaignAssets/TraffickingSheetLink';
import Loader from '#/components/common/loader/loader';

import useGetResource from '#/hooks/useGetResource';

import { useGetInstanceTacticsQuery } from '#/store/api/campaigns/tacticAssetForm';

import { THEME } from '../../../constants/theme/constants';
import NewButton from '../../common/button/NewButton';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import { useTacticsAssetsStyle } from './TacticsAssets.style';
import { UsersType } from '#/constants/usersRoles/usersRole';
import { useAppSelector } from '#/hooks';

interface TacticsAssetsProps {
  selectedTier: string;
  setDisableButton: React.Dispatch<React.SetStateAction<boolean>>;
}

const TacticsAssets: React.FC<TacticsAssetsProps> = ({ selectedTier, setDisableButton }) => {
  const classes = useTacticsAssetsStyle();
  const { resourceId } = useGetResource();
  const { user: userData } = useAppSelector((state) => state.user);
  const { data: tactics, isLoading: isTacticsLoading } = useGetInstanceTacticsQuery({ id: resourceId });

  const [formStatusByTier, setFormStatusByTier] = useState<Record<string, boolean[]>>({});

  const onDownloadAll = async (instanceId: string | undefined) => {
    const { headers, data } = await downloadAllAssets(instanceId);
    const name = headers['content-disposition']?.match(/filename=([^"]+)/)?.[1] || 'assets.zip';
    const link = document.createElement('a');
    link.href = URL.createObjectURL(data);
    link.download = name;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const onClickDownloadAll = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onDownloadAll(resourceId);
  };

  const updateFormStatus = (tierId: string, index: number, isValid: boolean) => {
    setFormStatusByTier((prevStatus) => {
      const newStatusByTier = { ...prevStatus };
      if (!newStatusByTier[tierId]) {
        newStatusByTier[tierId] = [];
      }
      newStatusByTier[tierId][index] = isValid;

      if (tierId === selectedTier) {
        const allSelectedTierFormsValid = newStatusByTier[tierId].every((status) => status === true);
        setDisableButton(!allSelectedTierFormsValid);
      }

      return newStatusByTier;
    });
  };

  useEffect(() => {
    if (tactics) {
      const initialStatusByTier = tactics.reduce(
        (acc, tactic) => {
          if (!acc[tactic.tierId]) {
            acc[tactic.tierId] = [];
          }
          acc[tactic.tierId].push(false);
          return acc;
        },
        {} as Record<string, boolean[]>,
      );
      setFormStatusByTier(initialStatusByTier);
    }
  }, [tactics]);

  useEffect(() => {
    if (formStatusByTier[selectedTier]) {
      const allSelectedTierFormsValid = formStatusByTier[selectedTier].every((status) => status === true);
      setDisableButton(!allSelectedTierFormsValid);
    }
  }, [formStatusByTier, selectedTier, setDisableButton]);

  if (isTacticsLoading) {
    return <Loader />;
  }

  if (!tactics) {
    return <></>;
  }

  const filteredTactics =
    selectedTier === 'none' ? tactics : tactics.filter((tactic) => tactic.tierId === selectedTier);
  if (!selectedTier) {
    return (
      <Box className={classes.tacticsAssetsContainer}>
        <TypographyWithFontFamily>No Asset Tactics</TypographyWithFontFamily>
      </Box>
    );
  }

  return (
    <Box className={classes.tacticsAssetsContainer}>
      <Box className={classes.tacticsHeaderContainer}>
        <TypographyWithFontFamily color={THEME.PALETTES.UI.Neutral['900']} variant="H4_Base">
          Asset Forms
        </TypographyWithFontFamily>
        {userData?.userType !== UsersType.AGENCY && userData?.userType !== UsersType.BRAND && (
          <Box className={classes.approveButtonContainer}>
            <TraffickingSheetLink />
            <DownloadTraffickingSheet />
            <NewButton onClick={onClickDownloadAll} text="Download Files" variant="Secondary" />
          </Box>
        )}
      </Box>
      {filteredTactics.length > 0 ? (
        filteredTactics.map((tactic, index) => (
          <CampaignAssetsForm
            key={tactic.instanceTacticId}
            formIndex={index}
            tactic={tactic}
            updateFormStatus={(index: number, isValid: boolean) => updateFormStatus(tactic.tierId, index, isValid)}
          />
        ))
      ) : (
        <TypographyWithFontFamily>No Asset Tactics</TypographyWithFontFamily>
      )}
    </Box>
  );
};

export default TacticsAssets;
