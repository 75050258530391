import { Box, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';

import { useGetCompanyBrandsQuery } from '#/store/api/company/company';

import { CompanyType } from '../../constants/companyType/CompanyType';
import { UsStates } from '../../constants/statesPlaceholder';
import { THEME } from '../../constants/theme/constants';
import { useCreateCompany } from '../../hooks/forms/companies/useCreateCompany';
import NewButton from '../common/button/NewButton';
import Checkbox from '../common/inputs/Checkbox/Checkbox';
import Select from '../common/inputs/Select/Select';
import { TypographyWithFontFamily } from '../common/typography/TypographyWithFontFamily';
import CompanyLogo from '../shared/companyLogo/CompanyLogo';
import { useAddUserWizardStyle, userInfoHeaderSx } from './AddUserWizard.style';

interface ICreateCompanyProps {
  onClose: () => void;
}

const CreateCompany = ({ onClose }: ICreateCompanyProps) => {
  const { register, onSubmit, control, setValue, watch, errors } = useCreateCompany(onClose);
  const { data: companyOptionsData } = useGetCompanyBrandsQuery();

  const [userImageId, setUserImageId] = useState('');
  useEffect(() => {
    setValue('logoId', userImageId);
  }, [userImageId]);
  const classes = useAddUserWizardStyle();
  return (
    <form onSubmit={onSubmit}>
      <Box>
        <TypographyWithFontFamily sx={userInfoHeaderSx} variant="H3_Base">
          Create Company
        </TypographyWithFontFamily>
        <Grid container spacing={THEME.REM_SPACES[0.5]}>
          <Grid item xs={2}>
            <Controller
              control={control}
              name={'billingState'}
              render={({ field: { onChange, value } }) => (
                <Select
                  defaultValue={CompanyType.AGENCY}
                  label="Company Type *"
                  onChange={onChange}
                  options={[
                    { label: 'BRAND', value: CompanyType.BRAND },
                    { label: 'AGENCY', value: CompanyType.AGENCY },
                  ]}
                  useRegister
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldOld editMode error={errors.name?.message} label="Company Name *" register={register('name')} />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={'parentId'}
              render={({ field: { onChange, value } }) => (
                <Select
                  defaultValue=""
                  label="Parent Company"
                  onChange={onChange}
                  options={(companyOptionsData ?? []).map((company) => ({
                    label: company.companyName,
                    value: company.companyId,
                  }))}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldOld
              editMode
              error={errors.companyUrl?.message}
              label="Company URL"
              register={register('companyUrl')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldOld editMode error={errors.ein?.message} label="Tax ID" register={register('ein')} />
          </Grid>
          <Grid item xs={4}>
            <TextFieldOld
              editMode
              error={errors.billingStreetAddress1?.message}
              label="Billing Address Line 1"
              register={register('billingStreetAddress1')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldOld
              editMode
              error={errors.billingStreetAddress2?.message}
              label="Billing Address Line 2"
              register={register('billingStreetAddress2')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldOld
              editMode
              error={errors.billingCity?.message}
              label="Billing City"
              register={register('billingCity')}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={'billingState'}
              render={({ field: { onChange, value } }) => (
                <Select
                  defaultValue=""
                  label="Mailing State"
                  onChange={onChange}
                  options={UsStates.map((state) => ({ label: state.label, value: state.code }))}
                  useRegister
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldOld
              editMode
              error={errors.billingCountry?.message}
              label="Billing Country"
              register={register('billingCountry')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldOld
              editMode
              error={errors.billingZip?.message}
              label="Billing Zip Code"
              register={register('billingZip')}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="isBillingSameAsMailing"
              render={({ field }) => (
                <Checkbox label="Is Mailing Same As Billing" onChange={field.onChange} value={field.value} />
              )}
            />
          </Grid>
          {!watch('isBillingSameAsMailing') && (
            <>
              <Grid item xs={4}>
                <TextFieldOld
                  editMode={!watch('isBillingSameAsMailing')}
                  error={errors.mailingStreetAddress1?.message}
                  label="Mailing Address Line 1"
                  register={register('mailingStreetAddress1')}
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldOld
                  editMode={!watch('isBillingSameAsMailing')}
                  error={errors.mailingStreetAddress2?.message}
                  label="Mailing Address Line 2"
                  register={register('mailingStreetAddress2')}
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldOld
                  editMode={!watch('isBillingSameAsMailing')}
                  error={errors.mailingCity?.message}
                  label="Mailing City"
                  register={register('mailingCity')}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  control={control}
                  name={'mailingState'}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      defaultValue=""
                      label="Mailing State"
                      onChange={onChange}
                      options={UsStates.map((state) => ({ label: state.label, value: state.code }))}
                      useRegister
                      value={value}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldOld
                  editMode={!watch('isBillingSameAsMailing')}
                  error={errors.mailingCountry?.message}
                  label="Mailing Country"
                  register={register('mailingCountry')}
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldOld
                  editMode={!watch('isBillingSameAsMailing')}
                  error={errors.mailingZip?.message}
                  label="Mailing Zip Code"
                  register={register('mailingZip')}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <TypographyWithFontFamily variant="Label_Base">Bill Type</TypographyWithFontFamily>
            <Controller
              control={control}
              name="billType"
              render={({ field }) => (
                <RadioGroup {...field} row>
                  <FormControlLabel control={<Radio />} label="BILLBACK" value={'BILL_BACK'} />
                  <FormControlLabel control={<Radio />} label="DIRECT BILLING" value={'DIRECT_BILLING'} />
                </RadioGroup>
              )}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TypographyWithFontFamily variant="Label_Base">Logo</TypographyWithFontFamily>
            <Box className={classes.uploadContainer}>
              <CompanyLogo imageId={watch('logoId')} setUserImageId={setUserImageId} />
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.companyButtonsContainer}>
          <NewButton text="Add" type="submit" variant="Primary" />
          <NewButton onClick={onClose} text="Cancel" variant="Secondary" />
        </Box>
      </Box>
    </form>
  );
};

export default CreateCompany;
