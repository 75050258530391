import { Box } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';

import Card from '#/components/common/card/card';
import Loader from '#/components/common/loader/loader';
import { Table } from '#/components/common/table/tableComponent/Table';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';

import useGetResource from '#/hooks/useGetResource';

import { ITableColumnsProps as ITableColumnProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { IEngagementStatsData } from '#/store/api/campaigns/campaignEngagementInterfaces';
import { useGetCampaignEngagementQuery } from '#/store/api/campaigns/campaignEngagementStats';

const EngagementStats = () => {
  const resource = useGetResource();
  const { data, isLoading } = useGetCampaignEngagementQuery({ resource });
  const formatNumberToStringOrZero = (text: string | number) => text?.toString() || '0';

  const tableColumns: ITableColumnProps<IEngagementStatsData>[] = [
    {
      fieldName: 'brandName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Brand',
    },
    {
      fieldName: 'lastSendDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Last Send Date',
      textFormatter: (date) => dayjs(date).format('YYYY-MM-DD'),
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Status',
    },

    {
      fieldName: 'delivered',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.STRING,
      customHead: 'Delivered',
      textFormatter: formatNumberToStringOrZero,
    },
    {
      fieldName: 'opened',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.STRING,
      customHead: 'Opened',
      textFormatter: formatNumberToStringOrZero,
    },
    {
      fieldName: 'clicked',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.STRING,
      customHead: 'Clicked',
      textFormatter: formatNumberToStringOrZero,
    },
    {
      fieldName: 'bounced',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.STRING,
      customHead: 'Bounced',
      textFormatter: formatNumberToStringOrZero,
    },

    {
      fieldName: 'complained',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.STRING,
      customHead: 'Complained',
      textFormatter: formatNumberToStringOrZero,
    },
    {
      fieldName: 'unsubscribed',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.STRING,
      customHead: 'Unsubscribed',
      textFormatter: formatNumberToStringOrZero,
    },
  ];
  return (
    <Card>
      <TypographyWithFontFamily gutterBottom variant={'H4_Base'}>
        Engagement Stats
      </TypographyWithFontFamily>
      {isLoading ? (
        <Loader />
      ) : (
        <Box>
          {data ? (
            <Table columns={tableColumns} data={data} />
          ) : (
            <TypographyWithFontFamily variant={'Default_Bold'}>No Engagement Stats</TypographyWithFontFamily>
          )}
        </Box>
      )}
    </Card>
  );
};

export default EngagementStats;
