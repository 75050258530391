import { Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { flow } from 'fp-ts/function';
import React, { FC } from 'react';
import { Control, Controller, FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { IAddDetailsToTierForm } from '#/components/campaigns/addDetailsToSpecificTier';
import DatePicker from '#/components/common/inputs/DatePicker/DatePicker';
import TextField from '#/components/common/inputs/TextField/TextField';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';

import { IChannelAndTacticsResponseTactic } from '#/interfaces/forms/campaigns';

import { IEditDetails } from '#/store/api/campaigns/campaignsInterfaces';

import { createFormatterWithReverse, extractNumber, numberFormatter } from '#/utils/textFormatters';

interface IAddDetailsOverviewProps {
  setValue: UseFormSetValue<IAddDetailsToTierForm>;
  errors: FieldErrors<IAddDetailsToTierForm>;
  register: UseFormRegister<IAddDetailsToTierForm>;
  control: Control<IAddDetailsToTierForm, unknown>;
  detailsData: IEditDetails;
  selectedTactic: IChannelAndTacticsResponseTactic;
  watch: UseFormWatch<IAddDetailsToTierForm>;
  editMode?: boolean;
}
const AddDetailsOverview: FC<IAddDetailsOverviewProps> = ({
  selectedTactic,
  setValue,
  errors,
  register,
  control,
  detailsData,
  watch,
  editMode = true,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <TypographyWithFontFamily variant={'Default_Bold'}>Tactic Overview</TypographyWithFontFamily>
      </Grid>
      <Grid item xs={3}>
        <TextField disabled label="Channel" value={selectedTactic.channel} />
      </Grid>
      <Grid item xs={3}>
        <TextField disabled label="Sub Channel" value={selectedTactic.subChannel} />
      </Grid>
      <Grid item xs={3}>
        <TextField disabled label="Tactic" value={selectedTactic.tactic} />
      </Grid>
      <Grid item xs={3}>
        <TextField disabled label="Channel Partner" value={detailsData?.channelPartnerName} />
      </Grid>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            control={control}
            {...register('startDate')}
            render={({ field }) => {
              return (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    editMode={editMode}
                    error={errors.startDate?.message}
                    label="START DATE"
                    onChange={(value) => {
                      setValue('startDate', dayjs(value).format('YYYY-MM-DD'), {
                        shouldValidate: true,
                      });
                    }}
                    value={field.value}
                  />
                </LocalizationProvider>
              );
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            control={control}
            {...register('endDate')}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  editMode={editMode}
                  error={errors.startDate?.message}
                  label="END  DATE"
                  onChange={(value) => {
                    setValue('endDate', dayjs(value).format('YYYY-MM-DD'), {
                      shouldValidate: true,
                    });
                  }}
                  value={field.value}
                />
              </LocalizationProvider>
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3}>
        {/*//TODO: maybe change with a generic number TextField component*/}
        <TextField
          disabled={!editMode}
          formatters={{
            preview: (v) =>
              numberFormatter(v, {
                maximumFractionDigits: 2,
              }),
            typing: createFormatterWithReverse(
              flow(extractNumber, (v) =>
                numberFormatter(v, {
                  maximumFractionDigits: 2,
                }),
              ),
              extractNumber,
            ),
          }}
          label="Asset Lead Time In Days"
          onChange={(e) => setValue('leadTimeInDays', parseInt(e.target.value))}
          value={watch('leadTimeInDays')?.toString()}
        />
      </Grid>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            control={control}
            {...register('creativeDueDate')}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  editMode={editMode}
                  error={errors.startDate?.message}
                  label="Creative Due Date"
                  onChange={(value) => {
                    setValue('creativeDueDate', dayjs(value).format('YYYY-MM-DD'), {
                      shouldValidate: true,
                    });
                  }}
                  value={field.value}
                />
              </LocalizationProvider>
            )}
          />
        </LocalizationProvider>
      </Grid>
    </>
  );
};

export default AddDetailsOverview;
