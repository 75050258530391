import { Box, FormControl, FormHelperText, Grid } from '@mui/material';
import classnames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import AlertError from '#/components/common/Alert/AlertError/AlertError';
import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';
import Loader from '#/components/common/loader/loader';
import { UsStates } from '#/constants/statesPlaceholder';
import { THEME } from '#/constants/theme/constants';

import { useAddNewBrandForm } from '#/hooks/forms/brands/useAddBrandForm';

import { IAddBrandFormProps } from '#/interfaces/forms/brands';

import { useGetCompanyBrandsQuery } from '#/store/api/company/company';

import { useBrandStyles } from '../../../../features/partners/brands/brands.styles';
import CompanyLogo from '../../../../shared/companyLogo/CompanyLogo';
import NewButton from '../../../button/NewButton';
import Checkbox from '../../../inputs/Checkbox/Checkbox';
import RadioGroup from '../../../inputs/RadioGroup/RadioGroup';
import Select from '../../../inputs/Select/Select';
import { modalBottomButtons } from '../../../modal/Modal.styles';
import { TypographyWithFontFamily } from '../../../typography/TypographyWithFontFamily';
import { styles } from './AddNewCompanyForm.styles';

const AddNewCompanyForm: FC<IAddBrandFormProps> = ({ handleOpenModal }) => {
  const { data: companyOptionsData, isLoading: brandOptionsIsLoading } = useGetCompanyBrandsQuery();
  const {
    onSubmit,
    watch,
    successfulCreated,
    errorMessage,
    errors,
    isLoading,
    setValue: registerValue,
    register,
    control,
    error,
  } = useAddNewBrandForm();
  const [value, setValue] = useState('');
  const [userImageId, setUserImageId] = useState('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const classes = useBrandStyles();

  useEffect(() => {
    if (successfulCreated) {
      handleOpenModal();
    }
  }, [successfulCreated]);

  useEffect(() => {
    registerValue('logoId', userImageId);
  }, [userImageId]);
  if (brandOptionsIsLoading) {
    return <Loader />;
  }
  return (
    <form onSubmit={onSubmit}>
      <AlertError error={error} />
      <Select
        className={classes.mb05}
        defaultValue=""
        label="COMPANY TYPE"
        options={[
          { label: 'AGENCY', value: 'AGENCY' },
          { label: 'BRAND', value: 'BRAND' },
        ]}
        register={register('companyType')}
        useRegister
      />
      {errors.companyType && (
        <TypographyWithFontFamily className={classes.redColor}>{errors.companyType.message}</TypographyWithFontFamily>
      )}
      <TextFieldOld
        className={classes.mb05}
        editMode
        error={errors.name?.message}
        label="COMPANY NAME *"
        register={register('name')}
      />
      {watch('companyType') === 'BRAND' && (
        <Controller
          control={control}
          name={'parentId'}
          render={({ field: { onChange, value } }) => (
            <Select
              displayEmpty
              label="Parent Company"
              onChange={onChange}
              options={[
                { label: '--None--', value: '' },
                ...(companyOptionsData ?? []).map((company) => ({
                  label: company.companyName,
                  value: company.companyId,
                })),
              ]}
              value={value || ''}
            />
          )}
        />
      )}
      <TextFieldOld
        className={classes.mb05}
        editMode
        error={errors.legalName?.message}
        label="Legal Name"
        register={register('legalName')}
      />
      <TextFieldOld
        className={classes.mb05}
        editMode
        error={errors.companyUrl?.message}
        label="COMPANY URL"
        register={register('companyUrl')}
      />
      <Grid columnSpacing={3} container rowSpacing={1}>
        <Grid item xs={4}>
          <TextFieldOld
            editMode
            error={errors.billingStreetAddress1?.message}
            label="Billing Address Line 1"
            register={register('billingStreetAddress1')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldOld
            editMode
            error={errors.billingStreetAddress2?.message}
            label="Billing Address Line 2"
            register={register('billingStreetAddress2')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldOld
            editMode
            error={errors.billingCity?.message}
            label="Billing City"
            register={register('billingCity')}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name={'billingState'}
            render={({ field: { onChange, value } }) => (
              <Select
                defaultValue=""
                label="Billing State"
                onChange={onChange}
                options={UsStates.map((state) => ({ label: state.label, value: state.code }))}
                register={register('billingState')}
                useRegister
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldOld
            editMode
            error={errors.billingCountry?.message}
            label="Billing Country"
            register={register('billingCountry')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldOld
            editMode
            error={errors.billingZip?.message}
            label="Billing Zip Code"
            register={register('billingZip')}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="isBillingSameAsMailing"
            render={({ field }) => (
              <Checkbox label="Is Mailing Same As Billing" onChange={field.onChange} value={field.value} />
            )}
          />
        </Grid>
        {!watch('isBillingSameAsMailing') && (
          <>
            <Grid item xs={4}>
              <TextFieldOld
                editMode={!watch('isBillingSameAsMailing')}
                error={errors.mailingStreetAddress1?.message}
                label="Mailing Address Line 1"
                register={register('mailingStreetAddress1')}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldOld
                editMode={!watch('isBillingSameAsMailing')}
                error={errors.mailingStreetAddress2?.message}
                label="Mailing Address Line 2"
                register={register('mailingStreetAddress2')}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldOld
                editMode={!watch('isBillingSameAsMailing')}
                error={errors.mailingCity?.message}
                label="Mailing City"
                register={register('mailingCity')}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name={'mailingState'}
                render={({ field: { onChange, value } }) => (
                  <Select
                    defaultValue=""
                    label="Mailing State"
                    onChange={onChange}
                    options={UsStates.map((state) => ({ label: state.label, value: state.code }))}
                    register={register('mailingState')}
                    useRegister
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldOld
                editMode={!watch('isBillingSameAsMailing')}
                error={errors.mailingCountry?.message}
                label="Mailing Country"
                register={register('mailingCountry')}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldOld
                editMode={!watch('isBillingSameAsMailing')}
                error={errors.mailingZip?.message}
                label="Mailing Zip Code"
                register={register('mailingZip')}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Box className={classes.flex}>
        <Box className={classnames(classes.width50, classes.mr40)}>
          <TextFieldOld editMode error={errors.ein?.message} label="TAX ID" register={register('ein')} />
        </Box>
        <Box className={classes.width50}>
          <TextFieldOld editMode error={errors.vendorId?.message} label="Vendor ID" register={register('vendorId')} />
        </Box>
      </Box>
      <Box className={classes.flexCenter}>
        <Box className={classes.uploadSpace}>
          <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} variant="Label_Base">
            UPLOAD COMPANY LOGO
          </TypographyWithFontFamily>
          <CompanyLogo imageId={watch('logoId')} setUserImageId={setUserImageId} />
          <FormHelperText>
            <TypographyWithFontFamily>{errors?.logoId?.message}</TypographyWithFontFamily>
          </FormHelperText>
        </Box>
        <FormControl className={classes.mt1}>
          <RadioGroup
            label="BILLING"
            onChange={handleChange}
            options={[
              { label: 'BillBack', value: 'BILL_BACK' },
              { label: 'Direct Billing', value: 'DIRECT_BILLING' },
            ]}
            register={register}
            registerParam="billType"
            value={value}
          />
        </FormControl>
        <FormHelperText>
          <TypographyWithFontFamily>{errors?.billType?.message}</TypographyWithFontFamily>
        </FormHelperText>
      </Box>
      <Box className={classes.error}>{errorMessage}</Box>
      <Box className={classes.success}>
        {successfulCreated && !errorMessage && <span>Company successfully added.</span>}
      </Box>
      <Box className={classes.bottomWrapDiv} sx={modalBottomButtons}>
        <NewButton onClick={handleOpenModal} sx={styles.cancelButton} text="Cancel" variant="Secondary" />
        <NewButton loading={isLoading} text="Add Company" type="submit" variant="Primary" />
      </Box>
    </form>
  );
};
export default AddNewCompanyForm;
