import { Box } from '@mui/material';
import classNames from 'classnames';
import { useEffect } from 'react';
import { FieldErrors, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';

import Select from '#/components/common/inputs/Select/Select';
import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';
import { useDynamicTaskActionListStyles } from '#/components/features/dynamicRibbon/dynamicComponents/DynamicTaskActionList.styles';

import { IDynamicTaskActions } from '#/interfaces/dynamicRibbon/dynamicRibbon';
import { ISubmitCampaignDynamicRibbon } from '#/interfaces/forms/campaigns';

import { capitalizeFirstLetter } from '#/utils/capitalizeFirstLetter';

import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';

const DynamicTaskActionList = ({
  component,
  setValue,
  watch,
  trigger,
  errors,
}: {
  component: IDynamicTaskActions;
  setValue: UseFormSetValue<ISubmitCampaignDynamicRibbon>;
  watch: UseFormWatch<ISubmitCampaignDynamicRibbon>;
  trigger: UseFormTrigger<ISubmitCampaignDynamicRibbon>;
  errors: FieldErrors<ISubmitCampaignDynamicRibbon>;
}) => {
  useEffect(() => {
    component.content.forEach((task, index) => {
      setValue(`taskActions.${index}.taskId`, task.taskId);
    });
  }, []);

  const classes = useDynamicTaskActionListStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.headerContainer}>
        <TypographyWithFontFamily className={classes.headerCell20} variant="Default_Bold">
          Task Name
        </TypographyWithFontFamily>
        <TypographyWithFontFamily className={classes.headerCell20} variant="Default_Bold">
          Target Object
        </TypographyWithFontFamily>
        <TypographyWithFontFamily className={classes.headerCell20} variant="Default_Bold">
          Description
        </TypographyWithFontFamily>
        <TypographyWithFontFamily className={classes.headerCell25} variant="Default_Bold">
          Action
        </TypographyWithFontFamily>
        <TypographyWithFontFamily className={classes.headerCell35} variant="Default_Bold">
          Comment
        </TypographyWithFontFamily>
      </Box>
      {component.content.map((task, index) => {
        return (
          <Box
            key={task.taskId}
            className={classNames(classes.tableContainer, {
              [classes.tableContainerBackgroundEven]: index % 2 === 0,
              [classes.tableContainerBackgroundOdd]: index % 2 === 1,
            })}
          >
            <TypographyWithFontFamily className={classes.tableCell20}>{task.taskName}</TypographyWithFontFamily>
            <TypographyWithFontFamily className={classes.tableCell20}>{task.targetObject}</TypographyWithFontFamily>
            <TypographyWithFontFamily className={classes.tableCell20}>{task.description}</TypographyWithFontFamily>
            <Select
              className={classes.select}
              editMode
              label=""
              onChange={(event) => {
                setValue(`taskActions.${index}.action`, event.target.value);
                trigger(`taskActions.${index}.comment`);
              }}
              options={task.taskActions.map((el) => ({
                label: capitalizeFirstLetter(el).replace('_', ' '),
                value: el,
              }))}
              value={watch(`taskActions.${index}.action`)}
            />
            <TextFieldOld
              className={classes.input}
              editMode
              error={errors?.taskActions?.[index]?.comment?.message}
              label=""
              onChange={(event) => {
                setValue(`taskActions.${index}.comment`, event.target.value);
                trigger(`taskActions.${index}.comment`);
              }}
              useRegister={false}
              value={watch(`taskActions.${index}.comment`)}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default DynamicTaskActionList;
