import { Filter } from '#/components/common/SearchFilters/SearchFilters.types';

import { SearchFilters } from '#/store/api/campaigns/campaignsInterfaces';

const isFilterInvalid = (filter: Filter, filtersData: SearchFilters[]) => {
  const columnNameData = filtersData.find((el) => el.columnName === filter.key);
  const hasOperations = columnNameData?.operations;
  const isSupportedOperation = hasOperations
    ? columnNameData?.operations?.some((el) => el.value === filter?.operation)
    : true;

  const isError = Boolean(
    columnNameData?.displayName === undefined || filter.value === undefined || !isSupportedOperation,
  );

  return isError;
};

export default isFilterInvalid;
