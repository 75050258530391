import UserTypeWrapper from '#/utils/UserTypeWrapper';

import CampaignChannelsAndTacticsBrand from './BrandView/CampaignChannelsAndTacticsBrand';
import ChannelPartnerChannelAndTactics from './ChannelPartnerView/ChannelPartnerChannelAndTactics';
import CampaignChannelsAndTactics from './RetailerView/CampaignChannelsAndTactics';

export const ChannelsAndTactics = () => {
  return (
    <div>
      <UserTypeWrapper
        agency={<CampaignChannelsAndTacticsBrand />}
        brand={<CampaignChannelsAndTacticsBrand />}
        channel-partner={<ChannelPartnerChannelAndTactics />}
        retailer={<CampaignChannelsAndTactics />}
      />
    </div>
  );
};
