import { SelectChangeEvent } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';

export type CalendarViewTypes = 'Marketing' | 'Standard';
export enum WEEKDAY_MAPPER {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

const useCalendarYear = (isViewSelectEnabled = false, retailerPeriods = 'SUNDAY') => {
  const [year, setYear] = useState(dayjs().year());
  const nextYearHandler = () => setYear(year + 1);
  const previousYearHandler = () => setYear(year - 1);
  const resetYearHandler = () => setYear(dayjs().year());

  const [view, setView] = useState<CalendarViewTypes>(isViewSelectEnabled ? 'Marketing' : 'Standard');
  const SUNDAY_FIRST_DAY_OF_WEEK = 0;
  const startDayOfWeek = view === 'Standard' ? SUNDAY_FIRST_DAY_OF_WEEK : WEEKDAY_MAPPER[retailerPeriods];

  const isCurrentYear = year === dayjs().year();
  const currentMonthThisYear = dayjs().toDate();
  const firstMonthOfSelectedYear = dayjs().set('year', year).set('month', 0).toDate();
  const initialDate = isCurrentYear ? currentMonthThisYear : firstMonthOfSelectedYear;

  const titleFormat = () => `${year.toString()} `;
  const calendarViewHandler = (event: SelectChangeEvent) => setView(event.target.value as CalendarViewTypes);

  const calendarQueryParams = {
    startDate: `${year}-01-01`,
    endDate: `${year}-12-31`,
  };

  const calendarKey = `${year}-${view}`;

  return {
    initialDate,
    startDayOfWeek,
    isCurrentYear,
    view,
    calendarQueryParams,
    calendarKey,
    calendarViewHandler,
    titleFormat,
    nextYearHandler,
    previousYearHandler,
    resetYearHandler,
  };
};

export default useCalendarYear;
