import Loader from '#/components/common/loader/loader';
import { BrandOrdersBrand } from '#/components/features/campaigns/BrandOrders/BrandOrdersBrand/BrandOrdersBrand';

import { useGetBrandOrdersBrandFilterQuery } from '#/store/api/campaigns/campaigns';

export const BrandOrdersBrandContainer = () => {
  const { isLoading: filterDataIsLoading } = useGetBrandOrdersBrandFilterQuery({});

  if (filterDataIsLoading) return <Loader />;

  return <BrandOrdersBrand />;
};
