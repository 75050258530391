import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { EditorState } from 'draft-js';

import { ICommunicationsRecipients } from '#/interfaces/forms/campaignInvitation';
import { ICommunicationPromoteSlice } from '#/interfaces/store/store';

import { ICommunicationsCompaniesResponse } from '#/store/api/communications/communication-interface';

import { updateCommunicationStateSelected } from '#/utils/utils';

interface ISelectedItemsState {
  itemId: string;
  updateFor: string;
}
interface IMarketingState {
  itemKey: string | EditorState | undefined;
  updateFor: string;
}

const initialState: ICommunicationPromoteSlice = {
  selectedCampaigns: [],
  selectedBrands: [],
  selectedRecipients: [],
  message: EditorState.createEmpty(),
  day: dayjs().toString(),
  time: dayjs().toString(),
  sendSchedule: 'immediately',
  filesAttachment: [],
  subjectLine: '',
};

const communicationsPromoteSlice = createSlice({
  name: 'communications',
  initialState: initialState,
  selectors: {
    getSelectedBrandsIds: (state) => {
      return state.selectedBrands.map((element) => element.aggregateId);
    },
    getSelectedRecipientsIds: (state) => {
      return state.selectedRecipients.map((element) => element.userId);
    },
    getSelectedRecipientsEmails: (state) => {
      return state.selectedRecipients.map((element) => element.email);
    },
  },
  reducers: {
    setSelectedCampaigns: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedCampaigns = payload;
    },
    updateSelectedItems: (state, { payload }: PayloadAction<ISelectedItemsState>) => {
      state[payload.updateFor] = updateCommunicationStateSelected(state[payload.updateFor], payload.itemId);
    },
    updateSelectedBrands: (state, { payload }: PayloadAction<ICommunicationsCompaniesResponse>) => {
      if (getSelectedBrandsIds(state).includes(payload.aggregateId)) {
        state.selectedBrands = state.selectedBrands.filter((item) => item.aggregateId !== payload.aggregateId);
      } else {
        state.selectedBrands.push(payload);
      }
    },
    selectAllBrands: (state, { payload }: PayloadAction<ICommunicationsCompaniesResponse[]>) => {
      state.selectedBrands = payload;
    },
    clearSelectedBrands: (state) => {
      state.selectedBrands = initialState.selectedBrands;
    },
    updateSelectedRecipients: (state, { payload }: PayloadAction<ICommunicationsRecipients>) => {
      if (
        getSelectedRecipientsIds(state).includes(payload.userId) ||
        getSelectedRecipientsEmails(state).includes(payload.email)
      ) {
        state.selectedRecipients = state.selectedRecipients.filter((item) => item.userId !== payload.userId);
      } else {
        state.selectedRecipients.push(payload);
      }
    },
    selectAllRecipients: (state, { payload }: PayloadAction<ICommunicationsRecipients[]>) => {
      state.selectedRecipients = payload;
    },
    clearSelectedRecipients: (state) => {
      state.selectedRecipients = initialState.selectedRecipients;
    },
    updateMarketingStrings: (state, { payload }: PayloadAction<IMarketingState>) => {
      state[payload.updateFor] = payload.itemKey;
    },
    resetStepsData: () => initialState,
  },
});

export const { actions } = communicationsPromoteSlice;
export const { getSelectedBrandsIds, getSelectedRecipientsIds, getSelectedRecipientsEmails } =
  communicationsPromoteSlice.getSelectors();

export default communicationsPromoteSlice;
