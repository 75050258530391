import { createStyles, makeStyles } from '#/utils/theme';

export const useWorkflowStyles = makeStyles((theme) =>
  createStyles({
    checkBox: {
      width: theme.custom.REM_SPACES[0.25],
      padding: 0,
      margin: 0,
    },
    header: {
      marginBottom: theme.custom.REM_SPACES[1],
    },
    columns: {
      '& tr td:first-child': {
        width: '5rem',
      },
      '& tr td:nth-child(3)': {
        width: '5rem',
      },
    },
  }),
);
