import * as t from 'io-ts';
import * as React from 'react';
import classnames from 'classnames';

import { useDataCy } from '#/hooks/useDataCy';

import { ITableColumnsProps } from '#/interfaces/table/table';

import AcceptIcon from '../../../../../assets/AcceptIcon.svg';
import CancelIcon from '../../../../../assets/CancelIcon.svg';
import EditIcon from '../../../../../assets/EditGreyIcon.svg';
import { useEditableCellStyles } from '../Table.style';

interface EditableCellInputProps<T> {
  isEditMode: boolean;
  setInputValueToTableData: () => void;
  setInputToDefaultValue: () => void;

  column: ITableColumnsProps<T>;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  inputValue: string;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditableCellInput = <T,>({
  isEditMode,
  setInputValueToTableData,
  setInputToDefaultValue,
  column,
  setInputValue,
  inputValue,
  setEditMode,
}: EditableCellInputProps<T>) => {
  const classes = useEditableCellStyles();
  const generateDataCy = useDataCy();

  if (isEditMode) {
    return (
      <div className={classes.container}>
        <div
          className={classes.buttonsContainer}
          data-cy={generateDataCy('button', `${String(column.fieldName)}-confirm-change`)}
        >
          <button className={classes.approvalButton} onClick={setInputValueToTableData}>
            <img alt="accept" className={classes.approvalIcon} src={AcceptIcon} />
          </button>
          <button
            className={classes.approvalButton}
            data-cy={generateDataCy('button', `${String(column.fieldName)}-cancel-change`)}
            onClick={setInputToDefaultValue}
          >
            <img alt="cancel" className={classes.approvalIcon} src={CancelIcon} />
          </button>
        </div>

        <input
          autoFocus
          className={classnames(classes.contentInput, column.itemClass || '')}
          data-cy={generateDataCy('input', `${String(column.fieldName)}-editable`)}
          onChange={(event) => {
            setInputValue(event.target.value);
          }}
          value={inputValue}
        />
      </div>
    );
  } else {
    return (
      <div className={classes.container}>
        <div className={classes.buttonsContainer}>
          <button
            className={classes.editButton}
            data-cy={generateDataCy('button', `${String(column.fieldName)}-edit`)}
            onClick={() => {
              setEditMode(true);
            }}
          >
            <img alt="edit" src={EditIcon} />
          </button>
        </div>

        <input
          className={classnames(classes.contentInput, column.itemClass)}
          disabled={!isEditMode}
          value={(column.textFormatter ?? t.identity)(inputValue)}
        />
      </div>
    );
  }
};
