export const getFileExtension = async (file: Blob) => {
  return new Promise<string>((resolve) => {
    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      let header = '';
      let type = '';
      if (e.target?.result instanceof ArrayBuffer) {
        const arr = new Uint8Array(e.target.result).subarray(0, 4);
        header = arr.reduce((acc, cur) => acc + cur.toString(16), '');
      }
      switch (header) {
        case '89504e47':
          type = 'png';
          break;
        case '47494638':
          type = 'gif';
          break;

        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          type = 'jpeg';
          break;
        case '25504446':
          type = 'pdf';
          break;
        default:
          type = 'txt';
          break;
      }
      resolve(type);
    };
    fileReader.readAsArrayBuffer(file);
  });
};

export const fileNameMatch = /filename="([^"]+)"/;

export const userName = /fullName="([^"]+)"/;
export const timestamp = /timestamp="([^"]+)"/;

export const imageFormats = ['gif', 'jpeg', 'jpg', 'png'];
