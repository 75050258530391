import CalendarContainerBrand from '#/components/Calendar/CalendarContainerBrand';
import CalendarContainerChannelPartner from '#/components/Calendar/CalendarContainerChannelPartner';
import CalendarContainerRetailer from '#/components/Calendar/CalendarContainerRetailer';

import UserTypeWrapper from '#/utils/UserTypeWrapper';

const CalendarContainer = () => {
  return (
    <>
      <UserTypeWrapper
        agency={<CalendarContainerBrand />}
        brand={<CalendarContainerBrand />}
        channel-partner={<CalendarContainerChannelPartner />}
        retailer={<CalendarContainerRetailer />}
      />
    </>
  );
};

export default CalendarContainer;
