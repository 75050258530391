import { IResource } from '#/hooks/useGetResource';

import { IDynamicRibbonModalData } from '#/interfaces/campaigns/campaignsInstances';
import { IGeneralOverviewInstance, ISubmitCampaignDynamicRibbon } from '#/interfaces/forms/campaigns';

import { SearchFilters } from '#/store/api/campaigns/campaignsInterfaces';
import {
  CampaignInstanceBrand,
  CampaignInstanceChannelPartner,
  CampaignInstanceRetailer,
  CampaignsInstances,
  InstancesHeader,
} from '#/store/api/campaigns/instancesInterfaces';

import { baseApi } from '../../base';

const instanceApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getInstance: builder.query<InstancesHeader, { instanceId: string | undefined }>({
      query: ({ instanceId }) => `/retailer/instances/${instanceId}/header`,
      providesTags: ['INSTANCE_HEADER'],
    }),

    getInstancesOverviewGeneral: builder.query<IGeneralOverviewInstance, { resource: IResource }>({
      query: ({ resource }) => `/retailer/instances/${resource.resourceId}/overview/general`,
      providesTags: ['INSTANCE_OVERVIEW_GENERAL'],
    }),

    putInstancesOverviewGeneral: builder.mutation<
      void,
      { campaignId: string | undefined; body: IGeneralOverviewInstance }
    >({
      query: ({ campaignId, body }) => ({
        url: `/retailer/instances/${campaignId}/overview/general`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['INSTANCE_OVERVIEW_GENERAL']),
    }),

    postCampaignDynamicRibbon: builder.mutation<
      IDynamicRibbonModalData,
      { data: ISubmitCampaignDynamicRibbon; instanceId: string | undefined }
    >({
      query: ({ data, instanceId }) => ({
        url: `retailer/instances/brand/${instanceId}/invitation/apply`,
        method: 'POST',
        body: data,
      }),
    }),

    putBrandOrderDetails: builder.mutation<
      void,
      { instanceId: string | undefined; body: { billingType: string; purchaseOrderNumber: string } }
    >({
      query: ({ instanceId, body }) => ({
        url: `/retailer/brand-order/${instanceId}`,
        method: 'PUT',
        body,
      }),
    }),
    getInstancesRetailerFilter: builder.query<{ filters: SearchFilters[] }, { campaignId: string | undefined }>({
      query: () => `/retailer/instance/view-controller/filters`,
    }),
    getInstancesRetailer: builder.query<
      CampaignsInstances<CampaignInstanceRetailer>,
      {
        campaignId: string | undefined;
        pageNumber: number;
        numberOfRows: number;
        searchQuery: string;
      }
    >({
      query: ({ campaignId, pageNumber, numberOfRows, searchQuery = '' }) => {
        const searchParams = new URLSearchParams();

        searchParams.set('pageNumber', pageNumber.toString());
        searchParams.set('numberOfRows', numberOfRows.toString());
        searchParams.set('searchQuery', searchQuery);

        return `/retailer/instance/view-controller/${campaignId}/filtered?${searchParams}`;
      },
    }),
    getInstancesChannelPartnerFilter: builder.query<{ filters: SearchFilters[] }, { campaignId: string | undefined }>({
      query: () => `/retailer/instance/view-controller/channel-partner/filters`,
    }),
    getInstancesChannelPartner: builder.query<
      CampaignsInstances<CampaignInstanceChannelPartner>,
      {
        campaignId: string | undefined;
        pageNumber: number;
        numberOfRows: number;
        searchQuery: string;
      }
    >({
      query: ({ campaignId, pageNumber, numberOfRows, searchQuery = '' }) => {
        const searchParams = new URLSearchParams();

        searchParams.set('pageNumber', pageNumber.toString());
        searchParams.set('numberOfRows', numberOfRows.toString());
        searchParams.set('searchQuery', searchQuery);
        return `/retailer/instance/view-controller/channel-partner/${campaignId}/filtered?${searchParams}`;
      },
    }),
    getInstancesBrandFilter: builder.query<{ filters: SearchFilters[] }, { campaignId: string | undefined }>({
      query: () => `/retailer/instance/view-controller/brand/filters`,
    }),
    getInstancesBrand: builder.query<
      CampaignsInstances<CampaignInstanceBrand>,
      {
        campaignId: string | undefined;
        pageNumber: number;
        numberOfRows: number;
        searchQuery: string;
      }
    >({
      query: ({ campaignId, pageNumber, numberOfRows, searchQuery = '' }) => {
        const searchParams = new URLSearchParams();

        searchParams.set('pageNumber', pageNumber.toString());
        searchParams.set('numberOfRows', numberOfRows.toString());
        searchParams.set('searchQuery', searchQuery);

        return `/retailer/instance/view-controller/brand/${campaignId}/filtered?${searchParams}`;
      },
    }),
  }),
});

export const {
  useGetInstanceQuery,
  usePostCampaignDynamicRibbonMutation,
  useGetInstancesOverviewGeneralQuery,
  usePutInstancesOverviewGeneralMutation,
  usePutBrandOrderDetailsMutation,
  useGetInstancesRetailerQuery,
  useGetInstancesRetailerFilterQuery,
  useGetInstancesBrandQuery,
  useGetInstancesBrandFilterQuery,
  useGetInstancesChannelPartnerFilterQuery,
  useGetInstancesChannelPartnerQuery,
} = instanceApi;

export { instanceApi };
