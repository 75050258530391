import { useParams } from 'react-router';

import ChannelAndTacticsSingleChannelPartner from './ChannelAndTacticsSingleChannelPartner';
import ChannelPartnerBilling from './channelPartnerBillingInfo';
import ChannelPartnerGeneralInfo from './channelPartnerGeneralInfo';
import ChannelPartnerUsers from './users/ChannelPartnerUsersTable';

const ChannelPartnerSingle = () => {
  const { channelId } = useParams();
  return (
    <>
      <ChannelPartnerGeneralInfo channelId={channelId} />
      <ChannelAndTacticsSingleChannelPartner channelPartnerId={channelId} />
      <ChannelPartnerUsers channelId={channelId} />
      <ChannelPartnerBilling channelId={channelId} />
    </>
  );
};

export default ChannelPartnerSingle;
