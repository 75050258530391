import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import EyeIcon from '../../../assets/EyeIcon.svg';
import { userProfileSubmenu } from '../../../constants/submenu';
import DisplayPageName from '../../common/displayPageName/DisplayPageName';
import { SubmenuBar } from '../../common/submenuBar/SubmenuBar';

export const UserProfile = () => {
  return (
    <Box>
      <DisplayPageName imgSrc={EyeIcon} pageName="My account" />
      <SubmenuBar tabs={userProfileSubmenu} />
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
};
