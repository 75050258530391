import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { retailerAxios } from '#/api/axiosConfig';
import { submitForInternalReview } from '#/api/tasks';

import { CAMPAIGN_STAGE_HEADER } from '#/constants/general';

import { useDataCy } from '#/hooks/useDataCy';

import { convertAllCapsUnderscoreStrings } from '#/utils/utils';

import StageIcon from '../../../assets/StageIcon.svg';
import StepCurrentIcon from '../../../assets/StepCurrentIcon.svg';
import StepSuccessIcon from '../../../assets/StepSuccessIcon.svg';
import StepUncompletedIcon from '../../../assets/StepUncompletedIcon.svg';
import NewButton from '../../common/button/NewButton';
import { useSnackbarError } from '../../common/snackbar/useSnackbarError';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import { useCampaignStepperStyles } from './campaignStepper.styles';

interface ICampaignStepperProps {
  campaignId: string | undefined;
}

const CampaignStepper = ({ campaignId }: ICampaignStepperProps) => {
  const navigate = useNavigate();
  const classes = useCampaignStepperStyles();
  const generateDataCy = useDataCy();

  const [currentStage, setCurrentStage] = useState<string>('');
  const [stages, setStages] = useState<string[]>([]);
  const { setError } = useSnackbarError();

  const fetchCurrentStage = async () => {
    try {
      const { data } = await retailerAxios.get(`/campaign/${campaignId}/header`);
      setCurrentStage(data.stage);
      setStages(data.stages);
    } catch (error) {
      setError(error);
    }
  };
  useEffect(() => {
    fetchCurrentStage();
  }, []);

  const submitHandler = async () => {
    try {
      await submitForInternalReview(campaignId);
      navigate(0);
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        setError(e.response?.data);
      }
    }
  };
  return (
    <Box className={classes.container}>
      <Box sx={{ display: 'flex' }}>
        <Box>
          <img src={StageIcon} />
        </Box>
        <TypographyWithFontFamily sx={{ fontSize: '17px', fontWeight: '700', marginLeft: '8px' }}>
          Stages
        </TypographyWithFontFamily>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Stepper activeStep={stages.findIndex((s) => currentStage === s)} alternativeLabel>
          {stages.map((step, index: number) => (
            <Step key={step} data-cy={generateDataCy('step', step)}>
              {index < stages.findIndex((s) => currentStage === s) && (
                <StepLabel icon={<img src={StepSuccessIcon} />}>{convertAllCapsUnderscoreStrings(step)}</StepLabel>
              )}
              {index === stages.findIndex((s) => currentStage === s) && (
                <StepLabel icon={<img src={StepCurrentIcon} />}>
                  <Box>
                    <Box>{convertAllCapsUnderscoreStrings(step)}</Box>
                    {currentStage === CAMPAIGN_STAGE_HEADER.CAMPAIGN_CREATION && (
                      <NewButton
                        className={classes.m0auto}
                        onClick={submitHandler}
                        text="Submit for review"
                        variant="Secondary"
                      />
                    )}
                  </Box>
                </StepLabel>
              )}
              {index > stages.findIndex((s) => currentStage === s) && (
                <StepLabel icon={<img src={StepUncompletedIcon} />}>{convertAllCapsUnderscoreStrings(step)}</StepLabel>
              )}
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
};
export default CampaignStepper;
