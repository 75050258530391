import { Box, Collapse, Divider, List, ListItemButton, ListItemText } from '@mui/material';
import { useMemo, useState } from 'react';

import { getAllChannelsAndTactics } from '../../../../api/channelsAndTactics';
import ArrowDownIcon from '../../../../assets/ArrowDownIcon.svg';
import ArrowLeftIcon from '../../../../assets/ArrowLeftIcon.svg';
import { tacticsTableColumns } from '../../../../constants/tables/channelAndTactics/ChannelAndTactics';
import { useRequest } from '../../../../hooks/useRequest';
import { ISelectedSubChannel } from '../../../../interfaces/channelsAndTactics/channelAndTactics';
import { Table } from '../../../common/table/tableComponent/Table';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import {
  headerSx,
  headerTacticsSx,
  listItemButtonSx,
  numberOfTacticsSx,
  useChannelsAndTacticsStyle,
} from './ChannelsAndTactics.style';

const ChannelsAndTactics = () => {
  const classes = useChannelsAndTacticsStyle();
  const getAllChannelsAndTacticsAction = useMemo(() => getAllChannelsAndTactics(), []);
  const { response } = useRequest(getAllChannelsAndTacticsAction);
  const channelsAndTacticsData = response?.data;

  const [selectedChannel, setSelectedChannel] = useState('');
  const [selectedSubChannel, setSelectedSubChannel] = useState<ISelectedSubChannel>();
  const displayTactics = selectedSubChannel?.tactics.map((subChannel) => {
    return {
      tactic: subChannel.area,
    };
  });

  return (
    <Box className={classes.channelsAndTacticsContainer}>
      <Box className={classes.channelsAndSubChannelsContainer}>
        <TypographyWithFontFamily sx={headerSx}>Channels & Sub-Channels</TypographyWithFontFamily>
        <List className={classes.list} component="nav">
          {channelsAndTacticsData?.channels.map((channel) => (
            <Box key={channel.channel}>
              <ListItemButton className={classes.collapsible} onClick={() => setSelectedChannel(channel.channel)}>
                <ListItemText primary={channel.channel} />
                <img src={channel.channel === selectedChannel ? ArrowDownIcon : ArrowLeftIcon} />
              </ListItemButton>
              {channel.subChannels.map((subChannel) => (
                <Collapse
                  key={subChannel.subChannel}
                  in={channel.channel === selectedChannel}
                  onClick={() => setSelectedSubChannel(subChannel)}
                  timeout="auto"
                >
                  <List
                    className={`${classes.subChannel} ${
                      selectedSubChannel?.subChannel === subChannel.subChannel ? classes.selectedSubChannel : ''
                    }`}
                    component="div"
                    disablePadding
                  >
                    <ListItemButton sx={listItemButtonSx}>
                      <ListItemText
                        primary={
                          <Box className={classes.subChannelContainer}>
                            <TypographyWithFontFamily>{subChannel.subChannel}</TypographyWithFontFamily>
                            <TypographyWithFontFamily sx={numberOfTacticsSx}>
                              {subChannel.tactics.length.toString()}
                            </TypographyWithFontFamily>
                          </Box>
                        }
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
              ))}
            </Box>
          ))}
        </List>
      </Box>
      <Divider flexItem orientation="vertical" />
      <Box className={classes.channelTacticsContainer}>
        <Box>
          <TypographyWithFontFamily sx={headerTacticsSx}>Channel Tactics</TypographyWithFontFamily>
          {selectedSubChannel ? (
            <Box className={classes.tacticsTableContainer}>
              <Table
                bodyCellClass={classes.tacticsTableBodyCell}
                columns={tacticsTableColumns}
                containerClass={classes.tacticsTableBody}
                data={displayTactics || []}
                headCellClass={classes.tacticsTableHeaderCell}
              />
            </Box>
          ) : (
            <Box>
              <TypographyWithFontFamily>No tactics to display</TypographyWithFontFamily>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ChannelsAndTactics;
