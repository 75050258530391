import * as React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import ErrorBoundary from '#/components/ErrorBoundary/ErrorBoundary';
import { UnexistingRoute } from '#/components/common/error/UnexistingRoute';
import PageLayout from '#/components/common/layout/pageLayout';
import CreateBrandUserForm from '#/components/features/settings/brandUserForm/BrandUserForm';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_AUDIENCE } from '#/constants/general';
import SecureRoute from '#/constants/routing/SecureRoute';
import { ROUTE_PATHS } from '#/constants/routing/routePaths';
import { IMainRoute, IRoute, routesList } from '#/constants/routing/routesList';

import { useAppSelector } from '#/hooks';

import { USER_TYPES, USER_TYPES_HIDDEN } from '#/utils/UserTypeWrapper';

const AppRoutes = (): JSX.Element => {
  const user = useAppSelector((state) => state.user);
  const userPermissions = user.permissions;
  const userType = user.user?.userType;

  const isRouteAllowed = (route: IMainRoute | IRoute) => {
    if (userType === USER_TYPES_HIDDEN.SUPER_ADMIN) return true;
    if (route.permission && userPermissions && !userPermissions.includes(route.permission)) {
      return false;
    }
    if (route.allowedUserTypes && userType && !route.allowedUserTypes.includes(userType as USER_TYPES)) {
      return false;
    }
    return true;
  };

  const authorizedRoutes = useMemo(() => {
    const filteredRoutes = routesList.filter(isRouteAllowed).map((mainRoute) => ({
      ...mainRoute,
      children: mainRoute.children?.filter(isRouteAllowed),
    }));

    return filteredRoutes.map((mainRoute) => {
      return (
        <Route key={mainRoute.path} element={<mainRoute.component />} path={mainRoute.path}>
          {(mainRoute.children ?? []).map((childrenRoute) => {
            return <Route key={childrenRoute.path} element={<childrenRoute.component />} path={childrenRoute.path} />;
          })}
        </Route>
      );
    });
  }, [user.permissions]);

  return (
    <ErrorBoundary>
      <Auth0Provider
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: AUTH0_AUDIENCE,
        }}
        cacheLocation="localstorage"
        clientId={AUTH0_CLIENT_ID}
        domain={AUTH0_DOMAIN}
      >
        <Routes>
          <Route element={<CreateBrandUserForm />} path={ROUTE_PATHS.SIGNUP} />
          <Route element={<SecureRoute />} path="*">
            <Route element={<PageLayout />}>{authorizedRoutes}</Route>
            <Route element={<UnexistingRoute />} path="*" />
          </Route>
        </Routes>
      </Auth0Provider>
    </ErrorBoundary>
  );
};

export default AppRoutes;
