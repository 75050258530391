import { Box, Grid } from '@mui/material';

import { THEME } from '../../constants/theme/constants';
import { IPartnerUserAccess } from '../../interfaces/partners/users/partnerUsers';
import { TypographyWithFontFamily } from '../common/typography/TypographyWithFontFamily';
import { useAddUserWizardStyle } from './AddUserWizard.style';

interface IUSerRequestData {
  userRequestData: IPartnerUserAccess;
}

const UserRequestData = ({ userRequestData }: IUSerRequestData) => {
  const classes = useAddUserWizardStyle();

  return (
    <Box>
      <TypographyWithFontFamily sx={{ margin: `${THEME.REM_SPACES[1]} 0` }} variant="H4_Base">
        Request Form Data
      </TypographyWithFontFamily>
      <Grid container>
        <Grid className={classes.flexCenter} item xs={4}>
          <TypographyWithFontFamily cName={classes.labelMargin} variant="H6_Base">
            Request Date:
          </TypographyWithFontFamily>
          <TypographyWithFontFamily variant="Label_Base">{userRequestData.submissionDate}</TypographyWithFontFamily>
        </Grid>
        <Grid className={classes.flexCenter} item xs={4}>
          <TypographyWithFontFamily cName={classes.labelMargin} variant="H6_Base">
            User Name:
          </TypographyWithFontFamily>
          <TypographyWithFontFamily variant="Label_Base">{userRequestData.firstName}</TypographyWithFontFamily>
        </Grid>
        <Grid className={classes.flexCenter} item xs={4}>
          <TypographyWithFontFamily cName={classes.labelMargin} variant="H6_Base">
            Last Name:
          </TypographyWithFontFamily>
          <TypographyWithFontFamily variant="Label_Base">{userRequestData.lastName}</TypographyWithFontFamily>
        </Grid>
        <Grid className={classes.flexCenter} item xs={4}>
          <TypographyWithFontFamily cName={classes.labelMargin} variant="H6_Base">
            Company:
          </TypographyWithFontFamily>
          <TypographyWithFontFamily variant="Label_Base">{userRequestData.companyName}</TypographyWithFontFamily>
        </Grid>
        <Grid className={classes.flexCenter} item xs={4}>
          <TypographyWithFontFamily cName={classes.labelMargin} variant="H6_Base">
            Department:
          </TypographyWithFontFamily>
          <TypographyWithFontFamily variant="Label_Base">{userRequestData.departmentName}</TypographyWithFontFamily>
        </Grid>
        <Grid className={classes.flexCenter} item xs={4}>
          <TypographyWithFontFamily cName={classes.labelMargin} variant="H6_Base">
            Job Title:
          </TypographyWithFontFamily>
          <TypographyWithFontFamily variant="Label_Base">{userRequestData.title}</TypographyWithFontFamily>
        </Grid>
        <Grid className={classes.flexCenter} item xs={4}>
          <TypographyWithFontFamily cName={classes.labelMargin} variant="H6_Base">
            Email:
          </TypographyWithFontFamily>
          <TypographyWithFontFamily variant="Label_Base">{userRequestData.email}</TypographyWithFontFamily>
        </Grid>
        <Grid className={classes.flexCenter} item xs={4}>
          <TypographyWithFontFamily cName={classes.labelMargin} variant="H6_Base">
            Comments:
          </TypographyWithFontFamily>
          <TypographyWithFontFamily variant="Label_Base">{userRequestData.comments}</TypographyWithFontFamily>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserRequestData;
