import { THEME } from '#/constants/theme/constants';

import { createStyles, makeStyles } from '#/utils/theme';

export const useAddTacticsToTierStyle = makeStyles((theme) =>
  createStyles({
    container: {
      marginBottom: theme.custom.REM_SPACES[4],
    },
    cancelButton: {
      marginRight: theme.custom.REM_SPACES['0.5'],
    },
    modalHeader: {
      backgroundColor: theme.custom.PALETTES.UI.Primary[900],
      height: theme.custom.REM_SPACES['4'],
      color: theme.custom.PALETTES.OTHER.White,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `1.25rem ${theme.custom.REM_SPACES['1']}`,
    },
    modalContent: {
      padding: `${theme.custom.REM_SPACES['1']} 0`,
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: '1fr',
      gridColumnGap: theme.custom.REM_SPACES['1'],
    },
    tacticsBox: {
      border: `1px solid ${theme.custom.PALETTES.BORDER.Extra_Light}`,
      borderRadius: theme.custom.REM_SPACES['0.5'],
      minHeight: '27rem',
      padding: theme.custom.REM_SPACES['1'],
    },
    selectTacticsContainer: {
      marginBottom: theme.custom.REM_SPACES['1'],
      userSelect: 'none',
      width: '21rem',
    },
    tacticSelect: {
      border: `1px solid ${theme.custom.PALETTES.BORDER.Extra_Light}`,
      height: theme.custom.REM_SPACES['2'],
      borderRadius: theme.custom.REM_SPACES['0.5'],
      padding: `${theme.custom.REM_SPACES['0.25']} ${theme.custom.REM_SPACES['0.5']}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    selectOptionsContainer: {
      border: `1px solid ${theme.custom.PALETTES.BORDER.Light}`,
      borderRadius: theme.custom.REM_SPACES['0.5'],
      padding: `${theme.custom.REM_SPACES['0.5']} 0`,
    },
    subChannelContainer: {
      cursor: 'pointer',
      padding: `${theme.custom.REM_SPACES['1']} ${theme.custom.REM_SPACES['3']}`,
      height: theme.custom.REM_SPACES['2'],
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        background: theme.custom.PALETTES.UI.Secondary[200],
      },
    },
    selectableArea: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    removeAreaContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    bottomButtonsContainer: {
      display: 'flex',
      justifyContent: 'end',
      columnGap: theme.custom.REM_SPACES['0.5'],
    },
    campaignDetails: {
      display: 'flex',
    },
  }),
);
export const styleSx = {
  accordionSx: {
    boxShadow: 'none',
    '&.MuiAccordion-root': {
      margin: '0',
    },
    '&.MuiAccordion-root:first-of-type': {
      borderRadius: THEME.REM_SPACES['0.5'],
    },
    '&.MuiAccordion-root:last-of-type': {
      borderRadius: THEME.REM_SPACES['0.5'],
    },

    '&.MuiAccordion-root::before': {
      backgroundColor: THEME.PALETTES.OTHER.White,
    },
  },

  accordionSummarySx: {
    '&.MuiAccordionSummary-root': {
      flexDirection: 'row-reverse',
      minHeight: THEME.REM_SPACES['2'],
      height: THEME.REM_SPACES['2'],
    },
    '&.MuiAccordionSummary-content .Mui-expanded': {
      margin: '0',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },

  accordionDetailsSx: {
    padding: '0',
  },
};
