import PartnerUsersRetailerContainer from '#/components/features/partners/users/PartnerUsersRetailerContainer';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import PermissionWrapper from '#/utils/PermissionWrapper';

const PartnerUsers = () => (
  <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.COMPANY_USER_READ]}>
    <PartnerUsersRetailerContainer />
  </PermissionWrapper>
);
export default PartnerUsers;
