import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { IAddDetailsToTierForm } from '#/components/campaigns/addDetailsToSpecificTier';

import { IChannelAndTacticsResponseTactic } from '#/interfaces/forms/campaigns';

import { IEditDetails } from '#/store/api/campaigns/campaignsInterfaces';

export const useTacticDetailsForm = (
  detailsData: IEditDetails | undefined,
  selectedTactic: IChannelAndTacticsResponseTactic,
) => {
  const schema = yup.object().shape({
    channelPartner: yup.string(),
    startDate: yup.string().nullable(),
    endDate: yup.string().nullable(),
    leadTimeInDays: yup.string().nullable(),
    creativeDueDate: yup.string().nullable(),
    adSize: yup.string().nullable(),
    notes: yup.string().nullable(),
    budget: yup.number().nullable(),
    placement: yup.string().nullable(),
    targeting: yup.string().nullable(),
    units: yup.string().nullable(),
    rateType: yup.string().nullable(),
    rateValue: yup.number().nullable(),
  });
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IAddDetailsToTierForm>({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    reset({
      leadTimeInDays: detailsData?.leadTime,
      channelPartner: detailsData?.channelPartnerName,
      startDate: detailsData?.startDate,
      endDate: detailsData?.endDate,
      creativeDueDate: detailsData?.creativeDueDate,
      notes: detailsData?.mediaNotes,
      budget: parseInt(selectedTactic?.budget) || 0,
      units: selectedTactic?.units?.toString(),
      placement: selectedTactic?.placement,
      rateType: selectedTactic?.rateType,
      targeting: selectedTactic?.targeting,
      adSize: detailsData?.adSize,
      rateValue: selectedTactic?.rateValue,
    });
  }, [detailsData?.tactic, detailsData?.campaignTacticId]);
  return { control, register, handleSubmit, reset, setValue, watch, errors };
};
