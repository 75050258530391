import Loader from '#/components/common/loader/loader';
import Companies from '#/components/features/partners/companies/Companies';

import { useGetCompaniesRetailerFilterQuery } from '#/store/api/company/company';

const CompaniesContainer = () => {
  const { isLoading: filterDataIsLoading } = useGetCompaniesRetailerFilterQuery({});

  if (filterDataIsLoading) return <Loader />;

  return <Companies />;
};
export default CompaniesContainer;
