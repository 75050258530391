import { USER_PERMISSIONS } from '../../../constants/permissions/userPermissions';
import { getUserHasPermission } from '../../../constants/permissions/utils';
import { useGetPeriodsQuery } from '../../../store/api/periods/periods';
import { useGetRetailerAccountCalendarQuery } from '../../../store/api/retailerAccount/retailerAccount';

const useGetPeriodsAndMarketingDay = () => {
  const hasPeriodsPermission = getUserHasPermission(USER_PERMISSIONS.ACCOUNT_READ);
  const skip = hasPeriodsPermission === false;
  const { data: retailerAccountCalendar, isLoading: retailerAccountCalendarLoading } =
    useGetRetailerAccountCalendarQuery(null, { skip });
  const { data: periods, isLoading: periodsLoading } = useGetPeriodsQuery(null, { skip });

  return {
    periods,
    retailerAccountCalendar,
    retailerAccountCalendarLoading,
    periodsLoading,
  };
};
export default useGetPeriodsAndMarketingDay;
