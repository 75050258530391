import { Box, Table as MaterialTable, TableContainer } from '@mui/material';
import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { ISortStateProps, ITableColumnsProps } from '../../../../interfaces/table/table';
import NewButton from '../../button/NewButton';
import { useTableStyles } from './Table.style';
import { TableBody } from './body/TableBody';
import { TableHead } from './head/TableHead';

const SCROLL_AMOUNT = 50;

export const Table = <T,>({
  columns,
  data,
  hiddenTableHead = false,
  headClass,
  headCellClass,
  bodyCellClass,
  containerClass,
  tableClass,
  displayStatusColor,
  sortProps,
  tableBodyPadding,
  tableHeadPadding,
  selectedId,
  selectedRowClass,
  hideTableHead,
  tableExtraRow,
  headCustomStyle,
  maxHeight,
}: {
  columns: ITableColumnsProps<T>[];
  data: T[];
  hiddenTableHead?: boolean;
  headClass?: string;
  headCellClass?: string;
  bodyCellClass?: string;
  containerClass?: string;
  tableClass?: string;
  displayStatusColor?: boolean;
  sortProps?: ISortStateProps;
  tableBodyPadding?: string;
  tableHeadPadding?: string;
  selectedId?: string;
  selectedRowClass?: string;
  hideTableHead?: boolean;
  tableExtraRow?: JSX.Element;
  maxHeight?: string;
  headCustomStyle?: { [key: string]: string };
}): JSX.Element => {
  const classes = useTableStyles();

  const [isScrollable, setIsScrollable] = useState(false);

  const tableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (tableRef.current) {
      const { scrollWidth, clientWidth } = tableRef.current;
      if (clientWidth < scrollWidth) {
        setIsScrollable(true);
      }
    }
  }, []);

  const handleScroll = (direction: string) => {
    if (tableRef.current) {
      const scrollDirection = direction === 'left' ? -SCROLL_AMOUNT : SCROLL_AMOUNT;
      tableRef.current.scrollLeft += scrollDirection;
    }
  };

  return (
    <Box>
      {isScrollable && (
        <Box className={classes.buttonsContainer}>
          <NewButton className={classes.scrollButton} onClick={() => handleScroll('left')} text="<" />
          <NewButton className={classes.scrollButton} onClick={() => handleScroll('right')} text=">" />
        </Box>
      )}
      <TableContainer
        ref={tableRef}
        className={classnames(classes.tableContainer, containerClass)}
        sx={maxHeight ? { maxHeight: maxHeight, position: 'relative' } : {}}
      >
        <MaterialTable className={classnames(classes.custom, tableClass)}>
          {hideTableHead ? null : (
            <TableHead
              columns={columns}
              headCellClass={classnames(headCellClass, classes.headCellClass)}
              headClass={classnames(classes.headClass, headClass)}
              headCustomStyle={headCustomStyle}
              hiddenTableHead={hiddenTableHead}
              sortProps={sortProps}
              tablePadding={tableHeadPadding}
            />
          )}
          <TableBody
            bodyCellClass={classnames(classes.bodyCellClass, bodyCellClass)}
            columns={columns}
            data={data}
            displayStatusColor={displayStatusColor}
            selectedId={selectedId}
            selectedRowClass={selectedRowClass}
            tableExtraRow={tableExtraRow}
            tablePadding={tableBodyPadding}
          />
        </MaterialTable>
      </TableContainer>
    </Box>
  );
};
