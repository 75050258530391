import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { deleteFile, downloadFile, getFile } from '../../../api/fileView';
import DeleteIcon from '../../../assets/DeleteIconBlue.svg';
import DownloadIcon from '../../../assets/DownloadIconBlue.svg';
import PDFIcon from '../../../assets/PDFIcon.png';
import CSVIcon from '../../../assets/csvIcon.png';
import { useRequest } from '../../../hooks/useRequest';
import { IFileViewProps } from '../../../interfaces/files/file-view';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import { useFileViewStyles } from './FileView.styles';

const FileView = ({
  downloadLink,
  deleteLink,
  readonly,
  fileId,
  watch,
  setValue,
  fileFor,
  refreshAfterDelete,
  timeStamp,
  defaultFileName,
  preventFetch,
}: IFileViewProps) => {
  const [shouldDelete, setShouldDelete] = useState(false);

  useEffect(() => {
    (async () => {
      if (shouldDelete) {
        await deleteFile(deleteLink);
        refreshAfterDelete && refreshAfterDelete();
        if (watch) {
          let temp = watch(fileFor);
          if (Array.isArray(temp)) {
            temp.splice(
              temp.findIndex((currentFile: string) => currentFile === fileId),
              1,
            );
          } else {
            temp = '';
          }
          setValue(fileFor, temp);
        }
      }
    })();
  }, [shouldDelete]);

  const [shouldDownload, setShouldDownload] = useState(0);

  useEffect(() => {
    (async () => {
      if (shouldDownload) {
        const response = await downloadFile(downloadLink, fileId);
        const match = response?.headers['content-disposition'].match(/filename="([^"]+)"/);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(response?.data);
        link.setAttribute('download', match?.[1] || defaultFileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    })();
  }, [shouldDownload]);
  const getFileAction = useMemo(() => (!preventFetch && fileId ? getFile(downloadLink, fileId) : null), []);
  const { response } = useRequest(getFileAction);
  const match: string[] = response?.headers['content-disposition'].match(/filename="([^"]+)"/);
  const fileName = match?.[1] || defaultFileName;

  const classes = useFileViewStyles();
  const extension = fileName?.split('.')[fileName.split('.').length - 1];
  return (
    <Box className={classes.fileViewContainer}>
      <Box className={classes.imageNameContainer}>
        {extension === 'csv' && <img height={'40px'} src={CSVIcon} width={'40px'} />}
        {extension !== 'csv' && <img src={PDFIcon} />}
        <Box className={classes.fileTextContainer}>
          <TypographyWithFontFamily>{fileName || defaultFileName}</TypographyWithFontFamily>
          {timeStamp && (
            <TypographyWithFontFamily cName={classes.uploadDateText}>Uploaded {timeStamp}</TypographyWithFontFamily>
          )}
        </Box>
      </Box>
      <Box className={classes.buttonsContainer}>
        <img onClick={() => setShouldDownload((prev) => prev + 1)} src={DownloadIcon} />
        {!readonly && <img onClick={() => setShouldDelete(true)} src={DeleteIcon} />}
      </Box>
    </Box>
  );
};
export default FileView;
