import { IResource } from '../../../hooks/useGetResource';
import { baseApi } from '../../base';
import { ICampaignHistory } from './campaignsInterfaces';

const campaignHistory = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCampaignHistory: builder.query<ICampaignHistory[], { resource: IResource }>({
      query: ({ resource }) => `/retailer/campaign/${resource.resourceId}/history`,
      providesTags: ['CAMPAIGN_HISTORY'],
    }),
  }),
});

export const { useGetCampaignHistoryQuery } = campaignHistory;

export { campaignHistory };
