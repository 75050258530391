import { IAttribute, ICategory, ISubcategory } from '#/api/common/productMix';

import { GATEWAY_API_URL } from '#/constants/general';

import { RootState } from '#/store/reducers';

import { addAuthorizationHeader } from '#/utils/apiUtils';

import { baseApi } from '../../base';

const productsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProductMixAllCategories: builder.query<
      { categories: ICategory[]; subcategories: ISubcategory[]; attributes: IAttribute[] },
      void
    >({
      queryFn: async (_args, api) => {
        const authToken = (api.getState() as RootState).user.authToken;
        const [categories, attributes] = await Promise.all([
          fetch(`${GATEWAY_API_URL}/retailer/product-mix/categories`, addAuthorizationHeader(authToken)).then(
            (categories) => categories.json(),
          ),
          fetch(`${GATEWAY_API_URL}/retailer/product-mix/attributes`, addAuthorizationHeader(authToken)).then(
            (attributes) => attributes.json(),
          ),
        ]);

        return {
          data: {
            categories: categories as ICategory[],
            subcategories: categories.flatMap((categories) => categories.subcategories) as ISubcategory[],
            attributes: attributes as IAttribute[],
          },
        };
      },
    }),
    postProductMixSubcategory: builder.mutation<void, { categoryId: string; subcategoryName: string }>({
      query: ({ categoryId, subcategoryName }) => ({
        url: `retailer/product-mix/${categoryId}/subcategory?subcategoryName=${encodeURIComponent(subcategoryName)}`,
        method: 'POST',
      }),
    }),
    deleteProductMixSubcategory: builder.mutation<void, { categoryId: string; subCategoryId: string }>({
      query: ({ categoryId, subCategoryId }) => ({
        url: `retailer/product-mix/${categoryId}/${subCategoryId}`,
        method: 'DELETE',
      }),
    }),
    postProductMixCategory: builder.mutation<void, { categoryName: string }>({
      query: ({ categoryName }) => ({
        url: `retailer/product-mix/category?categoryName=${encodeURIComponent(categoryName)}`,
        method: 'POST',
      }),
    }),
    deleteProductCategory: builder.mutation<void, { categoryId: string }>({
      query: ({ categoryId }) => ({
        url: `retailer/product-mix/${categoryId}`,
        method: 'DELETE',
      }),
    }),
    postProductMixAttribute: builder.mutation<void, { attributeName: string }>({
      query: ({ attributeName }) => ({
        url: `retailer/product-mix/attribute?attributeName=${encodeURIComponent(attributeName)}`,
        method: 'POST',
      }),
    }),
    deleteProductMixAttribute: builder.mutation<void, { attributeId: string }>({
      query: ({ attributeId }) => ({
        url: `retailer//product-mix/attribute/${attributeId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetProductMixAllCategoriesQuery,
  usePostProductMixSubcategoryMutation,
  useDeleteProductMixSubcategoryMutation,
  usePostProductMixCategoryMutation,
  useDeleteProductCategoryMutation,
  usePostProductMixAttributeMutation,
  useDeleteProductMixAttributeMutation,
} = productsApi;

export { productsApi };
