import { Box } from '@mui/material';
import { useState } from 'react';

import AlertError from '#/components/common/Alert/AlertError/AlertError';
import Loader from '#/components/common/loader/loader';

import {
  useDeleteInactivateUserMutation,
  useGetAllUsersAllQuery,
  usePutActivateUserMutation,
} from '#/store/api/users/users';

import PlusIcon from '../../../../assets/Plus.svg';
import NewButton from '../../../common/button/NewButton';
import { Table } from '../../../common/table/tableComponent/Table';
import AddRetailerUserModal from './AddRetailerUserModal';
import { useUserSettingsStyle } from './UsersSettings.style';
import { getUsersSettingsTable } from './UsersSettings.tables';

function UsersSettings() {
  const [shouldUserMaskBeOpened, setShouldUserMaskBeOpened] = useState(false);
  const { data, isFetching, refetch } = useGetAllUsersAllQuery({ userType: 'RETAILER' });

  const addUserAction = () => {
    setShouldUserMaskBeOpened(true);
  };
  const [putActivateUser, { isLoading: activateUserIsLoading, error: triggerPutActivateUserError }] =
    usePutActivateUserMutation();
  const [deleteInactivateUser, { isLoading: inactivateUserIsLoading, error: triggerDeleteInactivateUserError }] =
    useDeleteInactivateUserMutation();

  const tableColumns = getUsersSettingsTable(
    putActivateUser,
    activateUserIsLoading,
    deleteInactivateUser,
    inactivateUserIsLoading,
  );

  const classes = useUserSettingsStyle();
  return (
    <Box className={classes.container}>
      <AlertError error={triggerPutActivateUserError || triggerDeleteInactivateUserError} />
      <Box className={classes.content}>
        <NewButton
          className={classes.mb1}
          icon={PlusIcon}
          iconPosition="left"
          onClick={addUserAction}
          text="Add New Retailer User"
        />
      </Box>
      {isFetching ? <Loader /> : <Table columns={tableColumns} data={data || []} />}

      {shouldUserMaskBeOpened && (
        <AddRetailerUserModal
          onClose={() => {
            setShouldUserMaskBeOpened(false);
            refetch();
          }}
        />
      )}
    </Box>
  );
}
export default UsersSettings;
