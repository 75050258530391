import { useMemo, useState } from 'react';

import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';
import { getUserHasPermission } from '#/constants/permissions/utils';

import { getPartnersUserAccess } from '../../../../api/partners/partnerUsers';
import { deleteCompanyUserSignUp } from '../../../../api/users';
import { PartnersUserAccess } from '../../../../constants/tables/partners/userAccess/userAccessTable';
import { useRequest } from '../../../../hooks/useRequest';
import { IPartnerUserAccess } from '../../../../interfaces/partners/users/partnerUsers';
import AddUserWizard from '../../../addUserWizard/AddUserWizard';
import Card from '../../../common/card/card';
import Loader from '../../../common/loader/loader';
import { useSnackbarError } from '../../../common/snackbar/useSnackbarError';
import { useSnackbarSuccess } from '../../../common/snackbar/useSnackbarSuccess';
import { Table } from '../../../common/table/tableComponent/Table';

const UserAccess = () => {
  const { setError } = useSnackbarError();
  const [refetch, setRefetch] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isAddUserWizardOpen, setIsAddUserWizardOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IPartnerUserAccess | undefined>(undefined);
  const { setSuccess } = useSnackbarSuccess();

  const getStoresAction = useMemo(() => getPartnersUserAccess(), [isAddUserWizardOpen, refetch]);
  const { response, error, loading: loadingUserAccess } = useRequest(getStoresAction);

  const grandAccessAction = (id: string) => {
    setSelectedUser(response?.data.find((user) => user.id.toString() === id));
    setIsAddUserWizardOpen(true);
  };

  const denyUserRequest = async (email: string) => {
    setDeleteLoading(true);
    await deleteCompanyUserSignUp(email);
    setRefetch(!refetch);
    setSuccess('Access request denied.');
    setDeleteLoading(false);
  };

  const useHasPartnersUsersAccessGrantDenyWrite = getUserHasPermission(
    USER_PERMISSIONS.PARTNERS_USERS_ACCESS_GRANT_DENY_WRITE,
  );

  const { userAccessTable } = PartnersUserAccess(
    grandAccessAction,
    denyUserRequest,
    useHasPartnersUsersAccessGrantDenyWrite,
  );

  if (deleteLoading || loadingUserAccess) {
    return <Loader />;
  }

  if (error) {
    setError(error);
  }

  if (!response?.data.length) {
    return <Card>There are no requests.</Card>;
  }

  return (
    <Card>
      <Table columns={userAccessTable} data={response.data} />
      {isAddUserWizardOpen && (
        <AddUserWizard onClose={() => setIsAddUserWizardOpen(false)} userRequestData={selectedUser} />
      )}
    </Card>
  );
};
export default UserAccess;
