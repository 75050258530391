import CircleCheckIcon from '#/assets/CircleCheckIcon.svg';

import { IBrandUser } from '#/interfaces/partners/brands/brandUserList';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

const getBrandUserListTableColumns = (): ITableColumnsProps<IBrandUser>[] => {
  return [
    {
      fieldName: 'userId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Campaign Id',
      hide: true,
    },
    {
      fieldName: 'fullName',
      align: TableColumnAlign.LEFT,
      linkable: true,
      actionElementIdentifier: 'userId',
      type: TableCellTypes.STRING,
      customHead: 'User Name',
      baseUrl: '/partners/users',
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'User Brand Status',
    },
    {
      fieldName: 'keyContact',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.ELEMENT,
      customHead: 'Key Contact',
      element: () => <img alt="circle" src={CircleCheckIcon} />,
      hideElementIfNull: true,
    },

    {
      fieldName: 'billingContact',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.ELEMENT,
      customHead: 'Billing Contact',
      element: () => <img alt="circle" src={CircleCheckIcon} />,
      hideElementIfNull: true,
    },
    {
      fieldName: 'jobTitle',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Job Title',
    },
    {
      fieldName: 'email',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Email',
    },
    {
      fieldName: 'phone',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Phone',
    },
    {
      fieldName: 'lastLogin',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Last Login',
    },
  ];
};

export default getBrandUserListTableColumns;
