import { SnackbarKey, SnackbarProvider } from 'notistack';
import React, { useRef } from 'react';

import XIcon from '../../../assets/XIcon.svg';
import { useSnackbarStyles } from './SnackbarWrapper.styles';

export const SnackbarWrapper = ({ children }: { children: React.ReactChild }): JSX.Element => {
  const notiRef: React.Ref<SnackbarProvider> = useRef(null);
  const classes = useSnackbarStyles();

  const onClickDismiss = (key: SnackbarKey): void => {
    if (notiRef && notiRef.current) {
      notiRef.current.closeSnackbar(key);
    }
  };

  return (
    <SnackbarProvider
      ref={notiRef}
      action={(key) => (
        <button className={classes.button} onClick={() => onClickDismiss(key)}>
          <img src={XIcon} />
        </button>
      )}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={2000}
      dense
      hideIconVariant
      maxSnack={3}
    >
      {children}
    </SnackbarProvider>
  );
};
