import { useState, useEffect, useRef } from 'react';
import { convertToRaw, EditorState as DraftEditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { useWYSIWYGEditorStyles } from './WYSIWYGEditor.style';

const WYSIWYGEditor = (props) => {
  const initialContentState = props.value
    ? DraftEditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(props.value).contentBlocks))
    : DraftEditorState.createEmpty();

  const [editorState, setEditorState] = useState(() => initialContentState);
  const classes = useWYSIWYGEditorStyles();
  const editorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    adjustEditorHeight();
  }, [editorState]);

  const adjustEditorHeight = () => {
    if (editorRef.current) {
      const editorContent = editorRef.current.querySelector('.DraftEditor-root') as HTMLDivElement;
      if (editorContent) {
        editorContent.style.height = 'auto';
        editorContent.style.height = editorContent.scrollHeight + 'px';
      }
    }
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <div ref={editorRef} className={classes.editorWrapper}>
      <Editor
        editorClassName={classes.editor}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbarClassName="toolbarClassName"
        wrapperClassName={classes.editorWrapper}
      />
    </div>
  );
};

export default WYSIWYGEditor;
