import { createStyles, makeStyles } from '#/utils/theme';

export const useInfoStyles = makeStyles((theme) =>
  createStyles({
    flexContainer: {
      display: 'flex',
      alignItems: 'flexStart',
      flexDirection: 'column',
      width: '100%',
      marginTop: '0.625rem',
      marginBottom: '20px',
    },
    label: {
      color: '#606670',
      fontSize: '1rem',
      margin: '1rem 0 0 0',
    },
    value: {
      color: 'black',
    },
    link: {
      color: theme.custom.PALETTES.TEXT.Action_Secondary,
      textDecoration: 'none',
    },
  }),
);
