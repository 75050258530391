import { Box } from '@mui/system';
import { FC } from 'react';

import { THEME } from '../../../constants/theme/constants';
import { useGetUserProfileQuery } from '../../../store/api/users/users';
import Loader from '../../common/loader/loader';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import { UserProfilePicture } from '../../features/userProfile/UserProfilePicture';
import { useOwnerSectionStyles } from './OwnerSection.styles';

interface OwnerSectionProps {
  ownerId: string;
}

const OwnerSection: FC<OwnerSectionProps> = ({ ownerId = '' }) => {
  const { data, isLoading } = useGetUserProfileQuery({ userId: ownerId });

  const classes = useOwnerSectionStyles();

  if (!data) return null;

  return (
    <Box className={classes.container}>
      <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} variant="Label_Base">
        OWNER:
      </TypographyWithFontFamily>
      <TypographyWithFontFamily cName={classes.textAlignCenter} variant="Default_Base">
        <UserProfilePicture autoSave={false} imageId={data.avatarId} viewOnly />
        <Box className={classes.nameContainer}>{isLoading ? <Loader /> : data.fullName}</Box>
      </TypographyWithFontFamily>
    </Box>
  );
};

export default OwnerSection;
