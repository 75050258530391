import { createStyles, makeStyles } from '#/utils/theme';

export const useDynamicTaskActionListStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: theme.custom.REM_SPACES[0.5],
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: theme.custom.REM_SPACES[1],
      padding: theme.custom.REM_SPACES[0.5],
    },
    headerCell20: {
      width: '20%',
      marginRight: theme.custom.REM_SPACES[0.5],
    },
    headerCell25: {
      width: '25%',
    },
    headerCell35: {
      width: '35%',
    },
    tableContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: theme.custom.REM_SPACES[0.5],
      borderRadius: '10px',
    },
    tableContainerBackgroundEven: {
      background: theme.custom.PALETTES.UI.Neutral['100'],
    },
    tableContainerBackgroundOdd: {
      background: theme.custom.PALETTES.UI.Neutral['200'],
    },
    tableCell20: {
      width: '20%',
      marginTop: '0.3rem',
      marginRight: theme.custom.REM_SPACES[0.5],
    },
    input: {
      width: '35%',
    },
    select: {
      width: '25%',
      paddingRight: theme.custom.REM_SPACES[0.5],
    },
  }),
);
