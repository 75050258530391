import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useState } from 'react';

import { useIntervalInputStyles } from '#/components/common/SearchFilters/components/SearchInputs/components/IntervalInput.styles';
import DatePicker from '#/components/common/inputs/DatePicker/DatePicker';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';

type IntervalInput = {
  shouldDisableField: boolean;
  setSearchText: (newSearchText: string) => void;
  isAddToFiltersButtonEnabled: boolean;
  addFilter: () => void;
  searchText: string;
};

const IntervalInput = ({ shouldDisableField, setSearchText, isAddToFiltersButtonEnabled, addFilter, searchText }) => {
  const [start, setStart] = useState(searchText.split(',')[0]);
  const [end, setEnd] = useState(searchText.split(',')[1] || '');

  const onStartChange = (event) => {
    const newValue = dayjs(event).format('MM/DD/YYYY').toString();
    setStart(newValue);
    setSearchText(`${newValue},${end}`);
  };

  const onEndChange = (event) => {
    const newValue = dayjs(event).format('MM/DD/YYYY').toString();
    setEnd(newValue);
    setSearchText(`${start},${newValue}`);
  };

  const classes = useIntervalInputStyles();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box className={classes.container}>
        <DatePicker
          className={classes.intervalPicker}
          dataCy="Interval Start"
          editMode={shouldDisableField}
          hideTextFieldError
          onChange={onStartChange}
          onKeyUp={(event) => {
            if (event.key == 'Enter' && !isAddToFiltersButtonEnabled) addFilter();
          }}
          value={start}
        />
        <TypographyWithFontFamily className={classes.to}> TO </TypographyWithFontFamily>
        <DatePicker
          className={classes.intervalPicker}
          dataCy="Interval End"
          editMode={shouldDisableField}
          hideTextFieldError
          onChange={onEndChange}
          onKeyUp={(event) => {
            if (event.key == 'Enter' && !isAddToFiltersButtonEnabled) addFilter();
          }}
          value={end}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default IntervalInput;
