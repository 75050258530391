import { createStyles, makeStyles } from '#/utils/theme';

export const useDynamicTacticsInventoryStyles = makeStyles((theme) =>
  createStyles({
    tacticsGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
      columnGap: theme.custom.REM_SPACES[0.5],
      rowGap: theme.custom.REM_SPACES[0.25],
    },
    displayFlex: {
      display: 'flex',
    },
    tacticCheckboxImage: {
      width: theme.custom.REM_SPACES[1],
      height: theme.custom.REM_SPACES[1],
      margin: 'auto',
    },
    header: { paddingBottom: theme.custom.REM_SPACES[0.5] },
    tactic: { paddingRight: theme.custom.REM_SPACES[0.5] },
  }),
);
