import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const campaignTimelineStyle = makeStyles(() =>
  createStyles({
    timeLineContainer: {
      padding: THEME.REM_SPACES['1'],
      borderRadius: THEME.REM_SPACES['1'],
      background: THEME.PALETTES.OTHER.White,
    },
    iconContainer: {
      width: '1.5rem',
      height: '3rem',
    },
    icon: {
      width: '2rem',
      height: '3rem',
    },
  }),
);
export const timelineStyleSx = {
  timelineOppositeContent: { flexGrow: 0.2, alignSelf: 'center' },
  timelineContent: { m: 'auto 0' },
};
