import { Box, InputAdornment } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { flow } from 'lodash';
import { FC, useEffect } from 'react';

import SearchWhite from '#/assets/SearchWhite.svg';

import { INPUT_TYPE } from '#/components/common/SearchFilters/SearchFilters.types';
import { useSearchInputsStyles } from '#/components/common/SearchFilters/components/SearchInputs/SearchInputs.styles';
import IntervalInput from '#/components/common/SearchFilters/components/SearchInputs/components/IntervalInput';
import NewButton from '#/components/common/button/NewButton';
import DatePicker from '#/components/common/inputs/DatePicker/DatePicker';
import Select from '#/components/common/inputs/Select/Select';
import TextField from '#/components/common/inputs/TextField/TextField';

import { SearchFilters as SearchFiltersType } from '#/store/api/campaigns/campaignsInterfaces';

import { createFormatterWithReverse, extractNumber, numberFormatter } from '#/utils/textFormatters';

type SearchInputsProps = {
  columnNames: SearchFiltersType[];
  shouldDisableField: boolean;
  setSearchDropdownValue: (newSearchDropdownValue: string) => void;
  searchDropdownValue: string;
  setSearchText: (newSearchText: string) => void;
  searchText: string;
  isFilterActiveAlready: boolean;
  addFilter: () => void;
  setSearchOperation: (newSearchOperation: string) => void;
  operationValue: string;
};

const SearchInputs: FC<SearchInputsProps> = ({
  shouldDisableField,
  setSearchDropdownValue,
  searchDropdownValue,
  setSearchText,
  searchText,
  columnNames,
  isFilterActiveAlready,
  addFilter,
  setSearchOperation,
  operationValue,
}) => {
  useEffect(() => {
    setSearchDropdownValue(columnNames[0].columnName);
  }, []);

  const mappedSearchDropdownValue = columnNames.find((el: SearchFiltersType) => el.columnName === searchDropdownValue);

  const mappedSearchDropdownValueHasOperations = Boolean(
    mappedSearchDropdownValue &&
      'operations' in mappedSearchDropdownValue &&
      mappedSearchDropdownValue.operations.length !== 0,
  );

  const isSearchTextIntervalComplete =
    mappedSearchDropdownValue?.type === INPUT_TYPE.INTERVAL ? searchText.split(',').some((el) => el === '') : false;

  const isSearchText = searchText.length === 0;
  const isOperationValue = mappedSearchDropdownValueHasOperations && operationValue === '';

  const isAddToFiltersButtonEnabled =
    isSearchText || isFilterActiveAlready || isOperationValue || isSearchTextIntervalComplete;

  const mappedOptions = columnNames?.map((el) => ({ label: el.displayName, value: el.columnName }));

  useEffect(() => {
    if (mappedSearchDropdownValueHasOperations) {
      setSearchOperation(mappedSearchDropdownValue?.operations?.[0].value || '');
    }
  }, [mappedSearchDropdownValue?.columnName]);

  const classes = useSearchInputsStyles();

  return (
    <>
      <Box className={classes.selectTextComboContainer}>
        <Select
          className={classes.select}
          dataCy="Search Dropdown"
          editMode={shouldDisableField}
          onChange={(event) => setSearchDropdownValue(event.target.value)}
          options={mappedOptions}
          value={searchDropdownValue}
        />
        {mappedSearchDropdownValueHasOperations && (
          <Select
            className={classes.select}
            dataCy="Operation Dropdown"
            editMode={shouldDisableField}
            onChange={(event) => setSearchOperation(event.target.value)}
            options={columnNames.find((el) => el.columnName === searchDropdownValue)?.operations || []}
            value={operationValue}
          />
        )}
        {mappedSearchDropdownValue?.type === INPUT_TYPE.SELECT && (
          <Select
            className={classes.secondDropdown}
            dataCy="Options Dropdown"
            editMode={shouldDisableField}
            onChange={(event) => setSearchText(event.target.value)}
            options={columnNames.find((el) => el.columnName === searchDropdownValue)?.options || []}
            value={searchText}
          />
        )}

        {mappedSearchDropdownValue?.type === INPUT_TYPE.DATE && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className={classes.datePicker}
              dataCy="Date picker"
              editMode={shouldDisableField}
              hideTextFieldError
              onChange={(event) => setSearchText(dayjs(event).format('MM/DD/YYYY').toString())}
              onKeyUp={(event) => {
                if (event.key == 'Enter' && !isAddToFiltersButtonEnabled) addFilter();
              }}
              value={searchText}
            />
          </LocalizationProvider>
        )}
        {mappedSearchDropdownValue?.type === INPUT_TYPE.TEXT && (
          <TextField
            autoComplete="off"
            className={classnames(classes.input, {
              [classes.inputLarge]: !mappedSearchDropdownValueHasOperations,
            })}
            dataCy="Search Text"
            disabled={!shouldDisableField}
            onChange={(event) => setSearchText(event.target.value)}
            onKeyUp={(event) => {
              if (event.key == 'Enter' && !isAddToFiltersButtonEnabled) addFilter();
            }}
            value={searchText}
          />
        )}
        {mappedSearchDropdownValue?.type === INPUT_TYPE.CURRENCY && (
          <TextField
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            autoComplete="off"
            className={classnames(classes.input, {
              [classes.inputLarge]: !mappedSearchDropdownValueHasOperations,
            })}
            dataCy="Search Text"
            disabled={!shouldDisableField}
            formatters={{
              typing: createFormatterWithReverse(flow(extractNumber, numberFormatter), extractNumber),
            }}
            onChange={(event) => setSearchText(event.target.value)}
            onKeyUp={(event) => {
              if (event.key == 'Enter' && !isAddToFiltersButtonEnabled) addFilter();
            }}
            value={searchText}
          />
        )}
        {mappedSearchDropdownValue?.type === INPUT_TYPE.DECIMALS && (
          <TextField
            autoComplete="off"
            className={classnames(classes.input, {
              [classes.inputLarge]: !mappedSearchDropdownValueHasOperations,
            })}
            dataCy="Search Text"
            disabled={!shouldDisableField}
            formatters={{
              typing: createFormatterWithReverse(flow(extractNumber, numberFormatter), extractNumber),
            }}
            formattersDebounce={250}
            onChange={(event) => setSearchText(event.target.value)}
            onKeyUp={(event) => {
              if (event.key == 'Enter' && !isAddToFiltersButtonEnabled) addFilter();
            }}
            value={searchText}
          />
        )}
        {mappedSearchDropdownValue?.type === INPUT_TYPE.NUMBER && (
          <TextField
            autoComplete="off"
            className={classnames(classes.input, {
              [classes.inputLarge]: !mappedSearchDropdownValueHasOperations,
            })}
            dataCy="Search Text"
            disabled={!shouldDisableField}
            formatters={{
              typing: createFormatterWithReverse(flow(extractNumber), extractNumber),
            }}
            formattersDebounce={250}
            onChange={(event) => setSearchText(event.target.value)}
            onKeyUp={(event) => {
              if (event.key == 'Enter' && !isAddToFiltersButtonEnabled) addFilter();
            }}
            value={searchText}
          />
        )}
        {mappedSearchDropdownValue?.type === INPUT_TYPE.INTERVAL && (
          <IntervalInput
            key={searchText}
            addFilter={addFilter}
            isAddToFiltersButtonEnabled={isAddToFiltersButtonEnabled}
            searchText={searchText}
            setSearchText={setSearchText}
            shouldDisableField={shouldDisableField}
          />
        )}
      </Box>
      <NewButton
        disabled={isAddToFiltersButtonEnabled}
        icon={SearchWhite}
        onClick={addFilter}
        text="Add to filters"
        variant="Primary"
      ></NewButton>
    </>
  );
};

export default SearchInputs;
