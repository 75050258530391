import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FC } from 'react';

import Alert from '#/components/common/Alert/Alert';

interface AlertErrorProps {
  error: FetchBaseQueryError | SerializedError | undefined;
}

const AlertError: FC<AlertErrorProps> = ({ error }) => {
  //@ts-expect-error this is error type from BE
  if (error?.data?.type === 'banner') return <Alert text={error?.data?.message} />;
  //@ts-expect-error this is error type from BE
  if (error?.data?.error === 'Internal Server Error') return <Alert text="Internal Server Error" />;
  return null;
};

export default AlertError;
