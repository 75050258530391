import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

export const useSnackbarSuccess = () => {
  const { enqueueSnackbar } = useSnackbar();

  const setSuccess = useCallback(
    (message: string): void => {
      enqueueSnackbar(message, { variant: 'success' });
    },
    [enqueueSnackbar],
  );

  return { setSuccess };
};
