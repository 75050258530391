import { IPartnerUserAccess } from '../../../../interfaces/partners/users/partnerUsers';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '../../../../interfaces/table/table';

export const PartnersUserAccess = (
  grandAccessAction: (id: string) => void,
  denyUserRequest: (email: string) => void,
  useHasPartnersUsersAccessGrantDenyWrite: boolean,
) => {
  const userAccessTable: ITableColumnsProps<IPartnerUserAccess>[] = [
    {
      fieldName: null,
      align: TableColumnAlign.LEFT,
      customHead: 'Actions',
      type: TableCellTypes.ICONS_ACTIONS,
      actionElementIdentifier: 'id',
      iconsActions: [
        {
          text: 'Grant',
          action: grandAccessAction,
          isButton: true,
          buttonVariant: 'Primary',
        },
        {
          customIdentifier: 'email',
          text: 'Deny',
          action: denyUserRequest,
          isButton: true,
          buttonVariant: 'Secondary',
        },
      ],
      hide: !useHasPartnersUsersAccessGrantDenyWrite,
    },
    {
      fieldName: 'id',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Id',
      hide: true,
    },
    {
      fieldName: 'submissionDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Submission date',
    },
    {
      fieldName: 'firstName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'First Name',
    },
    {
      fieldName: 'lastName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Last Name',
    },
    {
      fieldName: 'companyName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Company',
    },
    {
      fieldName: 'departmentName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Department',
    },
    {
      fieldName: 'title',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Job Title',
    },
    {
      fieldName: 'email',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Email',
    },
    {
      fieldName: 'comments',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Comments',
    },
  ];

  return { userAccessTable };
};
