import { Box } from '@mui/material';

import AppRoutes from '#/constants/routing/AppRoutes';

import { SnackbarWrapper } from './components/common/snackbar/SmackbarWrapper';

function App() {
  return (
    <Box sx={{ background: '#F1F0F7', minHeight: '100vh' }}>
      <SnackbarWrapper>
        <AppRoutes />
      </SnackbarWrapper>
    </Box>
  );
}

export default App;
