import { createStyles, makeStyles } from '#/utils/theme';

export const useTableStyles = makeStyles((theme) =>
  createStyles({
    headCellClass: {
      '&&': {
        borderWidth: '1px',
        border: `1px solid var(--border-extra-light, ${theme.custom.PALETTES.BORDER.Extra_Light})`,
        background: `var(--neutral-100, ${theme.custom.PALETTES.UI.Neutral['100']})`,
        color: `var(--text-dark, ${theme.custom.PALETTES.TEXT.Dark})`,
        fontFamily: 'Public Sans',
        fontSize: '0.71875rem',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        cursor: 'default',
      },
    },
    headClass: {
      '&&': {
        height: '2.8rem',
        color: `var(--text-dark, ${theme.custom.PALETTES.TEXT.Dark})`,
        fontFamily: 'Public Sans',
        fontWeight: theme.custom.FONT_WEIGHTS.REGULAR,
        lineHeight: 'normal',
      },
    },
    taskHyperLink: {
      color: theme.custom.PALETTES.UI.Primary.Base,
    },
    bodyCellClass: {
      height: theme.custom.REM_SPACES['3'],
      fontSize: '0.8125rem',
      fontWeight: theme.custom.FONT_WEIGHTS.REGULAR,
    },
    custom: {
      border: `1px solid ${theme.custom.PALETTES.UI.Neutral['200']}`,
      background: theme.custom.PALETTES.TEXT.Light,
    },
    url: {
      color: theme.custom.PALETTES.UI.Primary.Base,
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    scrollButton: {
      background: 'transparent',
      width: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      color: theme.custom.PALETTES.TEXT.Black,
      border: 'none',
      boxShadow: 'none',
      padding: 0,
      height: theme.custom.REM_SPACES[1],
      '&:hover': {
        boxShadow: 'none',
        background: 'transparent',
      },
    },
    virtualizedTableContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflowX: 'scroll',
      '&::-webkit-scrollbar': {
        width: '1rem',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.custom.PALETTES.UI.Primary['100'],
        borderRadius: '4px',
      },
      '&:hover': {
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.custom.PALETTES.UI.Primary.Base,
        },
      },
    },
    tableContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        backgroundColor: 'transparent',
        width: theme.custom.REM_SPACES[0.25],
        height: theme.custom.REM_SPACES[0.25],
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'transparent',
      },
      '&:hover': {
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.custom.PALETTES.BORDER.Light,
          borderRadius: '2px',
        },
      },
    },

    tableHeadCell: {
      fontWeight: 'bold',
      '& p': {
        margin: '0',
      },
    },
    hidden: {
      display: 'none',
    },
    defaultSelectedTableRow: {
      backgroundColor: '#E4E8EF',
    },
    tableCell: {
      border: '1px solid #E4E8EF',
      '&.hidden': {
        display: 'none',
      },

      '& p': {
        margin: '0',
      },
    },
    link: {
      color: theme.custom.PALETTES.TEXT.Action_Secondary,
      fontWeight: theme.custom.FONT_WEIGHTS.SEMIBOLD,
      cursor: 'pointer',
    },
  }),
);

export const useIconsActionsStyles = makeStyles((theme) =>
  createStyles({
    iconsButtonsContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    iconButton: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      backgroundColor: 'white',
      cursor: 'pointer',
      border: '0 none',
    },
    iconImage: {
      height: 20,
      width: 20,
    },
    iconButtonText: {
      margin: 'none',
      alignSelf: 'center',
    },
    marginRight: {
      marginRight: '5px',
    },
    marginRightMedium: {
      '&&': {
        marginRight: theme.custom.REM_SPACES['0.5'],
      },
    },
  }),
);

export const useEditableCellStyles = makeStyles(() =>
  createStyles({
    approvalButton: {
      display: 'flex',
      alignItems: 'center',
      outline: 'none',
      border: 'none',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      padding: 0,
    },
    approvalIcon: {
      width: 'auto',
      height: 'auto',
    },
    buttonsContainer: {
      display: 'flex',
      marginRight: '3%',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '3.1rem',
    },
    container: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    },
    contentInput: {
      border: 'none',
      padding: '0',
      borderBottom: '1px solid grey',
      outline: 'none',

      '&:disabled': {
        backgroundColor: 'transparent',
        border: 'none',
      },
    },
    editButton: {
      width: '100%',
      border: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  }),
);

export const tooltipSx = {
  color: '#6a717a',
  backgroundColor: 'white',
  border: '1px solid #E4E8EF',
  borderRadius: 5,
  maxWidth: 300,
};

export const tableDefaultPadding = { padding: '0.2rem 0.8rem' };
