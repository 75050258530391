import SearchFilters from '#/components/common/SearchFilters/SearchFilters';
import useSearchFilters from '#/components/common/SearchFilters/useSearchFilters.hook';

import {
  useGetChanelPartnerAccountAllFilteredFiltersQuery,
  useGetChanelPartnerAccountAllFilteredQuery,
} from '#/store/api/partners/promote';

import { CompanyChannelPartnersTable } from '../../../../constants/tables/partners/channelPartners/channelPartners';
import Loader from '../../../common/loader/loader';
import { Table } from '../../../common/table/tableComponent/Table';

const ChannelPartnerRetailer = () => {
  const { data: filterData, isError: filterDataIsError } = useGetChanelPartnerAccountAllFilteredFiltersQuery({});

  const { actions, state } = useSearchFilters(undefined, true, filterData?.filters);

  const {
    data: channelPartnersAccountsData,
    isError: channelPartnersIsError,
    isFetching: channelPartnersIsFetching,
    isLoading: channelPartnersIsLoading,
  } = useGetChanelPartnerAccountAllFilteredQuery({
    pageNumber: state.pageNumber,
    numberOfRows: state.numberOfElementsPerPage,
    searchQuery: state.searchQuery,
  });

  const CompanyChannelPartnersTables = CompanyChannelPartnersTable(filterData ? filterData.filters : []);

  if (channelPartnersIsLoading) return <Loader />;

  return (
    <SearchFilters
      columnNames={filterData?.filters}
      currentIndex={channelPartnersAccountsData?.currentIndex}
      filterDataIsError={filterDataIsError}
      hideViewMode
      isError={channelPartnersIsError}
      isFetching={channelPartnersIsFetching}
      isNoResults={!channelPartnersAccountsData || channelPartnersAccountsData.channelPartners.length === 0}
      totalPages={channelPartnersAccountsData?.totalPages}
      totalResults={channelPartnersAccountsData?.totalResults}
      {...state}
      {...actions}
    >
      <Table
        columns={CompanyChannelPartnersTables}
        data={channelPartnersAccountsData?.channelPartners || []}
        displayStatusColor
        sortProps={{
          sortParams: state.sortParams,
          setSortParams: actions.setSortParam,
        }}
      />
    </SearchFilters>
  );
};
export default ChannelPartnerRetailer;
