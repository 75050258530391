import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { fullPageLoaderSx } from './Loader.style';

export default function FullPageLoader() {
  return (
    <Box sx={fullPageLoaderSx}>
      <CircularProgress />
    </Box>
  );
}
