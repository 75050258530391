import { BrandCommitmentStatus, IBrandsCommittedResponse } from '../interfaces/brandCommitted/brandCommitted';
import { retailerAxios } from './axiosConfig';
import { IRequestDto } from './requestDtoInterface';

export const getBrandsByCommitmentStatus = (
  campaignId: string | undefined,
  status: BrandCommitmentStatus,
  pageNumber: number,
  nrOfElementsInTable: number,
): Promise<IRequestDto<IBrandsCommittedResponse>> => {
  return retailerAxios.post(`instance/view-controller/${campaignId}/all/filtered`, {
    status,
    pageNumber,
    nrOfElementsInTable,
  });
};
