import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { IReportAddFormDTO } from '#/store/api/reports/reportsInterfaces';

export const useAddReportForm = (submitAction: (...args: unknown[]) => Promise<void>) => {
  const schema = yup.object().shape({
    name: yup.string(),
    altId: yup.string(),
    type: yup.string(),
    sql: yup.string(),
    permission: yup.string(),
    // COMMENTED CODE WILL BE REQUIRED IN FUTURE IMPLEMENTATION

    // metadata: yup.array(
    //   yup.object().shape({
    //     fieldName: yup.string(),
    //     align: yup.string(),
    //     type: yup.string(),
    //     isKey: yup.boolean(),
    //     hide: yup.boolean(),
    //     customHead: yup.string(),
    //     linkable: yup.boolean(),
    //     baseUrl: yup.string(),
    //     actionElementIdentifier: yup.string(),
    //     currency: yup.string(),
    //     urlExtraParams: yup.array(
    //       yup.object().shape({
    //         key: yup.string(),
    //         value: yup.string(),
    //       }),
    //     ),
    //   }),
    // ),
    // asyncData: yup.array(
    //   yup.object().shape({
    //     id: yup.string(),
    //     reportId: yup.string(),
    //     type: yup.string(),
    //     sql: yup.string(),
    //     schedule: yup.string(),
    //   }),
    // ),
  });

  const { register, handleSubmit, control } = useForm<IReportAddFormDTO>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (formData: IReportAddFormDTO) => {
    await submitAction(formData);
  });

  return {
    register,
    control,
    onSubmit,
  };
};
