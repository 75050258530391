import Pagination from '@mui/material/Pagination';
import React from 'react';

import { ITablePaginationProps } from '../../../interfaces/table/tablePagination';
import { useTablePaginationsStyles } from './TablePagination.styles';

const TablePagination = ({ totalNumberOfPages, currentPage, setCurrentPage }: ITablePaginationProps) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  const classes = useTablePaginationsStyles();
  return (
    <Pagination
      className={classes.paginationContainer}
      count={totalNumberOfPages}
      onChange={handleChange}
      page={currentPage}
      shape="rounded"
    />
  );
};

export default TablePagination;
