import { Box } from '@mui/material';

import NoResults from '#/components/common/NoResults/NoResults';
import SearchError from '#/components/common/SearchError/SearchError';
import { customStyles, useSearchFiltersStyles } from '#/components/common/SearchFilters/SearchFilters.styles';
import { Filter, FiltersActions, FiltersState } from '#/components/common/SearchFilters/SearchFilters.types';
import ActiveFilters from '#/components/common/SearchFilters/components/ActiveFilters/ActiveFilters';
import SearchInputs from '#/components/common/SearchFilters/components/SearchInputs/SearchInputs';
import TabModeToggle, { TabsIconMapper } from '#/components/common/TabModeToggle';
import Card from '#/components/common/card/card';
import Loader from '#/components/common/loader/loader';
import { Pagination } from '#/components/common/pagination/Pagination';

import { SearchFilters as SearchFiltersType } from '#/store/api/campaigns/campaignsInterfaces';

type SearchFiltersProps<T> = {
  columnNames: SearchFiltersType[] | undefined;
  currentIndex?: number;
  totalPages?: number;
  iconsMapper?: TabsIconMapper<T>[];
  isFetching: boolean;
  isNoResults: boolean;
  totalResults?: number;
  isError?: boolean;
  filterDataIsError?: boolean;
  hidePagination?: boolean;
  hideViewMode?: boolean;
  noVerticalSpace?: boolean;
} & FiltersState<T> &
  FiltersActions;

const SearchFilters = <T,>({
  columnNames,
  numberOfElementsPerPage = 0,
  currentIndex = 0,
  totalPages = 0,
  iconsMapper = [],
  hidePagination = false,
  hideViewMode = false,
  noVerticalSpace = false,
  children,
  searchText,
  filters,
  searchDropdownValue,
  searchOperation,
  viewMode,
  isFetching,
  isNoResults,
  totalResults,
  isError = false,
  filterDataIsError,
  setNumberOfElementsPerPage,
  setViewMode,
  setSearchDropdownValue,
  setSearchOperation,
  setSearchText,
  addFilter,
  clearFilters,
  removeFilter,
  setPage,
}: React.PropsWithChildren<SearchFiltersProps<T>>) => {
  const classes = useSearchFiltersStyles();

  const isFilterActiveAlready = filters.some(
    (filter: Filter) => filter.key === searchDropdownValue && filter.value === searchText.trim(),
  );
  const isActiveFiltersAreaVisible = filters.length !== 0;
  const shouldDisableField = !isFetching;

  const pagination =
    isError || isNoResults ? null : (
      <Card customClass={classes.paginationContainer}>
        {isFetching ? (
          <Loader />
        ) : (
          <Pagination
            itemsPerPage={numberOfElementsPerPage}
            page={currentIndex}
            setItemsPerPage={setNumberOfElementsPerPage}
            setPage={setPage as React.Dispatch<React.SetStateAction<number>>}
            totalPages={totalPages}
            totalResults={totalResults}
          />
        )}
      </Card>
    );

  return (
    <>
      <Card
        customStyles={
          noVerticalSpace
            ? customStyles.noVerticalSpace
            : {
                marginBottom: 0,
              }
        }
      >
        <Box className={classes.container}>
          {!hideViewMode && (
            <TabModeToggle iconsMapper={iconsMapper} selectedTab={viewMode} setSelectedTab={setViewMode} />
          )}
          {!filterDataIsError && columnNames && columnNames?.length !== 0 && (
            <SearchInputs
              addFilter={addFilter}
              columnNames={columnNames}
              isFilterActiveAlready={isFilterActiveAlready}
              operationValue={searchOperation}
              searchDropdownValue={searchDropdownValue}
              searchText={searchText}
              setSearchDropdownValue={setSearchDropdownValue}
              setSearchOperation={setSearchOperation}
              setSearchText={setSearchText}
              shouldDisableField={shouldDisableField}
            />
          )}
        </Box>

        {isActiveFiltersAreaVisible && columnNames && (
          <ActiveFilters
            clearFilters={clearFilters}
            columnNames={columnNames}
            filters={filters}
            removeFilter={removeFilter}
            shouldDisableField={shouldDisableField}
          />
        )}
      </Card>
      <Card customClass={classes.resultContainer}>
        {isFetching ? (
          <Loader />
        ) : isError ? (
          <SearchError clearFilters={clearFilters} />
        ) : isNoResults ? (
          <NoResults />
        ) : (
          children
        )}
      </Card>
      {!hidePagination && pagination}
    </>
  );
};

export default SearchFilters;
