import { TableCell, TableSortLabel, Tooltip } from '@mui/material';
import classnames from 'classnames';

import {
  FilterOrders,
  ISortStateProps,
  ITableColumnsProps,
  TableColumnAlign,
} from '../../../../../interfaces/table/table';
import { tableDefaultPadding, tooltipSx, useTableStyles } from '../Table.style';
import { SortIconComponent } from './SortIconComponent';

export const TableSortHeadCell = <T,>({
  column,
  headCellClass,
  sortProps,
  tablePadding,
  headCustomStyle,
  sortLookUpFieldName,
}: {
  column: ITableColumnsProps<T>;
  headCellClass?: string;
  sortProps: ISortStateProps;
  tablePadding?: string;
  headCustomStyle?: { [key: string]: string };
  sortLookUpFieldName?: string;
}) => {
  const classes = useTableStyles();
  const { sortParams, setSortParams } = sortProps;
  const { column: sortCol, order } = sortParams;

  const getColumnElement = (column: ITableColumnsProps<T>) => {
    return column.customHead ? String(column.customHead) : String(column.fieldName);
  };

  const handleClick = () => {
    let newOrder = FilterOrders.ASC;
    if (sortCol === sortLookUpFieldName ? sortLookUpFieldName : column.fieldName && order) {
      if (order === FilterOrders.ASC) {
        newOrder = FilterOrders.DESC;
      }
    }
    setSortParams({ column: sortLookUpFieldName ? sortLookUpFieldName : String(column.fieldName), order: newOrder });
  };

  // this approach will be removed once classes are fixed
  const tableHeadStyle = {
    padding: tablePadding ? tablePadding : tableDefaultPadding.padding,
    ...(headCustomStyle && { ...headCustomStyle }),
  };

  const cellElement = (
    <TableCell
      key={String(column.fieldName)}
      align={column.align || TableColumnAlign.LEFT}
      className={classnames(classes.tableHeadCell, classes.tableCell, headCellClass, {
        hidden: column.hide,
      })}
      style={tableHeadStyle}
    >
      <TableSortLabel
        IconComponent={() => <SortIconComponent displayDefault={sortCol !== column.fieldName} order={order} />}
        active={sortCol === column.fieldName}
        direction={(order as FilterOrders) || FilterOrders.ASC}
        onClick={handleClick}
      >
        {getColumnElement(column)}
      </TableSortLabel>
    </TableCell>
  );

  if (column.headerTooltip) {
    return (
      <Tooltip
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': tooltipSx,
          },
        }}
        disableFocusListener
        disableTouchListener
        placement="top-start"
        title={column.headerTooltip}
      >
        {cellElement}
      </Tooltip>
    );
  }

  return cellElement;
};
