import { DatePickerProps } from '@mui/lab';
import { FormHelperText } from '@mui/material';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { FC } from 'react';

import CalendarGreyIcon from '#/assets/CalendarGreyIcon.svg';

import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { THEME } from '#/constants/theme/constants';

import { useDataCy } from '#/hooks/useDataCy';

import { useDatePickerStyles } from './DatePicker.styles';

export type ButtonVariantOptions = 'Primary' | 'Secondary' | 'Ghost';
type MUIDatePickerProps = {
  onKeyUp?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  value: string | number;
  onChange: (value: Dayjs | null, context?: unknown) => void;
  editMode?: boolean;
  error?: string;
  className?: string;
  hideTextFieldError?: boolean;
} & (
  | {
      label?: undefined;
      dataCy: string;
    }
  | {
      label: string;
      dataCy?: string;
    }
);

const DatePicker: FC<MUIDatePickerProps> = ({
  label,
  dataCy,
  value,
  onChange,
  editMode = true,
  error,
  className,
  hideTextFieldError = false,
  onKeyUp = () => null,
}) => {
  const classes = useDatePickerStyles();
  const generateDataCy = useDataCy();
  const slotProps: DatePickerProps<Dayjs> = {
    field: {
      className: classes.input,
      id: `DatePicker-${label?.replace(' ', '-')}`,
    },
    textField: {
      size: 'small',
      fullWidth: true,
      onKeyUp,
    },
  };
  if (hideTextFieldError) {
    slotProps.textField.error = false;
  }
  return (
    <div className={className}>
      {label && (
        <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} variant="Label_Base">
          {label}
        </TypographyWithFontFamily>
      )}
      <MUIDatePicker
        className={`${!editMode && classes.disabled}`}
        data-cy={generateDataCy('DatePicker', label ?? dataCy)}
        disabled={!editMode}
        onChange={onChange}
        slotProps={slotProps}
        slots={{
          openPickerIcon: () => <img src={CalendarGreyIcon} />,
        }}
        value={dayjs(value)}
      />
      <FormHelperText error>{error}</FormHelperText>
    </div>
  );
};

export default DatePicker;
