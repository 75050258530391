import { IUrlExtraParams } from '../../../../../interfaces/table/table';

export const getTableExtraParams = (extraParams: IUrlExtraParams) => {
  const paramsString = Object.keys(extraParams)
    .map((param) => `${param}=${extraParams[param]}`)
    .join('&');

  if (!paramsString.length) {
    return '';
  }

  return `?${paramsString}`;
};
