import { IStore } from '#/interfaces/stores/general';
import { IStoreFilters } from '#/interfaces/stores/stores';

import {
  ICampaignBrandOrderPage,
  ICampaignBrandOrderSignedDate,
  SavedBrandOrdersBillingEffectiveDate,
} from '#/store/api/stores/interface';

import { baseApi } from '../../base';

const storesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getStores: builder.query<IStore, { filters: IStoreFilters; valueSelected: string }>({
      query: ({ filters, valueSelected }) => {
        const hasNoFiltersApplied = Object.keys(filters).length === 0 || valueSelected === 'all';
        if (hasNoFiltersApplied) {
          return 'retailer/store/all';
        }
        return {
          url: 'retailer/store/filtered',
          method: 'POST',
          body: filters,
        };
      },
    }),
    getSavedStores: builder.query<IStoreFilters, { campaignFetchingId: string | undefined }>({
      query: ({ campaignFetchingId }) => `retailer/campaign/${campaignFetchingId}/stores-filter`,
    }),
    getSavedBrandOrders: builder.query<ICampaignBrandOrderPage, { instanceId: string | undefined }>({
      query: ({ instanceId }) => `retailer/brand-order/${instanceId}`,
    }),
    getSavedBrandOrdersAfter: builder.query<ICampaignBrandOrderSignedDate, { instanceId: string | undefined }>({
      query: ({ instanceId }) => `retailer/brand-order/retailer/signed-date/${instanceId}`,
    }),
    getSavedBrandOrdersBillingEffectiveDate: builder.query<
      SavedBrandOrdersBillingEffectiveDate,
      { instanceId: string | undefined }
    >({
      query: ({ instanceId }) => `retailer/brand-order/retailer/billing-effective-date/${instanceId}`,
    }),
  }),
});

export const {
  useGetStoresQuery,
  useGetSavedStoresQuery,
  useGetSavedBrandOrdersQuery,
  useGetSavedBrandOrdersAfterQuery,
  useGetSavedBrandOrdersBillingEffectiveDateQuery,
} = storesApi;

export { storesApi };
