export const CampaignEventsUniqueColors = [
  '#DBDCDD',
  '#DBCDDC',
  '#CBDBCC',
  '#CDDDDB',
  '#DBBCCB',
  '#BCBDCD',
  '#BCCCBD',
  '#CCDCCD',
  '#BCBCDB',
  '#BDDCDD',
  '#DDBDBB',
  '#BDBDBB',
  '#BBDBDB',
  '#DCDDBC',
  '#CCDCBD',
  '#CDBDCC',
  '#BBCCBB',
  '#BDCCCB',
  '#DBDBCD',
  '#CCDDBC',
  '#BBCBBB',
  '#BDCBBC',
  '#DDBCBB',
  '#CBCCDC',
  '#CBBDBD',
  '#CDDDDD',
  '#CCBDBC',
  '#CCCCDD',
  '#CCDCDD',
  '#DCCBBC',
  '#DBCDBC',
  '#DBDCCC',
  '#BDBCCC',
  '#BDCCDC',
  '#DDDBDC',
  '#BBDBCB',
  '#BBBDDC',
  '#CBCBCD',
  '#CDDBBC',
  '#CDCCDB',
  '#CCCDDD',
  '#CBCBBB',
  '#CCBDCB',
  '#BBDCBD',
  '#DBDCBB',
  '#BDDCDC',
  '#BDBDBC',
  '#DDCBDB',
  '#CDDCBC',
  '#BDDBBD',
  '#DBBCDB',
  '#CCBCBD',
  '#DCBBCD',
  '#DDBBBD',
  '#DCBCCD',
  '#DDDCBC',
  '#DCDCCD',
  '#DCCCDD',
  '#BBDDBB',
  '#BBCBCC',
  '#BCCDBD',
  '#BDDBCC',
  '#CCDCDB',
  '#CBBBDC',
  '#BCCDDB',
  '#DBCBCD',
  '#CDDDCD',
  '#CCCBBC',
  '#CBDDBC',
  '#BBBCDD',
  '#CDDCBD',
  '#DBBDCB',
  '#DBDBDB',
  '#CBCCCC',
  '#BBBDDB',
  '#CBBDCD',
  '#BBBCBB',
  '#DDBCDB',
  '#CCBDDC',
  '#BDBBDC',
  '#CCCBDB',
  '#DCDBCB',
  '#CBBCDD',
  '#CCBDCC',
  '#CDDCDB',
  '#CBDBBD',
  '#BBCDBB',
  '#DDDDCB',
  '#DCDCDB',
  '#DDCCCD',
  '#CCDDBB',
  '#DDBCCC',
  '#BDBDCB',
  '#DBDCBD',
  '#CDDCDD',
  '#BCBBCC',
  '#BBCCCC',
  '#DBCCCD',
  '#DDCCDB',
  '#DBDDCD',
  '#CBBDBB',
  '#DDBCBC',
  '#DCBDBC',
  '#DBBBDB',
  '#DCDCDC',
  '#DCBDCC',
  '#DCCDDC',
  '#DBCBDC',
  '#DCCCCC',
  '#DBBDBC',
  '#CBDDDB',
  '#CCDCCC',
  '#BDCBDC',
  '#CDCBDC',
  '#BDBBCC',
  '#CBDCBB',
  '#CCDBBD',
  '#DCBBDC',
  '#CCDBCD',
  '#BBBCDB',
  '#DDDCDB',
  '#DCDDDD',
  '#DCDDBB',
  '#BDCDDD',
  '#DBBCBC',
  '#DBBDCC',
  '#BDCBCC',
  '#CDCBCC',
  '#BCDCCC',
  '#BBDBBC',
  '#DBCBCB',
  '#BBBBCD',
  '#CDDCCC',
  '#BBBCDC',
  '#CCCCDC',
  '#BDDCDB',
  '#CBCDCB',
  '#CCCBCC',
  '#BBDBBB',
  '#DCDDDB',
  '#DBBCDC',
  '#DCBDCB',
  '#CCBBDB',
  '#CDDDBC',
  '#BBDDDC',
  '#DBDBCC',
  '#BCCBBD',
  '#DBDBDC',
  '#DDBCDD',
  '#BDCBBB',
  '#DDCDBB',
  '#DBBDDB',
  '#CCBCDC',
  '#BDCDCB',
  '#BDDCBC',
  '#DBCCCB',
  '#CBDCBC',
  '#DDBBBC',
  '#DCBBCC',
  '#BCBCBD',
  '#DCCCBD',
  '#BDCCBC',
  '#BCCDCD',
  '#BDCBDB',
  '#CCDCBB',
  '#DBDBCB',
  '#CDBBBD',
  '#BDBDCD',
  '#BDDDCB',
  '#BBCBBD',
  '#CCCCBB',
  '#CDCDDB',
  '#CCDDDD',
  '#BCBDBD',
  '#CBDBDC',
  '#CCDBDB',
  '#DBDCDB',
  '#CDBCCD',
  '#DDBDDB',
  '#BCBCCD',
  '#BDCDCC',
  '#BCCCDB',
  '#DBCCBD',
  '#BCCDCB',
  '#CDCCBC',
  '#BBCCDD',
  '#DDDBDD',
  '#BCDBCB',
  '#DBCBBB',
  '#CDBDDB',
  '#BCCBDD',
  '#DBCBDB',
  '#BCCBBC',
  '#BDBCDB',
  '#DDCCDD',
  '#CDDCCB',
  '#CBDCDD',
  '#BDDCCD',
  '#DDCBDD',
  '#DDCDDB',
  '#BBBBDD',
  '#DCBBBD',
  '#DBBCCD',
  '#CBCBDC',
  '#BBDCBB',
  '#BBCBCD',
  '#DBCDBD',
  '#BDBCCD',
  '#DCDBDD',
  '#DBDCBC',
  '#CDCBDB',
  '#CCCDCB',
  '#CBCDDC',
  '#BBCCCD',
  '#CDBDBB',
  '#CBBDDB',
  '#DBCCBC',
  '#DDBDBD',
  '#CCDCBC',
  '#CDCBCB',
  '#DCDBBD',
  '#BDDBDD',
  '#BDBCCB',
  '#CBBCDC',
  '#DCDCBB',
  '#BCDBBC',
  '#DDBCDC',
  '#DBBDDD',
  '#CDCCCD',
  '#BDBBBC',
  '#BCBCDC',
  '#CBBDDD',
  '#CBCCCB',
  '#DBCCDD',
  '#BCCDBB',
  '#DDDBCC',
  '#CDBCBC',
  '#CDBDDC',
  '#CBDCCC',
  '#DBDCDC',
  '#BDBBCB',
  '#BDCCCC',
  '#DBBCBD',
  '#CDCCDD',
  '#DCCBBD',
  '#DDBDBC',
  '#BCBDBB',
  '#BBCDBC',
  '#DCDBBB',
  '#DDDBBD',
  '#BCDBBD',
  '#CBBBBB',
  '#CDBBBB',
  '#CBDDDC',
  '#DDDBDB',
  '#BDCCBD',
  '#CDBCDB',
  '#CDBCCC',
  '#CCCBCD',
  '#DCBDDC',
  '#DDDDBB',
  '#BCCCCD',
  '#BDBDDD',
  '#DCCDCB',
  '#CCBCCC',
  '#CCDDDC',
  '#DCDDBD',
  '#DDDBCD',
  '#BDDBBC',
  '#CBBCCB',
  '#CBCCBD',
  '#BCCCBB',
  '#CCBDBD',
  '#DDCDBC',
  '#DCCBDC',
  '#DBBDBB',
  '#BCCDBC',
  '#CDBBBC',
  '#BBDDBD',
  '#DBBBBD',
  '#CDBBDC',
  '#DBDDBD',
  '#DDCBBB',
  '#CCCDBC',
  '#CBCBCB',
  '#DDCBBD',
  '#CDCCBB',
  '#DBCBBC',
  '#BBDCBC',
  '#BBCBBC',
  '#BCBCBC',
  '#BBCCDC',
  '#BBDDCB',
  '#BCBBCD',
  '#BDDBCB',
  '#BDBBDB',
  '#DDBBDC',
  '#CDCBCD',
  '#CDDBDB',
  '#CCBCCD',
  '#CDBCCB',
  '#CBBBBC',
  '#DCBBCB',
  '#CBDBCD',
  '#BBBBDB',
  '#BCCBDB',
  '#BDDDDC',
  '#BDBDDC',
  '#DDBBBB',
  '#DDBBDD',
  '#BCBBDC',
  '#BCBBBC',
  '#CBDBBC',
  '#DDCBBC',
  '#CBBBDB',
  '#BBBDCD',
  '#BCBDCC',
  '#DBCDCD',
  '#DDBBDB',
  '#BBCCBD',
  '#DDBCBD',
  '#CCDDDB',
  '#BBCDCB',
  '#CBBBCB',
  '#BCDCCD',
  '#BCBDBC',
  '#DCDCCC',
  '#DBBCDD',
  '#DDBCCD',
  '#CBDDBB',
  '#BBDDBC',
  '#BBDBDC',
  '#DDCCDC',
  '#BBBDCC',
  '#BDCCCD',
  '#BCDCDB',
  '#CBDDCD',
  '#BCDDBB',
  '#CDDBBD',
  '#BDCDBB',
  '#BDCBCB',
  '#BCBDDB',
  '#CCBCCB',
  '#CBBCCC',
  '#BDBCDC',
  '#DDDDCC',
  '#DBCCDB',
  '#BCBDDD',
  '#CCBDBB',
  '#DDBBCD',
  '#CCBBCB',
  '#DCCBCB',
  '#CBBDDC',
  '#BDCBCD',
  '#DCBDBD',
  '#DCCCBB',
  '#DCBCBB',
  '#CDBBCD',
  '#DCCCCB',
  '#BCDDDB',
  '#CDDCCD',
  '#DCDBCC',
  '#CCCDBD',
  '#BBBDBC',
  '#DDDDDB',
  '#DBCBBD',
  '#CCCCCC',
  '#DCCCBC',
  '#BDCDDB',
  '#BDDDDB',
  '#BBDDCC',
  '#BBCDBD',
  '#BCCDCC',
  '#BDBBBB',
  '#BDBDBD',
  '#BDCCDD',
  '#CDCBDD',
  '#DCCBCD',
  '#CDBBDD',
  '#CDDBCC',
  '#CDBDBC',
  '#CBCDBC',
  '#BCDCBC',
  '#CCCDDC',
  '#BCBCDD',
  '#BBCBCB',
  '#CBDDBD',
  '#BDDDDD',
  '#CBCDCD',
  '#BBBDBB',
  '#CCDDCB',
  '#BCDDDC',
  '#DCDCBC',
  '#BCDDBD',
  '#BCCBDC',
  '#DDCBCD',
  '#DDDCBB',
  '#CDCDBB',
  '#BDDBBB',
  '#CDDCBB',
  '#BBBCCC',
  '#CDBDDD',
  '#DCBCBC',
  '#BCDBDC',
  '#CCDBCC',
  '#CBBDBC',
  '#BBCDDC',
  '#DDCBDC',
  '#BBCDDB',
  '#CBDDDD',
  '#BCDDCB',
  '#CDCDDD',
  '#BBDCCB',
  '#DBDCCD',
  '#CBDBCB',
  '#BDDDBD',
  '#DBDDBC',
  '#CBCBBC',
  '#CBBCDB',
  '#CBBBCC',
  '#DCCBBB',
  '#CDCCCB',
  '#CDBDCB',
  '#CDDBCD',
  '#BBDDDB',
  '#DBBBCB',
  '#DDCDCC',
  '#CBBDCC',
  '#CBCDBB',
  '#DBBBCC',
  '#CDCDBD',
  '#DBCCDC',
  '#DBDBBD',
  '#BCCCBC',
  '#DDDBBC',
  '#CBDDCB',
  '#DBDBBC',
  '#CDCDCB',
  '#CBCCDD',
  '#CCBCBC',
  '#CBCBBD',
  '#BDBDCC',
  '#DDBBCC',
  '#BCBBBD',
  '#CDCBBC',
  '#DCCCDB',
  '#CBDCCB',
  '#BDCDCD',
  '#BDDDCC',
  '#CCCDDB',
  '#CDDCDC',
  '#DDBDCC',
  '#BDBCBC',
  '#DBBBBB',
  '#DBDDCC',
  '#BCDCBB',
  '#CBBCBD',
  '#DDDCBD',
  '#DDCCBB',
  '#CCBBBB',
  '#DBBDDC',
  '#DDDCDC',
  '#CCDBBC',
  '#CDBDCD',
  '#BCDDBC',
  '#CDCCBD',
  '#CDBBCC',
  '#CBBCCD',
  '#DCBBDD',
  '#BDBDDB',
  '#CBDBDD',
  '#DBBBCD',
  '#CBCDCC',
  '#DCDDCB',
  '#BBBBCC',
  '#DDCDDC',
  '#DDBCCB',
  '#BBDBCC',
  '#CBDCCD',
  '#DCCDCC',
  '#BCBCBB',
  '#CBCCDB',
  '#DCCDCD',
  '#BDCCDB',
  '#CCBDDB',
  '#BBDBBD',
  '#CDDDBB',
  '#DCDDCC',
  '#DCBBBB',
  '#DBCCBB',
  '#BCBBDB',
  '#DDBBCB',
  '#BBBBCB',
  '#BDDCCC',
  '#CCBCBB',
  '#BDBBDD',
  '#BDDCBD',
  '#BDCBDD',
  '#CCDBDC',
  '#BCBBCB',
  '#DCDBDC',
  '#DDDDDD',
  '#DDBDDD',
  '#DDCBCC',
  '#DBCCCC',
  '#BDBCBD',
  '#CCCCBC',
  '#DCBDCD',
  '#CDCDBC',
  '#DBBBDD',
  '#BCDBDB',
  '#DCBCDD',
  '#BDDBDB',
  '#CDCDDC',
  '#DCCCCD',
  '#CCBCDD',
  '#DBCDDB',
  '#CCCCCB',
  '#BBDCDC',
  '#BDDCCB',
  '#DBBCCC',
  '#DBDDCB',
  '#BBDCDD',
  '#DCBBBC',
  '#BDBCDD',
  '#BCBCCC',
  '#DCBDBB',
  '#CCBBDD',
  '#CDCDCC',
  '#BDCDBC',
  '#BCCBBB',
  '#CCCCCD',
  '#BBDCCC',
  '#DBDDDB',
  '#DDCDDD',
  '#DDDDBD',
  '#CDDDCC',
  '#DDBDCD',
  '#CDDDDC',
  '#DCBDDB',
  '#DDBDDC',
  '#BCDDCD',
  '#CBBBCD',
  '#DBCBCC',
  '#CCCCBD',
  '#BBBDDD',
  '#CDBBDB',
  '#DCCCDC',
  '#CBDCDC',
  '#BBCDCC',
  '#DCDCBD',
  '#DCDBCD',
  '#CCDDCD',
  '#BBDBDD',
  '#CDCCCC',
  '#BDCCBB',
  '#DDCCBD',
  '#BCBDCB',
  '#BCBDDC',
  '#CCCDBB',
  '#DCBCCB',
  '#BCBBBB',
  '#DBDBBB',
  '#CDDBCB',
  '#DDCBCB',
  '#CCCBDD',
  '#CBBBBD',
  '#CBCCCD',
  '#BBCDDD',
  '#DCBDDD',
  '#CDBCBD',
  '#DDDCCB',
  '#BBCCDB',
  '#DCBCDC',
  '#BCBBDD',
  '#BBBCBC',
  '#DDCDBD',
  '#BDDDBB',
  '#DBCDCB',
  '#DDCCCC',
  '#BCCDDD',
  '#BDCBBD',
  '#DCCBDD',
  '#DBDDBB',
  '#DBBDBD',
  '#CDDDCB',
  '#BBDBCD',
  '#BDBCBB',
  '#CDBDBD',
  '#DBBDCD',
  '#DDDDBC',
  '#DCDBDB',
  '#DCDDDC',
  '#BCDCBD',
  '#BCBCCB',
  '#BBDCCD',
  '#CCDDBD',
  '#CCBBDC',
  '#CBCBCC',
  '#DCCDDD',
  '#BDBBCD',
  '#BDDCBB',
  '#CCBDDD',
  '#CBBDCB',
  '#BDCDDC',
  '#DBCBDD',
  '#BBBBBD',
  '#CCDDCC',
  '#DBBBDC',
  '#CCBBCD',
  '#CCBBBC',
  '#CBBCBB',
  '#DBDDDD',
  '#CCCBCB',
  '#CBCDDB',
  '#DBBCBB',
  '#BCCCDC',
  '#DDCDCB',
  '#DDDBBB',
  '#BCCCDD',
  '#CCBBCC',
  '#BCDBDD',
  '#CCBDCD',
  '#DCBCCC',
  '#DBDCCB',
  '#CDCDCD',
  '#CBCBDD',
  '#BBBCBD',
  '#CDDBDC',
  '#BBCCCB',
  '#BCDCDC',
  '#BBCDCD',
  '#BCDDDD',
  '#BDDBCD',
  '#CCCBBB',
  '#BBBCCD',
  '#DCDCCB',
  '#BCCBCC',
  '#CCDCCB',
  '#CDDBBB',
  '#CBBBDD',
  '#DCCDBD',
  '#CCBCDB',
  '#CCBBBD',
  '#CDCBBD',
  '#BBBBBC',
  '#CCDBDD',
  '#BCDBBB',
  '#CDBBCB',
  '#BBCBDD',
  '#BCDDCC',
  '#DCCBDB',
  '#CCCBBD',
  '#CBDDCC',
  '#CBCCBB',
  '#CCCBDC',
  '#BCDCDD',
  '#BBBCCB',
  '#CCCCDB',
  '#CCCDCC',
  '#DDCCCB',
  '#CBDBDB',
  '#BBBBDC',
  '#CBDBBB',
  '#BBCBDB',
  '#BCCCCB',
  '#DCCDDB',
  '#BDBBBD',
  '#BBBDBD',
  '#DCCDBB',
  '#CDDBDD',
  '#DBCDBB',
  '#DCBCBD',
  '#DCDDCD',
  '#CCDBBB',
  '#DCBCDB',
  '#CDDDBD',
  '#DDDDCD',
  '#DDCCBC',
  '#CDCCDC',
  '#CDBCDD',
  '#CBDCBD',
  '#BDCDBD',
  '#DCCDBC',
  '#DCDBBC',
  '#DCCBCC',
  '#BBDDDD',
  '#DBCDCC',
  '#CBDCDB',
  '#DDDCCD',
  '#CBCCBC',
  '#DDDDDC',
  '#CBCDDD',
  '#BBCBDC',
  '#BBDDCD',
  '#DBCDDD',
  '#BDDDBC',
  '#BCCBCD',
  '#BBBBBB',
  '#BBBDCB',
  '#CBCBDB',
  '#CDBCBB',
  '#DCDCDD',
  '#BCDBCD',
  '#CCDBCB',
  '#DDBDCB',
  '#BDDBDC',
  '#DDDCDD',
  '#BCCBCB',
  '#DCBBDB',
  '#BCDCCB',
  '#CDBCDC',
  '#DDDCCC',
  '#BCCCCC',
  '#DBDDDC',
  '#DBDBDD',
  '#CBBCBC',
  '#CDCBBB',
  '#CCCDCD',
  '#BCDBCC',
  '#BDDDCD',
  '#DDDBCB',
  '#CBCDBD',
  '#DDCDCD',
  '#BBDCDB',
  '#DBBBBC',
  '#BCCDDC',
  '#CCDCDC',
  '#BBCCBC',
];

export default CampaignEventsUniqueColors;
