import { Box } from '@mui/material';

import BellIcon from '#/assets/BellIcon.svg';

import DisplayPageName from '#/components/common/displayPageName/DisplayPageName';
import Loader from '#/components/common/loader/loader';
import { NoDataMessage } from '#/components/common/noDataMessage/NoDataMessage';
import { useSnackbarError } from '#/components/common/snackbar/useSnackbarError';
import { generateAlertsData, generateUrl } from '#/components/features/reports/utils';

import { AlertFields } from '#/interfaces/reports/reports';

import { useGetAlertsQuery } from '#/store/api/dashboard/dashboard';

import { convertAllCapsUnderscoreStrings } from '#/utils/utils';

import * as Styles from './Alerts.styles';
import { marginBottom, pendingTasksNoDataMessageSx, useQuickLinksStyles } from '../Dashboard.styles';

export const Alerts = () => {
  const quickLinksClasses = useQuickLinksStyles();

  const { setError } = useSnackbarError();

  const { data, isLoading, isFetching, error, isError, refetch } = useGetAlertsQuery();

  if (isLoading) {
    return (
      <Box className={quickLinksClasses.wrapperDiv}>
        <Loader />
      </Box>
    );
  }

  if (isError) {
    setError(error);
  }

  if (!data || data?.data.length === 0) {
    return (
      <NoDataMessage
        customStyle={pendingTasksNoDataMessageSx}
        dataLoading={isFetching}
        message="No Alerts Available"
        refetch={refetch}
      />
    );
  }

  const alerts = generateAlertsData(data);
  const redirectTo = generateUrl<AlertFields>(data, 'redirectReportId');

  return (
    <Box className={quickLinksClasses.wrapperDiv}>
      <DisplayPageName
        customStyles={marginBottom}
        customVariant="H4_Base"
        dataLoading={isFetching}
        imgSrc={BellIcon}
        pageName="Alerts"
        redirectTo={redirectTo}
        refetch={refetch}
      />
      <Box className={quickLinksClasses.linksContainer}>
        {alerts.map((alert) => {
          return (
            <Styles.Link key={alert.name} to={alert.url}>
              <Styles.Title>{convertAllCapsUnderscoreStrings(alert.name)}</Styles.Title>

              <Styles.Count>
                <Styles.CountText>{alert.count}</Styles.CountText>
              </Styles.Count>
            </Styles.Link>
          );
        })}
      </Box>
    </Box>
  );
};
