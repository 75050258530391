import { Box } from '@mui/material';
import { FC } from 'react';

import { THEME } from '#/constants/theme/constants';

import EditIcon from '../assets/Edit.svg';
import NewButton from '../components/common/button/NewButton';
import Refetch from '../components/common/reFetchComponent/Refetch';
import { styles, useEditComboButtonStyles } from './EditComboButton.styles';

interface EditComboButtonProps {
  edit: boolean;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  reset: () => void;
  isLoading: boolean;
  onSubmit: () => void;
  refetch: () => void;
  dataLoading: boolean;
}

const EditComboButton: FC<EditComboButtonProps> = ({
  edit,
  setEdit,
  reset,
  dataLoading,
  isLoading,
  refetch,
  onSubmit,
}) => {
  const classes = useEditComboButtonStyles();

  return (
    <Box className={classes.editContainer}>
      {!edit ? (
        <Box className={classes.flexCenter}>
          <Refetch isLoading={dataLoading} reFetchFn={refetch} />
          <NewButton
            icon={EditIcon}
            iconPosition="left"
            onClick={() => {
              setEdit(true);
            }}
            sx={{ marginLeft: THEME.REM_SPACES['0.5'] }}
            text="Edit"
            variant="Ghost"
          />
        </Box>
      ) : (
        <div className={classes.editContainer}>
          <NewButton
            onClick={() => {
              setEdit(false);
              reset();
            }}
            text="Cancel"
            variant="Secondary"
          />
          <NewButton
            disabled={isLoading}
            loading={isLoading}
            onClick={onSubmit}
            sx={styles.saveButton}
            text="Save"
            variant="Primary"
          />
        </div>
      )}
    </Box>
  );
};

export default EditComboButton;
