import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const tableRefreshStyle = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      background: THEME.PALETTES.OTHER.White,
      borderRadius: THEME.REM_SPACES[2],
      padding: THEME.REM_SPACES[1],
      marginBottom: THEME.REM_SPACES[1],
      width: '100%',
      minWidth: THEME.REM_SPACES[15],
      marginRight: THEME.REM_SPACES[1],
    },
  }),
);
