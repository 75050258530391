import { createStyles, makeStyles } from '#/utils/theme';

export const useAssetProductStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginBottom: theme.custom.REM_SPACES['1'],
      padding: theme.custom.REM_SPACES['1'],
    },
    content: {
      display: 'flex',
      alignItems: 'flex-end',
      columnGap: theme.custom.REM_SPACES['1'],
    },
    labelUppercase: {
      textTransform: 'uppercase',
    },
    errorContainer: {
      marginTop: theme.custom.REM_SPACES['1'],
    },
  }),
);
