import { IResource } from '../../../hooks/useGetResource';
import { baseApi } from '../../base';
import { IChannelAndTacticsResponseDisplayShell, IEditDetails } from './campaignsInterfaces';

const campaignChannelsAndTacticsBrand = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCampaignTiersBrand: builder.query<IChannelAndTacticsResponseDisplayShell, { resource: IResource }>({
      query: ({ resource }) => `/retailer/campaign/brand/${resource.resourceId}/tiers/all`,
      providesTags: ['CAMPAIGN_CHANNEL_AND_TACTICS'],
    }),
    getCampaignTiersDetailsBrand: builder.query<IEditDetails, { campaignTacticId: string | undefined }>({
      query: ({ campaignTacticId }) => `/retailer/campaign-tactic/brand/${campaignTacticId}/details`,
      providesTags: ['CAMPAIGN_CHANNEL_AND_TACTICS'],
    }),
  }),
});

export const { useGetCampaignTiersBrandQuery, useGetCampaignTiersDetailsBrandQuery } = campaignChannelsAndTacticsBrand;

export { campaignChannelsAndTacticsBrand };
