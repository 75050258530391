import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { useSnackbarError } from '#/components/common/snackbar/useSnackbarError';
import { useSnackbarSuccess } from '#/components/common/snackbar/useSnackbarSuccess';
import { useWorkflowStyles } from '#/components/features/settings/workflows/components/Workflow.styles';

import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { Workflow } from '#/store/api/workflow/workflowInterfaces';

const useWorkflowsTableColumns = (workflow: Workflow[], putWorkflowActivate, isLoading) => {
  const classes = useWorkflowStyles();
  const { setSuccess } = useSnackbarSuccess();
  const { setError } = useSnackbarError();
  const columns: ITableColumnsProps<Workflow>[] = [
    {
      fieldName: null,
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.CHECKBOX,
      customHead: 'Active',
      checkBoxAction: async (checked, id) => {
        if (checked) {
          try {
            await putWorkflowActivate({ workflowId: id }).unwrap();
            setSuccess('Workflow updated successfully');
          } catch (error) {
            setError((error as FetchBaseQueryError).data);
          }
        }
      },
      checkFromList: [workflow.find((el) => el.active === true)?.id || ''],
      hasCheckAll: false,
      checkBoxLoading: isLoading,
      actionElementIdentifier: 'id',
      itemClass: classes.checkBox,
    },
    {
      fieldName: 'workflowName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Workflow Name',
    },
    {
      fieldName: 'workflowVersionTag',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Version Tag',
    },
  ];
  return columns;
};

export default useWorkflowsTableColumns;
