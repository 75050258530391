import { Box, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { downloadReport } from '#/api/reports/reports';

import SearchFilters from '#/components/common/SearchFilters/SearchFilters';
import useSearchFilters from '#/components/common/SearchFilters/useSearchFilters.hook';
import MemoizedReportsDetailsVirtualizedTable from '#/components/common/table/tableComponent/VirtualizedTable';
import { THEME } from '#/constants/theme/constants';

import { useDataCy } from '#/hooks/useDataCy';

import { useGetReportDetailsQuery, useGetReportFilterQuery } from '#/store/api/reports/reports';

import DownloadIcon from '../../../assets/DownloadIcon.svg';
import RefreshIcon from '../../../assets/Refresh.svg';
import Loader from '../../common/loader/loader';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import { useReportsPageStyles } from './Reports.style';
import { generateTableProps } from './utils';

export const ReportDetails = () => {
  const { reportId } = useParams();
  const classes = useReportsPageStyles();
  const generateDataCy = useDataCy();

  const { data: filterData, isError: filterDataIsError } = useGetReportFilterQuery({ reportId });

  const { actions, state } = useSearchFilters(undefined, true, filterData?.filters);

  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const {
    data: reportData,
    isError: reportIsError,
    isFetching: reportIsFetching,
    isLoading: reportIsLoading,
    refetch: reportRefetch,
  } = useGetReportDetailsQuery({
    reportId,
    searchQuery: state.searchQuery,
  });

  const onDownloadFile = async () => {
    setIsDownloadLoading(true);
    const response = await downloadReport(reportId, encodeURIComponent(state.searchQuery));
    setIsDownloadLoading(false);
    const match = response?.headers['content-disposition'].match(/filename="([^"]+)"/);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(response?.data);
    link.setAttribute('download', match?.[1] || 'Report');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  if (reportIsLoading) return <Loader />;

  const { tableColumns, tableValues } = generateTableProps(reportData);

  return (
    <>
      <Box className={classes.buttonsContainer} data-cy={generateDataCy('box', 'download-button-container')}>
        <TypographyWithFontFamily variant="H5_Base">Data</TypographyWithFontFamily>
        <Box className={classes.buttonsContainer} data-cy={generateDataCy('box', 'head-buttons-container')}>
          <button className={classes.downloadButton} onClick={reportRefetch}>
            <img alt="Refresh" src={RefreshIcon} />
          </button>

          <button className={classes.downloadButton} onClick={onDownloadFile}>
            {isDownloadLoading ? (
              <CircularProgress size={`${THEME.REM_SPACES[1]}`} />
            ) : (
              <img alt="Download" src={DownloadIcon} />
            )}
          </button>
        </Box>
      </Box>

      <SearchFilters
        columnNames={filterData?.filters}
        filterDataIsError={filterDataIsError}
        hidePagination
        hideViewMode
        isError={reportIsError}
        isFetching={reportIsFetching}
        isNoResults={!reportData || !reportData.data.length || !reportData.head.length}
        noVerticalSpace
        {...state}
        {...actions}
      >
        <MemoizedReportsDetailsVirtualizedTable
          bodyCellClass={classes.bodyCell}
          columns={tableColumns}
          data={tableValues}
          data-cy={generateDataCy('table', 'reports')}
          sortProps={{
            sortParams: state.sortParams,
            setSortParams: actions.setSortParam,
          }}
          tableClass={classes.tableClass}
        />
      </SearchFilters>
    </>
  );
};
