import { Box, Grid } from '@mui/material';
import { FC, useState } from 'react';

import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';

import { useAddTireForm } from '../../../../../../hooks/forms/campaigns/useAddTireForm';
import { IChannelAndTacticsResponseTier } from '../../../../../../interfaces/forms/campaigns';
import { useCampaignTierStyle } from '../../../CampaignTier.style';
import NewCampaignTierTacticsTableChannelPartner from './NewCampaignTierTacticsTableChannelPartner';

interface ICampaignTierChannelPartnerProps {
  tier?: IChannelAndTacticsResponseTier;
}

const CampaignTierChannelPartner: FC<ICampaignTierChannelPartnerProps> = ({ tier }) => {
  const [editMode, setEditMode] = useState(false);
  const { errors, register, onSubmitEdit } = useAddTireForm(() => setEditMode(false), tier);

  const classes = useCampaignTierStyle();

  return (
    <Box className={classes.tierContainer}>
      <Box>
        <form className={classes.form} onSubmit={onSubmitEdit}>
          <Grid columnGap={'0.5rem'} container>
            <Grid item xs={2.9}>
              <TextFieldOld
                editMode={editMode}
                error={errors.tierName?.message}
                label="TIER NAME"
                register={register('tierName')}
                value={tier?.tierName}
              />
            </Grid>
            <Grid item xs={2.9}>
              <TextFieldOld
                editMode={editMode}
                error={errors.targetBrands?.message}
                label="TARGET BRANDS"
                register={register('targetBrands')}
                value={tier?.targetBrands?.toString()}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box className={classes.tacticsTableContainer}>
        <NewCampaignTierTacticsTableChannelPartner tactics={tier?.tactics} />
      </Box>
    </Box>
  );
};
export default CampaignTierChannelPartner;
