import { TableHead as MUTHead, TableRow } from '@mui/material';
import classnames from 'classnames';

import { ISortStateProps, ITableColumnsProps } from '../../../../../interfaces/table/table';
import { useTableStyles } from '../Table.style';
import { TableHeadCell } from './TableHeadCell';
import { TableSortHeadCell } from './TableSortHeadCell';

export const TableHead = <T,>({
  columns,
  hiddenTableHead,
  headCellClass,
  headClass,
  sortProps,
  tablePadding,
  headCustomStyle,
}: {
  columns: ITableColumnsProps<T>[];
  hiddenTableHead: boolean;
  headCellClass?: string;
  headClass?: string;
  sortProps?: ISortStateProps;
  tablePadding?: string;
  headCustomStyle?: { [key: string]: string };
}) => {
  const classes = useTableStyles();

  return (
    <MUTHead className={classnames({ [classes.hidden]: hiddenTableHead }, headClass)}>
      <TableRow>
        {columns.map((col) => {
          if (col.sortable && sortProps) {
            return (
              <TableSortHeadCell
                key={String(col.fieldName)}
                column={col}
                headCellClass={headCellClass}
                headCustomStyle={headCustomStyle}
                sortLookUpFieldName={col.sortLookUpFieldName}
                sortProps={sortProps}
                tablePadding={tablePadding}
              />
            );
          }
          return (
            <TableHeadCell
              key={String(col.fieldName)}
              column={col}
              headCellClass={headCellClass}
              headCustomStyle={headCustomStyle}
              tablePadding={tablePadding}
            />
          );
        })}
      </TableRow>
    </MUTHead>
  );
};
