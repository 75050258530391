import AssociateUserToCompany from '#/components/addUserWizard/AssociateUserToCompany';

import { useGetCompaniesAddUserRetailerFilterQuery } from '#/store/api/company/company';

import Loader from '../common/loader/loader';

const AssociateUserToCompanyContainer = () => {
  const { isLoading: filterDataIsLoading } = useGetCompaniesAddUserRetailerFilterQuery({});

  if (filterDataIsLoading) return <Loader />;

  return <AssociateUserToCompany />;
};

export default AssociateUserToCompanyContainer;
