import { createStyles, makeStyles } from '#/utils/theme';

import brandConstants, { THEME } from '../../../constants/theme/constants';

export const useReportsPageStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: '100%',
    },
    active: {
      backgroundColor: THEME.PALETTES.UI.Secondary[200],
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: `0 ${THEME.REM_SPACES[1]}`,
    },
    dataContainer: {
      border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      padding: THEME.REM_SPACES[1],
      flexGrow: 1,
      borderRadius: THEME.REM_SPACES[1],
      overflow: 'hidden',
    },
    divider: {
      borderBottom: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      marginBottom: THEME.REM_SPACES[1],
    },
    downloadButton: {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '20%',
      maxWidth: '20%',
      overflowY: 'auto',
      borderRight: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      marginRight: THEME.REM_SPACES[1],
      paddingRight: THEME.REM_SPACES[1],
      paddingBottom: THEME.REM_SPACES[1],
    },
    listElement: {
      textDecoration: 'none',
      color: THEME.PALETTES.TEXT.Dark,
      fontWeight: THEME.FONT_WEIGHTS.REGULAR,
      fontSize: theme.custom.FONT_SIZE.xs,
      padding: THEME.REM_SPACES[0.5],
      borderRadius: THEME.REM_SPACES[0.5],
    },
    runContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      marginBottom: THEME.REM_SPACES[0.5],
    },
    searchField: { width: '100%', borderRadius: THEME.REM_SPACES[0.5] },
    searchFieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: THEME.REM_SPACES[0.5],
      fontSize: brandConstants.FONT_MD,

      '& p': {
        margin: 0,
      },
    },
    listTitleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: THEME.REM_SPACES[1],
    },
    expandButtonContainer: {
      width: 'auto',
      minWidth: 0,
    },
    tableClass: {
      '& td': {
        height: '1rem',
      },
    },
    bodyCell: {
      textWrap: 'nowrap',
      whiteSpace: 'pre',
    },
  }),
);

export const pageContainer = {
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  overflow: 'hidden',
};

export const pageTitleSx = {
  marginBottom: '0',
};

export const infoCursorTooltipSx = {
  cursor: 'help',
};
