import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { SearchFilters } from '#/store/api/campaigns/campaignsInterfaces';
import { CampaignInstanceChannelPartner } from '#/store/api/campaigns/instancesInterfaces';

import { convertAllCapsUnderscoreStrings, formatDate } from '#/utils/utils';

const getCampaignInstancesChannelPartnerTableColumns = (
  columNames: SearchFilters[] = [],
): ITableColumnsProps<CampaignInstanceChannelPartner>[] => {
  const dateFormatter = (text: string | number) => formatDate(new Date(text));

  const BookedTacticInventoryCell = ({ rowData }: { rowData: CampaignInstanceChannelPartner }) =>
    `${rowData?.numberOfBookedTactics} of ${rowData?.numberOfTactics}`;

  const sortableColumns = columNames.map((el) => el.columnName);

  return [
    {
      fieldName: 'displayName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Name',
      linkable: true,
      baseUrl: `/instances/overview`,
      actionElementIdentifier: 'instanceId',
      sortable: sortableColumns.includes('displayName'),
    },
    {
      fieldName: 'brandName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Brand Name',
      sortable: sortableColumns.includes('brandName'),
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Status',
      textFormatter: convertAllCapsUnderscoreStrings,
      sortable: sortableColumns.includes('status'),
    },
    {
      fieldName: 'numberOfTactics',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: '# of Tactics',
      sortable: sortableColumns.includes('numberOfTactics'),
    },
    {
      fieldName: null,
      align: TableColumnAlign.CENTER,
      actionElementIdentifier: 'instanceId',
      type: TableCellTypes.REACT_TABLE_DATA_ELEMENT,
      element: BookedTacticInventoryCell,
      customHead: 'Booked Tactic Inventory',
    },
    {
      fieldName: 'pendingAssets',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Pending Assets',
      textFormatter: (text) => text?.toString() || '0',
      sortable: sortableColumns.includes('pendingAssets'),
    },
    {
      fieldName: 'inReviewAssets',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'In Review',
      textFormatter: (text) => text?.toString() || '0',
      sortable: sortableColumns.includes('inReviewAssets'),
    },
    {
      fieldName: 'approvedAssets',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Approved',
      textFormatter: (text) => text?.toString() || '0',
      sortable: sortableColumns.includes('approvedAssets'),
    },
    {
      fieldName: 'totalAssets',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Total',
      textFormatter: (text) => text?.toString() || '0',
      sortable: sortableColumns.includes('totalAssets'),
    },
    {
      fieldName: 'startDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Start Date',
      textFormatter: dateFormatter,
      sortable: sortableColumns.includes('startDate'),
    },
    {
      fieldName: 'endDate',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'End Date',
      textFormatter: dateFormatter,
      sortable: sortableColumns.includes('endDate'),
    },
  ];
};

export default getCampaignInstancesChannelPartnerTableColumns;
