import { FC } from 'react';

import AlertError from '#/components/common/Alert/AlertError/AlertError';
import Card from '#/components/common/card/card';
import { Table } from '#/components/common/table/tableComponent/Table';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import useWorkflowTableColumns from '#/components/features/settings/workflows/components/Workflow.columns';
import { useWorkflowStyles } from '#/components/features/settings/workflows/components/Workflow.styles';
import { getAggregateColumnMapper } from '#/constants/tables/taskTable/tasksTable';

import { usePutWorkflowActivateMutation } from '#/store/api/workflow/workflow';
import { Workflow as IWorkflow } from '#/store/api/workflow/workflowInterfaces';

import { camelOrPascalToRegular } from '#/utils/textFormatters';

interface WorkflowProps {
  aggregateType: string;
  workflows: IWorkflow[];
}

const Workflow: FC<WorkflowProps> = ({ aggregateType, workflows }) => {
  const [putWorkflowActivate, { isLoading, error }] = usePutWorkflowActivateMutation();

  const columns = useWorkflowTableColumns(workflows, putWorkflowActivate, isLoading);
  const classes = useWorkflowStyles();

  return (
    <Card>
      <AlertError error={error} />
      <TypographyWithFontFamily className={classes.header} variant="H4_Base">
        {camelOrPascalToRegular(getAggregateColumnMapper(aggregateType))}
      </TypographyWithFontFamily>
      <Table columns={columns} containerClass={classes.columns} data={workflows || []} />
    </Card>
  );
};

export default Workflow;
