import { createStyles, makeStyles } from '#/utils/theme';

export const useNextActionStyles = makeStyles(() =>
  createStyles({
    section: {
      background: '#fff',
      borderRadius: '30px',
      padding: '1.25rem',
      marginBottom: '1.25rem',
      display: 'flex',
      alignItems: 'center',
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'flexStart',
      flexDirection: 'column',
      width: '100%',
      fontSize: '13px',
      color: '#555',
    },
    menuItem: {
      textTransform: 'capitalize',
    },
    selector: {
      width: '250px',
      marginBottom: '1.25rem',
    },
    textArea: {
      resize: 'none',
      borderRadius: 5,
      fontFamily: 'Public Sans',
      fontSize: '18px',
      color: 'black',
      marginBottom: '2.5rem',
    },
    testAreaDisabled: {
      resize: 'none',
      borderRadius: 5,
      fontFamily: 'Public Sans',
      fontSize: '18px',
      margin: '1.25rem 0',
      color: '#606670',
      marginBottom: '2.5rem',
    },
    submit: {
      width: '200px',
      marginBottom: '1.25rem',
    },
  }),
);
