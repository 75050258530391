import { IResource } from '../../../hooks/useGetResource';
import { IDynamicRibbonRequestResponse } from '../../../interfaces/dynamicRibbon/dynamicRibbon';
import { baseApi } from '../../base';
import { IChannelAndTacticsResponseDisplayShell, IEditDetails } from '../campaigns/campaignsInterfaces';

const channelsAndTacticsChannelPartnerApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCampaignTiersInstanceChannelPartner: builder.query<
      IChannelAndTacticsResponseDisplayShell,
      { resource: IResource }
    >({
      query: ({ resource }) => `/retailer/instances/channel-partner/${resource.resourceId}/tiers/all`,

      providesTags: ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS'],
    }),
    putCampaignTacticChannelPartnerCampaignInventoryConfirmInstance: builder.mutation<
      void,
      { campaignTacticId: string | undefined }
    >({
      query: (campaignTacticId) => ({
        url: `/retailer/instance-tactic/channel-partner/${campaignTacticId}/campaign-inventory-book`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS']),
    }),
    putCampaignTacticChannelPartnerCampaignInventoryRejectInstance: builder.mutation<
      void,
      { campaignTacticId: string | undefined }
    >({
      query: (campaignTacticId) => ({
        url: `/retailer/instance-tactic/channel-partner/${campaignTacticId}/campaign-inventory-reject`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS']),
    }),
    putEditUnitsInstance: builder.mutation<void, { instanceTacticId: string | undefined; units: string }>({
      query: ({ instanceTacticId, units }) => ({
        url: `/retailer/instance-tactic/channel-partner/${instanceTacticId}/edit-units`,
        method: 'PUT',
        body: {
          units,
        },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS']),
    }),
    getCampaignTiersDetailsChannelPartnerInstance: builder.query<
      IEditDetails,
      { instanceTacticId: string | undefined }
    >({
      query: ({ instanceTacticId }) => `/retailer/instance-tactic/channel-partner/${instanceTacticId}/details`,
      providesTags: ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS'],
    }),
    getChannelPartnerDynamicRibbonInstance: builder.query<
      IDynamicRibbonRequestResponse,
      { instanceId: string | undefined }
    >({
      query: ({ instanceId }) => `/retailer/ribbon/channel-partner/campaign-instance/${instanceId}`,
      providesTags: ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS'],
    }),
  }),
});

export const {
  useGetCampaignTiersInstanceChannelPartnerQuery,
  usePutCampaignTacticChannelPartnerCampaignInventoryRejectInstanceMutation,
  usePutCampaignTacticChannelPartnerCampaignInventoryConfirmInstanceMutation,
  usePutEditUnitsInstanceMutation,
  useGetCampaignTiersDetailsChannelPartnerInstanceQuery,
  useGetChannelPartnerDynamicRibbonInstanceQuery,
} = channelsAndTacticsChannelPartnerApi;

export { channelsAndTacticsChannelPartnerApi };
