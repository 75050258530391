import { createStyles, makeStyles } from '#/utils/theme';

export const useCompanyGeneralFormStyles = makeStyles((theme) =>
  createStyles({
    viewGrid: {
      columnGap: theme.custom.REM_SPACES[3.5],
      rowGap: theme.custom.REM_SPACES[0.5],
    },
    wrap: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.custom.REM_SPACES['1'],
      justifyContent: 'space-between',
    },
    name: {
      minWidth: '20rem',
    },
  }),
);
