import { Card } from '@mui/material';
import { useParams } from 'react-router-dom';

import { useGetBrandOrderGeneralInformationQuery } from '../../../../../../../../store/api/campaigns/campaigns';
import Loader from '../../../../../../../common/loader/loader';
import { NoDataMessage } from '../../../../../../../common/noDataMessage/NoDataMessage';
import { useSnackbarError } from '../../../../../../../common/snackbar/useSnackbarError';
import { GeneralInformationRow } from '../GeneralInformationRow/GeneralInformationRow';
import { useBrandOrderGeneralInformationStyle } from './BrandOrderGeneralInformation.styles';

export const BrandOrderGeneralInformation = () => {
  const { campaignId } = useParams();
  const { setError } = useSnackbarError();
  const classes = useBrandOrderGeneralInformationStyle();
  const { data, error, isLoading } = useGetBrandOrderGeneralInformationQuery({ campaignId });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    setError(error);
  }

  if (!data) {
    return <NoDataMessage message="No Data Available" />;
  }

  return (
    <Card className={classes.container}>
      {Object.keys(data).map((element, index) => (
        <GeneralInformationRow key={index} label={element} value={data[element]} />
      ))}
    </Card>
  );
};
