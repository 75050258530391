import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useSnackbarError } from '#/components/common/snackbar/useSnackbarError';

import {
  useEditInstanceMetadataFormMutation,
  useEditShellMetadataFormMutation,
} from '#/store/api/campaigns/tacticMetadataForm';

import { ITacticMetadataDTO, TacticMetadataForm } from '../../../interfaces/tacticMetadata/tacticMetadata';
import { returnYupSchemaShape } from './utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useTacticMetadataForm = (
  inputs: ITacticMetadataDTO[],
  isInstance: boolean,
  tacticId: string | undefined,
  onClose?: () => void,
) => {
  const schema = yup.object().shape(returnYupSchemaShape(inputs));

  const { setError } = useSnackbarError();

  const [triggerEditInstanceMetadataForm] = useEditInstanceMetadataFormMutation();
  const [triggerEditShellMetadataForm] = useEditShellMetadataFormMutation();

  const putRequest = isInstance ? triggerEditInstanceMetadataForm : triggerEditShellMetadataForm;

  const {
    register,
    clearErrors,
    control,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<TacticMetadataForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (formData: TacticMetadataForm) => {
    try {
      await putRequest({ formData: JSON.stringify({ metadata: formData }), id: tacticId });
      if (onClose) {
        onClose();
      }
    } catch (error) {
      setError(error);
    }
  });

  return {
    register,
    clearErrors,
    control,
    watch,
    setValue,
    reset,
    onSubmit,
    errors,
    schema,
  };
};
