import CommunicationsSelectBrandsTable from '#/components/features/communications/tables/CommunicationsSelectBrandsTable';

import { useAppSelector } from '#/hooks';

import { useGetPromoteCompaniesFilterQuery } from '#/store/api/communications/communications';

import Loader from '../../../common/loader/loader';

const CommunicationsSelectBrandsTableContainer = ({ brandsFilters }) => {
  const { selectedCampaigns } = useAppSelector((state) => state.communications);

  const { isLoading: filterDataIsLoading } = useGetPromoteCompaniesFilterQuery({
    singleCampaign: selectedCampaigns.length === 1,
  });

  if (filterDataIsLoading) return <Loader />;

  return <CommunicationsSelectBrandsTable brandsFilters={brandsFilters} />;
};
export default CommunicationsSelectBrandsTableContainer;
