import { Box, Checkbox, Grid } from '@mui/material';
import { Control, Controller, FieldErrors, UseFormWatch } from 'react-hook-form';

import { THEME } from '../../../../constants/theme/constants';
import { ITacticMetadataDTO, TacticMetadataForm } from '../../../../interfaces/tacticMetadata/tacticMetadata';
import { InfoTooltip } from '../../../features/reports/InfoTooltip';
import { TypographyWithFontFamily } from '../../typography/TypographyWithFontFamily';
import { checkboxListContainerSx, errorTextSx, useTacticMetadataFormStyle } from '../TacticMetadataForm.style';

interface IProps {
  errors: FieldErrors<TacticMetadataForm>;
  input: ITacticMetadataDTO;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<TacticMetadataForm, any>;
  watch: UseFormWatch<TacticMetadataForm>;
  helperTextSubtitle?: boolean;
  editMode?: boolean;
}

export const CheckBoxListInput = ({
  errors,
  input,
  control,
  watch,
  helperTextSubtitle = false,
  editMode = true,
}: IProps) => {
  const classes = useTacticMetadataFormStyle();
  const existingErrors = errors[String(input.id)]?.message || '';

  const checkedValues = watch(String(input.id));

  return (
    <Box sx={checkboxListContainerSx}>
      <Grid container spacing={{ xs: '1rem' }} xs={12}>
        <Grid container xs={12}>
          <Grid item xs={9}>
            <Box className={classes.checkBoxList}>
              <TypographyWithFontFamily
                cName={classes.labelUppercase}
                color={THEME.PALETTES.TEXT.Gray}
                variant="Label_Base"
              >
                {input.label} {input.required ? '*' : ''}
              </TypographyWithFontFamily>
              {helperTextSubtitle && (
                <TypographyWithFontFamily gutterBottom variant={'Label_Base'}>
                  {input.helperText}
                </TypographyWithFontFamily>
              )}
              {input.listValues?.map((inputValue) => {
                let isChecked = false;
                if (inputValue.value && checkedValues) {
                  isChecked = !!checkedValues[inputValue.value];
                }
                return (
                  <Grid key={inputValue.displayValue} item xs={12}>
                    <Controller
                      control={control}
                      name={`${String(input.id)}.${inputValue.value}`}
                      render={({ field }) => (
                        <Box className={classes.checkBoxContainer}>
                          <Checkbox
                            checked={isChecked}
                            disabled={input.isDisabled || !editMode}
                            onChange={field.onChange}
                            value={field.value}
                          />
                          <TypographyWithFontFamily>{inputValue.displayValue}</TypographyWithFontFamily>
                        </Box>
                      )}
                    />
                  </Grid>
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={3}>
            {!helperTextSubtitle && input.helperText && <InfoTooltip text={input.helperText} />}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TypographyWithFontFamily sx={errorTextSx} variant="Default_Base">
            {String(existingErrors)}
          </TypographyWithFontFamily>
        </Grid>
      </Grid>
    </Box>
  );
};
