import { Link } from 'react-router-dom';

import TableLogoCell from '#/components/common/tableLogoCell/TableLogoCell';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { useBrandStyles } from '#/components/features/partners/brands/brands.styles';
import { ROUTE_PATHS } from '#/constants/routing/routePaths';

import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { CompanyAccount } from '#/store/api/company/companyInterfaces';

import { formatColumnToHaveComma } from '#/utils/utils';

const { PARTNERS, BRANDS, PARTNERS_AGENCIES, PARTNERS_USERS } = ROUTE_PATHS;

interface ICustomElementContactKeys {
  id: string;
  name: string;
  type?: string;
}

export const getCompaniesTableColumns = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columNames: any = [],
) => {
  const styles = useBrandStyles();
  const sortableColumns = columNames.map((el) => el.columnName);
  const formatTypeName = (text: string | number) => {
    if (typeof text === 'number') {
      return text.toString();
    }

    return text.charAt(0) + text.slice(1).toLowerCase();
  };

  const getCustomElement = (val: ICustomElementContactKeys[]) => {
    if (!val) {
      return null;
    }
    return (
      <TypographyWithFontFamily cName={styles.customHrefColor}>
        {val.map((el, index: number) => {
          return (
            <Link key={el.id} to={`/${PARTNERS}/${PARTNERS_USERS}/${el.id}`}>
              {formatColumnToHaveComma(el.name, index, val)}
            </Link>
          );
        })}
      </TypographyWithFontFamily>
    );
  };

  // TODO: replace companyType with el.type
  const getAssociatedCompanyElement = (val: ICustomElementContactKeys[]) => {
    if (!val) {
      return null;
    }
    return (
      <TypographyWithFontFamily cName={styles.customHrefColor}>
        {val.map((el, index: number) => {
          if ('type' in el) {
            return (
              <Link key={el.id} to={`/${PARTNERS}/${el.type === 'AGENCY' ? PARTNERS_AGENCIES : BRANDS}/${el.id}`}>
                {formatColumnToHaveComma(el.name, index, val)}
              </Link>
            );
          }
          return (
            <TypographyWithFontFamily key={el.id} className={styles.displayInline}>
              {formatColumnToHaveComma(el.name, index, val)}
            </TypographyWithFontFamily>
          );
        })}
      </TypographyWithFontFamily>
    );
  };

  const createCustomNameImageView = (
    companyId: string,
    companyType: string,
    logoId: string | null,
    companyName: string,
  ) => (
    <TableLogoCell
      link={
        companyType === 'AGENCY'
          ? `/${PARTNERS}/${PARTNERS_AGENCIES}/${companyId}`
          : `/${PARTNERS}/${BRANDS}/${companyId}`
      }
      logoId={logoId}
      name={companyName}
    />
  );

  const formatProductMixItems = (val: string[]) => (
    <>{val.map((el, index: number) => formatColumnToHaveComma(el, index, val))}</>
  );

  const companyTable: ITableColumnsProps<CompanyAccount>[] = [
    {
      fieldName: 'aggregateId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Campaign Id',
      hide: true,
    },
    {
      fieldName: 'companyName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Company Name ',
      actionElementIdentifier: 'aggregateId',
      actionElementSecondIdentifier: 'companyType',
      actionElementThirdIdentifier: 'logoId',
      actionElementFourthIdentifier: 'companyName',
      getCustomElement: createCustomNameImageView,
      sortable: sortableColumns.includes('companyName'),
    },
    {
      fieldName: 'companyType',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      textFormatter: formatTypeName,
      customHead: 'Company Type',
      sortable: sortableColumns.includes('companyType'),
    },
    {
      fieldName: 'parentName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Parent Company',
      sortable: sortableColumns.includes('companyParent'),
    },
    {
      fieldName: 'vendorId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Vendor ID',
      sortable: sortableColumns.includes('vendorId'),
    },
    {
      fieldName: 'associatedCompanies',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.CUSTOM_ELEMENT,
      customHead: 'Associated Companies',
      actionElementIdentifier: 'associatedCompanies',
      getCustomElement: getAssociatedCompanyElement,
    },
    {
      fieldName: 'keyContacts',
      align: TableColumnAlign.LEFT,
      customHead: 'Key Contacts',
      type: TableCellTypes.CUSTOM_ELEMENT,
      actionElementIdentifier: 'keyContacts',
      getCustomElement: getCustomElement,
    },
    {
      fieldName: 'categories',
      align: TableColumnAlign.LEFT,
      customHead: 'Categories',
      type: TableCellTypes.CUSTOM_ELEMENT,
      actionElementIdentifier: 'categories',
      getCustomElement: formatProductMixItems,
    },
    {
      fieldName: 'subCategories',
      align: TableColumnAlign.LEFT,
      customHead: 'Sub-Categories',
      type: TableCellTypes.CUSTOM_ELEMENT,
      actionElementIdentifier: 'subCategories',
      getCustomElement: formatProductMixItems,
    },
  ];
  return companyTable;
};
