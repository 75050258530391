import { IDataFeed } from '#/store/api/dataJob/dataJobsInterfaces';

import { baseApi } from '../../base';

const dataJob = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDataJobs: builder.query<IDataFeed[], void>({
      query: () => `/retailer/data-job/all`,
      providesTags: ['DATA_JOBS'],
    }),
    postDataJob: builder.mutation<void, { name: string; formData }>({
      query: ({ name, formData }) => {
        const bodyFormData = new FormData();
        bodyFormData.append('file', formData);
        console.log({ bodyFormData, formData });
        return {
          url: `retailer/data-job?name=${name}&type=TALEND`,
          method: 'POST',
          body: bodyFormData,
          formData: true,
        };
      },
    }),
  }),
});

export const { useGetDataJobsQuery, usePostDataJobMutation } = dataJob;
