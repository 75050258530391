import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../../../constants/theme/constants';

export const useCampaignInstanceTacticsStyle = makeStyles(() =>
  createStyles({
    container: {
      background: THEME.PALETTES.OTHER.White,
      borderRadius: THEME.REM_SPACES[1],
      padding: THEME.REM_SPACES[1],
      border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
    },
    editProductsAction: {
      '&&': {
        border: 'none',
        color: THEME.PALETTES.TEXT.Action_Secondary,
        fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
      },
      '&&:hover': {
        border: 'none',
        color: THEME.PALETTES.TEXT.Action_Secondary,
        fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
        background: THEME.PALETTES.OTHER.White,
      },
    },
    tableBodyCell: {
      color: '#16191F',
      fontSize: '0.8125rem',
    },
    tableContainer: {
      '& td:nth-child(3) > p': {
        background: THEME.PALETTES.UI.Neutral[200],
        padding: '0.5rem',
        borderRadius: '0.5rem',
      },
      '& td:nth-child(4) ': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& td:nth-child(1)': {
        fontWeight: 600,
      },
    },
    extraRowCell: {
      padding: '0.4rem 0.8rem',
      border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      fontWeight: THEME.FONT_WEIGHTS.BOLD,
      height: THEME.REM_SPACES[3],
    },
    nrOfProducts: {
      cursor: 'pointer',
      color: THEME.PALETTES.TEXT.Action_Secondary,
    },
    upnView: {
      border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      padding: THEME.REM_SPACES[1],
      borderRadius: THEME.REM_SPACES[1],
      minHeight: '20rem',
    },
  }),
);
