import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const useMainMenuStyles = makeStyles(() =>
  createStyles({
    menuContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: 'white',
    },
    menuItemsContainer: { display: 'flex', height: '100%' },
    menuItem: { display: 'flex', alignItems: 'center', columnGap: '10px', width: '100%' },
    itemLink: {
      textDecoration: 'none',
    },
    userProfileContainer: {
      padding: '1.3rem 14% 14%',
      display: 'flex',
      flexDirection: 'column',
    },
    userNameContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem',
      textDecoration: 'none',
    },
    title: {
      '&&': {
        fontSize: '0.875rem',
        fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
        color: THEME.PALETTES.UI.Primary.Base,
        letterSpacing: '0.5px',
      },
    },
    containerImage: { marginRight: '5%' },
    loaderContainer: { padding: '14%', display: 'flex', flexDirection: 'column' },
    loader: { marginBottom: '20%' },
    topImage: {
      '&& > img': {
        maxWidth: '85%',
        objectFit: 'contain',
        display: 'block',
        margin: '0 auto',
      },
    },
    companyLogo: {
      display: 'flex',
      alignItems: 'center',
      margin: '0 14%',
      paddingBottom: THEME.REM_SPACES['1'],
      borderBottom: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
    },
    companyImage: {
      maxWidth: '2.75rem',
      marginRight: '0.625rem',
    },
    marginLeft5: {
      marginLeft: THEME.REM_SPACES['0.5'],
    },
    companyName: {
      '&&': {
        lineBreak: 'anywhere',
        color: THEME.PALETTES.TEXT.Action_Primary,
        letterSpacing: '0.5px',
        fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
        fontSize: '0.875rem',
      },
    },
  }),
);

export const getTabSx = (isActive: boolean) => ({
  color: '#16191F',
  lineHeight: '1',
  fontWeight: '600',
  fontSize: '1rem',
  opacity: '1',
  background: isActive ? '#EBE9F9' : '',
  borderRadius: isActive ? '12%/50%' : '',
  textTransform: 'none',
  width: '100%',
  padding: '5px 10%',
});

export const dividerSx = { margin: '5%' };

export const menuTabsSx = {
  padding: '4%',
  display: 'flex',
  backgroundColor: '#FFF',
  width: '100%',
};

export const userProfileTextSx = { fontWeight: 600 };
