import { IResource } from '../../../hooks/useGetResource';
import { baseApi } from '../../base';
import { IChannelAndTacticsResponseDisplayShell, IEditDetails } from '../campaigns/campaignsInterfaces';

const campaignInstancesChannelAndTacticsBrandApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getInstancesBrandTiersAll: builder.query<IChannelAndTacticsResponseDisplayShell, { resource: IResource }>({
      query: ({ resource }) => `/retailer/instances/brand/${resource.resourceId}/tiers/all`,
      providesTags: ['CAMPAIGN_INSTANCES_CHANNEL_AND_TACTICS'],
    }),
    getCampaignTiersDetailsBrandInstance: builder.query<IEditDetails, { instanceTacticId: string | undefined }>({
      query: ({ instanceTacticId }) => `/retailer/instance-tactic/brand/${instanceTacticId}/details`,
      providesTags: ['CAMPAIGN_CHANNEL_AND_TACTICS'],
    }),
  }),
});

export const { useGetInstancesBrandTiersAllQuery, useGetCampaignTiersDetailsBrandInstanceQuery } =
  campaignInstancesChannelAndTacticsBrandApi;

export { campaignInstancesChannelAndTacticsBrandApi };
