export enum UsersRoles {
  SuperAdmin = '1',
  RetailerAdmin = '2',
  Retailer = '3',
  Brand = '4',
  ChannelPartner = '5',
}

export enum UsersType {
  SUPER_ADMIN = 'super_admin',
  RETAILER = 'retailer',
  CHANNEL_PARTNER = 'channel-partner',
  BRAND = 'brand',
  AGENCY = 'agency',
  ADMIN = 'admin',
}
