import { Box, Chip } from '@mui/material';
import React, { useMemo, useState } from 'react';

import { useDataCy } from '#/hooks/useDataCy';

import { useDeleteProductCategoryMutation, usePostProductMixCategoryMutation } from '#/store/api/productMix/productMix';

import { getProductMixCategories } from '../../../../../api/productMix';
import { useRequest } from '../../../../../hooks/useRequest';
import NewButton from '../../../../common/button/NewButton';
import Loader from '../../../../common/loader/loader';
import { TypographyWithFontFamily } from '../../../../common/typography/TypographyWithFontFamily';
import ProductMixInput from '../ProductMixInput/ProductMixInput';
import { titleSX, useProductMixStyles } from '../productMix.styles';

const ProductMixCategoriesTable = ({
  setSelectedId,
  selectedId,
}: {
  setSelectedId: React.Dispatch<React.SetStateAction<string>>;
  selectedId: string;
}) => {
  const [postProductMixCategory, { isLoading: isPostProductMixCategoryLoading }] = usePostProductMixCategoryMutation({
    fixedCacheKey: 'postProductMixCategory',
  });
  const [deleteProductMixCategory, { isLoading: idDeleteProductMixCategoryLoading }] = useDeleteProductCategoryMutation(
    { fixedCacheKey: 'deleteProductMixCategory' },
  );

  const isLoading = isPostProductMixCategoryLoading || idDeleteProductMixCategoryLoading;

  const styles = useProductMixStyles();
  const generateDataCy = useDataCy();

  const [submitted, setSubmitted] = useState<boolean>(false);

  const [value, setValue] = useState<string>('');

  const getProductMixCategoriesRequest = useMemo(() => getProductMixCategories('show-categories'), [isLoading]);
  const { response, loading } = useRequest(getProductMixCategoriesRequest);

  if (loading) {
    return <Loader />;
  }

  const handleAddProductMix = async () => {
    await postProductMixCategory({ categoryName: value }).unwrap();
    setSubmitted(!submitted);
  };

  const onSelectCategory = (id: string) => {
    if (selectedId === id) {
      setSelectedId('');
    } else {
      setSelectedId(id);
    }
  };
  const onDeleteCategory = async (id: string) => {
    await deleteProductMixCategory({ categoryId: id });
  };

  return (
    <>
      <TypographyWithFontFamily sx={titleSX} variant="H3_Base">
        Categories
      </TypographyWithFontFamily>
      <Box className={styles.flexDiv} data-cy={generateDataCy('box', 'categoriesButtonContainer')}>
        <ProductMixInput setValue={setValue} submitted={submitted} value={value} />
        <NewButton className={styles.buttonWidth} disabled={!value} onClick={handleAddProductMix} text="Add" />
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!response?.data.length ? (
            <Box className={styles.marginY}>There is nothing added.</Box>
          ) : (
            <Box className={styles.chipsContainer} data-cy={generateDataCy('box', 'productMixChipContainer')}>
              {response?.data?.map((category) => (
                <Chip
                  key={category.id}
                  className={`${styles.chipItem} ${selectedId === category.id ? styles.chipItemSelected : ''}`}
                  label={<TypographyWithFontFamily>{category.categoryName}</TypographyWithFontFamily>}
                  onClick={() => onSelectCategory(category.id)}
                  onDelete={() => onDeleteCategory(category.id)}
                />
              ))}
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default ProductMixCategoriesTable;
