import { IFileMetadata } from '../interfaces/files/files';
import { retailerAxios } from './axiosConfig';
import { IRequestDto } from './requestDtoInterface';

export const getFileMetadata = (fileId: string): Promise<IRequestDto<IFileMetadata>> => {
  return retailerAxios.get(`/file-upload/files/metadata/${fileId}`);
};
export const getMultipleFilesMetadata = (fileIds: string[]) => {
  const filesMetadataPromises = fileIds
    .filter((fileId) => fileId)
    .map((fileId) => getFileMetadata(fileId).then((file) => ({ ...file?.data, fileId })));
  return Promise.all(filesMetadataPromises);
};

export const uploadFileCustomLink = (customLink: string, formData: FormData) => {
  return retailerAxios.post(customLink, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
