import { Box } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';

import { TypographyWithFontFamily } from '../common/typography/TypographyWithFontFamily';
import { useLegendStyles } from './Legend.styles';
import { WEEKDAY_MAPPER } from './useCalendarYear.hook';

type LegendProps = {
  startDayOfWeek: number;
  marketingStartDate: string;
};

const Legend: FC<LegendProps> = ({ startDayOfWeek, marketingStartDate }) => {
  const classes = useLegendStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.legendContainer}>
        <TypographyWithFontFamily className={classes.legendHeader} variant="Text_Body_700">
          Showing weeks starting with:
        </TypographyWithFontFamily>
        <TypographyWithFontFamily className={classes.legendHeader} variant="Text_Body_500">
          {WEEKDAY_MAPPER[startDayOfWeek]}
        </TypographyWithFontFamily>
      </Box>
      <Box className={classes.legendContainer}>
        <TypographyWithFontFamily className={classes.legendHeader} variant="Text_Body_700">
          Marketing Start Date:
        </TypographyWithFontFamily>
        <TypographyWithFontFamily className={classes.legendHeader} variant="Text_Body_500">
          {marketingStartDate}
        </TypographyWithFontFamily>
      </Box>
      <TypographyWithFontFamily className={classes.legendHeader} variant="Text_Body_700">
        Legend:
      </TypographyWithFontFamily>
      <Box className={classes.legendContainer}>
        <Box className={classNames(classes.legendDot, classes.startOfPeriod)}></Box>
        <TypographyWithFontFamily className={classes.legendText} variant="Text_Body_500">
          Start of Period
        </TypographyWithFontFamily>
      </Box>
    </Box>
  );
};

export default Legend;
