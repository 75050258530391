import { Box } from '@mui/material';

import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';

import { IDynamicTextInputProps } from '#/interfaces/dynamicRibbon/dynamicRibbon';

import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { useDynamicRibbonStyles } from '../DynamicRibbon.styles';
import { useDynamicTextInputStyles } from './DynamicTextInput.styles';

const DynamicTextInput = ({ component, register, readonly }: IDynamicTextInputProps) => {
  const ribbonClasses = useDynamicRibbonStyles();
  const classes = useDynamicTextInputStyles();

  return (
    <Box className={classes.container}>
      <TypographyWithFontFamily cName={ribbonClasses.label}>{component.label}</TypographyWithFontFamily>
      {readonly ? (
        <TextFieldOld dataCy="dynamic-ribbon-input" useRegister={false} value={component.content} />
      ) : (
        <TextFieldOld
          dataCy="dynamic-ribbon-input"
          editMode
          register={register(component.actionInputId || 'comments')}
        />
      )}
    </Box>
  );
};

export default DynamicTextInput;
