import { Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import brandConstants from '#/constants/theme/constants';

import { styled } from '#/utils/theme';

export const Count = styled(Box)`
  align-self: center;
  width: 1.75rem;
  height: ${(props) => props.theme.custom.REM_SPACES[1]};
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  background-color: ${(props) => props.theme.custom.PALETTES.SEMANTIC.Error[500]};
  color: ${(props) => props.theme.custom.PALETTES.TEXT.Light};
  border-radius: 40%;
`;

export const CountText = styled('p')`
  margin: 0;
  font-size: ${brandConstants.FONT_SM};
`;

export const Title = styled('p')`
  margin: 0 ${(props) => props.theme.custom.REM_SPACES[0.5]} 0 0;
  overflow: hidden;
`;

export const Link = styled(RouterLink)`
  display: flex;
  text-decoration: none;
  margin-bottom: ${(props) => props.theme.custom.REM_SPACES[0.5]};
  color: ${(props) => props.theme.custom.PALETTES.TEXT.Action_Secondary};
  justify-content: space-between;
  align-items: center;
`;
