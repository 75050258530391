import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const useCompanyLogo = makeStyles(() =>
  createStyles({
    avatarIcon: {
      width: '2rem',
      height: '2rem',
      border: `1px solid ${THEME.PALETTES.UI.Primary.Base}`,
      borderRadius: '0.7rem',
      cursor: 'pointer',
      background: THEME.PALETTES.TEXT.Light,
      padding: '0.2rem',
    },
    smallAvatarIcon: {
      opacity: '0.8',
      position: 'absolute',
      left: '-21px',
      top: '-14px',
      minWidth: '1.538rem',
      border: `1px solid ${THEME.PALETTES.UI.Primary.Base}`,
      borderRadius: '0.7rem',
      cursor: 'pointer',
      background: THEME.PALETTES.TEXT.Light,
      padding: '0.2rem',
    },
    buttonsWrapper: {
      margin: '1rem 0',
    },
    displayNone: {
      display: 'none',
    },
    editButton: {
      border: 'none',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      outline: 'none',
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'flexStart',
      flexDirection: 'column',
      width: '100%',
    },
    icon: { width: '1.875rem', height: '1.875rem' },
    inputsContainer: {
      flexDirection: 'row',
      marginBottom: '0.5rem',
      alignItems: 'center',
      marginTop: '0.2rem',
    },
    saveButton: {
      border: '1px solid #C9CFD7',
      padding: '0.5rem 1rem',
      backgroundColor: 'white',
      color: 'black',
      borderRadius: '0.5rem',
      cursor: 'pointer',

      '&:active': {
        backgroundColor: '#E4E8EF',
      },
    },
    userInput: {
      fontSize: '1rem',
      marginRight: '1rem',
      border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      borderRadius: '0.3rem',
      padding: '0.6rem',
      width: '25%',
      minWidth: 150,
      outline: 'none',
    },
    userName: {
      color: THEME.PALETTES.UI.Neutral['900'],
    },
    userNameField: {
      color: THEME.PALETTES.UI.Neutral.Base,
      fontSize: '1rem',
      margin: '1rem 0 0 0',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      alignItems: 'flex-start',
      padding: '0.8rem',
      borderRadius: '1rem',
    },
    badgeMargin: {
      marginTop: '0.938rem',
      position: 'relative',
    },
    placeholderCustomBadge: {
      background: THEME.PALETTES.UI.Neutral['500'],
      fontSize: '0.75rem',
      color: THEME.PALETTES.TEXT.Light,
      padding: 'rem',
      borderRadius: '50%',
      width: THEME.REM_SPACES['2'],
      height: THEME.REM_SPACES['2'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: THEME.REM_SPACES['0.5'],
    },
    align: {
      alignItems: 'center',
      display: 'flex',
    },
  }),
);

export const styles = {
  avatarSx: { width: '5.5rem', height: '5.5rem' },
  avatarSmall: {
    width: '2rem',
    height: '2rem',
    marginRight: THEME.REM_SPACES['0.5'],
    '&& > img': {
      height: 'auto',
    },
  },
};
