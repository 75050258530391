import dayjs from 'dayjs';

import { currencyFormatter } from '#/utils/textFormatters';

import {
  ICampaignBrandCalendar,
  ICampaignChannelPartnerCalendar,
  ICampaignRetailerCalendar,
} from '../../store/api/campaigns/campaignsInterfaces';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import CampaignEventsUniqueColors from './CampaignEventsUniqueColors';

const getMappedCalendarData = (
  data: ICampaignRetailerCalendar[] | ICampaignBrandCalendar[] | ICampaignChannelPartnerCalendar[] | undefined = [],
) =>
  data.map((event: ICampaignRetailerCalendar | ICampaignBrandCalendar | ICampaignChannelPartnerCalendar, index) => ({
    data: event,
    id: event.id,
    start: dayjs(event.startDate).toDate(),
    //@HACK - add 1 day to end date to fix the issue with the calendar not showing the last day of the event
    end: dayjs(event.endDate).add(1, 'day').toDate(),
    title: `[${event.status}] ${capitalizeFirstLetter(event.name)}${'goal' in event ? ` - ${currencyFormatter(event.goal)}` : ''}`,
    eventShortHeight: 5,
    overlap: false,
    allDay: true,
    backgroundColor: CampaignEventsUniqueColors[index % CampaignEventsUniqueColors.length],
  }));

export default getMappedCalendarData;
