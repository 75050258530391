import { useEffect } from 'react';

import DynamicRibbon from '#/components/features/dynamicRibbon/DynamicRibbon';

import useGetResource from '#/hooks/useGetResource';

import { useGetRibbonTasksInstanceAssetsMutation } from '#/store/api/ribbon/ribbon';

interface TiersProps {
  setSelectedTier: React.Dispatch<React.SetStateAction<string>>;
  disableButton: boolean;
}

export const DynamicRibbonTaskInstanceAssets: React.FC<TiersProps> = ({ setSelectedTier, disableButton = false }) => {
  const resource = useGetResource();

  const [trigger, { data, isLoading }] = useGetRibbonTasksInstanceAssetsMutation({
    fixedCacheKey: 'instances_assets_dynamic_ribbon',
  });

  useEffect(() => {
    trigger({ instanceId: resource.resourceId });
  }, []);

  return (
    <DynamicRibbon
      disableButton={disableButton}
      isLoading={isLoading}
      response={data}
      setSelectedTier={setSelectedTier}
      submitCallback={() => trigger({ instanceId: resource.resourceId })}
    />
  );
};
