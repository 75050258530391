import classnames from 'classnames';

import { useDataCy } from '#/hooks/useDataCy';

import { IIconsActions } from '../../../../../interfaces/table/table';
import NewButton from '../../../button/NewButton';
import { useIconsActionsStyles } from '../Table.style';

export const IconsOptions = <T,>({
  iconsActions,
  itemIdentifier,
  data,
}: {
  data?: T;
  iconsActions?: IIconsActions[];
  itemIdentifier?: string;
}) => {
  const classes = useIconsActionsStyles();
  const customContainerClass = iconsActions ? iconsActions[0].classes?.container : null;
  const generateDataCy = useDataCy();

  return (
    <div className={classnames(classes.iconsButtonsContainer, customContainerClass)}>
      {iconsActions?.map((action, index) => {
        if (data && action.hideByProp && String(data[action.hideByProp as keyof T]) === action.hideByPropValue) {
          return null;
        }

        const actionIdentifier =
          action.customIdentifier && data ? String(data[action.customIdentifier as keyof T]) : itemIdentifier;
        return !action.isButton ? (
          <button
            key={`${actionIdentifier}-${index}`}
            className={classnames(classes.iconButton, action?.classes?.button)}
            data-cy={generateDataCy('button', `IconsOptions-${String(actionIdentifier)}`)}
            onClick={() => {
              if (action.action) {
                action.action(actionIdentifier || '');
              }
            }}
          >
            {action.icon && (
              <img
                alt="button-icon"
                className={classnames(classes.iconImage, { [classes.marginRight]: !!action.text })}
                src={action.icon}
              />
            )}
            {action.text && <p className={classes.iconButtonText}>{action.text}</p>}
          </button>
        ) : (
          <NewButton
            key={`${actionIdentifier}-${index}`}
            className={classnames(classes.marginRightMedium, action?.classes?.button)}
            icon={action.icon && action.icon}
            minWidth="6.25"
            onClick={() => {
              if (action.action) {
                action.action(actionIdentifier || '');
              }
            }}
            size="small"
            text={action.text ?? ''}
            variant={action.buttonVariant && action.buttonVariant}
          />
        );
      })}
    </div>
  );
};
