import { Box } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router';

import AssociateCompanyToCompanyForUser from '#/components/addUserWizard/AssociateCompanyToCompanyForUser';
import Modal from '#/components/common/modal/Modal';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import PermissionWrapper from '#/utils/PermissionWrapper';

import { AssociateAgencyBrandTable } from '../../../../constants/tables/partners/company/companyTable';
import { useGetCompanyAccountAssociatedBrandsQuery } from '../../../../store/api/company/company';
import { useGetUserQuery } from '../../../../store/api/users/users';
import NewButton from '../../../common/button/NewButton';
import Card from '../../../common/card/card';
import Loader from '../../../common/loader/loader';
import { Table } from '../../../common/table/tableComponent/Table';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { useAssociatedBrandsStyle } from './AssociatedBrands.style';

const AssociatedBrands = () => {
  const { userId } = useParams();
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);

  const classes = useAssociatedBrandsStyle();

  const { data: user } = useGetUserQuery({ userId });
  const { data: companyAccountAssociatedBrandsData, isLoading: isCompanyAccountAssociatedBrandsLoading } =
    useGetCompanyAccountAssociatedBrandsQuery({ userId });

  const { AssociatedUserBrands } = AssociateAgencyBrandTable();

  return (
    <Card>
      {isCompanyAccountAssociatedBrandsLoading ? (
        <Loader />
      ) : (
        <>
          <Box>
            <Box className={classes.associatedBrandsHeader}>
              <TypographyWithFontFamily variant="H4_Base">Associated Brands</TypographyWithFontFamily>
              <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.PARTNERS_USERS_ASSOCIATE_BRANDS_SECTION_WRITE]}>
                <NewButton onClick={() => setIsAddUserOpen(true)} text="Associate Brands" />
              </PermissionWrapper>
            </Box>
            {companyAccountAssociatedBrandsData?.length ? (
              <Table columns={AssociatedUserBrands} data={companyAccountAssociatedBrandsData} />
            ) : (
              <TypographyWithFontFamily>There are no brands associated to this user.</TypographyWithFontFamily>
            )}
          </Box>
          <Modal
            content={
              <AssociateCompanyToCompanyForUser
                companyId={user?.companyId}
                isBrandUser={user?.userType === 'brand'}
                onClose={() => setIsAddUserOpen(false)}
                userId={userId}
              />
            }
            handleCloseModal={() => setIsAddUserOpen(false)}
            modalTitle="Associate Brands"
            open={isAddUserOpen}
            size="small"
          />
        </>
      )}
    </Card>
  );
};

export default AssociatedBrands;
