import { useState } from 'react';
import { DynamicRibbonTaskInstanceAssets } from '#/components/campaigns/campaignAssets/DynamicRibbonTaskInstanceAssets';
import { usePageContainerStyles } from '../../features/campaigns/common.style';
import { CreativeSpecs } from './CreativeSpecs';
import TacticsAssets from './TacticsAssets';

import PermissionWrapper from '#/utils/PermissionWrapper';
import { USER_PERMISSIONS } from '../../../constants/permissions/userPermissions';

export const CampaignAssets = () => {
  const classes = usePageContainerStyles();
  const [selectedTier, setSelectedTier] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  return (
    <>
      <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.BRAND_CAMPAIGN_INSTANCES_ASSETS_TACTICS]}>
        <DynamicRibbonTaskInstanceAssets disableButton={disableButton} setSelectedTier={setSelectedTier} />
      </PermissionWrapper>
      <div className={classes.pageContainer}>
        <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.CAMPAIGN_INSTANCE_OVERVIEW_CREATIVE_SPECS_READ]}>
          <CreativeSpecs />
        </PermissionWrapper>
        <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.CAMPAIGN_INSTANCE_ASSETS]}>
          <TacticsAssets selectedTier={selectedTier} setDisableButton={setDisableButton} />
        </PermissionWrapper>
      </div>
    </>
  );
};
