import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../../../../constants/theme/constants';

export const useTacticsTableStyles = makeStyles(() =>
  createStyles({
    tableContainer: { height: '100%', margin: 'auto' },
    actionsContainer: { minWidth: THEME.REM_SPACES['10'], display: 'flex', justifyContent: 'space-around' },
  }),
);

export const styles = {
  actionButton: { minWidth: THEME.REM_SPACES['5'] },
};
