import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';
import { THEME } from '#/constants/theme/constants';

import useGetResource, { RESOURCE_TYPES } from '#/hooks/useGetResource';
import { IUserPermissions, useUserPermissionType } from '#/hooks/useUserPermissionType';

import {
  useGetOverviewCreativeSpecsQuery,
  usePutOverviewCreativeSpecsMutation,
} from '#/store/api/campaigns/creativeSpecs';

import MultipleDownloadUpload from '../../common/downloadUpload/MultipleDownloadUpload';
import Loader from '../../common/loader/loader';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import { containerBoxSx, overviewContainerBoxSx } from './CampaignAssets.style';

const permissionsObj: IUserPermissions = {
  retailerPermission: USER_PERMISSIONS.CAMPAIGN_OVERVIEW_CREATIVE_SPECS_WRITE,
};
const permissionsInstanceObj: IUserPermissions = {
  retailerPermission: USER_PERMISSIONS.CAMPAIGN_INSTANCE_OVERVIEW_CREATIVE_SPECS_WRITE,
};

export const CreativeSpecs = () => {
  const { retailerPermission } = useUserPermissionType(permissionsObj);
  const { retailerPermission: retailerInstancePermission } = useUserPermissionType(permissionsInstanceObj);
  const resource = useGetResource();
  const { data, isLoading } = useGetOverviewCreativeSpecsQuery({ resource });
  const [triggerPutCampaignCreativeSpecsMutation] = usePutOverviewCreativeSpecsMutation();
  const { setValue, watch } = useForm();
  useEffect(() => {
    if (watch('creativeSpecs')?.length >= 0) {
      triggerPutCampaignCreativeSpecsMutation({ resource, fileIds: watch('creativeSpecs') });
    }
  }, [watch('creativeSpecs')?.length]);

  return (
    <Box sx={resource.resourceType === RESOURCE_TYPES.instances ? containerBoxSx : overviewContainerBoxSx}>
      <TypographyWithFontFamily color={THEME.PALETTES.UI.Neutral['900']} gutterBottom variant="H4_Base">
        Campaign Information
      </TypographyWithFontFamily>
      <form>
        {!isLoading ? (
          <>
            <MultipleDownloadUpload
              editMode={
                resource.resourceType === RESOURCE_TYPES.campaign ? retailerPermission : retailerInstancePermission
              }
              filesGridSize={4}
              filesIds={watch('creativeSpecs', data?.creativeSpecs || [])}
              registerName="creativeSpecs"
              setValue={setValue}
              uploadLink={`/campaign/creative-specs`}
            />
          </>
        ) : (
          <Loader />
        )}
      </form>
    </Box>
  );
};
