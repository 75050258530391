import { Box, CircularProgress, Grid } from '@mui/material';
import { Controller } from 'react-hook-form';

import AlertError from '#/components/common/Alert/AlertError/AlertError';
import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';

import { usePostReportAddMutation } from '#/store/api/reports/reports';

import { THEME } from '../../../../../constants/theme/constants';
import { useAddReportForm } from '../../../../../hooks/forms/useAddReportForm';
import NewButton from '../../../../common/button/NewButton';
import Select from '../../../../common/inputs/Select/Select';
import Modal from '../../../../common/modal/Modal';
import { useSnackbarError } from '../../../../common/snackbar/useSnackbarError';
import { TypographyWithFontFamily } from '../../../../common/typography/TypographyWithFontFamily';
import { customInputStyle, headerWithSpacingSx, useReportsAdminFormStyle } from '../ReportsAdminPage.style';
import { reportTypeOptions } from '../editReport/constants';

interface IProps {
  onClose: () => void;
  setRefreshCount: React.Dispatch<React.SetStateAction<number>>;
}

export const AddReportModal = ({ onClose, setRefreshCount }: IProps) => {
  const [postReportAdd, { error, isLoading }] = usePostReportAddMutation();
  const { setError } = useSnackbarError();

  const submitHandler = async (data) => {
    try {
      await postReportAdd({ data }).unwrap();
      setRefreshCount((oldState) => oldState + 1);
      onClose();
    } catch (error) {
      setError(error);
    }
  };

  const { onSubmit, register, control } = useAddReportForm(submitHandler);
  const classes = useReportsAdminFormStyle();

  // COMMENTED CODE WILL BE REQURED IN FUTURE IMPLEMENTATION

  //   const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
  //     control,
  //     name: 'metadata',
  //   });

  //   const onAppend = () => {
  //     append({
  //       fieldName: '',
  //       align: '',
  //       type: '',
  //       isKey: false,
  //       hide: false,
  //       customHead: '',
  //       linkable: false,
  //       baseUrl: '',
  //       actionElementIdentifier: '',
  //       currency: false,
  //       urlExtraParams: [{ key: '', value: '' }],
  //     });
  //   };

  return (
    <Modal
      content={
        <form onSubmit={onSubmit}>
          <AlertError error={error} />
          <Box>
            <Box>
              <Grid container>
                <Grid item xs={4.9}>
                  <TypographyWithFontFamily sx={headerWithSpacingSx} variant="H5_Base">
                    Report Details
                  </TypographyWithFontFamily>
                </Grid>
              </Grid>
              <Grid container spacing={{ xs: '1rem' }}>
                <Grid item xs={6}>
                  <TextFieldOld editMode label="Name" register={register('name')} />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldOld editMode label="Alternative Id" register={register('altId')} />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldOld
                    className={classes.customField}
                    customInputStyle={customInputStyle}
                    editMode
                    label="Sql"
                    register={register('sql')}
                    rows={2}
                    textarea
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldOld editMode label="Permission" register={register('permission')} />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={'type'}
                    render={({ field: { onChange, value } }) => (
                      <Select editMode label="Type" onChange={onChange} options={reportTypeOptions} value={value} />
                    )}
                  />
                </Grid>
              </Grid>
              {/* <Metadata control={control} fields={fields} onAppend={onAppend} register={register} remove={remove} /> */}
            </Box>
            <Box className={classes.buttonsContainer}>
              {isLoading && <CircularProgress size={THEME.REM_SPACES[1.5]} />}
              <NewButton onClick={onClose} text="Cancel" variant="Secondary" />
              <NewButton text="Save" type="submit" variant="Primary" />
            </Box>
          </Box>
        </form>
      }
      handleCloseModal={onClose}
      modalTitle="Add Report"
    />
  );
};
