import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import SearchFilters from '#/components/common/SearchFilters/SearchFilters';
import useSearchFilters from '#/components/common/SearchFilters/useSearchFilters.hook';
import { usePromoteCampaignStyles } from '#/components/features/communications/PromoteCampaigns.styles';
import useGetPromoteCampaignTableColumns from '#/components/features/communications/PromoteCampaigns.tables';
import { BASE_PATH } from '#/constants/general';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';
import { getUserHasPermission } from '#/constants/permissions/utils';
import { ROUTE_PATHS } from '#/constants/routing/routePaths';

import { useAppDispatch, useAppSelector } from '#/hooks';

import { useGetPendingInvitationsFiltersQuery, useGetPendingInvitationsQuery } from '#/store/api/promote/promote';
import { communicationsPromote } from '#/store/slices';

import PermissionWrapper from '#/utils/PermissionWrapper';

import NewButton from '../../common/button/NewButton';
import Loader from '../../common/loader/loader';
import { NoDataMessage } from '../../common/noDataMessage/NoDataMessage';
import { useSnackbarError } from '../../common/snackbar/useSnackbarError';
import { Table } from '../../common/table/tableComponent/Table';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import { CommunicationsChart } from '../promote/communications/CommunicationsChart';

export const PromoteCampaigns = () => {
  const userHasCommunicationsPromotePromoteSectionWrite = getUserHasPermission(
    USER_PERMISSIONS.COMMUNICATIONS_PROMOTE_PROMOTE_SECTION_WRITE,
  );

  const { COMMUNICATIONS, CAMPAIGNS_INVITE_BRAND } = ROUTE_PATHS;

  const { setError } = useSnackbarError();
  const classes = usePromoteCampaignStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data: filterData, isError: filterDataIsError } = useGetPendingInvitationsFiltersQuery({});

  const { actions, state } = useSearchFilters(undefined, true, filterData?.filters);

  const { data, isError, error, isFetching, refetch, isLoading } = useGetPendingInvitationsQuery({
    pageNumber: state.pageNumber,
    numberOfRows: state.numberOfElementsPerPage,
    searchQuery: state.searchQuery,
  });

  const { selectedCampaigns } = useAppSelector((state) => state.communications);

  const handleNextClick = () => {
    const url = `${BASE_PATH}/${COMMUNICATIONS}/${CAMPAIGNS_INVITE_BRAND}`;
    const searchParams = new URLSearchParams();

    searchParams.set('campaigns', selectedCampaigns.join(','));

    navigate(`${url}?${searchParams}`);
  };

  useEffect(() => {
    dispatch(communicationsPromote.actions.resetStepsData());
  }, []);

  const tableColumns = useGetPromoteCampaignTableColumns(
    selectedCampaigns,
    filterData?.filters || [],
    userHasCommunicationsPromotePromoteSectionWrite,
  );

  if (isLoading) {
    return <Loader />;
  }

  if (!data || !filterData)
    return <NoDataMessage dataLoading={isFetching} message="No Pending Campaigns Available" refetch={refetch} />;

  if (isError) {
    setError(error);
  }

  return (
    <Box className={classes.wrapper}>
      <CommunicationsChart isGeneralCommunications={false} />
      <>
        <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.COMMUNICATIONS_PROMOTE_PROMOTE_SECTION_WRITE]}>
          <Box className={classes.buttonWrapSpace}>
            <TypographyWithFontFamily variant="H5_Base">Promote Campaigns</TypographyWithFontFamily>
            <NewButton disabled={!selectedCampaigns.length} onClick={handleNextClick} text="Promote" type="button" />
          </Box>
        </PermissionWrapper>
        <SearchFilters
          columnNames={filterData?.filters}
          currentIndex={data.currentIndex}
          filterDataIsError={filterDataIsError}
          hideViewMode
          isError={isError}
          isFetching={isFetching}
          isNoResults={!data || data.campaigns.length === 0}
          totalPages={data.totalPages}
          totalResults={data.totalResults}
          {...state}
          {...actions}
        >
          <Table
            columns={tableColumns}
            data={data?.campaigns}
            sortProps={{
              sortParams: state.sortParams,
              setSortParams: actions.setSortParam,
            }}
          />
        </SearchFilters>
      </>
    </Box>
  );
};
