import { makeStyles, createStyles } from '#/utils/theme';

export const usePageLayoutStyles = makeStyles(() =>
  createStyles({
    pageContainer: { position: 'absolute', top: 0, left: 0, display: 'flex', height: '100%', width: '100%' },
    menuContainer: { width: '15%', height: '100%', minWidth: 170 },
    contentContainer: {
      width: '85%',
      height: '100%',
      overflowY: 'scroll',
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);
