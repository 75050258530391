import { Autocomplete, Box, TextField } from '@mui/material';
import React, { useEffect } from 'react';

import { useDataCy } from '#/hooks/useDataCy';

const ProductMixInput = ({
  value,
  setValue,
  submitted,
}: {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  submitted: boolean;
}) => {
  const generateDataCy = useDataCy();

  useEffect(() => {
    setValue('');
  }, [submitted]);

  const updateValue = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setValue(e.target.value ?? '');

  return (
    <>
      <Autocomplete
        data-cy={generateDataCy('autocomplete', 'productMixInput')}
        disableClearable
        freeSolo
        fullWidth
        id="tags-standard"
        options={[]}
        renderInput={(params) => (
          <Box>
            <TextField
              onChange={(e) => {
                updateValue(e);
              }}
              {...params}
              InputProps={{
                ...params.InputProps,
                style: { width: '250px', padding: '2px 3px', borderRadius: '8px' },
                type: 'search',
              }}
              size="small"
            />
          </Box>
        )}
        value={value}
      />
    </>
  );
};
export default ProductMixInput;
