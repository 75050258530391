import { useAppDispatch, useAppSelector } from '#/hooks';

import { ICommunicationsRecipients } from '#/interfaces/forms/campaignInvitation';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { communicationsPromote } from '#/store/slices';

import { convertAllCapsUnderscoreStrings } from '#/utils/utils';

import { Table } from '../../../common/table/tableComponent/Table';

const CommunicationsSelectRecipientsTableSelected = () => {
  const dispatch = useAppDispatch();
  const selectedRecipientsIds = useAppSelector((state) =>
    communicationsPromote.getSelectedRecipientsIds(state.communications),
  );
  const { selectedRecipients } = useAppSelector((state) => state.communications);

  const tableColumns: ITableColumnsProps<ICommunicationsRecipients>[] = [
    {
      fieldName: null,
      customHead: ' ',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.CHECKBOX,
      checkBoxAction: (checked: boolean, userId: string) => {
        const recipientItemCheck = selectedRecipients?.find((recipient) => recipient.userId === userId);
        recipientItemCheck && dispatch(communicationsPromote.actions.updateSelectedRecipients(recipientItemCheck));
      },
      actionElementIdentifier: 'userId',
      actionElementSecondIdentifier: 'email',
      checkFromList: selectedRecipientsIds,
      hasCheckAll: false,
    },
    {
      fieldName: 'fullName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Name',
    },
    {
      fieldName: 'email',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Email',
    },
    {
      fieldName: 'jobTitle',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Job Title',
    },
    {
      fieldName: 'companyName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Company Name',
    },
    {
      fieldName: 'companyType',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Company Type',
    },
    {
      fieldName: 'invitationStatus',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Invitation Status',
      textFormatter: (text) => convertAllCapsUnderscoreStrings(text.toString()) || 'Not Sent',
    },
  ];

  if (!selectedRecipients) {
    return null;
  }

  return <Table columns={tableColumns} data={selectedRecipients} />;
};
export default CommunicationsSelectRecipientsTableSelected;
