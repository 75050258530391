import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import NewButton from '#/components/common/button/NewButton';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { useUserSettingsStyle } from '#/components/features/settings/userSettings/UsersSettings.style';
import { ROUTE_PATHS } from '#/constants/routing/routePaths';

import { IUserView } from '#/store/api/users/usersInterfaces';

import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '../../../../interfaces/table/table';

export const getUsersSettingsTable = (
  putActivateUser,
  activateUserIsLoading,
  deleteInactivateUser,
  inactivateUserIsLoading,
) => {
  const classes = useUserSettingsStyle();

  const getElement = (userId: string, status: string) => (
    <Box className={classes.tableButtons}>
      {status === 'Active' && (
        <NewButton
          loading={inactivateUserIsLoading || activateUserIsLoading}
          onClick={() => deleteInactivateUser({ userId })}
          text={'Deactivate'}
        />
      )}
      {status === 'Inactive' && (
        <NewButton
          loading={inactivateUserIsLoading || activateUserIsLoading}
          onClick={() => putActivateUser({ userId })}
          text={'Activate'}
        />
      )}
    </Box>
  );

  const getNameElement = (firstName: string, lastName: string, userId: string) => {
    return (
      <TypographyWithFontFamily className={classes.customHrefColor}>
        <Link to={`/${ROUTE_PATHS.PARTNERS}/${ROUTE_PATHS.PARTNERS_USERS}/${userId}`}>
          {firstName} {lastName}
        </Link>
      </TypographyWithFontFamily>
    );
  };
  const tableColumns: ITableColumnsProps<IUserView>[] = [
    {
      fieldName: null,
      align: TableColumnAlign.LEFT,
      customHead: 'Name',
      type: TableCellTypes.CUSTOM_ELEMENT,
      actionElementIdentifier: 'firstName',
      actionElementSecondIdentifier: 'lastName',
      actionElementThirdIdentifier: 'userId',
      getCustomElement: getNameElement,
    },
    {
      fieldName: 'emailAddress',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Email Address',
    },

    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Status',
    },
    {
      fieldName: 'userType',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Type',
    },
    {
      fieldName: 'role',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Role',
    },
    {
      fieldName: 'departmentName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Department',
    },
    {
      fieldName: null,
      align: TableColumnAlign.CENTER,
      customHead: 'Actions',
      type: TableCellTypes.CUSTOM_ELEMENT,
      actionElementIdentifier: 'userId',
      actionElementSecondIdentifier: 'status',
      getCustomElement: getElement,
    },
  ];
  return tableColumns;
};
