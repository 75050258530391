import { Box, Chip } from '@mui/material';
import { FC } from 'react';

import { Filter, INPUT_TYPE } from '#/components/common/SearchFilters/SearchFilters.types';
import { useActiveFiltersStyles } from '#/components/common/SearchFilters/components/ActiveFilters/ActiveFilters.styles';
import isFilterInvalid from '#/components/common/SearchFilters/isValidFilter';
import NewButton from '#/components/common/button/NewButton';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';

import { SearchFilters as SearchFiltersType } from '#/store/api/campaigns/campaignsInterfaces';

import { currencyFormatter, numberFormatter } from '#/utils/textFormatters';

type ActiveFiltersProps = {
  shouldDisableField: boolean;
  clearFilters: () => void;
  removeFilter: (filter: Filter) => void;
  filters: Filter[];
  columnNames: SearchFiltersType[];
};

const ActiveFilters: FC<ActiveFiltersProps> = ({
  shouldDisableField,
  clearFilters,
  removeFilter,
  filters,
  columnNames,
}) => {
  const classes = useActiveFiltersStyles();
  return (
    <>
      <Box className={classes.activeFiltersContainer}>
        <NewButton
          className={classes.clearButton}
          disabled={!shouldDisableField}
          onClick={clearFilters}
          text="Clear Filters"
          variant="Secondary"
        />
        {filters.map((filter: Filter) => {
          const columnNameData = columnNames.find((columnName) => columnName.columnName === filter.key);
          let value: string | undefined;
          switch (columnNameData?.type) {
            case INPUT_TYPE.SELECT:
              value = columnNameData?.options?.find((el) => el.value === filter.value)?.label;
              break;
            case INPUT_TYPE.CURRENCY:
              value = currencyFormatter(filter.value);
              break;
            case INPUT_TYPE.DECIMALS:
              value = numberFormatter(filter.value);
              break;
            case INPUT_TYPE.INTERVAL:
              value = filter.value.replace(',', ' TO ');
              break;
            default:
              value = filter.value;
          }

          const hasOperations = columnNameData?.operations;

          const chipText = isFilterInvalid(filter, columnNames)
            ? 'Unrecognized Filter'
            : `${columnNameData?.displayName} ${hasOperations ? filter?.operation : '='} ${value}`;

          return (
            <Chip
              key={`${filter.key}${filter.value}`}
              className={classes.chip}
              disabled={!shouldDisableField}
              label={<TypographyWithFontFamily>{chipText}</TypographyWithFontFamily>}
              onDelete={() => removeFilter(filter)}
            />
          );
        })}
      </Box>
    </>
  );
};

export default ActiveFilters;
