import { SearchFilters } from '#/store/api/campaigns/campaignsInterfaces';
import { ChannelPartnerAccounts } from '#/store/api/partners/promoteInterfaces';

import { baseApi } from '../../base';

const partnersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getChanelPartnerAccountAllFiltered: builder.query<
      ChannelPartnerAccounts,
      {
        pageNumber: number;
        numberOfRows: number;
        searchQuery: string;
      }
    >({
      query: ({ pageNumber, numberOfRows, searchQuery = '' }) => {
        const searchParams = new URLSearchParams();

        searchParams.set('pageNumber', pageNumber.toString());
        searchParams.set('numberOfRows', numberOfRows.toString());
        searchParams.set('searchQuery', searchQuery);

        return `/retailer/channel-partner-account?${searchParams}`;
      },
      providesTags: ['USER_DATA'],
    }),
    getChanelPartnerAccountAllFilteredFilters: builder.query<{ filters: SearchFilters[] }, unknown>({
      query: () => `/retailer/channel-partner-account/filters`,
    }),
  }),
});

export const { useGetChanelPartnerAccountAllFilteredQuery, useGetChanelPartnerAccountAllFilteredFiltersQuery } =
  partnersApi;
