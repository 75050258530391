import { createStyles, makeStyles } from '#/utils/theme';

export const useAlertStyles = makeStyles((theme) =>
  createStyles({
    container: {
      background: theme.custom.PALETTES.SEMANTIC.Error[100],
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: theme.custom.REM_SPACES[0.5],
      marginBottom: theme.custom.REM_SPACES[0.5],
    },
    iconTextContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    closed: {
      display: 'none',
    },
    icon: {
      color: 'white',
      padding: theme.custom.REM_SPACES[0.5],
    },
    text: {
      color: theme.custom.PALETTES.TEXT.Dark,
      alignSelf: 'center',
    },
  }),
);
