import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const useRadioGroupStyles = makeStyles(() =>
  createStyles({
    base: {
      '&& .MuiOutlinedInput-root': {
        height: THEME.REM_SPACES['2'],
        padding: '0.25rem 0.25rem 0.25rem 0.5rem',
        borderRadius: THEME.REM_SPACES['0.5'],
        border: `1px solid var(--border-extra-light, ${THEME.PALETTES.BORDER.Extra_Light})`,
        background: THEME.PALETTES.OTHER.White,
      },
      '&& .MuiFormControlLabel-label': {
        color: THEME.PALETTES.TEXT.Gray,
        fontSize: '0.71875rem',
        fontWeight: '500',
        textTransform: 'uppercase',
        marginBottom: 'auto',
        marginTop: 'auto',
      },
    },
  }),
);

export const styles = {
  enabled: {},
  disabled: {},
};
