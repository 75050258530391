import { makeStyles, createStyles } from '#/utils/theme';

export const useSnackbarStyles = makeStyles(() =>
  createStyles({
    button: {
      display: 'flex',
      border: 'none',
      borderRadius: '50%',
      cursor: 'pointer',
      backgroundColor: 'transparent',
    },
  }),
);
