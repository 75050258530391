import Loader from '#/components/common/loader/loader';
import { CampaignsBrand } from '#/components/features/campaigns/Campaigns/CampaignsBrand';

import { useGetCampaignsBrandFilterQuery } from '#/store/api/campaigns/campaigns';

export const CampaignsBrandContainer = () => {
  const { isLoading: filterDataIsLoading } = useGetCampaignsBrandFilterQuery({});

  if (filterDataIsLoading) return <Loader />;

  return <CampaignsBrand />;
};
