import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const useProductMixStyles = makeStyles((theme) =>
  createStyles({
    custom: {
      '&&': {
        marginTop: '1.875rem',
      },
    },
    button: {
      '&& > img': {
        width: '0.9375rem',
        height: '0.9375rem',
      },
      border: '0',
      background: 'transparent',
    },
    wrap: {
      display: 'flex',
      alignItems: 'stretch',
    },
    marginY: {
      margin: '1.875rem 0',
    },
    column: {
      flex: '32%',
      border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      marginRight: THEME.REM_SPACES[1],
      marginBottom: THEME.REM_SPACES[1],
      padding: THEME.REM_SPACES[1],
      borderRadius: '8px',
    },
    columnWithMargin: {
      marginRight: '3%',
    },
    columnButton: {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      fontSize: '0.875rem',
    },
    buttonWidth: {
      width: '6.25rem',
    },
    error: {
      color: theme.palette.error.main,
      marginTop: '1.25rem',
      display: 'block',
      fontSize: '0.8125rem',
    },
    flexDiv: {
      display: 'flex',
      alignItems: 'center',
    },
    disabled: {
      pointerEvents: 'none',
      opacity: '0.3',
    },
    active: {
      opacity: '1',
    },
    opacityFaded: {},
    chipItem: {
      '&.MuiChip-root': {
        background: THEME.PALETTES.UI.Secondary[200],
      },
      '&.MuiChip-deletable:focus': {
        background: THEME.PALETTES.UI.Secondary[200],
      },
      '&.MuiChip-clickable:hover': {
        background: THEME.PALETTES.UI.Secondary[200],
      },
    },
    chipItemSelected: {
      '&.MuiChip-root': {
        background: THEME.PALETTES.UI.Neutral[300],
      },
      '&.MuiChip-deletable:focus': {
        background: THEME.PALETTES.UI.Neutral[300],
      },
      '&.MuiChip-clickable:hover': {
        background: THEME.PALETTES.UI.Neutral[300],
      },
    },
    chipsContainer: {
      display: 'flex',
      maxWidth: '20rem',
      flexWrap: 'wrap',
      columnGap: THEME.REM_SPACES[0.5],
      rowGap: THEME.REM_SPACES[0.5],
      marginTop: THEME.REM_SPACES[1],
    },
  }),
);

export const titleSX = { fontSize: '1.25rem', marginBottom: '1.25rem' };
