import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../constants/theme/constants';

export const useAddDetailsToSpecificTierStyles = makeStyles((theme) =>
  createStyles({
    form: {
      minHeight: '30rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.custom.REM_SPACES[4],
    },
    flexParent: {
      display: 'flex',
    },
    bottomButtonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: THEME.REM_SPACES['1'],
    },
    chipItem: {
      '&.MuiChip-root': {
        background: '#D8EDFA',
        marginRight: '0.5rem',
        marginTop: '0.5rem',
      },
    },
  }),
);

export const styles = {
  cancelButton: {
    marginRight: THEME.REM_SPACES['0.5'],
  },
  divider: {
    marginTop: '1rem',
  },
  multiselect: {
    '&.MuiAutocomplete-root .MuiAutocomplete-tag': {
      background: THEME.PALETTES.UI.Secondary['200'],
      height: '100%',
    },
  },
  multiselectInput: {
    padding: '2px 3px',
    borderRadius: THEME.REM_SPACES['0.5'],
  },
};
