import { createStyles, makeStyles } from '#/utils/theme';

export const useCalendarDatePickerInputComboStyles = makeStyles((theme) =>
  createStyles({
    container: { width: '32%', flexDirection: 'row', display: 'flex' },
    width100: { width: '100%' },
    legendDot: {
      borderRadius: 20,
      maxWidth: theme.custom.REM_SPACES['0.5'],
      minWidth: theme.custom.REM_SPACES['0.5'],
      height: theme.custom.REM_SPACES['0.5'],
      alignSelf: 'center',
      marginLeft: theme.custom.REM_SPACES['0.25'],
    },
    labelContainer: {
      flexDirection: 'row',
      display: 'flex',
    },
    pickerButton: { marginTop: theme.custom.REM_SPACES['1.5'], marginLeft: theme.custom.REM_SPACES['0.5'] },
  }),
);
