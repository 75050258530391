import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { FC } from 'react';

import { MobileDatePicker } from '#/components/common/inputs/DatePicker/MobileDatePicker';

import AcceptIcon from '../../../../../assets/AcceptIcon.svg';
import CancelIcon from '../../../../../assets/CancelIcon.svg';
import EditIcon from '../../../../../assets/EditGreyIcon.svg';
import { useEditableCellStyles } from '../Table.style';

interface EditableCellDatePickerProps {
  isEditMode: boolean;
  setInputValueToTableData: () => void;
  setInputToDefaultValue: () => void;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  inputValue: string;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditableCellDatePicker: FC<EditableCellDatePickerProps> = ({
  isEditMode,
  setInputValueToTableData,
  setInputToDefaultValue,
  setInputValue,
  inputValue,
  setEditMode,
}) => {
  const classes = useEditableCellStyles();

  const editModePicker = (
    <div className={classes.container}>
      <div className={classes.buttonsContainer}>
        <button className={classes.approvalButton} onClick={setInputValueToTableData}>
          <img alt="accept" className={classes.approvalIcon} src={AcceptIcon} />
        </button>
        <button className={classes.approvalButton} onClick={setInputToDefaultValue}>
          <img alt="cancel" className={classes.approvalIcon} src={CancelIcon} />
        </button>
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          dataCy="date-picker"
          editMode
          onChange={(date) => setInputValue(date?.toString() ?? '')}
          value={inputValue}
        />
      </LocalizationProvider>
    </div>
  );

  const noEditModePicker = (
    <div className={classes.container}>
      <div className={classes.buttonsContainer}>
        <button
          className={classes.editButton}
          onClick={() => {
            setEditMode(true);
          }}
        >
          <img alt="edit" src={EditIcon} />
        </button>
      </div>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          dataCy="date-picker"
          editMode={false}
          onChange={(date) => setInputValue(date?.toString() ?? '')}
          value={inputValue}
        />
      </LocalizationProvider>
    </div>
  );

  return isEditMode ? editModePicker : noEditModePicker;
};
