import { Box, TableCell as MUTCell, TableRow } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router';

import { currencyFormatter } from '#/utils/textFormatters';

import { USER_PERMISSIONS } from '../../../../../constants/permissions/userPermissions';
import { getUserHasPermission } from '../../../../../constants/permissions/utils';
import { IDisplayCampaignInstanceTactic } from '../../../../../interfaces/campaigns/campaignsInstances';
import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '../../../../../interfaces/table/table';
import { useGetCampaignInstanceTacticsQuery } from '../../../../../store/api/products/products';
import Loader from '../../../../common/loader/loader';
import { Table } from '../../../../common/table/tableComponent/Table';
import { TypographyWithFontFamily } from '../../../../common/typography/TypographyWithFontFamily';
import { useCampaignInstanceTacticsStyle } from './CampaignInstanceTactics.styles';
import ProductFeedTableView from './ProductFeedTableView';
import { useAppSelector } from '#/hooks';
import { USER_TYPES } from '#/utils/UserTypeWrapper';

const CampaignInstanceTactics = () => {
  const hasPermission = getUserHasPermission(USER_PERMISSIONS.CAMPAIGN_INSTANCE_BRAND_CHANNEL_TACTICS);
  const user = useAppSelector((state) => state.user);
  if (user.user?.userType === USER_TYPES.BRAND || user.user?.userType === USER_TYPES.AGENCY) {
    return null;
  }
  const { instanceId } = useParams();
  const [upnCodesViewInstanceId, setUpnCodesViewInstanceId] = useState<string | null>(null);
  const [upnCodesViewName, setUpnCodesViewName] = useState<string>('');
  const { data, isLoading, error } = useGetCampaignInstanceTacticsQuery({ instanceId });
  const classes = useCampaignInstanceTacticsStyle();
  const displayTactics =
    data?.tactics.map((tactic) => ({ ...tactic, products: tactic.upnCodes.length.toString() })) || [];
  const channelAndTacticsColumns: ITableColumnsProps<IDisplayCampaignInstanceTactic>[] = [
    {
      fieldName: 'tacticName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Tactic',
    },
    {
      fieldName: 'assetStatus',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Asset Status',
    },
    {
      fieldName: 'committedSpend',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Committed Spend',
      textFormatter: currencyFormatter,
      itemClass: 'text-right',
    },

    {
      fieldName: 'products',
      align: TableColumnAlign.CENTER,
      customHead: 'Number of Products',
      type: TableCellTypes.CUSTOM_ELEMENT,
      actionElementIdentifier: 'upnCodes',
      actionElementSecondIdentifier: 'tacticName',
      actionElementThirdIdentifier: 'instanceTacticId',
      getCustomElement: (upnCodes, tacticName, instanceTacticId) => (
        <Box
          className={classes.nrOfProducts}
          onClick={() => {
            setUpnCodesViewInstanceId(instanceTacticId);
            setUpnCodesViewName(tacticName);
          }}
        >
          {upnCodes?.length}
        </Box>
      ),
    },
  ];
  const getTotalCommittedSpend = () =>
    data?.tactics?.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.committedSpend), 0) || '';
  const extraRow = (
    <TableRow>
      <MUTCell className={classes.extraRowCell} colSpan={2}>
        Total Committed Spend
      </MUTCell>
      <MUTCell className={classes.extraRowCell}>{currencyFormatter(getTotalCommittedSpend())}</MUTCell>
    </TableRow>
  );
  if (isLoading) {
    return <Loader />;
  }

  //@ts-expect-error @FIXME validate type
  if (error?.status === 418) {
    return null;
  }

  return (
    <Box sx={{ padding: '1rem' }}>
      {hasPermission && (
        <Box className={classes.container}>
          <TypographyWithFontFamily gutterBottom variant="H4_Base">
            {data?.brandName}
          </TypographyWithFontFamily>
          <Table
            bodyCellClass={classes.tableBodyCell}
            columns={channelAndTacticsColumns}
            containerClass={classes.tableContainer}
            data={displayTactics}
            tableExtraRow={extraRow}
          />
          {upnCodesViewInstanceId && (
            <ProductFeedTableView
              onClose={() => setUpnCodesViewInstanceId(null)}
              upnCodesViewInstanceId={upnCodesViewInstanceId}
              upnCodesViewName={upnCodesViewName}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
export default CampaignInstanceTactics;
