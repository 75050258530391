import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../constants/theme/constants';

export const useAddUserWizardStyle = makeStyles((theme) =>
  createStyles({
    rightButtonsContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& ::-webkit-scrollbar': {
        width: '2px',
      },
      '& ::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#16191F',
        outline: '1px solid slategrey',
      },
    },
    headerContainer: {
      background: '#232038',
      height: '4rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1rem',
    },
    modalContainer: {
      background: '#FFF',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginBottom: theme.custom.REM_SPACES[4],
    },
    stepperContainer: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    stepper: {
      marginTop: '1rem',
      marginBottom: '1rem',
      width: '40rem',
      justifyContent: 'space-around',
    },
    marginTop1: {
      marginTop: THEME.REM_SPACES['1'],
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      columnGap: '0.5rem',
    },
    input: {
      padding: '0',
      width: '100%',
      '& input': { padding: '0 8px', height: '30px' },
      height: '30px',
    },
    selectMenuPaper: {
      maxHeight: '300px',
    },
    tableContainer: {
      margin: `${THEME.REM_SPACES[1]} 0`,
      '& th:first-child': {
        borderRight: 'none',
        width: '4rem',
      },
      '& th:nth-child(2)': {
        borderLeft: 'none',
        width: '7rem',
      },
      '& tr td:first-child': {
        borderRight: 'none',
      },
      '& tr td:nth-child(2)': {
        borderLeft: 'none',
      },
    },
    tableHeader: {
      background: THEME.PALETTES.UI.Neutral[100],
      height: THEME.REM_SPACES['2.5'],
      color: THEME.PALETTES.TEXT.Dark,
      fontSize: '0.71875rem',
      fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
    },
    tableCell: {
      height: THEME.REM_SPACES['2.5'],
      color: THEME.PALETTES.TEXT.Dark,
      fontWeight: THEME.FONT_WEIGHTS.REGULAR,
      fontSize: '0.8125rem',
    },
    uploadContainer: {
      width: '15rem',
    },
    companyButtonsContainer: {
      padding: THEME.REM_SPACES['1'],
      display: 'flex',
      justifyContent: 'flex-end',
      columnGap: THEME.REM_SPACES['0.5'],
    },
    loadingSpacePlaceholder: {
      minHeight: '9.375rem',
      marginTop: '2rem',
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    labelMargin: {
      '&&': {
        marginRight: '0.2rem',
      },
    },
    checkboxContainer: {
      display: 'flex',
      columnGap: '0.5rem',
      alignItems: 'center',
    },
    lastButton: {
      marginLeft: theme.custom.REM_SPACES[1],
    },
  }),
);
export const userInfoHeaderSx = {
  margin: `${THEME.REM_SPACES[1]} 0`,
};
export const contactCheckboxContainerSx = {
  '&&': {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'self-start',
  },
};
export const companyAddressContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  rowGap: '0.5rem',
};
export const headerWithSpacingSx = {
  margin: `${THEME.REM_SPACES[1]} 0`,
};
export const gridFlexSx = {
  display: 'flex',
  alignItems: 'flex-end',
};
export const gridSpaceBetweenSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};
