import { Accordion, AccordionDetails, AccordionSummary, Box, ClickAwayListener } from '@mui/material';
import { useEffect, useState } from 'react';

import AlertError from '#/components/common/Alert/AlertError/AlertError';
import ModalBottomContainer from '#/components/common/modal/ModalBottomContainer';
import { getTacticUniqueIdentifier } from '#/components/features/campaigns/utils';

import { useDataCy } from '#/hooks/useDataCy';

import ArrowDownIcon from '../../../assets/ArrowDownIcon.svg';
import ArrowLeftIcon from '../../../assets/ArrowLeftIcon.svg';
import EditIcon from '../../../assets/EditIcon.svg';
import MinusIcon from '../../../assets/MinusIcon.svg';
import PlusDisabledIcon from '../../../assets/PlusDisabledIcon.svg';
import PlusEnabledIcon from '../../../assets/PlusEnabledIcon.svg';
import useGetResource from '../../../hooks/useGetResource';
import { IAddTacticsToTierProps, ISelectableTactic, ITacticsTree } from '../../../interfaces/forms/campaigns';
import {
  useGetChannelAndTacticsTreeQuery,
  usePutCampaignTierTacticsMutation,
} from '../../../store/api/campaigns/campaignChannelsAndTactics';
import { useGetCampaignOverviewGeneralQuery } from '../../../store/api/campaigns/campaigns';
import NewButton from '../../common/button/NewButton';
import Loader from '../../common/loader/loader';
import Modal from '../../common/modal/Modal';
import { useSnackbarError } from '../../common/snackbar/useSnackbarError';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import { styleSx, useAddTacticsToTierStyle } from './addTacticsToTier.styles';

const AddTacticsToTier = ({
  isAddTacticsModalOpen,
  setIsAddTacticsModalOpen,
  tierId,
  tierName,
  savedTactics,
}: IAddTacticsToTierProps) => {
  const classes = useAddTacticsToTierStyle();
  const { accordionDetailsSx, accordionSummarySx, accordionSx } = styleSx;
  const [selectedChannel, setSelectedChannel] = useState('');
  const [selectedSubChannel, setSelectedSubChannel] = useState('');
  const [selectableAreas, setSelectableAreas] = useState<ISelectableTactic[]>([]);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [selectedTactics, setSelectedTactics] = useState<ISelectableTactic[]>([]);
  const [tacticsList, setTacticsList] = useState<ITacticsTree | undefined>(undefined);
  const { setError } = useSnackbarError();
  const resource = useGetResource();
  const [trigger, { error }] = usePutCampaignTierTacticsMutation();
  const { data: campaignData } = useGetCampaignOverviewGeneralQuery({ resource });
  const { data: generalTreeData, isLoading: tacticsTreeLoading } = useGetChannelAndTacticsTreeQuery();
  useEffect(() => {
    const tempSelectedTactics: ISelectableTactic[] = [];
    //merged with saved data fetched from back end
    savedTactics?.forEach((savedTactic) => {
      generalTreeData?.channels.forEach((channel) => {
        if (channel.channel === savedTactic.channel) {
          channel.subChannels.forEach((subChannel) => {
            if (subChannel.subChannel === savedTactic.subChannel) {
              subChannel.tactics.forEach((tactic) => {
                if (tactic.area === savedTactic.area) {
                  if (tactic.channelPartnerId === savedTactic.channelPartnerId) {
                    tempSelectedTactics.push({
                      ...savedTactic,
                      channelTacticId: tactic.channelTacticId,
                      channelPartnerId: tactic.channelPartnerId,
                      channelPartnerName: tactic.channelPartnerName,
                    });
                  }
                }
              });
            }
          });
        }
      });
    });
    setSelectedTactics(tempSelectedTactics);
    setTacticsList(generalTreeData);
  }, [generalTreeData?.channels?.length]);

  const onSelectSubChannel = (
    channelName: string,
    subChannelName: string,
    areas: { area: string; channelTacticId: string; channelPartnerId: string; channelPartnerName: string }[],
  ) => {
    setSelectedChannel(channelName);
    setSelectedSubChannel(subChannelName);
    const tempAreas = areas.map((area) => ({
      channel: channelName,
      subChannel: subChannelName,
      area: area.area,
      channelTacticId: area.channelTacticId,
      selected: false,
      channelPartnerId: area.channelPartnerId,
      channelPartnerName: area.channelPartnerName,
    }));
    setSelectableAreas(tempAreas);
    setIsSelectOpen(false);
  };
  const generateDataCy = useDataCy();

  const changeTactic = (area: ISelectableTactic, selected: boolean) => {
    if (selected) setSelectedTactics((prev) => [...prev, area]);
    else {
      const temp = selectedTactics.filter(
        (tactic) => getTacticUniqueIdentifier(tactic) !== getTacticUniqueIdentifier(area),
      );
      setSelectedTactics(temp);
    }
  };

  const isTacticSelected = (channelTacticId: string) =>
    selectedTactics.some((tactic) => tactic.channelTacticId === channelTacticId);

  const onSaveTactics = async () => {
    try {
      await trigger({
        body: {
          tierId,
          tactics: selectedTactics.map((selectedTactic) => ({
            channel: selectedTactic.channel,
            subChannel: selectedTactic.subChannel,
            area: selectedTactic.area,
            channelPartnerId: selectedTactic.channelPartnerId,
          })),
        },
      }).unwrap();
      setIsAddTacticsModalOpen(false);
    } catch (error) {
      setError(error);
    }
  };
  const onCancel = () => {
    setIsAddTacticsModalOpen(false);
  };

  return (
    <Box>
      <Modal
        content={
          <Box className={classes.container}>
            <AlertError error={error} />
            {tacticsTreeLoading ? (
              <Loader />
            ) : (
              <Box>
                {/* body */}
                <Box className={classes.campaignDetails}>
                  <TypographyWithFontFamily variant={'Default_Bold'}>Campaign</TypographyWithFontFamily>
                  <TypographyWithFontFamily>: {campaignData?.name}</TypographyWithFontFamily>
                </Box>
                <Box className={classes.campaignDetails}>
                  <TypographyWithFontFamily variant={'Default_Bold'}>Tier</TypographyWithFontFamily>
                  <TypographyWithFontFamily>: {tierName}</TypographyWithFontFamily>
                </Box>
                <Box className={classes.modalContent}>
                  <Box className={classes.tacticsBox}>
                    {/* left side */}
                    <TypographyWithFontFamily gutterBottom variant="H5_Base">
                      Select Tactics
                    </TypographyWithFontFamily>
                    <TypographyWithFontFamily variant="Label_Base">SELECT CHANNEL/SUB CHANNEL</TypographyWithFontFamily>
                    <ClickAwayListener
                      mouseEvent="onMouseDown"
                      onClickAway={() => setIsSelectOpen(false)}
                      touchEvent="onTouchStart"
                    >
                      <Box className={classes.selectTacticsContainer}>
                        <Box
                          className={classes.tacticSelect}
                          data-cy={generateDataCy('box', `select-channel-sub-channel`)}
                          onClick={() => setIsSelectOpen((prev) => !prev)}
                        >
                          {selectedChannel && selectedSubChannel ? (
                            <TypographyWithFontFamily>{`${selectedChannel} / ${selectedSubChannel}`}</TypographyWithFontFamily>
                          ) : (
                            <TypographyWithFontFamily>Select Channel / SubChannel</TypographyWithFontFamily>
                          )}
                          <img src={isSelectOpen ? ArrowDownIcon : ArrowLeftIcon} />
                        </Box>
                        {isSelectOpen && (
                          <Box className={classes.selectOptionsContainer}>
                            {tacticsList?.channels.map((channel) => (
                              <Accordion
                                key={channel.channel}
                                data-cy={generateDataCy('accordion', `channel-name`)}
                                sx={accordionSx}
                              >
                                <AccordionSummary expandIcon={<img src={ArrowLeftIcon} />} sx={accordionSummarySx}>
                                  <TypographyWithFontFamily>{channel.channel}</TypographyWithFontFamily>
                                </AccordionSummary>
                                <AccordionDetails sx={accordionDetailsSx}>
                                  {channel.subChannels.map((subChannel) => (
                                    <Box
                                      key={subChannel.subChannel}
                                      className={classes.subChannelContainer}
                                      data-cy={generateDataCy('box', `on-select-sub-channel`)}
                                      onClick={() =>
                                        onSelectSubChannel(channel.channel, subChannel.subChannel, subChannel.tactics)
                                      }
                                    >
                                      <TypographyWithFontFamily>{subChannel.subChannel}</TypographyWithFontFamily>
                                    </Box>
                                  ))}
                                </AccordionDetails>
                              </Accordion>
                            ))}
                          </Box>
                        )}
                      </Box>
                    </ClickAwayListener>
                    <Box>
                      {selectableAreas.map((area) => (
                        <Box key={getTacticUniqueIdentifier(area)}>
                          {isTacticSelected(area.channelTacticId) && (
                            <Box
                              className={classes.selectableArea}
                              data-cy={generateDataCy('Box', `enabled-dd-selected-area`)}
                            >
                              <TypographyWithFontFamily>
                                {area.channel}/{area.subChannel}/{area.area}: {area.channelPartnerName}
                              </TypographyWithFontFamily>
                              <Box>
                                <img src={PlusDisabledIcon} />
                              </Box>
                            </Box>
                          )}
                          {!isTacticSelected(area.channelTacticId) && (
                            <Box
                              className={classes.selectableArea}
                              data-cy={generateDataCy('box', `disabled-add-selected-area`)}
                              onClick={() => changeTactic(area, true)}
                            >
                              <TypographyWithFontFamily>
                                {area.channel}/{area.subChannel}/{area.area}: {area.channelPartnerName}
                              </TypographyWithFontFamily>
                              <Box>
                                <img src={PlusEnabledIcon} />
                              </Box>
                            </Box>
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Box className={classes.tacticsBox} data-cy={generateDataCy('box', `added-to-campaign-section`)}>
                    {/* right side */}
                    <TypographyWithFontFamily gutterBottom variant="H5_Base">
                      Added to Campaign
                    </TypographyWithFontFamily>
                    <Box>
                      {selectedTactics.map((area) => (
                        <Box key={getTacticUniqueIdentifier(area)} className={classes.removeAreaContainer}>
                          <TypographyWithFontFamily>
                            {area.channel}/{area.subChannel}/{area.area}: {area.channelPartnerName}
                          </TypographyWithFontFamily>
                          <Box
                            data-cy={generateDataCy('box', `remove-tactic-button`)}
                            onClick={() => changeTactic(area, false)}
                          >
                            <img src={MinusIcon} />
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            <ModalBottomContainer>
              <Box></Box>
              <Box className={classes.bottomButtonsContainer}>
                <NewButton className={classes.cancelButton} onClick={onCancel} text="Cancel" variant="Secondary" />
                <NewButton onClick={onSaveTactics} text="Save" variant="Primary" />
              </Box>
            </ModalBottomContainer>
          </Box>
        }
        handleCloseModal={onCancel}
        icon={EditIcon}
        modalTitle="Edit Tactics"
        open={isAddTacticsModalOpen}
        size="large"
      ></Modal>
    </Box>
  );
};
export default AddTacticsToTier;
