import { createStyles, makeStyles } from '#/utils/theme';

export const useCommunicationsSelectedCampaignsStyles = makeStyles(() =>
  createStyles({
    selectedCampaignsTableHeader: {
      '& th:nth-child(2)': {
        minWidth: '11rem',
      },
      '& th:nth-child(3)': {
        minWidth: '7rem',
      },
    },
  }),
);
