import { matchRoutes, useLocation } from 'react-router';

const routes = [{ path: 'instances/channel-tactics/:instanceId' }, { path: 'campaigns/channel-tactics/:campaignId' }];

export const useCurrentPath = () => {
  const location = useLocation();
  const route = matchRoutes(routes, location);
  if (route === null) return '';
  return route[0].route.path;
};

export default useCurrentPath;
